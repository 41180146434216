import { IFeatureFlags } from "src/interfaces/organisation/organisation-features";

export enum FeatureFlagStorageKeys {
  MUSIC_USAGE_DECLARATION = "musicUsageDeclaration",
}

type IFeatureFlagOverrides = {
  [key: string]: "on" | "off";
};

export const StorageKeys = {
  CSCMODE: "cscMode",
};

const getUrlFeatureFlagOverrides = (
  urlSearchParams: URLSearchParams
): IFeatureFlagOverrides => {
  return Object.values(FeatureFlagStorageKeys)
    .map((key) => ({
      key,
      value: urlSearchParams.get(
        FeatureFlagStorageKeys.MUSIC_USAGE_DECLARATION
      ),
    }))
    .reduce((acc, { key, value }) => ({ ...acc, [key]: value }), {});
};

const storeFeatureFlagsToLocalStorage = (featureFlags: IFeatureFlags) => {
  Object.entries(featureFlags).forEach(([key, value]) => {
    localStorage.setItem(key, value ? "on" : "off");
  });
};

const updateFeatureFlagsInLocalStorage = (
  featureFlags: IFeatureFlagOverrides
) => {
  Object.entries(featureFlags).forEach(([key, value]) => {
    if (key && ["on", "off"].includes(value)) {
      localStorage.setItem(key, value);
    }
  });
};

export default {
  getUrlFeatureFlagOverrides,
  storeFeatureFlagsToLocalStorage,
  updateFeatureFlagsInLocalStorage,
};
