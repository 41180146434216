import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  contactTileContainer: css`
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
    padding: 12px 0;

    @media ${queries.large} {
      max-width: 376px;
      height: 170px;
    }
  `,
  header: css`
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 3.3px;
    padding-bottom: 18px;
  `,
  contactTileDetailsContainer: css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-bottom: 24px;
  `,
  contactTileTextDetailsContainer(marginLeft: number): SerializedStyles {
    return css`
      display: flex;
      flex-direction: column;
      margin-left: ${marginLeft}px;
      text-align: left;
      min-width: 250px;
    `;
  },
  tooltip: css`
    .ant-tooltip {
      font-size: 0.875rem;
    }

    .ant-tooltip-inner {
      color: ${theme.colors.black} !important;
    }
  `,
  imageContainer: css`
    border-radius: 24px;
    padding-right: 16px;
  `,
  title: css`
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
  `,
  description: css`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    a {
      color: ${theme.colors.functional.brightOrangeHover};
      font-weight: 700;
    }
  `,
  emailLink: css`
    color: ${theme.colors.white};
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;

    @media ${queries.large} {
      -webkit-line-clamp: 1;
    }

    &:hover {
      font-weight: 700;
      color: ${theme.colors.functional.brightOrangeHover};
    }
  `,
  link: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
    color: ${theme.colors.white};

    &:hover {
      text-decoration: none;
      cursor: pointer;
      color: ${theme.colors.functional.brightOrangeHover};
    }
  `,
};
