import { ReactElement } from "react";

const RubbishBinIcon = (): ReactElement => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.327 5.27694C15.5344 5.27694 15.7021 5.10919 15.7021 4.90178V3.50831C15.7021 3.3009 15.5344 3.13315 15.327 3.13315H0.675213C0.467801 3.13315 0.300049 3.3009 0.300049 3.50831V4.90178C0.300049 5.10919 0.467801 5.27694 0.675213 5.27694H1.39553V17.8562V20H14.6067V17.8562V5.27694H15.327ZM5.08446 17.8562H3.53879V5.27694H5.08446V17.8562ZM8.77394 17.8562H7.22826V5.27694H8.77394V17.8562ZM12.4634 17.8562H10.9177V5.27694H12.4634V17.8562Z"
        fill="white"
      />
      <path
        d="M10.5474 1.76863L10.5474 0.375164C10.5474 0.167967 10.3794 -7.34206e-09 10.1722 -1.63989e-08L5.80368 -2.07353e-07C5.59648 -2.1641e-07 5.42852 0.167966 5.42852 0.375164L5.42852 1.76863C5.42852 1.97583 5.59648 2.14379 5.80368 2.14379L10.1722 2.14379C10.3794 2.1438 10.5474 1.97583 10.5474 1.76863Z"
        fill="white"
      />
    </svg>
  );
};
export default RubbishBinIcon;
