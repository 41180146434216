import { useWindowSize } from "@songtradr/spa-common";
import React, { Fragment, ReactElement, useEffect, useState } from "react";
import { IConfirmContentProps } from "src/components/interfaces";
import { Table, Tooltip } from "antd";
import { ModalTypes } from "src/components/modals/base-modal";
import { eApplicationType } from "src/interfaces/auth";
import { IRelatedOrganisation } from "src/interfaces/related-organisations";
import getAgencyColumns from "src/pages/team/table-data/org-agency-columns";
import ViewTableItemIcon from "src/app/assets/icons/component-icons/view-table-item-icon";
import theme from "src/theme";
import TableLoginIcon from "src/app/assets/icons/component-icons/login-icon";
import OrgAgencyTable from "..";
import styles from "../../../styles";
import {
  IOrganisationMemberProps,
  OrgMembersSortType,
  SortOrder,
} from "../../../interfaces";

interface IProps {
  searchText: string;
  confirmDeleteContentProps: IConfirmContentProps;
  setSelectedOrgMember: React.Dispatch<
    React.SetStateAction<IOrganisationMemberProps | undefined>
  >;
  isDeleteMemberModalOpen: boolean;
  setIsDeleteMemberModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingRequest: boolean;
  allApplications: eApplicationType[];
  currentSortType: OrgMembersSortType;
  setCurrentSort: React.Dispatch<React.SetStateAction<OrgMembersSortType>>;
  nameSortAscending: boolean;
  setNameSort: React.Dispatch<React.SetStateAction<boolean>>;
  filteredAndSortedOrgMembersData: IOrganisationMemberProps[] | [];
  openModalType: ModalTypes;
  setSortTypeAndOrder: (
    sortType: OrgMembersSortType,
    sortOrder: SortOrder
  ) => void;
  applySortAndSearchFilter: (members?: IOrganisationMemberProps[]) => void;
  agencyOrganisations: IRelatedOrganisation[];
  handleAddApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>;
  handleRemoveApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>;
  handleAddAndRemoveApplication: (
    member: IOrganisationMemberProps,
    addApplication: eApplicationType,
    removeApplication: eApplicationType
  ) => Promise<void>;
  setCurrentTab: (value: string) => void;
  currentChildTab: string;
  setChildTab: (value: string) => void;
}

enum PageName {
  AgenciesOverview = "agencies-overview",
  AgencyProfile = "agency-profile",
}

const OrganisationMemberContainer = ({
  setCurrentTab,
  setSelectedOrgMember,
  searchText,
  confirmDeleteContentProps,
  isDeleteMemberModalOpen,
  setIsDeleteMemberModalOpen,
  isLoadingRequest,
  allApplications,
  currentSortType,
  setCurrentSort,
  nameSortAscending,
  setNameSort,
  filteredAndSortedOrgMembersData,
  openModalType,
  setSortTypeAndOrder,
  agencyOrganisations,
  applySortAndSearchFilter,
  handleAddApplication,
  handleRemoveApplication,
  handleAddAndRemoveApplication,
  setChildTab,
  currentChildTab,
}: IProps): ReactElement => {
  useWindowSize();

  const handleNameSort = () => {
    setCurrentSort(OrgMembersSortType.Name);
    setNameSort((state) => !state);
  };

  const filteredOrgMembersByAgency = (
    members: IOrganisationMemberProps[] | [],
    orgId: string
  ): IOrganisationMemberProps[] => {
    return members.filter((member) => member.controllingOrgId === orgId);
  };

  useEffect(() => {
    applySortAndSearchFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameSortAscending, searchText]);

  const agencyColumns = getAgencyColumns;
  const [selectedAgency, setSelectedAgency] = useState<IRelatedOrganisation>();

  const data = agencyOrganisations.map((agency: IRelatedOrganisation) => {
    const handleViewAgency = (agencyOrgId: IRelatedOrganisation) => {
      if (agencyOrgId) {
        setSelectedAgency(agencyOrgId);
        setChildTab(PageName.AgencyProfile);
      }
    };

    return {
      key: agency.orgId,
      brand: (
        <Fragment>
          <div css={styles.teamInviteEmail}>{agency.name}</div>
        </Fragment>
      ),
      members: (
        <Fragment>
          <div css={styles.brandMembersCol}>
            {filteredOrgMembersByAgency(
              filteredAndSortedOrgMembersData,
              agency.orgId
            )
              .map((item) => `${item.firstName} ${item.lastName}`)
              .join(", ")}
          </div>
        </Fragment>
      ),
      viewAgency: (
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title="View agency"
          color={theme.colors.white}
          placement="top"
          css={styles.tooltip}
        >
          <button
            type="button"
            css={styles.iconButton}
            onClick={(e) => {
              e.stopPropagation();
              handleViewAgency(agency);
            }}
          >
            <ViewTableItemIcon />
          </button>
        </Tooltip>
      ),
      viewBrand: (
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title="Add member"
          color={theme.colors.white}
          placement="top"
          css={styles.tooltip}
        >
          <button
            type="button"
            css={styles.iconButton}
            onClick={() => {
              // setChildOrgMembersData(orgMembersByOrg);
              // handleAssignMember(relatedOrganisation);
            }}
          >
            <TableLoginIcon />
          </button>
        </Tooltip>
      ),
    };
  });

  return (
    <Fragment>
      {currentChildTab === PageName.AgenciesOverview && (
        <Table
          dataSource={data}
          columns={agencyColumns()}
          pagination={false}
          css={styles.table}
          data-testid="team-members-table"
        />
      )}
      {currentChildTab === PageName.AgencyProfile && selectedAgency && (
        <OrgAgencyTable
          orgMembers={filteredOrgMembersByAgency(
            filteredAndSortedOrgMembersData,
            selectedAgency?.orgId
          )}
          searchText={searchText}
          setCurrentTab={setCurrentTab}
          setSelectedOrgMember={setSelectedOrgMember}
          handleNameSort={handleNameSort}
          nameSortAscending={nameSortAscending}
          currentSortType={currentSortType}
          isDeleteMemberModalOpen={isDeleteMemberModalOpen}
          setIsDeleteMemberModalOpen={setIsDeleteMemberModalOpen}
          openModalType={openModalType}
          confirmDeleteContentProps={confirmDeleteContentProps}
          setSortTypeAndOrder={setSortTypeAndOrder}
          isLoading={isLoadingRequest}
          nameTitleReplacement={selectedAgency?.name}
          handleAddApplication={handleAddApplication}
          handleRemoveApplication={handleRemoveApplication}
          handleAddAndRemoveApplication={handleAddAndRemoveApplication}
          allApplications={allApplications}
        />
      )}
    </Fragment>
  );
};

export default OrganisationMemberContainer;
