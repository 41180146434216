import axios, { AxiosResponse } from "axios";
import { IImgPreviews } from "src/pages/org-dashboard/components/logo-product-info-section/interfaces";
import buildOrganisationsBaseUrl from "../base-url-helper";
import { getAuthHeader } from "../headers";

export type DeleteBody = {
  [key in keyof IImgPreviews]: true;
};

const deleteTheme = async (
  orgId: string,
  accessToken: string,
  data: DeleteBody
): Promise<AxiosResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken) },
    data,
  };

  return axios.delete(`${buildOrganisationsBaseUrl(orgId)}/logo`, options);
};

export default deleteTheme;
