import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  inputField: css`
    height: 64px;
  `,
  inputPassword: css`
    background-color: ${theme.colors.background.landingDarkGray} !important;
    span {
      width: auto;
      pointer-events: auto;
    }
  `,
  showHidePasswordBtn: css`
    display: flex;
    align-items: center;
  `,
};
