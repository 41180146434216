import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: css`
    margin-bottom: 148px;
    h1 {
      color: ${theme.colors.white};
      font-family: ${theme.fonts.sofiaPro};
      font-weight: 250;
      font-size: 48px;
      line-height: 56px;
      text-align: center;
      letter-spacing: -0.02em;
    }
  `,
};
