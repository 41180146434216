import React, { ReactElement, useRef, useState } from "react";
import { Col, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import useAuth from "src/auth/use-auth";
import FloatingLabelInput from "src/components/floating-label-input";
import { useHistory, useLocation } from "react-router-dom";
import { errorToast, successToast } from "src/components/toast-notification";
import { getUnixTimestampInSeconds } from "src/utils/time";
import appStyles from "src/app/styles";
import CopyrightFooter from "src/components/copyright-footer";
import BackBtn from "src/components/back-button";
import styles from "./styles";
import PasswordInputV2 from "../user-profile/components/user-profile-form/components/password-input-v2";

const resendCodeThreshold = 60; // seconds

const ResetPassword = (): ReactElement | null => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { changePassword, resetPassword } = useAuth();
  const { state } = useLocation();
  const resendCodeTimeout = useRef(0);
  const history = useHistory();

  const verficitaionCode: string = form.getFieldValue("code");

  if (!state.email) {
    history.back();
    return null;
  }

  const sendEmail = () => {
    if (!state || !state.email) {
      return;
    }

    const currentTimestamp = getUnixTimestampInSeconds();

    if (currentTimestamp - resendCodeTimeout.current <= resendCodeThreshold) {
      errorToast({
        message: `Please wait ${resendCodeThreshold} seconds before trying to resend the code`,
      });
      return;
    }

    resetPassword(state.email);
    successToast({ message: "Code resent" });
    resendCodeTimeout.current = currentTimestamp;
  };

  const onSetNewPassword = (e: any) => {
    e.preventDefault();
    setIsLoading(true);

    const code = form.getFieldValue("code");

    if (!state || !state.email) {
      return;
    }

    if (!isValidPassword) {
      setIsError(true);
      return;
    }

    changePassword(code, password, state.email, () => {
      setIsLoading(false);
      setIsError(false);
    });
  };

  return (
    <div css={styles.flexContainer}>
      <div css={[appStyles.container, appStyles.guestPageContainer]}>
        <div css={styles.backBtn}>
          <BackBtn />
        </div>
        <Form
          form={form}
          onSubmitCapture={onSetNewPassword}
          css={appStyles.guestPageInnerContainer}
        >
          <Row css={styles.row}>
            <Col span={24}>
              <div css={styles.inputContainer}>
                <h2 css={styles.title}>
                  {t("We’ve sent your code via email")}
                </h2>
                <p css={styles.description}>
                  {t(
                    "Input the verification code we've sent, then select a new password for your account."
                  )}
                </p>
                <div css={styles.inputHeader}>{t("RESET PASSWORD")}</div>
                <FloatingLabelInput
                  label="Verification code"
                  name="code"
                  value={verficitaionCode}
                  dataTestId="verification-code-input"
                  required
                  helpMessage="Please input your verification code!"
                />
              </div>
              <div css={styles.inputContainer}>
                <Form.Item name="password">
                  <PasswordInputV2
                    css={styles.input}
                    setPassword={setPassword}
                    primaryBtnText="Set new password"
                    primaryBtnAction={onSetNewPassword}
                    secondaryBtnText="Resend code"
                    secondaryBtnAction={sendEmail}
                    isValidPassword={isValidPassword}
                    setIsValidPassword={setIsValidPassword}
                    isError={isError && !isValidPassword}
                    name="password"
                    password={password}
                    isLoading={isLoading}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div css={[appStyles.container, styles.copyrightFooter]}>
        <CopyrightFooter />
      </div>
    </div>
  );
};

export default ResetPassword;
