import axios from "axios";
import { buildRelatedOrganisationsBaseUrl } from "src/api/base-url-helper";
import { IRelatedOrganisations } from "src/interfaces/related-organisations";
import { getJsonContentHeader, getAuthHeader } from "../../headers";

const getRelatedOrganisations = async (
  organisationId: string,
  accessToken: string,
  abortController?: AbortController
): Promise<IRelatedOrganisations | null> => {
  const options = {
    headers: {
      ...getAuthHeader(accessToken),
      ...getJsonContentHeader(),
    },
    ...{ signal: abortController?.signal },
  };

  const { data } = await axios.get(
    buildRelatedOrganisationsBaseUrl(organisationId),
    options
  );
  return data as IRelatedOrganisations;
};

export default getRelatedOrganisations;
