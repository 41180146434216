import axios, { AxiosResponse } from "axios";
import buildOrganisationsBaseUrl from "../base-url-helper";
import { getAuthHeader } from "../headers";

export interface IImgFormData {
  Images: {
    PrimaryLogo?: File | null;
    BackgroundImg?: File | null;
  };
}

const updateTheme = async (
  orgId: string,
  accessToken: string,
  formData: IImgFormData
): Promise<AxiosResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken) },
  };
  const url = `${buildOrganisationsBaseUrl(orgId)}/logo`;
  return axios.put(url, formData, options);
};

export default updateTheme;
