import { css } from "@emotion/react";
import theme from "src/theme";

const darkGray = "linear-gradient(0deg, #141414, #141414)";
const customIcons = css`
  position: absolute;
  display: flex;
  right: 0;
  height: 48px;
  border: none;
  align-items: center;
`;
const iconWidth = 48;

export default {
  searchWrapper: css`
    display: flex;
    position: relative;
  `,
  searchInput: css`
    width: 300px;
    height: 48px;
    outline: none;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.background.white};
    border: 1px solid transparent;
    padding: 0;
    background: ${darkGray};
    padding-left: 48px;
    padding-right: 30px;
    &:hover {
      border-bottom: 1px solid ${theme.colors.white};
    }
    &:focus {
      border-bottom: 1px solid ${theme.colors.white};
      border-bottom-color: ${theme.colors.white};
    }
  `,
  closeButton: (isCloseButtonVisible: boolean) => css`
    ${customIcons}
    opacity: ${isCloseButtonVisible ? 1 : 0};
    padding: 0;
    justify-content: center;
    background: none;
    &:hover {
      cursor: pointer;
    }
    button {
      opacity: 1;
      color: ${theme.colors.background.white};
      background: none;
      border: none;
      background-repeat: no-repeat;
      cursor: pointer;
      overflow: hidden;
    }
  `,
  searchIcon: (isSearchIconOnRightSide: boolean, searchWidth: number) => css`
    ${customIcons}
    opacity: 1;
    width: 48px;
    color: ${theme.colors.background.white};
    background-color: transparent;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    transform: translateX(
      ${isSearchIconOnRightSide ? `-${searchWidth - iconWidth}px` : 0}
    );
    transition: transform 0.3s ease-out;
  `,
};
