import React, { Fragment, ReactElement, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Table from "src/components/table";
import {
  getIsMobile,
  getIsTablet,
  useWindowSize,
} from "@songtradr/spa-common/lib/utils";
import getColumns from "src/pages/team/table-data/org-members-columns";
import BaseModal, { ModalTypes } from "src/components/modals/base-modal";
import { IConfirmContentProps } from "src/components/interfaces";
import Content from "src/components/modals/confirmation-modal/content";
import { eApplicationType } from "src/interfaces/auth";
import STLoadingLogo from "src/components/st-loading-logo";
import {
  IOrganisationMemberProps,
  OrgMembersSortType,
  SortOrder,
} from "../../interfaces";
import { teamMemberData } from "../../table-data/org-member-data";
import styles from "../../styles";

interface IProps {
  orgMembers: Array<IOrganisationMemberProps>;
  searchText: string;
  nameSortAscending: boolean;
  handleNameSort: () => void;
  currentSortType: OrgMembersSortType;
  openModalType?: ModalTypes;
  isDeleteMemberModalOpen: boolean;
  setIsDeleteMemberModalOpen: (value: boolean) => void;
  setSortTypeAndOrder: (
    sortType: OrgMembersSortType,
    sortOrder: SortOrder
  ) => void;
  confirmDeleteContentProps: IConfirmContentProps;
  setCurrentTab: (value: string) => void;
  setSelectedOrgMember: React.Dispatch<
    React.SetStateAction<IOrganisationMemberProps | undefined>
  >;
  isLoading: boolean;
  nameTitleReplacement?: string;
  handleAddApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>;
  handleRemoveApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>;
  handleAddAndRemoveApplication: (
    member: IOrganisationMemberProps,
    addApplication: eApplicationType,
    removeApplication: eApplicationType
  ) => Promise<void>;
  allApplications: eApplicationType[];
}

const OrgAgencyTable = ({
  orgMembers,
  nameSortAscending,
  handleNameSort,
  currentSortType,
  openModalType,
  isDeleteMemberModalOpen,
  setIsDeleteMemberModalOpen,
  setCurrentTab,
  setSelectedOrgMember,
  setSortTypeAndOrder,
  confirmDeleteContentProps,
  isLoading,
  nameTitleReplacement,
  handleAddApplication,
  handleRemoveApplication,
  handleAddAndRemoveApplication,
  allApplications,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  const handleRowClick = (id: string) => {
    const portalUser = orgMembers.find((a) => a.id === id);
    setSelectedOrgMember(portalUser);
  };

  useEffect(() => {
    setSortTypeAndOrder(OrgMembersSortType.LastLogin, SortOrder.Descending);
  }, [setSortTypeAndOrder]);

  const handleDeleteButtonClick = (orgMember: IOrganisationMemberProps) => {
    setIsDeleteMemberModalOpen(true);
    setSelectedOrgMember(orgMember);
  };

  useWindowSize();
  const isTablet = getIsTablet();
  const isMobile = getIsMobile();

  const dataSource = teamMemberData(
    orgMembers,
    isMobile,
    handleRowClick,
    handleDeleteButtonClick,
    handleAddApplication,
    handleRemoveApplication,
    handleAddAndRemoveApplication,
    allApplications
  );

  const columns = getColumns(
    isTablet,
    isMobile,
    nameSortAscending,
    false,
    handleNameSort,
    () => {},
    currentSortType,
    t,
    nameTitleReplacement
  );

  const brandsData: any = [];
  orgMembers.forEach((members, org) => {
    brandsData.push({ members, org });
  });

  const showSpinner = useMemo(() => isLoading && dataSource.length === 0, [
    dataSource.length,
    isLoading,
  ]);

  const emptyText = useMemo(
    () =>
      showSpinner ? <Fragment /> : t("manageTeam##members##No members found"),
    [showSpinner, t]
  );

  return (
    <Fragment>
      <Table
        onRow={(record) => {
          return {
            onClick: () => {
              setCurrentTab("user-profile");
              handleRowClick(record.key);
            },
          };
        }}
        dataSource={dataSource}
        columns={columns}
        css={styles.table}
        data-testid="team-members-table"
        loading={{
          spinning: showSpinner,
          indicator: <STLoadingLogo />,
        }}
        locale={{ emptyText }}
      />
      {openModalType === ModalTypes.deleteOrganisationMember &&
        confirmDeleteContentProps && (
          <BaseModal
            visible={isDeleteMemberModalOpen}
            content={<Content {...confirmDeleteContentProps} />}
            buttons={[]}
            onClose={() => setIsDeleteMemberModalOpen(false)}
          />
        )}
    </Fragment>
  );
};

export default OrgAgencyTable;
