import config from "src/config";
import { Subscriptions } from "src/constants";

const getDefaultPlaylistsUrl = (orgId?: string, isAbsoluteUrl = true) => {
  const baseUrl = isAbsoluteUrl
    ? `${Subscriptions.Library.path}/playlists`
    : "/playlists";

  let digitalSubscriptionUrl;
  switch (orgId) {
    case config.selectHub.doveOrgId:
    case config.selectHub.sisleyOrgId:
    case config.selectHub.hogarthOrgId:
    case config.selectHub.nespressoOrgId:
    case config.selectHub.reckittOrgId:
    case config.selectHub.niveaOrgId:
    case config.selectHub.zalandoOrgId:
    case config.selectHub.kauflandOrgId:
    case config.selectHub.heinekenOrgId:
      digitalSubscriptionUrl = `${baseUrl}/personalized`;
      break;

    default:
      digitalSubscriptionUrl = `${baseUrl}/all-playlists`;
      break;
  }

  return digitalSubscriptionUrl;
};

export default getDefaultPlaylistsUrl;
