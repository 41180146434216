import React, { useState, useEffect, useMemo, Fragment } from "react";
import { Table } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import useAuth from "src/auth/use-auth";
import axios from "axios";
import { approveInvite, denyInvite } from "src/api/app-invites";
import { format } from "date-fns";
import BaseModal from "src/components/modals/base-modal";
import modalStyles from "src/components/modals/styles";
import { Button, CloseIcon } from "@songtradr/component-library";
import { errorToast, successToast } from "src/components/toast-notification";
import STLoadingLogo from "src/components/st-loading-logo";
import styles from "./styles";
import tableStyles from "../../styles";
import ApproveIcon from "../passcodes/icons/approveIcon";
import DenyIcon from "../passcodes/icons/denyIcon";
import { IAppInvite } from "../passcodes/interfaces";

interface IProps {
  tableData?: IAppInvite[];
  onGetData: () => void;
}

const PendingInvitesTable = ({ tableData, onGetData }: IProps) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const { organisationId, getAccessToken } = useAuth();
  const [approveModalVisible, setApproveModalVisible] = useState<boolean>(
    false
  );
  const [denyModalVisible, setDenyModalVisible] = useState<boolean>(false);
  const [selectedInviteId, setSelectedInviteId] = useState<string>("");
  const [isMultiSelect, setIsMultiSelect] = useState<boolean>(false);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const clearMultiSelect = () => {
    setIsMultiSelect(false);
    setSelectedRowKeys([]);
  };

  const approveUser = async () => {
    const accessToken = getAccessToken();
    const inviteIds =
      selectedRowKeys.length > 0 ? selectedRowKeys : [selectedInviteId];

    if (!organisationId) {
      return;
    }

    try {
      await approveInvite(accessToken, organisationId, inviteIds);
      setApproveModalVisible(false);
      successToast({ message: "User approved" });
      onGetData();
      if (isMultiSelect) {
        clearMultiSelect();
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        errorToast({ message: "There was an error approving this user" });
      }
    }
  };

  const denyUser = async () => {
    const accessToken = getAccessToken();
    const inviteIds =
      selectedRowKeys.length > 0 ? selectedRowKeys : [selectedInviteId];

    if (!organisationId) {
      return;
    }

    try {
      await denyInvite(accessToken, organisationId, inviteIds);
      setDenyModalVisible(false);
      successToast({ message: "User denied" });
      onGetData();
      if (isMultiSelect) {
        clearMultiSelect();
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        errorToast({ message: "There was an error denying this user" });
      }
    }
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Passcode name",
      dataIndex: "passcodeName",
      key: "passcodeName",
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      key: "permissions",
    },
    {
      title: "Date accessed",
      dataIndex: "sentDate",
      key: "sentDate",
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
    },
  ];

  const handleApprove = async (id: string) => {
    setApproveModalVisible(true);
    setSelectedInviteId(id);
  };

  const handleDeny = async (id: string) => {
    setDenyModalVisible(true);
    setSelectedInviteId(id);
  };

  const formatPendingTableData = (pendingData: IAppInvite[]) => {
    return pendingData.map((item) => {
      return {
        key: item.id,
        userName: `${item.firstName} ${item.lastName}`,
        passcodeName: item.passcode.name,
        permissions: item.passcode.applications.join(", "),
        sentDate: format(new Date(item.sentDate), "MMM dd yyy"),
        createdBy: item.passcode.createdByName,
        action: (
          <div className="actions">
            <button
              data-testid="approve-pass-code-invite"
              type="button"
              onClick={() => handleApprove(item.id)}
            >
              <ApproveIcon />
            </button>
            <button
              data-testid="deny-pass-code-invite"
              type="button"
              onClick={() => handleDeny(item.id)}
            >
              <DenyIcon />
            </button>
          </div>
        ),
      };
    });
  };

  useEffect(() => {
    if (selectedRowKeys.length > 1) {
      setIsMultiSelect(true);
    } else {
      setIsMultiSelect(false);
    }
  }, [selectedRowKeys]);

  const showSpinner = useMemo(() => !tableData, [tableData]);

  const emptyText = useMemo(
    () => (showSpinner ? <Fragment /> : "No pending members found."),
    [showSpinner]
  );

  return (
    <div css={styles.container}>
      <Table
        data-testid="pass-code-table"
        css={tableStyles.table}
        rowSelection={rowSelection}
        dataSource={formatPendingTableData(tableData ?? [])}
        columns={columns}
        loading={{
          spinning: showSpinner,
          indicator: <STLoadingLogo />,
        }}
        locale={{ emptyText }}
      />
      <BaseModal
        buttons={[]}
        visible={approveModalVisible}
        onClose={() => setApproveModalVisible(false)}
        content={
          <div css={styles.modal}>
            <h2>Approve user</h2>
            <p>
              You are about to approve this user to access your Studio platform.
              Are you sure?
            </p>
            <div css={modalStyles.footer}>
              <Button
                type="button"
                onClick={() => setApproveModalVisible(false)}
                className="confirmationModalCancelButton"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="confirmationModalConfirmButton"
                onClick={approveUser}
                data-testid="approve-user"
              >
                Approve user
              </Button>
            </div>
          </div>
        }
      />
      <BaseModal
        buttons={[]}
        visible={denyModalVisible}
        onClose={() => setDenyModalVisible(false)}
        content={
          <div css={styles.modal}>
            <h2>Deny user</h2>
            <p>
              You are about to deny this user access to your Studio platform.
              Are you sure?
            </p>
            <div css={modalStyles.footer}>
              <Button
                type="button"
                onClick={() => setDenyModalVisible(false)}
                className="confirmationModalCancelButton"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="confirmationModalConfirmButton"
                data-testid="deny-user"
                onClick={denyUser}
              >
                Deny user
              </Button>
            </div>
          </div>
        }
      />
      {isMultiSelect && (
        <div css={styles.multiSelect}>
          <span>{selectedRowKeys.length} users selected.</span>
          <Button
            type="button"
            className="approve-button"
            onClick={approveUser}
          >
            <ApproveIcon /> Approve all
          </Button>
          <Button type="button" className="deny-button" onClick={denyUser}>
            <DenyIcon /> Deny all
          </Button>
          <CloseIcon className="close-icon" onClick={clearMultiSelect} />
        </div>
      )}
    </div>
  );
};

export default PendingInvitesTable;
