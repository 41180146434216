import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Form, Input } from "antd";
import orgDashboardStyles from "src/pages/org-dashboard/styles";
import { errorToast, successToast } from "src/components/toast-notification";
import updateOrganisation from "src/api/organisation/update-organisation";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import checkFormAndShowNotification, {
  FormNotificationErrors,
} from "src/utils/organisation-dashboard-validation";
import useAuth from "src/auth/use-auth";
import { Button } from "@songtradr/component-library";
import axios from "axios";
import { IProps } from "./interfaces";
import styles from "../../styles";

interface IAccountManagerForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

const colSpan = { span: 24 };

const AccountManagerSection = ({
  org,
  refreshData,
}: IProps): ReactElement | null => {
  const [form] = Form.useForm();
  const { getAccessToken } = useAuth();

  const handleFailedSubmit = useCallback((error: ValidateErrorEntity) => {
    checkFormAndShowNotification(error);
  }, []);

  const isAdminFieldFilledIn = () =>
    form.getFieldValue("firstName") ||
    form.getFieldValue("lastName") ||
    form.getFieldValue("email") ||
    form.getFieldValue("phone");

  const [isAdminInfoRequired, setIsAdminInfoRequired] = useState(false);

  const onAdminInfoChange = () => {
    setIsAdminInfoRequired(isAdminFieldFilledIn());
  };

  // Trigger validation check, so they're picked up by ant's form validation.
  // Can't do it in state initialisation because ant's validation object isn't
  // ready yet
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(onAdminInfoChange, []);

  if (!org) {
    return null;
  }

  const onFormSubmit = async (values: IAccountManagerForm) => {
    const accessToken = getAccessToken();
    const orgId = org.id;

    if (!orgId) {
      errorToast({ message: "Can't update org without a valid org ID" });
      return;
    }

    try {
      await updateOrganisation(orgId, accessToken, {
        ...org,
        contact: {
          ...values,
        },
      });
      successToast({ message: "Organization successfully saved." });
      refreshData();
    } catch (error) {
      if (axios.isAxiosError(error)) {
        errorToast({ message: error.message });
      }
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFormSubmit}
      onFinishFailed={handleFailedSubmit}
    >
      <h2 css={styles.subHeading}>Account manager</h2>
      <div
        css={orgDashboardStyles.detailsContainer}
        data-testid="account-manager-section"
      >
        <Form.Item
          name="firstName"
          label="First name"
          labelCol={colSpan}
          wrapperCol={colSpan}
          rules={[
            {
              required: isAdminInfoRequired,
            },
          ]}
          validateTrigger="onSubmit"
          help={FormNotificationErrors.Required}
          initialValue={org?.contact?.firstName}
        >
          <Input onChange={onAdminInfoChange} aria-label="First name" />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last name"
          labelCol={colSpan}
          wrapperCol={colSpan}
          rules={[
            {
              required: isAdminInfoRequired,
            },
          ]}
          validateTrigger="onSubmit"
          help={FormNotificationErrors.Required}
          initialValue={org?.contact?.lastName}
        >
          <Input onChange={onAdminInfoChange} aria-label="Last name" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          labelCol={colSpan}
          wrapperCol={colSpan}
          rules={[
            {
              required: isAdminInfoRequired || !!org?.contact?.email,
              type: "email",
            },
          ]}
          validateTrigger="onSubmit"
          help={FormNotificationErrors.Email}
          initialValue={org?.contact?.email}
        >
          <Input onChange={onAdminInfoChange} aria-label="Email" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          labelCol={colSpan}
          wrapperCol={colSpan}
          initialValue={org?.contact?.phone}
        >
          <Input onChange={onAdminInfoChange} aria-label="Phone" />
        </Form.Item>
      </div>
      <Form.Item>
        <Button
          css={styles.submitBtn}
          variant="primary"
          type="submit"
          data-testid="submit-btn"
        >
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AccountManagerSection;
