import React, { ReactElement } from "react";
import { IComponentIconProps } from "../interfaces";

const SortASC = ({ fill = "white" }: IComponentIconProps): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.11508 14.3399L7.14818 15.3832C7.30162 15.5382 7.55118 15.5382 7.70463 15.3832L11.7218 11.3263C11.8752 11.1713 12.1248 11.1713 12.2782 11.3263L16.2954 15.3838C16.4488 15.5387 16.6984 15.5387 16.8518 15.3838L17.8849 14.3404C18.0384 14.1855 18.0384 13.9334 17.8849 13.7785L12.2782 8.11623C12.1248 7.96126 11.8752 7.96126 11.7218 8.11623L6.11508 13.7785C5.96164 13.9334 5.96164 14.1855 6.11508 14.3404L6.11508 14.3399Z"
        fill={fill}
      />
    </svg>
  );
};

export default SortASC;
