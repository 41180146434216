export const isValidUrl = (urlString: string) => {
  let url;

  try {
    url = new URL(urlString);
  } catch (e) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};

export const parseDuration = (duration: string) => {
  // Extract hours, minutes, and seconds using regular expressions
  const pattern = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
  const match = duration.match(pattern);

  if (!match) {
    return "";
  }

  const hours = match[1] ? Number(match[1]) : 0;
  const minutes = match[2] ? Number(match[2]) : 0;
  const seconds = match[3] ? Number(match[3]) : 0;

  // Format the duration as a human-readable string
  let durationStr = "";

  if (hours > 0) {
    durationStr += `${hours}h `;
  }

  if (minutes > 0) {
    durationStr += `${minutes}m `;
  }

  if (seconds > 0 || (hours === 0 && minutes === 0 && seconds === 0)) {
    durationStr += `${seconds}s`;
  }

  return durationStr;
};
