import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  headingContainer: css`
    padding: 1rem;
    color: ${theme.colors.white};
    width: 100%;

    @media ${queries.large} {
      margin-top: 128px;
      width: 46%;
    }

    h1 {
      color: ${theme.colors.white};
      font-family: ${theme.fonts.sofiaPro};
      font-style: normal;
      font-weight: 250;
      font-size: 3rem;
      line-height: 3.5rem;
      letter-spacing: -0.02em;
    }
    p {
      color: ${theme.colors.white};
      font-family: ${theme.fonts.sofiaPro};
      font-weight: 300;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  `,
  container: css`
    margin: 1rem 1rem 3rem;
    height: 620px;
    display: flex;

    img {
      object-fit: cover;
      width: 100%;
      height: 350px;
      min-height: 350px;
      border-radius: 20px;
    }

    .description {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      cursor: pointer;
      h2 {
        color: ${theme.colors.white};
        font-family: ${theme.fonts.sofiaPro};
        font-weight: 250;
        font-size: 32px;
        line-height: 40px;
        margin-top: 1.5rem;
      }
      p {
        height: 50px;
        font-family: ${theme.fonts.sofiaPro};
        color: ${theme.colors.white};
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 24.5px;
      }
      button {
        color: ${theme.colors.white};
      }
    }

    .swiper-container {
      height: 100%;
      padding-top: 1rem;

      @media ${queries.medium} {
        padding: 0.5rem;
      }
    }

    .swiper-slide {
      transition: all 0.15s ease-in-out;
      text-align: left;
      width: 100%;
      height: 540px;

      @media ${queries.small} {
        width: 25%;
      }
      @media ${queries.medium} {
        &:hover {
          cursor: pointer;
          transform: scale(1.035);
        }
      }
    }

    .swiper-pagination {
      bottom: 0;

      @media ${queries.medium} {
        display: none;
      }
    }

    .swiper-pagination-bullet {
      background: ${theme.colors.secondary.mediumGrey};
    }

    .swiper-pagination-bullet-active {
      background: ${theme.colors.secondary.waveformGray};
    }
  `,
};
