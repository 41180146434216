import React from "react";
import theme from "src/theme";
import SortButton, {
  sortTypes,
} from "src/components/control-buttons/sort-button";
import { TFunction } from "react-i18next";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import { Subscriptions } from "src/constants";
import style from "../styles";
import { OrgMembersSortType } from "../interfaces";

export default (
  isTablet: boolean,
  isMobile: boolean,
  nameSortAscending: boolean,
  dateSort: boolean,
  handleNameSort: () => void,
  handleDateSort: () => void,
  currentSortType: OrgMembersSortType,
  t: TFunction<string>,
  nameTitleReplacement?: string
): Array<IColumnsData> => {
  const columns = [
    {
      title: (
        <div css={style.titleContainer}>
          <span>{nameTitleReplacement ?? t("manageTeam##members##Name")}</span>
          <SortButton
            tooltipText={nameSortAscending ? t("Sort A-Z") : t("Sort Z-A")}
            css={style.sortButton}
            dataTestId="team-name-sort-button"
            sortType={nameSortAscending ? sortTypes.A_Z : sortTypes.Z_A}
            onClick={handleNameSort}
            {...(currentSortType !== OrgMembersSortType.Name && {
              fillColor: theme.colors.background.lightGray,
            })}
          />
        </div>
      ),
      dataIndex: "name",
      key: "key",
      width: "20%",
    },
    {
      title: (
        <div
          css={[style.titleContainer, style.teamEnterpriseAdminCol, style.date]}
        >
          <span>{t("manageTeam##members##Date Added")}</span>
          <SortButton
            tooltipText={dateSort ? "Sort Ascending" : "Sort Descending"}
            css={style.sortButton}
            dataTestId="team-name-sort-button"
            sortType={dateSort ? sortTypes.A_Z : sortTypes.Z_A}
            onClick={handleDateSort}
            {...(currentSortType !== OrgMembersSortType.CreatedDate && {
              fillColor: theme.colors.background.lightGray,
            })}
          />
        </div>
      ),
      dataIndex: "dateAdded",
      key: "key",
      width: "15%",
    },
    {
      title: (
        <div css={[style.titleContainer, style.teamEnterpriseAdminCol]}>
          <span>{t("manageTeam##members##Admin")}</span>
        </div>
      ),
      dataIndex: "isAdmin",
      key: "key",
      width: "10%",
    },
    {
      title: (
        <div css={[style.titleContainer, style.titleAdminCol]}>
          <span>{`${Subscriptions.Library.name}`}</span>
        </div>
      ),
      dataIndex: "hasMusicSubscription",
      key: "key",
      width: "10%",
    },
    {
      title: (
        <div css={[style.titleContainer, style.titleAdminCol]}>
          <span>{Subscriptions.ReadOnlyLibrary.name}</span>
        </div>
      ),
      dataIndex: "hasReadOnlyLibraryPermissions",
      key: "key",
      width: "10%",
    },
    {
      title: (
        <div css={[style.titleContainer, style.titleAdminCol]}>
          <span>{Subscriptions.Projects.name}</span>
        </div>
      ),
      dataIndex: "hasProjectsSubscription",
      key: "key",
      width: "10%",
    },
    {
      title: (
        <div css={[style.titleContainer, style.titleAdminCol]}>
          <span>{Subscriptions.MyContent.name}</span>
        </div>
      ),
      dataIndex: "hasMyContentPermissions",
      key: "key",
      width: "10%",
    },
    {
      title: (
        <div css={[style.titleContainer, style.titleAdminCol]}>
          <span>{Subscriptions.Validate.name}</span>
        </div>
      ),
      dataIndex: "hasValidatePermissions",
      key: "key",
      width: "10%",
    },
    {
      title: <div css={[style.titleContainer, style.titleAdminCol]} />,
      dataIndex: "delete",
      key: "key",
      width: "10%",
    },
  ];

  return columns;
};
