export const grecaptchaKey = "6LeZoY0fAAAAAILHLwcCZGRZgdfGqseHXIOIY3wA";

export const hubspotFormId = "6bbc6ad3-e050-4f19-8d55-89a70e08ce78";

const heapAnalyticsDomains = [
  "https://cdn.heapanalytics.com",
  "https://heapanalytics.com",
];

const wavAudioBucketUrl =
  "https://songtradr-data.s3-us-west-1.amazonaws.com/UserFiles/OriginalFiles/";

const supportEmail = "select-support@songtradr.com";

/*
  We need to set correct domain for cognito cookie storage to work correctly. 
*/
const generateDevEnv = () => {
  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    return "localhost";
  }

  if (window.location.hostname === "host.docker.internal") {
    return "host.docker.internal";
  }

  return "songtradr.dev";
};

const isCognitoSecure = () =>
  window.location.hostname !== "host.docker.internal";

export const buildEnvUrl = (subdomainPrefix?: string, baseUrl?: string) => {
  if (baseUrl) {
    return baseUrl;
  }

  if (subdomainPrefix) {
    return `${window.location.protocol}//${subdomainPrefix}.${window.location.host}`;
  }

  return `${window.location.protocol}//${window.location.host}`;
};

const config = {
  production: {
    production: true,
    analytics: {
      heapAppID: "874137444",
      heapDomains: heapAnalyticsDomains,
    },
    assetsUrl: "https://assets.songtradr.net",
    auth0: {
      domain: "identity.songtradr.com",
      clientId: "jvAfEIhhdYGPMIjfZXPt43YoHm6p3qxe",
      audience: "songtradr-client-api",
      appName: "portal-spa",
      dbConnection: "portal-app-connection",
    },
    cognito: {
      userPoolId: "us-east-1_ohzpKwsbF",
      clientId: "3jv2k529skmgpgod3ve5v405v9",
      domain: "songtradr.com",
      isSecure: isCognitoSecure(),
    },
    openExchangeRateKey: {
      key: "6296876378fb4e438e3e50033854fcd5",
    },
    organisationSubscriptionsService: {
      url: "https://api.songtradr.net/org",
    },
    songCollectionService: {
      url: "https://api.songtradr.net/collections",
    },
    projectManagementService: {
      url: "https://api.songtradr.net/project-management",
    },
    searchService: {
      url: "https://search.api.prod1.songtradr.net",
    },
    songService: {
      url: "https://api.songtradr.net/music/songs/",
    },
    songCDNService: {
      url: "https://api-cdn.songtradr.net",
    },
    brandAudit: {
      url: "https://api.songtradr.net/brand-audit",
    },
    musicInsightsProxy: {
      url: "https://api.songtradr.net/song-insights-proxy",
    },
    userInteractionService: {
      url: "https://api.songtradr.net/user-interactions",
    },
    userMetaDataService: {
      url: "https://api.songtradr.net/user-metadata/v2",
    },
    marketplaceService: {
      url: "https://wapi.songtradr.com/web/v3/public",
    },
    player: {
      subdomainPrefix: "player",
    },
    support: {
      url: "https://studio-help.songtradr.com",
    },
    assetsSpa: {
      subdomainPrefix: "assets",
    },
    aimsProxyService: {
      url: "https://api.songtradr.net/aims-proxy",
    },
    playerApiBase: {
      url: "https://player-api.songtradr.com/enterprise",
    },
    oldPlayerOrgIds: [
      "62c2b97fe714848a99d7bc28", // Zalando
      "62d704a9b1166976d1123f2f", // Kaufland
      "62cd3dae4f230fe5a2fdb9ff", // Heineken international
    ],
    externalResources: {
      // TODO: Placeholder URLs - these need replaced
      termsOfService: "https://www.songtradr.com/legals/termsofuse",
      registrationLearnMore:
        "https://support.songtradr.com/hc/en-us/articles/115005436448-How-do-I-sign-up-",
      marriotPlatformWalkthrough:
        "https://www.canva.com/design/DAFSujaPr28/pcxVu9t8nuMXwqjVGdRWvw/view#1",
    },
    requiresTermsCheckbox: {
      orgId: "62cdc8a24f230fe5a2fdbfc3",
    },
    dataDogToken: "pub4b37dc80da1a08d288af1ce3daef9fdb",
    // TODO: This may need to be changed depending on final prod url e.g. "/commerciallicenses" if base url is "www.vinyl.com/commerciallicenses"
    basePathname: "/",
    wavAudioBucketUrl,
    webHookUrl:
      "https://webhook-events.api.prod1.songtradr.net/webhooks/events",
    selectHub: {
      doveOrgId: "615b2c2a7e654b8529266946",
      unileverOrgId: "615b2a016f7a1b156e7a3b95",
      sisleyOrgId: "61e8103dd77497e8c2b67c2a",
      hogarthOrgId: "62474a158457d01a0785b0a2",
      nespressoOrgId: "6274e3cbad5b47610d0d6ad3",
      reckittOrgId: "62bddf5be714848a99d7ab1f",
      niveaOrgId: "62c2b5940e378a31e2c57a0d",
      zalandoOrgId: "62c2b97fe714848a99d7bc28",
      kauflandOrgId: "62d704a9b1166976d1123f2f",
      heinekenOrgId: "62cd3dae4f230fe5a2fdb9ff",
    },
    supportEmail,
    stripePublishableKey:
      "pk_live_51J8NoWL3ht73cDqLCS3hgY25MUB81LB8y34af0dPqdJEKeh0VODFtFocLdjNB4Nhnfe9k41W0TrF3El6DTzDu3mD00eGziOMUL",
    products: {
      starter: {
        yearly: "price_1JUr1gL3ht73cDqLEzk6ZjrO",
        monthly: "price_1JUr1XL3ht73cDqLaLVoagkg",
      },
    },
    featureToggles: {
      similarSearch: false,
      priceEstimator: ["62474a158457d01a0785b0a2"],
      showComingSoon: false,
      showValidateFeature: true,
    },
    servicingOrganisations: [
      { label: "Big Sync Music", value: "62726e0b9957a241f422c5a3" },
      { label: "Massive Music", value: "6239ed7f97569b41e1dfb994" },
      { label: "Songtradr", value: "6315c04cc80bc8cf4fa3be2a" },
      { label: "1107", value: "631f0ac2df578bb9ea37e2a4" },
    ],
    videoSubscriptionCode: "VMh5uA6u8fqx",
    defaultProjectsSupportEmail:
      "declan.lee+enterprise-musicrequest@songtradr.com; nick.woollard+enterprise-musicrequest@songtradr.com",
  },
  staging: {
    staging: true,
    analytics: {
      heapAppID: "259303604",
      heapDomains: heapAnalyticsDomains,
    },
    assetsUrl: "https://assets.staging-2.songtradr.dev",
    auth0: {
      domain: "identity.staging-2.songtradr.dev",
      clientId: "cyDnUPmBrfHfgE0bOgUj3LHcRmnKWzE8",
      audience: "songtradr-client-api",
      appName: "portal-spa",
      dbConnection: "portal-app-connection",
    },
    cognito: {
      userPoolId: "us-east-1_WFhdIb0np",
      clientId: "6dh8lcq3hvdup914juf4hg0oo1",
      domain: "songtradr.dev",
      isSecure: isCognitoSecure(),
    },
    openExchangeRateKey: {
      key: "b62cb733dcab4199877ffeb38350ed79",
    },
    aimsProxyService: {
      url: "https://api.staging-2.songtradr.dev/aims-proxy",
    },
    recaptcha: {
      // TODO: temporarily using development key
      key: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    },
    organisationSubscriptionsService: {
      url: "https://api.staging-2.songtradr.dev/org",
    },
    projectManagementService: {
      url: "https://api.staging-2.songtradr.dev/project-management",
    },
    songService: {
      url: "https://api.staging-2.songtradr.dev/music/songs/",
    },
    songCollectionService: {
      url: "https://api.staging-2.songtradr.dev/collections",
    },
    searchService: {
      url: "https://search.api.staging-2.songtradr.dev",
    },
    songCDNService: {
      url: "https://api-cdn.staging-2.songtradr.dev",
    },
    userInteractionService: {
      url: "https://api.staging-2.songtradr.dev/user-interactions",
    },
    userMetaDataService: {
      url: "https://api.staging-2.songtradr.dev/user-metadata/v2",
    },
    brandAudit: {
      url: "https://api.staging-2.songtradr.dev/brand-audit",
    },
    musicInsightsProxy: {
      url: "https://api.staging-2.songtradr.dev/song-insights-proxy",
    },
    marketplaceService: {
      url: "https://wapi.songtradr.com/web/v3/public",
    },
    player: {
      subdomainPrefix: "player",
      // baseUrl: "https://player.portal.staging-2.songtradr.dev",
    },
    support: {
      url: "https://studio-help.songtradr.com",
    },
    assetsSpa: {
      subdomainPrefix: "assets",
      // baseUrl: "https://assets.portal.staging-2.songtradr.dev",
    },
    playerApiBase: {
      url: "https://staging-player-api.songtradr.dev/enterprise",
    },
    oldPlayerOrgIds: [
      "633c38b62f78748dabd8e0ea", // justine.bateman+qa-old-player-enterprise-admin@songtradr.com
    ],
    externalResources: {
      // TODO: Placeholder URLs - these need replaced
      termsOfService: "https://www.songtradr.com/legals/termsofuse",
      registrationLearnMore:
        "https://support.songtradr.com/hc/en-us/articles/115005436448-How-do-I-sign-up-",
      marriotPlatformWalkthrough:
        "https://www.canva.com/design/DAFSujaPr28/pcxVu9t8nuMXwqjVGdRWvw/view#1",
    },
    dataDogToken: "pub4b37dc80da1a08d288af1ce3daef9fdb",
    basePathname: "/",
    wavAudioBucketUrl,
    requiresTermsCheckbox: {
      orgId: "62557b0d512adf1aae7c8905",
    },
    webHookUrl:
      "https://webhook-events.api.staging-2.songtradr.dev/webhooks/events",
    selectHub: {
      doveOrgId: "615d9da59c698208c8704504",
      unileverOrgId: "61951d7ee3408b311f8ab254",
      sisleyOrgId: "62307197ff0659644603ee04",
      hogarthOrgId: "624b1aff4159e8f626979868",
      nespressoOrgId: "6278ec28db20a06f146ffa95",
      reckittOrgId: "62bddf5be714848a99d7ab1f",
      niveaOrgId: "TODO",
      zalandoOrgId: "TODO",
      kauflandOrgId: "TODO",
      heinekenOrgId: "TODO",
    },
    supportEmail,
    stripePublishableKey:
      "pk_test_51J8NFzJ8IyZFlraVIGwmeTtyWUGnYl6DwExN8VH8ADGIGvkaOfFI2HfsGptbFw0tCro0bFkZAEplJHvzvN5QyXLe00V3c1Ltyy",
    products: {
      starter: {
        yearly: "price_1JUqyaJ8IyZFlraVw7NZuwnb",
        monthly: "price_1JUqyQJ8IyZFlraVWafAURrS",
      },
    },
    featureToggles: {
      similarSearch: true,
      priceEstimator: ["6253f49e780aa9b4e8798e54"],
      showComingSoon: false,
      showValidateFeature: true,
    },
    servicingOrganisations: [
      {
        value: "622b38b209673127f4d48de2",
        label: "Internal Test Organisation",
      },
      { value: "62bc7f86d9345b1a9e6c1548", label: "QA Internal Service Org" },
      { label: "Big Sync - Staging", value: "65313eb327646d9ba77050c9" },
    ],
    videoSubscriptionCode: "VMh5uA6u8fqx",
    defaultProjectsSupportEmail:
      "justine.bateman+staging-music-request@songtradr.com",
  },
  development: {
    development: true,
    analytics: {
      heapAppID: "259303604",
      heapDomains: heapAnalyticsDomains,
    },
    assetsUrl: "https://assets.dev.songtradr.dev",
    auth0: {
      domain: "identity.dev.songtradr.dev",
      clientId: "C8McAbjljJBXI84y0otFkMvAOz1Bq0a5",
      audience: "songtradr-client-api",
      appName: "portal-spa",
      dbConnection: "portal-app-connection",
    },
    cognito: {
      userPoolId: "eu-west-1_mTtikScyR",
      clientId: "7p93i8nrk5qo533phdd60u68uq",
      domain: generateDevEnv(),
      isSecure: isCognitoSecure(),
    },
    openExchangeRateKey: {
      key: "b62cb733dcab4199877ffeb38350ed79",
    },
    recaptcha: {
      key: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
    },
    brandAudit: {
      url: "https://api.dev.songtradr.dev/brand-audit",
    },
    aimsProxyService: {
      url: "https://api.dev.songtradr.dev/aims-proxy",
    },
    organisationSubscriptionsService: {
      url: "https://api.dev.songtradr.dev/org",
    },
    projectManagementService: {
      url: process.env.REACT_APP_LOCAL_SERVICE
        ? "http://localhost:5000/project-management"
        : "https://api.dev.songtradr.dev/project-management",
    },
    userInteractionService: {
      url: "https://api.dev.songtradr.dev/user-interactions",
    },
    userMetaDataService: {
      url: process.env.REACT_APP_LOCAL_SERVICE
        ? "http://localhost:5000/user-metadata"
        : "https://api.dev.songtradr.dev/user-metadata/v2",
    },
    searchService: {
      url: "https://search.api.dev.songtradr.dev",
    },
    songService: {
      url: "https://api.dev.songtradr.dev/music/songs/",
    },
    songCDNService: {
      url: "https://api-cdn.dev.songtradr.dev",
    },
    songCollectionService: {
      url: "https://api.dev.songtradr.dev/collections",
    },
    musicInsightsProxy: {
      url: "https://api.dev.songtradr.dev/song-insights-proxy",
    },
    marketplaceService: {
      url: "https://wapi.songtradr.com/web/v3/public",
    },
    player: {
      // subdomainPrefix: "player",
      baseUrl: "https://player.portal.dev.songtradr.dev",
    },
    support: {
      url: "https://studio-help.songtradr.com",
    },
    assetsSpa: {
      // subdomainPrefix: "assets",
      baseUrl: "https://assets.portal.dev.songtradr.dev",
    },
    playerApiBase: {
      url: "https://staging-player-api.songtradr.dev/enterprise",
    },
    oldPlayerOrgIds: [
      "622b687910693a0178d410d1", // justine.bateman+e2e-prtl-enterprise-admin@songtradr.com
      "633c39094234b54d5a8b293f", // justine.bateman+qa-old-player-enterprise-admin@songtradr.com
    ],
    externalResources: {
      // TODO: Placeholder URLs - these need replaced
      termsOfService: "https://www.songtradr.com/legals/termsofuse",
      registrationLearnMore:
        "https://support.songtradr.com/hc/en-us/articles/115005436448-How-do-I-sign-up-",
      marriotPlatformWalkthrough:
        "https://www.canva.com/design/DAFSujaPr28/pcxVu9t8nuMXwqjVGdRWvw/view#7",
    },
    dataDogToken: "pub4b37dc80da1a08d288af1ce3daef9fdb",
    basePathname: "/",
    wavAudioBucketUrl,
    requiresTermsCheckbox: {
      orgId: "62386ad79c29abf314a476d7",
    },
    webHookUrl: "https://webhook-events.api.dev.songtradr.dev/webhooks/events",
    selectHub: {
      doveOrgId: "61950d83e341070993d3ac8a",
      unileverOrgId: "61409f5faf34af4525c5e750",
      sisleyOrgId: "62306e191d742c4f0e955f83",
      hogarthOrgId: "624b0abcfc1c7077ef72932e",
      nespressoOrgId: "6278ebe8327e6ae651139d03",
      reckittOrgId: "62bddf5be714848a99d7ab1f",
      niveaOrgId: "62d02a3c4577a88961ba1d4c",
      zalandoOrgId: "TODO",
      kauflandOrgId: "TODO",
      heinekenOrgId: "TODO",
    },
    supportEmail,
    stripePublishableKey:
      "pk_test_51J04UZK2dtxtJJU4dR2OYk9nlz5VjEKWmJR6FugUVeJh808U7jMa31t7vxPduuS5GgdFPvqX2rWibU0RGSTDEZPm00QDhXBGpE",
    products: {
      starter: {
        yearly: "price_1JUqAsK2dtxtJJU4fzonKC54",
        monthly: "price_1JUqCqK2dtxtJJU4RqF03lzi",
      },
    },
    servicingOrganisations: [
      {
        label: "Internal Test Organisation",
        value: "6225e4b38257d45b5bc06068",
      },
      {
        label: "Alison Internal Test Organisation",
        value: "626164636c29d7dab3654d0d",
      },
      { label: "QA Internal Test Org 2", value: "62bc319da408dcc601e11c41" },
      { label: "Big Sync - Development", value: "6531249d241a7b1c44b413b8" },
    ],
    featureToggles: {
      similarSearch: true,
      priceEstimator: ["6253f49e780aa9b4e8798e54"],
      showComingSoon: false,
      showValidateFeature: true,
    },
    videoSubscriptionCode: "VMh5uA6u8fqx",
    defaultProjectsSupportEmail:
      "justine.bateman+dev-music-request-one@songtradr.com; justine.bateman+dev-music-request-two@songtradr.com",
  },
};

export default config[process.env.REACT_APP_SETTINGS] || config.development;
