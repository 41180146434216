import React, { ReactElement, useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import arrowsIconAnimation from "src/app/assets/animations/arrows_plus_button.json";
import STButton from "../../../STButton";
import styles from "./styles";

interface IProps {
  title: string;
  description: string;
  onClick: () => void;
  linkText?: string;
  imageSrc: string;
}

const CaseStudyCard = ({
  title,
  onClick,
  description,
  imageSrc,
  linkText = "Coming soon",
}: IProps): ReactElement => {
  const [play, setPlay] = useState(false);
  const [loop, setLoop] = useState(false);
  const [direction, setDirection] = useState<AnimationDirection>(1);

  type AnimationDirection = 1 | -1;

  const mouseEnterActions = () => {
    setDirection(1);
    setPlay(true);
    setLoop(true);
  };

  useEffect(() => {
    if (loop) {
      setLoop(false);
    }
  }, [loop]);

  const mouseLeaveActions = () => {
    setPlay(true);
    setLoop(true);
    setDirection(-1);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      className="description"
      onMouseEnter={mouseEnterActions}
      onMouseLeave={mouseLeaveActions}
      onClick={onClick}
      onKeyDown={onClick}
    >
      <img src={imageSrc} alt="" />
      <h2>{title}</h2>
      <p>{description}</p>
      <div css={styles.buttonContainer}>
        <STButton theme="orange" text={linkText} />
        <Lottie
          style={{ height: "32px", width: "32px", cssFloat: "right" }}
          animationData={arrowsIconAnimation}
          direction={direction}
          loop={loop}
          play={play}
        />
      </div>
    </div>
  );
};

export default CaseStudyCard;
