import React, { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "./interfaces";

const NextIcon = ({
  height = theme.svgSize.medium,
  width = theme.svgSize.medium,
  fill = theme.colors.white,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.54683 4.15345L7.15572 5.5309C6.9491 5.7355 6.9491 6.06825 7.15572 6.27284L12.565 11.629C12.7716 11.8336 12.7716 12.1664 12.565 12.371L7.15497 17.7272C6.94834 17.9318 6.94834 18.2645 7.15497 18.4691L8.54607 19.8466C8.7527 20.0511 9.08874 20.0511 9.29536 19.8466L16.845 12.371C17.0517 12.1664 17.0517 11.8336 16.845 11.629L9.29536 4.15345C9.08874 3.94885 8.7527 3.94885 8.54607 4.15345L8.54683 4.15345Z"
        fill={fill}
      />
    </svg>
  );
};

export default NextIcon;
