import { ReactElement } from "react";

const DashIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="12" cy="12" r="11.5" fill="#141414" stroke="#555555" />
      <rect x="6" y="10.5" width="12" height="3" rx="0.5" fill="white" />
    </svg>
  );
};
export default DashIcon;
