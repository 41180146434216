import axios, { AxiosResponse } from "axios";
import config from "src/config";
import { getAuthHeader, getAxiosOptions } from "../../headers";

export interface IProductInformationFormData {
  ProductInformation: File | null;
}

const saveProductInformation = async (
  orgId: string,
  accessToken: string,
  data: IProductInformationFormData
): Promise<AxiosResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken) },
    ...getAxiosOptions(),
  };
  const url = `${config.projectManagementService.url}/organisations/${orgId}/product-information`;

  const response = await axios.post(url, data, options);
  return response;
};

export default saveProductInformation;
