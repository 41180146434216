import {
  IFeeSplitDetailState,
  INameLinkState,
  IProjectAttachmentsUpdate,
  IProjectForm,
  IProjectMusicSection,
  IFinalTrackOwnership,
} from "src/pages/projects/client-project-view/interfaces";
import { createContext } from "react";
import { PickKeys } from "ts-essentials";

export interface IProjectProviderContext {
  isLoading: boolean;
  projectFound: boolean;
  serverErrorOccurred: boolean;
  storeProject: (project: IProjectForm) => void;
  storeAttachments: (attachmentUpdate: IProjectAttachmentsUpdate) => void;
  addNameLinkRowForArray: (
    arrayName: PickKeys<IProjectMusicSection, Array<INameLinkState>>
  ) => void;
  addFinalTrackLinksRow: () => void;
  addFeeRowForArray: (
    arrayName: PickKeys<IFinalTrackOwnership, Array<IFeeSplitDetailState>>
  ) => void;
  project: IProjectForm;
}

export default createContext<IProjectProviderContext>({
  isLoading: true,
  projectFound: true,
  serverErrorOccurred: false,
  storeProject: () => {},
  storeAttachments: () => {},
  addNameLinkRowForArray: () => {},
  addFinalTrackLinksRow: () => {},
  addFeeRowForArray: () => {},
  project: {
    name: "",
    id: "",
    attachments: [],
    purchaseOrderAttachments: [],
    estimateAttachments: [],
    licenseAttachments: [],
    invoiceAttachments: [],
    miscAttachments: [],
    clientsVersion: 0,
    sequenceNo: 0,
    clientReferenceNo: "",
    music: {
      finalTrack: {
        masterFee: { currency: "", value: 0 },
        masterFeeSplitsVersion: 0,
        masterFeeSplits: [],
        publisherFee: { currency: "", value: 0 },
        publisherFeeSplitsVersion: 0,
        publisherFeeSplits: [],
        finalTrackLinksVersion: 0,
        finalTrackLinks: [],
        finalTrackAttachments: [],
      },
      musicAssetsVersion: 0,
      musicAssets: [],
      finalVideoVersion: 0,
      finalVideo: [],
    },
    version: 0,
    terms: [],
    assetAttachments: [],
    clients: [],
  },
});
