import { css } from "@emotion/react";
import appTheme from "src/theme";

const getColorByTheme = (theme: string) => {
  if (["orange-link", "orange"].includes(theme)) return "#FC4819";

  return "white";
};

const styles = {
  button: (theme: string) => {
    const color = getColorByTheme(theme);

    return css`
      margin: 0;
      border: none;
      color: inherit;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      min-height: 32px;
      line-height: 22px;
      position: relative;
      letter-spacing: 0.07em;
      padding: 0 48px 10px 0;
      font-family: ${appTheme.fonts.sofiaPro};
      text-transform: uppercase;
      background-color: transparent;

      ::after {
        left: 0;
        bottom: 0;
        height: 2px;
        content: "";
        position: absolute;
        border-radius: 2px;
        width: calc(100% - 48px);
        transition: transform 0.3s 0.3s;
        background-color: ${color};
      }

      :hover::after {
        transform: translateX(50%) scaleX(0);
      }

      svg path {
        fill: ${color};
      }
    `;
  },

  arrows: css`
    top: 0;
    right: 0;
    width: 32px;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: flex-end;
  `,
};

export default styles;
