import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";

interface ISectionTitleAndSubtitleProps {
  title: string;
  subtitle?: string;
  titleTranslationPrefix?: string;
}

const SectionTitleAndSubtitle = ({
  title,
  subtitle,
  titleTranslationPrefix = "ClientProjectViewPage##",
}: ISectionTitleAndSubtitleProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.titleAndSubtitle}>
      <div css={styles.sectionTitle}>
        {t(`${titleTranslationPrefix}${title}`)}
      </div>
      {subtitle && (
        <div css={styles.sectionSubtitle}>
          {t(`${titleTranslationPrefix}${subtitle}`)}
        </div>
      )}
    </div>
  );
};

export default SectionTitleAndSubtitle;
