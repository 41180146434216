import { css } from "@emotion/react";
import theme from "src/theme";

const dimensions = "2rem";

export default {
  control: css`
    align-items: center;
    background-color: transparent;
    background-image: none;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: cover;
    border: 0;
    box-shadow: none;
    color: ${theme.colors.white};
    cursor: pointer;
    display: inline-flex;
    font-size: 1.5rem;
    font-weight: 300;
    justify-content: center;
    line-height: 1.5715;
    min-width: ${dimensions};
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    white-space: nowrap;
    margin-right: 0.15rem;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  `,
  controlWithTooltip: css`
    border-radius: 30px;
    padding: 0;
    margin: 0;

    .ant-tooltip-arrow-content {
      height: 1rem;
      width: 1rem;
      position: unset !important;
      right: unset !important;
      left: unset !important;
      margin-top: -0.125rem;
      margin-left: -0.156rem;
    }

    .ant-tooltip-arrow {
      overflow: visible;
      z-index: -1;
    }

    :hover {
      background-color: ${theme.colors.background.fadeToGrey};
    }
  `,
  label: css`
    color: ${theme.colors.black};
    font-weight: 600;
    font-size: 0.85rem;
    margin-left: 1rem;
  `,
  tooltip: css`
    background: ${theme.colors.background.landingDarkGray} !important;
    font-family: ${theme.fonts.sofiaPro};
    padding: 3px 13px !important;
    border-radius: 4px !important;
    font-size: 0.75rem !important;
    font-weight: 700;
    line-height: 1rem;
    letter-spacing: 0;
    text-align: center;
    margin-top: -3px !important;
  `,
};
