import React, { ReactElement } from "react";

const ResourcesIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M13.2829 2.5L4.16102 2.5C3.99928 2.5 3.86816 2.6316 3.86816 2.79393L3.86816 4.5575C3.86816 4.71983 3.99928 4.85143 4.16102 4.85143L13.2829 4.85143C13.4446 4.85143 13.5757 4.71983 13.5757 4.5575V2.79393C13.5757 2.6316 13.4446 2.5 13.2829 2.5Z" />
      <path d="M23.7072 5.79165L11.829 5.79165C11.6672 5.79165 11.5361 5.92325 11.5361 6.08558V7.84915C11.5361 8.01148 11.6672 8.14308 11.829 8.14308L23.7072 8.14308C23.869 8.14308 24.0001 8.01148 24.0001 7.84915V6.08558C24.0001 5.92325 23.869 5.79165 23.7072 5.79165Z" />
      <path d="M15.4551 9.08411L6.72157 9.08411C6.55983 9.08411 6.42871 9.2157 6.42871 9.37803V11.1416C6.42871 11.3039 6.55983 11.4355 6.72157 11.4355L15.4551 11.4355C15.6169 11.4355 15.748 11.3039 15.748 11.1416V9.37803C15.748 9.2157 15.6169 9.08411 15.4551 9.08411Z" />
      <path d="M18.7282 2.5L16.5897 2.5C16.428 2.5 16.2969 2.6316 16.2969 2.79393V4.5575C16.2969 4.71983 16.428 4.85143 16.5897 4.85143H18.7282C18.8899 4.85143 19.021 4.71983 19.021 4.5575V2.79393C19.021 2.6316 18.8899 2.5 18.7282 2.5Z" />
      <path d="M13.777 15.6682H11.6386C11.4768 15.6682 11.3457 15.7998 11.3457 15.9621V17.7257C11.3457 17.888 11.4768 18.0196 11.6386 18.0196H13.777C13.9387 18.0196 14.0698 17.888 14.0698 17.7257V15.9621C14.0698 15.7998 13.9387 15.6682 13.777 15.6682Z" />
      <path d="M23.0192 18.9598H20.8807C20.719 18.9598 20.5879 19.0914 20.5879 19.2538V21.0173C20.5879 21.1797 20.719 21.3113 20.8807 21.3113H23.0192C23.1809 21.3113 23.312 21.1797 23.312 21.0173V19.2538C23.312 19.0914 23.1809 18.9598 23.0192 18.9598Z" />
      <path d="M19.14 12.3757L4.16102 12.3757C3.99928 12.3757 3.86816 12.5073 3.86816 12.6697L3.86816 14.4332C3.86816 14.5956 3.99928 14.7272 4.16102 14.7272L19.14 14.7272C19.3018 14.7272 19.4329 14.5956 19.4329 14.4332V12.6697C19.4329 12.5073 19.3018 12.3757 19.14 12.3757Z" />
      <path d="M7.07832 15.6682L0.292856 15.6682C0.131116 15.6682 0 15.7998 0 15.9621L0 17.7257C0 17.888 0.131116 18.0196 0.292856 18.0196H7.07832C7.24006 18.0196 7.37118 17.888 7.37118 17.7257V15.9621C7.37118 15.7998 7.24006 15.6682 7.07832 15.6682Z" />
    </svg>
  );
};

export default ResourcesIcon;
