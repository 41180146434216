import { ReactElement } from "react";
import IIconComponentProps from "src/app/assets/interfaces";

const StatusActive = ({
  fill = "#20C885",
}: IIconComponentProps): ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill={fill} />
      <path
        d="M13.6599 21.2596L9.86512 17.4649C9.71163 17.3114 9.71163 17.0619 9.86512 16.9084L11.5346 15.2389C11.6881 15.0854 11.9376 15.0854 12.0911 15.2389L13.6599 16.8076C13.8134 16.9611 14.0629 16.9611 14.2164 16.8076L19.9089 11.1151C20.0624 10.9616 20.3119 10.9616 20.4654 11.1151L22.1349 12.7846C22.2884 12.9381 22.2884 13.1876 22.1349 13.3411L14.2164 21.2596C14.0629 21.4131 13.8134 21.4131 13.6599 21.2596Z"
        fill="white"
      />
    </svg>
  );
};

export default StatusActive;
