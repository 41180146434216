import React, {
  ReactElement,
  FormEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Input,
  Button,
  ScopedTheme,
  InputFeedback,
  ValidationMessage,
  ErrorOvalIcon,
  ButtonLoadingIcon,
} from "@songtradr/component-library";
import useAuth from "src/auth/use-auth";
import getEmailValidationError from "src/utils/getEmailValidationErrorMessage";
import appStyles from "src/app/styles";
import { Link, useHistory } from "react-router-dom";
import EyeWithStrikethrough from "src/app/assets/icons/eye-with-strikethrough";
import FloatingLabelInput from "src/components/floating-label-input";
import EyeWithoutStrikethrough from "src/app/assets/icons/eye-without-strikethrough";
import CopyrightFooter from "src/components/copyright-footer";
import FloatingLabel from "src/components/floating-label";
import styles from "./styles";
import { IProps } from "./interfaces";

const Login = ({ redirectUrl }: IProps): ReactElement => {
  const { handleLogin, isSessionValid, organisationId, user } = useAuth();
  const history = useHistory();
  const [formStatus, setFormStatus] = useState({
    email: "",
    password: "",
    isLoading: false,
    error: { password: "", email: "" },
    errorMessage: "",
    showPassword: false,
  });

  const hasError = useMemo(() => {
    const { errorMessage } = formStatus;
    return errorMessage;
  }, [formStatus]);

  useEffect(() => {
    const checkSession = async () => {
      const isSession = await isSessionValid();
      if (isSession && organisationId && user) {
        history.push("/home");
      }
    };
    checkSession();
  }, [isSessionValid, history, organisationId, user]);

  const updateFormField = (name: string) => {
    return ({ target: { value } }) => {
      setFormStatus((oldValues) => ({ ...oldValues, [name]: value }));
    };
  };

  const toggleShowPassword = () => {
    setFormStatus({
      ...formStatus,
      showPassword: !formStatus.showPassword,
    });
  };

  const handleSignIn = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormStatus({
      ...formStatus,
      error: { password: "", email: "" },
      errorMessage: "",
      isLoading: true,
    });
    const { email, password } = formStatus;
    const emailValidationMessage = email
      ? getEmailValidationError(email)
      : "Please fill all fields";

    const passwordError = password ? "" : "Please fill all fields";
    const errorMessage = "Please fill all fields";

    if (emailValidationMessage || !password) {
      setFormStatus({
        ...formStatus,
        error: { email: emailValidationMessage, password: passwordError },
        errorMessage: emailValidationMessage || errorMessage,
        isLoading: false,
      });

      return;
    }

    handleLogin(
      formStatus.email,
      formStatus.password,
      redirectUrl ? decodeURIComponent(redirectUrl) : undefined,
      (error) => {
        setFormStatus({
          ...formStatus,
          isLoading: false,
          errorMessage: error,
        });
      }
    );
  };

  return (
    <ScopedTheme mode="dark" css={styles.background}>
      <div css={[appStyles.container, appStyles.guestPageContainer]}>
        <form
          css={appStyles.guestPageInnerContainer}
          onSubmit={handleSignIn}
          autoComplete="off"
        >
          <h3 css={styles.subHeading}>Welcome to</h3>
          <h1 css={styles.mainHeading}>Songtradr Studio</h1>
          <p css={styles.text}>
            Empower your musical journey with our comprehensive platform—your
            all-in-one solution for music licensing and management, curated for
            brands and agencies.
          </p>
          <h4 css={styles.sectionHeader}>Sign in</h4>
          <FloatingLabelInput
            required
            name="email"
            label="Email"
            hasError={!!formStatus.error.email}
            disabled={formStatus.isLoading}
            onChange={updateFormField("email")}
            value={formStatus.email}
            css={[styles.inputField, styles.spacingBottom]}
          />

          <FloatingLabel
            label="Password"
            value={formStatus.password}
            css={styles.spacingBottom}
          >
            <Input
              name="password"
              hasError={!!formStatus.error.password}
              disabled={formStatus.isLoading}
              onChange={updateFormField("password")}
              type={formStatus.showPassword ? "text" : "password"}
              css={[
                appStyles.inputAutofillOverride,
                styles.inputField,
                styles.inputPassword,
              ]}
              icon={
                <button
                  onClick={toggleShowPassword}
                  type="button"
                  css={[appStyles.resetBtn, styles.showHidePasswordBtn]}
                >
                  {formStatus.showPassword ? (
                    <EyeWithoutStrikethrough />
                  ) : (
                    <EyeWithStrikethrough />
                  )}
                </button>
              }
            />
          </FloatingLabel>

          {hasError && (
            <InputFeedback css={styles.validationMessage}>
              <ValidationMessage type="error">
                <ErrorOvalIcon />
                {formStatus.errorMessage}
              </ValidationMessage>
            </InputFeedback>
          )}

          <div css={styles.submitContainer}>
            <Link
              to="/forgot-password"
              css={styles.smallLink}
              id="forgot-password"
            >
              Forgot password?
            </Link>
            <Button
              variant="primary"
              disabled={formStatus.isLoading}
              type="submit"
              css={styles.submitBtn}
              id="sign-in"
            >
              Sign in
              {formStatus.isLoading && <ButtonLoadingIcon />}
            </Button>
          </div>
        </form>
      </div>
      <div css={[appStyles.container, styles.copyrightFooter]}>
        <CopyrightFooter />
      </div>
    </ScopedTheme>
  );
};

export default Login;
