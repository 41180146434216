import { DownOutlined, UpOutlined } from "@ant-design/icons";
import React, { ReactElement, useState } from "react";
import styles from "./styles";

interface IProps {
  question: string;
  answer: string;
}

const Question = ({ question, answer }: IProps): ReactElement => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div css={styles.container} data-testid="faq-question-container">
      <div className="title-container">
        <h2>{question}</h2>
        {isVisible ? (
          <UpOutlined onClick={() => setIsVisible(false)} />
        ) : (
          <DownOutlined onClick={() => setIsVisible(true)} />
        )}
      </div>
      <p className={`animation ${isVisible ? "visible" : ""}`}>{answer}</p>
      <hr />
    </div>
  );
};

export default Question;
