import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  inviteMembersContainer: css`
    .inviteMembersCancelButton,
    .inviteSentCloseButton {
      margin-right: 16px;
    }
    .inviteMembersCancelButton {
      margin-right: 16px;
    }
    button span {
      font-family: ${theme.fonts.sofiaPro};
      font-weight: 700 !important;
    }
  `,
  cancelBtn: css`
    color: ${theme.colors.white} !important;
    &:hover {
      color: ${theme.colors.black} !important;
    }
  `,
  formContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${theme.colors.white};
  `,
  errorBlock: css`
    margin: 1rem 0 2rem;
    color: ${theme.colors.white};
    text-align: left;

    p {
      margin-bottom: 0.5rem;
    }
  `,
  contentContainer: css`
    padding: 0 1rem 0 1rem;

    @media ${queries.medium} {
      padding: 0;
    }

    .input-label,
    .helper-text {
      font-family: ${theme.fonts.sofiaPro};
      color: ${theme.colors.white} !important;
    }

    .ant-select-multiple,
    .ant-select-selector {
      min-height: 200px !important;
      width: 600px;
      color: ${theme.colors.white} !important;
    }

    .ant-tag {
      display: inline;
      text-transform: lowercase !important;
    }
  `,
  checkboxContainer: css`
    padding-top: 46px;

    .ant-row.ant-form-item {
      margin-bottom: 16px;
    }

    label {
      font-family: ${theme.fonts.sofiaPro};
      color: ${theme.colors.white} !important;
      font-size: 1rem !important;
      line-height: 1.5rem !important;
      padding-left: 8px;
      padding-right: 8px;
    }

    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-input:focus {
      border-color: ${theme.colors.functional.brightOrange} !important;
    }
  `,
  header: css`
    color: ${theme.colors.white} !important;
    font-size: 2rem !important;
    line-height: 2.5rem;
    font-weight: 250 !important;
    margin-bottom: 1.5rem !important;
    height: 1.5rem;
    letter-spacing: -2%;
    margin-left: 1rem;
    font-family: ${theme.fonts.sofiaPro};
    margin-top: 0 !important;

    @media ${queries.medium} {
      margin-left: 0;
    }

    :first-of-type {
      margin-top: 1.375rem;

      @media ${queries.medium} {
        margin-top: 1rem;
      }
    }
  `,
  subtitle: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400 !important;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1.5rem !important;
    color: ${theme.colors.white} !important;
  `,
  tabletDesktopButtonContainer: css`
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    margin-top: 32px;

    button {
      box-shadow: none !important;
    }

    button.inviteMembersNextButton:disabled,
    button.inviteMembersSendButton:disabled {
      background: ${theme.colors.background.fadeToGrey};
      border-color: ${theme.colors.background.fadeToGrey};
      color: ${theme.colors.secondary.blueOnBlackShadow};
    }
  `,
  mobileButtonContainer: css`
    height: 3.125rem;
    background-color: ${theme.colors.white};
    display: flex;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-top: 0.06rem solid ${theme.colors.background.veryLightGray};
  `,
  inviteSentWithErrors: css`
    padding-top: 0;
  `,
  inviteSent: css`
    text-align: center;
    padding-top: 7.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  inviteSentHeader: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-size: 32px;
    line-height: 40px;
    font-weight: 800;
    letter-spacing: -2%;
  `,
  inviteSentPrimaryButtonMobile: css`
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;
    z-index: 100;
  `,
  deleteInviteBody: css`
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
  `,
  checkboxWrapper: css`
    .ant-form-item-control-input-content {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      justify-content: left;
      gap: 1rem;
    }
  `,
};
