import React, {
  ReactElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Lottie from "react-lottie-player";
import backgroundLinesAnimation from "src/app/assets/animations/bars_bg.json";
import { Button } from "@songtradr/component-library";
import { useHistory } from "react-router-dom";
import DetailsSection from "src/pages/org-dashboard/components/details-section";
import AccountManagerSection from "src/pages/org-dashboard/components/account-manager-section";
import LogoProductInfoSection from "src/pages/org-dashboard/components/logo-product-info-section";
import UnderlinedButton from "src/components/underlined-button";
import getAllOrganisations, {
  IGetOrgsResponse,
} from "src/api/organisation/get-all-organisations";
import useAuth from "src/auth/use-auth";
import { errorToast } from "src/components/toast-notification";
import OrgTable from "src/pages/org-dashboard/components/org-table";
import selfOrganisations, {
  IOrganisationSearchResponse,
} from "src/api/organisation/self";
import Modules from "src/pages/org-dashboard/components/modules";
import useOrganisation from "src/providers/organisation/hooks";
import axios, { AxiosError } from "axios";
import config from "src/config";
import styles from "./styles";
import Superadmins from "./components/superadmins";

enum TABS {
  DETAILS = "Details",
  ACCOUNT_MANAGER = "Account manager",
  LOGO_PRODUCT_INFO = "Logo & product info",
  MODULES = "Modules",
  SUPERADMINS = "Superadmins",
}

type TAB_KEYS = keyof typeof TABS;

const orgsPerPage = 20;

const getPossiblePages = (currentPage: number, maxPossiblePage: number) =>
  [
    currentPage - 2,
    currentPage - 1,
    currentPage,
    currentPage + 1,
    currentPage + 2,
  ].filter((page) => page <= maxPossiblePage && page > -1);

const OrgDashboard = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(true);
  const [orgsResponse, setOrgsResponse] = useState<IGetOrgsResponse>();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrgIndex, setSelectedOrgIndex] = useState<number>();
  const [searchQuery, setSearchQuery] = useState("");
  const [activeTab, setActiveTab] = useState<TABS>(TABS.DETAILS);
  const history = useHistory();
  const { organisation, getOrg } = useOrganisation();
  const abortController = useRef<AbortController>();
  const { getAccessToken, isBlessedSuperAdmin } = useAuth();
  let currentPageOfOrgs = orgsResponse?.items;

  if (currentPageOfOrgs && organisation && !searchQuery && currentPage === 0) {
    // If number of orgs per page increases substantially this would be worth memoising
    currentPageOfOrgs = [
      organisation,
      ...currentPageOfOrgs.filter((org) => org.id !== organisation.id),
    ];
  }

  let maxNoOfPages = 0;
  const [
    orgDropdownData,
    setOrgDropdownData,
  ] = useState<IOrganisationSearchResponse>();
  const selectedOrgId =
    currentPageOfOrgs && selectedOrgIndex !== undefined
      ? currentPageOfOrgs[selectedOrgIndex].id
      : null;

  if (orgsResponse) {
    maxNoOfPages = Math.floor(orgsResponse.total / orgsPerPage);
  }

  const fetchOrgs = async (page: number, query: string) => {
    setIsLoading(true);
    abortController.current = new AbortController();

    try {
      const accessToken = getAccessToken();
      const orgsApiResponse = await getAllOrganisations(
        accessToken,
        {
          start: page * orgsPerPage,
          perPage: orgsPerPage,
          organisationName: query,
        },
        abortController.current
      );

      if (!orgsApiResponse) {
        return;
      }

      setOrgsResponse(orgsApiResponse);

      const orgDropdownResponse = await selfOrganisations(accessToken);

      if (orgDropdownResponse) {
        setOrgDropdownData(orgDropdownResponse);

        if (orgsApiResponse.total > 0) {
          setSelectedOrgIndex(0);
        }
      }
    } catch (e) {
      if (axios.isAxiosError(e) && e.code === AxiosError.ERR_CANCELED) {
        return;
      }

      errorToast({ message: "There was an issue getting the organisations" });

      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    void fetchOrgs(currentPage, searchQuery);

    return () => {
      if (abortController.current) {
        abortController.current.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshData = () => {
    void fetchOrgs(currentPage, searchQuery);

    // If we've just edited the org we're currently on, refresh this too
    // so our changes are reflected
    if (selectedOrgId === organisation?.id && organisation?.id) {
      void getOrg(organisation.id, getAccessToken());
    }
  };

  let activeTabComponent;

  if (currentPageOfOrgs && selectedOrgIndex !== undefined) {
    switch (activeTab) {
      case TABS.ACCOUNT_MANAGER:
        activeTabComponent = (
          <AccountManagerSection
            org={currentPageOfOrgs[selectedOrgIndex]}
            refreshData={refreshData}
          />
        );
        break;
      case TABS.LOGO_PRODUCT_INFO:
        activeTabComponent = (
          <LogoProductInfoSection org={currentPageOfOrgs[selectedOrgIndex]} />
        );
        break;
      case TABS.MODULES:
        activeTabComponent = (
          <Modules
            org={currentPageOfOrgs[selectedOrgIndex]}
            refreshData={refreshData}
          />
        );
        break;
      case TABS.SUPERADMINS:
        activeTabComponent = <Superadmins />;
        break;
      default:
        activeTabComponent = (
          <DetailsSection
            org={currentPageOfOrgs[selectedOrgIndex]}
            orgDropdownData={orgDropdownData?.items}
            refreshData={refreshData}
          />
        );
    }
  }

  const handleTabSwitch = (tabToSwitchTo: TABS) => {
    if (activeTab === tabToSwitchTo) {
      return;
    }

    setActiveTab(tabToSwitchTo);
  };

  const handleGoToPage = (newPage: number) => {
    if (newPage === currentPage || !orgsResponse) {
      return;
    }

    if (newPage < 0) {
      return;
    }

    if (orgsResponse.total < newPage * orgsPerPage) {
      return;
    }

    setCurrentPage(newPage);
    void fetchOrgs(newPage, searchQuery);
  };

  const handleSearchQuery = (newSearchQuery: string) => {
    setSelectedOrgIndex(undefined);
    setCurrentPage(0);
    setSearchQuery(newSearchQuery);
    void fetchOrgs(0, newSearchQuery);
  };

  const showCreateNewOrgButton = useMemo(() => {
    return isBlessedSuperAdmin || !(config.production ?? false);
  }, [isBlessedSuperAdmin]);

  return (
    <div css={styles.container}>
      <div css={styles.animationContainer}>
        <Lottie
          animationData={backgroundLinesAnimation}
          style={{ width: 1000, height: 175 }}
          loop
          play
        />
      </div>
      <div css={styles.innerContainer}>
        <div css={styles.headerContainer}>
          <h1 css={styles.mainHeading}>Organization Dashboard</h1>
          {showCreateNewOrgButton && (
            <Button
              variant="primary"
              onClick={() => history.push("/admin/create-new-organization")}
              data-testid="create-new-org"
            >
              Create new organization
            </Button>
          )}
        </div>
        <div css={styles.mainContentContainer}>
          <div css={styles.leftContainer}>
            <OrgTable
              orgs={currentPageOfOrgs}
              currentPage={currentPage}
              possiblePages={getPossiblePages(currentPage, maxNoOfPages)}
              handleSelectOrgIndex={setSelectedOrgIndex}
              handleUpdateOrgSearchQuery={handleSearchQuery}
              handleGoToPage={handleGoToPage}
              selectedOrgIndex={selectedOrgIndex}
              isLoading={isLoading}
              refreshData={refreshData}
            />
          </div>
          <div css={styles.rightContainer}>
            <div css={styles.tabContainer}>
              {(Object.keys(TABS) as TAB_KEYS[])
                .filter(
                  (item) =>
                    !(TABS[item] === TABS.SUPERADMINS && !isBlessedSuperAdmin)
                )
                .map((TAB_INDEX) => (
                  <UnderlinedButton
                    data-testid={`tab-${TAB_INDEX}`}
                    key={TAB_INDEX}
                    css={styles.underlinedBtn}
                    onClick={() => handleTabSwitch(TABS[TAB_INDEX])}
                    hasUnderline={activeTab === TABS[TAB_INDEX]}
                  >
                    {TABS[TAB_INDEX]}
                  </UnderlinedButton>
                ))}
            </div>
            <div css={styles.activeTabContainer} key={selectedOrgId}>
              {activeTabComponent}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrgDashboard;
