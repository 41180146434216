import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ResultsTable, { TAB } from "src/pages/validate/components/results-table";
import { Button } from "@songtradr/component-library";
import globalStyles from "src/app/styles";
import styles from "../../styles";
import ExplainerAndIntro from "../explainer-and-intro";
import ValidateTabHeader from "../header";
import CaseStudies from "../case-studies";
import FAQ from "../faq";

const Measurement = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { TabPane } = Tabs;

  const [activeTab, setActiveTab] = useState<TAB>(TAB.OVERVIEW);
  const handleTabChange = (activeKey: string) => {
    setActiveTab(activeKey as TAB);
  };
  return (
    <Tabs activeKey={activeTab} css={styles.tabs} onChange={handleTabChange}>
      <TabPane tab="Research Overview" key={TAB.OVERVIEW}>
        <ValidateTabHeader />
        <div css={styles.validateContent}>
          <ExplainerAndIntro />
          <CaseStudies />
          <FAQ />
        </div>
      </TabPane>
      <TabPane tab="My Research" key={TAB.MYRESEARCH}>
        <div css={globalStyles.myResearchContainer}>
          <ResultsTable
            css={styles.testResultsTable}
            updateTab={handleTabChange}
            renderBeforeTable={
              <div css={styles.tableHeaderContainer}>
                <h2 css={styles.secondaryHeader}>
                  {t("Validate##My Research")}
                </h2>
                <Button
                  variant="primary"
                  onClick={() => history.push("/measurement/new-test")}
                >
                  {t("Validate##Create a new test")}
                </Button>
              </div>
            }
          />
        </div>
      </TabPane>
    </Tabs>
  );
};

export default Measurement;
