import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  mainContainer: css`
    width: 324px;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: ${theme.colors.white};
    position: relative;
  `,
  labelContainer: css`
    display: flex;
    flex-direction: row;
    padding: 0.75rem 0.75rem 0.75rem 1.5rem;
    border: 1px solid ${theme.colors.darkCharcoal};
    border-radius: 2.5rem;
    cursor: pointer;

    &:hover {
      border: 1px solid ${theme.colors.white};
    }
    > div:first-child {
      display: flex;
      flex: 1;
      padding: 0;

      svg {
        margin-right: 1rem;
      }
    }
  `,
  activeContainer: css`
    border: 1px solid ${theme.colors.white};
  `,
  menuItem: css`
    display: flex;
    flex-direction: row;
    padding: 0.75rem 0.75rem 0.75rem 1.5rem;
    color: ${theme.colors.white};

    &:hover {
      cursor: pointer;
    }

    div {
      display: flex;
      flex: 1;
    }
    svg {
      margin-right: 2.5rem;
    }
  `,
  menuItemSelected: css`
    font-weight: 700;
  `,
  dataContainer: css`
    padding: 0.75rem 0;
    display: flex;
    flex-direction: column;
    max-height: 192px;
    overflow-y: auto;
    border: 1px solid ${theme.colors.darkCharcoal};
    border-radius: 0.5rem;
    position: absolute;
    width: 324px;
    top: 58px;
    background-color: ${theme.colors.background.landingDarkGray};
    z-index: 4;
  `,
  noData: css`
    display: flex;
    justify-content: center;
  `,
  noDataLabel: css`
    color: ${theme.colors.functional.gray65};
    display: flex;
    flex: 1;
    justify-content: start;
  `,
  disabledContainer: css`
    opacity: 0.5;
    pointer-events: none;
  `,
};
