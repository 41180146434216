import { SerializedStyles } from "@emotion/react";

import styles from "./styles";

type TBasicButtonTheme = "orange" | "white";

interface IBasicButtonProps {
  theme?: TBasicButtonTheme | TButtonTheme;
  style?: SerializedStyles;
  text?: string;
}

type TButtonTheme =
  | "basic"
  | "orange-to-white"
  | "white-to-dark"
  | "dark-to-orange"
  | "orange-to-dark"
  | "white-link"
  | "dark-link"
  | "orange-link"
  | "white-to-orange-link"
  | "white-to-orange-footer";

const STButton = ({ style, text = "", theme = "white" }: IBasicButtonProps) => {
  return (
    <button type="button" css={[styles.button(theme), style]}>
      {text}
    </button>
  );
};

export default STButton;
