enum Greeting {
  MORNING = "Good Morning",
  AFTERNOON = "Good Afternoon",
  EVENING = "Good Evening",
}

const getGreeting = (): Greeting => {
  const morningLimits = {
    min: 5,
    max: 12,
  };
  const afternoonLimits = {
    min: 12,
    max: 18,
  };
  const currentHour = new Date().getHours();
  if (currentHour > morningLimits.min && currentHour < morningLimits.max) {
    return Greeting.MORNING;
  }
  if (currentHour > afternoonLimits.min && currentHour < afternoonLimits.max) {
    return Greeting.AFTERNOON;
  }
  return Greeting.EVENING;
};

export default getGreeting;
