import React, { ReactElement } from "react";
import CustomSearchInput, {
  ICustomSearchInput,
} from "src/components/custom-search-input";
import Button from "src/components/button";
import { useTranslation } from "react-i18next";
import LicenseDropdownSelector, {
  ILicenseDropdownSelectorProps,
} from "../license-dropdown-selector";
import styles from "../styles";

interface IButtonProps {
  label: string;
  onClick: () => void;
}

interface ILibraryTableActionsProps {
  dropdownSelect: ILicenseDropdownSelectorProps;
  search: ICustomSearchInput;
  button: IButtonProps;
}

const LibraryTableActions = ({
  dropdownSelect,
  search,
  button,
}: ILibraryTableActionsProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <div css={styles.actionsContainer}>
      <LicenseDropdownSelector
        projects={dropdownSelect.projects}
        selectedProjectId={dropdownSelect.selectedProjectId}
        disabled={dropdownSelect.disabled}
        onChange={dropdownSelect.onChange}
      />
      <div css={styles.actions}>
        <CustomSearchInput
          searchText={search.searchText}
          onUpdateSearchText={search.onUpdateSearchText}
          onClearSearchText={search.onClearSearchText}
        />
        <Button
          ariaLabel={t(`${button.label}`)}
          css={styles.primaryButton}
          onClick={button.onClick}
        >
          {t(`${button.label}`)}
        </Button>
      </div>
    </div>
  );
};

export default LibraryTableActions;
