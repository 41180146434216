import React, { ReactElement } from "react";
import theme from "src/theme";
import { useTranslation } from "react-i18next";

export interface IComponentIconProps {
  height?: number;
  width?: number;
  fill?: string;
  size?: number;
  isActive?: boolean;
}

const MoreIcon = ({
  height = theme.svgSize.large,
  width = theme.svgSize.large,
}: IComponentIconProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 38 38"
      aria-labelledby="more-button"
    >
      <title id="more-button">{t("More")}</title>
      <g fill="#FFF" fillRule="evenodd" transform="translate(10 17)">
        <circle cx="2" cy="2" r="2" />
        <circle cx="9" cy="2" r="2" />
        <circle cx="16" cy="2" r="2" />
      </g>
    </svg>
  );
};

export default MoreIcon;
