import React, { ReactElement, forwardRef } from "react";
import { Table } from "antd";
import {
  IProjectAttachment,
  IProjectUploadFile,
} from "src/pages/projects/client-project-view/interfaces";
import { useTranslation } from "react-i18next";
import { format, parseISO } from "date-fns";
import projectsMainStyles from "src/pages/projects/styles";
import { DebouncedFunc } from "lodash";
import SortDESC from "src/app/assets/icons/component-icons/buttons/sort-desc";
import SortASC from "src/app/assets/icons/component-icons/buttons/sort-asc";
import { DownloadIcon } from "src/app/assets/icons/component-icons";
import styles from "./styles";
import mainStyles from "../../styles";
import SectionTitleAndSubtitle from "../section-title-and-subtitle";

interface IProps {
  purchaseOrders: IProjectAttachment[];
  invoices: IProjectAttachment[];
  licenses: IProjectAttachment[];
  estimates: IProjectAttachment[];
  miscellaneous: IProjectAttachment[];
  assets: IProjectAttachment[];
  downloadFile?: DebouncedFunc<(file: IProjectUploadFile) => Promise<void>>;
}

const Documents = forwardRef(
  (
    {
      purchaseOrders = [],
      invoices = [],
      licenses = [],
      estimates = [],
      miscellaneous = [],
      assets = [],
      downloadFile,
    }: IProps,
    ref: React.Ref<HTMLDivElement>
  ): ReactElement => {
    const { t } = useTranslation();

    const handleDownloadFile = async (data: IProjectAttachment) => {
      if (downloadFile) {
        await downloadFile(data);
      }
    };

    const allDocs = [
      ...purchaseOrders,
      ...invoices,
      ...licenses,
      ...estimates,
      ...miscellaneous,
      ...assets,
    ];

    const columns = [
      {
        dataIndex: "type",
        key: "type",
        sorter: (a: any, b: any) => a.type.localeCompare(b.type),
        title: (sortColumn: any) => {
          const sortedColumn = sortColumn.sortColumn?.key === "type";
          const sortedOrder = sortColumn.sortOrder;
          return (
            <div css={mainStyles.tableColumnHeader}>
              {t("ClientProjectViewPage##Type")}
              {sortedColumn && sortedOrder === "ascend" ? (
                <SortDESC />
              ) : (
                <SortASC />
              )}
            </div>
          );
        },
      },
      {
        title: t("ClientProjectViewPage##Name"),
        dataIndex: "name",
        key: "name",
      },
      {
        dataIndex: "dateUploaded",
        key: "dateUploaded",
        sorter: (a: any, b: any) =>
          new Date(a.dateUploaded).getTime() -
          new Date(b.dateUploaded).getTime(),
        title: (sortColumn: any) => {
          const sortedColumn = sortColumn.sortColumn?.key === "dateUploaded";
          const sortedOrder = sortColumn.sortOrder;
          return (
            <div css={mainStyles.tableColumnHeader}>
              {t("ClientProjectViewPage##Date uploaded")}
              {sortedColumn && sortedOrder === "ascend" ? (
                <SortDESC />
              ) : (
                <SortASC />
              )}
            </div>
          );
        },
      },
      {
        dataIndex: "download",
        key: "download",
        title: "",
        width: 50,
      },
    ];

    const tableDataSource = allDocs?.map((document) => {
      return {
        key: document.id,
        type:
          document.documentCategory === "Misc"
            ? "Miscellaneous"
            : document.documentCategory,
        name: document.name,
        dateUploaded: format(
          parseISO(document.uploaded.dateTime),
          "MMM dd, yyyy"
        ),
        download: (
          <button
            css={styles.buttonContainer}
            type="button"
            aria-label={t("Download")}
            onClick={() => handleDownloadFile(document)}
          >
            <DownloadIcon fill="white" height={48} width={48} />
          </button>
        ),
      };
    });

    return (
      <div css={mainStyles.sectionContainer} id="documents" ref={ref}>
        <SectionTitleAndSubtitle
          title="Documents"
          subtitle="All your related invoices, estimates and files"
        />
        <Table
          css={projectsMainStyles.getTableStyles(false)}
          columns={columns}
          dataSource={tableDataSource}
          pagination={false}
          scroll={{ x: true }}
        />
      </div>
    );
  }
);

export default Documents;
