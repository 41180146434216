import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from "react";
import { verifyCode } from "src/api/app-invites";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import styles from "./styles";
import SignUp from "../sign-up";

const CodePage = (): ReactElement => {
  const [code, setCode] = useState<Array<string>>([]);
  const [isError, setIsError] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [showSignUp, setShowSignUp] = useState<boolean>(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const handleChange = async (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    await setCode((prev: Array<string>) => {
      const newCode = [...prev];
      newCode[index] = event.target.value;
      return newCode;
    });
    if (event.target.value.length >= 1 && event.target.nextElementSibling) {
      event.target.nextElementSibling.focus();
    }
  };

  const submitCode = useCallback(async () => {
    try {
      await verifyCode(code.join(""));
      setIsSuccess(true);
    } catch (error) {
      if (error.response.status === 404) {
        setIsError(true);
      }
    }
  }, [code]);

  useEffect(() => {
    if (code.length === 6) {
      submitCode();
    }
  }, [code, submitCode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isSuccess) {
        setShowSignUp(true);
      }
    }, 1000);
    return () => clearTimeout(timer);
  }, [isSuccess]);

  useEffect(() => {
    const pointer = (event: ClipboardEvent) => {
      const pasteString = event.clipboardData.getData("text");
      setCode(pasteString.slice(0, 6).split(""));
    };
    window.addEventListener("paste", pointer);

    return () => {
      window.removeEventListener("paste", pointer);
    };
  }, []);

  const reset = () => {
    setCode([]);
    setIsError(false);
  };

  if (showSignUp) {
    return <SignUp code={code.join("")} />;
  }

  return (
    <div css={styles.container}>
      <div className="content">
        <h2>
          You have been invited to collaborate with{" "}
          {params.get("orgName") || "Songtradr"}
        </h2>
        <h1>Let&apos;s get you started</h1>
        <h3>Please enter the provided passcode below</h3>

        {!isSuccess && !isError && (
          <div>
            <input
              onChange={(event) => handleChange(event, 0)}
              type="text"
              maxLength={1}
              value={code[0]}
              className="passcode-character"
            />
            <input
              onChange={(event) => handleChange(event, 1)}
              type="text"
              maxLength={1}
              value={code[1]}
              className="passcode-character"
            />
            <input
              onChange={(event) => handleChange(event, 2)}
              type="text"
              maxLength={1}
              value={code[2]}
              className="passcode-character"
            />
            <input
              onChange={(event) => handleChange(event, 3)}
              type="text"
              maxLength={1}
              value={code[3]}
              className="passcode-character"
            />
            <input
              onChange={(event) => handleChange(event, 4)}
              type="text"
              maxLength={1}
              value={code[4]}
              className="passcode-character"
            />
            <input
              onChange={(event) => handleChange(event, 5)}
              type="text"
              maxLength={1}
              value={code[5]}
              className="passcode-character"
            />
          </div>
        )}
        {isSuccess && (
          <div className="confirmation">
            <p data-test-id="passcode-confirmed">
              <CheckCircleOutlined /> Passcode confirmed
            </p>
          </div>
        )}
        {isError && (
          <div className="confirmation">
            <p data-test-id="passcode-incorrect">
              <CloseCircleOutlined onClick={reset} data-testid="close-btn" />{" "}
              Passcode incorrect
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CodePage;
