import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils";
import theme from "../../../theme";

export default {
  backBtn: css`
    width: 100%;
    margin: 3rem auto;
  `,
  copyrightFooter: css`
    width: 100%;
  `,
  subHeading: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1.5rem;
    font-weight: 400;
    padding: 0;
    color: ${theme.colors.white};
    line-height: 2rem;
    margin-bottom: 0.5rem;

    @media ${queries.large} {
      margin: 0;
    }
  `,
  mainHeading: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 500;
    margin: 0 0 1rem 0;
    padding: 0;
    color: ${theme.colors.white};
    line-height: 2.5rem;
    font-size: 2.5rem;

    @media ${queries.large} {
      font-size: 5rem;
      line-height: 5.5rem;
    }
  `,
  text: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    font-weight: 400;
    padding: 0;
    color: ${theme.colors.white};
    line-height: 1.5rem;
  `,
  sectionHeader: css`
    text-transform: uppercase;
    letter-spacing: 3.3px;
    font-size: 0.8rem;
    font-weight: 400;
    margin-top: 3rem;
    margin-bottom: 1.25rem;
    color: ${theme.colors.secondary.waveformGray};
  `,
  background: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${theme.colors.background.landingDarkGray};
  `,
  inputField: css`
    height: 64px;
  `,
  inputPassword: css`
    background-color: ${theme.colors.background.landingDarkGray} !important;
    span {
      width: auto;
      pointer-events: auto;
    }
  `,
  submitContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
  `,
  smallLink: css`
    font-size: 0.9rem;

    &,
    &:hover,
    &:focus,
    &:active {
      color: ${theme.colors.white};
    }
  `,
  showHidePasswordBtn: css`
    display: flex;
    align-items: center;
  `,
  validationMessage: css`
    margin-top: 0.25rem !important;

    svg {
      height: 1.25rem !important;
      margin: 0 0.5rem 0 0 !important;
    }
  `,
  submitBtn: css`
    transition: all 2s ease-in-out;

    svg {
      margin: 0 0 0 1rem;
    }
  `,
  spacingBottom: css`
    margin-bottom: 1rem;
  `,
};
