import React, { ReactElement, useEffect, useState } from "react";
import useApiPoll from "src/utils/hooks/use-api-poll";
import {
  FileState,
  IBrandAuditJob,
  AuditState,
} from "src/interfaces/brand-audit";
import {
  getSingleAudit,
  downloadAuditCsv,
  restartFile,
  deleteAudit,
  restartAudit,
} from "src/api/brand-audit";
import useAuth from "src/auth/use-auth";
import brandListStyles from "src/pages/brand-audit/components/brand-audit-list/styles";
import {
  Button,
  ButtonGroup,
  ButtonLoadingIcon,
  ProgressIndicator,
} from "@songtradr/component-library";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import dayJSHelper from "src/sumo/src/utils/dayJSHelper";
import axios, { AxiosError } from "axios";
import { errorToast } from "src/components/toast-notification";
import { format, parseISO } from "date-fns";
import STLoadingLogo from "src/components/st-loading-logo";
import { IProps } from "./interfaces";
import styles from "./styles";

const pollingInterval = 10000;

const BrandAuditDetails = ({ auditId }: IProps): ReactElement | null => {
  const { getAccessToken } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);

  const {
    data,
    triggerFetchData,
    abortController: sharedAbortController,
  } = useApiPoll<IBrandAuditJob>({
    fetchData: async (abortController: AbortController) => {
      let auditData;

      try {
        auditData = await getSingleAudit(
          getAccessToken(),
          abortController,
          auditId
        );
      } catch (error) {
        if (axios.isAxiosError(error) && error?.response?.status === 404) {
          errorToast({ message: "Audit does not exist" });
          history.push("/admin/brand-audit");
          return null;
        }

        errorToast({
          message: "There was a problem fetching the data",
        });
      }

      if (!auditData) {
        return null;
      }

      return auditData;
    },
    shouldRePoll: (job) =>
      job ? AuditState[job.auditStatus].shouldRePoll : false,
    pollingInterval,
  });

  const refreshData = async () => {
    setIsLoading(true);

    await (async () => {
      try {
        await triggerFetchData();
      } catch (e) {
        return;
      }

      setIsLoading(false);
    })();
  };

  const doRequestPrep = () => {
    const args = {
      accessToken: undefined,
      sharedAbortController_: undefined,
    };

    if (!sharedAbortController) {
      return args;
    }

    const accessToken = getAccessToken();

    if (!accessToken) {
      return args;
    }

    setIsProcessingRequest(true);

    return { accessToken, sharedAbortController_: sharedAbortController };
  };

  useEffect(() => {
    const accessToken = getAccessToken();

    if (!accessToken) {
      return;
    }

    void refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canDownloadCsv =
    data?.auditStatus === "CompleteWithoutErrors" ||
    data?.auditStatus === "CompleteWithErrors";

  const retryableFiles =
    data?.files.filter((x) => x.isErrored).map((x) => x.id) ?? [];

  const getDuration = (milliseconds: number | null): string => {
    if (
      milliseconds == null ||
      typeof milliseconds !== "number" ||
      !Number.isFinite(milliseconds) ||
      milliseconds < 0
    ) {
      return "";
    }

    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;

    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const getCsvFileName = () => {
    let fileName = "brand-audit";

    if (data?.name) {
      fileName += `-${data?.name.toLowerCase().replaceAll(" ", "")}`;
    }

    if (data?.brandName) {
      fileName += `-${data?.brandName.toLowerCase().replaceAll(" ", "")}`;
    }

    return fileName;
  };

  const handleCsvDownload = async () => {
    const { accessToken, sharedAbortController_ } = doRequestPrep();

    if (!canDownloadCsv || !accessToken || !sharedAbortController_) {
      return;
    }

    try {
      await downloadAuditCsv(
        accessToken,
        sharedAbortController_,
        auditId,
        getCsvFileName()
      );
    } catch (e) {
      if (axios.isAxiosError(e) && e.code === AxiosError.ERR_CANCELED) {
        return;
      }

      errorToast({
        message: "There was a problem fetching the data",
      });

      // eslint-disable-next-line no-console
      console.error(e);
    }

    setIsProcessingRequest(false);
  };

  const retryFiles = async (fileIds: string[]) => {
    const { accessToken, sharedAbortController_ } = doRequestPrep();

    if (!accessToken || !sharedAbortController_) {
      return;
    }

    try {
      for (let i = 0; i < fileIds.length; i += 1) {
        const fileId = fileIds[i];
        // eslint-disable-next-line no-await-in-loop
        await restartFile(accessToken, sharedAbortController_, auditId, fileId);
      }

      void refreshData();
    } catch (e) {
      if (axios.isAxiosError(e) && e.code === AxiosError.ERR_CANCELED) {
        return;
      }

      errorToast({
        message: "There was a problem retrying the file",
      });

      // eslint-disable-next-line no-console
      console.error(e);
    }

    setIsProcessingRequest(false);
  };

  const handleDeleteAudit = async () => {
    const { accessToken, sharedAbortController_ } = doRequestPrep();

    if (!accessToken || !sharedAbortController_) {
      return;
    }

    try {
      await deleteAudit(accessToken, sharedAbortController_, auditId);
    } catch (e) {
      if (axios.isAxiosError(e) && e.code === AxiosError.ERR_CANCELED) {
        return;
      }

      errorToast({
        message: "There was an issue deleting the audit",
      });

      // eslint-disable-next-line no-console
      console.error(e);

      return;
    } finally {
      setIsProcessingRequest(false);
    }

    history.push("/admin/brand-audit");
  };

  const handleRestartAudit = async () => {
    const { accessToken, sharedAbortController_ } = doRequestPrep();

    if (!accessToken || !sharedAbortController_) {
      return;
    }

    try {
      await restartAudit(accessToken, sharedAbortController_, auditId);
      void refreshData();
    } catch (e) {
      if (axios.isAxiosError(e) && e.code === AxiosError.ERR_CANCELED) {
        return;
      }

      errorToast({
        message: "There was an issue restarting the audit",
      });

      // eslint-disable-next-line no-console
      console.error(e);
    }

    setIsProcessingRequest(false);
  };

  return (
    <div>
      {isLoading ? (
        <div css={brandListStyles.loadingSpinnerContainer}>
          <STLoadingLogo pageCentered />
        </div>
      ) : (
        <>
          <div css={styles.buttonRowContainer}>
            <button
              css={styles.backBtn}
              type="button"
              aria-label="Back"
              name="Back"
              onClick={() => history.push("/admin/brand-audit")}
            >
              <LeftOutlined />
              <span css={styles.backBtnTxt}>Back</span>
            </button>
          </div>
          <div
            css={[
              brandListStyles.tableHeaderContainer,
              styles.tableHeaderContainerOverrides,
            ]}
          >
            <h1 css={brandListStyles.secondaryHeader}>
              Brand Audit of {data?.name}
            </h1>
            <ButtonGroup>
              <Button
                disabled={isProcessingRequest}
                type="button"
                aria-label="Edit audit"
                data-testid="edit-audit-btn"
                name="Edit audit"
                variant="link"
                onClick={() =>
                  history.push(`/admin/brand-audit/${auditId}/edit`)
                }
                css={styles.secondaryBtn}
              >
                Edit audit
              </Button>
              <Button
                disabled={isProcessingRequest}
                type="button"
                aria-label="Restart audit"
                data-testid="restart-audit-btn"
                name="Restart audit"
                variant="link"
                onClick={handleRestartAudit}
                css={styles.secondaryBtn}
              >
                Restart audit
              </Button>
              <Button
                disabled={isProcessingRequest}
                type="button"
                aria-label="Delete audit"
                data-testid="delete-audit-btn"
                name="Delete audit"
                variant="link"
                onClick={handleDeleteAudit}
                css={styles.secondaryBtn}
              >
                Delete audit
              </Button>
              <Button
                disabled={retryableFiles.length === 0 || isProcessingRequest}
                onClick={() => retryFiles(retryableFiles)}
                variant="primary"
                data-testid="retry-all-failed"
              >
                {isProcessingRequest && <ButtonLoadingIcon />} Retry failed
                files
              </Button>
              <Button
                disabled={!canDownloadCsv || isProcessingRequest}
                onClick={handleCsvDownload}
                variant="primary"
                data-testid="download-csv"
              >
                {isProcessingRequest && <ButtonLoadingIcon />} Download CSV
              </Button>
            </ButtonGroup>
          </div>
          <div css={styles.metaData}>
            {data?.brandName && (
              <p>
                <span css={styles.metaDataHeading}>Brand name:</span>{" "}
                {data?.brandName}
              </p>
            )}
            {data?.userName && (
              <p>
                <span css={styles.metaDataHeading}>Created by:</span>{" "}
                {data?.userName}
              </p>
            )}
            {data?.createdAt && (
              <p>
                <span css={styles.metaDataHeading}>Started at:</span>{" "}
                {format(
                  parseISO(data?.createdAt),
                  dayJSHelper.format.FullMonthDateTime
                )}
              </p>
            )}
            {data?.completedAt && (
              <p>
                <span css={styles.metaDataHeading}>Finished at:</span>{" "}
                {format(
                  parseISO(data?.completedAt),
                  dayJSHelper.format.FullMonthDateTime
                )}
              </p>
            )}
            {data?.lastUpdatedAt && (
              <p>
                <span css={styles.metaDataHeading}>Last updated at:</span>{" "}
                {/* Set key here so everytime lastUpdatedAt changes, the animation is re-triggered */}
                <span key={data.lastUpdatedAt} css={styles.pulseAnimation}>
                  {format(
                    parseISO(data?.lastUpdatedAt),
                    dayJSHelper.format.FullMonthDateTime
                  )}
                </span>
              </p>
            )}
          </div>
          <table css={brandListStyles.tableBase}>
            <thead>
              <tr>
                <th>File name</th>
                <th css={brandListStyles.durationColumn}>Duration</th>
                <th>Status</th>
                <th>Issue details</th>
              </tr>
            </thead>
            <tbody>
              {data?.files?.map((file, index) => {
                const fileStatus =
                  data?.auditStatus === "CompleteWithoutErrors"
                    ? "Done"
                    : file.fileStatus;

                const fileState = FileState[fileStatus];
                const fileDuration = getDuration(file.durationMS);

                return (
                  <tr key={file.id} data-testid={`file-row-${index}`}>
                    <td css={brandListStyles.textEllipses}>
                      <a
                        href={file.url}
                        css={styles.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {file.title ?? file.url}
                      </a>
                    </td>
                    <td css={brandListStyles.durationColumn}>
                      <span>{fileDuration}</span>
                    </td>
                    <td css={styles.progressIndicator}>
                      <div css={styles.progressIndicatorLabel}>
                        {fileState.label}
                      </div>
                      <ProgressIndicator
                        progress={fileState.progress}
                        variant={fileState.variant}
                      />
                    </td>
                    <td>
                      <span>{file.fileErrorDescription ?? "No issues"}</span>
                      {retryableFiles.includes(file.id) && (
                        <span css={styles.retryFileButton}>
                          <Button
                            disabled={isProcessingRequest}
                            onClick={() => retryFiles([file.id])}
                            variant="link"
                            data-testid="retry-failed-file"
                          >
                            Retry?
                          </Button>
                        </span>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default BrandAuditDetails;
