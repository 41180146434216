import { Divider } from "antd";
import React, { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "src/components/page-container/styles/index";
import { ProjectListingType } from "src/pages/projects";
import appStyles from "src/app/styles";

interface ITabPageContainer {
  content: JSX.Element;
  projectType: ProjectListingType;
}
const TabPageContainer = ({
  content,
  projectType,
}: ITabPageContainer): ReactElement => {
  const { t } = useTranslation();
  const title = useMemo(() => {
    if (projectType === ProjectListingType.LibraryLicenses) {
      return "Access your library licenses.";
    }
    return "Access your commercial licenses.";
  }, [projectType]);

  return (
    <div css={appStyles.container}>
      <div css={styles.tabHeaderContainer}>
        <div css={styles.tabHeaderAdditionalText}>{t("My Projects")}</div>
        <div css={styles.tabHeaderTitle}>{t(title)}</div>
        <Divider css={styles.divider} />
      </div>
      {content}
    </div>
  );
};

export default TabPageContainer;
