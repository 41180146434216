import axios from "axios";
import {
  SortOrder,
  ProjectSortType,
  IProjectSearchResponse,
} from "src/pages/projects/components/project-listings/interfaces";
import { searchProjectsUrl } from "../project-url-helper";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../headers";

const searchProjects = async (
  accessToken: string,
  organisationId: string,
  sort: { by: ProjectSortType; order: SortOrder },
  from = 0,
  axiosOptionOverrides = {},
  filters?: { projectTypes: string[]; licenseStatuses: string[] },
  excludeFilters?: { projectTypes: string[] },
  searchText?: string
): Promise<IProjectSearchResponse | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    ...axiosOptionOverrides,
  };
  const body = {
    from,
    sort,
    organisationId,
    showClientOnly: true,
    filters,
    excludeFilters,
    searchText,
  };
  const { data } = await axios.post<IProjectSearchResponse>(
    searchProjectsUrl(),
    body,
    options
  );
  return data;
};

export default searchProjects;
