import React, { ReactElement } from "react";
import Question from "./components/question";
import styles from "./styles";
import questions from "./data/questions";

const FAQ = (): ReactElement => {
  return (
    <div css={styles.container}>
      <h1>Frequently asked questions</h1>
      {questions.map((item) => {
        return (
          <Question
            question={item.question}
            answer={item.answer}
            key={item.question}
          />
        );
      })}
    </div>
  );
};

export default FAQ;
