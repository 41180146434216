import React, { ReactElement } from "react";

const EyeWithStrikethrough = (
  props: React.SVGProps<SVGSVGElement>
): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.23965 5.4531C4.08702 5.2893 4.00393 5.07266 4.00788 4.84881C4.01183 4.62496 4.10251 4.41139 4.26082 4.25308C4.41913 4.09477 4.63271 4.00408 4.85656 4.00013C5.08041 3.99619 5.29705 4.07928 5.46084 4.2319L8.48964 7.25955C9.53457 6.68467 10.7247 6.27798 12 6.27798C13.765 6.27798 15.3641 7.05794 16.6486 7.98305C17.9366 8.91047 18.9562 10.0211 19.5737 10.7676L19.5783 10.7734C19.8548 11.1167 20 11.551 20 11.9923C20 12.4335 19.8548 12.8678 19.5783 13.2112L19.5737 13.2181C18.8047 14.1545 17.9359 15.0044 16.9827 15.7526L19.7604 18.5314C19.913 18.6952 19.9961 18.9119 19.9921 19.1357C19.9882 19.3596 19.8975 19.5731 19.7392 19.7315C19.5809 19.8898 19.3673 19.9804 19.1435 19.9844C18.9196 19.9883 18.703 19.9053 18.5392 19.7526L4.23965 5.4531ZM14.3237 13.0948C14.5516 12.6153 14.6255 12.077 14.5352 11.5538C14.445 11.0306 14.195 10.5482 13.8195 10.1727C13.4441 9.79731 12.9617 9.5473 12.4385 9.45702C11.9153 9.36675 11.377 9.44063 10.8975 9.66853L14.3237 13.0948ZM4.42744 10.7676C4.79495 10.3218 5.30531 9.74803 5.93089 9.16047L14.1152 17.3448C13.4346 17.58 12.7201 17.7022 12 17.7065C10.235 17.7065 8.63596 16.9277 7.3514 16.0026C6.06338 15.0741 5.04379 13.9635 4.42628 13.2181L4.42168 13.2112C4.14689 12.8645 3.99819 12.4346 4.00002 11.9923C4.00002 11.551 4.14518 11.1167 4.42168 10.7734L4.42744 10.7676Z"
        fill="white"
      />
    </svg>
  );
};

export default EyeWithStrikethrough;
