import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  floatingLabelError: css`
    border: 1px solid var(--semantic-alert-red-bg) !important;
  `,
  floatingLabelInput: css`
    input {
      height: 64px;
      padding: 1.7rem 0.735rem 1rem 0.735rem;
      border: 1px solid ${theme.colors.secondary.mediumGrey};
      background-color: ${theme.colors.background.landingDarkGray};

      :disabled {
        background-color: ${theme.colors.background.landingDarkGray};
      }
    }
  `,
};
