import React from "react";
import style from "../styles";

export default () => {
  return [
    {
      title: <div css={style.titleContainer}>Username</div>,
      dataIndex: "name",
      key: "key",
      width: "5%",
    },
    {
      title: <div css={style.titleContainer}>Selected Projects only</div>,
      dataIndex: "hasWhitelist",
      key: "key",
      width: "20%",
    },
    {
      title: <div css={style.titleContainer}>Selected Projects</div>,
      dataIndex: "whiteListedProjects",
      key: "key",
      width: "65%",
    },
  ];
};
