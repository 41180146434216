const questions = [
  {
    question: "What kinds of assets can I test?",
    answer: `The short answer is ANYTHING! 

      You can test short-form sounds like sonic logos and UX/UI sounds.
      You can test long-form sounds like songs, jingles, and voice-overs.
      You can test sound/music on its own, locked up with visual imagery, or synced to video content.
      You can test advertising assets created for television, radio, YouTube, TikTok, Instagram, or any other sound-on medium.`,
  },
  {
    question: "What kinds of tests can I run?",
    answer: `There are two different tests that you can run:

    1. A/B Test – this is a method of evaluating two or more assets by dividing the audience into groups and exposing each group to a different asset. The goal is to determine which asset is more effective by measuring performance on a set of pre-defined metrics such as appeal, memorability, brand fit, and audience alignment. A/B tests are generally cheaper and quicker to complete than Brand Impact Tests.
    2. Brand Impact Test – quantifies the impact of one or more assets on “emotion-weighted preference” for your brand in a fully competitive context. Emotion-weighted preference is a scientifically-validated metric that combines conscious and sub-conscious response to your asset(s); the metric has been shown to be the most reliable predictor of behavioural response (in other words, sales lift) of any research technique.

    If you have requirements that don’t quite fit into one of these options, you can speak with one of our experts who can customize an approach to suit your needs.`,
  },
  {
    question: "How much will it cost?",
    answer: `Tests can be completed for as little as $4,000 and will vary based on the number and type of assets you want to test, your target audience, and your test markets.

    When you create your test, you will see standard pricing based on your parameters…but you will always have the option to speak with one of our experts who can adapt the approach to suit both your needs and budget.
    Your subscription may include a set number of measurements – to find out more please contact your account manager.`,
  },
  {
    question: "How long does it take?",
    answer: `A/B tests can be completed in as little as a few hours. Brand Impact Tests can be completed in as little as 3 days. The timeline for both types of test will vary based on the number and type of assets you want to test, your target audience, and your test markets.

    When you create your test, you will see an estimate delivery timeframe based on your specific parameters…but you always have the option to speak with one of our experts who can adapt the approach to suit both your requirements and timeline.`,
  },
  {
    question: "Can I specify my audience?",
    answer: `Absolutely.

    The standard set of profilers allows you to specify age, gender, and country. You can add non-standard profilers such as brand awareness or usage, ethnicity, marital status, income / socio-economic level, and key attitudes in consultation with one of our experts.
    
    Adding profile variables can impact the cost of your test, so use them with caution.`,
  },
  {
    question: "Where do you get participants from?",
    answer: `With over 20 online access panels in our network, we can tap into tens of millions of participants in over 60 countries.

    Each of the panels in our network has been thoroughly vetted to ensure that they meet critical standards in recruitment, identity verification, data quality, panel management, and incentive administration.
    
    All of this means that we can get just the right audience for you, at the best possible cost.`,
  },
];

export default questions;
