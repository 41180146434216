import { SerializedStyles, css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";

import theme from "src/theme";

export default {
  mainProfileAndContactContainer: css`
    position: relative;
    display: flex;
    flex-direction: column;

    @media ${queries.extraLarge} {
      flex-direction: row;
    }

    .swiper-slide {
      transition: all 0.15s ease-in-out;
      width: 100%;

      @media ${queries.medium} {
        width: 40%;
      }

      @media ${queries.extraLarge} {
        width: 35%;

        :nth-child(2n) {
          width: 45%;
        }
      }
    }

    .swiper-pagination {
      bottom: 0;

      @media ${queries.medium} {
        display: none;
      }
    }

    .swiper-pagination-bullet {
      background: ${theme.colors.secondary.mediumGrey};
    }

    .swiper-pagination-bullet-active {
      background: ${theme.colors.secondary.waveformGray};
    }
  `,
  profileDividers: css`
    .contact-tile-main-container {
      border-left: 1px solid ${theme.colors.darkCharcoal};
    }
  `,
  profileLargeDesktopDividers(child: number): SerializedStyles {
    return css`
      .contact-tile-main-container {
        border-left: 1px solid ${theme.colors.darkCharcoal};
        &:nth-child(${child}) {
          border-left: none;
        }
      }
    `;
  },
  mobileProfileBorders: css`
    li {
      border-left: 1px solid ${theme.colors.darkCharcoal};
      &:first-child {
        border: none;
        .contact-tile-main-container {
          padding-left: 0;
        }
      }
    }
  `,
  carouselControllerContainer: css`
    display: flex;
    justify-content: end;
    gap: 24px;
  `,
  profileDataContainerSeparate: css`
    margin-bottom: 24px;
  `,
};
