import React, { ReactElement, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { LicenseFixedDurations, TermStatus } from "src/constants";
import { IProjectTerm } from "src/pages/projects/client-project-view/interfaces";
import termUtils from "src/utils/projects/terms";
import { ConditionallyVisible } from "src/sumo/src/components/visibility";
import styles from "../styles";

interface IProps {
  term: IProjectTerm;
}

const styleMap = new Map([
  [TermStatus.ACTIVE, styles.active],
  [TermStatus.RENEW, styles.lessThan30Days],
  [TermStatus.URGENT, styles.lessThan7Days],
  [TermStatus.EXPIRED, styles.expired],
  [TermStatus.PERPETUITY, styles.perpetuity],
]);

const TermBadge = ({ term }: IProps): ReactElement => {
  const { t } = useTranslation();

  const fixedDurationEndDate =
    term.startDate &&
    term.fixedDuration &&
    termUtils.calculateEndDate(term.startDate, term.fixedDuration);

  const licenseEndDate = term.endDate
    ? new Date(
        new Date(term.endDate || "").setDate(
          new Date(term.endDate || "").getDate() - 1
        )
      ).toISOString()
    : "";

  const status =
    term.fixedDuration === LicenseFixedDurations.InPerpetuity ||
    term.fixedDuration === LicenseFixedDurations.Archival
      ? TermStatus.PERPETUITY
      : termUtils.determineTermStatus(
          term.endDate
            ? licenseEndDate
            : fixedDurationEndDate &&
                new Date(fixedDurationEndDate).toISOString()
        );
  const formattedStatus =
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  const timeRemaining = termUtils.calculateTimeRemainingFromTerm(term);

  return (
    <Fragment>
      <ConditionallyVisible
        condition={termUtils.isTermStartDateInThePast(term.startDate, true)}
      >
        <div css={[styles.statusChip, status && styleMap.get(status)]}>
          {term.fixedDuration === LicenseFixedDurations.InPerpetuity ||
          term.fixedDuration === LicenseFixedDurations.Archival ? (
            <div css={styles.timeRemaining}>{term.fixedDuration}</div>
          ) : (
            <div css={styles.timeRemaining}>
              {formattedStatus}
              {": "}
              <b>
                {timeRemaining} {t("ProjectsPage##remaining")}
              </b>
            </div>
          )}
        </div>
      </ConditionallyVisible>
    </Fragment>
  );
};

export default TermBadge;
