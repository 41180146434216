import { ReactElement } from "react";
import styles from "./styles";

const StudioPlaylists = (): ReactElement => {
  return (
    <div css={styles.playlists}>
      <svg
        id="Artwork"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 252 242"
      >
        <defs>
          <linearGradient
            id="playlists-linear-gradient"
            x1="126"
            y1="181.99"
            x2="126"
            y2="134.73"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4a4c4c" />
            <stop offset="1" stopColor="#4a4c4c" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="playlists-linear-gradient-2"
            x1="107"
            y1="190.12"
            x2="145"
            y2="124.29"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4f5050" />
            <stop offset="1" stopColor="#323434" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="playlists-linear-gradient-3"
            x1="126"
            y1="169.67"
            x2="126"
            y2="110.51"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#323434" />
            <stop offset="1" stopColor="#323434" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="playlists-linear-gradient-4"
            x1="126"
            y1="148.85"
            x2="126"
            y2="96.1"
            xlinkHref="#playlists-linear-gradient"
          />
          <linearGradient
            id="playlists-linear-gradient-5"
            x1="107"
            y1="153.92"
            x2="145"
            y2="88.08"
            xlinkHref="#playlists-linear-gradient-2"
          />
          <linearGradient
            id="playlists-linear-gradient-6"
            x1="126"
            y1="133.47"
            x2="126"
            y2="74.31"
            xlinkHref="#playlists-linear-gradient-3"
          />
          <linearGradient
            id="playlists-linear-gradient-7"
            x1="132.53"
            y1="112.59"
            x2="122.71"
            y2="70.78"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ec5734" />
            <stop offset="1" stopColor="#ec5734" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="playlists-linear-gradient-8"
            x1="107"
            y1="117.71"
            x2="145"
            y2="51.88"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ec5734" />
            <stop
              offset=".06"
              stopColor="rgba(236,87,52,.92)"
              stopOpacity=".92"
            />
            <stop
              offset=".25"
              stopColor="rgba(236,87,52,.64)"
              stopOpacity=".64"
            />
            <stop
              offset=".44"
              stopColor="rgba(236,87,52,.41)"
              stopOpacity=".41"
            />
            <stop
              offset=".62"
              stopColor="rgba(236,87,52,.24)"
              stopOpacity=".24"
            />
            <stop
              offset=".77"
              stopColor="rgba(236,87,52,.11)"
              stopOpacity=".11"
            />
            <stop
              offset=".91"
              stopColor="rgba(236,87,52,.03)"
              stopOpacity=".03"
            />
            <stop offset="1" stopColor="#ec5734" stopOpacity="0" />
          </linearGradient>
        </defs>
        <path
          className="cls-10"
          d="M126,241.5c-66.44,0-120.5-54.06-120.5-120.5S59.56.5,126,.5s120.5,54.06,120.5,120.5-54.06,120.5-120.5,120.5Z"
        />
        <path
          className="cls-11"
          d="M126,219.53c-54.33,0-98.53-44.2-98.53-98.53S71.67,22.47,126,22.47s98.53,44.2,98.53,98.53-44.2,98.53-98.53,98.53Z"
        />
        <path
          className="cls-5"
          d="M126,197.12c-41.97,0-76.12-34.15-76.12-76.12S84.03,44.88,126,44.88s76.12,34.15,76.12,76.12-34.15,76.12-76.12,76.12ZM126,45.94c-41.39,0-75.06,33.67-75.06,75.06s33.67,75.06,75.06,75.06,75.06-33.67,75.06-75.06-33.67-75.06-75.06-75.06Z"
        />
        <path
          className="cls-1"
          d="M172.39,141.43l-46.76-12.59c-2.62-.7-6.48.4-8.64,2.47l-38.21,36.65c-2.15,2.07-1.78,4.31.83,5.02l46.76,12.59c2.62.7,6.48-.4,8.64-2.47l38.21-36.65c2.15-2.07,1.78-4.31-.83-5.02Z"
        />
        <path
          className="cls-4"
          d="M123.89,128.62c.61,0,1.2.07,1.74.21l46.76,12.59c2.62.7,2.99,2.95.83,5.02l-38.21,36.65c-1.71,1.64-4.51,2.68-6.9,2.68-.61,0-1.2-.07-1.74-.21l-46.76-12.59c-2.62-.7-2.99-2.95-.83-5.02l38.21-36.65c1.71-1.64,4.51-2.68,6.9-2.68M123.89,127.62c-2.7,0-5.75,1.19-7.59,2.96l-38.21,36.65c-1.37,1.31-1.95,2.83-1.61,4.16.31,1.23,1.34,2.13,2.88,2.54l46.76,12.59c.61.16,1.28.25,2,.25,2.7,0,5.75-1.19,7.59-2.96l38.21-36.65c1.37-1.31,1.95-2.83,1.61-4.16-.31-1.23-1.34-2.13-2.88-2.54l-46.76-12.59c-.61-.16-1.28-.25-2-.25h0Z"
        />
        <path
          className="cls-7"
          d="M123.89,111.51c.61,0,1.2.07,1.74.21l46.76,12.59c2.62.7,2.99,2.95.83,5.02l-38.21,36.65c-1.71,1.64-4.51,2.68-6.9,2.68-.61,0-1.2-.07-1.74-.21l-46.76-12.59c-2.62-.7-2.99-2.95-.83-5.02l38.21-36.65c1.71-1.64,4.51-2.68,6.9-2.68M123.89,110.51c-2.7,0-5.75,1.19-7.59,2.96l-38.21,36.65c-1.37,1.31-1.95,2.83-1.61,4.16.31,1.23,1.34,2.13,2.88,2.54l46.76,12.59c.61.16,1.28.25,2,.25,2.7,0,5.75-1.19,7.59-2.96l38.21-36.65c1.37-1.31,1.95-2.83,1.61-4.16-.31-1.23-1.34-2.13-2.88-2.54l-46.76-12.59c-.61-.16-1.28-.25-2-.25h0Z"
        />
        <path
          className="cls-9"
          d="M172.39,105.23l-46.76-12.59c-2.62-.7-6.48.4-8.64,2.47l-38.21,36.65c-2.15,2.07-1.78,4.31.83,5.02l46.76,12.59c2.62.7,6.48-.4,8.64-2.47l38.21-36.65c2.15-2.07,1.78-4.31-.83-5.02Z"
        />
        <path
          className="cls-3"
          d="M123.89,92.42c.61,0,1.2.07,1.74.21l46.76,12.59c2.62.7,2.99,2.95.83,5.02l-38.21,36.65c-1.71,1.64-4.51,2.68-6.9,2.68-.61,0-1.2-.07-1.74-.21l-46.76-12.59c-2.62-.7-2.99-2.95-.83-5.02l38.21-36.65c1.71-1.64,4.51-2.68,6.9-2.68M123.89,91.42c-2.7,0-5.75,1.19-7.59,2.96l-38.21,36.65c-1.37,1.31-1.95,2.83-1.61,4.16.31,1.23,1.34,2.13,2.88,2.54l46.76,12.59c.61.16,1.28.25,2,.25,2.7,0,5.75-1.19,7.59-2.96l38.21-36.65c1.37-1.31,1.95-2.83,1.61-4.16-.31-1.23-1.34-2.13-2.88-2.54l-46.76-12.59c-.61-.16-1.28-.25-2-.25h0Z"
        />
        <path
          className="cls-2"
          d="M123.89,75.31c.61,0,1.2.07,1.74.21l46.76,12.59c2.62.7,2.99,2.95.83,5.02l-38.21,36.65c-1.71,1.64-4.51,2.68-6.9,2.68-.61,0-1.2-.07-1.74-.21l-46.76-12.59c-2.62-.7-2.99-2.95-.83-5.02l38.21-36.65c1.71-1.64,4.51-2.68,6.9-2.68M123.89,74.31c-2.7,0-5.75,1.19-7.59,2.96l-38.21,36.65c-1.37,1.31-1.95,2.83-1.61,4.16.31,1.23,1.34,2.13,2.88,2.54l46.76,12.59c.61.16,1.28.25,2,.25,2.7,0,5.75-1.19,7.59-2.96l38.21-36.65c1.37-1.31,1.95-2.83,1.61-4.16-.31-1.23-1.34-2.13-2.88-2.54l-46.76-12.59c-.61-.16-1.28-.25-2-.25h0Z"
        />
        <path
          className="cls-6"
          d="M172.39,69.02l-46.76-12.59c-2.62-.7-6.48.4-8.64,2.47l-38.21,36.65c-2.15,2.07-1.78,4.31.83,5.02l46.76,12.59c2.62.7,6.48-.4,8.64-2.47l38.21-36.65c2.15-2.07,1.78-4.31-.83-5.02ZM115.24,86.05l10.39-10.78,10.18,10.47-20.57.31Z"
        />
        <path
          className="cls-8"
          d="M123.89,56.22c.61,0,1.2.07,1.74.21l46.76,12.59c2.62.7,2.99,2.95.83,5.02l-38.21,36.65c-1.71,1.64-4.51,2.68-6.9,2.68-.61,0-1.2-.07-1.74-.21l-46.76-12.59c-2.62-.7-2.99-2.95-.83-5.02l38.21-36.65c1.71-1.64,4.51-2.68,6.9-2.68M115.24,86.05l20.57-.31-10.18-10.47-10.39,10.78M123.89,55.22c-2.7,0-5.75,1.19-7.59,2.96l-38.21,36.65c-1.37,1.31-1.95,2.83-1.61,4.16.31,1.23,1.34,2.13,2.88,2.54l46.76,12.59c.61.16,1.28.25,2,.25,2.7,0,5.75-1.19,7.59-2.96l38.21-36.65c1.37-1.31,1.95-2.83,1.61-4.16-.31-1.23-1.34-2.13-2.88-2.54l-46.76-12.59c-.61-.16-1.28-.25-2-.25h0ZM117.63,85.01l8.01-8.3,7.84,8.07-15.85.24h0Z"
        />
      </svg>
    </div>
  );
};
export default StudioPlaylists;
