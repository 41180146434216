import { SerializedStyles, css } from "@emotion/react";
import theme from "src/theme";

export default {
  headingContainer: css`
    position: relative;
    display: flex;
    justify-content: space-between;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
    margin-top: 48px;
    margin-bottom: 88px;
  `,
  greetingContainer: css`
    display: flex;
    flex-direction: column;
  `,
  greeting(isDesktop: boolean): SerializedStyles {
    return css`
      font-size: ${isDesktop ? "24px" : "20px"};
      line-height: ${isDesktop ? "28px" : "28px"};
      font-weight: 400;
      padding-bottom: 16px;
    `;
  },
  welcomeMessage(isDesktop: boolean): SerializedStyles {
    return css`
      font-size: ${isDesktop ? "56px" : "32px"};
      line-height: ${isDesktop ? "64px" : "40px"};
      font-weight: 500;
    `;
  },
  logo: css`
    height: 104px;
    max-height: 104px;
  `,
};
