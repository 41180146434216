import React, { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "../interfaces";

const DeleteIcon = ({
  height = theme.svgSize.large,
  width = theme.svgSize.large,
  fill = theme.colors.secondary.blueOnBlack,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      height={height}
      width={width}
      aria-labelledby="delete-button"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2916 5.1416H3.29165V15.1663C3.29165 15.3964 3.47819 15.583 3.70831 15.583H11.875C12.1051 15.583 12.2916 15.3964 12.2916 15.1663V5.1416ZM2.04165 3.8916V15.1663C2.04165 16.0868 2.78784 16.833 3.70831 16.833H11.875C12.7955 16.833 13.5416 16.0868 13.5416 15.1663V3.8916H2.04165Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7083 2.33317C10.7083 1.4127 9.96214 0.666504 9.04167 0.666504H6.54167C5.62119 0.666504 4.875 1.4127 4.875 2.33317V5.13813H10.7083V2.33317ZM9.45833 3.88813V2.33317C9.45833 2.10305 9.27179 1.9165 9.04167 1.9165H6.54167C6.31155 1.9165 6.125 2.10305 6.125 2.33317V3.88813H9.45833Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.45144 7.23724C6.79661 7.23724 7.07644 7.51707 7.07644 7.86224V12.8622C7.07644 13.2074 6.79661 13.4872 6.45144 13.4872C6.10626 13.4872 5.82644 13.2074 5.82644 12.8622V7.86224C5.82644 7.51707 6.10626 7.23724 6.45144 7.23724Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.1319 7.23724C9.47708 7.23724 9.7569 7.51707 9.7569 7.86224V12.8622C9.7569 13.2074 9.47708 13.4872 9.1319 13.4872C8.78672 13.4872 8.5069 13.2074 8.5069 12.8622V7.86224C8.5069 7.51707 8.78672 7.23724 9.1319 7.23724Z"
        fill="#120A23"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 4.48598C0.5 4.1408 0.779822 3.86098 1.125 3.86098H14.4583C14.8035 3.86098 15.0833 4.1408 15.0833 4.48598C15.0833 4.83116 14.8035 5.11098 14.4583 5.11098H1.125C0.779822 5.11098 0.5 4.83116 0.5 4.48598Z"
        fill={fill}
      />
    </svg>
  );
};

export default DeleteIcon;
