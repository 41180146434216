import React from "react";
import { Button } from "@songtradr/component-library";
import { useHistory } from "react-router-dom";
import styles from "./styles";
import UserImage from "../user-image";

export interface IProfileTileProps {
  imageURL?: string;
  name: string;
  role: string;
  jobTitle?: string;
}

const ProfileTile = ({ imageURL, name, role, jobTitle }: IProfileTileProps) => {
  const history = useHistory();

  return (
    <div css={styles.profileTileContainer}>
      <UserImage imageURL={imageURL} name={name} size={112} fontSize={48} />
      <div css={styles.profileDetailsWithButton}>
        <div css={styles.profileDetails}>
          <span css={styles.profileName}>{name}</span>
          <span css={styles.profileRole}>{role}</span>
          {jobTitle && <span css={styles.profileJobTitle}>{jobTitle}</span>}
        </div>
        <Button
          css={styles.editProfileButton}
          variant="important"
          onClick={() => history.push("/profile")}
        >
          Edit profile
        </Button>
      </div>
    </div>
  );
};

export default ProfileTile;
