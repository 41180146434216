import { ReactElement } from "react";

const PlusIcon = (): ReactElement => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.70167V7.29833C12 7.53809 11.8061 7.7311 11.5672 7.7311H7.7311V11.5672C7.7311 11.807 7.53722 12 7.29833 12H4.70167C4.46278 12 4.2689 11.807 4.2689 11.5672V7.7311H0.432776C0.193883 7.7311 0 7.53809 0 7.29833V4.70167C0 4.46278 0.193883 4.2689 0.432776 4.2689H4.2689V0.432776C4.2689 0.193883 4.46278 0 4.70167 0H7.29833C7.53722 0 7.7311 0.193883 7.7311 0.432776V4.2689H11.5672C11.8061 4.2689 12 4.46278 12 4.70167Z"
        fill="white"
      />
    </svg>
  );
};
export default PlusIcon;
