import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  textContainer: css`
    display: block;
    font-family: ${theme.fonts.sofiaPro};
  `,
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 3rem;
  `,
  contentContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  reportContainer: css`
    div {
      margin: 0 auto;
      height: calc(100vh - 103px);

      iframe {
        border: none;
      }
    }
  `,
};
