import Select from "react-select";
import React, { ReactElement } from "react";
import { IOrganisation } from "src/interfaces/organisation";
import styles from "./styles";

export interface IOption {
  value?: string;
  label?: string;
}

interface IProps {
  setValue: (value: IOption) => void;
  orgDropdownData?: IOrganisation[];
  selectedOrg?: IOption;
  id?: string;
}

const formatDropdownOptions = (options?: IOrganisation[]) => {
  if (!options) return [];
  return options.map((option: IOrganisation) => ({
    value: option.id ?? "",
    label: option.name ?? "",
  }));
};

const OrganisationDropdown = ({
  setValue,
  orgDropdownData = [],
  selectedOrg,
  id,
}: IProps): ReactElement | null => {
  const getOrgLabel = (orgId?: string) => {
    const selectedOrgDetails = orgDropdownData.find(
      (organisation) => organisation.id === orgId
    );
    if (selectedOrgDetails?.name) {
      return selectedOrgDetails?.name;
    }
    return undefined;
  };

  return (
    <div css={styles.dropdownContainer}>
      <Select
        id={id}
        isLoading={!orgDropdownData}
        className="org-dropdown"
        classNamePrefix="org-options"
        onChange={(value) => {
          if (value) {
            setValue(value);
          }
        }}
        options={formatDropdownOptions(orgDropdownData)}
        defaultValue={
          selectedOrg?.value && {
            value: selectedOrg.value,
            label: getOrgLabel(selectedOrg.value),
          }
        }
      />
    </div>
  );
};

export default OrganisationDropdown;
