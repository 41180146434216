import { RuleType, ValidateErrorEntity } from "rc-field-form/lib/interface";
import { errorToast } from "src/components/toast-notification";

type TypeUrl = {
  type: RuleType;
};

export enum FormNotificationErrors {
  Required = "This field is required.",
  Url = "This field must be a valid url.",
  Email = "Please enter Email",
  FillOut = "Please fill out the form",
}
export const requiredField = {
  required: true,
};
export const typeUrl: TypeUrl = {
  type: "url",
};

const checkFormAndShowNotification = (errors: ValidateErrorEntity) => {
  // Select first error in form
  const firstError =
    errors?.errorFields[0]?.errors[0] || FormNotificationErrors.FillOut;

  let notification: string;

  // If we don't give form item a specific message, switch case will pick a generic one
  switch (firstError) {
    case FormNotificationErrors.Url:
      notification = "Please provide a URL in the correct format.";
      break;
    case FormNotificationErrors.Email:
      notification = "Please provide a valid email.";
      break;
    case FormNotificationErrors.Required:
      notification =
        "Please fill out all required fields before submitting the form.";
      break;
    default:
      notification = firstError;
  }
  errorToast({ message: notification });
};
export default checkFormAndShowNotification;
