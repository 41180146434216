import axios from "axios";
import { eApplicationType } from "src/interfaces/auth";
import {
  IMemberInvite,
  IMembersInviteResponse,
} from "src/pages/team/interfaces";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";
import { getOrganisationInvitesUrl } from "../../organisation-invites-url-helper";

export const getOrganisationInvites = async (
  accessToken: string,
  organisationId: string,
  searchText: string,
  abortController?: AbortController
): Promise<Array<IMemberInvite>> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    ...{ signal: abortController?.signal },
  };
  const { data } = await axios.post(
    getOrganisationInvitesUrl(organisationId),
    {
      start: 0,
      perPage: 500,
      application: eApplicationType.Portal,
      organisationId,
      searchText,
      orderBy: "Desc",
    },
    options
  );
  return data.items;
};

export default getOrganisationInvites;
