import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ConditionallyVisible } from "src/sumo/src/components/visibility";
import { useTranslation } from "react-i18next";
import config from "src/config";
import { Form, notification, Typography } from "antd";
import IUserProfileProps from "src/interfaces/user-profile";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import {
  Button,
  ButtonLoadingIcon,
  Checkbox,
  ScopedTheme,
} from "@songtradr/component-library";
import countries from "i18n-iso-countries";
import { IOption } from "src/pages/team/table-data/org-member-data";
import { OrganisationRequesterRoles } from "@songtradr/vinyl-common";
import mainStyles from "src/app/styles";
import FloatingLabelInput from "src/components/floating-label-input";
import FloatingLabelSelect from "src/components/floating-label-select";
import CopyrightFooter from "src/components/copyright-footer";
import { IsNullOrEmptyString } from "src/components/hubSpotFormDrawer/helper";
import useAuth from "src/auth/use-auth";
import styles from "./styles";
import PasswordInputWithFloatingLabel from "./components/password-input-with-floating-label";
import PasswordValidationCriteria from "./components/password-validation-criteria";

const FIELDS = {
  firstName: "firstName",
  lastName: "lastName",
  password: "password",
  jobTitle: "jobTitle",
  country: "country",
};

const UserProfileForm = ({ onSubmit }: IUserProfileProps): ReactElement => {
  const { isLoading: isAuthLoading } = useAuth();
  const requiresTermsCheckboxOrgId = config.requiresTermsCheckbox.orgId;
  const { t } = useTranslation();
  const { organisationId } = useParams<{
    organisationId: string;
  }>();
  const [formStatus, setFormStatus] = useState({
    firstName: "",
    lastName: "",
    password: "",
    country: "",
    jobTitle: "",
    isLoading: false,
    error: "",
    isValidPassword: false,
    isConsentChecked: false,
  });

  const updateInputFormField = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value, name } = event.target;
      setFormStatus((oldValues) => ({ ...oldValues, [name]: value }));
    },
    []
  );

  const updateSelectFormField = useCallback((name: string, value?: string) => {
    setFormStatus((oldValues) => ({ ...oldValues, [name]: value }));
  }, []);

  const handleUpdatePassword = useCallback((newPassword: string) => {
    setFormStatus((oldValues) => ({ ...oldValues, password: newPassword }));
  }, []);

  const handleChangePasswordValidity = useCallback(
    (isValidPassword: boolean) => {
      setFormStatus((oldValues) => ({ ...oldValues, isValidPassword }));
    },
    []
  );

  const handleUpdateConsent = useCallback(() => {
    setFormStatus((oldValues) => ({
      ...oldValues,
      isConsentChecked: !oldValues.isConsentChecked,
    }));
  }, []);

  const countryOptions = useMemo(
    () =>
      Object.values(countries.getNames("en", { select: "official" })).map(
        (countryName) => {
          return {
            value: countryName,
            label: countryName,
          };
        }
      ),
    []
  );

  const getJobTitleOptions = useCallback((): IOption[] => {
    return Object.keys(OrganisationRequesterRoles).map((enumKey) => ({
      value: enumKey.toString(),
      label: t(`requesterRoles##${enumKey}`),
    }));
  }, [t]);

  const hasError = useMemo(() => formStatus.error !== "", [formStatus.error]);

  const hasIncompleteFormData = useMemo(() => {
    const {
      firstName,
      lastName,
      country,
      jobTitle,
      isValidPassword,
    } = formStatus;

    return (
      !isValidPassword ||
      IsNullOrEmptyString(firstName) ||
      IsNullOrEmptyString(lastName) ||
      IsNullOrEmptyString(country) ||
      IsNullOrEmptyString(jobTitle)
    );
  }, [formStatus]);

  const handleFormSubmit = async () => {
    const { firstName, lastName, password, country, jobTitle } = formStatus;

    if (hasIncompleteFormData) {
      setFormStatus({
        ...formStatus,
        error: "Please fill all fields",
        isLoading: false,
      });
      return;
    }
    setFormStatus({
      ...formStatus,
      error: "",
      isLoading: true,
    });
    try {
      await onSubmit({
        firstName,
        lastName,
        password,
        country,
        jobTitle,
      });
    } catch (error) {
      log(DataDogLogTypes.ERROR, "Accept invite error", error);
      notification.error({
        message: t("userProfile##Error Creating Profile"),
        description: t(
          "userProfile##An error has ocurred when creating your profile. Please try again."
        ),
        duration: 0,
      });
    } finally {
      setFormStatus({
        ...formStatus,
        isLoading: false,
      });
    }
  };

  const marriotUserIncompleteData = useMemo(
    () => !hasIncompleteFormData && formStatus.isConsentChecked,
    [formStatus.isConsentChecked, hasIncompleteFormData]
  );

  const isSubmitButtonDisabled = useMemo(() => {
    return requiresTermsCheckboxOrgId !== organisationId
      ? hasIncompleteFormData
      : !marriotUserIncompleteData;
  }, [
    hasIncompleteFormData,
    marriotUserIncompleteData,
    organisationId,
    requiresTermsCheckboxOrgId,
  ]);

  return (
    <ScopedTheme mode="dark" css={styles.background}>
      <div css={[mainStyles.container, styles.flexGrow]}>
        <div css={mainStyles.guestPageInnerContainer}>
          <div css={styles.titleAndSubtitleContainer}>
            <Typography.Title css={styles.title} level={3}>
              {t("userProfile##You’ve been invited to Songtradr Studio")}
            </Typography.Title>
            <Typography.Paragraph css={styles.description}>
              {t(
                "userProfile##Complete your account sign up to access the features shared with you."
              )}
            </Typography.Paragraph>
          </div>
          <div css={styles.formContainer}>
            <FloatingLabelInput
              required
              name={FIELDS.firstName}
              label="First Name"
              hasError={hasError}
              disabled={formStatus.isLoading}
              onChange={updateInputFormField}
              value={formStatus.firstName}
              type="text"
              helpMessage={t(
                "userProfile##validation##Please provide your first name"
              )}
            />
            <FloatingLabelInput
              required
              name={FIELDS.lastName}
              label="Last Name"
              hasError={hasError}
              disabled={formStatus.isLoading}
              onChange={updateInputFormField}
              value={formStatus.lastName}
              type="text"
              helpMessage={t(
                "userProfile##validation##Please provide your last name"
              )}
            />
            <FloatingLabelSelect
              id="job-title-select"
              required
              name={FIELDS.jobTitle}
              label="Job Title"
              hasError={hasError}
              disabled={formStatus.isLoading}
              onChange={updateSelectFormField}
              options={getJobTitleOptions()}
              value={formStatus.jobTitle}
              type="text"
              helpMessage={t(
                "userProfile##validation##Please provide your job title"
              )}
            />
            <FloatingLabelSelect
              id="country-select"
              required
              name={FIELDS.country}
              label="Country"
              hasError={hasError}
              disabled={formStatus.isLoading}
              onChange={updateSelectFormField}
              options={countryOptions}
              value={formStatus.country}
              type="text"
              helpMessage={t(
                "userProfile##validation##Please provide your location"
              )}
            />
            <Form.Item name={FIELDS.password} css={styles.passwordContainer}>
              <PasswordInputWithFloatingLabel
                name={FIELDS.password}
                password={formStatus.password}
                hasError={hasError && !formStatus.isValidPassword}
                onPasswordChange={handleUpdatePassword}
              />
            </Form.Item>

            <ConditionallyVisible
              condition={requiresTermsCheckboxOrgId === organisationId}
            >
              <Form.Item css={styles.disclaimer}>
                <div css={styles.checkboxContainer}>
                  <Checkbox
                    checked={formStatus.isConsentChecked}
                    onClick={handleUpdateConsent}
                    css={styles.checkbox}
                    variant="primary"
                  />
                </div>
                <div>
                  By checking this box, you consent that the use of the music on
                  this platform will only be used for content created on behalf
                  of Marriott International and will adhere to the usage rights
                  outlined on page 7 of the User Guide. User Guide is available{" "}
                  <a
                    href={config.externalResources.marriotPlatformWalkthrough}
                    target="_blank"
                    rel="noreferrer noopener"
                    css={styles.link}
                  >
                    here
                  </a>{" "}
                  and in the Resources section of this website.
                </div>
              </Form.Item>
            </ConditionallyVisible>
          </div>

          <Form.Item css={styles.submit}>
            <Button
              variant="primary"
              onClick={handleFormSubmit}
              disabled={
                isSubmitButtonDisabled || formStatus.isLoading || isAuthLoading
              }
            >
              {t("userProfile##Get started")}
              {(formStatus.isLoading || isAuthLoading) && <ButtonLoadingIcon />}
            </Button>
          </Form.Item>

          <PasswordValidationCriteria
            password={formStatus.password}
            onUpdatePasswordValidity={handleChangePasswordValidity}
          />
        </div>
      </div>
      <div css={[mainStyles.container, styles.copyrightFooter]}>
        <CopyrightFooter />
      </div>
    </ScopedTheme>
  );
};

export default UserProfileForm;
