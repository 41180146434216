import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  container: css`
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
    width: 100%;
    margin: 0 auto;
    padding: 0 24px;
  `,
  animationBg: css`
    position: absolute;
    right: 0;
  `,
  headerContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: ${theme.fonts.sofiaPro};
    font-style: normal;
    margin-left: 1rem;
    margin-right: 1.5rem;

    @media ${queries.large} {
      margin-bottom: 50px;
      margin-left: 0;
      margin-right: 0;
    }
  `,
  introContainer: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  logoContainer: css`
    display: flex;
    margin-top: 64px;
    margin-left: 0;
    margin-bottom: 24px;
    width: 100%;
    z-index: 9;
    justify-content: center;

    @media ${queries.large} {
      margin-top: 96px;
      margin-bottom: 64px;
      justify-content: unset;
    }
  `,
  sectionHeader: css`
    display: flex;
    justify-content: center;
    width: 89px;
    margin-top: 44px;
    height: 24px;
    font-family: ${theme.fonts.sofiaPro};
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${theme.colors.secondary.blueOnBlackShadow};
    transform: rotate(90deg);
  `,
  textDecorator: css`
    display: flex;
    justify-content: center;
    width: 2px;
    height: 23px;
    margin-left: 1rem;
    color: ${theme.colors.secondary.blueOnBlackShadow};
    transform: rotate(90deg);
    border: 1px solid #e5ebf2;
    opacity: 0.15;
  `,
  orgLogoContainer: css`
    z-index: 9;
  `,
  borderedContainer: css`
    z-index: 1;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 48px;
    padding-bottom: 48px;
  `,
  flexContainer: (imageUrl?: string) => css`
    display: flex;
    z-index: 1;
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;

    @media ${queries.large} {
      flex-direction: row;
      gap: 40px;
    }

    .column {
      flex: 1;
      max-width: fit-content;
      text-align: left;
      font-size: 1rem;
      font-weight: 400;
      h2 {
        font-family: ${theme.fonts.sofiaPro};
        color: #adadad;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
      }
      &.welcome-text {
        font-family: ${theme.fonts.sofiaPro};
        font-style: normal;
        font-weight: 250;
        font-size: 24px;
        line-height: 32px;
        margin-left: 24px;
      }
      .email {
        font-size: 14px;
        color: #adadad;
      }
      a {
        img {
          height: 74px;
          width: 74px;
          border-radius: 50%;
        }
      }
      .profile-image {
        font-size: 2rem;
        width: 74px;
        background: ${imageUrl || theme.colors.secondary.userIconBackground};
        border-radius: 50%;
        text-align: center;
        font-weight: 800;
        align-items: center;
        line-height: 1.25rem;
        cursor: pointer;
        height: 74px;
        a {
          color: ${theme.colors.black};
          line-height: 74px;
          vertical-align: middle;
          &:hover {
            text-decoration: none;
          }
        }
        img {
          height: 74px;
          width: 74px;
          border-radius: 50%;
        }
      }
    }
  `,
  headerText: css`
    font-weight: 250;
    line-height: 56px;
    color: ${theme.colors.white} !important;
    font-size: 3rem !important;
    margin-bottom: 18px;
    z-index: 9999;
  `,
  link: css`
    display: flex;
    width: 425px;
    align-items: center;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
  `,
  buttonText: css`
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white} !important;
    margin-right: 1rem;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.05em;
    border-bottom: 1px solid #ff4e00;
    margin-bottom: 5px;

    :hover {
      border-bottom: 1px solid ${theme.colors.white} !important;
    }
  `,
  imageContainer: css`
    display: flex;
    margin-right: 60px;

    img {
      border-radius: 10px;
    }
  `,
  orgLogo: css`
    height: 100%;
    object-fit: cover;
    display: block;
    width: auto;
    max-height: 24px;

    @media ${queries.large} {
      max-height: 48px;
    }
  `,
  iconContainer: css`
    height: 34px;
    width: 34px;
  `,
};
