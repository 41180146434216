import React, { ReactElement, useEffect, useState } from "react";
import { Button } from "@songtradr/component-library";
import { useHistory } from "react-router-dom";
import getAllAudits from "src/api/brand-audit";
import useAuth from "src/auth/use-auth";
import { IBrandAuditJob, AuditState } from "src/interfaces/brand-audit";
import dayJSHelper from "src/sumo/src/utils/dayJSHelper";
import useApiPoll from "src/utils/hooks/use-api-poll";
import { format, parseISO } from "date-fns";
import { errorToast } from "src/components/toast-notification";
import STLoadingLogo from "src/components/st-loading-logo";
import styles from "./styles";

enum Tabs {
  ALL_AUDITS = "All Audits",
  MY_AUDITS = "My Audits",
}

const pollingInterval = 10000; // 10 seconds

const BrandAuditList = (): ReactElement | null => {
  const history = useHistory();
  const { getAccessToken, user } = useAuth();
  const [activeTab, setActiveTab] = useState(Tabs.ALL_AUDITS);
  const [isLoading, setIsLoading] = useState(true);
  const { data: allJobs, triggerFetchData } = useApiPoll<IBrandAuditJob[]>({
    fetchData: async (abortController: AbortController) => {
      try {
        return await getAllAudits(getAccessToken(), abortController);
      } catch (e) {
        errorToast({
          message: "There was a problem fetching the data",
        });

        return null;
      }
    },
    // Should always re-poll as new audits may get added
    shouldRePoll: () => true,
    pollingInterval,
  });

  useEffect(() => {
    setIsLoading(true);
    const accessToken = getAccessToken();

    if (!accessToken) {
      return;
    }

    (async () => {
      try {
        await triggerFetchData();
      } catch (e) {
        return;
      }

      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div css={styles.tabContainer}>
        <button
          css={[
            styles.tab,
            activeTab === Tabs.ALL_AUDITS ? styles.activeTab : null,
          ]}
          onClick={() => setActiveTab(Tabs.ALL_AUDITS)}
          type="button"
          data-testid="all-audits-tab"
        >
          {Tabs.ALL_AUDITS}
        </button>
        <button
          css={[
            styles.tab,
            activeTab === Tabs.MY_AUDITS ? styles.activeTab : null,
          ]}
          onClick={() => setActiveTab(Tabs.MY_AUDITS)}
          type="button"
          data-testid="my-audits-tab"
        >
          {Tabs.MY_AUDITS}
        </button>
      </div>
      <div css={styles.tableHeaderContainer}>
        <h1 css={styles.secondaryHeader}>Brand Audits</h1>
        <Button
          variant="primary"
          onClick={() => history.push("/admin/brand-audit/new")}
          data-testid="create-new-audit"
        >
          New audit
        </Button>
      </div>
      {isLoading ? (
        <div css={styles.loadingSpinnerContainer}>
          <STLoadingLogo pageCentered relativePosition />
        </div>
      ) : (
        <table css={styles.tableBase}>
          <thead>
            <tr>
              <th>Audit name</th>
              <th>Brand name</th>
              <th>Started at</th>
              <th>Started by</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {allJobs?.map((job, index) => {
              if (activeTab === Tabs.MY_AUDITS && job.ownerId !== user?.id) {
                return null;
              }

              const jobState = AuditState[job.auditStatus];

              return (
                <tr
                  key={job.id}
                  onClick={() => history.push(`/admin/brand-audit/${job.id}`)}
                  css={styles.clickableRow}
                  data-testid={`audit-${index}`}
                >
                  <td css={styles.textEllipses}>{job.name}</td>
                  <td css={styles.textEllipses}>{job.brandName}</td>
                  <td css={styles.textEllipses}>
                    {format(
                      parseISO(job.createdAt),
                      dayJSHelper.format.FullMonthDateTime
                    )}
                  </td>
                  <td css={styles.textEllipses}>{job.userName}</td>
                  <td>
                    <span css={[styles.statusPill, jobState.styles]}>
                      {jobState.label}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default BrandAuditList;
