import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  dropdownContainer: css`
    .org-dropdown {
      > div {
        background: #1a1a1a !important;
        border: 1px solid #2a2a2a !important;
        color: ${theme.colors.white} !important;
      }
      input {
        height: 40px;
      }
      .org-options {
        &__menu {
          background: #1a1a1a !important;
          border: 1px solid #2a2a2a !important;
          color: ${theme.colors.white} !important;
        }
        &__option {
          background: #1a1a1a !important;
          &:hover {
            background: #2a2a2a !important;
          }
        }
        &__group {
          background: #1a1a1a !important;
        }
        &__menu-portal {
          background: #1a1a1a !important;
        }
        &__single-value {
          color: ${theme.colors.white} !important;
        }
      }
    }
  `,
};
