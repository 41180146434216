import { css } from "@emotion/react";
import theme from "../../../theme";

export default {
  selectionInput: css`
    border-color: ${theme.colors.secondary.blueOnBlack};
    border-radius: 4px;

    input {
      padding: 8px 0 !important;
    }
  `,
  dropdownContainer: css`
    .dropdown {
      > div {
        background: ${theme.colors.background.landingDarkGray} !important;
        border: 1px solid ${theme.colors.secondary.mediumGrey} !important;
        color: ${theme.colors.white} !important;
      }
      input {
        height: 40px;
      }

      .dropdown-options {
        &__menu {
          background: ${theme.colors.background.landingDarkGray} !important;
          border: 1px solid ${theme.colors.secondary.mediumGrey} !important;
          color: ${theme.colors.white} !important;
        }
        &__option {
          background: ${theme.colors.background.landingDarkGray} !important;
          &:hover {
            background: ${theme.colors.secondary.mediumGrey} !important;
          }
        }
        &__single-value {
          margin-top: 10px !important;
          color: ${theme.colors.white} !important;
        }
        &__input {
          color: ${theme.colors.white} !important;
          padding-top: 1rem !important;
        }
        &__control--is-focused {
          box-shadow: 0 0 0 1px ${theme.colors.secondary.mediumGrey} !important;
        }
      }
    }
  `,
  backBtn: css`
    position: absolute;
    top: 0;
    left: 0;
  `,
  copyrightFooter: css`
    width: 100%;
  `,
  mainHeading: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 500;
    margin: 0 0 1rem;
    padding: 0;
    color: ${theme.colors.white};
    line-height: 2.5rem;
    font-size: 2rem;
  `,
  text: css`
    font-size: 1rem;
    font-weight: 400;
    padding: 0;
    color: ${theme.colors.white};
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
  `,
  background: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${theme.colors.background.landingDarkGray};
  `,
  idLabel: css`
    color: ${theme.colors.background.lightGray};
  `,
};
