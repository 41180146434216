import React from "react";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import style from "../styles";

export default (): Array<IColumnsData> => {
  return [
    {
      title: <div css={style.titleContainer}>User</div>,
      dataIndex: "member",
      key: "key",
      width: "90%",
    },
    {
      title: "",
      dataIndex: "removeFromBrand",
      key: "key",
      width: "10%",
    },
  ];
};
