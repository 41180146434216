import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useProjectsSubNav = () => {
  const location = useLocation();
  const [activeProjectSubMenu, setActiveProjectSubMenu] = useState("");

  useEffect(() => {
    let newValue = "";

    if (location.pathname.startsWith("/commerciallicense")) {
      newValue = "commerciallicenses";
    } else if (location.pathname.startsWith("/librarylicenses")) {
      newValue = "librarylicenses";
    }

    setActiveProjectSubMenu(newValue);
  }, [location.pathname]);

  // Pass any attributes to the links here, they'll be added
  // to both the mobile and desktop navs
  return [
    activeProjectSubMenu !== "",
    {
      CommercialLicenses: {
        isActive: activeProjectSubMenu === "commerciallicenses",
        "data-testid": "header-nav-commercial",
        to: "/commerciallicenses",
      },
      LibraryLicenses: {
        isActive: activeProjectSubMenu === "librarylicenses",
        "data-testid": "header-nav-library",
        to: "/librarylicenses",
      },
    },
  ] as const;
};

export default useProjectsSubNav;
