import { ReactElement } from "react";
import { IComponentIconProps } from "./interfaces";

const CloseIcon = ({
  fill,
  height,
  width,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-labelledby="close-icon-title"
      role="img"
    >
      <title id="close-icon-title">Close modal</title>
      <path
        fill={fill}
        d="M17.6568542,5.65685425 C18.2126414,6.21264142 18.2126414,7.11375048 17.6568542,7.66953765 L14.0115985,11.3123657 L17.6568542,14.9578793 C18.2126414,15.5136665 18.2126414,16.4147756 17.6568542,16.9705627 C17.1010671,17.5263499 16.199958,17.5263499 15.6441708,16.9705627 L11.9989151,13.3250491 L8.35582915,16.9705627 C7.80004198,17.5263499 6.89893293,17.5263499 6.34314575,16.9705627 C5.78735858,16.4147756 5.78735858,15.5136665 6.34314575,14.9578793 L9.98623166,11.3123657 L6.34314575,7.66953765 C5.78735858,7.11375048 5.78735858,6.21264142 6.34314575,5.65685425 C6.89893293,5.10106707 7.80004198,5.10106707 8.35582915,5.65685425 L11.9989151,9.29968226 L15.6441708,5.65685425 C16.199958,5.10106707 17.1010671,5.10106707 17.6568542,5.65685425 Z"
      />
    </svg>
  );
};
export default CloseIcon;
