import axios from "axios";
import { buildOrganisationSubscriptionsBaseUrl } from "src/api/base-url-helper";
import { getAuthHeader, getJsonContentHeader } from "src/api/headers";

export const getPasscodes = async (
  accessToken: string,
  orgId: string,
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.post(
    `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}/passcodes/_search`,
    {
      start: 0,
      perPage: 500,
      application: "Portal",
      organisationId: orgId,
      orderBy: "Desc",
    },
    options
  );
  return data;
};

interface IPasscodePostBody {
  applications: string[];
  status: string;
  name: string;
  code: string;
}

interface IPasscodePatchBody {
  applications?: string[];
  status?: string;
  name?: string;
  code?: string;
}

export const createPasscode = async (
  accessToken: string,
  orgId: string,
  passcodeData: IPasscodePostBody,
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.post(
    `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}/passcodes`,
    passcodeData,
    options
  );
  return data;
};

export const deletePasscode = async (
  accessToken: string,
  orgId: string,
  passcodeId: string,
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.delete(
    `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}/passcodes/${passcodeId}`,
    options
  );
  return data;
};

export const patchPasscode = async (
  accessToken: string,
  orgId: string,
  passcodeId: string,
  passcodeData: IPasscodePatchBody,
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.patch(
    `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}/passcodes/${passcodeId}`,
    passcodeData,
    options
  );
  return data;
};

export const generatePasscode = async (
  accessToken: string,
  orgId: string,
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.get(
    `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}/passcodes/_generate`,
    options
  );
  return data;
};
