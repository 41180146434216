import axios, { ResponseType } from "axios";
import { getAxiosOptions } from "../headers";

const downloadFile = async (
  presignedUrl: string,
  contentType: string
): Promise<Blob> => {
  const options = {
    headers: { "Content-Type": contentType },
    responseType: "blob" as ResponseType,
    ...getAxiosOptions(),
  };

  const { data } = await axios.get<Blob>(`${presignedUrl}`, options);
  return data;
};

export default downloadFile;
