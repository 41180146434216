import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  menuContainer: css`
    position: relative;
  `,
  avatarBtn: css`
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    position: relative;
    align-items: center;
    background-color: ${theme.colors.functional.brightOrange};
    display: flex;
    justify-content: center;
  `,
  avatar: css`
    height: 48px;
    width: 48px;
    border-radius: 100%;
    cursor: pointer;
  `,
  chevronContainer: css`
    height: 16px;
    width: 16px;
    position: absolute;
    background-color: ${theme.colors.background.darkGray100};
    right: 0;
    bottom: 0;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  dropdownContainerOpen: css`
    pointer-events: auto;
    opacity: 1;
  `,
  dropdownContainer: css`
    pointer-events: none;
    opacity: 0;
    transition: all 0.15s ease-in-out 0s;
    transform: translate(1.25rem, 2px);
    background: ${theme.colors.black};
    position: absolute;
    top: 3.75rem;
    right: 1rem;
    border-radius: 10px;
    filter: drop-shadow(rgba(0, 0, 0, 0.16) 0 4px 16px);
    z-index: 11;
    border: 1px solid ${theme.colors.background.darkGray100};
    min-width: 220px;
    padding: 1rem;
  `,
  username: css`
    color: ${theme.colors.white};
    margin: 0;
    padding: 0;
    line-height: 20px;
    font-weight: 600;
  `,
  role: css`
    color: ${theme.colors.white};
    margin: 0;
    padding: 0;
    letter-spacing: 0;
    line-height: 15px;
    font-size: 12px;
    font-weight: 300;
  `,
  link: css`
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    font-family: ${theme.fonts.sofiaPro};
    display: block;
    width: 100%;
    text-align: left;
    padding: 8px 0;
    border-top: 1px solid ${theme.colors.background.darkGray100};
    font-weight: 300;
    cursor: pointer;

    &,
    &:hover,
    &:focus {
      color: ${theme.colors.white};
      text-decoration: none;
    }
  `,
  userData: css`
    display: block;
    padding: 8px 0;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  `,
};
