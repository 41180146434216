import { useTranslation } from "react-i18next";
import { Form } from "antd";
import { eApplicationType } from "src/interfaces/auth";
import { useCallback, useEffect, useState } from "react";
import { Button } from "@songtradr/component-library";
import styles from "./styles";
import PermissionCheckbox from "./permission-checkbox";

interface IRolesAndPermissionsProps {
  allApplications: eApplicationType[];
  inviteButtonText: string;
  isMobile: boolean;
  onCancel: () => void;
  onFormSubmit: (selectedPermissions: eApplicationType[]) => Promise<void>;
}

const RolesAndPermission = ({
  allApplications,
  inviteButtonText,
  isMobile,
  onCancel,
  onFormSubmit,
}: IRolesAndPermissionsProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectedPermissions, setSelectedPermissions] = useState<
    eApplicationType[]
  >([]);

  const isPermissionChecked = useCallback(
    (permission: eApplicationType): boolean => {
      return (
        (selectedPermissions.includes(eApplicationType.TeamManagement) &&
          (allApplications ?? []).includes(permission)) ||
        selectedPermissions.includes(permission)
      );
    },
    [allApplications, selectedPermissions]
  );

  const isPermissionCheckboxDisabled = useCallback(
    (permission: eApplicationType): boolean => {
      return (
        selectedPermissions.includes(eApplicationType.TeamManagement) ||
        !(allApplications ?? []).includes(permission)
      );
    },
    [allApplications, selectedPermissions]
  );

  const handlePermissionClick = useCallback(
    (applicationPermission: eApplicationType) => {
      if (selectedPermissions.includes(applicationPermission))
        setSelectedPermissions((prevState) => [
          ...prevState.filter(
            (permission) => permission !== applicationPermission
          ),
        ]);
      else {
        const isLibraryFullId =
          applicationPermission === eApplicationType.LibraryFull;
        const isLibraryReadOnlyId =
          applicationPermission === eApplicationType.LibraryReadOnly;
        const isLibraryFullSelected = selectedPermissions.includes(
          eApplicationType.LibraryFull
        );
        const isLibraryReadOnlySelected = selectedPermissions.includes(
          eApplicationType.LibraryReadOnly
        );

        if (isLibraryFullId && isLibraryReadOnlySelected) {
          setSelectedPermissions((prevState) => [
            ...prevState.filter(
              (permission) => permission !== eApplicationType.LibraryReadOnly
            ),
            applicationPermission,
          ]);
        } else if (isLibraryReadOnlyId && isLibraryFullSelected) {
          setSelectedPermissions((prevState) => [
            ...prevState.filter(
              (permission) => permission !== eApplicationType.LibraryFull
            ),
            applicationPermission,
          ]);
        } else {
          setSelectedPermissions((prevState) => [
            ...prevState,
            applicationPermission,
          ]);
        }
      }
    },
    [selectedPermissions]
  );

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, [form]);

  return (
    <div
      data-testid="invite-member-permissions"
      css={styles.inviteMembersContainer}
    >
      <div css={styles.header}>
        {t("inviteMembers##Access and permissions")}
      </div>
      <div css={styles.subtitle}>
        {t("inviteMembers##Permissions subtitle")}
      </div>
      <Form
        form={form}
        onFinish={() => onFormSubmit(selectedPermissions)}
        layout="vertical"
        css={styles.formContainer}
      >
        <div css={styles.checkboxContainer}>
          <PermissionCheckbox
            permission={eApplicationType.TeamManagement}
            checked={selectedPermissions.includes(
              eApplicationType.TeamManagement
            )}
            dataTestId="admin-checkbox"
            disabled={false}
            label={t("inviteMembers##Enterprise admin")}
            onClick={handlePermissionClick}
          />
          {[
            {
              permission: eApplicationType.LibraryFull,
              dataTestId: "music-checkbox",
              label: t("inviteMembers##Library"),
            },
            {
              permission: eApplicationType.LibraryReadOnly,
              dataTestId: "music-checkbox-read-only",
              label: t("inviteMembers##LibraryReadOnly"),
            },
            {
              permission: eApplicationType.ProjectsClient,
              dataTestId: "projects-checkbox",
              label: t("inviteMembers##Projects"),
            },
            {
              permission: eApplicationType.Resources,
              dataTestId: "resources-checkbox",
              label: t("inviteMembers##Resources"),
            },
            {
              permission: eApplicationType.Validate,
              dataTestId: "validate-checkbox",
              label: t("inviteMembers##Measurement"),
            },
          ].map(({ permission, dataTestId, label }) => {
            return (
              <PermissionCheckbox
                key={dataTestId}
                permission={permission}
                checked={isPermissionChecked(permission)}
                dataTestId={dataTestId}
                disabled={isPermissionCheckboxDisabled(permission)}
                label={label}
                onClick={handlePermissionClick}
              />
            );
          })}
        </div>
        <div
          css={
            isMobile
              ? styles.mobileButtonContainer
              : styles.tabletDesktopButtonContainer
          }
        >
          <Button
            variant="subtle"
            onClick={onCancel}
            className="inviteMembersCancelButton"
            css={styles.cancelBtn}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="primary"
            data-testid="send-invite-button"
            className="inviteMembersSendButton"
          >
            {inviteButtonText}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default RolesAndPermission;
