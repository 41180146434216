import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  profileTileContainer: css`
    width: 100%;
    height: 160px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${theme.colors.darkCharcoal};
    background-color: ${theme.colors.background.landingDarkGray};
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.75);
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
    margin-right: 48px;

    @media ${queries.small} {
      width: 510px;
      padding: 24px;
    }
  `,
  profileDetailsWithButton: css`
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    text-align: left;
    flex: 1;
    flex-wrap: wrap;
  `,
  profileDetails: css`
    display: flex;
    flex-direction: column;
    text-align: left;
    flex: 1;
  `,
  profileName: css`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    word-wrap: break-word;
  `,
  profileRole: css`
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    word-wrap: break-word;
  `,
  profileJobTitle: css`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    word-wrap: break-word;
  `,
  editProfileButton: css`
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    margin-top: 16px;
    height: 48px !important;
  `,
};
