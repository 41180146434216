import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  createButton: css`
    float: right;
  `,
  modalText: css`
    color: ${theme.colors.white} !important;
  `,
  deleteModalButtons: css`
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;

    button:nth-of-type(1) {
      margin-right: 16px;
    }
  `,
  loadingLogo: css`
    top: 10%;
  `,
};
