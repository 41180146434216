import axios, { AxiosResponse } from "axios";
import { IInvitemembersRequest } from "src/interfaces/invite-members";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";
import { sendOrganisationInvitesUrl } from "../../organisation-invites-url-helper";

export interface IOrgInvitesResponse {
  existingInvites: string[];
  existingUsers: string[];
  invalidEmails: string[];
  invitedEmails: string[];
}

async function sendOrganisationInvites(
  organisationId: string,
  accessToken: string,
  body: IInvitemembersRequest
): Promise<AxiosResponse<IOrgInvitesResponse>> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.post(sendOrganisationInvitesUrl(organisationId), body, options);
}

export default sendOrganisationInvites;
