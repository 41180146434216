import React from "react";
import Lottie from "react-lottie-player";
import { LeftOutlined } from "@ant-design/icons";
import backgroundLinesAnimation from "src/app/assets/animations/bars_bg.json";
import globalStyles from "src/app/styles";
import styles from "../../styles";

interface IValidateHeaderProps {
  onGoBack: () => void;
}
const ValidateHeader = ({ onGoBack }: IValidateHeaderProps) => {
  return (
    <>
      <div css={[globalStyles.myResearchContainer, styles.backBtnContainer]}>
        <button
          css={styles.backBtn}
          type="button"
          aria-label="Back"
          name="Back"
          onClick={onGoBack}
        >
          <LeftOutlined />
        </button>
        <button type="button" onClick={onGoBack} css={styles.backText}>
          BACK
        </button>
      </div>
      <div css={styles.animationBg}>
        <Lottie
          animationData={backgroundLinesAnimation}
          style={{ width: 1000, height: 175 }}
          loop
          play
        />
      </div>
    </>
  );
};

export default ValidateHeader;
