import React, { ReactElement } from "react";
import Lottie from "react-lottie-player";
import { LeftOutlined } from "@ant-design/icons";
import backgroundLinesAnimation from "src/app/assets/animations/bars_bg.json";
import { useHistory, useLocation } from "react-router-dom";
import NotFound from "src/pages/not-found";
import headerStyles from "../header/styles";
import dashHeaderStyles from "../../../dashboard/components/header/styles";

import styles from "./styles";

const caseStudies = [
  {
    title: (
      <>
        <strong>Zalando:</strong> Never Out Of Fashion
      </>
    ),
    lede: `‘Silent Night’. Everyone knows it. Everyone can sing it. No matter the language. 
    But what about combining all that international heritage in one recording, sung in five 
    different languages by not necessarily musically trained people to give it that ‘unblemished’ touch?`,
    video: undefined,
    challenge: `That’s what happened with ‘Never Out of Fashion’, the new Christmas campaign from Zalando, Europe’s leading online platform for fashion and lifestyle. Created with Wieden+Kennedy Amsterdam and directed by film director Jake Nava, we had the honour to work intensively with more than a hundred people on set.
    This is already a challenge in itself, but we knew we also had to make it sound good.`,
    approach: `For the shoot of the film, we flew to London, where we trained and conducted a choir of more than a hundred people. Jake Nava, Film Director on this project, explains the approach: “The idea was to execute the script in a way which made the viewer feel like they were a participant in a kind of experiential, alternative show more than watching another Christmas TV commercial. Using this approach, I wanted to draw the audience into the emotional landscape of the performers, with as few obstacles to that as possible, but in a style which was still engaging to watch from beginning to end.”`,
    solution: `The massive choir (pun very intended) makes the campaign stand out both visually and musically.
    We ended up adding choir harmonies, doubles, background vocals and even extra instrumentation with the result being a brand new version of the popular song, one that embraces unity, modernity and keeps the Christmas spirit alive.
    
    Tom Tukker, Senior Music Producer at MassiveMusic, says: “For this project, it was really interesting to see how the shoot went hand in hand with the music. We teamed up and worked very intensively with all parties involved, including creative agency Wieden+Kennedy Amsterdam as well as director Jake Nava, known for his work with Beyoncé and Arctic Monkeys among others.”
    
    He continues: “We had the honour to work with more than a hundred people on set, from different cultures, ages and walks of life. The fact that the majority of them were not professional singers made it even more authentic. Unbelievable to think it only took us four days to become a real choir.”`,
    route: `/study/zalando`,
  },
];

const CaseStudy = (): ReactElement => {
  const history = useHistory();
  const location = useLocation();

  const onGoBack = () => {
    history.goBack();
    window.scrollTo(0, 0);
  };

  const selectedCaseStudy = caseStudies.find(
    (study) => study.route === location.pathname
  );

  if (!selectedCaseStudy) {
    return <NotFound />;
  }

  return (
    <div css={styles.container}>
      <div css={headerStyles.backBtnContainer}>
        <button
          css={headerStyles.backBtn}
          type="button"
          aria-label="Back"
          name="Back"
          onClick={() => onGoBack()}
        >
          <LeftOutlined />
        </button>
        <button
          type="button"
          onClick={() => onGoBack()}
          css={headerStyles.backText}
        >
          BACK
        </button>
      </div>
      <div css={headerStyles.headerContainer}>
        <div css={dashHeaderStyles.animationBg}>
          <Lottie
            animationData={backgroundLinesAnimation}
            style={{ width: 1000, height: 175 }}
            loop
            play
          />
        </div>
      </div>
      <div className="content">
        <h1>{selectedCaseStudy.title}</h1>
        <p>{selectedCaseStudy.lede}</p>
        <div css={styles.videoContainer} />
        <h2>Challenge</h2>
        <p>{selectedCaseStudy.challenge}</p>
        <h2>Approach</h2>
        <p>{selectedCaseStudy.approach}</p>
        <h2>Solution</h2>
        <p>{selectedCaseStudy.solution}</p>
      </div>
    </div>
  );
};

export default CaseStudy;
