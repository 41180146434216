import { buildVinylPowerBiBaseUrl } from "./base-url-helper";

export const getAvailableReportsUrl = (organisationId: string): string => {
  return `${buildVinylPowerBiBaseUrl()}/available-reports?orgId=${organisationId}`;
};

export const getAvailableReportByIdUrl = (
  organisationId: string,
  reportId: string
): string => {
  return `${buildVinylPowerBiBaseUrl()}/available-reports/${reportId}/_access?orgId=${organisationId}`;
};
