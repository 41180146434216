import React, { ReactElement } from "react";
import { IUnderlinedButtonProps } from "./interfaces";
import styles from "./styles";

const UnderlinedButton = ({
  onClick,
  hasUnderline = false,
  className,
  children,
  ...rest
}: IUnderlinedButtonProps): ReactElement => {
  return (
    <button
      className={className}
      type="button"
      onClick={onClick}
      css={[styles.btn, hasUnderline ? styles.hasUnderline : null]}
      {...rest}
    >
      {children}
    </button>
  );
};

export default UnderlinedButton;
