import React, { ReactElement, useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { Form, Upload } from "antd";
import { Button, DeleteIcon } from "@songtradr/component-library";
import { useTranslation } from "react-i18next";
import { useImmer } from "use-immer";
import deleteProfileImage from "src/api/delete-profile-image";
import { OrganisationRequesterRoles } from "@songtradr/vinyl-common";
import selfOrganisations from "src/api/organisation/self";
import useAuth from "src/auth/use-auth";
import { errorToast, successToast } from "src/components/toast-notification";
import updateProfileImage, { IImgFormData } from "src/api/update-profile-image";
import BaseModal from "src/components/modals/base-modal";
import ModalPortal from "src/components/modal-portal";
import countries from "i18n-iso-countries";
import theme from "src/theme";
import { getIsMobile } from "@songtradr/spa-common";
import FloatingLabel from "src/components/floating-label";
import axios from "axios";
import FloatingLabelInput from "src/components/floating-label-input";
import STLoadingLogo, {
  STLoadingLogoSize,
} from "src/components/st-loading-logo";
import SlideDrawer from "../team/components/slide-drawer";
import imageUploadSteps from "./components/image-upload-steps";
import styles from "./styles";
import {
  IImgPreviews,
  FileUpload,
  IOption,
  IFormState,
  IProps,
} from "./interfaces";

const FIELDS = {
  firstName: "firstName",
  lastName: "lastName",
  phoneNumber: "phoneNumber",
  jobTitle: "jobTitle",
  companyName: "companyName",
  country: "country",
  email: "email",
  organization: "organization",
};

const validImgMimeTypes = ["image/png", "image/jpeg"];
const mbToByteConversions = {
  "5": 5242880,
  "10": 10485760,
};

const Profile = ({
  submitForm,
  isLoading,
  profile,
  resetPassword,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const isMobile = getIsMobile();
  const [selectedOrg, setSelectedOrg] = useState<IOption | undefined>();
  const [formState, setFormState] = useImmer<IFormState>({} as IFormState);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const getJobTitleOptions = (): IOption[] => {
    const jobTitleOptions: IOption[] = [];

    Object.keys(OrganisationRequesterRoles).forEach((enumKey) => {
      jobTitleOptions.push({
        value: enumKey.toString(),
        label: t(`requesterRoles##${enumKey}`),
      });
    });

    return jobTitleOptions;
  };

  const formatOptionLabel = useCallback((data: IOption) => {
    const { label, value } = data;
    return (
      <>
        <div css={styles.idLabel}>
          <div className="orgName">{label}</div>|
          <div className="orgId">{value}</div>
        </div>
      </>
    );
  }, []);

  const countryOptions = Object.values(
    countries.getNames("en", { select: "official" })
  ).map((country) => {
    return {
      value: country,
      label: country,
    };
  });

  const [allOrganisations, setAllOrganisations] = useState<IOption[] | []>([]);
  const [isLoadingOrgs, setIsLoadingOrgs] = useState(false);
  const {
    getAccessToken,
    organisationId,
    switchOrg,
    hasMultipleOrgs,
    user,
    profileImage,
  } = useAuth();
  const accessToken = getAccessToken();
  const [userProfileImage, setUserProfileImage] = useState(profileImage);
  const [imageGuidelinesOpen, setImageGuidelinesOpen] = useState(false);
  const [isImageUploadOpen, setIsImageUploadOpen] = useState(false);

  const handleImgDeletion = async (filename: keyof IImgPreviews) => {
    if (!user?.id) {
      return Promise.reject();
    }

    try {
      await deleteProfileImage(organisationId, user.id, accessToken, {
        [filename]: true,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        errorToast({ message: error.response?.data.Message });
      }

      return Promise.reject();
    }

    setUserProfileImage(undefined);
    successToast({ message: "Image was successfully deleted." });

    return Promise.resolve();
  };

  const handleImgUpload = async (
    { file, filename }: FileUpload,
    maxFileSizeInMb: keyof typeof mbToByteConversions
  ) => {
    const formData = new FormData();

    if (!user?.id) {
      return Promise.reject();
    }

    if (file.size > mbToByteConversions[maxFileSizeInMb]) {
      errorToast({
        message: `File is too large, maximum size is ${maxFileSizeInMb}mb`,
      });

      return Promise.reject();
    }

    if (!validImgMimeTypes.includes(file.type)) {
      errorToast({
        message: "Invalid file type, only JPEG and PNG files are allowed",
      });

      return Promise.reject();
    }
    setIsUploadingImage(true);
    formData.append(filename, file);

    try {
      await updateProfileImage(
        organisationId,
        user.id,
        accessToken,
        (formData as unknown) as IImgFormData
      );
    } catch (error) {
      errorToast({ message: error.response.data.Message });
      return await Promise.reject();
    } finally {
      setIsUploadingImage(false);
    }
    const objectUrl = URL.createObjectURL(file);

    setUserProfileImage(objectUrl);

    successToast({ message: "Image upload successful." });
    return Promise.resolve();
  };

  const toggleMenu = () => {
    setIsImageUploadOpen(!isImageUploadOpen);
  };

  const renderImg = () => {
    if (!userProfileImage) {
      return <div css={styles.profileCircle}> {user?.name?.charAt(0)} </div>;
    }

    return (
      <div>
        <img src={userProfileImage} alt="" data-testid="profile-user-image" />
        <div css={styles.mobileDeleteBtn}>
          <button
            css={styles.buttonContainer}
            onClick={() => handleImgDeletion("profileImage")}
            data-testid="back-button"
            data-heapid="Back"
            type="button"
          >
            <DeleteIcon fill={theme.colors.white} />
          </button>
        </div>
      </div>
    );
  };

  const ImageGuidelinesModalContent = () => {
    return (
      <>
        <h2 css={styles.modalTitle}>Logo Image Guidelines</h2>
        <div css={styles.modalContent}>
          <ul>
            <li>
              The preferred dimensions are 512 pixels, so make sure your profile
              image is at least that size. If it&apos;s smaller, it may not be
              clear and could appear blurry or pixelated.
            </li>
            <li>
              Ideally, your profile image should be square in shape. If
              it&apos;s not square, that&apos;s okay, but it may be resized to
              fit the square dimensions.
            </li>
            <li>The preferred format is either PNG or JPG.</li>
            <li>
              The maximum file size should not exceed 5MB. If your logo is too
              large, you may need to compress the file or save it at a lower
              resolution.
            </li>
          </ul>
        </div>
      </>
    );
  };

  useEffect(() => {
    setFormState((draft) => {
      draft.firstName = profile?.firstName ?? "";
      draft.lastName = profile?.lastName ?? "";
      draft.companyName = profile?.companyName ?? "";
      draft.phoneNumber = profile?.phoneNumber ?? "";
      draft.email = profile?.email ?? "";
      draft.jobTitle = { label: profile?.jobTitle, value: profile?.jobTitle };
      draft.country = { label: profile?.country, value: profile?.country };
    });

    setUserProfileImage(profile?.profileImage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  useEffect(() => {
    if (!hasMultipleOrgs) {
      return;
    }

    // eslint-disable-next-line consistent-return
    (async () => {
      try {
        setIsLoadingOrgs(true);
        const response = await selfOrganisations(getAccessToken());

        if (!response || !response.items) {
          return false;
        }

        setAllOrganisations(
          response.items.map((org) => {
            return {
              value: org.id ? org.id : "",
              label: `${org.name} | ${org.id}`,
            };
          })
        );

        const selectedOrgValue = response.items.find(
          (org) => org.id === organisationId
        );

        if (selectedOrgValue && selectedOrgValue.id) {
          setSelectedOrg({
            value: selectedOrgValue.id,
            label: selectedOrgValue.name,
          });
        }
      } catch (e) {
        errorToast({
          message:
            "There was a problem retrieving organizations data. Please try again.",
        });
      } finally {
        setIsLoadingOrgs(false);
      }
    })();
  }, [getAccessToken, hasMultipleOrgs, organisationId]);

  return (
    <div css={styles.profilePage}>
      <div>
        {isLoading ? (
          <STLoadingLogo pageCentered />
        ) : (
          <>
            {imageGuidelinesOpen && (
              <ModalPortal>
                <BaseModal
                  css={styles.modalContainer}
                  visible={imageGuidelinesOpen}
                  content={<ImageGuidelinesModalContent />}
                  buttons={[]}
                  onClose={() => setImageGuidelinesOpen(false)}
                />
              </ModalPortal>
            )}
            <div>
              <div css={styles.header}>
                <div css={styles.yourProfile} data-testid="profile-user-name">
                  Your profile
                  <div css={styles.dividerLine} />
                  <div css={styles.instruction}>
                    Please complete your profile
                  </div>
                </div>
              </div>

              <div css={styles.profileContent}>
                <div>
                  <div css={styles.sectionHeader}>Profile image</div>
                  <div css={styles.imageUpload}>
                    <div css={styles.uploadContainer}>
                      <div
                        className="org-logo"
                        css={styles.uploadImageContainer}
                      >
                        {isUploadingImage && (
                          <STLoadingLogo
                            size={STLoadingLogoSize.Medium}
                            styles={styles.uploadingImageLogo}
                          />
                        )}
                        {renderImg()}
                      </div>
                      <div css={styles.uploadActions}>
                        <div css={styles.uploadButtons}>
                          {isMobile ? (
                            <Upload
                              className="parent"
                              customRequest={(file) =>
                                handleImgUpload(file, "10")
                              }
                              showUploadList={false}
                              name="profileImage"
                              data-testid="upload-image"
                            >
                              <Button variant="important">Upload image</Button>
                            </Upload>
                          ) : (
                            <Button
                              variant="important"
                              onClick={() => setIsImageUploadOpen(true)}
                            >
                              Upload image
                            </Button>
                          )}

                          <div css={styles.deleteImageBtn}>
                            <Button
                              variant="link"
                              onClick={() => handleImgDeletion("profileImage")}
                              data-testid="delete-image"
                              onKeyDown={() =>
                                handleImgDeletion("profileImage")
                              }
                            >
                              Delete image
                            </Button>
                          </div>
                        </div>
                        <div css={styles.guidelineText}>
                          For image guidelines, click
                          <button
                            type="button"
                            onClick={() => setImageGuidelinesOpen(true)}
                            css={styles.linkText}
                          >
                            here
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div css={styles.detailsContainer}>
                  {hasMultipleOrgs && (
                    <div css={styles.orgSelect}>
                      <div css={styles.sectionHeader}>
                        {t("UserProfilePage##Org select")}
                      </div>
                      <FloatingLabel
                        label="Organization"
                        value={formState.companyName}
                      >
                        <div css={styles.dropdownContainer}>
                          <Select
                            className="dropdown"
                            classNamePrefix="dropdown-options"
                            components={{
                              IndicatorSeparator: null,
                            }}
                            key={selectedOrg && selectedOrg.value}
                            isSearchable
                            isLoading={isLoadingOrgs}
                            defaultValue={selectedOrg}
                            data-testid="org-select"
                            formatOptionLabel={formatOptionLabel}
                            placeholder="Select"
                            css={styles.selectionInput}
                            onChange={async (data: any) => {
                              await switchOrg(data.value as string);
                            }}
                            options={allOrganisations}
                          />
                        </div>
                      </FloatingLabel>
                    </div>
                  )}
                  <div css={styles.sectionHeader}>
                    {t("UserProfilePage##Edit Details")}
                  </div>
                  <div css={styles.formContainer}>
                    <Form
                      layout="vertical"
                      initialValues={formState}
                      onFinish={submitForm}
                    >
                      <div css={styles.inputs}>
                        <div css={styles.formItemAndLabel}>
                          <FloatingLabelInput
                            label={t("UserProfilePage##First name")}
                            name={FIELDS.firstName}
                            value={formState.firstName}
                            dataTestId="first-name-input"
                            onBlurEvent={(e) =>
                              setFormState((draft) => {
                                draft.firstName = e.target.value;
                              })
                            }
                            required
                            helpMessage="Please provide a first name"
                          />
                        </div>
                        <div css={styles.formItemAndLabel}>
                          <FloatingLabelInput
                            label={t("UserProfilePage##Last name")}
                            name={FIELDS.lastName}
                            value={formState.lastName}
                            dataTestId="last-name-input"
                            onBlurEvent={(e) =>
                              setFormState((draft) => {
                                draft.lastName = e.target.value;
                              })
                            }
                            required
                            helpMessage="Please provide a last name"
                          />
                        </div>
                        <div css={styles.formItemAndLabel}>
                          <FloatingLabelInput
                            label={t("UserProfilePage##Email")}
                            value={formState.email}
                            name={FIELDS.email}
                            disabled
                            dataTestId="email-input"
                          />
                        </div>
                        <div css={styles.formItemAndLabel}>
                          <FloatingLabelInput
                            label={t("UserProfilePage##Organisation")}
                            value={formState?.companyName}
                            name={FIELDS.companyName}
                            disabled
                            dataTestId="org-input"
                          />
                        </div>
                        <div css={styles.formItemAndLabel}>
                          <FloatingLabelInput
                            label={t("UserProfilePage##Phone")}
                            value={formState.phoneNumber}
                            name={FIELDS.phoneNumber}
                            dataTestId="phone-number-input"
                            onBlurEvent={(e) =>
                              setFormState((draft) => {
                                draft.phoneNumber = e.target.value;
                              })
                            }
                          />
                        </div>
                        <div css={styles.formItemAndLabel}>
                          <div css={styles.dropdownContainer}>
                            <FloatingLabel
                              label={t("UserProfilePage##Job title")}
                              value={FIELDS.jobTitle}
                            >
                              <Form.Item
                                htmlFor="job-title-select"
                                name={FIELDS.jobTitle}
                              >
                                <Select
                                  id="job-title-select"
                                  className="dropdown"
                                  classNamePrefix="dropdown-options"
                                  aria-label="Job title"
                                  data-testid="job-title-select"
                                  placeholder="Select"
                                  css={styles.selectionInput}
                                  components={{
                                    IndicatorSeparator: null,
                                  }}
                                  onChange={(data: any) => {
                                    setFormState((draft) => {
                                      draft.jobTitle = data.value;
                                    });
                                  }}
                                  options={getJobTitleOptions()}
                                  value={{
                                    label: formState.jobTitle,
                                    value: formState.jobTitle,
                                  }}
                                />
                              </Form.Item>
                            </FloatingLabel>
                          </div>
                        </div>
                        <div css={styles.formItemAndLabel}>
                          <div css={styles.dropdownContainer}>
                            <FloatingLabel
                              label={t("UserProfilePage##Country")}
                              value={FIELDS.country}
                            >
                              <Form.Item
                                name={FIELDS.country}
                                htmlFor="country-select"
                              >
                                <Select
                                  id="country-select"
                                  data-testid="country-select"
                                  classNamePrefix="dropdown-options"
                                  className="dropdown"
                                  aria-label="Country"
                                  placeholder="Select"
                                  components={{
                                    IndicatorSeparator: null,
                                  }}
                                  onChange={(data: any) => {
                                    setFormState((draft) => {
                                      draft.country = data.value;
                                    });
                                  }}
                                  options={countryOptions}
                                  value={{
                                    label: formState.country,
                                    value: formState.country,
                                  }}
                                />
                              </Form.Item>
                            </FloatingLabel>
                          </div>
                        </div>
                      </div>

                      <div css={styles.submit}>
                        <div css={styles.resetPasswordBtn}>
                          <Button
                            variant="link"
                            onClick={resetPassword}
                            onKeyDown={resetPassword}
                            tabIndex={0}
                          >
                            {t("UserProfilePage##Reset password")}
                          </Button>
                        </div>
                        <div css={styles.horizontalDivider} />
                        <Form.Item css={styles.saveChangesBtn}>
                          <Button variant="important">
                            {t("UserProfilePage##Save changes")}
                          </Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <SlideDrawer
        isOpen={isImageUploadOpen}
        toggleMenu={toggleMenu}
        steps={imageUploadSteps(
          handleImgUpload,
          userProfileImage || "",
          user,
          handleImgDeletion
        )}
        onComplete={() => setIsImageUploadOpen(false)}
      />
    </div>
  );
};

export default Profile;
