import { ReactElement, useEffect, useState } from "react";
import { Button, Table, TableData } from "@songtradr/component-library";
import BaseModal from "src/components/modals/base-modal";
import useAuth from "src/auth/use-auth";
import { deletePasscode, patchPasscode } from "src/api/passcodes";
import { errorToast, successToast } from "src/components/toast-notification";
import modalStyles from "src/components/modals/styles";
import styles from "../../styles";
import { formatTableData } from "./utils";
import { IPassCode } from "./interfaces";

interface IProps {
  showActive: boolean;
  allPasscodes: IPassCode[];
  handleEditPasscode: (id: string) => void;
}

const renderFunction = (data: TableData, key: string): ReactElement => {
  return <div>{data[key]}</div>;
};

const PasscodesTable = ({
  showActive,
  allPasscodes,
  handleEditPasscode,
}: IProps): ReactElement => {
  const { getAccessToken, organisationId } = useAuth();
  const [passcodes, setPasscodes] = useState<IPassCode[]>(allPasscodes);
  const [deletePasscodeModalVisible, setDeletePasscodeModalVisible] = useState(
    false
  );
  const [selectedPasscodeId, setSelectedPasscodeId] = useState<string>("");

  const handleDeletePasscode = (id: string) => {
    setDeletePasscodeModalVisible(true);
    setSelectedPasscodeId(id);
  };

  const editPasscode = (id: string) => {
    handleEditPasscode(id);
  };

  const togglePasscodeStatus = async (id: string, status: string) => {
    const accessToken = await getAccessToken();
    const patchBody = {
      status,
    };
    try {
      await patchPasscode(accessToken, organisationId, id, patchBody);
      successToast({
        message: `Passcode ${status === "Active" ? "enabled" : "disabled"}`,
      });
      const updatedPasscodes = allPasscodes.map((item) => {
        const newItem = item;
        if (item.id === id) {
          newItem.status = status;
        }
        return newItem;
      });
      setPasscodes(
        formatTableData(
          updatedPasscodes,
          handleDeletePasscode,
          togglePasscodeStatus,
          editPasscode
        )
      );
    } catch (e) {
      errorToast({ message: "Error setting passcode status" });
    }
  };

  const confirmDelete = async () => {
    const accessToken = getAccessToken();
    try {
      await deletePasscode(accessToken, organisationId, selectedPasscodeId);
      successToast({ message: "Passcode deleted" });
      setDeletePasscodeModalVisible(false);
      const remainingPasscodes = allPasscodes.filter(
        (item) => item.id !== selectedPasscodeId
      );
      setPasscodes(
        formatTableData(
          remainingPasscodes,
          handleDeletePasscode,
          togglePasscodeStatus,
          editPasscode
        )
      );
    } catch (e) {
      errorToast({ message: "Error deleting passcode" });
    }
  };

  const filterPasscodes = (data: IPassCode[]) => {
    if (showActive) {
      const activePassCodes = data.filter((item) => item.status === "Active");
      return formatTableData(
        activePassCodes,
        handleDeletePasscode,
        togglePasscodeStatus,
        editPasscode
      );
    }
    return formatTableData(
      allPasscodes,
      handleDeletePasscode,
      togglePasscodeStatus,
      editPasscode
    );
  };

  useEffect(() => {
    setPasscodes(filterPasscodes(allPasscodes));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActive]);

  useEffect(() => {
    setPasscodes(
      formatTableData(
        allPasscodes,
        handleDeletePasscode,
        togglePasscodeStatus,
        editPasscode
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPasscodes]);

  const columns = [
    {
      label: "Name",
      key: "name",
      render: (data: TableData) => renderFunction(data, "name"),
    },
    {
      label: "Code",
      key: "code",
      render: (data: TableData) => renderFunction(data, "code"),
    },
    {
      label: "Permissions",
      key: "permissions",
      render: (data: TableData) => renderFunction(data, "permissions"),
    },
    {
      label: "Last updated",
      key: "lastUpdated",
      render: (data: TableData) => renderFunction(data, "lastUpdated"),
    },
    {
      label: "Created by",
      key: "createdBy",
      render: (data: TableData) => renderFunction(data, "createdBy"),
    },
    {
      label: "Status",
      key: "status",
      render: (data: TableData) => renderFunction(data, "status"),
    },
    {
      label: "",
      key: "copy",
      render: (data: TableData) => renderFunction(data, "copy"),
    },
    {
      label: "",
      key: "options",
      render: (data: TableData) => renderFunction(data, "options"),
    },
  ];

  return (
    <div css={styles.compLibTable}>
      {passcodes.length > 0 ? (
        <Table
          columns={columns}
          data={passcodes}
          data-testid="pass-codes-table"
        />
      ) : (
        <div className="not-found">No passcodes found.</div>
      )}
      <BaseModal
        buttons={[]}
        visible={deletePasscodeModalVisible}
        onClose={() =>
          setDeletePasscodeModalVisible(!deletePasscodeModalVisible)
        }
        dataTestId="delete-passcodes-modal"
        css={styles.deleteModal}
        content={
          <div>
            <div css={styles.modalTitle}>Are you sure?</div>
            <div css={modalStyles.footer}>
              <Button
                type="button"
                onClick={() => setDeletePasscodeModalVisible(false)}
                className="confirmationModalCancelButton"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="confirmationModalConfirmButton"
                onClick={confirmDelete}
              >
                Delete
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default PasscodesTable;
