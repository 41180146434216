import Service1 from "../images/service1.png";
import Service2 from "../images/service2.png";
import Service3 from "../images/service3.png";
import Service4 from "../images/service4.png";
import Service5 from "../images/service5.png";
import Service6 from "../images/service6.png";

const getServicesData = () => {
  return [
    {
      title: "Brand performance & research",
      description:
        "Do you know how your sound use is affecting your business? How about your competitors? We can help you understand how to make your sound generate more ROI.",
      link: "",
      image: Service1,
    },
    {
      title: "Sonic Logos",
      description:
        "How you sound is everything. We've helped the world's largets brands craft unique sonic identities through custom-tailored music, and we can do the same for you.",
      link: "",
      image: Service2,
    },
    {
      title: "Brand sonic strategy",
      description:
        "Research shows that 73% of major brands don't use music effectively. We solve the gap with expert strategy, sonic audits, and comprehensive music management.",
      link: "",
      image: Service3,
    },
    {
      title: "Custom Compositions",
      description:
        "Let our team create your sound, from custom TV ad songs to product sounds, with bespoke compositions from our global network of top consumers, songwriters, and musicians.",
      link: "",
      image: Service4,
    },
    {
      title: "Hit music licensing",
      description:
        "We're the leading sync licensing experts for a reason — deep industry relationships and decades of experience mean we can get you the rights for the perfect track.",
      link: "",
      image: Service5,
    },
    {
      title: "Re-recordings",
      description:
        "Tailor your favorite tracks specifically to your unique needs, with a re-record of any song that can give your brand its own sonic style.",
      link: "",
      image: Service6,
    },
  ];
};

export default getServicesData;
