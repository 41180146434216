import { css } from "@emotion/react";

export default {
  detailsContainer: css`
    grid-gap: 20px 10px;
    grid-template-columns: repeat(4, 1fr);
  `,
  submitBtn: css`
    width: auto !important;
  `,
};
