import axios from "axios";
import { IFeatureFlags } from "src/interfaces/organisation/organisation-features";
import { getAuthHeader, getJsonContentHeader } from "../headers";
import getOrganisationFeaturesUrl from "../organisation-features-url-helper";

const getFeatureFlags = async (
  accessToken: string,
  abortController?: AbortController
): Promise<IFeatureFlags> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : null),
  };
  const { data } = await axios.get<IFeatureFlags>(
    getOrganisationFeaturesUrl(),
    options
  );
  return data;
};

export default getFeatureFlags;
