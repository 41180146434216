import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  button: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 300;
    background: none;
    border-radius: 32px;
    border: 1px solid ${theme.colors.darkCharcoal};
    padding: 12px;
    color: ${theme.colors.white};
    border-color: ${theme.colors.darkCharcoal};

    &:hover {
      cursor: pointer;
      border: 1px solid rgba(255, 255, 255, 0.7);
    }
  `,
  selectedButton: css`
    border-color: ${theme.colors.white} !important;
  `,
};
