import React, { Fragment } from "react";
import { Select, Tooltip } from "antd";
import { IOrganisationMemberDataSource } from "src/components/table/interfaces";
import { eApplicationType } from "src/interfaces/auth";
import { Checkbox } from "@songtradr/component-library";
import styles from "../components/whitelisting-tab/styles";
import { IOrganisationMemberProps } from "../interfaces";

export interface IOption {
  value: string;
  label: string;
}

export const WhitelistingData = (
  teamMembers: Array<IOrganisationMemberProps>,
  projects: IOption[],
  handleAddApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>,
  handleRemoveApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>,
  hasProjectsClientApplication: boolean,
  handleToggleUseProjectsWhitelist: (
    selectedOrgMember: IOrganisationMemberProps,
    useProjectsWhitelist: boolean
  ) => Promise<void>,
  handleUpdateProjectsWhitelist: (
    selectedOrgMember: IOrganisationMemberProps,
    projects: string[]
  ) => Promise<void>,
  isProcessingRequest: boolean,
  setIsProcessingRequest: (newValue: boolean) => void
): Array<IOrganisationMemberDataSource> => {
  return teamMembers?.map((orgMember: IOrganisationMemberProps) => {
    const { key, id, firstName, lastName, email, applications } = orgMember;
    const isAdmin = applications.includes(eApplicationType.TeamManagement);

    const projectOptions = projects.map((project) =>
      !project.label || project.label === ""
        ? { value: project.value, label: "Untitled Project" }
        : { value: project.value, label: project.label }
    );

    const projectsToggle = applications.includes(
      eApplicationType.ProjectsClient
    );

    const useProjectsWhitelistChecked =
      projectsToggle && orgMember.useProjectsWhitelist;

    const projectsChecked = !hasProjectsClientApplication
      ? false
      : projectsToggle || isAdmin;

    let projectWhitelist: string[] = [];
    let defaultProjectWhitelist: string[] = [];

    const doesProjectExist = (projectId: string) =>
      projects.findIndex((project) => project.value === projectId);

    if (projects.length) {
      projectWhitelist = orgMember.projectsWhitelist;

      defaultProjectWhitelist = (orgMember.projectsWhitelist ?? []).filter(
        (projectId) => !doesProjectExist(projectId)
      );
    }

    const getSelectedProjectsOnlyTooltip = () => {
      if (isProcessingRequest) {
        return "Is processing request";
      }

      if (!hasProjectsClientApplication) {
        return "This org does not have the 'Projects (client)' module enabled";
      }

      if (isAdmin) {
        return "This user is an admin so they have access to all projects";
      }

      if (!projectsChecked) {
        return "This user does not have the 'Projects' permission enabled";
      }

      return "";
    };

    const isDisabled =
      isProcessingRequest ||
      !hasProjectsClientApplication ||
      isAdmin ||
      !projectsChecked;

    return {
      key,
      id,
      name: (
        <Fragment>
          <div css={styles.teamName}>
            {firstName} {lastName}
          </div>
          <div css={styles.teamEmail}>{email}</div>
        </Fragment>
      ),

      hasProjectsSubscription: (
        <div>
          <Checkbox
            disabled={
              isProcessingRequest || !hasProjectsClientApplication || isAdmin
            }
            checked={projectsChecked}
            onClick={async () => {
              setIsProcessingRequest(true);
              if (projectsChecked) {
                await handleRemoveApplication(
                  orgMember,
                  eApplicationType.ProjectsClient
                );
              } else {
                await handleAddApplication(
                  orgMember,
                  eApplicationType.ProjectsClient
                );
              }
              setIsProcessingRequest(false);
            }}
            data-testid="projects-admin-checkbox"
          />
        </div>
      ),
      hasWhitelist: (
        <div>
          <Tooltip
            getPopupContainer={(triggerNode) => triggerNode}
            title={getSelectedProjectsOnlyTooltip()}
            css={styles.antTooltip}
            placement="top"
          >
            <div css={styles.tooltipContainer}>
              <Checkbox
                css={isDisabled ? styles.disabled : null}
                disabled={isDisabled}
                checked={useProjectsWhitelistChecked}
                onClick={async () => {
                  setIsProcessingRequest(true);
                  await handleToggleUseProjectsWhitelist(
                    orgMember,
                    !useProjectsWhitelistChecked
                  );
                  setIsProcessingRequest(false);
                }}
                data-testid="projects-admin-checkbox"
              />
            </div>
          </Tooltip>
        </div>
      ),
      whiteListedProjects: (
        <div>
          <div css={styles.whitelistProjectSelect}>
            <Select
              disabled={
                isProcessingRequest ||
                !hasProjectsClientApplication ||
                isAdmin ||
                !projectsChecked ||
                !useProjectsWhitelistChecked
              }
              maxTagCount="responsive"
              showArrow
              showSearch
              mode="multiple"
              defaultValue={defaultProjectWhitelist}
              value={projectWhitelist}
              data-testid="project-whitelist-select"
              placeholder="Select a project"
              onChange={async (data: any) => {
                setIsProcessingRequest(true);
                await handleUpdateProjectsWhitelist(
                  orgMember,
                  data as string[]
                );
                setIsProcessingRequest(false);
              }}
              dropdownClassName="custom-dropdown"
              dropdownStyle={{
                backgroundColor: "#222222",
              }}
              options={projectOptions}
              dropdownRender={(menu) => (
                <div css={styles.customDropdown}>{menu}</div>
              )}
            />
          </div>
        </div>
      ),
    };
  });
};
