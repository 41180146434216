import { buildVinylProjectManagementBaseUrl } from "./base-url-helper";

export const getProjectUrl = (
  projectId: string,
  organisationId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}?orgId=${organisationId}&showClientOnly=true`;
};
export const createProjectUrl = (): string => {
  return buildVinylProjectManagementBaseUrl();
};
export const attachmentDownloadUrl = (
  projectId: string,
  attachmentId: string
): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/attachments/${attachmentId}/download`;
};

export const attachmentUploadUrl = (projectId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/attachments`;
};

export const searchProjectsUrl = (): string => {
  return `${buildVinylProjectManagementBaseUrl()}/_search`;
};

export const shareProjectsUrl = (projectId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/share-code`;
};

export const updateProjectUrl = (projectId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}`;
};

export const createTermUrl = (projectId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/terms`;
};

export const updateTermUrl = (projectId: string, termId: string): string => {
  return `${buildVinylProjectManagementBaseUrl()}/${projectId}/terms/${termId}`;
};
