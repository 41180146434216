import { IOrganisation } from "src/interfaces/organisation";

export interface IProps {
  org?: IOrganisation;
  orgDropdownData?: IOrganisation[];
  refreshData: () => void;
}

export enum CurrencyCode {
  "USD" = "USD",
  "AUD" = "AUD",
  "GBP" = "GBP",
  "CNY" = "CNY",
  "EUR" = "EUR",
  "JPY" = "JPY",
  "SGD" = "SGD",
  "THB" = "THB",
  "HKD" = "HKD",
  "PHP" = "PHP",
  "ZAR" = "ZAR",
  "IDR" = "IDR",
  "BRL" = "BRL",
  "TRY" = "TRY",
  "MMK" = "MMK",
  "ARS" = "ARS",
  "PLN" = "PLN",
  "MXN" = "MXN",
  "CAD" = "CAD",
  "SEK" = "SEK",
  "AED" = "AED",
}

export enum CurrencySymbol {
  "USD" = "$",
  "AUD" = "A$",
  "GBP" = "£",
  "CNY" = "CN¥",
  "EUR" = "€",
  "JPY" = "JP¥",
  "SGD" = "S$",
  "THB" = "฿",
  "HKD" = "HK$",
  "PHP" = "₱",
  "ZAR" = "R",
  "IDR" = "Rp",
  "BRL" = "R$",
  "TRY" = "₺",
  "MMK" = "K",
  "ARS" = "ARS$",
  "PLN" = "zł",
  "MXN" = "Mex$",
  "CAD" = "CA$",
  "SEK" = "kr",
  "AED" = "د.إ",
}
