import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import useAuth from "src/auth/use-auth";
import debounce from "lodash/debounce";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { errorToast, successToast } from "src/components/toast-notification";
import getPresignedDownloadUrl from "src/api/attachments/get-presigned-download-url";
import { handleFileDownload } from "src/components/file-upload/helpers";
import useProject from "src/providers/project/hooks";
import useBreadcrumbs from "src/providers/breadcrumbs";
import { CurrencyCode } from "src/pages/org-dashboard/components/details-section/interfaces";
import { ConditionallyVisible } from "src/sumo/src/components/visibility";
import appStyles from "src/app/styles";
import STLoadingLogo from "src/components/st-loading-logo";
import FinalTrackDetails from "./components/final-track-details";
import Licensing from "./components/licensing";
import Documents from "./components/documents";
import styles from "./styles";
import {
  IProjectAttachment,
  IProjectRouteParams,
  IProjectTrack,
  IProjectUploadFile,
} from "./interfaces";
import ProductDetails from "./components/product-details";
import Services from "./components/services";
import OtherAssets from "./components/assets";
import ProjectContacts from "./components/project-contacts";
import ProjectHeader from "./components/project-header";
import Pills from "./components/pills";

enum Anchor {
  ProductDetailsSection = "#productDetails",
  FinalTracksSection = "#finalTracks",
  LicensingTermsSection = "#licensingTerms",
  ServicesSection = "#services",
  AssetsSection = "#assets",
  DocumentsSection = "#documents",
  ContactsSection = "#contacts",
}

const DEFAULT_TIMEOUT_SECTION_LOAD_INTERVAL = 500;

const ClientProjectView = (): ReactElement => {
  const { id } = useParams<IProjectRouteParams>();
  const { getAccessToken, organisationId } = useAuth();
  const { t } = useTranslation();
  const { project, isLoading, serverErrorOccurred } = useProject();
  const { updateBreadcrumbProjectName } = useBreadcrumbs();
  const productDetailsRef = React.useRef<HTMLDivElement>(null);
  const finalTracksRef = React.useRef<HTMLDivElement>(null);
  const licensingTermsRef = React.useRef<HTMLDivElement>(null);
  const servicesRef = React.useRef<HTMLDivElement>(null);
  const assetsRef = React.useRef<HTMLDivElement>(null);
  const documentsRef = React.useRef<HTMLDivElement>(null);
  const projectContactsRef = React.useRef<HTMLDivElement>(null);
  const history = useHistory();

  const [tracks, setTracks] = useState<IProjectTrack[]>([]);
  const [accessToken, setAccessToken] = useState<string>();

  const downloadFile = debounce(
    async (file: IProjectUploadFile): Promise<void> => {
      if (!file) return;
      successToast({ message: t("File downloading to your file storage") });

      if (accessToken) {
        const downloadFileRequest = await handleFileDownload(
          file,
          accessToken,
          id,
          organisationId
        );

        if (!downloadFileRequest) {
          errorToast({
            message: t("There was a problem downloading the file"),
          });
        }
      }
    },
    500
  );

  useEffect(() => {
    async function fetchTracks() {
      const token: string = await getAccessToken();
      setAccessToken(token);

      if (project.tracks) {
        project.tracks.map(async (track: IProjectTrack) => {
          const filteredAttachments = project.attachments?.filter(
            (attachment: IProjectAttachment) => attachment.trackId === track.id
          );
          let trackAttachments;
          if (filteredAttachments) {
            trackAttachments = await Promise.all(
              filteredAttachments.map(async (attachment) => {
                try {
                  const response = await getPresignedDownloadUrl({
                    fileName: attachment.name,
                    attachmentId: attachment.id,
                    projectId: project.id,
                    organisationId,
                    accessToken: token,
                  });
                  const updatedAttachment = {
                    ...attachment,
                    url: response.data.url,
                  };
                  return updatedAttachment;
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.log(
                    `Error getting download url for track ${attachment.name}. Message: `,
                    err
                  );
                }
                return attachment;
              })
            );
          }

          const updatedTrack = {
            ...track,
            attachments: trackAttachments ?? [],
          };
          setTracks((previous) => [...previous, updatedTrack]);
        });
      }
    }

    void fetchTracks();
  }, [
    getAccessToken,
    project.attachments,
    project.id,
    project.tracks,
    organisationId,
  ]);

  const trackMasterFees = useMemo(() => {
    return (project?.tracks ?? []).map(
      (track) => track.masterFee ?? { value: 0, currency: CurrencyCode.USD }
    );
  }, [project?.tracks]);

  const trackPublisherFees = useMemo(() => {
    return (project?.tracks ?? []).map(
      (track) => track.publisherFee ?? { value: 0, currency: CurrencyCode.USD }
    );
  }, [project?.tracks]);

  const licenseFee = useMemo(() => {
    return (project?.services ?? []).map((service) =>
      service.serviceType === "License" ||
      service.serviceType === "LicenseRenewal"
        ? service.totalFee ?? {
            value: 0,
            currency: CurrencyCode.USD,
          }
        : { value: 0, currency: CurrencyCode.USD }
    );
  }, [project?.services]);

  useEffect(() => {
    updateBreadcrumbProjectName(project.name || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project.name]);

  const scrollTo = useCallback((ref: React.RefObject<HTMLDivElement>) => {
    const elementTopValue = ref.current?.getBoundingClientRect().top;
    if (elementTopValue) {
      window.scrollBy({
        behavior: "smooth",
        top: elementTopValue - 150,
      });
    }
  }, []);

  const isProductDetailsSectionHidden = useMemo(() => {
    return Boolean(
      !project.globalBrand?.name &&
        !project.regionalBrand?.name &&
        !project.product &&
        !project.productDivision?.name
    );
  }, [
    project.globalBrand?.name,
    project.product,
    project.productDivision?.name,
    project.regionalBrand?.name,
  ]);

  const isFinalTracksSectionHidden = useMemo(() => {
    return !project.tracks || project.tracks.length === 0;
  }, [project.tracks]);

  const isLicensingTermsSectionHidden = useMemo(() => {
    return !project.terms || project.terms.length === 0;
  }, [project.terms]);

  const isServicesSectionHidden = useMemo(() => {
    return !project.services || project.services.length === 0;
  }, [project.services]);

  const isAssetsSectionHidden = useMemo(() => {
    const populatedMusicAssets = project.music.musicAssets.filter(
      ({ name, link }) => name && link
    );
    const populatedVideoAssets = project.music.finalVideo.filter(
      ({ name, link }) => name && link
    );
    const populatedAssets = populatedMusicAssets.concat(populatedVideoAssets);

    return !populatedAssets || populatedAssets.length === 0;
  }, [project.music.finalVideo, project.music.musicAssets]);

  const isDocumentsSectionHidden = useMemo(() => {
    const allDocs = [
      ...project.purchaseOrderAttachments,
      ...project.invoiceAttachments,
      ...project.licenseAttachments,
      ...project.estimateAttachments,
      ...project.miscAttachments,
      ...project.assetAttachments,
    ].filter(({ name }) => name);

    return allDocs.length === 0;
  }, [
    project.assetAttachments,
    project.estimateAttachments,
    project.invoiceAttachments,
    project.licenseAttachments,
    project.miscAttachments,
    project.purchaseOrderAttachments,
  ]);

  const isContactsSectionHidden = useMemo(() => {
    const hasProjectLeads = !project.leads || project.leads.length === 0;
    const hasAdditionalContacts =
      !project.contacts || project.contacts.length === 0;

    return hasProjectLeads && hasAdditionalContacts;
  }, [project.contacts, project.leads]);

  const sections = useMemo(
    () => [
      {
        name: "ProductDetails",
        ref: productDetailsRef,
        anchor: Anchor.ProductDetailsSection,
        isHidden: isProductDetailsSectionHidden,
      },
      {
        name: "FinalTrack",
        ref: finalTracksRef,
        anchor: Anchor.FinalTracksSection,
        isHidden: isFinalTracksSectionHidden,
      },
      {
        name: "LicensingTerms",
        ref: licensingTermsRef,
        anchor: Anchor.LicensingTermsSection,
        isHidden: isLicensingTermsSectionHidden,
      },
      {
        name: "Services",
        ref: servicesRef,
        anchor: Anchor.ServicesSection,
        isHidden: isServicesSectionHidden,
      },
      {
        name: "Assets",
        ref: assetsRef,
        anchor: Anchor.AssetsSection,
        isHidden: isAssetsSectionHidden,
      },
      {
        name: "Documents",
        ref: documentsRef,
        anchor: Anchor.DocumentsSection,
        isHidden: isDocumentsSectionHidden,
      },
      {
        name: "Contacts",
        ref: projectContactsRef,
        anchor: Anchor.ContactsSection,
        isHidden: isContactsSectionHidden,
      },
    ],
    [
      isAssetsSectionHidden,
      isContactsSectionHidden,
      isDocumentsSectionHidden,
      isFinalTracksSectionHidden,
      isLicensingTermsSectionHidden,
      isProductDetailsSectionHidden,
      isServicesSectionHidden,
    ]
  );

  const getPillsData = useCallback(() => {
    return sections.map(({ name, ref, anchor, isHidden }) => {
      return {
        key: anchor,
        name: `ClientProjectViewPage##${name}`,
        isSelected: history.location.hash === anchor,
        isHidden,
        onClick: () => {
          scrollTo(ref);
          history.push(anchor);
        },
      };
    });
  }, [history, scrollTo, sections]);

  useEffect(() => {
    const currentAnchor = history.location.hash;
    const onPageLoad = () => {
      const currentAnchorRef = sections.find(
        (section) => section.anchor === currentAnchor
      )?.ref;
      if (currentAnchorRef?.current) {
        setTimeout(() => {
          scrollTo(currentAnchorRef);
        }, DEFAULT_TIMEOUT_SECTION_LOAD_INTERVAL);
      } else {
        setTimeout(() => {
          onPageLoad();
        }, DEFAULT_TIMEOUT_SECTION_LOAD_INTERVAL);
      }
    };

    onPageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollTo, sections]);

  useEffect(() => {
    if (serverErrorOccurred) {
      errorToast({
        message: t(
          "There was an error retrieving this project, please try again."
        ),
      });
    }
  }, [serverErrorOccurred, t]);

  if (isLoading) {
    return <STLoadingLogo pageCentered />;
  }

  return (
    <div css={styles.projectBackground}>
      <ProjectHeader
        campaignName={project.campaignName ?? ""}
        sequenceNo={project.sequenceNo}
        name={project.name ?? ""}
        brandName={project.globalBrand?.name}
        onBackButtonClick={() => history.push("/commerciallicenses")}
      />
      <Pills pills={getPillsData()} />
      <div css={[appStyles.container, styles.clientProjectContainer]}>
        <ConditionallyVisible condition={!isProductDetailsSectionHidden}>
          <ProductDetails
            globalBrandName={project.globalBrand?.name}
            regionalBrandName={project.regionalBrand?.name}
            productName={project.product}
            productDivision={project.productDivision?.name}
            ref={productDetailsRef}
          />
        </ConditionallyVisible>

        <ConditionallyVisible condition={!isFinalTracksSectionHidden}>
          <FinalTrackDetails
            tracks={tracks}
            downloadFile={downloadFile}
            ref={finalTracksRef}
          />
        </ConditionallyVisible>

        <ConditionallyVisible condition={!isLicensingTermsSectionHidden}>
          <Licensing
            terms={project.terms}
            sequenceId={project.sequenceNo}
            projectName={project.name || ""}
            clientName={project.client?.name || ""}
            projectLeads={project?.leads}
            projectTracks={project?.tracks}
            projectTrackMasterFees={trackMasterFees}
            projectTrackPublisherFees={trackPublisherFees}
            projectLicenseFees={licenseFee}
            ref={licensingTermsRef}
          />
        </ConditionallyVisible>

        <ConditionallyVisible condition={!isServicesSectionHidden}>
          <Services services={project.services} ref={servicesRef} />
        </ConditionallyVisible>

        <ConditionallyVisible condition={!isAssetsSectionHidden}>
          <OtherAssets
            musicAssets={project.music.musicAssets}
            finalVideo={project.music.finalVideo}
            ref={assetsRef}
          />
        </ConditionallyVisible>

        <ConditionallyVisible condition={!isDocumentsSectionHidden}>
          <Documents
            purchaseOrders={project.purchaseOrderAttachments}
            invoices={project.invoiceAttachments}
            licenses={project.licenseAttachments}
            estimates={project.estimateAttachments}
            miscellaneous={project.miscAttachments}
            assets={project.assetAttachments}
            downloadFile={downloadFile}
            ref={documentsRef}
          />
        </ConditionallyVisible>

        <ConditionallyVisible condition={!isContactsSectionHidden}>
          <ProjectContacts
            projectLeads={project.leads}
            additionalContacts={project.contacts}
            ref={projectContactsRef}
          />
        </ConditionallyVisible>
      </div>
    </div>
  );
};

export default ClientProjectView;
