import { ReactElement } from "react";

interface IProps {
  width?: string;
  height?: string;
}

const MagnifyingGlass = ({
  width = "24",
  height = "24",
}: IProps): ReactElement => {
  return (
    <svg
      className="magnify"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 11C19 14.866 15.866 18 12 18C8.13401 18 5 14.866 5 11C5 7.13401 8.13401 4 12 4C15.866 4 19 7.13401 19 11ZM16.686 18.6854C15.321 19.5194 13.7166 20 12 20C7.02944 20 3 15.9706 3 11C3 6.02944 7.02944 2 12 2C16.9706 2 21 6.02944 21 11C21 13.5289 19.9569 15.8142 18.2776 17.4492L20.7071 19.8787C21.0976 20.2692 21.0976 20.9024 20.7071 21.2929C20.3166 21.6834 19.6834 21.6834 19.2929 21.2929L16.7071 18.7071C16.6999 18.7 16.6929 18.6927 16.686 18.6854Z"
        fill="#120A23"
      />
    </svg>
  );
};

export default MagnifyingGlass;
