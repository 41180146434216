import { ReactElement } from "react";

const ApproveIcon = (): ReactElement => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="approve-icon"
    >
      <rect width="48" height="48" rx="8" />
      <path d="M21.0046 30.4656L16.1474 25.6083C15.9509 25.4118 15.9509 25.0925 16.1474 24.896L18.2843 22.759C18.4808 22.5626 18.8002 22.5626 18.9966 22.759L21.0046 24.767C21.2011 24.9635 21.5205 24.9635 21.7169 24.767L29.0034 17.4806C29.1998 17.2841 29.5192 17.2841 29.7157 17.4806L31.8526 19.6176C32.0491 19.814 32.0491 20.1334 31.8526 20.3299L21.7169 30.4656C21.5205 30.662 21.2011 30.662 21.0046 30.4656Z" />
    </svg>
  );
};
export default ApproveIcon;
