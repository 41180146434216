import axios, { CancelTokenSource } from "axios";
import { IOrganisationMember } from "src/pages/team/interfaces";
import { eApplicationType } from "src/interfaces/auth";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";
import { getOrganisationUserUrl } from "../../organisation-members-url-helper";

let call: CancelTokenSource;
const getOrganisationMember = async (
  accessToken: string,
  orgId: string,
  currentUserId: string
): Promise<IOrganisationMember> => {
  call = axios.CancelToken.source();
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    cancelToken: call.token,
  };

  const { data } = await axios.get<IOrganisationMember>(
    getOrganisationUserUrl(orgId, currentUserId),
    options
  );
  data.isCurrentUser = currentUserId === data.auth0UserId;
  data.hasMusicSubscription =
    data.applications.includes(eApplicationType.LibraryReadOnly) ||
    data.applications.includes(eApplicationType.LibraryFull);
  data.hasProjectsSubscription =
    data.applications.includes(eApplicationType.ProjectsClient) ||
    data.applications.includes(eApplicationType.ProjectsInternal);
  return data;
};

export default getOrganisationMember;
