import { eApplicationType } from "src/interfaces/auth";

export const getOrgPermsFromFormData = (values: any) => [
  eApplicationType.TeamManagement,
  ...(values["modules.portal"] ? [eApplicationType.Portal] : []),
  ...(values["modules.resources"] ? [eApplicationType.Resources] : []),
  ...(values["modules.validate"] ? [eApplicationType.Validate] : []),
  ...(values["modules.libraryReadOnly"]
    ? [eApplicationType.LibraryReadOnly]
    : []),
  ...(values["modules.libraryFull"] ? [eApplicationType.LibraryFull] : []),
  ...(values["modules.projectsClient"]
    ? [eApplicationType.ProjectsClient]
    : []),
  ...(values["modules.projectsInternal"]
    ? [eApplicationType.ProjectsInternal]
    : []),
];

export default getOrgPermsFromFormData;
