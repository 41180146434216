import React, { ReactElement } from "react";
import { Form } from "antd";
import orgDashboardStyles from "src/pages/org-dashboard/styles";
import { Button } from "@songtradr/component-library";
import useAuth from "src/auth/use-auth";
import { errorToast, successToast } from "src/components/toast-notification";
import updateOrganisation from "src/api/organisation/update-organisation";
import ModuleCheckboxes from "src/pages/org-dashboard/components/module-checkboxes";
import getOrgPermsFromFormData from "src/pages/org-dashboard/components/module-checkboxes/utils";
import styles from "./styles";
import { IProps } from "./interfaces";

const Modules = ({ org, refreshData }: IProps): ReactElement | null => {
  const [form] = Form.useForm();
  const { getAccessToken } = useAuth();

  if (!org) {
    return null;
  }

  const handleFormSubmit = async (values: any) => {
    const accessToken = getAccessToken();
    const orgId = org.id;

    if (!orgId) {
      errorToast({ message: "Can't update org without a valid org ID" });
      return;
    }

    const payload = {
      ...org,
      applications: getOrgPermsFromFormData(values),
    };

    try {
      const response = await updateOrganisation(orgId, accessToken, payload);
      if (response) {
        successToast({ message: "Organization successfully saved." });
        refreshData();
      }
    } catch (e) {
      errorToast({ message: error.message });
    }
  };

  const handleFormChange = (event: React.FormEvent<HTMLFormElement>) => {
    const changedId = (event.target as HTMLFormElement).id;
    const projectsInternalId = "modules.projectsInternal";
    const projectsClientId = "modules.projectsClient";
    const isProjectsInternalSelected =
      form.getFieldValue(projectsInternalId) === true;
    const isProjectsClientSelected =
      form.getFieldValue(projectsClientId) === true;
    const areBothProjectsSelected =
      isProjectsClientSelected && isProjectsInternalSelected;
    const shouldResetProjectsClient =
      changedId === projectsInternalId && areBothProjectsSelected;
    const shouldResetProjectsInternal =
      changedId === projectsClientId && areBothProjectsSelected;

    if (shouldResetProjectsClient) {
      form.setFieldsValue({
        [projectsClientId]: false,
      });
    } else if (shouldResetProjectsInternal) {
      form.setFieldsValue({
        [projectsInternalId]: false,
      });
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleFormSubmit}
      onChange={handleFormChange}
      key={org.id}
      data-testid="modules-section"
    >
      <h2 css={orgDashboardStyles.subHeading}>Modules</h2>
      <div css={[orgDashboardStyles.detailsContainer, styles.detailsContainer]}>
        <ModuleCheckboxes org={org} />
      </div>
      <Button
        css={[orgDashboardStyles.submitBtn, styles.submitBtn]}
        variant="primary"
        data-testid="submit-btn"
      >
        Save changes
      </Button>
    </Form>
  );
};

export default Modules;
