import React, { ReactElement } from "react";

const SearchIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_475_2676)">
        <path d="M4.39418 10.3781V3.18551C4.39418 2.99657 4.54752 2.84323 4.73646 2.84323H10.5668C10.7558 2.84323 10.9091 2.68989 10.9091 2.50095V0.447289C10.9091 0.258351 10.7558 0.105011 10.5668 0.105011H1.99824C1.8093 0.105011 1.65596 0.258351 1.65596 0.447289V10.3781C1.65596 10.5671 1.8093 10.7204 1.99892 10.7204H4.05259C4.24152 10.7204 4.39486 10.5671 4.39486 10.3781H4.39418ZM19.7214 19.8093C21.3239 17.3168 21.0364 13.9529 18.8582 11.7739C17.6417 10.5575 16.0241 9.88731 14.3038 9.88731C12.5835 9.88731 10.9659 10.5575 9.74946 11.7739C8.533 12.9904 7.86282 14.608 7.86282 16.3283C7.86282 18.0486 8.533 19.6662 9.74946 20.8826C10.9659 22.0991 12.5835 22.7693 14.3038 22.7693C15.5579 22.7693 16.7573 22.4126 17.7868 21.7479L20.0438 24.0049C20.1773 24.1384 20.3943 24.1384 20.5278 24.0049L21.9797 22.553C22.1132 22.4195 22.1132 22.2025 21.9797 22.069L19.7207 19.8099L19.7214 19.8093ZM16.9215 18.946C16.2226 19.645 15.2923 20.0304 14.3038 20.0304C13.3153 20.0304 12.385 19.6456 11.6861 18.946C10.9871 18.2471 10.6017 17.3168 10.6017 16.3283C10.6017 15.3398 10.9864 14.4095 11.6861 13.7105C12.385 13.0116 13.3153 12.6262 14.3038 12.6262C15.2923 12.6262 16.2226 13.0116 16.9215 13.7105C18.3653 15.1543 18.3653 17.503 16.9215 18.9467V18.946ZM4.05122 15.0694H1.99755C1.80861 15.0694 1.65527 15.2227 1.65527 15.4117V22.5728C1.65527 22.7617 1.80861 22.9151 1.99755 22.9151H6.26781C6.45675 22.9151 6.61009 22.7617 6.61009 22.5728V20.5191C6.61009 20.3302 6.45675 20.1769 6.26781 20.1769H4.73577C4.54684 20.1769 4.3935 20.0235 4.3935 19.8346V15.4117C4.3935 15.2227 4.24015 15.0694 4.05122 15.0694ZM18.4022 0.105011H16.3486C16.1596 0.105011 16.0063 0.258351 16.0063 0.447289V6.3372C16.0063 6.52614 16.1596 6.67948 16.3486 6.67948H18.4022C18.5912 6.67948 18.7445 6.52614 18.7445 6.3372V0.447289C18.7445 0.258351 18.5912 0.105011 18.4022 0.105011Z" />
      </g>
      <defs>
        <clipPath id="clip0_475_2676">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
