import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  moduleTileContainer: css`
    height: 350px;
    border-radius: 8px;
    border: 1px solid ${theme.colors.darkCharcoal};
    background-color: ${theme.colors.background.landingDarkGray};
    display: flex;
    flex-direction: column;
    padding: 24px;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
    text-align: left;

    @media ${queries.medium} {
      height: 400px;
    }
  `,
  title: css`
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-bottom: 6px;
  `,
  description: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  `,
  cardImg: css`
    height: 50%;
  `,
};
