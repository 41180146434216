import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import searchProjects from "src/api/projects/search-projects";
import { errorToast } from "src/components/toast-notification";
import { ProjectFilterStatus, ProjectsPageSize } from "src/constants";
import { parseISO, compareAsc, compareDesc } from "date-fns";
import useAuth from "src/auth/use-auth";
import { getIsMobile, getIsTablet, useWindowSize } from "@songtradr/spa-common";
import { ProjectTypes } from "src/pages/projects/interfaces";
import useOrganisation from "src/providers/organisation/hooks";
import getChildOrgsCount from "src/api/projects/count-child-orgs";
import axios, { AxiosError } from "axios/index";
import { IOption } from "src/components/custom-select/interfaces";
import useDebounce from "src/utils/hooks/use-debounce";
import {
  IClientOrgProjectProps,
  IProjectTableSort,
  ProjectSortType,
  SortOrder,
  DrawerTypes,
  IClientOrgProjectSearchResponse,
} from "../interfaces";
import ProjectListings from "..";
import getDataSource from "../table-data/get-data-source";
import getColumns from "../table-data/get-columns";

const CommercialProjectListings = (): ReactElement => {
  const history = useHistory();
  const { getAccessToken, organisationId } = useAuth();
  const { organisation } = useOrganisation();
  const location = useLocation();
  const [childOrgCount, setChildOrgCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(searchText, 300);
  const [selectedStatusOption, setSelectedStatusOption] = useState<IOption>({
    value: ProjectFilterStatus.All,
    label: `Status: ${ProjectFilterStatus.All}`,
  });

  useWindowSize();
  const isTablet = getIsTablet();
  const isMobile = getIsMobile();

  const [projects, setProjects] = useState<IClientOrgProjectProps[] | []>([]);
  const [sortOptions, setSortOptions] = useState<IProjectTableSort>({
    sortBy: ProjectSortType.lastUpdated,
    order: SortOrder.DESC,
  });
  const [mobileDrawerVisible, setMobileDrawerVisible] = useState<boolean>(
    false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [paginationOptions, setPaginationOptions] = useState({
    total: 0,
    currentPage: 1,
  });

  const clearSearchText = () => {
    setSearchText("");
  };

  const handleStatusChange = (newOption: IOption) => {
    if (!newOption) {
      setSelectedStatusOption({
        value: ProjectFilterStatus.All,
        label: `Status: ${ProjectFilterStatus.All}`,
      });
    } else {
      setSelectedStatusOption(newOption);
    }
  };

  const handleSort = ({ sortBy, order }: IProjectTableSort) => {
    setSortOptions({
      sortBy,
      order,
    });
    history.push({ search: `sortBy=${sortBy}&order=${order}` });
  };

  const handlePageChange = (page: number) => {
    if (page > 1) {
      history.push({
        search: `sortBy=${sortOptions.sortBy}&order=${sortOptions.order}&page=${page}`,
      });
    } else {
      history.push({
        search: `sortBy=${sortOptions.sortBy}&order=${sortOptions.order}`,
      });
    }
  };

  const updateSearchText = (value: string) => {
    handlePageChange(1);
    setSearchText(value);
  };

  const handleDrawerToggle = () => {
    setMobileDrawerVisible((state) => !state);
  };

  const fetchProjects = useCallback(
    async ({ sortBy, order }: IProjectTableSort, from?: number) => {
      const licenseStatuses =
        selectedStatusOption.value !== ProjectFilterStatus.All
          ? [selectedStatusOption.value]
          : [];
      const filters = { projectTypes: [], licenseStatuses };
      const excludeFilters = { projectTypes: [ProjectTypes.EnterpriseLibrary] };
      const accessToken: string = getAccessToken();
      if (accessToken) {
        const sort = {
          by: sortBy,
          order,
        };
        const response = (await searchProjects(
          accessToken,
          organisationId,
          sort,
          from,
          {},
          filters,
          excludeFilters,
          searchText
        )) as IClientOrgProjectSearchResponse;

        return response;
      }
      return false;
    },
    [getAccessToken, organisationId, searchText, selectedStatusOption?.value]
  );

  useEffect(() => {
    const fetchAndSetProjects = async () => {
      setIsLoading(true);
      const searchParams = new URLSearchParams(location.search);
      const sortBy = searchParams.get("sortBy");
      const order = searchParams.get("order");
      const page = searchParams.get("page");
      let from = 0;
      if (page) {
        from = (Number(page) - 1) * ProjectsPageSize;
      }
      try {
        if (
          sortBy &&
          Object.keys(ProjectSortType).includes(sortBy) &&
          order &&
          Object.keys(SortOrder).includes(order)
        ) {
          const sortTyped = sortBy as ProjectSortType;
          const orderTyped = order as SortOrder;

          const response = await fetchProjects(
            { sortBy: sortTyped, order: orderTyped },
            from
          );
          if (response && response.results) {
            if (sortTyped === ProjectSortType.termEndDate) {
              response.results.forEach((result) => {
                result.terms.sort((a, b) => {
                  const date1 = parseISO(a.endDate);
                  const date2 = parseISO(b.endDate);

                  if (orderTyped === SortOrder.ASC) {
                    return compareAsc(date1, date2);
                  }

                  return compareDesc(date1, date2);
                });
              });
            }

            setProjects(response.results);
            setPaginationOptions({
              total: response.totalResults,
              currentPage: response.currentPage,
            });
            setSortOptions({
              sortBy: sortTyped,
              order: orderTyped,
            });
          }
        } else {
          const defaultSort = {
            sortBy: ProjectSortType.lastUpdated,
            order: SortOrder.DESC,
          };

          const response = await fetchProjects(defaultSort);

          if (response && response.results) {
            setProjects(response.results);

            setPaginationOptions({
              total: response.totalResults,
              currentPage: response.currentPage,
            });
            setIsLoading(false);
          }
        }
      } catch {
        errorToast({
          message:
            "There was a problem retrieving projects data. Please try again.",
        });
      } finally {
        setIsLoading(false);
      }
    };
    if (organisationId) {
      fetchAndSetProjects();
    }
  }, [
    location,
    debouncedSearchTerm,
    selectedStatusOption,
    fetchProjects,
    organisationId,
  ]);

  useEffect(() => {
    const accessToken = getAccessToken();

    if (!organisation?.isHoldingCompany || !accessToken) {
      return;
    }

    const abortController = new AbortController();

    (async () => {
      try {
        const data = await getChildOrgsCount(
          accessToken,
          organisationId,
          abortController
        );
        setChildOrgCount(data.count);
      } catch (e) {
        if (axios.isAxiosError(e) && e.code === AxiosError.ERR_CANCELED) {
          return;
        }

        // eslint-disable-next-line no-console
        console.error(e);
      }
    })();

    // eslint-disable-next-line consistent-return
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisation]);

  const showBrandNameColumn =
    (organisation?.isHoldingCompany && childOrgCount > 0) ?? false;

  const columns = getColumns(
    isMobile,
    isTablet,
    handleSort,
    sortOptions,
    showBrandNameColumn
  );

  const dataSource = getDataSource(
    projects,
    showBrandNameColumn,
    selectedStatusOption
  );

  return (
    <ProjectListings
      handleSort={handleSort}
      handlePageChange={handlePageChange}
      onUpdateSearchText={updateSearchText}
      onClearSearchText={clearSearchText}
      searchText={searchText}
      isLoading={isLoading}
      projects={projects}
      sortOptions={sortOptions}
      paginationOptions={paginationOptions}
      mobileDrawerVisible={mobileDrawerVisible}
      currentActiveDrawer={DrawerTypes.sortProjects}
      handleDrawerToggle={handleDrawerToggle}
      tableTitle="Commercial"
      columns={columns}
      dataSource={dataSource}
      onStatusChange={handleStatusChange}
      selectedStatusOption={selectedStatusOption}
    />
  );
};

export default CommercialProjectListings;
