import { SerializedStyles } from "@emotion/react";
import React, { ReactElement, useState } from "react";
import styles from "./styles";

interface IProps {
  children?: ReactElement;
  label: string;
  value?: string;
  name?: string;
  wrapperStyles?: SerializedStyles | undefined;
  helpText?: string;
}

const FloatingLabel = (props: IProps): ReactElement => {
  const [focus, setFocus] = useState(false);
  const { children, label, value, name, wrapperStyles, helpText } = props;

  const labelStyle =
    focus || (value && value.length !== 0) ? styles.labelFloat : undefined;

  let labelText;

  if (focus || (value && value.length !== 0)) {
    labelText = label;
  } else {
    labelText = helpText ?? label;
  }

  return (
    <div
      css={wrapperStyles === undefined ? styles.floatLabel : wrapperStyles}
      className="floatingLabel"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label htmlFor={name} css={[styles.label, labelStyle]}>
        {labelText}
      </label>
    </div>
  );
};

export default FloatingLabel;
