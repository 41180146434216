import React from "react";
import { useTranslation } from "react-i18next";
import styles from "../../../styles";

const DeleteTeamMemberModalMainContent = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <p css={styles.deleteModal} data-testid="main-Text">
      {t("manageTeam##members##About to delete")}
    </p>
  );
};

export default DeleteTeamMemberModalMainContent;
