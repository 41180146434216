import { css } from "@emotion/react";
import {
  semanticBdrNormal,
  componentInputBg,
  componentInputBgHover,
  white,
  componentInputBdrHover,
} from "@songtradr/component-library/tokens";

export default {
  select: css`
    width: 100%;

    > div {
      background: ${componentInputBg};
    }

    .menu-el {
      border: 1px solid ${semanticBdrNormal};
    }

    .loading-el,
    .single-value-el {
      color: ${white};
    }

    .control-el {
      cursor: text;
      transition: border-color 0.3s ease;
      border: 1px solid ${semanticBdrNormal};
      color: ${white};

      &:hover {
        transition: none;
        border: 1px solid ${componentInputBdrHover};
      }
    }

    .input-el,
    .placeholder-el,
    .single-value-el {
      padding: 0 0.25rem;
    }

    .input-el {
      height: 48px;
      color: ${white};
    }

    .option-el {
      background: ${componentInputBg};
      cursor: pointer;

      &:hover {
        background: ${componentInputBgHover};
      }
    }
  `,
};
