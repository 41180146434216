import { eApplicationType } from "src/interfaces/auth";

export interface IUserPermissions {
  canAccessPortal: boolean;
  canAccessProjects: boolean;
  canAccessTeams: boolean;
  canAccessMusicSubscription: boolean;
  canAccessInsights: boolean;
  canAccessMyContent: boolean;
  canAccessValidate: boolean;
}

/*
  This function is primarily for determining a users permissions on login.
  This should be used with the getProfile endpoint response to determine permissions. 
*/
export const getUserPermissions = (
  isSuperAdmin: boolean,
  applications: eApplicationType[]
): IUserPermissions => {
  let canAccessPortal = false;
  let canAccessProjects = false;
  let canAccessTeams = false;
  let canAccessMusicSubscription = false;
  let canAccessInsights = false;
  let canAccessMyContent = false;
  let canAccessValidate = false;

  if (isSuperAdmin) {
    return {
      canAccessPortal: true,
      canAccessProjects: true,
      canAccessTeams: true,
      canAccessMusicSubscription: true,
      canAccessInsights: true,
      canAccessMyContent: true,
      canAccessValidate: true,
    };
  }

  applications.forEach((application) => {
    // eslint-disable-next-line default-case
    switch (application) {
      case eApplicationType.Portal:
        canAccessPortal = true;
        break;
      case eApplicationType.ProjectsInternal:
        canAccessProjects = true;
        break;
      case eApplicationType.LibraryFull:
        canAccessMusicSubscription = true;
        break;
      case eApplicationType.ProjectsClient:
        canAccessProjects = true;
        break;
      case eApplicationType.LibraryReadOnly:
        canAccessMusicSubscription = true;
        break;
      case eApplicationType.Validate:
        canAccessValidate = true;
        break;
      case eApplicationType.Resources:
        canAccessMyContent = true;
        break;
      case eApplicationType.Insights:
        canAccessInsights = true;
        break;
      case eApplicationType.TeamManagement:
        canAccessTeams = true;
        break;
    }
  });

  return {
    canAccessPortal,
    canAccessTeams,
    canAccessProjects,
    canAccessMusicSubscription,
    canAccessInsights,
    canAccessMyContent,
    canAccessValidate,
  };
};
