import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import SongtradrHomeLogoWhite from "src/app/assets/icons/component-icons/navigation/songtradr-home-logo-white";
import MobileNavMenu from "src/components/mobile-nav-menu";
import appStyles from "src/app/styles";
import AvatarMenu from "src/components/avatar-menu";
import GridMenu from "src/components/grid-menu";
import useProjectsSubNav from "src/utils/hooks/use-projects-sub-nav";
import { FeatureFlagStorageKeys } from "src/providers/feature-flags/state/utils";
import useFeatureFlags from "src/providers/feature-flags/hooks";
import useAuth from "src/auth/use-auth";
import useDebouncedCallback from "src/utils/hooks/use-debounced-callback";
import styles from "./styles";

const NavigationBar = (): JSX.Element => {
  const menuAnchor = useRef<HTMLDivElement>(null);
  const [hideTopNav, setHideTopNav] = useState(false);
  const [atTop, setAtTop] = useState(true);
  const initialScrollTop = useRef(document.documentElement.scrollTop || 0);
  const prevScrollTop = useRef(document.documentElement.scrollTop || 0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showProjectsSubNav, projectsSubNavData] = useProjectsSubNav();
  const { isAuthenticated, hasMultipleOrgs, organisationId, user } = useAuth();
  const { isLoading } = useFeatureFlags();
  const needToSelectOrg = hasMultipleOrgs && organisationId === "";

  const hasAccessToMusicUsageDeclaration = useMemo(() => {
    return (
      !isLoading &&
      localStorage.getItem(FeatureFlagStorageKeys.MUSIC_USAGE_DECLARATION) ===
        "on"
    );
  }, [isLoading]);

  const isUserLoggedIn = useMemo(() => {
    return user && organisationId && isAuthenticated;
  }, [user, organisationId, isAuthenticated]);

  const setPrevScrollTop = useDebouncedCallback((top: number) => {
    prevScrollTop.current = top <= 0 ? 0 : top;
  }, 300);

  const hideNavIfScrollingDown = useCallback(
    (isMobileCurrentlyMenuOpen) => {
      if (isMobileCurrentlyMenuOpen) {
        return;
      }

      const scrollMargin = 70;
      const scrollTop = document.documentElement.scrollTop || 0;
      const scrollDiff = scrollTop - initialScrollTop.current;
      const isScrollingUp = scrollTop - prevScrollTop.current < 0; // towards top
      const isScrollingDown = scrollTop - prevScrollTop.current > 0; // towards bottom

      if (isScrollingDown && scrollDiff > scrollMargin) {
        setHideTopNav(true);
        initialScrollTop.current = scrollTop;
      }

      if (isScrollingUp) {
        setHideTopNav(false);
        initialScrollTop.current = scrollTop;
      }

      setAtTop(scrollTop === 0);
      setPrevScrollTop(scrollTop);
    },
    [setPrevScrollTop]
  );

  useEffect(() => {
    const handler = () => hideNavIfScrollingDown(isMobileMenuOpen);
    window.addEventListener("scroll", handler);
    return () => window.removeEventListener("scroll", handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobileMenuOpen]);

  return (
    <div css={styles.navBuffer} ref={menuAnchor}>
      <div
        css={[styles.navWrapper, styles.navBarTransition(hideTopNav, atTop)]}
        data-testid="header-nav-wrapper"
      >
        <div css={[appStyles.container, styles.innerContainer]}>
          <div className="logo-container">
            <Link
              to={(location: Location) => {
                const pathname = isUserLoggedIn ? "/home" : "/select-org";
                return { ...location, pathname };
              }}
              data-testid="header-nav-logo"
              data-heapid="header-nav-logo"
            >
              <SongtradrHomeLogoWhite />
            </Link>
          </div>
          {isAuthenticated && !needToSelectOrg && (
            <>
              <div className="middle-container">
                <div css={appStyles.desktopOnly}>
                  {showProjectsSubNav && (
                    <>
                      <Link
                        {...projectsSubNavData.CommercialLicenses}
                        css={[
                          styles.link,
                          projectsSubNavData.CommercialLicenses.isActive &&
                            styles.activeLink,
                        ]}
                      >
                        Commercial licenses
                      </Link>
                      {hasAccessToMusicUsageDeclaration && (
                        <Link
                          {...projectsSubNavData.LibraryLicenses}
                          css={[
                            styles.link,
                            projectsSubNavData.LibraryLicenses.isActive &&
                              styles.activeLink,
                          ]}
                        >
                          Library Licenses
                        </Link>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="nav-menus">
                <AvatarMenu
                  css={appStyles.desktopOnly}
                  data-testid="avatar-menu"
                />
                <GridMenu css={appStyles.desktopOnly} data-testid="grid-menu" />
                <MobileNavMenu
                  css={[appStyles.mobileOnly, styles.mobileNav]}
                  isMobileMenuOpen={isMobileMenuOpen}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                  menuAnchor={menuAnchor}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavigationBar;
