import styles from "../custom-select/styles";

interface ICustomFloatingLabel {
  isInputFocused: boolean;
  placeholder: string;
}

const CustomFloatingLabel = ({
  isInputFocused,
  placeholder,
}: ICustomFloatingLabel) => {
  return <span css={styles.floatingLabel(isInputFocused)}>{placeholder}</span>;
};

export default CustomFloatingLabel;
