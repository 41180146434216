import { css } from "@emotion/react";
import theme from "../../../theme";

export default {
  container: css`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${theme.colors.background.basaltBlack};
    font-family: sofia-pro, sans-serif;
  `,
  section: css`
    background: transparent;
    display: flex;
    flex-direction: column;
    flex: auto;
    min-height: 0;
  `,
  mainContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: auto;
  `,
  copyrightFooter: css`
    width: 100%;
  `,
  logo: css`
    margin-bottom: 5rem;
    margin-top: 3rem;
  `,
  row: css`
    width: 100%;
  `,
  inputContainer: css`
    margin-bottom: 1rem;
  `,
  input: css`
    font-family: ${theme.fonts.sofiaPro};
  `,
  subText: css`
    color: ${theme.colors.white};
    font-size: 14px !important;
    font-weight: 600;
    margin-top: -8px;
    margin-bottom: 48px;
    text-align: right;
    cursor: pointer;
    width: 110px;
    float: right;
  `,
  forgotPassword: css`
    color: ${theme.colors.white} !important;
    font-size: 16px !important;
    font-weight: 600;
    margin-top: 2rem;
    cursor: pointer;
    text-align: center;
  `,
  backToSignin: css`
    color: ${theme.colors.white} !important;
    font-size: 16px !important;
    font-weight: 600;
    margin-top: 2rem;
    cursor: pointer;
    text-align: center;
  `,
  label: css`
    font-size: 0.9rem;
    padding-bottom: 0.3rem;
  `,
  title: css`
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.5rem;
    letter-spacing: -0.64px;
    margin-bottom: 1rem;
  `,
  description: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400;
    margin-bottom: 48px;
    color: ${theme.colors.white};
  `,
  inputHeader: css`
    color: ${theme.colors.white};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 3.3px;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
  `,
  button: css`
    border-radius: 0.2rem;
    border: 0;
    width: 100%;
    height: 50px;
    font-size: 1rem;
    cursor: pointer;
  `,
  resetButton: css`
    border-radius: 0.2rem;
    background-color: ${theme.colors.white};
    border: 0;
    color: ${theme.colors.white};
    width: 100%;
    height: 50px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 50px;

    span {
      color: ${theme.colors.white};
    }

    :hover {
      background-color: ${theme.colors.primary.eiffelBlue};
      color: ${theme.colors.white};
    }

    :focus {
      background-color: ${theme.colors.primary.eiffelBlue};
      color: ${theme.colors.white};
    }
  `,
  customError: css`
    margin: 0 0 11px 0;
    padding: 15px 0 15px 17.5px !important;
    box-sizing: border-box !important;
    width: 100%;
    background-color: ${theme.colors.functional.alertRedLight};
    display: flex;
  `,
  customErrorIcon: css`
    background-color: ${theme.colors.functional.alertRed} !important;
    border-radius: 50%;
    margin-right: 10px;
    color: white;
  `,
  customReset: css`
    margin: 0 0 11px 0;
    padding: 15px 0 15px 17.5px !important;
    box-sizing: border-box !important;
    width: 100%;
    background-color: ${theme.colors.functional.successGreenLight};
    display: flex;
  `,
  customResetIcon: css`
    background-color: ${theme.colors.functional.successGreen} !important;
    border-radius: 50%;
    margin-right: 10px;
    color: white;
  `,
  customResetMsg: css`
    font-weight: 700;
    line-height: 18px;
  `,
  customErrorMsg: css`
    font-weight: 700;
    line-height: 18px;
  `,
  graphicLeft: css`
    position: absolute;
    right: 0;
    top: 75px;
  `,
  graphicRight: css`
    position: absolute;
    bottom: 75px;
  `,
  flexContainer: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  backBtn: css`
    position: absolute;
    top: 0;
    left: 0;
  `,
};
