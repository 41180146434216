import { SerializedStyles, css } from "@emotion/react";
import theme from "src/theme";

export default {
  getTableStyles(selectableRow: boolean): SerializedStyles {
    return css`
      .ant-table {
        background: ${theme.colors.background.basaltBlack} !important;
        border-radius: 0 !important;
        box-shadow: none !important;
        .ant-table-measure-row {
          td {
            padding: 0 !important;
          }
        }
        table {
          tr {
            height: 64px !important;
            th {
              color: ${theme.colors.white} !important;
              border-right: 1px solid
                var(
                  --basalt-background,
                  ${theme.colors.background.landingDarkGray}
                );
              border-bottom: none !important;
              background: ${theme.colors.background.veryDarkGray} !important;
              font-size: 16px;
              text-wrap: nowrap;
              padding: 18px 16px !important;
            }
            &:hover {
              cursor: ${selectableRow ? "pointer" : "default"};
              td {
                background: ${theme.colors.black} !important;
              }
            }
          }

          td {
            padding: 8px 16px !important;
            min-height: 64px !important;
            height: 64px;
            color: ${theme.colors.white} !important;
            background: ${theme.colors.background.basaltBlack};
            border-bottom: none !important;
            border-top: none !important;
            &:hover {
              background: ${theme.colors.background.veryDarkGray} !important;
            }
            a {
              color: ${theme.colors.white} !important;
              text-decoration: none;
              &:hover {
                color: ${theme.colors.functional.brightOrangeHover} !important;
              }
            }
          }
          .ant-table-measure-row {
            height: 0 !important;
          }
          thead tr th:first-of-type .ant-checkbox-inner::after {
            position: relative;
            border: none;
            width: 25px;
            height: 25px;
            background: transparent;
          }
          .ant-checkbox-inner {
            border: 1.5px solid ${theme.colors.functional.gray65};
          }
          .ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: #77acba !important;
              color: ${theme.colors.black} !important;
              ::after {
                border-color: ${theme.colors.black} !important;
              }
            }
          }

          .ant-table-tbody > tr > td {
            max-width: none;
          }
          border-radius: 0 !important;
          padding: 0;
          box-shadow: none !important;
          .more-info-button {
            svg {
              transform: rotate(90deg);
            }
          }
        }
      }
      .ant-checkbox:hover .ant-checkbox-inner {
        border-color: ${theme.colors.white} !important;
      }
      .ant-pagination {
        li {
          color: ${theme.colors.secondary.mediumGrey} !important;
        }
        background: ${theme.colors.background.landingDarkGray} !important;

        .ant-pagination-item {
          font-weight: 500 !important;
          background: ${theme.colors.functional.brightOrange} !important;
          border: transparent !important;
          border-radius: 1rem !important;

          a {
            color: ${theme.colors.white} !important;
          }
        }

        .ant-pagination-item.ant-pagination-item-active {
          margin: 0 0.6rem !important;
        }

        .ant-pagination-prev .ant-pagination-item-link,
        .ant-pagination-next .ant-pagination-item-link {
          span {
            color: ${theme.colors.white} !important;
          }
          border: none !important;
          background: none !important;
        }

        .ant-pagination-jump-prev
          .ant-pagination-item-container
          .ant-pagination-item-link-icon,
        .ant-pagination-jump-next
          .ant-pagination-item-container
          .ant-pagination-item-link-icon {
          color: ${theme.colors.white};
        }

        .ant-pagination-jump-prev
          .ant-pagination-item-container
          .ant-pagination-item-ellipsis,
        .ant-pagination-jump-next
          .ant-pagination-item-container
          .ant-pagination-item-ellipsis {
          color: ${theme.colors.white};
        }

        .ant-pagination-item:not(.ant-pagination-item-active) {
          font-weight: 500 !important;
          background: none !important;

          a {
            margin: 0 0.6rem !important;
            margin-right: 5px;
            min-width: 16px !important;
            height: 16px !important;
            color: ${theme.colors.white} !important;
            border: none !important;
            border-radius: 1rem !important;
          }
        }
      }
      .actions {
        .approve-icon {
          margin-right: 8px;
          rect {
            fill: ${theme.colors.white};
          }
          path {
            fill: ${theme.colors.functional.approveGreen};
          }
          &:hover {
            cursor: pointer;
            rect {
              fill: ${theme.colors.functional.approveGreen};
            }
            path {
              fill: ${theme.colors.white};
            }
          }
        }
        .deny-icon {
          cursor: pointer;
          rect {
            fill: ${theme.colors.white};
          }
          path {
            fill: ${theme.colors.functional.denyRed};
          }
          &:hover {
            cursor: pointer;
            rect {
              fill: ${theme.colors.functional.denyRed};
            }
            path {
              fill: ${theme.colors.white};
            }
          }
        }
        button {
          background: none;
          border: none;
        }
      }
      .ant-table-placeholder {
        font-family: ${theme.fonts.sofiaPro};
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.5rem;
      }
      .ant-spin-nested-loading {
        .ant-spin-dot-item {
          background-color: ${theme.colors.functional.brightOrangeHover};
        }
      }
    `;
  },
};
