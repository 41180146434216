import React, { useMemo } from "react";
import styles from "./styles";

interface IUserImageProps {
  imageURL?: string;
  name: string;
  size: number;
  fontSize: number;
}

const UserImage = ({ imageURL, name, size, fontSize }: IUserImageProps) => {
  const showImage = useMemo(() => Boolean(imageURL), [imageURL]);
  const initials = useMemo(() => {
    if (!name) {
      return "";
    }
    const [firstName, lastName] = name.split(" ");
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  }, [name]);

  return (
    <>
      {!showImage && (
        <div css={styles.initialsImage(size, fontSize)}>{initials}</div>
      )}
      {showImage && <img css={styles.image(size)} src={imageURL} alt={name} />}
    </>
  );
};

export default UserImage;
