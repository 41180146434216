import React, { ReactElement } from "react";

const HamburgerIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="14" y="15.5" width="20" height="3" rx="1" fill="white" />
      <rect x="14" y="22.5" width="20" height="3" rx="1" fill="white" />
      <rect x="14" y="29.5" width="20" height="3" rx="1" fill="white" />
    </svg>
  );
};

export default HamburgerIcon;
