import React, { ReactElement, useEffect, useMemo, useRef } from "react";
import { Route, RouteProps } from "react-router-dom";
import useAuth from "src/auth/use-auth";
import { DataDogLogTypes, log } from "src/utils/data-dog";

export enum RestrictionType {
  GUEST_ONLY = "GUEST_ONLY",
  AUTHENTICATED_WITHOUT_ORGANISATION = "AUTHENTICATED_WITHOUT_ORGANISATION",
  AUTHENTICATED_ONLY = "AUTHENTICATED_ONLY",
  SUPER_ADMIN_ONLY = "SUPER_ADMIN_ONLY",
}

interface IProps extends RouteProps {
  restriction: RestrictionType;
  whenLoadingShow?: ReactElement | null;
}

const RestrictedRoute = ({
  restriction,
  whenLoadingShow = null,
  ...rest
}: IProps): ReactElement | null => {
  const { isSessionValid, isAuthenticated, isSuperAdmin, logout } = useAuth();
  const isSessionCheckInProgress = useRef(false);

  useEffect(() => {
    const checkValidity = async () => {
      const validity = await isSessionValid();
      if (!validity) {
        logout();
      }
    };
    if (!isSessionCheckInProgress.current) {
      try {
        isSessionCheckInProgress.current = true;
        const isAuthenticationWithoutOrgId =
          RestrictionType.AUTHENTICATED_WITHOUT_ORGANISATION === restriction;
        if (!isAuthenticationWithoutOrgId) {
          void checkValidity();
        } else if (isAuthenticationWithoutOrgId && !isAuthenticated) {
          logout();
        }
      } catch (cognitoError) {
        log(DataDogLogTypes.ERROR, "Login failure", cognitoError);
        logout();
      } finally {
        isSessionCheckInProgress.current = false;
      }
    }
  }, [isSessionValid, logout, restriction, isAuthenticated]);

  const isAllowed = useMemo(() => {
    switch (restriction) {
      case RestrictionType.AUTHENTICATED_ONLY:
      case RestrictionType.AUTHENTICATED_WITHOUT_ORGANISATION:
        return isAuthenticated;
      case RestrictionType.GUEST_ONLY:
        return !isAuthenticated;
      case RestrictionType.SUPER_ADMIN_ONLY:
        return isSuperAdmin;
      default:
        throw Error(
          "Cannot restrict a route without specifying its restriction"
        );
    }
  }, [isAuthenticated, isSuperAdmin, restriction]);
  return (
    <Route {...rest} {...(isAllowed ? {} : { children: whenLoadingShow })} />
  );
};

export default RestrictedRoute;
