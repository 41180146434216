import { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "./interfaces";

const CopyLink = ({
  height = theme.svgSize.medium,
  width = theme.svgSize.medium,
  fill = theme.colors.white,
}: IComponentIconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.718 30.1318C22.0567 31.7766 19.3531 31.7766 17.6912 30.1318C16.0293 28.4869 16.043 25.823 17.7043 24.1782L17.7786 24.1046C17.8994 23.9849 17.8994 23.7905 17.7786 23.6708L16.4619 22.3671C16.341 22.2475 16.1446 22.2475 16.0238 22.3671L16.0027 22.388C13.3785 24.9862 13.314 29.2158 15.9228 31.8287C18.542 34.4521 22.8294 34.4613 25.4604 31.8563L29.0395 28.3127C29.1604 28.193 29.1604 27.9986 29.0395 27.8789L27.7283 26.5807C27.6069 26.4605 27.4105 26.4611 27.2896 26.5807L23.718 30.1318ZM32.0064 15.9037C29.3673 13.3208 25.0954 13.3846 22.4712 15.9829L18.9448 19.4744C18.824 19.594 18.824 19.7885 18.9448 19.9081L20.256 21.2063C20.3774 21.3266 20.5739 21.326 20.6947 21.2063L24.2664 17.6559C25.9276 16.0111 28.6312 16.0111 30.2931 17.6559C31.9544 19.3008 31.9407 21.964 30.2794 23.6095L30.2051 23.6831C30.0843 23.8027 30.0843 23.9972 30.2051 24.1169L31.5218 25.4206C31.6427 25.5402 31.8391 25.5402 31.9599 25.4206L32.0337 25.3476C34.6647 22.7426 34.6554 18.4983 32.0058 15.9043L32.0064 15.9037Z"
      fill={fill}
    />
    <path
      d="M26.8094 19.3678L19.4203 26.6838C19.2993 26.8036 19.2993 26.9978 19.4203 27.1176L20.7347 28.419C20.8557 28.5388 21.0519 28.5388 21.1729 28.419L28.562 21.1031C28.683 20.9833 28.683 20.7891 28.562 20.6693L27.2475 19.3678C27.1265 19.248 26.9304 19.248 26.8094 19.3678Z"
      fill={fill}
    />
  </svg>
);

export default CopyLink;
