import React from "react";
import style from "../styles";

export default () => {
  return [
    {
      title: <div css={style.titleContainer}>Agency</div>,
      dataIndex: "brand",
      key: "key",
      width: "20%",
    },
    {
      title: (
        <div css={[style.titleContainer, style.teamEnterpriseAdminCol]}>
          Members
        </div>
      ),
      dataIndex: "members",
      key: "key",
      width: "70%",
    },
    {
      title: "",
      dataIndex: "viewAgency",
      key: "key",
      width: "5%",
    },
    // {
    //   title: "",
    //   dataIndex: "viewBrand",
    //   key: "key",
    //   width: "5%",
    // },
  ];
};
