import React, { ReactElement, useState } from "react";
import { Button, Input as ModalInput } from "@songtradr/component-library";
import { debounce } from "lodash";
import RubbishBinIcon from "src/app/assets/icons/component-icons/bin-icon";
import ChevronRight from "src/app/assets/icons/component-icons/chevron-right";
import ModalPortal from "src/components/modal-portal";
import BaseModal from "src/components/modals/base-modal";
import { IOrganisation } from "src/interfaces/organisation";
import deleteOrganisation from "src/api/organisation/delete-organisation";
import useAuth from "src/auth/use-auth";
import { errorToast, successToast } from "src/components/toast-notification";
import { Form, Input } from "antd";
import { RuleObject } from "antd/lib/form";
import MagnifyingGlass from "src/app/assets/icons/component-icons/magifying_glass";
import STLoadingLogo from "src/components/st-loading-logo";
import { IProps } from "./interfaces";
import styles from "./styles";

const searchDebounceDelay = 750;

const OrgTable = ({
  orgs,
  possiblePages,
  currentPage,
  handleSelectOrgIndex,
  handleUpdateOrgSearchQuery,
  handleGoToPage,
  selectedOrgIndex,
  isLoading,
  refreshData,
}: IProps): ReactElement | null => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleteInProgress, setDeleteInProgress] = useState<boolean>(false);
  const [orgToDelete, setOrgToDelete] = useState<IOrganisation | null>(null);
  const [form] = Form.useForm();
  const { getAccessToken } = useAuth();

  if (!orgs) {
    return null;
  }

  const handleSearchQueryChange = debounce(
    handleUpdateOrgSearchQuery,
    searchDebounceDelay
  );

  const openDeleteModal = (org: IOrganisation) => {
    setDeleteModalOpen(true);
    setOrgToDelete(org);
  };

  const namesMatch = () => {
    return (
      form
        .getFieldValue("organisationName")
        ?.localeCompare(orgToDelete?.name || "") === 0
    );
  };

  const handleDeleteOrg = async () => {
    if (!namesMatch() || !orgToDelete?.id) {
      return;
    }
    setDeleteInProgress(true);
    handleSelectOrgIndex(undefined);

    const accessToken = getAccessToken();
    const response = await deleteOrganisation(accessToken, orgToDelete.id);

    if (response?.status === 200) {
      successToast({ message: "Organization was deleted" });
      refreshData();
    } else {
      errorToast({
        message: "There was a problem deleting the organization",
      });
    }

    setDeleteInProgress(false);
    setDeleteModalOpen(false);
  };

  const DeleteModalContent = () => {
    return (
      <>
        <Form
          form={form}
          onFinish={handleDeleteOrg}
          key={orgToDelete?.id}
          preserve={false}
        >
          <h2 css={styles.modalTitle}>Delete Organization?</h2>
          <div css={styles.modalContent}>
            Type{" "}
            <span css={styles.modalHighlightText}>{orgToDelete?.name}</span>{" "}
            into the text box to confirm you wish to delete this organization
          </div>
          <Form.Item
            name="organisationName"
            rules={[
              {
                validator: (_rule: RuleObject, value: string) => {
                  try {
                    if (!namesMatch() && value?.length > 0) {
                      throw new Error("Organization name does not match");
                    }
                    return Promise.resolve();
                  } catch (error) {
                    return Promise.reject(error);
                  }
                },
              },
              {
                required: true,
                message:
                  "Please enter the name of the organization you want to delete",
              },
            ]}
            validateTrigger={["onBlur"]}
          >
            <ModalInput
              aria-label="Organization Name"
              id="delete-org-input"
              css={styles.modalInput}
              placeholder="Organization name"
            />
          </Form.Item>
          <Form.Item>
            <div css={styles.deleteModalButtons}>
              <Button onClick={() => setDeleteModalOpen(false)}>Cancel</Button>
              <Button variant="primary">
                {deleteInProgress ? "Loading..." : "Delete Organization"}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </>
    );
  };

  const getOrgJsx = () => {
    if (isLoading) {
      return (
        <div css={styles.loadingSpinnerContainer}>
          <STLoadingLogo relativePosition />
        </div>
      );
    }

    if (!orgs.length) {
      return <div css={styles.noResultsFound}>No results were found</div>;
    }

    return orgs?.map((org, index) => (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        tabIndex={0}
        data-testid={`org-select-${index}`}
        role="button"
        css={[
          styles.row,
          selectedOrgIndex === index ? styles.selectedRow : null,
        ]}
        key={org.id}
        onClick={() => handleSelectOrgIndex(index)}
      >
        <span>{org.name}</span>
        <button
          type="button"
          data-testid={`org-delete-${index}`}
          css={styles.btnReset}
          onClick={(e) => {
            e.stopPropagation();
            openDeleteModal(org);
          }}
        >
          <RubbishBinIcon />
        </button>
      </div>
    ));
  };

  return (
    <div>
      {deleteModalOpen && (
        <ModalPortal>
          <BaseModal
            visible={deleteModalOpen}
            content={<DeleteModalContent />}
            buttons={[]}
            onClose={() => setDeleteModalOpen(false)}
          />
        </ModalPortal>
      )}

      <div css={styles.headingContainer}>
        <h2 css={styles.heading}>Organizations</h2>
        <Input
          prefix={<MagnifyingGlass />}
          data-testid="org-search-input"
          css={styles.searchInput}
          placeholder="Search by organization name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleSearchQueryChange(e.target.value)
          }
        />
      </div>
      <div css={styles.tableContainer} data-testid="Org-table">
        {getOrgJsx()}
      </div>
      {isLoading || !orgs.length ? null : (
        <div css={styles.paginationContainer}>
          <button
            type="button"
            css={[styles.btnReset, styles.pageBtn]}
            onClick={() => handleGoToPage(currentPage - 1)}
            data-testid="prev-page-btn"
          >
            <ChevronRight css={styles.horizontalFlip} />
          </button>
          <div>
            {possiblePages.map((page) => (
              <button
                type="button"
                css={[
                  styles.btnReset,
                  styles.pageBtn,
                  currentPage === page ? styles.currentPageBtn : null,
                ]}
                key={`page-${page}`}
                onClick={() => handleGoToPage(page)}
                data-testid={
                  currentPage === page
                    ? `current-page-btn`
                    : `go-to-page-${page}-btn`
                }
              >
                {page + 1}
              </button>
            ))}
          </div>
          <button
            type="button"
            css={[styles.btnReset, styles.pageBtn]}
            onClick={() => handleGoToPage(currentPage + 1)}
            data-testid="next-page-btn"
          >
            <ChevronRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default OrgTable;
