import { ReactElement } from "react";
import theme from "src/theme";

interface IIconComponentProps {
  fill?: string;
  width?: string | number;
  height?: string | number;
  title?: string;
  role?: string;
}

const CloseIcon = ({
  fill = theme.colors.secondary.blueOnBlack,
  height = "14",
  width = "13",
  title,
  role = "img",
}: IIconComponentProps): ReactElement => {
  return (
    <svg
      className="close-icon"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 9 9"
      aria-labelledby="close-icon-title"
      role={role}
    >
      <title id="close-icon-title">{title}</title>
      <g
        fill="none"
        fillRule="evenodd"
        stroke={fill}
        strokeLinecap="round"
        strokeWidth="1.5"
      >
        <line
          x1="4.179"
          x2="4.179"
          y1="-.643"
          y2="9.643"
          transform="rotate(-45 4.179 4.5)"
        />
        <line
          x1="4.179"
          x2="4.179"
          y1="-.643"
          y2="9.643"
          transform="scale(1 -1) rotate(-45 -6.685 0)"
        />
      </g>
    </svg>
  );
};
export default CloseIcon;
