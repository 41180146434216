import { ReactElement } from "react";

const StatusExpired = (): ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" fill="#141414" stroke="#555555" />
      <rect x="8" y="14" width="16" height="4" rx="0.5" fill="white" />
    </svg>
  );
};

export default StatusExpired;
