import axios from "axios";
import { IProjectForm } from "src/pages/projects/client-project-view/interfaces";
import { getProjectUrl } from "../../project-url-helper";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

const getProject = async (
  accessToken: string,
  organisationId: string,
  projectId: string
): Promise<IProjectForm | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };
  const { data } = await axios.get<IProjectForm>(
    getProjectUrl(projectId, organisationId),
    options
  );
  return data;
};

export default getProject;
