import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils";
import theme from "src/theme";

const styles = {
  formItem: css`
    width: 100%;
    color: ${theme.colors.white};
    margin-bottom: 1.5rem;

    p {
      font-size: 0.75rem;
    }

    .ant-input {
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.background.darkGray} !important;
      background: ${theme.colors.background.veryDarkGray};

      &:focus {
        border-color: ${theme.colors.white};
      }
    }

    input {
      font-family: ${theme.fonts.sofiaPro};
      border: 1px solid ${theme.colors.background.darkGray} !important;
      background: ${theme.colors.background.veryDarkGray};
      color: ${theme.colors.white};
    }
  `,
  selector: css`
    .ant-select {
      width: 100%;
    }

    .ant-select-dropdown {
      background-color: ${theme.colors.white};
    }
    .ant-select-selector {
      background: white !important;
      padding: -2px 8px !important;
      font-size: 16px !important;
      font-family: ${theme.fonts.sofiaPro};
    }
    .ant-select-selection-item {
      padding-top: 8px !important;
      color: ${theme.colors.white} !important;
    }
    .ant-select-selection-placeholder {
      font-family: ${theme.fonts.sofiaPro} !important;
      padding-top: 10px !important;
    }
    .ant-select-selection-search-input {
      margin-top: 0 !important;
    }
  `,
  formDropdownMenu: css`
    border-radius: 0;
    border-radius: 4px;
    border: 1px solid ${theme.colors.background.darkGray} !important;
    background: ${theme.colors.background.veryDarkGray} !important;

    .ant-select-selection-placeholder {
      font-family: ${theme.fonts.sofiaPro} !important;
      padding-top: 10px !important;
    }
    .ant-dropdown-menu-item {
      &:hover {
        background: ${theme.colors.background.darkGray};
      }
    }
    .ant-select-arrow {
      color: white !important;
    }

    .ant-select-selection-item {
      color: ${theme.colors.white} !important;
    }
  `,
  singleSelectMenu: css`
    .ant-select-item,
    .ant-select-item-option-content {
      padding: unset !important;
      margin: 0 !important;
      background: ${theme.colors.background.veryDarkGray} !important;
      border-radius: 0 !important;
      display: flex;
      color: white;
    }

    .ant-select-item-option-content {
      padding-top: 8px !important;
      padding-left: 16px !important;
      padding-bottom: 8px !important;
    }

    .ant-select-item-option {
      background: ${theme.colors.background.veryDarkGray} !important;

      :hover {
        background: ${theme.colors.background.darkGray} !important;
      }
    }

    .ant-select-selection-overflow {
      margin-left: 0.5rem;
    }
  `,
  textArea: css`
    font-family: ${theme.fonts.sofiaPro} !important;
    height: 120px !important;
    resize: none;
    width: 480px;
    color: ${theme.colors.white} !important;
    border-color: ${theme.colors.background.darkGray} !important;
    background: ${theme.colors.background.veryDarkGray} !important;
  `,
  submitBtn: css`
    margin-top: 1rem;
    font-family: ${theme.fonts.sofiaPro};
    width: 180px !important;
  `,
  panelContent: css`
    font-family: ${theme.fonts.sofiaPro} !important;
    width: 100%;
  `,
  panelTitle: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 300;
    font-size: 1rem;
    margin-top: 2rem;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;

    @media ${queries.large} {
      font-weight: 250;
      font-size: 1.5rem;
      line-height: 2rem;
      margin-top: 0;
      margin-bottom: 1rem;
    }
  `,
  panelBody: css`
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
    font-size: 0.75rem;
    margin-top: 0.5rem;
    line-height: 1rem;
    margin-bottom: 1.5rem;

    @media ${queries.large} {
      font-weight: 250;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 1.5rem;
    }
  `,
};

export default styles;
