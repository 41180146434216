import React from "react";
import BackButton from "src/components/back-button";
import appStyles from "src/app/styles";
import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import styles from "../../styles";

interface IProjectHeaderProps {
  campaignName: string;
  sequenceNo: number;
  name: string;
  brandName?: string;
  onBackButtonClick: () => void;
}

const ProjectHeader = ({
  campaignName,
  sequenceNo,
  name,
  brandName,
  onBackButtonClick,
}: IProjectHeaderProps) => {
  const { t } = useTranslation();

  return (
    <div css={styles.header}>
      <div css={[appStyles.container, styles.headerContent]}>
        <div css={styles.mainHeader}>
          <div css={styles.backBtnAndTitleCol}>
            <BackButton onClick={onBackButtonClick} css={styles.backBtn} />
            <div css={styles.title}>{campaignName}</div>
            <div css={styles.subtitles}>
              <div>
                <span>{t("ClientProjectViewPage##Project ID")}:</span>
                <span>{sequenceNo}</span>
              </div>
              <Divider type="vertical" />
              <div>
                <span>{t("ClientProjectViewPage##Project Name")}:</span>
                <span>{name}</span>
              </div>
              {brandName && (
                <>
                  <Divider type="vertical" />
                  <div>
                    <span>{t("ClientProjectViewPage##Brand")}:</span>
                    <span>{brandName}</span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectHeader;
