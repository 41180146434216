import { Fragment, ReactElement, useEffect, useState } from "react";
import { IOrganisationMemberProps } from "src/pages/team/interfaces";
import { IRelatedOrganisation } from "src/interfaces/related-organisations";
import { Table, Tooltip } from "antd";
import theme from "src/theme";
import TableLoginIcon from "src/app/assets/icons/component-icons/login-icon";
import ViewTableItemIcon from "src/app/assets/icons/component-icons/view-table-item-icon";
import RubbishBinIcon from "src/app/assets/icons/component-icons/bin-icon";
import { IBrandObj } from "../../table-data/org-brands-data";
import getColumns from "../../table-data/org-brand-columns";
import getBrandProfileColumns from "../../table-data/org-brand-view-columns";
import { IChildOrgMemberList } from "../..";
import styles from "../../styles";

export type OrgMembersByOrg = Map<
  IRelatedOrganisation,
  IOrganisationMemberProps[]
>;

enum PageName {
  BrandsOverview = "brands-overview",
  BrandView = "brand-profile",
}

interface IProps {
  orgMembersByOrg: OrgMembersByOrg;
  currentChildTab: string;
  setChildTab: (value: string) => void;
  setSelectedBrand: (org: IRelatedOrganisation) => void;
  setChildOrgMembersData: (
    value: React.SetStateAction<OrgMembersByOrg>
  ) => void;
  setBrandTitle: (name: string) => void;
  handleAssignMember: (
    org: IRelatedOrganisation,
    members: IOrganisationMemberProps[]
  ) => void;
  handleUnassignMember: (
    member: IOrganisationMemberProps,
    destinationOrg: IRelatedOrganisation
  ) => Promise<void>;
}

export const ChildOrgMembersTable = ({
  orgMembersByOrg,
  currentChildTab,
  setChildTab,
  setBrandTitle,
  setSelectedBrand,
  handleAssignMember,
  handleUnassignMember,
  setChildOrgMembersData,
}: IProps): ReactElement => {
  const unassignMember = async (
    member: IOrganisationMemberProps,
    destinationOrg: IRelatedOrganisation
  ) => {
    await handleUnassignMember(member, destinationOrg);
  };

  const [
    brandProfileTableData,
    setBrandProfileTableData,
  ] = useState<IChildOrgMemberList>();

  const brandsData: IBrandObj[] = [];
  orgMembersByOrg.forEach(
    (members: IOrganisationMemberProps[], org: IRelatedOrganisation) => {
      brandsData.push({ members, org });
    }
  );

  useEffect(() => {
    if (brandProfileTableData) {
      const updatedBrandsData: IBrandObj[] = [];
      orgMembersByOrg.forEach(
        (members: IOrganisationMemberProps[], org: IRelatedOrganisation) => {
          updatedBrandsData.push({ members, org });
        }
      );
      setBrandProfileTableData(updatedBrandsData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgMembersByOrg]);

  const handleViewBrand = (brand: IBrandObj) => {
    if (brand) {
      setBrandProfileTableData(brand);
      setChildTab(PageName.BrandView);
    }
  };

  const columns = getColumns;

  const brandViewColumns = getBrandProfileColumns;

  const brandView = (brandInfo: IChildOrgMemberList): ReactElement => {
    const { members, org } = brandInfo;

    const brandProfiledata = members.map((member) => {
      return {
        key: member.id,
        member: (
          <Fragment>
            <div css={styles.teamInviteEmail}>
              {member.firstName} {member.lastName}
            </div>
            <div css={styles.brandMembersCol}>{member.email}</div>
          </Fragment>
        ),
        removeFromBrand: (
          <Fragment>
            <div css={styles.brandMembersCol}>
              <Tooltip
                getPopupContainer={(triggerNode) => triggerNode}
                title="Remove user"
                color={theme.colors.white}
                placement="top"
                css={styles.tooltip}
              >
                <button
                  type="button"
                  css={styles.iconButton}
                  onClick={() => unassignMember(member, org)}
                >
                  <RubbishBinIcon />
                </button>
              </Tooltip>
            </div>
          </Fragment>
        ),
      };
    });

    if (members !== undefined && members.length > 0) {
      return (
        <div>
          <Table
            dataSource={brandProfiledata}
            columns={brandViewColumns()}
            pagination={false}
            css={styles.table}
            data-testid="team-members-table"
          />
        </div>
      );
    }
    return <div />;
  };

  const data = brandsData.map((brand: IBrandObj) => {
    const relatedOrganisation = {
      orgId: brand.org.orgId,
      name: brand.org.name,
    };

    const currentMembers = brand.members;

    return {
      key: brand.org.orgId,
      brand: (
        <Fragment>
          <div css={styles.teamInviteEmail}>{brand.org.name}</div>
        </Fragment>
      ),
      members: (
        <Fragment>
          <div css={styles.brandMembersCol}>
            {brand.members
              .map(
                (member: { firstName: string; lastName: string }) =>
                  `${member.firstName} ${member.lastName}`
              )
              .join(", ")}
          </div>
        </Fragment>
      ),
      viewUser: (
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title="View brand"
          color={theme.colors.white}
          placement="top"
          css={styles.tooltip}
        >
          <button
            type="button"
            css={styles.iconButton}
            onClick={(e) => {
              e.stopPropagation();
              handleViewBrand(brand);
              setBrandTitle(brand.org.name);
              setSelectedBrand(brand.org);
            }}
          >
            <ViewTableItemIcon />
          </button>
        </Tooltip>
      ),
      viewBrand: (
        <Tooltip
          getPopupContainer={(triggerNode) => triggerNode}
          title="Add member"
          color={theme.colors.white}
          placement="top"
          css={styles.tooltip}
        >
          <button
            type="button"
            css={styles.iconButton}
            onClick={() => {
              setChildOrgMembersData(orgMembersByOrg);
              handleAssignMember(relatedOrganisation, currentMembers);
            }}
          >
            <TableLoginIcon />
          </button>
        </Tooltip>
      ),
    };
  });

  return (
    <Fragment>
      {currentChildTab === PageName.BrandsOverview && (
        <Table
          dataSource={data}
          columns={columns()}
          pagination={false}
          css={styles.table}
          data-testid="team-members-table"
        />
      )}
      {currentChildTab === PageName.BrandView && brandProfileTableData && (
        <div>{brandView(brandProfileTableData)}</div>
      )}
    </Fragment>
  );
};
