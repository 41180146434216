import { SerializedStyles, css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";

const imageContainer = css`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  margin-top: -96px;
  position: fixed;
`;

export default {
  fullScreenBackground(url: string): SerializedStyles {
    return css`
      ${imageContainer};
      @media ${queries.large} {
        ${url &&
        `
          ::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
              270deg,
              rgba(20, 20, 20, 0.9) 0%,
              rgba(20, 20, 20, 0) 50%
            ),
            linear-gradient(90deg, rgba(20, 20, 20, 0.8) 0%, rgba(20, 20, 20, 0) 50%),
            linear-gradient(0deg, #141414 0%, rgba(20, 20, 20, 0) 44.68%),
            url("${url}") lightgray 50% / cover no-repeat;
            filter: grayscale(100%);
            opacity: 0.6;
          }
        `}
      }
    `;
  },
};
