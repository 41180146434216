import axios from "axios";
import { buildOrganisationSubscriptionsBaseUrl } from "src/api/base-url-helper";
import { IOrganisation } from "src/interfaces/organisation";
import { getJsonContentHeader, getAuthHeader } from "../../headers";

export interface IOrganisationSearchResponse {
  length: number;
  total: number;
  limit: number;
  start: number;
  items: IOrganisation[];
}

const selfOrganisations = async (
  accessToken: string,
  abortController?: AbortController
): Promise<IOrganisationSearchResponse | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.get<IOrganisationSearchResponse>(
    `${buildOrganisationSubscriptionsBaseUrl()}/self`,
    options
  );
  return data;
};
export default selfOrganisations;
