import React, { ReactElement } from "react";
import MobileDrawer from "src/components/mobile-drawer";
import generateDrawerContent from "./drawers";
import { DrawerTypes, IProjectTableSort } from "../interfaces";

interface IProps {
  mobileDrawerVisible: boolean;
  currentActiveDrawer: DrawerTypes;
  handleDrawerToggle: () => void;
  handleSort: (params: IProjectTableSort) => void;
  sortOptions: IProjectTableSort;
}

const ProjectsMobileControls = ({
  mobileDrawerVisible,
  handleDrawerToggle,
  handleSort,
  currentActiveDrawer,
  sortOptions,
}: IProps): ReactElement => {
  const Drawer = generateDrawerContent(
    currentActiveDrawer,
    handleSort,
    sortOptions,
    handleDrawerToggle
  );

  return (
    <MobileDrawer
      placement="bottom"
      onClose={handleDrawerToggle}
      visible={mobileDrawerVisible}
      key="bottom"
      closable={false}
      data-testid="mobile-drawer-container"
    >
      {Drawer}
    </MobileDrawer>
  );
};

export default ProjectsMobileControls;
