import React, { ReactElement, useState } from "react";
import {
  Button,
  Label,
  Input,
  ButtonLoadingIcon,
  Checkbox,
} from "@songtradr/component-library";
import { useHistory } from "react-router-dom";
import { IVideoToProcess } from "src/interfaces/brand-audit";
import VideoSelect from "src/pages/brand-audit/components/video-select";
import RubbishBinIcon from "src/app/assets/icons/component-icons/bin-icon";
import areFormFieldsValid from "src/pages/brand-audit/components/brand-audit-form/utils";
import brandListStyles from "../brand-audit-list/styles";
import styles from "./styles";
import { IProps, TGenericAuditFormShape, TVideosToProcess } from "./interfaces";

const BrandAuditForm = ({
  handleFormSubmit,
  defaultFormData = {},
  defaultVideosToProcess = {},
  backBtnLink,
  isEditing = false,
}: IProps): ReactElement => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<TGenericAuditFormShape>(
    defaultFormData
  );
  const [isDirtyFormData, setIsDirtyFormData] = useState<
    TGenericAuditFormShape<boolean>
  >();
  const [videosToProcess, setVideosToProcess] = useState<TVideosToProcess>(
    defaultVideosToProcess
  );
  const isFormDataValid = areFormFieldsValid(formData, videosToProcess);

  const handleInternalFormSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();

    // Ensure all fields are filled in
    if (!isFormDataValid.allValid) {
      return;
    }

    setIsLoading(true);

    try {
      await handleFormSubmit(formData, videosToProcess);
    } catch (error) {
      // Reset the loading state so the user can try again
      setIsLoading(false);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });

    setIsDirtyFormData({
      ...isDirtyFormData,
      [e.target.id]: true,
    });
  };

  const handleAddUrls = (urlsToAdd: string[]) => {
    const newVideosToProcess = urlsToAdd.reduce(
      (acc: TVideosToProcess, url) => {
        // eslint-disable-next-line no-param-reassign
        acc[url] = {
          url,
          skipLalal: false,
        };

        return acc;
      },
      {}
    );

    setVideosToProcess({ ...videosToProcess, ...newVideosToProcess });
  };

  const handleRemoveUrl = (urlToRemove: string) => {
    const newVideosToProcess = { ...videosToProcess };
    delete newVideosToProcess[urlToRemove];
    setVideosToProcess(newVideosToProcess);
  };

  const handleToggleProperty = (
    urlToToggle: string,
    propertyToToggle: keyof IVideoToProcess
  ) => {
    setVideosToProcess({
      ...videosToProcess,
      [urlToToggle]: {
        ...videosToProcess[urlToToggle],
        [propertyToToggle]: !videosToProcess[urlToToggle][propertyToToggle],
      },
    });
  };

  return (
    <div css={styles.pageContainer}>
      <h1 css={brandListStyles.secondaryHeader}>
        {isEditing ? "Edit" : "New"} Brand Audit
      </h1>
      <form onSubmit={handleInternalFormSubmit}>
        <div css={styles.mainContentContainer}>
          <div css={styles.leftColumn}>
            <h2 css={styles.subHeading}>Details</h2>
            <div>
              <Label htmlFor="name">Audit name</Label>
              <Input
                id="name"
                value={formData.name ?? ""}
                onChange={handleInputChange}
                placeholder="Eg. Competition Review"
                hasError={!isFormDataValid.name && isDirtyFormData?.name}
                data-testid="audit-name-input"
              />
            </div>
            <div css={styles.marginTop2}>
              <Label htmlFor="brandName">Brand name</Label>
              <Input
                id="brandName"
                value={formData.brandName ?? ""}
                onChange={handleInputChange}
                placeholder="Eg. Nike"
                hasError={
                  !isFormDataValid.brandName && isDirtyFormData?.brandName
                }
                data-testid="brand-name-input"
              />
            </div>
          </div>
          <div css={styles.rightColumn}>
            <h2 css={styles.subHeading}>Add URLS</h2>
            <div data-testid="video-select-container">
              <VideoSelect
                handleSubmitVideoUrls={(videoUrls) => handleAddUrls(videoUrls)}
              />
            </div>
            <div>
              <h2 css={[styles.subHeading, styles.marginTop4]}>
                Selected URLS
              </h2>
              <div data-testid="selected-urls-table">
                <div css={styles.flexTableRow}>
                  <div
                    css={[
                      styles.flexTableCall,
                      styles.tableCellHeading,
                      styles.tableCellUrl,
                    ]}
                  >
                    URL
                  </div>
                  <div
                    css={[
                      styles.flexTableCall,
                      styles.tableCellHeading,
                      styles.tableCellCheckbox,
                    ]}
                  >
                    Skip Lalal?
                  </div>
                  <div css={styles.flexTableCall} />
                </div>
                {Object.values(videosToProcess).map((video, index) => (
                  <div
                    css={styles.flexTableRow}
                    key={video.url}
                    data-testid={`selected-urls-table-row-${index}`}
                  >
                    <div css={[styles.flexTableCall, styles.tableCellUrl]}>
                      <a href={video.url} target="_blank" rel="noreferrer">
                        {video.url}
                      </a>
                    </div>
                    <div css={[styles.flexTableCall, styles.tableCellCheckbox]}>
                      <Checkbox
                        data-testid="toggle-lalal-box"
                        variant="primary"
                        checked={video.skipLalal}
                        onClick={() =>
                          handleToggleProperty(video.url, "skipLalal")
                        }
                      />
                    </div>
                    <div css={styles.flexTableCall}>
                      <Button
                        data-testid="remove-url-btn"
                        variant="link"
                        onClick={() => handleRemoveUrl(video.url)}
                      >
                        <RubbishBinIcon />
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div css={styles.footerContainer}>
          <Button
            onClick={() => history.push(backBtnLink ?? "/admin/brand-audit")}
            variant="link"
            data-testid="back-btn"
            css={styles.backBtn}
          >
            Back
          </Button>
          <Button
            variant="primary"
            type="submit"
            data-testid="submit-btn"
            disabled={isLoading || !isFormDataValid.allValid}
          >
            {isLoading && <ButtonLoadingIcon />}{" "}
            {isEditing ? "Submit changes" : "Create audit"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BrandAuditForm;
