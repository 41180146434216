export type IOption = {
  value: string;
  label: string;
};
export enum SelectOption {
  SelectNew = "select-option",
  RemoveValue = "remove-value",
}

export default interface ICustomSelectAction {
  action: SelectOption;
  option?: IOption;
  removedValue?: IOption;
}
