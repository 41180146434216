import { eApplicationType } from "src/interfaces/auth";

const BlockedEmailProviders: string | string[] = [];

interface ILookup {
  code: string;
  displayText: string;
}

interface ISubscription {
  name: string;
  path: string;
}

enum InviteStatus {
  Accepted = "Accepted",
  Active = "Active",
}

enum CommercialPills {
  AllProjects = "All Projects",
  ProjectInsights = "Project Insights",
}

enum ProjectFilterStatus {
  All = "All",
  Active = "Active",
  SoonToExpire = "Soon to expire",
  Expired = "Expired",
  InPerpetuity = "In perpetuity",
  Undefined = "Undefined",
}

type ValueOf<T> = T[keyof T];

function getEnumKeyByEnumValue<
  R extends string | number,
  T extends { [key: string]: R }
>(myEnum: T, enumValue: ValueOf<T>): string {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys.length > 0 ? keys[0] : "";
}

enum ProjectFilterIconStatus {
  Active = "Active",
  ExpireIn7Days = "Expires within 7 days",
  ExpireIn30Days = "Expires within 30 days",
  Expired = "Expired",
  InPerpetuity = "In perpetuity",
  Undefined = "Undefined",
}

enum DaysDuration {
  Expired = 0,
  Seven = 7,
  Thirty = 30,
}

const Currencies: { [id: string]: ILookup } = {};
Currencies.GBP = { code: "GBP", displayText: "£ (GBP)" };
Currencies.USD = { code: "USD", displayText: "$ (USD)" };
Currencies.SGD = { code: "SGD", displayText: "$ (SGD)" };
Currencies.EUR = { code: "EUR", displayText: "€ (EUR)" };
Currencies.JPY = { code: "JPY", displayText: "¥ (JPY)" };
Currencies.CNY = { code: "CNY", displayText: "¥ (CNY)" };
Currencies.THB = { code: "THB", displayText: "฿ (THB)" };
Currencies.HKD = { code: "HKD", displayText: "$ (HKD)" };
Currencies.PHP = { code: "PHP", displayText: "₱ (PHP)" };
Currencies.ZAR = { code: "ZAR", displayText: "R (ZAR)" };
Currencies.IDR = { code: "IDR", displayText: "Rp (IDR)" };
Currencies.AUD = { code: "AUD", displayText: "$ (AUD)" };
Currencies.BRL = { code: "BRL", displayText: "R$ (BRL)" };
Currencies.TRY = { code: "TRY", displayText: "₺ (TRY)" };
Currencies.MMK = { code: "MMK", displayText: "K (MMK)" };
Currencies.ARS = { code: "ARS", displayText: "$ (ARS)" };
Currencies.PLN = { code: "PLN", displayText: "zł (PLN)" };
Currencies.MXN = { code: "MXN", displayText: "$ (MXN)" };
Currencies.CAD = { code: "CAD", displayText: "$ (CAD)" };
Currencies.SEK = { code: "SEK", displayText: "kr (SEK)" };
Currencies.AED = { code: "AED", displayText: "د.إ (AED)" };

const ProjectsPageSize = 20;
const LibraryTrackPageSize = 20;

const lorealProfessionalOrgId = "6319cb6dc80bc8cf4fa3e25f";

const MusicTypes: { [id: string]: ILookup } = {};

MusicTypes.commercialMusic = {
  code: "CommercialMusic",
  displayText: "Commercial Music",
};

MusicTypes.originalComposition = {
  code: "OriginalComposition",
  displayText: "Original Composition",
};

MusicTypes.productionMusic = {
  code: "ProductionMusic",
  displayText: "Production Music",
};

MusicTypes.bespokeReRecordedRemix = {
  code: "BespokeReRecordRemix",
  displayText: "Bespoke re-record / Remix",
};

enum TermTypes {
  Term = "Term",
}

enum TermStatus {
  ACTIVE = "ACTIVE",
  RENEW = "RENEW",
  URGENT = "URGENT",
  EXPIRED = "EXPIRED",
  PERPETUITY = "PERPETUITY",
}

enum Exclusivity {
  None = "None",
  Full = "Full",
}

enum MediaTypes {
  Radio = "Radio",
  Events = "Events",
  TikTok = "TikTok",
}

enum LicenseFixedDurations {
  ThreeMonths = "3 months",
  SixMonths = "6 months",
  OneYear = "1 year",
  EighteenMonths = "18 months",
  TwoYears = "2 years",
  ThreeYears = "3 years",
  FourYears = "4 years",
  FiveYears = "5 years",
  Archival = "Archival",
  InPerpetuity = "In perpetuity",
}

const ISODateParseFormat = "YYYY-MM-DD";

const DateFormatMMMDDYYYY = "MMM dd yyyy";

const Subscriptions: { [id: string]: ISubscription } = {};
Subscriptions.Projects = {
  name: "Commercial Licenses",
  path: "/commerciallicenses",
};
Subscriptions.ProjectsReport = {
  name: "Library Licenses",
  path: "/librarylicenses",
};
Subscriptions.Library = { name: "Library", path: "/library" };
Subscriptions.ReadOnlyLibrary = {
  name: "Library (listen-only)",
  path: "/library",
};
Subscriptions.Insights = { name: "Insights", path: "/musicinsights" };
Subscriptions.Team = { name: "Manage Team", path: "/team" };
Subscriptions.Home = { name: "Home", path: "/home" };
Subscriptions.Profile = { name: "Profile", path: "/profile" };
Subscriptions.MyContent = { name: "Resources", path: "/assets" };
Subscriptions.Validate = { name: "Measurement", path: "/measurement" };

export {
  ProjectFilterStatus,
  ProjectFilterIconStatus,
  DaysDuration,
  lorealProfessionalOrgId,
  BlockedEmailProviders,
  Currencies,
  ProjectsPageSize,
  LibraryTrackPageSize,
  MusicTypes,
  Exclusivity,
  TermTypes,
  TermStatus,
  LicenseFixedDurations,
  MediaTypes,
  CommercialPills,
  ISODateParseFormat,
  InviteStatus,
  Subscriptions,
  DateFormatMMMDDYYYY,
  getEnumKeyByEnumValue,
};

export const allowedApplications = [
  eApplicationType.TeamManagement,
  eApplicationType.LibraryFull,
  eApplicationType.LibraryReadOnly,
  eApplicationType.ProjectsClient,
  eApplicationType.Insights,
  eApplicationType.Resources,
  eApplicationType.Validate,
];
