import React, { FunctionComponent } from "react";
import ReactDOM from "react-dom";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localizedFormat from "dayjs/plugin/localizedFormat";
import minMax from "dayjs/plugin/minMax";
import dayjs from "dayjs";
import { initDataDog } from "src/utils/data-dog";
import withBreadcrumbs from "src/providers/breadcrumbs/with-breadcrumbs";
import { BrowserRouter } from "react-router-dom";
import withInternationalization from "./i18n";
import withOrganisation from "./providers/organisation/with-organisation";
import App from "./app";
import withAuthentication from "./auth/with-authentication";

initDataDog();

// needed for parsing dates with dayjs in safari
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(minMax);

const AuthenticatedApp = withOrganisation(
  withAuthentication(
    withInternationalization(withBreadcrumbs(App as FunctionComponent))
  )
);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthenticatedApp />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
