import { css } from "@emotion/react";

export default {
  contactsContainer: css`
    display: flex;
    flex-direction: column;

    div.ant-row:not(:last-of-type) {
      margin-bottom: 40px;
    }
  `,
  contentRow: css`
    display: block;
  `,
  additionalContactContainer: css`
    margin-bottom: 40px;
  `,
};
