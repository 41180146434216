import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import deleteOrganisationInvite from "src/api/organisation-invites/delete-org-invite";
import getOrganisationInvites from "src/api/organisation-invites/get-org-invites";
import useAuth from "src/auth/use-auth";
import { IConfirmContentProps } from "src/components/interfaces";
import { ModalTypes } from "src/components/modals/base-modal";
import { errorToast, successToast } from "src/components/toast-notification";
import axios from "axios/index";
import OrganisationInvitesTable from "..";
import resendOrganisationInvite from "../../../../../api/organisation-invites/resend-org-invite";
import { IMemberInvite } from "../../../interfaces";
import DeleteTeamInviteModalMainContent from "../components/invite-members/delete-team-invite";

interface IProps {
  invitesLastUpdatedAt: Date;
  orgInvitesData: IMemberInvite[] | [];
  setOrgInvitesData: React.Dispatch<React.SetStateAction<IMemberInvite[] | []>>;
  searchText: string;
}

const OrganisationInvitesContainer = ({
  invitesLastUpdatedAt,
  orgInvitesData,
  setOrgInvitesData,
  searchText,
}: IProps): ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openModalType, setOpenModalType] = useState<ModalTypes>(
    ModalTypes.deleteOrganisationInvite
  );
  const [selectedInvite, setSelectedInvite] = useState<IMemberInvite>();
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
  const [filteredInvitesData, setFilteredInvitesData] = useState<
    IMemberInvite[] | []
  >([]);
  const abortController = useRef<AbortController>(new AbortController());

  const { t } = useTranslation();
  const { getAccessToken, organisationId } = useAuth();

  useEffect(() => () => abortController.current.abort(), []);

  const filterWithSearchText = (invites: IMemberInvite[]): IMemberInvite[] => {
    const searchTextTokens = searchText
      .split(" ")
      .filter((token) => token.trim().length > 0)
      .map((token) => token.toLowerCase());

    return invites.filter((invite) =>
      searchTextTokens.every(
        (token) =>
          (invite.email?.toLowerCase().includes(token) ?? false) ||
          (invite.firstName?.toLowerCase().includes(token) ?? false) ||
          (invite.lastName?.toLowerCase().includes(token) ?? false)
      )
    );
  };

  useEffect(() => {
    if (searchText) {
      setFilteredInvitesData(filterWithSearchText(orgInvitesData));
    } else {
      setFilteredInvitesData(orgInvitesData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, orgInvitesData]);

  const getData = async (): Promise<void> => {
    const accessToken = getAccessToken();

    if (!organisationId) {
      return;
    }

    try {
      setIsLoadingRequest(true);
      const response: IMemberInvite[] = await getOrganisationInvites(
        accessToken,
        organisationId,
        searchText,
        abortController.current
      );

      const filteredData = searchText
        ? filterWithSearchText(response)
        : response;

      setOrgInvitesData(response);
      setFilteredInvitesData(filteredData);
      setIsLoadingRequest(false);
    } catch (e) {
      if (!axios.isCancel(e)) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  const handleResendInvite = async (inviteId: string) => {
    const accessToken = getAccessToken();

    try {
      if (organisationId) {
        await resendOrganisationInvite(accessToken, {
          organisationId,
          organisationInviteId: inviteId,
        });
        await getData();
        successToast({
          message: t("manageTeam##invites##Invite resent successfully"),
        });
      }
    } catch {
      errorToast({
        message: t(
          "manageTeam##invites##An error occured resending the invite."
        ),
      });
    }
  };

  const handleDeleteInvite = (inviteId: string) => {
    setIsModalOpen(true);
    setOpenModalType(ModalTypes.deleteOrganisationInvite);

    const orgInvite = orgInvitesData.find((invite) => {
      return invite.id === inviteId;
    });

    setSelectedInvite(orgInvite);
  };

  const handleOnCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDeleteInviteClick = async () => {
    if (selectedInvite) {
      setIsModalOpen(false);
      const accessToken: string = await getAccessToken();
      try {
        if (organisationId) {
          await deleteOrganisationInvite(
            organisationId,
            selectedInvite.id,
            accessToken
          );

          const orgInvite = orgInvitesData.find((invite) => {
            return invite.id === selectedInvite.id;
          });

          setSelectedInvite(orgInvite);
        }

        await getData();
        successToast({
          message: t("manageTeam##invites##Organisation invite deleted"),
        });
      } catch {
        errorToast({
          message: t(
            "manageTeam##invites##An error occurred deleting this invitation"
          ),
        });
      }
    }
  };

  const orgMemberInvite = selectedInvite ? `${selectedInvite.email}` : "";

  const confirmDeleteContentProps: IConfirmContentProps = {
    confirmAction: handleConfirmDeleteInviteClick,
    onClose: () => setIsModalOpen(false),
    primaryButtonLabel: "manageTeam##invites##Delete invite",
    secondaryButtonLabel: "Cancel",
    mainContent: (
      <DeleteTeamInviteModalMainContent inviteEmail={orgMemberInvite} />
    ),
  };

  return (
    <OrganisationInvitesTable
      data-testid="team-invites-table"
      orgInvites={filteredInvitesData}
      getData={getData}
      handleResendInvite={handleResendInvite}
      handleDeleteInvite={handleDeleteInvite}
      handleConfirmDeleteInviteClick={handleConfirmDeleteInviteClick}
      invitesLastUpdatedAt={invitesLastUpdatedAt}
      isModalOpen={isModalOpen}
      openModalType={openModalType}
      confirmDeleteContentProps={confirmDeleteContentProps}
      handleOnCloseModal={handleOnCloseModal}
      isLoading={isLoadingRequest}
      searchText={searchText}
    />
  );
};

export default OrganisationInvitesContainer;
