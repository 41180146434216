import React, { ReactElement } from "react";
import { Checkbox, Form } from "antd";
import { eApplicationType } from "src/interfaces/auth";
import { IProps } from "./interfaces";

const colSpan = { span: 24 };

const ModuleCheckboxes = ({ org }: IProps): ReactElement | null => {
  return (
    <>
      <Form.Item
        name="modules.projectsInternal"
        labelCol={colSpan}
        wrapperCol={colSpan}
        valuePropName="checked"
        initialValue={org?.applications.includes(
          eApplicationType.ProjectsInternal
        )}
      >
        <Checkbox aria-label="Projects (internal)">
          Projects (internal)
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="modules.projectsClient"
        labelCol={colSpan}
        wrapperCol={colSpan}
        valuePropName="checked"
        initialValue={org?.applications.includes(
          eApplicationType.ProjectsClient
        )}
      >
        <Checkbox aria-label="Projects (client)">Projects (client)</Checkbox>
      </Form.Item>
      <Form.Item
        name="modules.libraryFull"
        labelCol={colSpan}
        wrapperCol={colSpan}
        valuePropName="checked"
        initialValue={org?.applications.includes(eApplicationType.LibraryFull)}
      >
        <Checkbox aria-label="Library">Library</Checkbox>
      </Form.Item>
      <Form.Item
        name="modules.libraryReadOnly"
        labelCol={colSpan}
        wrapperCol={colSpan}
        valuePropName="checked"
        initialValue={org?.applications.includes(
          eApplicationType.LibraryReadOnly
        )}
      >
        <Checkbox aria-label="Library (listen only)">
          Library (listen only)
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="modules.validate"
        labelCol={colSpan}
        wrapperCol={colSpan}
        valuePropName="checked"
        initialValue={org?.applications.includes(eApplicationType.Validate)}
      >
        <Checkbox aria-label="Validate">Validate</Checkbox>
      </Form.Item>
      <Form.Item
        name="modules.resources"
        labelCol={colSpan}
        wrapperCol={colSpan}
        valuePropName="checked"
        initialValue={org?.applications.includes(eApplicationType.Resources)}
      >
        <Checkbox aria-label="Resources">Resources</Checkbox>
      </Form.Item>
      <Form.Item
        name="modules.portal"
        labelCol={colSpan}
        wrapperCol={colSpan}
        valuePropName="checked"
        initialValue={org?.applications.includes(eApplicationType.Portal)}
      >
        <Checkbox aria-label="Portal">Portal</Checkbox>
      </Form.Item>
    </>
  );
};

export default ModuleCheckboxes;
