import axios from "axios";
import config from "src/config";
import IProps from "src/pages/org-dashboard/components/product-table/interfaces";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

const getProductInformation = async (
  orgId: string,
  accessToken: string,
  abortController: AbortController
): Promise<IProps> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    signal: abortController.signal,
    ...getAxiosOptions(),
  };
  const url = `${config.projectManagementService.url}/organisations/${orgId}/product-information`;

  const { data } = await axios.get<IProps>(url, options);
  return data;
};

export default getProductInformation;
