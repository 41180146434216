import { Typography } from "antd";
import React, { ReactElement } from "react";
import appStyles from "src/app/styles";
import { BackBtn } from "../back-button";
import styles from "./styles";
import { IProps } from "./interfaces";

const PageContainer = ({
  title,
  Content,
  showBackBtn,
  useDarkBg = false,
}: IProps): ReactElement => {
  return (
    <div
      css={[styles.baseBackground, useDarkBg ? styles.darkBackground : null]}
    >
      <div css={appStyles.container}>
        <div css={styles.headerContainer}>
          {showBackBtn && (
            <div css={styles.headerBackBtn}>
              <BackBtn />
            </div>
          )}
          {title && (
            <Typography.Title css={styles.heading}>{title}</Typography.Title>
          )}
        </div>
        {Content}
      </div>
    </div>
  );
};

export default PageContainer;
