import React, { ReactElement } from "react";

const Expired = (): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#F12121" />
      <path
        d="M10.7001 6C11.2775 5 12.7209 5 13.2982 6L18.4944 15C19.0717 16 18.35 17.25 17.1953 17.25H6.80302C5.64832 17.25 4.92663 16 5.50398 15L10.7001 6Z"
        fill="white"
      />
      <rect
        x="11.25"
        y="8.25"
        width="1.5"
        height="4.5"
        rx="0.75"
        fill="#F12121"
      />
      <rect
        x="11.25"
        y="13.5"
        width="1.5"
        height="1.5"
        rx="0.75"
        fill="#F12121"
      />
    </svg>
  );
};

export default Expired;
