import { IUserPermissions } from "../access-token-utils";

const defaultUserPermissions: IUserPermissions = {
  canAccessPortal: false,
  canAccessInsights: false,
  canAccessMyContent: false,
  canAccessValidate: false,
  canAccessTeams: false,
  canAccessProjects: false,
  canAccessMusicSubscription: false,
};

export default defaultUserPermissions;
