import { SerializedStyles, css } from "@emotion/react";
import theme from "src/theme";

export default {
  initialsImage(size: number, fontSize: number): SerializedStyles {
    return css`
      background: ${theme.colors.functional.brightOrange};
      border: 1px solid ${theme.colors.white};
      border-radius: ${size / 2}px;
      width: ${size}px !important;
      min-width: ${size}px;
      height: ${size}px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: ${fontSize}px;
      font-family: ${theme.fonts.sofiaPro};
      font-weight: 700;
      color: ${theme.colors.white};
      box-shadow: 0 8px 32px 0 rgba(255, 255, 255, 0.15);
    `;
  },
  image(size: number): SerializedStyles {
    return css`
      width: ${size}px !important;
      height: ${size}px;
      min-width: ${size}px;
      border-radius: ${size / 2}px;
    `;
  },
};
