import React, { ReactElement } from "react";
import { Button } from "@songtradr/component-library";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./styles";

const ValidateTabHeader = (): ReactElement => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div css={styles.validateHeaderContainer}>
      <div css={styles.headerContainer}>
        <div css={styles.textContainer}>
          <h1 css={styles.headerText}>
            <span>Maximize the impact </span> of your music with our
            groundbreaking research solution
          </h1>
          <p>
            Make sure you are getting the biggest return on your investment by
            choosing the RIGHT music & sound for your advertising. Test with
            real audiences, in context.
          </p>
          <Button
            variant="primary"
            className="createButton"
            onClick={() => history.push("/measurement/new-test")}
          >
            {t("Validate##Create a new test")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ValidateTabHeader;
