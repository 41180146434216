import { ReactElement, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import useAuth from "src/auth/use-auth";
import { Button, ScopedTheme } from "@songtradr/component-library";
import appStyles from "src/app/styles";
import CopyrightFooter from "src/components/copyright-footer";
import style from "./styles";

const NotFound = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const { isSessionValid } = useAuth();

  useEffect(() => {
    // Make sure that the flag isAuthenticated is set
    void isSessionValid();
  }, [isSessionValid]);

  const handleButtonClick = useCallback(() => {
    history.push("/");
  }, [history]);

  return (
    <ScopedTheme mode="dark" css={style.background}>
      <div css={[appStyles.container, style.container]} />
      <Typography.Title css={style.backgroundHeading}>
        {t("Not Found##BackgroundHeading")}
      </Typography.Title>
      <div css={style.headingAndTextContainer}>
        <Typography.Title css={style.heading}>
          {t("Not Found##Heading")}
        </Typography.Title>
        <Typography.Text css={style.text}>
          {t("Not Found##Text")}
        </Typography.Text>
        <Button
          data-testid="go-home-button"
          variant="primary"
          css={style.goHomeBtn}
          onClick={handleButtonClick}
        >
          {t("Not Found##Button")}
        </Button>
      </div>
      <div css={[appStyles.container, style.copyrightFooter]}>
        <CopyrightFooter />
      </div>
    </ScopedTheme>
  );
};

export default NotFound;
