import { css, SerializedStyles } from "@emotion/react";
import theme from "src/theme";

export default {
  passwordCriteria(hasError: boolean): SerializedStyles {
    return css`
      margin-top: ${hasError ? "28px" : "52px"};
      padding: 1rem;
      height: 157px;
      font-size: 0.75rem;
      border-radius: 8px;
      background-color: ${theme.colors.secondary.bigSky};
      line-height: 0.9375rem;
      font-family: ${theme.fonts.sofiaPro};

      ul {
        list-style: none;
        padding-left: 0;
        padding-top: 0.5rem;
        li {
          font-size: 0.875rem;
          padding-bottom: 0.5rem;
        }
      }
    `;
  },
  check: css`
    color: ${theme.colors.functional.successGreen};
  `,
  info: css`
    color: ${theme.colors.primary.eiffelBlue};
  `,
  validPassword: css`
    border-radius: 8px;
    padding: 1.5rem;
    margin-top: 52px;
    background-color: ${theme.colors.functional.successGreenLight};
    font-size: 0.875rem;
    font-family: ${theme.fonts.sofiaPro};
  `,
};
