import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: css`
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
  `,
  modal: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    h2 {
      color: ${theme.colors.white};
      font-size: 2rem;
      font-weight: 250;
      line-height: 2.5rem;
      letter-spacing: -0.02em;
      margin-bottom: 1rem;
    }
  `,
  multiSelect: css`
    position: fixed;
    bottom: 0;
    left: 35%;
    border-radius: 8px;
    border: 1px solid ${theme.colors.secondary.mediumGrey};
    background: ${theme.colors.background.landingDarkGray};
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.5);
    width: 555px;
    height: 72px;
    margin: 0 auto;
    span {
      padding: 24px;
      font-size: 16px;
      font-weight: 700;
      vertical-align: top;
      line-height: 72px;
    }
    .approve-button,
    .deny-button {
      transition-property: none;
      background: ${theme.colors.functional.approveGreen};
      border-radius: 8px;
      border: none;
      color: ${theme.colors.white};
      align-content: center;
      padding: 12px 24px 12px 16px;
      margin: 8px;
      &:hover {
        cursor: pointer;
        background: ${theme.colors.functional.approveGreen} !important;
      }
    }
    .approve-button:hover {
      transition-property: none;
      &:hover {
        background: ${theme.colors.functional.approveGreenDark} !important;
        rect {
          fill: ${theme.colors.functional.approveGreenDark} !important;
        }
      }
    }

    .deny-button {
      background: ${theme.colors.functional.denyRed};
      &:hover {
        transition-property: none;
        cursor: pointer;
        background: ${theme.colors.functional.denyRedDark} !important;
        rect {
          fill: ${theme.colors.functional.denyRedDark};
        }
      }
    }
    .approve-icon {
      height: 32px;
      width: 32px;
      margin-right: 8px;
      rect {
        fill: ${theme.colors.functional.approveGreen};
      }
      path {
        fill: ${theme.colors.white};
      }
      &:hover {
        cursor: pointer;
        rect {
          fill: ${theme.colors.functional.approveGreenDark};
        }
        path {
          fill: ${theme.colors.white};
        }
      }
    }
    .deny-icon {
      height: 32px;
      width: 32px;
      cursor: pointer;
      rect {
        fill: ${theme.colors.functional.denyRed};
      }
      path {
        fill: ${theme.colors.white};
      }
      &:hover {
        cursor: pointer;
        rect {
          fill: ${theme.colors.functional.denyRedDark};
        }
        path {
          fill: ${theme.colors.white};
        }
      }
    }
    .close-icon {
      cursor: pointer;
      margin-left: 8px;
      margin-bottom: 8px;
    }
  `,
  resendContainer: css`
    display: inline-block;
    float: right;
    margin-right: 55px;
  `,
};
