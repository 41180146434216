import axios from "axios";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";
import { projectsWhitelistUrl } from "../../organisation-members-url-helper";

const updateProjectsWhitelist = (
  accessToken: string,
  organisationId: string,
  userId: string,
  useProjectWhitelist: boolean,
  projectIds: string[]
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.put(
    projectsWhitelistUrl(organisationId, userId),
    {
      useProjectWhitelist,
      projectIds,
    },
    options
  );
};

export default updateProjectsWhitelist;
