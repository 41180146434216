import { IAuthenticatedUser } from "@songtradr/spa-common";
import { useEffect } from "react";

declare const StonlyWidget: any;

interface IProps {
  userDetails?: IAuthenticatedUser | null;
  orgName?: string;
  orgId?: string;
}

const doesStonlyExist = () =>
  typeof StonlyWidget !== "undefined" && StonlyWidget;

const Stonly = ({ userDetails, orgName, orgId }: IProps): null => {
  useEffect(() => {
    if (!doesStonlyExist() || !userDetails) {
      return;
    }

    const args = {
      userId: userDetails.id,
      orgid: orgId,
      orgname: orgName,
    };

    StonlyWidget("identify", userDetails.id, args);
  }, [userDetails, orgName, orgId]);

  return null;
};

export default Stonly;
