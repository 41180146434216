import produce from "immer";
import { IAvailableReport, IReport } from "src/api/power-bi";
import { PowerBiAction, PowerBiActions } from "./types";

export const powerBiReducer = produce(
  (
    draft: {
      availableReports: IAvailableReport[] | null;
      selectedReport: IReport | null;
    },
    action: PowerBiAction
  ) => {
    switch (action.type) {
      case PowerBiActions.AVAILABLE_REPORTS:
        draft.availableReports = action.value;
        return draft;
      case PowerBiActions.SELECTED_REPORT:
        draft.selectedReport = action.value;
        return draft;
      default:
        throw new Error();
    }
  }
);

export const InitialPowerBiReportsState = {
  availableReports: null,
  selectedReport: null,
};
