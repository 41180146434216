import React from "react";
import { TFunction } from "react-i18next";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import style from "../styles";

export default (
  isTablet: boolean,
  isMobile: boolean,
  t: TFunction<string>
): Array<IColumnsData> => {
  if (isMobile) {
    return [
      {
        title: "",
        dataIndex: "email",
        key: "key",
        width: "100%",
      },
    ];
  }
  if (isTablet) {
    return [
      {
        title: "",
        dataIndex: "email",
        key: "key",
        width: "55%",
      },
      {
        title: <div />,
        dataIndex: "status",
        key: "key",
        width: "20%",
        className: "team-invite-align-left",
      },
      {
        title: <div />,
        dataIndex: "resend",
        key: "key",
        width: "15%",
        className: "team-invite-align-left",
      },
      {
        title: <div />,
        dataIndex: "delete",
        key: "key",
        width: "10%",
        className: "team-invite-align-left",
      },
    ];
  }

  return [
    {
      title: (
        <div css={style.titleContainer}>
          <span>{t("manageTeam##members##Name")}</span>
        </div>
      ),
      dataIndex: "email",
      key: "key",
      width: "45%",
    },
    {
      title: (
        <div css={style.sentDateTitleContainer}>
          <span>{t("manageTeam##members##DateSent")}</span>
        </div>
      ),
      dataIndex: "sentDate",
      key: "key",
      width: "20%",
      className: "team-invite-align-left",
    },
    {
      title: (
        <div css={[style.titleContainer, style.invitesColumnHeader]}>
          <span>{t("manageTeam##invites##Status")}</span>
        </div>
      ),
      dataIndex: "status",
      key: "key",
      width: "20%",
      className: "team-invite-align-left",
    },
    {
      title: <div css={style.invitesColumnHeader} />,
      dataIndex: "resend",
      key: "key",
      width: "5%",
      className: "team-invite-align-right",
    },
    {
      title: <div css={style.invitesColumnHeader} />,
      dataIndex: "delete",
      key: "key",
      width: "5%",
      className: "team-invite-align-center",
    },
  ];
};
