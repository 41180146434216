import { format, parseISO } from "date-fns";
import { IProjectLibraryTrackDetails } from "../interfaces";
import { ChannelType } from "./get-data-source";

interface ILibraryTrackExportDataSource {
  trackId: string;
  trackTitle: string;
  trackArtist: string;
  downloadedBy: string;
  declaredBy: string;
  createdDate: string;
  projectName: string;
  projectDescription: string;
  businessBrand: string;
  territories: string;
  goLiveDate: string;
  youtubeChannels: string;
  facebookPages: string;
  instagramPages: string;
  tikTokAccounts: string;
  twitterAccounts: string;
  companyWebsite: string;
  other: string;
  videoLinks: string;
}

export default (
  libraryTracks: IProjectLibraryTrackDetails[]
): ILibraryTrackExportDataSource[] => {
  return libraryTracks.map(
    ({
      trackId,
      trackTitle,
      trackArtist,
      downloadedByUser,
      declaredByUser,
      createdDate,
      projectName,
      projectDescription,
      businessBrand,
      regions,
      channels,
      goLiveDate,
    }) => {
      const channelsByType: {
        [key: string]: string[];
      } = {
        youtube: [],
        facebook: [],
        instagram: [],
        twitter: [],
        tikTok: [],
        companyWebsite: [],
        other: [],
        videoLinks: (channels ?? [])?.[0]?.usageLinks ?? [],
      };

      channels.forEach((channel) => {
        switch (channel.type) {
          case ChannelType.Youtube:
            channelsByType.youtube.push(channel.name);
            break;
          case ChannelType.Facebook:
            channelsByType.facebook.push(channel.name);
            break;
          case ChannelType.Instagram:
            channelsByType.instagram.push(channel.name);
            break;
          case ChannelType.Twitter:
            channelsByType.twitter.push(channel.name);
            break;
          case ChannelType.TikTok:
            channelsByType.tikTok.push(channel.name);
            break;
          case ChannelType.CompanyWebsite:
            channelsByType.companyWebsite.push(channel.id);
            break;
          case ChannelType.Other:
            channelsByType.other.push(channel.id);
            break;
          default:
            break;
        }
      });
      const territories = (regions ?? []).join(", ");
      const youtubeChannels = channelsByType.youtube.join(", ");
      const facebookPages = channelsByType.facebook.join(", ");
      const instagramAccounts = channelsByType.instagram.join(", ");
      const twitterAccounts = channelsByType.twitter.join(", ");
      const tikTokAccounts = channelsByType.tikTok.join(", ");
      const companyWebsite = channelsByType.companyWebsite.join(", ");
      const otherChannels = channelsByType.other.join(", ");
      const videoLinks = channelsByType.videoLinks.join(", ");

      return {
        trackId,
        trackTitle: trackTitle ?? "",
        trackArtist: trackArtist ?? "",
        downloadedBy: `${downloadedByUser.name ?? ""} (${
          downloadedByUser.email ?? ""
        })`,
        declaredBy: `${declaredByUser.name ?? ""} (${
          declaredByUser.email ?? ""
        })`,
        createdDate:
          createdDate && format(parseISO(createdDate), "MMM do, yyyy"),
        projectName: projectName ?? "",
        projectDescription: projectDescription ?? "",
        businessBrand: businessBrand ?? "",
        territories,
        goLiveDate: goLiveDate && format(parseISO(goLiveDate), "MMM do, yyyy"),
        youtubeChannels,
        facebookPages,
        instagramPages: instagramAccounts,
        tikTokAccounts,
        twitterAccounts,
        companyWebsite,
        other: otherChannels,
        videoLinks,
      };
    }
  );
};
