import React, { Fragment } from "react";
import { Button, DeleteIcon } from "@songtradr/component-library";
import { Upload } from "antd";
import UploadIcon from "src/app/assets/icons/component-icons/upload-icon";
import theme from "src/theme";
import { FileUpload } from "../../interfaces";
import styles from "../../styles";

const { Dragger } = Upload;

const mbToByteConversions = {
  "5": 5242880,
  "10": 10485760,
};

const imageUploadSteps = (
  handleImgUpload: (
    { file, filename }: FileUpload,
    maxFileSizeInMb: keyof typeof mbToByteConversions
  ) => void,
  userProfileImage: string,
  user: any,
  handleImgDeletion: (value: string) => void
) => {
  const renderImg = () => {
    if (userProfileImage) {
      return (
        <div>
          <img src={userProfileImage} alt="" />
          <div css={styles.mobileDeleteBtn}>
            <button
              css={styles.drawerButtonContainer}
              onClick={() => handleImgDeletion("profileImage")}
              data-testid="back-button"
              data-heapid="Back"
              type="button"
            >
              <DeleteIcon fill={theme.colors.white} />
            </button>
          </div>
        </div>
      );
    }

    return <div css={styles.profileCircle}> {user?.name?.charAt(0)} </div>;
  };

  return [
    {
      content: (
        <Fragment>
          <h1>Upload an image</h1>
          {userProfileImage === "" ? (
            <div css={styles.drawerImageUploadContainer}>
              <Dragger
                className="parent"
                customRequest={(file) => handleImgUpload(file, "10")}
                showUploadList={false}
                name="profileImage"
                data-testid="upload-image"
              >
                <UploadIcon title="upload-icon" />
                Select an image to upload
                <br />
                or
                <Button variant="important">Browse files</Button>
              </Dragger>
            </div>
          ) : (
            <div css={styles.uploadImageViewer}>{renderImg()}</div>
          )}
        </Fragment>
      ),
    },
  ];
};

export default imageUploadSteps;
