import React, { ReactElement } from "react";

const MeasurementIcon = (
  props: React.SVGProps<SVGSVGElement>
): ReactElement => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M10.8856 24H0.462288C0.206709 24 0 23.7953 0 23.5423V15.9266C0 15.6736 0.207369 15.4689 0.462288 15.4689H2.17936C2.43494 15.4689 2.64165 15.6742 2.64165 15.9266V20.9261C2.64165 21.1792 2.84835 21.3839 3.10393 21.3839H10.8856C11.1411 21.3839 11.3478 21.5885 11.3478 21.8416V23.5416C11.3478 23.7947 11.1411 23.9993 10.8856 23.9993V24Z" />
      <path d="M3.10491 6.86259C2.84933 6.86259 2.64262 7.06725 2.64262 7.3203V9.94233C2.64262 10.1954 2.43591 10.4 2.18033 10.4H0.463264C0.207685 10.4 0.000976562 10.1954 0.000976562 9.94233V4.70481C0.000976562 4.45176 0.207685 4.2471 0.463264 4.2471H19.6528C19.8351 4.2471 19.983 4.39357 19.983 4.57403V23.5422C19.983 23.7953 19.7763 23.9999 19.5207 23.9999H15.5622C15.3067 23.9999 15.1 23.7953 15.1 23.5422V21.8422C15.1 21.5891 15.3067 21.3844 15.5622 21.3844H16.8791C17.1347 21.3844 17.3414 21.1798 17.3414 20.9267V7.18952C17.3414 7.00906 17.1935 6.86259 17.0112 6.86259H3.10491Z" />
      <path d="M15.4282 6.86239H4.55384C4.37156 6.86239 4.22363 6.71592 4.22363 6.53545V0.326936C4.22363 0.146467 4.37156 0 4.55384 0H15.4282C15.6104 0 15.7584 0.146467 15.7584 0.326936V6.53545C15.7584 6.71592 15.6104 6.86239 15.4282 6.86239ZM7.19548 4.2469H12.7865C12.9688 4.2469 13.1167 4.10043 13.1167 3.91996V2.94243C13.1167 2.76196 12.9688 2.61549 12.7865 2.61549H7.19548C7.01321 2.61549 6.86528 2.76196 6.86528 2.94243V3.91996C6.86528 4.10043 7.01321 4.2469 7.19548 4.2469Z" />
      <path d="M8.02847 18.2784L4.84463 15.1261C4.71585 14.9986 4.71585 14.7913 4.84463 14.6638L6.24536 13.2769C6.37414 13.1494 6.58349 13.1494 6.71227 13.2769L8.02847 14.5801C8.15725 14.7076 8.3666 14.7076 8.49538 14.5801L13.2715 9.85131C13.4003 9.7238 13.6096 9.7238 13.7384 9.85131L15.1391 11.2382C15.2679 11.3657 15.2679 11.573 15.1391 11.7005L8.49538 18.2784C8.3666 18.4059 8.15725 18.4059 8.02847 18.2784Z" />
    </svg>
  );
};

export default MeasurementIcon;
