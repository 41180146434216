import { ReactElement } from "react";
import theme from "src/theme";
import IIconComponentProps from "../../interfaces";

const ModalSuccessIcon = ({
  fill = theme.colors.functional.successGreen,
  height = "66",
  width = "66",
  title,
}: IIconComponentProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.2391 44.734C30.2695 45.7644 31.9419 45.7644 32.9723 44.734L59.9512 17.7551C60.848 16.8583 60.9811 15.4455 60.2629 14.3974C54.0978 5.38518 43.907 0 33.0001 0C14.8018 0 0 14.8017 0 33C0 51.1983 14.8018 66 33.0001 66C51.1983 66 66 51.199 66 33.0007C66 31.5431 64.818 30.3605 63.3603 30.3605C61.9025 30.3605 60.7201 31.5428 60.7201 33.0007C60.7201 48.2836 48.2827 60.721 32.9997 60.721C17.7167 60.721 5.27929 48.2836 5.27929 33.0007C5.27929 17.7177 17.7167 5.28033 32.9997 5.28033C41.4343 5.28033 49.3594 9.11219 54.6053 15.6324L31.1046 39.1331L22.7213 30.7498C21.6909 29.7195 20.0185 29.7195 18.9881 30.7498C17.9578 31.7802 17.9577 33.4526 18.9881 34.483L29.2391 44.734Z"
        fill={fill}
      />
    </svg>
  );
};

export default ModalSuccessIcon;
