import React, { ReactElement } from "react";
import { IComponentIconProps } from "../interfaces";

const SortDESC = ({ fill = "white" }: IComponentIconProps): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.11508 9.14874L7.14818 8.11565C7.30162 7.9622 7.55118 7.9622 7.70463 8.11565L11.7218 12.1328C11.8752 12.2862 12.1248 12.2862 12.2782 12.1328L16.2954 8.11508C16.4488 7.96164 16.6984 7.96164 16.8518 8.11508L17.8849 9.14818C18.0384 9.30162 18.0384 9.55118 17.8849 9.70463L12.2782 15.3113C12.1248 15.4648 11.8752 15.4648 11.7218 15.3113L6.11508 9.70463C5.96164 9.55118 5.96164 9.30162 6.11508 9.14818L6.11508 9.14874Z"
        fill={fill}
      />
    </svg>
  );
};

export default SortDESC;
