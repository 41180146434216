import {
  formFields,
  TGenericAuditFormShape,
  TVideosToProcess,
} from "src/pages/brand-audit/components/brand-audit-form/interfaces";

type TFormValidation = {
  [key in typeof formFields[number]]?: boolean;
};

export interface IFormValidation extends TFormValidation {
  videosToProcess: boolean;
  allValid: boolean;
}

const areFormFieldsValid = (
  formData: TGenericAuditFormShape,
  videosToProcess: TVideosToProcess
) => {
  const validation = {
    allValid: !!Object.keys(videosToProcess).length,
    videosToProcess: !!Object.keys(videosToProcess).length,
    isDirty: {},
  };

  return formFields.reduce((acc: IFormValidation, curr) => {
    // eslint-disable-next-line no-param-reassign
    acc[curr] = !(formData[curr] === undefined || !formData[curr]);

    if (!acc[curr]) {
      // eslint-disable-next-line no-param-reassign
      acc.allValid = false;
    }

    return acc;
  }, validation);
};

export default areFormFieldsValid;
