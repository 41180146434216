import React, {
  ReactElement,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Col, Row, Typography, Table } from "antd";
import { getI18n, useTranslation } from "react-i18next";
import {
  IFee,
  IFinalTrack,
  IProjectTerm,
  IProjectTrack,
} from "src/pages/projects/client-project-view/interfaces";
import useOrgSubscription from "src/providers/organisation/hooks";
import { DownOutlined } from "@ant-design/icons";
import countries from "i18n-iso-countries";
import { CurrencyCode } from "src/pages/org-dashboard/components/details-section/interfaces";
import startCase from "lodash/startCase";
import { ConditionallyVisible } from "src/sumo/src/components/visibility";
import { TermTypes } from "src/constants";
import projectsMainStyles from "src/pages/projects/styles";
import mainStyles from "../../styles";
import styles from "./styles";
import CollapsiblePanels, { ICollapsiblePanel } from "../collapsible-panels";
import TermBadge from "./components/term-badge";
import TermProgress from "./components/term-progress";
import { formatPriceWithCurrency } from "../services/utils";

const maxTerritoriesBeforeFold = 10;
interface IProps {
  terms?: IProjectTerm[];
  sequenceId: number;
  projectName: string;
  projectLeads?: IProjectLead[];
  clientName: string;
  projectTrackMasterFees?: IFee[];
  projectTrackPublisherFees?: IFee[];
  projectLicenseFees?: IFee[];
  projectTracks?: IProjectTrack[];
}

interface IProjectLead {
  id: string;
  name: string;
  email?: string;
}

enum Organisation {
  BigSyncOrg = "62726e0b9957a241f422c5a3",
}

enum NoteType {
  MediaTypeNote = "Media type note",
  TerritoriesNote = "Territories note",
  ScriptNote = "Script note",
  Notes = "Notes",
}

const Licensing = forwardRef(
  (
    {
      terms,
      sequenceId,
      projectName,
      projectLeads,
      clientName,
      projectTrackMasterFees,
      projectTrackPublisherFees,
      projectLicenseFees,
      projectTracks,
    }: IProps,
    ref: React.Ref<HTMLDivElement>
  ): ReactElement => {
    const { t } = useTranslation();
    const { language } = getI18n();
    const { organisation } = useOrgSubscription();
    const [projectLeadsList, setProjectLeadsList] = useState("");
    const [isTerritoriesCollapsed, setIsTerritoriesCollapsed] = useState(true);

    const subject = encodeURIComponent(
      `I need help with this project (ID: ${sequenceId})`
    );

    const isBigSyncOrg = useMemo(() => {
      // show master, publish and service fee only for big sync org
      return organisation?.id === Organisation.BigSyncOrg;
    }, [organisation?.id]);

    useEffect(() => {
      const leadsList: any[] = [];
      if (projectLeads !== undefined) {
        projectLeads.forEach((item) => {
          leadsList.push(item.email);
        });
        setProjectLeadsList(leadsList.join(", "));
      }
    }, [projectLeads]);

    const getFormattedFee = useCallback(
      (feesObject: { value: number; currency: string }) => {
        return formatPriceWithCurrency(feesObject.value, feesObject.currency);
      },
      []
    );

    const getNoteField = useCallback(
      (note: string, labelName: string) => {
        return (
          note && (
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24}>
                <div css={styles.noteContainer}>
                  <div>{t(`ClientProjectViewPage##${labelName}`)}</div>
                  <div>{note}</div>
                </div>
              </Col>
            </Row>
          )
        );
      },
      [t]
    );

    const getFees = useCallback((array: IFee[]) => {
      let fees: IFee = { value: 0, currency: CurrencyCode.USD };
      let total = 0;
      (array ?? []).forEach((el) => {
        total += el.value;
        fees = { value: total, currency: el.currency } ?? {
          value: 0,
          currency: CurrencyCode.USD,
        };
      });
      return fees;
    }, []);

    const body = encodeURIComponent(`
    Project name: ${projectName}
    Client: ${clientName}
    Project Leads: ${projectLeadsList}
    ID: ${sequenceId}
  `);

    const formatMediaTypeList = (mediaTypes: string[]) => {
      const list = mediaTypes.map((type, index) => {
        let value = startCase(type);
        if (mediaTypes[index + 1]) {
          value += ", ";
        }
        return value;
      });
      return list;
    };

    const formatFinalTracks = (tracks: IFinalTrack[]) => {
      let selectTracks = "";
      if (tracks.length > 0) {
        selectTracks = tracks
          .map((track) => {
            return track.title;
          })
          .join(", ");
      }
      return selectTracks;
    };

    const panels: ICollapsiblePanel[] = terms
      .filter((term) => term.type === TermTypes.Term)
      .map((term) => {
        const selectedCountryCodes = term.territories.worldwide
          ? ["Worldwide"]
          : term.territories.includedCountries;
        const noDuration =
          !term.fixedDuration && !term.startDate && !term.endDate;
        const mediaTypes = (term.mediaType ?? []).concat(
          term.customMediaType ?? []
        );

        // in future get master and publisher fee for specific track from BE, for now leave this solution.
        const finalTracks = (term.finalTracks ?? []).map((el) => el.id);
        const allTracks = (projectTracks ?? []).map((el) => el.id);
        const intersection = allTracks.filter((element) =>
          finalTracks.includes(element)
        );
        const findTrack = (projectTracks ?? []).filter((el) =>
          intersection.includes(el.id)
        );
        const masterFeeValueOfAppliedTrack = findTrack.map(
          (el) => el.masterFee ?? { value: 0, currency: CurrencyCode.USD }
        );
        const formatedMasterFeeForAllTracks = getFees(
          masterFeeValueOfAppliedTrack ?? []
        );
        const formattedMasterFeeWithSpecificTrack = getFormattedFee(
          formatedMasterFeeForAllTracks
        );
        const publisherFeeValueOfAppliedTrack = (findTrack ?? []).map(
          (el) => el.publisherFee ?? { value: 0, currency: CurrencyCode.USD }
        );
        const formatedAplliedToAllTrackPublisherFees = getFees(
          publisherFeeValueOfAppliedTrack
        );
        const formattedPublisherFeeWithSpecificTrack = getFormattedFee(
          formatedAplliedToAllTrackPublisherFees
        );
        const masterFeeObject = getFees(projectTrackMasterFees ?? []);
        const formattedMasterFee = getFormattedFee(masterFeeObject);
        const publisherFeeObject = getFees(projectTrackPublisherFees ?? []);
        const formattedPublisherFee = getFormattedFee(publisherFeeObject);
        const serviceFeeObject = getFees(projectLicenseFees ?? []);
        const formattedServiceFee = getFormattedFee(serviceFeeObject);
        const mediaTypeNote = term.mediaTypeNote ?? "";
        const territoriesNote = term.territoriesNote ?? "";
        const scriptNotes = term.scriptNotes ?? "";

        const scriptColumns = [
          {
            title: "Script name",
            dataIndex: "scriptName",
            key: "scriptName",
          },
          {
            title: "Script details",
            dataIndex: "scriptDetails",
            key: "scriptDetails",
          },
          {
            title: "Duration",
            dataIndex: "duration",
            key: "duration",
          },
          {
            title: "Includes cutdowns",
            dataIndex: "includesCutdowns",
            key: "includesCutdowns",
          },
        ];

        const finalTrackFeeColumns = [
          {
            title: "Master total fee",
            dataIndex: "masterTotalFee",
            key: "masterTotalFee",
          },
          {
            title: "Publisher total fee",
            dataIndex: "publisherTotalFee",
            key: "publisherTotalFee",
          },
          {
            title: "Service total fee",
            dataIndex: "serviceTotalFee",
            key: "serviceTotalFee",
          },
        ];

        const scriptsTableDataSource = (term.scripts ?? [])?.map(
          (script, index) => {
            return {
              key: `${term.id}-${script.name || ""}-${index}`,
              scriptName: script.name || "-",
              scriptDetails: script.details || "-",
              duration: script.duration || "-",
              includesCutdowns: script.includesCutdowns ? "Yes" : "No",
            };
          }
        );

        const finalTracksFeesTableDataSource = term.appliesToAllFinalTracks
          ? [
              {
                key: `${term.id}-fees-table-row`,
                masterTotalFee: formattedMasterFee,
                publisherTotalFee: formattedPublisherFee,
                serviceTotalFee: formattedServiceFee,
              },
            ]
          : [
              {
                key: `${term.id}-fees-table-row`,
                masterTotalFee: formattedMasterFeeWithSpecificTrack,
                publisherTotalFee: formattedPublisherFeeWithSpecificTrack,
                serviceTotalFee: formattedServiceFee,
              },
            ];

        const areFinalTracksDetailsVisible =
          term?.finalTracks?.length > 0 || term.appliesToAllFinalTracks;

        return {
          id: term.id,
          header: (
            <div css={styles.panelHeader}>
              <div>
                <div css={styles.headerTitle}>{term.name}</div>
                <div css={styles.headerSubtitle}>
                  {term.fixedDuration
                    ? term.fixedDuration
                    : t("ClientProjectViewPage##Custom duration")}
                </div>
              </div>
              <ConditionallyVisible condition={!noDuration}>
                <TermBadge term={term} />
              </ConditionallyVisible>
            </div>
          ),
          expandedContent: (
            <div css={styles.panelBody}>
              <ConditionallyVisible condition={!noDuration}>
                <TermProgress term={term} />
              </ConditionallyVisible>
              <Row gutter={[16, 16]}>
                {term.notes && (
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <div css={mainStyles.tableTitle}>
                      {t("ClientProjectViewPage##Term details")}
                    </div>
                    <div css={mainStyles.sectionMainText}>{term.notes}</div>
                  </Col>
                )}
              </Row>
              <Row gutter={[48, 48]}>
                {mediaTypes.length > 0 && (
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Row css={styles.noBottomMargin}>
                      <div css={mainStyles.tableTitle}>
                        {t("ClientProjectViewPage##Media type")}
                      </div>
                    </Row>
                    <Row>
                      <div
                        css={[mainStyles.sectionMainText, styles.minHeight80px]}
                      >
                        {formatMediaTypeList(mediaTypes)}
                      </div>
                    </Row>
                    <Row>{getNoteField(mediaTypeNote, NoteType.Notes)}</Row>
                  </Col>
                )}
                {selectedCountryCodes.length > 0 && (
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Row css={styles.noBottomMargin}>
                      <div css={mainStyles.tableTitle}>
                        {t("ClientProjectViewPage##Territories")}
                      </div>
                    </Row>
                    <Row>
                      <div
                        css={[mainStyles.sectionMainText, styles.minHeight80px]}
                      >
                        <ul css={styles.territoryList}>
                          {selectedCountryCodes.map(
                            (code, index, territories) => {
                              if (
                                index === maxTerritoriesBeforeFold &&
                                isTerritoriesCollapsed
                              ) {
                                return (
                                  <Col
                                    xs={24}
                                    sm={24}
                                    onClick={() =>
                                      setIsTerritoriesCollapsed(false)
                                    }
                                    key="show-more"
                                  >
                                    <Typography css={styles.showMoreText}>
                                      {t(
                                        "ProjectsPage##licensingSection##Show more territories"
                                      )}{" "}
                                      (
                                      {territories.length -
                                        maxTerritoriesBeforeFold}
                                      ) <DownOutlined />
                                    </Typography>
                                  </Col>
                                );
                              }
                              if (
                                index > maxTerritoriesBeforeFold &&
                                isTerritoriesCollapsed
                              ) {
                                return false;
                              }
                              return (
                                <li key={code} css={styles.territory}>
                                  {code === "Worldwide" ||
                                  !countries.getName(code, language)
                                    ? code
                                    : countries.getName(code, language)}
                                  {index + 1 < territories.length ? ", " : ""}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    </Row>
                    <Row>{getNoteField(territoriesNote, NoteType.Notes)}</Row>
                  </Col>
                )}
                {areFinalTracksDetailsVisible && (
                  <Col xs={24} sm={24} md={8} lg={8}>
                    <Row css={styles.noBottomMargin}>
                      <div css={mainStyles.tableTitle}>
                        {t("ClientProjectViewPage##Final tracks")}
                      </div>
                    </Row>
                    <Row>
                      {term.appliesToAllFinalTracks ? (
                        <div css={mainStyles.sectionMainText}>
                          {t("ClientProjectViewPage##appliesToAllFinalTracks")}
                        </div>
                      ) : (
                        <div css={mainStyles.sectionMainText}>
                          {formatFinalTracks(term.finalTracks)}
                        </div>
                      )}
                    </Row>
                  </Col>
                )}
              </Row>
              {areFinalTracksDetailsVisible && isBigSyncOrg && (
                <Row gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={24}>
                        <Table
                          css={projectsMainStyles.getTableStyles(false)}
                          columns={finalTrackFeeColumns}
                          dataSource={finalTracksFeesTableDataSource}
                          locale={{
                            emptyText: "No data available",
                          }}
                          pagination={false}
                          scroll={{ x: true }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24}>
                  <div css={mainStyles.tableTitle}>
                    {t("ClientProjectViewPage##Film/Scripts")}
                  </div>
                  <Table
                    css={projectsMainStyles.getTableStyles(false)}
                    columns={scriptColumns}
                    dataSource={scriptsTableDataSource}
                    locale={{
                      emptyText: "No data available",
                    }}
                    pagination={false}
                    scroll={{ x: true }}
                  />
                </Col>
              </Row>
              {getNoteField(scriptNotes, NoteType.Notes)}
            </div>
          ),
        };
      });

    return (
      <div css={mainStyles.sectionContainer} id="licensing-terms" ref={ref}>
        <CollapsiblePanels
          mainTitle="Licensing terms"
          subtitle="All tracks, links or assets provided for your project"
          panels={panels}
        />
        <div css={styles.getInTouch}>
          {" "}
          {t("ClientProjectViewPage##Extend Usage")}
          <a
            href={`mailto:${organisation?.servicingOrganisation?.email}?subject=${subject}&body=${body}`}
          >
            {t("ClientProjectViewPage##Get in touch")}
          </a>
          , {t("ClientProjectViewPage##we would be happy to help with that.")}
        </div>
      </div>
    );
  }
);

export default Licensing;
