import { css } from "styled-components";
import theme from "src/theme";
import { ISelectProps } from "../ts";

export default ({ hasError = false }: ISelectProps) => css`
  .ant-select {
    ::-webkit-scrollbar {
      width: 0.5rem;
      border-radius: 4px;
      margin-right: 2px;
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 4px ${theme.colors.background};
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.colors.lightGrey};
      outline: 1px solid ${theme.colors.background};
      width: 4px;
      border-radius: 4px;
    }
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    .ant-select-selector {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      padding: 12px 17px !important;
      border: 1px solid
        ${hasError
          ? theme.colors.functional.alertRed
          : `${theme.colors.background.veryDarkGray} !important`};
      box-sizing: border-box;
      border-radius: 4px !important;
      height: auto !important;
      transition: ease all 0.3s;
    }
    .ant-select-selection-overflow {
      align-self: baseline;
    }
    .ant-select-selection-overflow-item {
      margin: 4px 0;
    }
    .ant-select-selection-search-input {
      margin-top: 0.75rem !important;
      margin-left: 0.5rem !important;
    }
    .ant-select-arrow {
      span {
        display: none;
      }
      ::after {
        content: url('data:image/svg+xml,<svg width="14" height="10" viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg"><line x1="9.50586" y1="8.2645" x2="16.5769" y2="1.19343" stroke="black" stroke-width="2" stroke-linecap="round" /> <line x1="2.3732" y1="1.21094" x2="9.44427" y2="8.28201" stroke="black" stroke-width="2" stroke-linecap="round" /></svg>');
        margin-right: 2px;
      }
    }
    &.ant-select-focused {
      .ant-select-selector {
        border: 1px solid
          ${hasError
            ? theme.colors.functional.alertRed
            : theme.colors.background.veryDarkGray} !important;
        outline: none !important;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      }
    }
    .ant-tag {
      background-color: ${theme.colors.background.greyRoom};
      padding: 8px 16px;
      border-radius: 8px;
      color: ${theme.colors.secondary.blueOnBlack};
      font-size: 14px;
      line-height: 20px;
      height: 36px;
    }
    .ant-tag-close-icon {
      padding-left: 8px;
      g {
        stroke: ${theme.colors.secondary.blueOnBlack};
      }
    }
    .ant-tag.invalid-tag {
      background-color: ${theme.colors.functional.alertRedLight};
      color: ${theme.colors.functional.alertRed};
      .ant-tag-close-icon g {
        stroke: ${theme.colors.functional.alertRed};
      }
    }
  }
  .input-label {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 8px;
    color: ${theme.colors.secondary.blueOnBlack};
  }
  .helper-text {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 8px;
    color: ${theme.colors.secondary.blueOnBlackShadow};
  }
`;
