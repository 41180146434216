import { ReactElement } from "react";
import IIconComponentProps from "src/app/assets/interfaces";

const StatusSoonToExpire = ({
  fill = "#F88600",
}: IIconComponentProps): ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill={fill} />
      <path
        d="M14.2675 8C15.0373 6.66667 16.9618 6.66667 17.7316 8L24.6598 20C25.4296 21.3333 24.4673 23 22.9277 23H9.07134C7.53174 23 6.56949 21.3333 7.33929 20L14.2675 8Z"
        fill="white"
      />
      <rect x="15" y="18" width="2" height="2" rx="1" fill={fill} />
      <rect x="15" y="11" width="2" height="6" rx="1" fill={fill} />
    </svg>
  );
};

export default StatusSoonToExpire;
