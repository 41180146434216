import { useWindowSize } from "@songtradr/spa-common";
import React, { Fragment, ReactElement, useEffect } from "react";
import { IConfirmContentProps } from "src/components/interfaces";
import { ModalTypes } from "src/components/modals/base-modal";
import { eApplicationType } from "src/interfaces/auth";
import { IOption } from "src/pages/team/table-data/org-member-data";
import { TablePaginationConfig } from "antd";
import OrgMembersTable from "..";
import {
  IOrganisationMemberProps,
  OrgMembersSortType,
} from "../../../interfaces";

interface IProps {
  searchText: string;

  confirmDeleteContentProps: IConfirmContentProps;
  projects: IOption[];
  paginationData: TablePaginationConfig;
  isDeleteMemberModalOpen: boolean;
  isLoadingRequest: boolean;
  allApplications: eApplicationType[];
  currentSortType: OrgMembersSortType;
  nameSortAscending: boolean;
  dateSort: boolean;
  filteredAndSortedOrgMembersData: IOrganisationMemberProps[] | [];
  openModalType: ModalTypes;
  setCurrentSort: React.Dispatch<React.SetStateAction<OrgMembersSortType>>;
  setSelectedOrgMember: React.Dispatch<
    React.SetStateAction<IOrganisationMemberProps | undefined>
  >;
  setNameSort: React.Dispatch<React.SetStateAction<boolean>>;
  setDateSort: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleteMemberModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  applySortAndSearchFilter: (members?: IOrganisationMemberProps[]) => void;

  setCurrentTab: (value: string) => void;
  handleToggleUseProjectsWhitelist: (
    selectedOrgMember: IOrganisationMemberProps,
    useProjectsWhitelist: boolean
  ) => void;
  handleUpdateProjectsWhitelist: (
    selectedOrgMember: IOrganisationMemberProps,
    projects: string[]
  ) => void;
  handleAddApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>;
  handleRemoveApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>;
  handleAddAndRemoveApplication: (
    member: IOrganisationMemberProps,
    addApplication: eApplicationType,
    removeApplication: eApplicationType
  ) => Promise<void>;
  setPaginationData: (values: TablePaginationConfig) => void;
}

const OrganisationMemberContainer = ({
  confirmDeleteContentProps,
  filteredAndSortedOrgMembersData,
  searchText,
  isLoadingRequest,
  allApplications,
  currentSortType,
  openModalType,
  isDeleteMemberModalOpen,
  nameSortAscending,
  dateSort,
  projects,
  setSelectedOrgMember,
  setIsDeleteMemberModalOpen,
  applySortAndSearchFilter,
  setCurrentSort,
  setNameSort,
  setDateSort,
  setCurrentTab,
  handleToggleUseProjectsWhitelist,
  handleUpdateProjectsWhitelist,
  handleAddApplication,
  handleRemoveApplication,
  handleAddAndRemoveApplication,
  paginationData,
  setPaginationData,
}: IProps): ReactElement => {
  useWindowSize();

  const handleNameSort = () => {
    setCurrentSort(OrgMembersSortType.Name);
    setNameSort((state) => !state);
  };

  const handleDateSort = () => {
    setCurrentSort(OrgMembersSortType.CreatedDate);
    setDateSort((state) => !state);
  };

  useEffect(() => {
    applySortAndSearchFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameSortAscending, searchText]);

  return (
    <Fragment>
      <OrgMembersTable
        setCurrentTab={setCurrentTab}
        setSelectedOrgMember={setSelectedOrgMember}
        orgMembers={filteredAndSortedOrgMembersData}
        handleNameSort={handleNameSort}
        handleDateSort={handleDateSort}
        nameSortAscending={nameSortAscending}
        dateSort={dateSort}
        currentSortType={currentSortType}
        isDeleteMemberModalOpen={isDeleteMemberModalOpen}
        setIsDeleteMemberModalOpen={setIsDeleteMemberModalOpen}
        openModalType={openModalType}
        confirmDeleteContentProps={confirmDeleteContentProps}
        isLoading={isLoadingRequest}
        projects={projects}
        handleToggleUseProjectsWhitelist={handleToggleUseProjectsWhitelist}
        handleUpdateProjectsWhitelist={handleUpdateProjectsWhitelist}
        handleAddApplication={handleAddApplication}
        handleRemoveApplication={handleRemoveApplication}
        handleAddAndRemoveApplication={handleAddAndRemoveApplication}
        paginationData={paginationData}
        setPaginationData={setPaginationData}
        allApplications={allApplications}
      />
    </Fragment>
  );
};

export default OrganisationMemberContainer;
