import { IVideoToProcess } from "src/interfaces/brand-audit";

// Add additional form fields here, they'll automatically be type safe
// and have "required" validation applied to them
export const formFields = ["name", "brandName"] as const;

export type TGenericAuditFormShape<T = string> = {
  [key in typeof formFields[number]]?: T;
};

export interface IYtChannelSelectOption {
  label: string;
  value: string;
  thumbnail: string;
}

export type TVideosToProcess = Record<string, IVideoToProcess>;

export interface IProps {
  handleFormSubmit: (
    formData: TGenericAuditFormShape,
    videosToProcess: TVideosToProcess
  ) => Promise<void>;
  defaultFormData?: any;
  defaultVideosToProcess?: any;
  isEditing?: boolean;
  backBtnLink?: string;
}
