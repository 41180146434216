import axios from "axios";
import { buildOrganisationSubscriptionsBaseUrl } from "src/api/base-url-helper";
import { IOrganisation } from "../../../interfaces/organisation";
import { getJsonContentHeader, getAuthHeader } from "../../headers";
import setOrgDefaults from "../get-organisation/transformer";

export interface IGetOrgsResponse {
  items: IOrganisation[];
  length: number;
  limit: number;
  start: number;
  total: number;
}

const getAllOrganisations = async (
  accessToken: string,
  params = {
    start: 1,
    perPage: 20,
    organisationName: "",
  },
  abortController: AbortController
): Promise<IGetOrgsResponse | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    signal: abortController.signal,
  };

  const { data } = await axios.post<IGetOrgsResponse>(
    `${buildOrganisationSubscriptionsBaseUrl()}/_search`,
    params,
    options
  );

  data.items = data.items.map((org) => setOrgDefaults(org));

  return data;
};

export default getAllOrganisations;
