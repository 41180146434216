import React, { ReactElement } from "react";

const DropDownArrowDown = (): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.11508 9.16012L7.14818 8.11679C7.30162 7.96183 7.55118 7.96183 7.70463 8.11679L11.7218 12.1737C11.8752 12.3287 12.1248 12.3287 12.2782 12.1737L16.2954 8.11623C16.4488 7.96126 16.6984 7.96126 16.8518 8.11623L17.8849 9.15956C18.0384 9.31452 18.0384 9.56656 17.8849 9.72152L12.2782 15.3838C12.1248 15.5387 11.8752 15.5387 11.7218 15.3838L6.11508 9.72152C5.96164 9.56656 5.96164 9.31452 6.11508 9.15955L6.11508 9.16012Z"
        fill="white"
      />
    </svg>
  );
};

export default DropDownArrowDown;
