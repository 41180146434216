import searchProjects from "src/api/projects/search-projects";
import { IService } from "src/pages/projects/client-project-view/interfaces";
import {
  ProjectSortType,
  SortOrder,
} from "src/pages/projects/components/project-listings/interfaces";
import { IProjectAttachment } from "src/pages/projects/interfaces";

export interface IValidationAttachment extends IProjectAttachment {
  file_url?: string;
}

export interface IValidationResult {
  id: string;
  campaign_name: string;
  date: string;
  file_url?: string;
  attachments?: IValidationAttachment[];
}

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getValidationResults = async (
  accessToken: string,
  organisationId: string,
  abortController: AbortController
): Promise<IValidationResult[] | null> => {
  let projects;

  try {
    projects = await searchProjects(
      accessToken,
      organisationId,
      {
        by: ProjectSortType.lastUpdated,
        order: SortOrder.DESC,
      },
      0,
      {
        signal: abortController.signal,
      }
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(["There was an issue fetching projects", e]);
    return Promise.reject(e);
  }

  if (projects === null) {
    return Promise.reject(new Error("No projects were found"));
  }

  return projects.results
    .filter(
      (project) =>
        project.services?.find(
          (service: IService) => service.serviceType === "MusicValidation"
        ) !== undefined
    )
    .map((project) => {
      const testResultAttachments = project.attachments?.filter(
        (attachment) => attachment.documentCategory === "TestResult"
      );

      let date = "-";

      if (project.jobListingDate && project.jobListingDate !== "") {
        const dateObj = new Date(project.jobListingDate);
        date = `${dateObj.getDay()} ${
          monthNames[dateObj.getMonth()]
        } ${dateObj.getFullYear()}`;
      }

      return {
        campaign_name: project.campaignName ?? `Untitled campaign`,
        date,
        attachments: testResultAttachments,
        id: project.id,
      };
    });
};
