import { ReactElement } from "react";

const ShadedLineChart = (): ReactElement => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 268 231"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 117.357L8.93333 79L17.8667 134L26.8 100L35.7333 23.4715L44.6667 29.5L53.6 0L59.7287 70.4145L71.4667 93.886L80.4 46.943H89.3333L98.2667 23.4715L107.2 79L116.133 91L125.067 110.5L134 14.5L142.933 167.34L150.36 70.4145L160.8 93.886L169.733 117.357L178.667 29.5L190.093 110.5L196.533 46.943L205.467 231L214.4 117.357L223.333 70.4145L232.267 93.886L241.2 70.4145L250.133 9L259.067 231L267.481 17L268 231H259.067H250.133H241.2H232.267H223.333H214.4H205.467H196.533H187.6H178.667H169.733H160.8H151.867H142.933H134H125.067H116.133H107.2H98.2667H89.3333H80.4H71.4667H62.5333H53.6H44.6667H35.7333H26.8H17.8667H8.93333H0V117.357Z"
        fill="#6AA5B4"
      />
    </svg>
  );
};
export default ShadedLineChart;
