import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  contentContainer: css`
    .ant-divider {
      color: ${theme.colors.darkCharcoal};
      border-color: ${theme.colors.darkCharcoal};
      margin: 0;
    }
  `,
  waveformContainer: css`
    width: 100%;
  `,
  trackTitle: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${theme.colors.white};

    div:nth-of-type(1) {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }

    div:nth-of-type(2) {
      font-weight: 400;
      color: ${theme.colors.white};
      opacity: 0.8;
    }
  `,
  trackDetails: css`
    margin-top: 48px;
    margin-bottom: 48px;
  `,
  noDataAvailable: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${theme.colors.white};
    text-align: center;
  `,
};
