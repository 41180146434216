import { Input } from "@songtradr/component-library";
import React, { useCallback, useState } from "react";
import EyeWithStrikethrough from "src/app/assets/icons/eye-with-strikethrough";
import EyeWithoutStrikethrough from "src/app/assets/icons/eye-without-strikethrough";
import FloatingLabel from "src/components/floating-label";
import appStyles from "src/app/styles";
import styles from "./styles";

interface IPasswordInputWithFloatingLabelProps {
  name: string;
  password: string;
  hasError: boolean;
  onPasswordChange: (password: string) => void;
}

const MAX_PASSWORD_LENGTH = 80;

const PasswordInputWithFloatingLabel = ({
  name,
  password,
  hasError,
  onPasswordChange,
}: IPasswordInputWithFloatingLabelProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const [readOnly, setReadOnly] = useState(true);

  const handlePasswordValidation = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPassword = e.target.value;
      onPasswordChange(newPassword);
    },
    [onPasswordChange]
  );

  return (
    <FloatingLabel label="Password" value={password}>
      <Input
        required
        data-testid="password-input"
        name={name}
        maxLength={MAX_PASSWORD_LENGTH}
        hasError={hasError}
        onChange={handlePasswordValidation}
        type={showPassword ? "text" : "password"}
        css={[
          appStyles.inputAutofillOverride,
          styles.inputField,
          styles.inputPassword,
        ]}
        readOnly={readOnly}
        onFocus={() => setReadOnly(false)}
        icon={
          <button
            onClick={() => setShowPassword(!showPassword)}
            type="button"
            css={[appStyles.resetBtn, styles.showHidePasswordBtn]}
          >
            {showPassword ? (
              <EyeWithoutStrikethrough />
            ) : (
              <EyeWithStrikethrough />
            )}
          </button>
        }
      />
    </FloatingLabel>
  );
};

export default PasswordInputWithFloatingLabel;
