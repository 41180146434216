import styles from "src/pages/brand-audit/components/brand-audit-list/styles";
import { ICreateAuditFormShape } from "src/pages/brand-audit/components/brand-audit-new/interfaces";

export const AuditState = {
  Pending: {
    label: "Pending",
    styles: styles.statusPillPending,
    shouldRePoll: true,
  },
  InProgress: {
    label: "In Progress...",
    styles: styles.statusPillPending,
    shouldRePoll: true,
  },
  CompleteWithoutErrors: {
    label: "Finished",
    styles: styles.statusPillComplete,
    shouldRePoll: false,
  },
  CompleteWithErrors: {
    label: "Error",
    styles: styles.statusPillError,
    shouldRePoll: false,
  },
} as const;

export const FileState = {
  Pending: {
    label: "Pending",
    variant: "primary",
    progress: 0,
  },
  Youtube_Downloading: {
    label: "YouTube downloading...",
    variant: "primary",
    progress: 16,
  },
  Lalal_Uploading: {
    label: "Uploading to Lalal...",
    variant: "primary",
    progress: 32,
  },
  Lalal_Checking: {
    label: "Lalal is processing...",
    variant: "primary",
    progress: 48,
  },
  Lalal_Downloading: {
    label: "Downloading from Lalal...",
    variant: "primary",
    progress: 64,
  },
  MusicAnalysis_Uploading: {
    label: "Uploading for Music Analysis...",
    variant: "primary",
    progress: 80,
  },
  MusicAnalysis_Checking: {
    label: "Music Analysis is processing...",
    variant: "primary",
    progress: 96,
  },
  Youtube_Downloading_Error: {
    label: "YouTube downloading error",
    variant: "alert",
    progress: 100,
  },
  Lalal_Uploading_Error: {
    label: "Lalal uploading error",
    variant: "alert",
    progress: 100,
  },
  Lalal_Checking_Error: {
    label: "Lalal processing error",
    variant: "alert",
    progress: 100,
  },
  Lalal_Downlading_Error: {
    label: "Error Downloading from Lalal",
    variant: "alert",
    progress: 100,
  },
  MusicAnalysis_UploadingError: {
    label: "Error uploading for Music Analysis",
    variant: "alert",
    progress: 100,
  },
  MusicAnalysis_Checking_Error: {
    label: "Music Analysis processing error",
    variant: "alert",
    progress: 100,
  },
  Done: {
    label: "Finished",
    variant: "success",
    progress: 100,
  },
} as const;

export interface INewBrandAuditJob extends ICreateAuditFormShape {
  files: IVideoToProcess[];
}

export interface IVideoToProcess {
  url: string;
  skipLalal: boolean;
}

export interface IBrandAuditJob {
  id: string;
  name: string;
  brandName: string;
  ownerId: string;
  auditStatus: keyof typeof AuditState;
  auditStatusId: number;
  createdAt: string;
  startedAt: string | null;
  lastUpdatedAt: string;
  completedAt: string;
  userName: string;
  files: IBrandAuditFile[];
}

export interface IBrandAuditFile {
  fileStatus: keyof typeof FileState;
  fileStatusId: number;
  title: string;
  isErrored: boolean;
  isComplete: boolean;
  fileErrorDescription: string | null;
  jobId: IBrandAuditJob["id"];
  id: string;
  url: string;
  taggrams: string;
  rawS3Path: string;
  rawFilename: string;
  lalalUploadId: string | null;
  lalalCheckCount: number;
  lalalDownloadUrl: string | null;
  lalalDownloadFilename: string;
  stemS3Path: string;
  musicAnalysisUploadUrl: null;
  musicAnalysisObjectId: string;
  musicAnalysisCheckCount: number;
  musicAnalysisMetadata: string;
  skipLalal: boolean;
  durationMS: number | null;
}

export interface IYouTubeThumbnails {
  default: {
    url: string;
  };
  high: {
    url: string;
  };
  medium: {
    url: string;
  };
}

export interface IYouTubeChannel {
  snippet: {
    channelId: string | null;
    description: string | null;
    thumbnails: IYouTubeThumbnails;
    title: string | null;
  };
}

export interface IYouTubeVideo {
  Id: {
    VideoId: string;
  };
  Snippet: {
    Description: string | null;
    Title: string | null;
    thumbnails: IYouTubeThumbnails;
    ViewCount: string;
    Duration: string;
  };
}
