import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { getIsMobile, getIsTablet, useWindowSize } from "@songtradr/spa-common";
import { IOption } from "src/components/custom-select/interfaces";
import {
  CommercialPills,
  ProjectFilterStatus,
  ProjectsPageSize,
} from "src/constants";
import useOrganisation from "src/providers/organisation/hooks";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import { IProjectsDataSource } from "src/components/table/interfaces";
import PowerBiReport from "src/components/powerbi-report";
import STLoadingLogo from "src/components/st-loading-logo";
import style from "./styles";
import {
  DrawerTypes,
  IClientOrgProjectProps,
  IProjectTableSort,
} from "./interfaces";
import ProjectsMobileControls from "./mobile-controls";
import CommercialTableActions, { IPillData } from "./commercial-table-actions";
import projectsMainStyles from "../../styles";

export enum ProjectTableTitle {
  Commercial = "Commercial",
}

interface IProps {
  isLoading: boolean;
  projects: Array<IClientOrgProjectProps>;
  handleSort: (params: IProjectTableSort) => void;
  handlePageChange: (page: number) => void;
  sortOptions: IProjectTableSort;
  paginationOptions: { total: number; currentPage: number };
  mobileDrawerVisible: boolean;
  currentActiveDrawer: DrawerTypes;
  tableTitle: string;
  columns: IColumnsData[];
  dataSource: IProjectsDataSource[];
  handleDrawerToggle: () => void;
  onUpdateSearchText?: (value: string) => void;
  onClearSearchText?: () => void;
  searchText?: string;
  onStatusChange: (newOption: IOption) => void;
  selectedStatusOption: IOption;
}

const ProjectListings = ({
  isLoading,
  projects,
  handleSort,
  sortOptions,
  handlePageChange,
  paginationOptions,
  mobileDrawerVisible,
  currentActiveDrawer,
  tableTitle,
  columns,
  dataSource,
  handleDrawerToggle,
  onUpdateSearchText,
  onClearSearchText,
  searchText,
  onStatusChange,
  selectedStatusOption,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const { organisation } = useOrganisation();
  const [paginationRange, setPaginationRange] = useState([
    1,
    paginationOptions.total > 0 && paginationOptions.total < ProjectsPageSize
      ? paginationOptions.total
      : ProjectsPageSize,
  ]);
  const [selectedPill, setSelectedPill] = useState<CommercialPills>(
    CommercialPills.AllProjects
  );
  const history = useHistory();

  useWindowSize();
  const isTablet = getIsTablet();
  const isMobile = getIsMobile();

  useEffect(() => {
    if (paginationOptions.total > 0) {
      const min = (paginationOptions.currentPage - 1) * ProjectsPageSize + 1;
      const max =
        paginationOptions.total <
        ProjectsPageSize * paginationOptions.currentPage
          ? paginationOptions.total
          : ProjectsPageSize * paginationOptions.currentPage;
      setPaginationRange([min, max]);
    }
  }, [paginationOptions.total, paginationOptions.currentPage]);

  const onPageChange = (page: number) => {
    handlePageChange(page);
    if (page === 1) {
      setPaginationRange([
        1,
        paginationOptions.total < ProjectsPageSize
          ? paginationOptions.total
          : ProjectsPageSize,
      ]);
      return;
    }
    const min = (page - 1) * ProjectsPageSize + 1;
    const max =
      min + ProjectsPageSize < paginationOptions.total
        ? min + ProjectsPageSize - 1
        : paginationOptions.total;
    setPaginationRange([min, max]);
  };

  function itemRender(current: number, type: string, element: ReactNode) {
    if (type === "prev") {
      return <ArrowLeftOutlined />;
    }
    if (type === "next") {
      return <ArrowRightOutlined />;
    }
    return element;
  }

  const getPillsData = useCallback(() => {
    if (tableTitle === ProjectTableTitle.Commercial) {
      const numberOfProjects = isLoading ? 0 : paginationOptions.total;
      const pillData: IPillData[] = [
        {
          label: CommercialPills.AllProjects,
          variable: String(numberOfProjects),
        },
      ];
      const projectInsight = !!organisation?.enableLicensingReports && {
        label: CommercialPills.ProjectInsights,
      };
      if (projectInsight) {
        pillData.push(projectInsight);
      }
      return pillData;
    }
    return [];
  }, [
    tableTitle,
    paginationOptions.total,
    organisation?.enableLicensingReports,
    isLoading,
  ]);

  const getProjectOptions = useCallback((): IOption[] => {
    if (tableTitle === ProjectTableTitle.Commercial) {
      return Object.entries(ProjectFilterStatus).map(([value, label]) => {
        return {
          value,
          label,
        };
      });
    }
    return [];
  }, [tableTitle]);

  const handleChangePill = useCallback((newSelectedPill: CommercialPills) => {
    setSelectedPill(newSelectedPill);
  }, []);

  const emptyText = useMemo(
    () =>
      isLoading ? (
        <Fragment />
      ) : (
        t(
          "ProjectsPage##Nothing to see here as a project has yet to be created for your organisation"
        )
      ),
    [isLoading, t]
  );

  return (
    <div data-testid="project-listings">
      <CommercialTableActions
        selectedPill={selectedPill}
        searchText={searchText}
        options={getProjectOptions()}
        pillsData={getPillsData()}
        onChangePill={handleChangePill}
        onClearSearchText={onClearSearchText}
        onUpdateSearchText={(text) => onUpdateSearchText?.(text)}
        onStatusChange={onStatusChange}
        selectedStatusOption={selectedStatusOption}
      />
      <div css={style.tabsContainer}>
        {!!organisation?.enableLicensingReports && (
          <PowerBiReport
            visible={selectedPill === CommercialPills.ProjectInsights}
          />
        )}

        {selectedPill === CommercialPills.AllProjects && (
          <>
            <div css={projectsMainStyles.getTableStyles(true)}>
              <Table
                onRow={(record) => {
                  const project = record as IProjectsDataSource;

                  return {
                    onClick: () => {
                      history.push(
                        `/commerciallicense/${project.projectId ?? ""}`
                      );
                    },
                  };
                }}
                columns={columns}
                dataSource={dataSource}
                loading={{
                  spinning: isLoading,
                  indicator: <STLoadingLogo />,
                }}
                pagination={{
                  pageSize: ProjectsPageSize,
                  total: paginationOptions.total,
                  current: paginationOptions.currentPage,
                  position: ["bottomCenter"],
                  onChange: (page) => onPageChange(page),
                  showSizeChanger: false,
                  showLessItems: true,
                  itemRender,
                }}
                scroll={{ x: true }}
                locale={{ emptyText }}
              />
              {!(projects.length === 0 && !isLoading) && (
                <div
                  css={style.paginationCount}
                >{`Showing ${paginationRange[0]}-${paginationRange[1]} of ${paginationOptions.total} results`}</div>
              )}
            </div>
            {(isMobile || isTablet) && (
              <ProjectsMobileControls
                mobileDrawerVisible={mobileDrawerVisible}
                currentActiveDrawer={currentActiveDrawer}
                handleDrawerToggle={handleDrawerToggle}
                handleSort={handleSort}
                sortOptions={sortOptions}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectListings;
