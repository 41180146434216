import axios from "axios";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";
import { assignMemberUrl } from "../../organisation-members-url-helper";

const assignMember = (
  accessToken: string,
  organisationId: string,
  destinationOrganisationId: string,
  userId: string
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.post(
    assignMemberUrl(organisationId),
    {
      userId,
      destinationOrganisationId,
    },
    options
  );
};

export default assignMember;
