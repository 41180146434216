import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  background: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${theme.colors.background.landingDarkGray};
  `,
};
