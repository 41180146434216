import { css } from "@emotion/react";
import theme from "src/theme";
import { rotate360 } from "src/components/loading-spinner/styles";

export default {
  tabContainer: css`
    display: flex;
    justify-content: center;
  `,
  tableHeaderContainer: css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0;
  `,
  secondaryHeader: css`
    letter-spacing: -0.02em;
    font-weight: 250;
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 0;
    font-size: 2rem;
    line-height: normal;
  `,
  tableBase: css`
    width: 100%;
    text-align: left;
    table-layout: fixed;
    margin-bottom: 3rem;

    tr {
      border-bottom: 1px solid var(--colors-very-dark-gray);
    }

    td,
    th {
      padding: 1.25rem 3rem 1.25rem 0;
    }
  `,
  durationColumn: css`
    width: 7.25rem;
    white-space: nowrap;
  `,
  textEllipses: css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `,
  clickableRow: css`
    cursor: pointer;

    &:hover {
      background: var(--colors-very-dark-gray);
    }
  `,
  statusPill: css`
    border-radius: 40px;
    padding: 6px 16px;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 0.875rem;
    line-height: 1.25rem;
  `,
  statusPillPending: css`
    background: ${theme.colors.functional.infoBlueLight};
    color: ${theme.colors.functional.infoBlue};
  `,
  statusPillComplete: css`
    background: ${theme.colors.functional.successGreenLight};
    color: ${theme.colors.functional.successGreenDark};
  `,
  statusPillError: css`
    background: ${theme.colors.functional.alertRedLight};
    color: ${theme.colors.functional.alertRed};
  `,
  loadingSpinnerContainer: css`
    padding: 4rem;
    text-align: center;
  `,
  loadingSpinner: css`
    stroke: ${theme.colors.white};
    animation: ${rotate360} 1s linear infinite;
    transform-box: fill-box;
    transform-origin: 50% 50%;
  `,
  tab: css`
    appearance: none;
    background: transparent;
    cursor: pointer;
    border: 0;
    padding: 0 0 1rem 0;
    margin: 0 1.5rem;
    font-weight: 200;
    font-size: 1.5rem;
    color: ${theme.colors.secondary.darkGray};
    border-bottom: 2px solid transparent;
    transition: all 0.25s ease-in-out;
  `,
  activeTab: css`
    border-bottom-color: ${theme.colors.functional.brightOrange};
    color: ${theme.colors.white};
  `,
};
