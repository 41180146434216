import { css } from "@emotion/react";
import theme from "src/theme";
import { rotate360 } from "src/components/loading-spinner/styles";

export default {
  headingContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  `,
  heading: css`
    margin: 0;
    color: inherit;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1.5rem;
  `,
  searchInput: css`
    width: 250px !important;
    background: transparent;
    border: none;
    .ant-input-affix-wrapper {
      background-color: transparent;
      border: 0;
      padding: 0;
    }

    .ant-input-prefix {
      svg {
        stroke: ${theme.colors.secondary.textSecondary};

        path {
          fill: ${theme.colors.secondary.textSecondary};
        }
      }
    }

    input.ant-input {
      padding-left: 0.5rem;
      color: ${theme.colors.white};
    }
  `,
  btnReset: css`
    border: 0;
    appearance: none;
    background: transparent;

    &:hover {
      cursor: pointer;
    }
  `,
  pageBtn: css`
    padding: 0.5rem;
    cursor: pointer;
    border-radius: 100%;
    line-height: normal;
    height: 40px;
    width: 40px;
  `,
  currentPageBtn: css`
    background: ${theme.colors.white};
    color: ${theme.colors.black};
    margin: 0.5rem;
  `,
  row: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem;
    color: ${theme.colors.white};
    background: ${theme.colors.background.basaltBlack};
    border-bottom: 1px solid ${theme.colors.background.darkGray};
    cursor: pointer;

    &:hover {
      background: ${theme.colors.background.veryDarkGray};
    }

    &:last-child {
      border-bottom: 0;
    }

    &:nth-of-type(odd):not(:hover) {
      background: ${theme.colors.black};
    }
  `,
  selectedRow: css`
    background: ${theme.colors.background.veryDarkGray} !important;
  `,
  tableContainer: css`
    max-height: 650px;
    overflow-y: auto;
  `,
  paginationContainer: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
  `,
  horizontalFlip: css`
    transform: rotateZ(180deg);
  `,
  modalTitle: css`
    color: ${theme.colors.white} !important;
  `,
  modalContent: css`
    color: ${theme.colors.white};
  `,
  modalHighlightText: css`
    text-decoration: underline;
  `,
  modalInput: css`
    margin-top: 16px;

    #delete-org-input {
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.background.darkGray} !important;
      background: ${theme.colors.background.veryDarkGray} !important;
    }
  `,
  deleteModalButtons: css`
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;

    button:nth-of-type(1) {
      margin-right: 16px;
    }
  `,
  noResultsFound: css`
    padding: 1.5rem;
    background: ${theme.colors.black};
    text-align: center;
  `,
  loadingSpinnerContainer: css`
    padding: 4rem;
    text-align: center;
  `,
  loadingSpinner: css`
    stroke: ${theme.colors.white};
    animation: ${rotate360} 1s linear infinite;
    transform-box: fill-box;
    transform-origin: 50% 50%;
  `,
};
