import React, { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "../interfaces";
import styles from "../../styles";

const ArrowDownIcon = ({
  height = theme.svgSize.small,
  width = theme.svgSize.small,
}: IComponentIconProps): ReactElement => {
  return (
    <div css={styles.icon}>
      <svg
        width={height}
        height={width}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.25 11.25L13.1925 10.1925L9.75 13.6275V1.5H8.25V13.6275L4.8075 10.185L3.75 11.25L9 16.5L14.25 11.25Z" />
      </svg>
    </div>
  );
};

export default ArrowDownIcon;
