import React, { ReactElement, useCallback, useEffect, useState } from "react";
import searchProjects from "src/api/projects/search-projects";
import { errorToast } from "src/components/toast-notification";
import useAuth from "src/auth/use-auth";
import { ProjectTypes } from "src/pages/projects/interfaces";
import LibraryListings from "src/pages/projects/client-project-view/components/library-listings";
import { getProject } from "src/api/projects";
import { IProjectLibraryTrackDetails } from "src/pages/projects/client-project-view/components/library-listings/interfaces";
import {
  IClientOrgProjectProps,
  SortOrder,
  IClientOrgProjectSearchResponse,
  IProjectTableSort,
  ProjectSortType,
} from "../interfaces";

const EnterpriseLibraryProjectListings = (): ReactElement => {
  const { getAccessToken, organisationId, isAuthenticated } = useAuth();

  const [projects, setProjects] = useState<IClientOrgProjectProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState<string>("");
  const [
    isSelectedProjectDataLoading,
    setIsSelectedProjectDataLoading,
  ] = useState(false);
  const [
    selectedProjectLibraryTracksData,
    setSelectedProjectLibraryTracksData,
  ] = useState<IProjectLibraryTrackDetails[]>([]);

  const fetchProjects = useCallback(
    async ({ sortBy, order }: IProjectTableSort, from?: number) => {
      const filters = { projectTypes: [ProjectTypes.EnterpriseLibrary] };
      const excludeFilters = { projectTypes: [] };
      const accessToken: string = await getAccessToken();
      if (accessToken) {
        const sort = {
          by: sortBy,
          order,
        };
        const response = (await searchProjects(
          accessToken,
          organisationId,
          sort,
          from,
          {},
          filters,
          excludeFilters
        )) as IClientOrgProjectSearchResponse;

        return response;
      }
      return false;
    },
    [getAccessToken, organisationId]
  );

  const onProjectChange = useCallback((projectId: string) => {
    setSelectedProjectId(projectId);
  }, []);

  useEffect(() => {
    const defaultSort = {
      sortBy: ProjectSortType.contractEndDate,
      order: SortOrder.DESC,
    };
    const getProjects = async () => {
      setIsLoading(true);
      try {
        const projectsResponse = await fetchProjects(defaultSort);
        if (projectsResponse && projectsResponse.results) {
          setProjects(projectsResponse.results);
          if (projectsResponse.results.length > 0) {
            setSelectedProjectId(projectsResponse.results[0].id);
          }
        }
      } catch (error) {
        errorToast({
          message:
            "There was a problem retrieving projects data. Please try again.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    getProjects();
  }, [fetchProjects]);

  useEffect(() => {
    const getProjectData = async (projectId: string) => {
      if (isAuthenticated) {
        try {
          setIsSelectedProjectDataLoading(true);
          const accessToken: string = await getAccessToken();
          const response = await getProject(
            accessToken,
            organisationId,
            projectId
          );

          if (response) {
            setSelectedProjectLibraryTracksData(response?.libraryTracks ?? []);
          }
        } catch (reason) {
          setSelectedProjectLibraryTracksData([]);
        } finally {
          setIsSelectedProjectDataLoading(false);
        }
      }
    };

    if (selectedProjectId) {
      getProjectData(selectedProjectId);
    } else {
      setSelectedProjectLibraryTracksData([]);
    }
  }, [getAccessToken, isAuthenticated, organisationId, selectedProjectId]);

  return (
    <LibraryListings
      isLoading={isLoading || isSelectedProjectDataLoading}
      libraryTracks={selectedProjectLibraryTracksData}
      projects={projects}
      selectedProjectId={selectedProjectId}
      isLoadingProjects={isLoading}
      onProjectChange={onProjectChange}
    />
  );
};

export default EnterpriseLibraryProjectListings;
