import React, { ReactElement, useEffect, useState } from "react";
import useAuth from "src/auth/use-auth";
import selfOrganisations from "src/api/organisation/self";
import { errorToast } from "src/components/toast-notification";
import appStyles from "src/app/styles";
import BackBtn from "src/components/back-button";
import CopyrightFooter from "src/components/copyright-footer";
import Select from "react-select";
import styles from "./styles";

interface IOption {
  value: string;
  label: string;
}

const SelectOrg = (): ReactElement => {
  const [allOrganisations, setAllOrganisations] = useState<IOption[] | []>([]);
  const [isLoadingOrgs, setIsLoadingOrgs] = useState(true);
  const { getAccessToken, switchOrg, logout } = useAuth();

  useEffect(() => {
    const getNewOrganisations = async () => {
      try {
        setIsLoadingOrgs(true);
        const response = await selfOrganisations(getAccessToken());

        if (response?.items) {
          setAllOrganisations(
            response.items.map((org) => {
              return {
                value: org.id ? org.id : "",
                label: `${org.name} | ${org.id}`,
              };
            })
          );
        }
      } catch (e) {
        errorToast({
          message:
            "There was a problem retrieving organizations data. Please try again.",
        });
      } finally {
        setIsLoadingOrgs(false);
      }
    };
    getNewOrganisations();
  }, [getAccessToken]);

  return (
    <div css={styles.background}>
      <div css={[appStyles.container, appStyles.guestPageContainer]}>
        <div css={styles.backBtn}>
          <BackBtn onClick={logout} />
        </div>
        <div css={appStyles.guestPageInnerContainer}>
          <h3 css={styles.mainHeading}>Select organization to login.</h3>
          <p css={styles.text}>
            Your user is permitted to login to multiple organizations, select
            one from the list below. If you change your mind at a later time you
            can switch the current organization from the profile page.
          </p>
          <div css={styles.dropdownContainer}>
            <Select
              className="dropdown"
              classNamePrefix="dropdown-options"
              components={{
                IndicatorSeparator: null,
              }}
              isSearchable
              isLoading={isLoadingOrgs}
              id="org-select"
              placeholder="Select"
              css={styles.selectionInput}
              onChange={async (data) => {
                if (!data) {
                  return;
                }

                await switchOrg(data.value);
              }}
              options={allOrganisations}
            />
          </div>
        </div>
      </div>
      <div css={[appStyles.container, styles.copyrightFooter]}>
        <CopyrightFooter />
      </div>
    </div>
  );
};

export default SelectOrg;
