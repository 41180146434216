import { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "./interfaces";

const RemoveIcon = ({
  fill = theme.colors.white,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 10.7017V13.2983C18 13.5381 17.8061 13.7311 17.5672 13.7311H13.7311H10.2689H6.43278C6.19388 13.7311 6 13.5381 6 13.2983V10.7017C6 10.4628 6.19388 10.2689 6.43278 10.2689H10.2689H13.7311H17.5672C17.8061 10.2689 18 10.4628 18 10.7017Z"
        fill={fill}
      />
    </svg>
  );
};
export default RemoveIcon;
