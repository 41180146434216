import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  explainerAndIntroContainer: css`
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 1rem;

    @media ${queries.large} {
      grid-template-columns: 1fr 1fr;
    }
  `,
  textContainer: css`
    display: flex;
    align-items: left;
    margin: 0 1rem 2rem;

    @media ${queries.medium} {
      margin-right: 128px;
      align-items: center;
    }
  `,
  explainerAndIntroTitle: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 250;
    letter-spacing: -2%;
    margin-bottom: 24px;
    font-size: 3rem;
    line-height: 3.5rem;

    span {
      color: ${theme.colors.functional.brightOrange};
      font-weight: 250;
    }
  `,
  explainerAndIntroDescription: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 32px;
  `,
  imageContainer: css`
    margin: 1rem;

    @media ${queries.large} {
      margin: 0;
    }

    > div {
      display: inline-grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 24px;
      width: 100%;
    }
  `,
  card: css`
    background: ${theme.colors.background.veryDarkGray};
    border-radius: 20px;
    padding: 38px;
    margin-bottom: 24px;
    max-height: 316px;

    > * {
      display: block;
      margin: auto;
    }
  `,
};
