import { Col, Row } from "antd";
import React, { ReactElement, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import mainStyles from "../../styles";
import { IContact, IProjectLead } from "../../interfaces";
import SectionTitleAndSubtitle from "../section-title-and-subtitle";

interface IProps {
  projectLeads?: IProjectLead[];
  additionalContacts?: IContact[];
}

interface IProjectLeadsContactsProps {
  projectLeads: IProjectLead[];
}

const ProjectLeadsContacts = ({ projectLeads }: IProjectLeadsContactsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div css={mainStyles.tableTitle}>
        {t("ClientProjectViewPage##Project lead")}
      </div>
      <Row gutter={[16, 16]}>
        {projectLeads && (
          <>
            <Col xs={24} sm={24} md={8} lg={8}>
              {projectLeads.map((lead) => (
                <div
                  css={[mainStyles.sectionMainText, styles.contentRow]}
                  key={`name${lead.id}`}
                >
                  {lead.name}
                </div>
              ))}
            </Col>
            <Col flex="auto">
              {projectLeads.map((lead) => (
                <div
                  css={[mainStyles.sectionMainText, styles.contentRow]}
                  key={`email${lead.id}`}
                >
                  {lead.email}
                </div>
              ))}
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

interface IAdditionalContactsProps {
  contacts?: IContact[];
}

const AdditionalContacts = ({ contacts }: IAdditionalContactsProps) => {
  const { t } = useTranslation();
  if (!contacts || contacts.length === 0) return <></>;

  return (
    <>
      {contacts.map((contact) => {
        return (
          <div css={styles.additionalContactContainer} key={contact.email}>
            <div css={mainStyles.tableTitle}>
              {contact.contactType
                ? contact.contactType
                : t("ClientProjectViewPage##Other")}
            </div>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={24} md={8} lg={8}>
                <div css={mainStyles.sectionMainText}>{contact.name}</div>
              </Col>
              <Col xs={24} sm={24} md={8} lg={8}>
                <div css={mainStyles.sectionMainText}>{contact.email}</div>
              </Col>
            </Row>
          </div>
        );
      })}
    </>
  );
};

const ProjectContacts = forwardRef(
  (
    { projectLeads, additionalContacts }: IProps,
    ref: React.Ref<HTMLDivElement>
  ): ReactElement => {
    return (
      <div css={mainStyles.sectionContainer} id="key-contacts" ref={ref}>
        <div css={styles.contactsContainer}>
          <SectionTitleAndSubtitle
            title="Contacts"
            subtitle="Relevant contact details associated to this project"
          />
          <ProjectLeadsContacts projectLeads={projectLeads} />
          <AdditionalContacts contacts={additionalContacts} />
        </div>
      </div>
    );
  }
);

export default ProjectContacts;
