import React, { ReactElement } from "react";
import {
  DrawerTypes,
  IClientOrgProjectProps,
  IProjectTableSort,
} from "../../interfaces";
import ProjectInfoDrawer from "./project-info";
import SortProjectsDrawer from "./sort-projects";

const generateDrawerContent = (
  key: DrawerTypes,
  handleSort: (params: IProjectTableSort) => void,
  sortOptions: IProjectTableSort,
  handleDrawerToggle: () => void,
  activeProject?: IClientOrgProjectProps
): ReactElement => {
  switch (key) {
    case DrawerTypes.sortProjects:
      return (
        <SortProjectsDrawer handleSort={handleSort} sortOptions={sortOptions} />
      );
    case DrawerTypes.projectInfo:
      return <ProjectInfoDrawer activeProject={activeProject} />;
    default:
      return <div />;
  }
};

export default generateDrawerContent;
