import React from "react";
import { Tooltip } from "antd";
import theme from "src/theme";
import isAdobePremiere from "src/utils/isAdobePremiere";
import styles from "./styles";
import UserImage from "../user-image";

export interface IContactTileProps {
  header: string;
  image?: {
    url?: string;
    name: string;
  };
  title?: string;
  description?: string;
  descriptionType: "email" | "text";
}

function createMarkup(description: string) {
  return { __html: description };
}

const ContactTile = ({
  header,
  image,
  title,
  description,
  descriptionType,
}: IContactTileProps) => {
  return (
    <div
      css={styles.contactTileContainer}
      className="contact-tile-main-container"
    >
      <span css={styles.header}>{header}</span>
      <div css={styles.contactTileDetailsContainer}>
        {image && (
          <UserImage
            imageURL={image.url}
            name={image.name}
            size={48}
            fontSize={16}
          />
        )}
        <div css={styles.contactTileTextDetailsContainer(image ? 16 : 0)}>
          {title && <span css={styles.title}>{title}</span>}
          {description && descriptionType === "email" && (
            <Tooltip
              getPopupContainer={(triggerNode) => triggerNode}
              title={description}
              css={styles.tooltip}
              color={theme.colors.white}
            >
              {isAdobePremiere ? (
                <div css={[styles.description, styles.emailLink]}>
                  {description}
                </div>
              ) : (
                <a
                  href={`mailto:${description}`}
                  css={[styles.description, styles.emailLink]}
                >
                  {description}
                </a>
              )}
            </Tooltip>
          )}
          {description && descriptionType === "text" && (
            <div
              css={styles.description}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={createMarkup(description)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ContactTile;
