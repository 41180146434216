import React, { ReactElement, useEffect, useState } from "react";
import {
  Button,
  Input,
  TextArea,
  ValidationMessage,
} from "@songtradr/component-library";
import { useTranslation } from "react-i18next";
import useOrgSubscription from "src/providers/organisation/hooks";
import config from "src/config";
import { Form, Select } from "antd";
import useAuth from "src/auth/use-auth";
import { errorToast, successToast } from "src/components/toast-notification";
import notifyAccountManager from "src/api/organisation-notifications/notify-account-manager";
import axios from "axios";
import styles from "./styles";

interface IOption {
  value: string;
  label: string;
}
const regionOptions: IOption[] = [
  { label: "Asia", value: "Asia" },
  { label: "Europe", value: "Europe" },
  { label: "North America", value: "North America" },
  { label: "South America", value: "South America" },
  { label: "Oceania", value: "Oceania" },
];

const FIELDS = {
  name: "name",
  region: "region",
  description: "description",
};

const ContactAccountManagerPanel = (): ReactElement => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [region, setRegion] = useState<IOption | undefined>(undefined);
  const { user, getAccessToken, organisationId } = useAuth();

  const [name, setName] = useState<string | undefined>(user?.name);
  const [description, setDescription] = useState<string>("");
  const { organisation } = useOrgSubscription();
  const { defaultProjectsSupportEmail } = config;

  let email: string;
  if (organisation?.servicingOrganisation?.email) {
    email = organisation?.servicingOrganisation?.email;
  } else {
    email = defaultProjectsSupportEmail;
  }

  const encodedRegion = encodeURIComponent(region?.value || "");

  const body = encodeURIComponent(description || "");
  const inputUser = encodeURIComponent(`${name}`);

  const onFormSubmit = async () => {
    if (organisationId && organisation?.id === organisationId) {
      try {
        await notifyAccountManager(getAccessToken(), organisation.id, {
          message: description,
          name,
          region: region?.value,
        });
        successToast({
          message: "Message was successfully sent to your Account Manager",
        });

        setRegion(undefined);
        setDescription("");
        setName(user?.name);
        form.resetFields([FIELDS.description, FIELDS.region]);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          errorToast({
            message: "Error sending message to your Account Manager.",
          });
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    } else {
      const a = document.createElement("a");
      a.target = "_blank";
      a.href = `mailto:${email}?subject=Account Assistance Request (${encodedRegion}): ${inputUser}&body=${body}`;
      a.click();
    }
  };

  const handleSetRegion = (selectedRegion: IOption) => {
    setRegion(selectedRegion);
  };

  useEffect(() => {
    form.setFieldsValue({
      name,
      region,
      description,
    });
  });

  return (
    <div css={styles.panelContent}>
      <div css={styles.panelTitle}>{t("DashboardPage##ContactAccManager")}</div>
      <div css={styles.panelBody}>
        {t("DashboardPage##ContactAccManagerDescription")}
      </div>
      <Form form={form} onFinish={onFormSubmit}>
        <Form.Item
          name={FIELDS.name}
          css={styles.formItem}
          validateTrigger="onBlur"
          rules={[
            {
              required: true,
              message: (
                <ValidationMessage type="error">
                  {t("userProfile##validation##Please provide your name")}
                </ValidationMessage>
              ),
            },
          ]}
        >
          <p>{t("DashboardPage##Name")}</p>
          <Input
            name={FIELDS.name}
            value={name}
            onChange={(e: any) => setName(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          css={styles.formItem}
          name={FIELDS.region}
          rules={[
            {
              required: true,
              message: (
                <ValidationMessage type="error">
                  Please select a region
                </ValidationMessage>
              ),
            },
          ]}
        >
          <p>{t("DashboardPage##Region")}</p>
          <Select
            maxTagCount="responsive"
            labelInValue
            showArrow
            css={styles.formDropdownMenu}
            placeholder="Select a region"
            onChange={handleSetRegion}
            options={regionOptions}
            dropdownStyle={{ backgroundColor: "#222222" }}
            dropdownRender={(menu) => (
              <div css={styles.singleSelectMenu}>{menu}</div>
            )}
          />
        </Form.Item>
        <Form.Item
          css={styles.formItem}
          name={FIELDS.description}
          initialValue={description}
          rules={[
            {
              required: true,
              message: (
                <ValidationMessage type="error">
                  Please enter your request
                </ValidationMessage>
              ),
            },
          ]}
        >
          <p>{t("DashboardPage##Your Request")}</p>
          <TextArea
            name={FIELDS.description}
            value={description}
            onChange={(e: any) => setDescription(e.target.value)}
            css={styles.textArea}
          />
        </Form.Item>
        <Button
          css={styles.submitBtn}
          variant="primary"
          data-testid="contact-us-btn"
          type="submit"
        >
          {t("DashboardPage##SubmitRequest")}
        </Button>
      </Form>
    </div>
  );
};

export default ContactAccountManagerPanel;
