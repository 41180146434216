import { ReactElement, useEffect } from "react";
import { Skeleton } from "antd";
import IUserProfileProps from "src/interfaces/user-profile";
import { Redirect } from "react-router-dom";
import { InviteStatus } from "src/constants";
import { isAxiosError } from "axios";
import ProfileForm from "./components/user-profile-form";
import LinkError from "./components/link-error";

const UserProfile = (props: IUserProfileProps): ReactElement | null => {
  useEffect(() => {
    props.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { organisationInvite } = props;
  const isLoading = !organisationInvite;
  if (isLoading) return <Skeleton active />;

  if (organisationInvite?.status === InviteStatus.Accepted)
    return <Redirect to="/home" />;

  if (isAxiosError(organisationInvite)) {
    return <LinkError />;
  }

  const { onSubmit, isSavePending, getData } = props;
  return (
    <ProfileForm
      organisationInvite={organisationInvite}
      getData={getData}
      onSubmit={onSubmit}
      isSavePending={isSavePending}
    />
  );
};

export default UserProfile;
