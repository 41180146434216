import { CheckCircleFilled, InfoCircleFilled } from "@ant-design/icons";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "@songtradr/component-library";
import styles from "./styles";
import {
  MINIMUM_PASSWORD_LENGTH,
  numberCheck,
  hasUppercase,
  hasLowercase,
  hasSpecial,
} from "../../../../utils/regex";

interface IProps {
  setPassword: (value: string) => void;
  setIsValidPassword: (value: boolean) => void;
  isValidPassword: boolean;
  isError: boolean;
  name: string;
}

const PasswordInput = ({
  setPassword,
  setIsValidPassword,
  isValidPassword,
  isError,
  name,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [hasMinimumLength, setHasMinimumLength] = useState(false);
  const [hasUppercaseLetter, setHasUpperCaseLetter] = useState(false);
  const [hasLowercaseLetter, setHasLowerCaseLetter] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);

  useEffect(() => {
    if (
      hasMinimumLength &&
      hasUppercaseLetter &&
      hasLowercaseLetter &&
      hasSpecialCharacter &&
      hasNumber
    ) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(false);
    }
  }, [
    hasMinimumLength,
    hasUppercaseLetter,
    hasLowercaseLetter,
    hasSpecialCharacter,
    hasNumber,
    setIsValidPassword,
  ]);

  const toggleValidationMessage = (check: boolean, message: string) => (
    <React.Fragment>
      {check ? (
        <CheckCircleFilled css={styles.check} />
      ) : (
        <InfoCircleFilled css={styles.info} />
      )}{" "}
      {t(message)}
    </React.Fragment>
  );

  const handlePasswordValidation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setPassword(value);

    if (value) {
      setHasMinimumLength(value.length >= MINIMUM_PASSWORD_LENGTH);
      setHasUpperCaseLetter(hasUppercase(value));
      setHasLowerCaseLetter(hasLowercase(value));
      setHasSpecialCharacter(hasSpecial(value));
      setHasNumber(numberCheck(value));
    } else {
      setHasMinimumLength(false);
      setHasUpperCaseLetter(false);
      setHasLowerCaseLetter(false);
      setHasSpecialCharacter(false);
      setHasNumber(false);
    }
  };

  return (
    <React.Fragment>
      <Input
        className={isError ? "error" : "password-field"}
        maxLength={80}
        onChange={(event: any) => handlePasswordValidation(event)}
        data-testid="password-input"
        placeholder="Password"
        name={name}
        type="password"
        hasError={isError}
      />
      {isValidPassword ? (
        <div className="valid-message" css={styles.validPassword}>
          <CheckCircleFilled css={styles.check} />{" "}
          {t("Your password is secure, you're good to go.")}
        </div>
      ) : (
        <div
          className="password-criteria"
          css={styles.passwordCriteria(isError)}
        >
          <div>{t("Create a password that contains")}:</div>
          <ul>
            <li>
              {toggleValidationMessage(
                hasMinimumLength,
                "8 characters minimum"
              )}
            </li>
            <li>
              {toggleValidationMessage(
                hasUppercaseLetter,
                "1 upper case character"
              )}
            </li>
            <li>
              {toggleValidationMessage(
                hasLowercaseLetter,
                "1 lower case character"
              )}
            </li>
            <li>
              {toggleValidationMessage(
                hasSpecialCharacter,
                "1 special character"
              )}
            </li>
            <li>{toggleValidationMessage(hasNumber, "1 number")}</li>
          </ul>
        </div>
      )}
    </React.Fragment>
  );
};

export default PasswordInput;
