import axios from "axios";
import React from "react";
import { buildOrganisationSubscriptionsBaseUrl } from "src/api/base-url-helper";
import { getAuthHeader, getJsonContentHeader } from "src/api/headers";
import config from "src/config";

export const getPendingInvites = async (
  accessToken: string,
  orgId: string,
  searchText: string,
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.post(
    `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}/app-invites/_search`,
    {
      filterStatuses: ["Pending"],
      start: 0,
      perPage: 500,
      application: "Portal",
      organisationId: orgId,
      searchText,
      orderBy: "Desc",
    },
    options
  );
  return data;
};

export const getRejectedInvites = async (
  accessToken: string,
  orgId: string,
  searchText: string,
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.post(
    `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}/app-invites/_search`,
    {
      filterStatuses: ["Denied"],
      start: 0,
      perPage: 500,
      application: "Portal",
      organisationId: orgId,
      searchText,
      orderBy: "Desc",
    },
    options
  );
  return data;
};

export const registerInvite = async (
  code: string,
  firstName?: string,
  lastName?: string,
  email?: string,
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.post(
    `${config.organisationSubscriptionsService.url}/passcodes/_register`,
    {
      code,
      firstName,
      lastName,
      email,
    },
    options
  );
  return data;
};

export const verifyCode = async (
  code: string,
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.post(
    `${config.organisationSubscriptionsService.url}/passcodes/_verify`,
    {
      code,
    },
    options
  );
  return data;
};

export const approveInvite = async (
  accessToken: string,
  orgId: string,
  applicationInviteIds: React.Key[],
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.post(
    `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}/app-invites/_approve`,
    {
      applicationInviteIds,
    },
    options
  );
  return data;
};

export const denyInvite = async (
  accessToken: string,
  orgId: string,
  applicationInviteIds: React.Key[],
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.post(
    `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}/app-invites/_deny`,
    {
      applicationInviteIds,
    },
    options
  );
  return data;
};

export const deleteInvite = async (
  accessToken: string,
  orgId: string,
  applicationInviteIds: React.Key[],
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.post(
    `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}/app-invites/_delete`,
    {
      applicationInviteIds,
    },
    options
  );
  return data;
};

export const undoDenyInvite = async (
  accessToken: string,
  orgId: string,
  applicationInviteIds: React.Key[],
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.post(
    `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}/app-invites/_undo-deny`,
    {
      applicationInviteIds,
    },
    options
  );
  return data;
};

export const resendInvites = async (
  accessToken: string,
  orgId: string,
  inviteIds: React.Key[],
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.post(
    `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}/app-invites/_resend-invites`,
    {
      inviteIds,
    },
    options
  );
  return data;
};
