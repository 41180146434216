import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import CaseStudyCard from "./components/case-study-card";
import styles from "./styles";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import caseStudyContent from "./statics";

const CaseStudies = (): ReactElement => {
  const history = useHistory();
  useWindowSize();
  const isMobile = getIsMobile();

  const navigateTo = (path: string) => {
    if (path) {
      history.push(path);
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      <div css={styles.headingContainer}>
        <h1>
          Difficult challenges demand innovative solutions. See how we do it.
        </h1>
        <p>
          The end result isn’t a report or slide deck. It is an iconic and
          influential piece of creative work that has been optimized with data.
        </p>
      </div>
      <div css={styles.container}>
        <Swiper
          className="carousel-swiper"
          direction="horizontal"
          grabCursor
          pagination={{
            clickable: true,
          }}
          slidesPerView={isMobile ? 1 : "auto"}
          spaceBetween={isMobile ? 24 : 48}
          mousewheel={{ forceToAxis: true }}
        >
          {caseStudyContent.map((caseStudy) => {
            return (
              <SwiperSlide className="slideSwipe">
                <CaseStudyCard
                  key={caseStudy.title}
                  title={caseStudy.title}
                  onClick={() => navigateTo(caseStudy.link)}
                  linkText={caseStudy.linkText}
                  description={caseStudy.description}
                  imageSrc={caseStudy.imageSrc}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </>
  );
};

export default CaseStudies;
