import { ReactElement } from "react";

const StatusUndefined = (): ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="15.5" fill="#141414" stroke="#555555" />
      <path
        d="M13.776 12.8915C13.776 12.452 13.9063 12.0225 14.1505 11.6571C14.3946 11.2918 14.7416 11.007 15.1475 10.8389C15.5535 10.6707 16.0002 10.6267 16.4312 10.7124C16.8622 10.7982 17.258 11.0098 17.5687 11.3205C17.8794 11.6312 18.091 12.027 18.1768 12.458C18.2625 12.889 18.2185 13.3357 18.0503 13.7417C17.8822 14.1476 17.5974 14.4946 17.2321 14.7387C16.8667 14.9829 16.4372 15.1132 15.9977 15.1132C15.6442 15.1132 15.3051 15.2536 15.0552 15.5036C14.8052 15.7536 14.6647 16.0926 14.6647 16.4462V17.5944C14.6647 17.9479 14.8052 18.287 15.0552 18.537C15.3051 18.7869 15.6442 18.9274 15.9977 18.9274C16.3513 18.9274 16.6903 18.7869 16.9403 18.537C17.1903 18.287 17.3308 17.9479 17.3308 17.5944C18.2062 17.3467 18.9952 16.8596 19.6089 16.188C20.2227 15.5164 20.6369 14.6869 20.8049 13.7928C20.973 12.8986 20.8882 11.9753 20.5602 11.1267C20.2322 10.2781 19.6739 9.53783 18.9482 8.98917C18.2225 8.44051 17.358 8.10521 16.4521 8.021C15.5462 7.93678 14.6348 8.10698 13.8204 8.51245C13.0059 8.91793 12.3208 9.54259 11.8419 10.3162C11.3631 11.0898 11.1096 11.9817 11.11 12.8915C11.11 13.245 11.2504 13.5841 11.5004 13.834C11.7504 14.084 12.0895 14.2245 12.443 14.2245C12.7966 14.2245 13.1356 14.084 13.3856 13.834C13.6356 13.5841 13.776 13.245 13.776 12.8915Z"
        fill="white"
      />
      <path
        d="M15.9978 24C16.4692 24 16.9212 23.8127 17.2546 23.4794C17.5879 23.1461 17.7751 22.694 17.7751 22.2226C17.7751 21.7512 17.5879 21.2992 17.2546 20.9658C16.9212 20.6325 16.4692 20.4453 15.9978 20.4453C15.5264 20.4453 15.0743 20.6325 14.741 20.9658C14.4077 21.2992 14.2204 21.7512 14.2204 22.2226C14.2204 22.694 14.4077 23.1461 14.741 23.4794C15.0743 23.8127 15.5264 24 15.9978 24Z"
        fill="white"
      />
    </svg>
  );
};

export default StatusUndefined;
