import { IAvailableReport, IReport } from "src/api/power-bi";

export enum PowerBiActions {
  AVAILABLE_REPORTS = "AVAILABLE_REPORTS",
  SELECTED_REPORT = "SELECTED_REPORT",
}

interface IStoreAvailableReports {
  type: PowerBiActions.AVAILABLE_REPORTS;
  value: IAvailableReport[];
}

interface ISelectedReport {
  type: PowerBiActions.SELECTED_REPORT;
  value: IReport | null;
}

export type PowerBiAction = IStoreAvailableReports | ISelectedReport;
