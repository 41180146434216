import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common";

export default {
  main: css`
    .ant-table {
      border-radius: 0 !important;
    }

    .ant-table-tbody > tr > td {
      max-width: 1px;
    }

    thead .ant-table-cell {
      font-weight: bold;
      padding: 0;
      color: ${theme.colors.secondary.navyBlue};
    }

    .ant-table table {
      box-shadow: 0 0 3px 0 ${theme.colors.background.lightGray};
      color: ${theme.colors.black} !important;
      padding-left: 1rem;
      padding-right: 1rem;
      border-radius: 0;
    }

    .ant-table-tbody {
      border-radius: 0;
    }

    .ellipsis-button {
      text-align: right;
    }

    @media ${queries.medium} {
      .ant-table table {
        border-radius: 10px;
        padding: 0 0 0.7rem 0 !important;
      }

      thead tr th.ant-table-cell {
        text-align: left;
        font-weight: 600;
      }

      .ant-table-tbody > tr:last-child td {
        border-bottom: 0;
      }
    }

    @media ${queries.large} {
      margin-bottom: 0;

      thead tr th.ant-table-cell {
        padding-bottom: 0.625rem;
        padding-top: 1.25rem;
      }
    }
  `,
};
