import React from "react";
import mainStyles from "src/app/styles";
import { useTranslation } from "react-i18next";
import useOrganisation from "src/providers/organisation/hooks";
import styles from "./styles";

const AdaptiveMusic = () => {
  const { t } = useTranslation();
  const { organisation } = useOrganisation();

  return (
    <div css={mainStyles.container}>
      <div css={styles.tabHeaderContainer}>
        <div css={styles.tabHeaderAdditionalText}>{t("Adaptive music")}</div>
        <div css={styles.tabHeaderTitle}>
          {t("Dynamically edit and compose")}
        </div>
      </div>
      <div
        css={styles.adaptiveMusicContainer}
        data-testid="adaptive-music-container"
      >
        <iframe
          id="adaptive-music-iframe"
          title="Adaptive music"
          width="100%"
          height="100%"
          src={organisation?.adaptiveMusicUrl}
        />
      </div>
    </div>
  );
};

export default AdaptiveMusic;
