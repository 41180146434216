import { Button, Checkbox, Input, Label } from "@songtradr/component-library";
import React, { ReactElement, useState } from "react";
import useAuth from "src/auth/use-auth";
import orgDashboardStyles from "src/pages/org-dashboard/styles";
import PasswordInput from "src/pages/user-profile/components/user-profile-form/components/password-input";
import { createSuperadmin } from "src/api/superadmins";
import { errorToast, successToast } from "src/components/toast-notification";
import config from "src/config";
import styles from "./styles";

interface IProps {
  goBack: () => void;
}

const CreateSuperadmin = ({ goBack }: IProps): ReactElement => {
  const { error, getAccessToken } = useAuth();
  const [password, setPassword] = useState<string>("");
  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [firstNameHasError, setFirstNameHasError] = useState<boolean>(false);
  const [surnameHasError, setSurnameHasError] = useState<boolean>(false);
  const [emailHasError, setEmailHasError] = useState<boolean>(false);
  const [phoneNumberHasError, setPhoneNumberHasError] = useState<boolean>(
    false
  );
  const [selectedServiceOrgs, setSelectedServiceOrgs] = useState<string[]>([]);
  const { servicingOrganisations } = config;

  const handleServiceOrgs = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const checked =
      event.currentTarget.getAttribute("aria-checked") === "false";
    if (checked) {
      setSelectedServiceOrgs((prev) => [...prev, id]);
    } else {
      setSelectedServiceOrgs((prev) => prev.filter((el) => el !== id));
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!firstName) {
      setFirstNameHasError(true);
      return;
    }
    if (!surname) {
      setSurnameHasError(true);
      return;
    }
    if (!email) {
      setEmailHasError(true);
      return;
    }
    if (!phoneNumber) {
      setPhoneNumberHasError(true);
      return;
    }
    if (!password) {
      return;
    }
    const accessToken = getAccessToken();
    const formData = {
      firstName,
      email,
      phoneNumber,
      password,
      lastName: surname,
      superAdminServiceOrganisations: selectedServiceOrgs,
    };
    const response = await createSuperadmin(accessToken, formData);
    if (!response) {
      errorToast({ message: "Error creating superadmin" });
      return;
    }
    successToast({ message: "Superadmin created" });
    goBack();
  };

  return (
    <div css={orgDashboardStyles.container}>
      <Button css={styles.backButton} variant="important" onClick={goBack}>
        Back
      </Button>
      <div css={orgDashboardStyles.innerContainer}>
        <form onSubmit={onSubmit} css={styles.form}>
          <Label htmlFor="firstName">First Name</Label>
          <Input
            id="firstName"
            value={firstName}
            onChange={(e) => {
              setFirstName(e.target.value);
              setFirstNameHasError(false);
            }}
            hasError={firstNameHasError}
          />
          <Label htmlFor="surname">Surname</Label>
          <Input
            id="surname"
            value={surname}
            onChange={(e) => {
              setSurname(e.target.value);
              setSurnameHasError(false);
            }}
            hasError={surnameHasError}
          />
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            id="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailHasError(false);
            }}
            hasError={emailHasError}
          />
          <Label htmlFor="phoneNumber">Phone number</Label>
          <Input
            type="number"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setPhoneNumberHasError(false);
            }}
            hasError={phoneNumberHasError}
          />
          <Label htmlFor="phoneNumber">Servicing Organizations</Label>
          <div css={styles.servicingOrgs}>
            {servicingOrganisations?.map((org) => (
              <Checkbox
                onClick={(event) => handleServiceOrgs(event, org.value)}
                key={org.value}
                label={org.label}
              />
            ))}
          </div>
          <Label htmlFor="password">Password</Label>
          <div css={styles.password}>
            <PasswordInput
              setPassword={setPassword}
              isValidPassword={isValidPassword}
              setIsValidPassword={setIsValidPassword}
              isError={!!error && !isValidPassword}
              name="password"
            />
          </div>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default CreateSuperadmin;
