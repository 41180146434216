import React, { Fragment, useMemo, useState } from "react";
import { Dropdown, Menu, Table } from "antd";
import useAuth from "src/auth/use-auth";
import axios from "axios";
import { deleteInvite, undoDenyInvite } from "src/api/app-invites";
import { format } from "date-fns";
import BaseModal from "src/components/modals/base-modal";
import modalStyles from "src/components/modals/styles";
import { Button } from "@songtradr/component-library";
import { errorToast, successToast } from "src/components/toast-notification";
import STLoadingLogo from "src/components/st-loading-logo";
import styles from "./styles";
import tableStyles from "../../styles";
import MoreInfoButton from "../more-info-button";
import { IAppInvite } from "../passcodes/interfaces";

interface IProps {
  tableData?: IAppInvite[];
  onGetData: () => void;
}

const RejectedInvitesTable = ({ tableData, onGetData }: IProps) => {
  const { organisationId, getAccessToken } = useAuth();
  const [undoModalVisible, setUndoModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  const [selectedInviteId, setSelectedInviteId] = useState<string>("");
  const [isMultiSelect, setIsMultiSelect] = useState<boolean>(false);

  const clearMultiSelect = () => {
    setIsMultiSelect(false);
  };

  const handleUndo = async (id: string) => {
    setUndoModalVisible(true);
    setSelectedInviteId(id);
  };

  const moveToPending = async () => {
    const accessToken = getAccessToken();
    if (!organisationId) {
      return;
    }

    try {
      await undoDenyInvite(accessToken, organisationId, [selectedInviteId]);
      setUndoModalVisible(false);
      successToast({ message: "User moved to pending" });
      onGetData();
      if (isMultiSelect) {
        clearMultiSelect();
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        errorToast({ message: "There was an error moving this invite." });
      }
    }
  };

  const handleDelete = async (id: string) => {
    setDeleteModalVisible(true);
    setSelectedInviteId(id);
  };

  const deleteRejected = async () => {
    const accessToken = getAccessToken();
    if (!organisationId) {
      return;
    }

    try {
      await deleteInvite(accessToken, organisationId, [selectedInviteId]);
      setDeleteModalVisible(false);
      successToast({ message: "Invite deleted" });
      onGetData();
    } catch (e) {
      if (!axios.isCancel(e)) {
        errorToast({ message: "Error, invite could not be deleted." });
      }
    }
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Passcode name",
      dataIndex: "passcodeName",
      key: "passcodeName",
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      key: "permissions",
    },
    {
      title: "Date rejected",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
      key: "createdBy",
    },
    {
      title: "",
      key: "action",
      dataIndex: "action",
    },
  ];

  const formatPendingTableData = (pendingData: IAppInvite[]) => {
    return pendingData.map((item) => {
      return {
        key: item.id,
        userName: `${item.firstName} ${item.lastName}`,
        passcodeName: item.passcode.name,
        permissions: item.passcode.applications.join(", "),
        lastUpdated: format(new Date(item.lastUpdated), "MMM dd yyy"),
        createdBy: item.passcode.createdByName,
        action: (
          <Dropdown
            aria-label="action-items-dropdown"
            overlay={
              <Menu
                css={tableStyles.dropdownMenu}
                data-testid="action-items-dropdown"
              >
                <Menu.Item key="1">
                  <button
                    type="button"
                    aria-label="Disable"
                    onClick={() => handleUndo(item.id)}
                    data-testid="move-to-pending"
                  >
                    Move to pending
                  </button>
                </Menu.Item>
                <Menu.Item key="2">
                  <button
                    type="button"
                    aria-label="Delete"
                    onClick={() => handleDelete(item.id)}
                    data-testid="delete-rejected"
                  >
                    Delete rejected
                  </button>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode}
            placement="bottomRight"
          >
            <MoreInfoButton
              className="more-info-button"
              showTooltip
              customStyles={tableStyles.moreInfoButton}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
              }}
            />
          </Dropdown>
        ),
      };
    });
  };

  const showSpinner = useMemo(() => !tableData, [tableData]);

  const emptyText = useMemo(
    () => (showSpinner ? <Fragment /> : "No rejected members found."),
    [showSpinner]
  );

  return (
    <div css={styles.container}>
      <Table
        css={tableStyles.table}
        dataSource={formatPendingTableData(tableData ?? [])}
        columns={columns}
        loading={{
          indicator: <STLoadingLogo />,
          spinning: showSpinner,
        }}
        locale={{ emptyText }}
      />
      <BaseModal
        buttons={[]}
        visible={undoModalVisible}
        onClose={() => setUndoModalVisible(false)}
        content={
          <div css={styles.modal}>
            <h2>Move to pending</h2>
            <p>
              You are about to move this invite back to pending. Are you sure?
            </p>
            <div css={modalStyles.footer}>
              <Button
                type="button"
                onClick={() => setUndoModalVisible(false)}
                className="confirmationModalCancelButton"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="confirmationModalConfirmButton"
                onClick={moveToPending}
                data-testid="confirm-move-to-pending-btn"
              >
                Move to pending
              </Button>
            </div>
          </div>
        }
      />
      <BaseModal
        buttons={[]}
        visible={deleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
        content={
          <div css={styles.modal}>
            <h2>Delete</h2>
            <p>You are about to delete this invite. Are you sure?</p>
            <div css={modalStyles.footer}>
              <Button
                type="button"
                onClick={() => setDeleteModalVisible(false)}
                className="confirmationModalCancelButton"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                className="confirmationModalConfirmButton"
                onClick={deleteRejected}
                data-testid="confirm-delete-invite-btn"
              >
                Delete invite
              </Button>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default RejectedInvitesTable;
