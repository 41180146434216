import React, { useEffect, useMemo, useState } from "react";
import config from "src/config";
import useAuth from "src/auth/use-auth";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper/core";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import ContactAccountManagerPanel from "src/pages/dashboard/components/contact-acc-manager-panel";
import { IOrganisationMember } from "src/pages/team/interfaces";
import getOrgMember from "src/api/organisation-members/get-org-member";
import SlideDrawer from "src/pages/team/components/slide-drawer";
import useOrganisation from "src/providers/organisation/hooks";
import ProfileTile from "../profile-tile";
import ContactTile from "../contact-tile";
import styles from "./styles";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const ProfileAndContactSection = () => {
  const {
    organisationId,
    user,
    profileImage,
    isSuperAdmin,
    getAccessToken,
    isAuthenticated,
  } = useAuth();
  const [orgMember, setOrgMember] = useState<IOrganisationMember>(
    {} as IOrganisationMember
  );
  const { organisation } = useOrganisation();
  SwiperCore.use([Pagination]);

  useWindowSize();
  const isMobile = getIsMobile();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const contactUserSidebarContent = useMemo(() => {
    return [
      {
        content: <ContactAccountManagerPanel />,
        validationCondition: false,
      },
    ];
  }, []);

  useEffect(() => {
    const getAndSetMember = async () => {
      const organisationMember = await getOrgMember(
        getAccessToken(),
        organisationId,
        user!.id
      );
      setOrgMember(organisationMember);
    };

    if (isAuthenticated && user?.id && organisationId) {
      getAndSetMember();
    }
  }, [getAccessToken, isAuthenticated, organisationId, user]);

  const profileContent = useMemo(() => {
    return (
      <ProfileTile
        imageURL={profileImage}
        name={user?.name ?? ""}
        role={isSuperAdmin ? "Super Admin" : ""}
        jobTitle={orgMember.jobTitle}
      />
    );
  }, [isSuperAdmin, orgMember.jobTitle, profileImage, user?.name]);

  const hasNoAccountManager = useMemo(() => {
    const { firstName = "", lastName = "", email = "" } =
      organisation?.contact ?? {};

    return !firstName && !lastName && !email;
  }, [organisation?.contact]);

  const accountManagerContent = useMemo(() => {
    const { firstName = "", lastName = "", email = "" } =
      organisation?.contact ?? {};
    if (hasNoAccountManager) {
      return <></>;
    }
    const userName = `${firstName} ${lastName}`;
    return (
      <ContactTile
        header="Account Manager"
        image={{
          name: userName ?? "",
        }}
        title={userName}
        description={email}
        descriptionType="email"
      />
    );
  }, [organisation?.contact, hasNoAccountManager]);

  const helpContent = useMemo(() => {
    return (
      <ContactTile
        header="We’re here to help"
        description={`Visit our <a href="${config.support.url}">Help Center</a> for FAQs and tips on getting the most from our platform.`}
        descriptionType="text"
      />
    );
  }, []);

  const carouselData = (
    <Swiper
      className="carousel-swiper"
      direction="horizontal"
      grabCursor
      pagination={{
        clickable: true,
      }}
      slidesPerView={isMobile ? 1 : "auto"}
      spaceBetween={isMobile ? 24 : 48}
      mousewheel={{ forceToAxis: true }}
    >
      <SwiperSlide className="slideSwipe">{helpContent}</SwiperSlide>
      {!hasNoAccountManager && (
        <SwiperSlide className="slideSwipe">
          {accountManagerContent}
        </SwiperSlide>
      )}
    </Swiper>
  );
  return (
    <div css={styles.mainProfileAndContactContainer}>
      <SlideDrawer
        isOpen={isSidebarOpen}
        toggleMenu={() => setIsSidebarOpen(!isSidebarOpen)}
        steps={contactUserSidebarContent}
        onComplete={() => setIsSidebarOpen(false)}
        showButtonContainer={false}
      />
      <div css={styles.profileDataContainerSeparate}>{profileContent}</div>
      <div>{carouselData}</div>
    </div>
  );
};

export default ProfileAndContactSection;
