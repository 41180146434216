import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Select, { SelectInstance } from "react-select";
import styles, { customStyles } from "./styles";
import ICustomSelectAction, { IOption, SelectOption } from "./interfaces";
import CustomFloatingLabel from "../custom-floating-label";

interface ICustomSelectProps {
  isMultiSelect: boolean;
  placeholder: string;
  options: IOption[];
  selectedOption?: IOption | IOption[];
  isClearable: boolean;
  onChangeSelectedOption: (value?: IOption | IOption[]) => void;
}

const CustomSelect = ({
  placeholder,
  isMultiSelect,
  options,
  selectedOption,
  isClearable,
  onChangeSelectedOption,
}: ICustomSelectProps): ReactElement => {
  const selectRef = useRef<SelectInstance<IOption | null>>(null);
  const [isMenuOpened, setMenuOpened] = useState(false);
  const [isSelectFocused, setSelectFocused] = useState(false);

  useEffect(() => {
    if (isSelectFocused) {
      setMenuOpened(true);
    }
  }, [isSelectFocused]);

  const isOptionSelected = useMemo(() => {
    if (Array.isArray(selectedOption)) {
      return !!selectedOption.length;
    }
    return !!selectedOption;
  }, [selectedOption]);

  const isInputFocused = useMemo(
    () => isMenuOpened || isOptionSelected || isSelectFocused,
    [isMenuOpened, isOptionSelected, isSelectFocused]
  );

  const handleMenuOpen = useCallback(() => {
    setMenuOpened(!isMenuOpened);
  }, [isMenuOpened]);

  const handleOnBlur = useCallback(() => {
    setMenuOpened(false);
    selectRef.current?.blur();
    setSelectFocused(false);
  }, []);

  const handleOnChange = useCallback(
    (change: ICustomSelectAction, singleValue: IOption) => {
      const { action, option, removedValue } = change;
      let newValue;
      const areMultipleOptions = isMultiSelect && Array.isArray(selectedOption);
      if (action === SelectOption.SelectNew) {
        if (areMultipleOptions && option) {
          newValue = [...selectedOption, option];
        } else {
          newValue = singleValue;
        }
      } else if (action === SelectOption.RemoveValue && removedValue) {
        if (areMultipleOptions) {
          newValue = selectedOption.filter(
            (selected) => selected.value !== removedValue.value
          );
        } else {
          newValue = undefined;
        }
      }
      onChangeSelectedOption(newValue);
      handleOnBlur();
    },
    [isMultiSelect, selectedOption, handleOnBlur, onChangeSelectedOption]
  );

  const isPlaceHolderVisible = useMemo(() => {
    return (
      (!isMultiSelect && !selectedOption && !isInputFocused) || isMultiSelect
    );
  }, [isMultiSelect, selectedOption, isInputFocused]);

  const handleChangeFocus = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      e.preventDefault();
      e.stopPropagation();
      if (isSelectFocused) {
        handleOnBlur();
      }
      setSelectFocused(!isSelectFocused);
    },
    [isSelectFocused, handleOnBlur]
  );
  return (
    <div css={styles.customSelectWrapper}>
      {isPlaceHolderVisible && (
        <CustomFloatingLabel
          isInputFocused={isInputFocused}
          placeholder={placeholder}
        />
      )}

      <Select
        ref={selectRef}
        options={options}
        isClearable={isClearable && !isMultiSelect}
        isMulti={isMultiSelect}
        placeholder=""
        css={[styles.openedMenu]}
        openMenuOnFocus
        openMenuOnClick
        value={selectedOption}
        styles={customStyles(isMenuOpened, isSelectFocused)}
        onFocus={handleChangeFocus}
        onMenuOpen={handleMenuOpen}
        onBlur={handleOnBlur}
        onMenuClose={handleOnBlur}
        onChange={(singleValue, change) => {
          handleOnChange(change as ICustomSelectAction, singleValue as IOption);
        }}
      />
    </div>
  );
};
export default CustomSelect;
