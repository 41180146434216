import React, { Fragment, useEffect, useState } from "react";
import { generatePasscode } from "src/api/passcodes";
import useAuth from "src/auth/use-auth";
import { Button } from "@songtradr/component-library";
import { CopyOutlined } from "@ant-design/icons";
import STLoadingLogo from "src/components/st-loading-logo";
import styles from "./styles";
import { copyCode } from "../../utils";

interface IProps {
  updatePasscodeData: (value: string) => void;
  passcode?: string;
}

const GeneratePasscode = ({ updatePasscodeData, passcode }: IProps) => {
  const { getAccessToken, organisationId } = useAuth();
  const [generatedPasscode, setGeneratedPasscode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const generateNewPasscode = async () => {
    setIsLoading(true);
    const accessToken = await getAccessToken();
    const passcodeResponse = await generatePasscode(
      accessToken,
      organisationId
    );
    if (passcodeResponse) {
      setGeneratedPasscode(passcodeResponse.code);
      updatePasscodeData(passcodeResponse.code);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!passcode) {
      generateNewPasscode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="passcode">
        <STLoadingLogo relativePosition />
      </div>
    );
  }
  return (
    <Fragment>
      <div
        css={styles.passcodeContainer}
        data-testid={passcode && "generated-passcode"}
      >
        {passcode
          ? passcode.split("").map((char) => {
              return <span className="passcode-character">{char}</span>;
            })
          : generatedPasscode.split("").map((char) => {
              return <span className="passcode-character">{char}</span>;
            })}
      </div>
      <Button
        variant="important"
        onClick={() => copyCode(passcode || generatedPasscode)}
        css={styles.copyButton}
      >
        <CopyOutlined css={styles.icon} /> Copy code
      </Button>
    </Fragment>
  );
};

export default GeneratePasscode;
