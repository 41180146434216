import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import theme from "src/theme";
import {
  ArrowPointingDownIcon,
  ArrowPointingUpIcon,
} from "src/app/assets/icons/component-icons";
import ControlButton from "../base-button";

interface IProps {
  showLabel?: boolean;
  showTooltip?: boolean;
  className?: string;
  sortType?: sortTypes;
  tooltipText?: string;
  onClick?: () => void;
  fillColor?: string;
  dataTestId?: string;
}

export enum sortTypes {
  A_Z = "A-Z",
  Z_A = "Z_A",
}

const SortButton = ({
  showLabel = false,
  tooltipText = "Sort",
  sortType = sortTypes.A_Z,
  showTooltip = true,
  className = "",
  onClick,
  dataTestId = "sort-button",
  fillColor = theme.colors.secondary.purple,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  let Icon;

  switch (sortType) {
    case sortTypes.A_Z:
      Icon = ArrowPointingDownIcon;
      break;
    case sortTypes.Z_A:
      Icon = ArrowPointingUpIcon;
      break;
    default:
      Icon = ArrowPointingDownIcon;
  }

  return (
    <ControlButton
      className={className}
      showLabel={showLabel}
      showTooltip={showTooltip}
      label={t(tooltipText)}
      data-testid={dataTestId}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <Icon fill={fillColor} />
    </ControlButton>
  );
};

export default SortButton;
