import { ReactElement } from "react";

const TableLoginIcon = (): ReactElement => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7 17.8198C9.03026 19.3468 3.99859 16.8901 2.30162 12.3255C2.23109 12.1361 2.33526 11.9267 2.52718 11.8639L3.7919 11.4504C3.97216 11.3914 4.16886 11.4831 4.23655 11.6605C5.57097 15.1723 9.45752 17.0563 13.0651 15.8766C14.8648 15.2882 16.328 14.0342 17.1842 12.3451C18.0406 10.6565 18.1884 8.73496 17.5999 6.93529C17.0114 5.13562 15.7574 3.67242 14.0684 2.81618C12.3798 1.95978 10.4582 1.81203 8.65854 2.4005C6.46195 3.11877 4.78563 4.82375 4.09887 7.0096C4.02272 7.252 3.77497 7.4034 3.52824 7.34273L2.38647 7.06198C2.16717 7.00805 2.03219 6.78625 2.09591 6.56959C2.48668 5.24083 3.17882 4.00805 4.11191 2.98313C5.16963 1.82139 6.52218 0.947784 8.02303 0.457017C10.3421 -0.301297 12.8169 -0.111264 14.9929 0.992348C17.169 2.09596 18.7846 3.98087 19.5427 6.29946C20.3011 8.61852 20.111 11.0933 19.0074 13.2694C17.9038 15.4454 16.0189 17.061 13.7003 17.8192L13.7 17.8198Z"
        fill="white"
      />
      <path
        d="M15.1642 9.5859L10.4225 14.3277C10.2969 14.4532 10.0928 14.4532 9.96721 14.3277L9.1215 13.4819C8.99596 13.3564 8.99596 13.1522 9.1215 13.0267L12.5627 9.58544C12.6883 9.45989 12.6883 9.25571 12.5627 9.13016L9.1215 5.68893C8.99596 5.56339 8.99596 5.3592 9.1215 5.23366L9.96675 4.38795C10.0923 4.2624 10.2965 4.2624 10.422 4.38795L15.1638 9.1297C15.2893 9.25525 15.2893 9.45943 15.1638 9.58498L15.1642 9.5859Z"
        fill="white"
      />
      <path
        d="M-6.63356e-08 8.76043L-1.40712e-08 9.9561C-6.2999e-09 10.1339 0.144125 10.278 0.321911 10.278L13.769 10.278C13.9468 10.278 14.0909 10.1339 14.0909 9.9561L14.0909 8.76043C14.0909 8.58265 13.9468 8.43852 13.769 8.43852L0.321911 8.43852C0.144125 8.43852 -7.4107e-08 8.58265 -6.63356e-08 8.76043Z"
        fill="white"
      />
    </svg>
  );
};
export default TableLoginIcon;
