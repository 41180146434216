import React, { useCallback } from "react";
import { IColumnsData } from "src/interfaces/table/IColumnsData";
import { useTranslation } from "react-i18next";
import { isNotNull } from "src/utils/type-guards";
import ControlButton from "src/components/control-buttons/base-button";
import SortASC from "src/app/assets/icons/component-icons/buttons/sort-asc";
import SortDESC from "src/app/assets/icons/component-icons/buttons/sort-desc";
import theme from "src/theme";
import style from "../styles";
import { IProjectTableSort, ProjectSortType, SortOrder } from "../interfaces";

export default (
  isMobile: boolean,
  isTablet: boolean,
  handleSort: (params: IProjectTableSort) => void,
  sortOptions: IProjectTableSort,
  showBrandNameColumn: boolean
): Array<IColumnsData> => {
  const { t } = useTranslation();

  const sortByColumn = useCallback(
    (column: ProjectSortType) => {
      handleSort({
        sortBy: column,
        order:
          sortOptions.sortBy === column && sortOptions.order === SortOrder.ASC
            ? SortOrder.DESC
            : SortOrder.ASC,
      });
    },
    [handleSort, sortOptions.order, sortOptions.sortBy]
  );

  const generateColumn = useCallback(
    (
      columnsData: ({
        title: string;
        key: string;
        sort: boolean;
        sortKey?: ProjectSortType;
        fixed?: "left" | "right" | undefined;
        sortTooltipType?: "numeric" | "string";
      } | null)[]
    ): IColumnsData[] => {
      return columnsData
        .filter(isNotNull)
        .map(({ title, key, sort, sortKey, fixed, sortTooltipType }) => {
          const isOrderTypeASC =
            sortOptions.sortBy !== sortKey ||
            (sortOptions.sortBy === sortKey &&
              sortOptions.order === SortOrder.ASC);
          let tooltipText = "";
          if (sortTooltipType === "numeric") {
            tooltipText = isOrderTypeASC
              ? t("Sort ascending")
              : t("Sort descending");
          } else {
            tooltipText = isOrderTypeASC ? t("Sort A-Z") : t("Sort Z-A");
          }
          const fill =
            sort && sortOptions.sortBy === sortKey
              ? theme.colors.functional.gray65
              : theme.colors.white;
          return ({
            title: (
              <div css={style.titleContainer}>
                <span>{title}</span>
                {sort && !(isTablet || isMobile) && (
                  <ControlButton
                    css={style.sortButton}
                    showTooltip
                    label={tooltipText}
                    onClick={() => sortByColumn(sortKey!)}
                  >
                    {isOrderTypeASC ? (
                      <SortDESC fill={fill} />
                    ) : (
                      <SortASC fill={fill} />
                    )}
                  </ControlButton>
                )}
              </div>
            ),
            dataIndex: key,
            key,
            width: 200,
            fixed,
          } as unknown) as IColumnsData;
        });
    },
    [isMobile, isTablet, sortByColumn, sortOptions.order, sortOptions.sortBy, t]
  );

  return generateColumn([
    {
      title: t("ProjectsPage##Project name"),
      key: "name",
      sort: false,
      fixed: isMobile ? undefined : "left",
    },
    {
      title: t("ProjectsPage##Campaign name"),
      key: "campaignName",
      sort: false,
    },
    showBrandNameColumn
      ? {
          title: t("ProjectsPage##Brand name"),
          key: "brandName",
          sort: true,
          sortKey: ProjectSortType.ClientOrganisationName,
          sortTooltipType: "string",
        }
      : null,
    {
      title: t("ProjectsPage##Track name"),
      key: "trackName",
      sort: true,
      sortKey: ProjectSortType.name,
      sortTooltipType: "string",
    },
    {
      title: t("ProjectsPage##Client ref ID"),
      key: "clientRefId",
      sort: false,
    },
    { title: t("ProjectsPage##Start date"), key: "startDate", sort: false },
    { title: t("ProjectsPage##Duration"), key: "duration", sort: false },
    {
      title: t("ProjectsPage##Remaining"),
      key: "remaining",
      sort: true,
      sortKey: ProjectSortType.termEndDate,
      sortTooltipType: "numeric",
    },
    { title: t("ProjectsPage##Territories"), key: "territories", sort: false },
    { title: t("ProjectsPage##Media"), key: "media", sort: false },
    {
      title: t("ProjectsPage##Status"),
      key: "status",
      sort: false,
      fixed: isMobile || isTablet ? undefined : "right",
    },
  ]);
};
