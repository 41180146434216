import React, { useCallback, useEffect, useState } from "react";
import DashIcon from "src/app/assets/icons/component-icons/dash-icon";
import TickIcon from "src/app/assets/icons/component-icons/tick-icon";
import { useTranslation } from "react-i18next";
import {
  MINIMUM_PASSWORD_LENGTH,
  hasLowercase,
  hasSpecial,
  hasUppercase,
  numberCheck,
} from "src/pages/user-profile/utils/regex";
import styles from "./styles";

interface IPasswordValidationCriteriaProps {
  password?: string;
  onUpdatePasswordValidity: (isValidPassword: boolean) => void;
}

const PasswordValidationCriteria = ({
  password,
  onUpdatePasswordValidity,
}: IPasswordValidationCriteriaProps) => {
  const [hasMinimumLength, setHasMinimumLength] = useState(false);
  const [hasUppercaseLetter, setHasUpperCaseLetter] = useState(false);
  const [hasLowercaseLetter, setHasLowerCaseLetter] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);

  const handlePasswordValidation = useCallback(() => {
    if (password) {
      setHasMinimumLength(password.length >= MINIMUM_PASSWORD_LENGTH);
      setHasUpperCaseLetter(hasUppercase(password));
      setHasLowerCaseLetter(hasLowercase(password));
      setHasSpecialCharacter(hasSpecial(password));
      setHasNumber(numberCheck(password));
    } else {
      setHasMinimumLength(false);
      setHasUpperCaseLetter(false);
      setHasLowerCaseLetter(false);
      setHasSpecialCharacter(false);
      setHasNumber(false);
    }
  }, [password]);

  const handleUpdatePasswordValidity = useCallback(() => {
    const isValidPassword =
      hasMinimumLength &&
      hasUppercaseLetter &&
      hasLowercaseLetter &&
      hasSpecialCharacter &&
      hasNumber;

    onUpdatePasswordValidity(isValidPassword);
  }, [
    hasLowercaseLetter,
    hasMinimumLength,
    hasNumber,
    hasSpecialCharacter,
    hasUppercaseLetter,
    onUpdatePasswordValidity,
  ]);

  useEffect(() => {
    handlePasswordValidation();
    handleUpdatePasswordValidity();
  }, [handlePasswordValidation, handleUpdatePasswordValidity]);

  const { t } = useTranslation();

  const toggleValidationMessage = (check: boolean, message: string) => (
    <>
      {check ? <TickIcon css={styles.check} /> : <DashIcon css={styles.info} />}
      {t(message)}
    </>
  );

  return (
    <div className="password-criteria" css={styles.passwordCriteria}>
      <div css={styles.passwordInstruction}>
        {t("Your password must contain")}
      </div>
      <ul>
        <li>
          {toggleValidationMessage(hasMinimumLength, "8 characters minimum")}
        </li>
        <li>
          {toggleValidationMessage(
            hasUppercaseLetter,
            "1 upper case character"
          )}
        </li>
        <li>
          {toggleValidationMessage(
            hasLowercaseLetter,
            "1 lower case character"
          )}
        </li>
        <li>
          {toggleValidationMessage(hasSpecialCharacter, "1 special character")}
        </li>
        <li>{toggleValidationMessage(hasNumber, "1 number")}</li>
      </ul>
    </div>
  );
};

export default PasswordValidationCriteria;
