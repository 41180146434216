import { ReactElement, useCallback } from "react";
import CustomSearchInput from "src/components/custom-search-input";
import { IOption } from "src/components/custom-select/interfaces";
import Pills from "src/pages/projects/client-project-view/components/pills";
import CustomSelect from "src/components/custom-select";
import { CommercialPills } from "src/constants";
import styles from "../styles";

const defaultPlaceHolder = "Status";

export interface IPillData {
  label: string;
  variable?: string;
  isHidden?: boolean;
}

interface ITableActions {
  searchText?: string;
  pillsData: IPillData[];
  options: IOption[];
  selectedPill: CommercialPills;
  onChangePill: (newSelectedPill: CommercialPills) => void;
  onClearSearchText?: () => void;
  onUpdateSearchText: (searchText: string) => void;
  onStatusChange: (newOption: IOption) => void;
  selectedStatusOption: IOption;
}
const CommercialTableActions = ({
  searchText,
  pillsData,
  options,
  selectedPill,
  onChangePill,
  onClearSearchText,
  onUpdateSearchText,
  onStatusChange,
  selectedStatusOption,
}: ITableActions): ReactElement => {
  const getPillsData = useCallback(() => {
    return pillsData.map((pill) => {
      return {
        key: pill.label,
        name: pill.label,
        variable: pill?.variable,
        isSelected: pill.label === selectedPill,
        onClick: () => onChangePill(pill.label as CommercialPills),
      };
    });
  }, [pillsData, selectedPill, onChangePill]);

  const handleChangeSelectedOption = useCallback(
    (newOption: IOption) => {
      let modifiedNewOption = newOption;
      if (newOption) {
        modifiedNewOption = {
          value: newOption.value,
          label: `${defaultPlaceHolder}: ${newOption.label}`,
        };
      }
      onStatusChange(modifiedNewOption);
    },
    [onStatusChange]
  );

  return (
    <div css={styles.actionsContainer}>
      <Pills pills={getPillsData()} customStyles={styles.customActionPills} />
      {selectedPill === CommercialPills.AllProjects && (
        <div css={styles.actions}>
          <CustomSearchInput
            searchText={searchText}
            onUpdateSearchText={onUpdateSearchText}
            onClearSearchText={onClearSearchText}
          />
          <CustomSelect
            isClearable={false}
            selectedOption={selectedStatusOption}
            isMultiSelect={false}
            placeholder={defaultPlaceHolder}
            options={options}
            onChangeSelectedOption={(e) =>
              handleChangeSelectedOption(e as IOption)
            }
          />
        </div>
      )}
    </div>
  );
};

export default CommercialTableActions;
