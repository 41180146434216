import { createContext } from "react";

const noop = () => {};

export interface IBreadcrumbProvider {
  playlistName: string;
  updateBreadcrumbPlaylistName: (name: string) => void;
  trackName: string;
  updateBreadcrumbTrackName: (name: string) => void;
  folderName: string;
  updateBreadcrumbFolderName: (name: string) => void;
  projectName: string;
  updateBreadcrumbProjectName: (name: string) => void;
}

export default createContext<IBreadcrumbProvider>({
  playlistName: "",
  updateBreadcrumbPlaylistName: () => new Promise<void>(() => noop),
  trackName: "",
  updateBreadcrumbTrackName: () => new Promise<void>(() => noop),
  folderName: "",
  updateBreadcrumbFolderName: () => new Promise<void>(() => noop),
  projectName: "",
  updateBreadcrumbProjectName: () => new Promise<void>(() => noop),
});
