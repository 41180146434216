import React, { ReactElement, useState } from "react";
import { Col, Form, Row } from "antd";
import { Input } from "@songtradr/component-library";
import { IOrganisationMemberProps } from "src/pages/team/interfaces";
import styles from "./styles";

const FIELDS = {
  firstName: "firstName",
  lastName: "lastName",
  company: "company",
  email: "email",
  jobTitle: "jobTitle",
  phoneNumber: "phoneNumber",
};

interface IProps {
  profileData: IOrganisationMemberProps;
}

const UserProfileForm = ({ profileData }: IProps): ReactElement => {
  const [firstName, setFirstName] = useState(profileData.firstName);
  const [lastName, setLastName] = useState(profileData.lastName);
  const [company, setCompany] = useState(profileData.companyName);
  const [email, setEmail] = useState(profileData.email);
  const [jobTitle, setJobTitle] = useState(profileData.jobTitle);
  const [phoneNumber, setPhoneNumber] = useState(profileData.phoneNumber);

  const [isError] = useState(false);

  const isValidName = (name: string) => {
    return name.trim().length > 0;
  };

  const onFormSubmit = () => {};

  return (
    <div css={styles.profileFormContainer}>
      <Form layout="vertical" onFinish={onFormSubmit}>
        <Row>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 8, order: 1 }}
            lg={{ span: 7 }}
            xl={{ span: 7 }}
          >
            <Form.Item
              name={FIELDS.firstName}
              css={styles.input}
              initialValue={firstName}
              className={!isValidName(firstName) && isError ? "error" : ""}
            >
              <Input
                defaultValue={profileData.firstName}
                value={profileData.firstName}
                disabled
                onChange={(e: any) => setFirstName(e.target.value)}
                name={FIELDS.firstName}
                hasError={!isValidName(firstName) && isError}
              />
            </Form.Item>

            <Form.Item
              name={FIELDS.lastName}
              initialValue={lastName}
              css={styles.input}
              className={!isValidName(lastName) && isError ? "error" : ""}
            >
              <Input
                value={profileData.lastName}
                defaultValue={profileData.lastName}
                onChange={(e: any) => setLastName(e.target.value)}
                name={FIELDS.lastName}
                disabled
                hasError={!isValidName(lastName) && isError}
              />
            </Form.Item>

            <Form.Item
              name={FIELDS.company}
              initialValue={company}
              css={styles.input}
              className={!isValidName(company || "") && isError ? "error" : ""}
            >
              <Input
                defaultValue={profileData.companyName}
                value={profileData.companyName}
                onChange={(e: any) => setCompany(e.target.value)}
                name={FIELDS.company}
                disabled
                hasError={!isValidName(company || "") && isError}
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 8, order: 1 }}
            lg={{ span: 7, offset: 1 }}
            xl={{ span: 7, offset: 1 }}
          >
            <Form.Item
              name={FIELDS.email}
              css={styles.input}
              className={!isValidName(email) && isError ? "error" : ""}
              initialValue={email}
            >
              <Input
                value={profileData.email}
                defaultValue={profileData.email}
                disabled
                onChange={(e: any) => setEmail(e.target.value)}
                name={FIELDS.email}
                hasError={!isValidName(email) && isError}
              />
            </Form.Item>

            <Form.Item
              name={FIELDS.jobTitle}
              css={styles.input}
              initialValue={jobTitle}
              className={!isValidName(jobTitle || "") && isError ? "error" : ""}
            >
              <Input
                value={jobTitle}
                defaultValue={profileData.jobTitle}
                onChange={(e: any) => setJobTitle(e.target.value)}
                name={FIELDS.jobTitle}
                disabled
                hasError={!isValidName(jobTitle || "") && isError}
              />
            </Form.Item>
            <Form.Item
              name={FIELDS.phoneNumber}
              initialValue={phoneNumber}
              css={styles.input}
              className={
                !isValidName(phoneNumber || "") && isError ? "error" : ""
              }
            >
              <Input
                value={phoneNumber}
                defaultValue={profileData.phoneNumber}
                onChange={(e: any) => setPhoneNumber(e.target.value)}
                name={FIELDS.phoneNumber}
                disabled
                hasError={!isValidName(phoneNumber || "") && isError}
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 8, order: 1 }}
            lg={{ span: 7, offset: 1 }}
            xl={{ span: 7, offset: 1 }}
          >
            {/* Todo - add in user brand column */}
            {/* Associated Brands */}
          </Col>
        </Row>
        <Row>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 3, order: 1 }}
            lg={{ span: 3 }}
            xl={{ span: 3 }}
          >
            <Form.Item css={styles.submit}>
              {/* <Button
                type="orange-to-white"
                htmlType="submit"
                block
                size="small"
                aria-label={t("userProfile##Get started")}
                // loading={isSavePending}
                data-testid="submit-profile-button"
                theme="orange-to-white"
              >
                {t("userProfile##EditProfile")}
              </Button> */}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default UserProfileForm;
