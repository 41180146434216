import axios, { AxiosResponse } from "axios";
import { IImgPreviews } from "src/pages/profile/interfaces";
import buildOrganisationsBaseUrl from "../base-url-helper";
import { getAuthHeader } from "../headers";

export type DeleteBody = {
  [key in keyof IImgPreviews]: true;
};

const deleteProfileImage = async (
  orgId: string,
  userId: string,
  accessToken: string,
  data: DeleteBody
): Promise<AxiosResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken) },
    data,
  };

  return axios.delete(
    `${buildOrganisationsBaseUrl(orgId)}/members/${userId}/logo`,
    options
  );
};

export default deleteProfileImage;
