import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  background: css`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  formContainer: css`
    .ant-form-item .ant-form-item-control {
      margin-bottom: 1rem;
    }
  `,
  titleAndSubtitleContainer: css`
    margin-top: 80px;
    margin-bottom: 48px;
  `,
  submit: css`
    margin-top: 1rem;
    margin-bottom: 0;
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: row-reverse;
    }

    svg {
      margin: 0 0 0 0.75rem;
    }
  `,
  disclaimer: css`
    color: ${theme.colors.white};
    .ant-form-item-control-input-content {
      display: flex;
    }
  `,
  input: css`
    .error input {
      border-color: ${theme.colors.functional.red} !important;
    }

    .ant-form-item-explain-error span {
      padding: 0 !important;
      margin-bottom: 16px;
    }

    .input-label,
    div {
      font-family: ${theme.fonts.sofiaPro};
    }
  `,
  title: css`
    margin-bottom: 16px !important;
    font-size: 2rem !important;
    line-height: 2.5rem !important;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 500 !important;
    color: ${theme.colors.white} !important;
  `,
  description: css`
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400 !important;
    color: ${theme.colors.white} !important;
  `,
  checkboxContainer: css`
    display: flex;
    height: 10%;
    width: 8%;
    margin-top: 3px;
  `,
  checkbox: css`
    margin-right: 0.5rem;
    .ant-checkbox-inner {
      background-color: ${theme.colors.white} !important;
      border-color: #fafafa;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: ${theme.colors.functional.brightOrange} !important;
    }
    .ant-checkbox-checked .ant-checkbox-inner::after {
      color: white !important;
    }
  `,
  link: css`
    color: ${theme.colors.functional.brightOrange} !important;
    font-weight: bold;
  `,
  selectionInput: css`
    border-color: ${theme.colors.secondary.blueOnBlack};
    border-radius: 4px;
    padding-top: 8px;

    input {
      padding: 8px 0 !important;
    }
    .profile-select__value-container {
      padding-left: 15px;
    }
  `,
  copyrightFooter: css`
    width: 100%;
  `,
  passwordContainer: css`
    .floatingLabel {
      margin-bottom: 0;
    }
  `,
  flexGrow: css`
    flex-grow: 1;
  `,
};
