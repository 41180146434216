import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import useOrg from "src/providers/organisation/hooks";
import config from "src/config";
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
  CookieStorage,
} from "amazon-cognito-identity-js";
import {
  getUserPermissions,
  IUserPermissions,
} from "src/auth/access-token-utils";
import { useHistory } from "react-router-dom";
import getOrganisationMembers from "src/api/organisation-members/get-org-members";
import { ISimpleUser } from "src/interfaces/auth";
import { errorToast, successToast } from "src/components/toast-notification";
import { useTranslation } from "react-i18next";
import { DataDogLogTypes, log } from "src/utils/data-dog";
import getOrgMember from "src/api/organisation-members/get-org-member";
import CognitoContext from "./context";
import { transformCognitoUser } from "./utils/transform-cognito-user";
import { ICognitoError, IAuthenticatedUser } from "./interfaces";

interface IProps {
  children: ReactElement;
  userPoolId: string;
  clientId: string;
}

interface ISetupUserSessionProps {
  orgId?: string;
  cognitoUser: CognitoUser | null;
  redirectUrl?: string;
}

const cookieStorage = new CookieStorage({
  domain: config.cognito.domain,
  secure: config.cognito.isSecure,
});

const CognitoProvider = ({
  children,
  userPoolId,
  clientId,
}: IProps): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const hasMultipleOrgsRef = useRef(true);
  const [isPasswordReset, setIsPasswordReset] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isBlessedSuperAdmin, setIsBlessedSuperAdmin] = useState<
    boolean | undefined
  >(false);
  const [user, setUser] = useState<IAuthenticatedUser>();
  const [error, setError] = useState<ICognitoError | null>(null);
  const [organisationId, setOrganisationId] = useState("");
  const [hasMultipleOrgs, setHasMultipleOrgs] = useState(false);
  const [profileImage, setProfileImage] = useState<undefined | string>("");
  const [userPermissions, setUserPermissions] = useState<IUserPermissions>();
  const [users, setUsers] = useState<ISimpleUser[]>();
  const { t } = useTranslation();

  const { getOrg } = useOrg();

  const history = useHistory();

  const userPool = useMemo(() => {
    return new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
      Storage: cookieStorage,
    });
  }, [clientId, userPoolId]);

  const currentCognitoUser = userPool.getCurrentUser();

  const resetStates = useCallback(() => {
    setIsLoading(false);
    setUser(undefined);
    setIsAuthenticated(false);
    setIsSuperAdmin(false);
    setIsBlessedSuperAdmin(false);
    setOrganisationId("");
    cookieStorage.removeItem("portal-organisationId");
    history.push("/login");
  }, [history]);

  const logout = useCallback(() => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut(() => {
        resetStates();
      });
    } else {
      resetStates();
    }
  }, [userPool, resetStates]);

  const getOrgIdsFromDecodedToken = (decodedToken: { [id: string]: any }) => {
    const cognitoGroups: string[] = decodedToken["cognito:groups"];
    if (cognitoGroups) {
      if (cognitoGroups.includes("superadmin")) {
        return [];
      }

      const orgMemberCognitoGroups = cognitoGroups.filter((g) =>
        g.startsWith("org:member:")
      );
      if (orgMemberCognitoGroups?.length > 0) {
        return orgMemberCognitoGroups.map((g) => g.replace("org:member:", ""));
      }

      log(
        DataDogLogTypes.ERROR,
        "User tried to login without being a member of an organisation",
        `Cognito username: ${decodedToken.username}`
      );
    } else {
      log(
        DataDogLogTypes.ERROR,
        "User tried to login without cognito groups",
        `Cognito username: ${decodedToken.username}`
      );
    }
    return [];
  };

  const hasSuperAdminRole = (decodedToken: { [id: string]: any }) => {
    const cognitoGroups: string[] = decodedToken["cognito:groups"];
    return cognitoGroups.includes("superadmin");
  };

  const setSession = useCallback(
    async (setupUserSessionProperties: ISetupUserSessionProps) => {
      const {
        cognitoUser,
        orgId = "",
        redirectUrl = "",
      } = setupUserSessionProperties;
      try {
        setIsLoading(true);
        const previousOrgId = cookieStorage.getItem("portal-organisationId");
        cognitoUser?.getSession(
          async (err: any, session: CognitoUserSession) => {
            if (err || !session) {
              logout();
            }

            const isSessionValid = session.isValid();

            if (isSessionValid) {
              setIsAuthenticated(true);
              const token = session.getIdToken();
              const accessToken = session.getAccessToken();
              const jwt = accessToken.getJwtToken();
              const decodedToken = accessToken.decodePayload();

              const orgIds = getOrgIdsFromDecodedToken(decodedToken);
              if (hasSuperAdminRole(decodedToken)) {
                setIsSuperAdmin(true);
              }
              let temporaryOrganisationId =
                orgIds.length === 1 ? orgIds[0] : null;

              const isSuperAdminOrHasPreviousOrgId =
                previousOrgId &&
                (hasSuperAdminRole(decodedToken) ||
                  orgIds.some(
                    (organisation) => organisation === previousOrgId
                  ));

              if (isSuperAdminOrHasPreviousOrgId) {
                temporaryOrganisationId = previousOrgId;
              }

              hasMultipleOrgsRef.current =
                orgIds.length > 1 || hasSuperAdminRole(decodedToken);
              setHasMultipleOrgs(hasMultipleOrgsRef.current);

              const selectedOrgId = orgId || temporaryOrganisationId;

              const transformedUser = transformCognitoUser(token);
              setUser(transformedUser);
              if (selectedOrgId) {
                await getOrg(selectedOrgId, jwt);
                setOrganisationId(selectedOrgId);
                if (hasSuperAdminRole(decodedToken)) {
                  cookieStorage.setItem("portal-organisationId", selectedOrgId);
                }

                // Get profile
                // Legacy users will have auth0 userIds, so we check if they have those first.
                const sub =
                  token.payload["custom:exUserId"] || decodedToken.sub;
                const orgMember = await getOrgMember(jwt, selectedOrgId, sub);

                transformedUser.name = `${orgMember.firstName} ${orgMember.lastName}`;
                setProfileImage(orgMember?.profileImage);
                transformedUser.firstName = orgMember.firstName;
                transformedUser.isBlessedSuperAdmin =
                  orgMember?.isBlessedSuperAdmin ?? false;
                setIsBlessedSuperAdmin(orgMember.isBlessedSuperAdmin);
                setUser(transformedUser);
                const permissions = getUserPermissions(
                  hasSuperAdminRole(decodedToken),
                  orgMember.applications
                );

                setIsOrgAdmin(permissions.canAccessTeams);
                if (!permissions.canAccessPortal) {
                  setError({
                    error: "no portal permission",
                    error_description:
                      "This user does not have a portal permission. Please contact your account manager.",
                  });
                  /* User does not have a portal permission and should be logged out */
                  log(
                    DataDogLogTypes.ERROR,
                    "User tried to login without a portal permission",
                    orgMember
                  );
                  logout();
                  return;
                }

                setUserPermissions(permissions);
                if (!users) {
                  const usersResponse = await getOrganisationMembers(
                    jwt,
                    selectedOrgId,
                    false,
                    user?.id,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    200
                  );

                  const usersArray = usersResponse?.orgMembers.map(
                    (orgUser) => ({
                      name: `${orgUser.firstName} ${orgUser.lastName}`,
                      id: orgUser.auth0UserId,
                      email: orgUser.email,
                    })
                  );

                  setUsers(usersArray);
                }
                if (redirectUrl) {
                  history.push(redirectUrl);
                }
              } else if (hasMultipleOrgsRef.current) {
                history.push("/select-org");
              }
            } else {
              logout();
            }
          }
        );
      } catch (cognitoError) {
        log(
          DataDogLogTypes.ERROR,
          "Error getting cognito session",
          cognitoError
        );
        logout();
      } finally {
        setIsLoading(false);
      }
    },
    [user, users, logout, getOrg, history]
  );

  /**
   * Validates the current session with access & id tokens in local stroage
   * @returns boolean representation of session validity
   */
  const isSessionValid = useCallback(async (): Promise<boolean> => {
    let isValid = false;
    const getValidatedSession = () => {
      return new Promise<boolean>((resolve) => {
        if (currentCognitoUser) {
          const signInUserSession = currentCognitoUser.getSignInUserSession();
          if (signInUserSession && !signInUserSession.isValid()) {
            setIsLoading(true);
            currentCognitoUser.refreshSession(
              signInUserSession.getRefreshToken(),
              async (err: any, session: CognitoUserSession): Promise<void> => {
                if (err) {
                  resolve(false);
                  return;
                }
                if (session.isValid()) {
                  await setSession({ cognitoUser: currentCognitoUser });
                  resolve(session.isValid());
                  return;
                }
                resolve(false);
              }
            );
            setIsLoading(false);
          } else {
            currentCognitoUser?.getSession(
              async (err: any, session: CognitoUserSession): Promise<void> => {
                if (err) {
                  resolve(false);
                  return;
                }

                if (session.isValid()) {
                  if (!user) {
                    await setSession({ cognitoUser: currentCognitoUser });
                  }
                  resolve(session.isValid());
                  return;
                }
                resolve(false);
              }
            );
          }
        } else {
          resolve(false);
        }
      });
    };
    isValid = await getValidatedSession();

    if (!isValid) {
      logout();
    }
    return isValid || false;
  }, [currentCognitoUser, logout, setSession, user]);

  const getAccessToken = useCallback((): string => {
    let jwt;
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      cognitoUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          setIsAuthenticated(false);
          history.push("/login");
        }
        if (session) {
          const token = session.getAccessToken();
          jwt = token.getJwtToken();
        }
      });
    }

    return jwt || "";
  }, [history, userPool]);

  const login = useCallback(
    (target?: string) => {
      if (target) {
        history.push(target);
      } else {
        history.push("/");
      }
    },
    [history]
  );

  const resetPassword = useCallback(
    (email: string) => {
      const userData = {
        Username: email,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);
      cognitoUser?.forgotPassword({
        onSuccess() {
          // successfully initiated reset password request
          if (isPasswordReset) setIsPasswordReset(false);
        },
        onFailure(e) {
          log(DataDogLogTypes.ERROR, "Forgot password error", e);
          if (isPasswordReset) setIsPasswordReset(false);
          if (e.toString().includes("LimitExceededException")) {
            errorToast({
              message: t("Reset code limit reached, try again later"),
            });
          }
        },
      });
    },
    [userPool, t, isPasswordReset]
  );

  const confirmResetVerificationCode = useCallback(
    (verificationCode: string, newPassword: string, email: string) => {
      setIsLoading(true);
      const userData = {
        Username: email,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);
      cognitoUser?.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          setIsLoading(false);
          setIsPasswordReset(true);
          setError(null);
        },
        onFailure(e) {
          log(DataDogLogTypes.ERROR, "Password reset fail", e);
          setError({
            error: "password reset fail",
            error_description:
              "Invalid verification code provided, please try again.",
          });
          setIsLoading(false);
          if (isPasswordReset) setIsPasswordReset(false);
        },
      });
    },
    [isPasswordReset, userPool]
  );

  const changePassword = useCallback(
    (
      verificationCode: string,
      newPassword: string,
      email: string,
      successCallback?: () => void
    ) => {
      const userData = {
        Username: email,
        Pool: userPool,
      };

      const cognitoUser = new CognitoUser(userData);
      cognitoUser?.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          if (successCallback) {
            successCallback();
          }

          successToast({ message: t("Your password has been updated") });
          history.push("/home");
        },
        onFailure(e) {
          log(DataDogLogTypes.ERROR, "Failed to change password", e);
          errorToast({
            message: t(
              "Failed to change password, please check that the code is correct"
            ),
          });
        },
      });
    },
    [history, t, userPool]
  );

  const handleLogin = useCallback(
    (
      username: string,
      password: string,
      redirectUrl?: string,
      afterLoginFailure?: (error: string) => void
    ) => {
      cookieStorage.removeItem("portal-organisationId");

      function onLoginFailure() {
        if (isPasswordReset) setIsPasswordReset(false);

        const errorDescription = "Incorrect email address or password provided";

        if (afterLoginFailure) {
          afterLoginFailure(errorDescription);
        }
      }

      async function onLoginSuccess() {
        const cognitoUser = userPool?.getCurrentUser();
        await setSession({ cognitoUser, redirectUrl });
        if (!hasMultipleOrgsRef.current) {
          history.push("/home");
        }
        setError(null);
        setIsLoading(false);
      }

      try {
        setIsLoading(true);

        const authenticationDetails = new AuthenticationDetails({
          Username: username,
          Password: password,
        });

        const userData = {
          Username: username,
          Pool: userPool,
          Storage: cookieStorage,
        };

        const userLogin = new CognitoUser(userData);

        userLogin.authenticateUser(authenticationDetails, {
          onSuccess: onLoginSuccess,
          onFailure: onLoginFailure,
        });
      } catch (err) {
        setError({
          error: "login failed",
          error_description: err as string,
        });
        log(DataDogLogTypes.ERROR, "Login failure", err);
        setIsLoading(false);
      }
    },
    [isPasswordReset, setSession, userPool, history]
  );

  const switchOrg = useCallback(
    async (orgId: string) => {
      const cognitoUser = userPool?.getCurrentUser();

      await setSession({ orgId, cognitoUser });
      history.push("/home");
    },
    [setSession, history, userPool]
  );

  return (
    <CognitoContext.Provider
      value={{
        user,
        users,
        isOrgAdmin,
        userPermissions,
        error,
        organisationId,
        isAuthenticated,
        isLoading,
        isPasswordReset,
        isSuperAdmin,
        hasMultipleOrgs,
        profileImage,
        isBlessedSuperAdmin: isBlessedSuperAdmin ?? false,
        confirmResetVerificationCode,
        setOrganisationId,
        switchOrg,
        resetPassword,
        login,
        handleLogin,
        isSessionValid,
        getAccessToken,
        logout,
        changePassword,
      }}
    >
      {children}
    </CognitoContext.Provider>
  );
};

export default CognitoProvider;
