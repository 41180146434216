import React from "react";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import styles from "./styles";

interface ILandingBackgroundProps {
  url?: string;
}

const LandingBackground = ({ url }: ILandingBackgroundProps) => {
  useWindowSize();
  const isMobile = getIsMobile();

  if (isMobile) return null;

  return (
    <>
      <div css={styles.fullScreenBackground(url || "")} />
    </>
  );
};

export default LandingBackground;
