import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  whitelistProjectSelect: css`
    width: 100% !important;

    .ant-select-arrow {
      span {
        color: #adadad !important;
      }
    }

    .ant-select {
      width: 100%;

      .ant-select-selector {
        background: ${theme.colors.background.veryDarkGray};
        color: #adadad !important;
        border: 1px solid ${theme.colors.secondary.mediumGrey} !important;
        font-size: 0.875rem !important;
        line-height: 1.125rem !important;

        .ant-select-selection-overflow {
          .ant-select-selection-item {
            height: 30px !important;
            line-height: 16px !important;
            margin-left: 0.5rem;
            color: ${theme.colors.white} !important;
            border: 1px solid ${theme.colors.white} !important;
            background: none;
            padding-top: 0 !important;
            align-items: center;
            border-radius: 8px !important;
            margin-right: 0.5rem;
          }
        }

        .ant-select-selection-search-input {
          margin-top: 0 !important;
        }

        .ant-select-selection-item-remove {
          span {
            color: ${theme.colors.white} !important;
          }

          span :hover {
            color: ${theme.colors.functional.brightOrange} !important;
          }
        }
      }

      .ant-select-dropdown {
        border-radius: 4px !important;
        z-index: 9999 !important;
      }

      .ant-select-dropdown .ant-select-item-option,
      .ant-select-dropdown .ant-select-item-option-content {
        background: black;
        color: white;
        z-index: 99999 !important;
      }
    }

    .ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background: ${theme.colors.background.veryDarkGray};
    }
  `,
  customDropdown: css`
    border: 1px solid ${theme.colors.secondary.mediumGrey} !important;

    div {
      font-size: 1rem !important;
      color: white !important;

      &:hover {
        background-color: ${theme.colors.background.veryDarkGray} !important;
      }
    }

    .ant-select-selection-overflow {
      margin-left: 0.5rem;
    }

    .ant-select-item-option,
    .ant-select-item-option-content {
      transition: all 0.25s ease-in-out;
    }

    .ant-select-item-option-content {
      background: ${theme.colors.background.veryDarkGray};
    }

    .ant-select-item,
    .ant-select-item-option,
    .ant-select-item-option-selected {
      display: flex !important;
      align-items: center;
    }

    .ant-select-item,
    .ant-select-item-option {
      display: flex !important;
      align-items: center;
    }

    .ant-select-item-option-state {
      user-select: none;
      border-radius: 4px;
      height: 22px;
      width: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: red !important;
    }

    .ant-select-item-option {
      background: ${theme.colors.background.veryDarkGray};

      &:hover {
        &,
        .ant-select-item-option-content {
          background: ${theme.colors.background.extraDarkGray} !important;
        }
      }
    }
  `,
  antTooltip: css`
    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: ${theme.colors.white} !important;
    }

    .ant-tooltip-inner {
      background: ${theme.colors.white};
      color: ${theme.colors.black};
    }
  `,
  tooltipContainer: css`
    display: inline-block;
  `,
  disabled: css`
    pointer-events: none;
  `,
  teamName: css`
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: ${theme.colors.white} !important;
  `,
  teamEmail: css`
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${theme.colors.secondary.blueOnBlackShadow};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  teamMembersTable: css`
    font-family: ${theme.fonts.sofiaPro};
    background-color: ${theme.colors.background.basaltBlack};

    .ant-table-cell-row-hover {
      background: ${theme.colors.background.veryDarkGray} !important;
    }

    tbody {
      tr:hover > td {
        background: none !important;
        cursor: pointer;
      }
    }

    .ant-table-thead > tr > th {
      border-bottom: 1px solid ${theme.colors.background.veryDarkGray};
    }

    .delete-team-member-button {
      text-align: right;
    }

    .team-invite-align-right {
      text-align: right;
    }

    .team-invite-align-left {
      text-align: left;
    }

    .team-invite-align-center {
      text-align: center;
    }

    .ant-table {
      background: ${theme.colors.background.basaltBlack};
    }

    button span {
      font-size: 14px;
      font-family: ${theme.fonts.sofiaPro};
    }

    .ant-table table {
      box-shadow: none !important;
      background-color: ${theme.colors.background.basaltBlack};
    }

    .ant-table-content table {
      padding: 0 !important;
      box-shadow: none !important;
    }

    .ant-table-thead tr th:first-of-type,
    .ant-table-tbody tr td:first-of-type {
      padding-left: 24px;
      padding-right: 16px;
    }

    .ant-table-thead .ant-table-cell {
      background: ${theme.colors.background.basaltBlack};
      padding-top: 14px;
      padding-bottom: 14px;
      line-height: 1.25rem;
      font-weight: 700;
      color: ${theme.colors.white} !important;
    }

    .ant-table-tbody .ant-table-cell {
      padding-top: 16px;
      padding-bottom: 16px;
      color: ${theme.colors.white} !important;
      border-bottom: 1px solid ${theme.colors.background.veryDarkGray} !important;
    }

    .ant-table-tbody button {
      padding: 0;
    }

    .ant-pagination {
      background: ${theme.colors.background.basaltBlack} !important;

      .ant-pagination-item {
        font-weight: 500 !important;
        background: ${theme.colors.white} !important;
        border: transparent !important;
        border-radius: 1rem !important;

        a {
          color: ${theme.colors.black} !important;
        }
      }

      .ant-pagination-item.ant-pagination-item-active {
        margin: 0 0.6rem !important;
      }

      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        span {
          color: ${theme.colors.white};
        }
        border: none !important;
        background: none !important;
      }

      .ant-pagination-jump-prev
        .ant-pagination-item-container
        .ant-pagination-item-link-icon,
      .ant-pagination-jump-next
        .ant-pagination-item-container
        .ant-pagination-item-link-icon {
        color: ${theme.colors.white};
      }

      .ant-pagination-jump-prev
        .ant-pagination-item-container
        .ant-pagination-item-ellipsis,
      .ant-pagination-jump-next
        .ant-pagination-item-container
        .ant-pagination-item-ellipsis {
        color: ${theme.colors.white};
      }

      .ant-pagination-item:not(.ant-pagination-item-active) {
        font-weight: 500 !important;
        background: none !important;

        a {
          margin: 0 0.6rem !important;
          min-width: 16px !important;
          height: 16px !important;
          color: ${theme.colors.white} !important;
          border: none !important;
          border-radius: 1rem !important;
        }
      }
    }
  `,
  invitesTable: css`
    font-family: ${theme.fonts.sofiaPro};
    padding-bottom: 4rem;

    .ant-table-tbody > tr > td {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;

      :hover {
        background-color: ${theme.colors.black} !important;
      }
    }

    .ant-table-tbody > tr:hover > td {
      background-color: ${theme.colors.black} !important;
    }
  `,
  inviteMembersModal: css`
    .ant-select-open {
      z-index: 9999;
    }

    .ant-select-dropdown {
      z-index: 9999;
    }

    .ant-modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: ${theme.colors.background.basaltBlack} !important;
    }

    .ant-modal-content {
      width: 600px;
      height: 600px;
    }

    .ant-modal-close-x {
      svg {
        g {
          stroke: ${theme.colors.white} !important;
        }
      }
    }
  `,
};
