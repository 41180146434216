import React, { ReactElement } from "react";
import { Form } from "antd";
import { Input, ValidationMessage } from "@songtradr/component-library";
import appStyles from "src/app/styles";
import FloatingLabel from "../floating-label";
import { IFloatingLabelInputProps } from "./interfaces";
import styles from "./styles";

const FloatingLabelInput = (props: IFloatingLabelInputProps): ReactElement => {
  const { required, ...restProps } = props;
  const { value, label, helpMessage, name, className, hasError } = restProps;

  return (
    <div
      css={[appStyles.inputAutofillOverride, styles.floatingLabelInput]}
      className={className}
    >
      <FloatingLabel value={value} label={label}>
        <Form.Item
          name={name}
          validateTrigger="onChange"
          rules={[
            {
              required,
              whitespace: true,
              message: (
                <ValidationMessage type="error">
                  {helpMessage}
                </ValidationMessage>
              ),
            },
          ]}
        >
          <Input {...restProps} css={hasError && styles.floatingLabelError} />
        </Form.Item>
      </FloatingLabel>
    </div>
  );
};

export default FloatingLabelInput;
