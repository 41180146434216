import { ReactElement } from "react";

const DenyIcon = (): ReactElement => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="deny-icon"
    >
      <rect width="48" height="48" rx="8" />
      <path d="M28.4328 17.1191L30.8809 19.5672C31.107 19.7933 31.1061 20.158 30.8809 20.3833L27.2642 24L30.8809 27.6167C31.107 27.8428 31.1061 28.2075 30.8809 28.4328L28.4328 30.8809C28.2075 31.1062 27.8428 31.107 27.6167 30.8809L24 27.2642L20.3833 30.8809C20.158 31.1062 19.7933 31.107 19.5672 30.8809L17.1191 28.4328C16.8938 28.2076 16.8938 27.842 17.1191 27.6167L20.7358 24L17.1191 20.3833C16.8938 20.158 16.8938 19.7924 17.1191 19.5672L19.5672 17.1191C19.7924 16.8938 20.158 16.8938 20.3833 17.1191L24 20.7358L27.6167 17.1191C27.8419 16.8938 28.2075 16.8938 28.4328 17.1191Z" />
    </svg>
  );
};
export default DenyIcon;
