import { css } from "@emotion/react";
import theme from "src/theme";
import { mobileNavHeight } from "src/components/navigation-bar/styles";

export default {
  button: css`
    border: 0;
    outline: none;
    appearance: none;
    -webkit-appearance: none;
    background: transparent;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 1;
  `,
  closeIcon: css`
    transform: scale(0.8) rotate(45deg);
  `,
  invisible: css`
    pointer-events: none;
    opacity: 0;
  `,
  visible: css`
    pointer-events: auto;
    opacity: 1;
  `,
  menuContainer: css`
    transition: all 0.25s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  `,
  menuItemContainer: (isOpen: boolean) => css`
    transform: ${isOpen ? `translatey(0) ` : `translatey(10px) `};
    transition: all 0.25s ease-in-out;
    width: 100%;
    max-width: 390px;
    padding: 1.5rem;
    overflow-y: auto;
    background: ${theme.colors.black};
    position: fixed;
    top: ${mobileNavHeight};
    right: 0;
    bottom: 0;
  `,
  title: css`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 3.3px;
    margin-bottom: 2rem;
    color: ${theme.colors.white};
  `,
  link: css`
    margin-bottom: 2rem;
    padding: 0.5rem 0;
    line-height: 28px;
    font-size: 24px;
    font-weight: 250;
    color: ${theme.colors.white};
    display: inline-block;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: ${theme.colors.functional.brightOrange};
      text-decoration: none;
    }
  `,
  smallLink: css`
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 3.3px;
    margin-bottom: 2rem;
    color: ${theme.colors.white};
    display: block;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: ${theme.colors.functional.brightOrange};
      text-decoration: none;
    }
  `,
  smallestLink: css`
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 1rem;
    color: ${theme.colors.white};
    display: block;

    &:hover,
    &:focus,
    &:active,
    &.active {
      color: ${theme.colors.functional.brightOrange};
      text-decoration: none;
    }
  `,
  section: css`
    border-bottom: 1px solid ${theme.colors.background.darkGray100};
    background: transparent;
    margin-bottom: 2rem;
  `,
  logoutBtnContainer: css`
    margin-top: 2rem;
  `,
  logo: css`
    height: 104px;
    max-height: 104px;
    max-width: 100%;
    margin-bottom: 2rem;
  `,
};
