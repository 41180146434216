import { IOrganisation } from "src/interfaces/organisation";
import config from "src/config";
import { useMemo, useState } from "react";

type TOrgArrays = {
  onlyServicingOrgs: IOrganisation[];
  excludedServicingOrgs: IOrganisation[];
};

const getOrgArrays = (orgDropdownData: IOrganisation[]): TOrgArrays => {
  const { servicingOrganisations } = config;
  const excludedServicingOrgs: IOrganisation[] = [];
  const onlyServicingOrgs: IOrganisation[] = [];

  if (!servicingOrganisations) {
    return { excludedServicingOrgs, onlyServicingOrgs };
  }

  const servicingOrgIds = servicingOrganisations.map((org) => org.value);

  orgDropdownData.forEach((org) => {
    if (!org.id) {
      return;
    }

    if (servicingOrgIds.includes(org.id)) {
      onlyServicingOrgs.push(org);
      return;
    }

    excludedServicingOrgs.push(org);
  });

  return { excludedServicingOrgs, onlyServicingOrgs };
};

const useServicingOrgDropdown = (orgDropdownData?: IOrganisation[]) => {
  const [
    orgDropdownDataFiltered,
    setOrgDropdownDataFiltered,
  ] = useState<TOrgArrays>({
    excludedServicingOrgs: [],
    onlyServicingOrgs: [],
  });

  useMemo(() => {
    if (!orgDropdownData) {
      return;
    }

    setOrgDropdownDataFiltered(getOrgArrays(orgDropdownData));
  }, [orgDropdownData]);

  return orgDropdownDataFiltered;
};

export default useServicingOrgDropdown;
