import { ReactElement } from "react";

const PieChart = (): ReactElement => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 240 240"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M240 120C240 145.342 231.977 170.033 217.082 190.534C202.187 211.036 181.183 226.296 157.082 234.127C132.981 241.958 107.019 241.958 82.918 234.127C58.8167 226.296 37.8133 211.036 22.918 190.534L120 120H240Z"
        fill="#FF4E00"
      />
      <path
        d="M68.0864 228.19C49.8633 219.445 34.1441 206.24 22.3875 189.798L120 120L68.0864 228.19Z"
        fill="#B93A00"
      />
      <path
        d="M82.4513 6.02591C100.499 0.0801249 119.701 -1.49477 138.476 1.43084C157.251 4.35645 175.063 11.6989 190.446 22.8539C205.829 34.009 218.343 48.6576 226.958 65.5943C235.573 82.5309 240.043 101.271 240 120.273L120 120L82.4513 6.02591Z"
        fill="#B6CED6"
      />
      <path
        d="M23.2171 190.944C12.0214 175.671 4.59933 157.965 1.55783 139.273C-1.48367 120.582 -0.0582777 101.436 5.71746 83.4008C11.4932 65.3658 21.4553 48.9538 34.7892 35.5066C48.1232 22.0595 64.4504 11.959 82.4359 6.03098L120 120L23.2171 190.944Z"
        fill="#6AA5B4"
      />
    </svg>
  );
};
export default PieChart;
