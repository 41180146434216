import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  input: css`
    max-width: 497px;
    background: ${theme.colors.background.white};
    border: 1px solid ${theme.colors.background.greyRoom};
    border-radius: 8px;
    margin-bottom: 24px;
    margin-right: 16px;
    .ant-input-group-addon:first-child {
      border: unset !important;
      background-color: transparent;
      background-repeat: no-repeat;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      padding: 6px 0 0 10px;
    }

    .ant-input {
      border: unset !important;
    }

    .ant-input::placeholder {
      color: ${theme.colors.secondary.blueOnBlackShadow};
      opacity: 1; /* Firefox */
    }
  `,
  tracksTitle: css`
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: ${theme.colors.secondary.black};
    margin-bottom: 32px;
  `,
  titleContainer: css`
    display: flex;
    white-space: nowrap;
    align-items: center;
    font-weight: bold;
  `,
  libraryTrackTableContainer: css`
    .ant-table-tbody > tr > td {
      max-width: 300px !important;
    }
    .ant-table-container .ant-table-content table {
      padding: 0 !important;
      border-radius: 10px 10px 0 0 !important;
      thead {
        tr {
          th {
            background: ${theme.colors.secondary.bigSky} !important;
            border: none !important;
            color: ${theme.colors.secondary.black} !important;
            padding-left: 16px !important;
            padding-right: 16px !important;
            div {
              display: flex;
              align-items: center;
            }
            &::before {
              content: none !important;
            }
            &:first-child {
              border-right: 2px solid ${theme.colors.background.greyRoom} !important;
            }
            &:last-child {
              border-left: 2px solid ${theme.colors.background.greyRoom} !important;
            }
          }
        }
      }
      tbody tr td {
        padding-left: 16px !important;
        padding-right: 16px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:first-child {
          border-right: 2px solid ${theme.colors.background.greyRoom} !important;
        }
        &:last-child {
          border-left: 2px solid ${theme.colors.background.greyRoom} !important;
        }
      }
    }
    @media (min-width: 768px) {
      table {
        padding: 0 !important;
      }
    }
    .ant-pagination {
      margin-bottom: 1.375rem;

      .ant-pagination-item-link {
        border: none;
      }
      .ant-pagination-item-active {
        background-color: ${theme.colors.primary.eiffelBlue};
        a {
          color: ${theme.colors.white} !important;
          padding-top: 0.0625rem;
        }
      }
      .ant-pagination-item {
        border: none;
        border-radius: 1rem !important;
      }
      .ant-pagination-prev,
      .ant-pagination-next {
        color: ${theme.colors.secondary.purple} !important;
      }
      .ant-pagination-disabled {
        color: ${theme.colors.background.lightGray} !important;
      }
    }
  `,
  sortButton: css`
    display: flex;
    margin-right: 0;
    &:hover {
      background-color: transparent;
    }
    &:focus-visible {
      outline: none;
    }
  `,
  tableDataText: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.colors.white};
    max-width: 350px;
  `,
  tableDataSubText: css`
    font-size: 0.875rem;
    color: ${theme.colors.functional.gray65};
    margin-top: -0.3125rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  `,
  paginationCount: css`
    font-size: 0.875rem;
    text-align: center;
    margin-bottom: 3.5rem;
    color: ${theme.colors.white};
  `,
  primaryButton: css`
    border: 1px solid ${theme.colors.background.greyRoom};
    border-radius: 8px !important;
    background-color: ${theme.colors.background.white};
    padding: 12px 24px;
    margin-bottom: 24px;
    height: 48px !important;

    span {
      font-family: ${theme.fonts.sofiaPro};
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
      color: ${theme.colors.secondary.black};
    }

    &:hover,
    &:focus {
      border: 1px solid ${theme.colors.background.greyRoom};
      background-color: ${theme.colors.background.fadeToGrey};
    }
  `,
  actionsContainer: css`
    display: flex;
    flex: 1;
    justify-content: space-between;
  `,
  moreInfoButton: css`
    display: flex;
    font-size: 28px;
    pointer-events: visible;
    svg {
      circle {
        fill: black;
      }
    }
    :hover {
      background-color: ${theme.colors.background.fadeToGrey};
      border-radius: 1.5625rem;
      box-sizing: border-box;
      svg {
        circle {
          fill: black;
        }

        path {
          stroke: white;
        }
      }
    }
  `,
  dropdownMenu: css`
    border-radius: 0.2rem;
    box-shadow: 0 0 0.313rem 0.125rem rgb(0 0 0 / 12%),
      0 0.375rem 1rem 0 rgb(0 0 0 / 8%), 0 0.563rem 1.75rem rgb(0 0 0 / 5%);

    .ant-dropdown-menu-item,
    .ant-dropdown-menu-submenu-title {
      padding: 0;
    }

    ul {
      list-style-type: none;
      padding-inline-start: 0;
      margin-bottom: 0;
      padding-right: 1rem;
      padding-left: 0.5rem;
      font-family: ${theme.fonts.sofiaPro};
      background-color: ${theme.colors.background.gray};

      li {
        padding: 0.2rem;
        cursor: pointer;

        :hover {
          font-weight: bold;
        }
      }
    }

    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
      background-color: ${theme.colors.background.gray};
    }
    min-width: 190px;
    max-width: 190px;
  `,
  actionButton: css`
    display: inline-block;
    border: none;
    margin: 0;
    padding: 0.375rem 1.125rem;
    text-decoration: none;
    background-color: ${theme.colors.background.gray};
    cursor: pointer;
    width: 100%;
    text-align: left;
    font-size: 12px;

    :hover {
      font-weight: bold;
    }
  `,
  mr5: css`
    margin-right: 5px;
  `,
  moreInfo: css`
    position: absolute;
    right: 0;
    bottom: 0;
  `,
  customMediaButton: css`
    width: 2rem;
    min-width: 2rem;
    height: 2rem;

    :hover {
      background-color: ${theme.colors.secondary.greyRoom};
    }
  `,
  moreButton: css`
    height: 48px;
    display: flex;
    margin-right: 0;
    &:hover {
      background-color: transparent;
      svg path {
        fill: ${theme.colors.functional.brightOrangeHover};
      }
    }
    &:focus-visible {
      outline: none;
    }
  `,
};
