import { UploadFile } from "antd/lib/upload/interface";
import { saveAs } from "file-saver";
import downloadFile from "src/api/attachments/download-file";
import getPresignedDownloadUrl from "src/api/attachments/get-presigned-download-url";

export const handleFileDownload = async (
  file: UploadFile,
  accessToken: string,
  projectId: string,
  organisationId: string
): Promise<boolean> => {
  try {
    const getPresignedDownloadURL = await getPresignedDownloadUrl({
      fileName: file.name,
      attachmentId: file.uid,
      projectId,
      organisationId,
      accessToken,
    });
    if (!file.type) throw new Error("No file type detected");
    const fileToSave: Blob = await downloadFile(
      getPresignedDownloadURL.data.url,
      file.type
    );
    saveAs(fileToSave, file.name);

    return true;
  } catch (error) {
    return false;
  }
};

export default handleFileDownload;
