import React, { useMemo } from "react";
import SectionTitleAndSubtitle from "src/pages/projects/client-project-view/components/section-title-and-subtitle";
import { Swiper, SwiperSlide } from "swiper/react";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import styles from "./styles";
import getServicesData from "./utils";
import ServiceTile from "../service-tile";
import CarouselControllers from "../services-carousel-controllers";

const ServicesSection = () => {
  const services = useMemo(() => {
    return getServicesData();
  }, []);

  useWindowSize();
  const isMobile = getIsMobile();

  return (
    <div css={styles.servicesSectionContainer}>
      <div css={styles.servicesHeaderContainer}>
        <SectionTitleAndSubtitle
          title="Expert services"
          subtitle="Elevate your sounds with our additional services"
          titleTranslationPrefix=""
        />
        <CarouselControllers />
      </div>

      <div css={styles.carouselContainer}>
        <Swiper
          className="carousel-swiper"
          direction="horizontal"
          mousewheel={{ forceToAxis: true }}
          pagination={{ clickable: false }}
          navigation={{
            nextEl: ".services-next-button",
            prevEl: ".services-previous-button",
          }}
          slidesPerView={isMobile ? 1.15 : "auto"}
          spaceBetween={isMobile ? 24 : 48}
        >
          {services.map((service) => {
            return (
              <SwiperSlide className="slideSwipe">
                <ServiceTile
                  key={service.title}
                  title={service.title}
                  description={service.description}
                  link={service.link}
                  image={service.image}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default ServicesSection;
