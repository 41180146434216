import axios, { AxiosResponse } from "axios";
import { getAuthHeader } from "../headers";
import { patchOrganisationMemberUrl } from "../organisation-members-url-helper";

export interface IImgFormData {
  Images: {
    ProfileImage?: File | null;
  };
}

const updateProfileImage = async (
  orgId: string,
  userId: string,
  accessToken: string,
  request: IImgFormData
): Promise<AxiosResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken) },
  };

  return axios.patch(
    `${patchOrganisationMemberUrl(orgId, userId)}/logo`,
    request,
    options
  );
};

export default updateProfileImage;
