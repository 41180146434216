import { LicenseFixedDurations, MediaTypes } from "src/constants";
import {
  IFinalTrack,
  IProjectAttachment,
  IService,
} from "src/pages/projects/client-project-view/interfaces";
import { IProjectClient, ProjectStatus } from "../../../interfaces";

export enum ProjectSortType {
  lastUpdated = "lastUpdated",
  name = "name",
  status = "status",
  termEndDate = "termEndDate",
  contractEndDate = "contractEndDate",
  ClientOrganisationName = "ClientOrganisationName",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface IProjectTableSort {
  sortBy: ProjectSortType;
  order: SortOrder;
}

export interface IProjectClients {
  id: string;
  name: string;
}

export interface IClientOrgProjectProps {
  contractEndDate: string;
  contractStartDate: string;
  clientReferenceNo: string;
  id: string;
  lastUpdated: string;
  lastUpdatedBy: string;
  lead: {
    email: string;
    id: string;
    name: string;
  };
  music: {
    finalTrack: {
      title: string;
    };
  };
  name: string;
  organisationId: string;
  product: string;
  projectType: string;
  sequenceNo: string;
  sharedWithClient: boolean;
  status: string;
  terms: ITerm[];
  client?: IProjectClient;
  projectRegion?: string;
  campaignName?: string;
  tracks?: IFinalTrack[];
  libraryTracks?: [];
}

export interface ITerm {
  mediaType: MediaTypes[];
  customMediaType: string[];
  territories: {
    worldwide: boolean;
    excludedCountries: string[];
    includedCountries: string[];
  };
  startDate: string;
  endDate: string;
  fixedDuration: LicenseFixedDurations;
}

export interface IClientProjectRowsProps {
  id: string;
  projectId: string;
  name: string;
  trackName?: string;
  clientRef?: string;
  startDate?: string;
  duration?: string;
  endDate?: string;
  territories?: JSX.Element | number;
  media?: JSX.Element | string[] | string;
  campaignName?: string;
  tracks?: IFinalTrack[];
  trackCount?: number;
  brandName?: string;
  status?: string;
}

export interface IClientOrgProjectSearchResponse {
  totalPages: number;
  totalResults: number;
  currentPage: number;
  results: IClientOrgProjectProps[];
}

export enum DrawerTypes {
  sortProjects = "sortProjects",
  projectInfo = "projectInfo",
}

export interface IProjectSearchResponse {
  totalPages: number;
  totalResults: number;
  currentPage: number;
  results: IProjectProps[];
}

export interface IProjectProps {
  attachments?: IProjectAttachment[];
  campaignName?: string;
  id: string;
  sequenceNo: string;
  organisationId: string;
  name: string;
  projectType?: string;
  lead?: {
    id: string;
    name: string;
  };
  status: ProjectStatus;
  lastUpdated: string;
  lastUpdatedBy: string;
  contractStartDate?: string;
  jobListingDate?: string;
  contractEndDate?: string;
  product?: string;
  client?: IProjectClient;
  clientReferenceNo?: string;
  projectRegion?: string;
  services?: IService[];
}
