import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  default: css`
    .ant-modal-content {
      background-color: ${theme.colors.background.basaltBlack};
      height: 80%;
      color: ${theme.colors.white};
      width: 680px;
      border-radius: 16px;
    }

    .confirmationModalCancelButton,
    .confirmationModalConfirmButton {
      font-family: ${theme.fonts.sofiaPro};
    }
    .confirmationModalConfirmButton {
      font-family: ${theme.fonts.sofiaPro};
      background-color: ${theme.colors.functional.brightOrange};

      :hover {
        background-color: ${theme.colors.functional.brightOrange} !important;
        opacity: 0.7;
      }
      :focus {
        border: 3px solid ${theme.colors.white} !important;
        background-color: ${theme.colors.functional.brightOrange} !important;
        opacity: 0.7;
      }
      :active {
        border: 3px solid ${theme.colors.white} !important;
        background-color: ${theme.colors.functional.brightOrange} !important;
        box-shadow: none;
      }
    }
    .confirmationModalCancelButton {
      margin-right: 16px;
    }
    .ant-modal-footer {
      border-top: none;
    }
    .ant-modal-body {
      padding: 2rem !important;
      border-radius: 16px;
    }
    .close-icon {
      margin-top: 1rem;
      margin-right: 0.3rem;
    }
    .ant-modal-close-icon {
      color: ${theme.colors.white};
    }
    .ant-modal-close g {
      stroke: ${theme.colors.white};
    }
  `,
  centerButton: css`
    justify-content: center;
  `,
  modalTitle: css`
    font-size: 2rem !important;
    font-weight: 250 !important;
    line-height: 2.5rem !important;
    font-family: ${theme.fonts.sofiaPro};
    letter-spacing: -2%;
    color: ${theme.colors.white} !important;
    margin-bottom: 2rem !important;
  `,
  footer: css`
    border-top: none;
    display: flex;
    justify-content: flex-end;
    flex-flow: row nowrap;
  `,
  note: css`
    font-size: 0.75rem;
    color: ${theme.colors.background.darkGray};
    padding-left: 1rem;
    padding-right: 3.5938rem;
    margin-bottom: 1.5rem !important;
  `,
};
