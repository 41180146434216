import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: css`
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
  `,
  modal: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    h2 {
      color: ${theme.colors.white};
      font-size: 2rem;
      font-weight: 250;
      line-height: 2.5rem;
      letter-spacing: -0.02em;
      margin-bottom: 1rem;
    }
  `,
};
