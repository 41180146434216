import axios, { AxiosResponse } from "axios";
import buildOrganisationsBaseUrl from "src/api/base-url-helper";
import { getAuthHeader, getAxiosOptions } from "../../headers";
import getJsonContentHeader from "../../headers/get-json-content-header";

async function deleteOrganisationMember(
  accessToken: string,
  organisationId: string,
  userId: string
): Promise<AxiosResponse> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };
  const baseURL = buildOrganisationsBaseUrl(organisationId);
  const response = await axios.delete(`${baseURL}/members/${userId}`, options);
  if (response.status !== 200) {
    throw new Error(
      `Error deleting organisation member. ${response.status}: ${response.statusText}`
    );
  }
  return response;
}

export default deleteOrganisationMember;
