import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: css`
    position: relative;
    background: ${theme.colors.background.landingDarkGray};
    color: ${theme.colors.white};
  `,
  innerContainer: css`
    position: relative;
    z-index: 1;
    max-width: 1552px;
    padding: 4.5rem 24px 1rem;
    margin: auto;
    height: 100%;

    .ant-form-item-label > label {
      font-size: 1rem;
    }
  `,
  animationContainer: css`
    position: absolute;
    top: 100px;
    right: 0;
  `,
};
