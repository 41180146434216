import { ReactElement } from "react";
import { Row } from "antd";
import { IOrganisationMemberProps } from "src/pages/team/interfaces";
import ProfileForm from "./components/user-profile-form";
import styles from "./styles";

interface IProps {
  userProfile: IOrganisationMemberProps | undefined;
}

const RolesAndPermissionsProfile = ({
  userProfile,
}: IProps): ReactElement | null => {
  if (!userProfile) {
    return <div />;
  }
  return (
    <Row>
      <div css={styles.formWrapper}>
        <ProfileForm profileData={userProfile} />
      </div>
    </Row>
  );
};

export default RolesAndPermissionsProfile;
