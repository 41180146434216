import { ReactElement } from "react";
import styles from "./styles";

const Projects = (): ReactElement => {
  return (
    <div css={styles.projects}>
      <svg
        id="Artwork"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 252 242"
      >
        <defs>
          <linearGradient
            id="projects-linear-gradient"
            x1="49.2"
            y1="68.89"
            x2="144.36"
            y2="68.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2e3030" />
            <stop offset="1" stopColor="#161818" stopOpacity=".8" />
          </linearGradient>
          <linearGradient
            id="projects-linear-gradient-2"
            x1="45.89"
            y1="68.89"
            x2="74.46"
            y2="68.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#323434" />
            <stop offset="1" stopColor="#323434" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="projects-linear-gradient-3"
            x1="87.8"
            y1="68.89"
            x2="221.5"
            y2="68.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4a4c4c" />
            <stop offset="1" stopColor="#4a4c4c" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="projects-linear-gradient-4"
            x1="86.8"
            y1="68.89"
            x2="222.5"
            y2="68.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4f5050" />
            <stop offset="1" stopColor="#323434" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="projects-linear-gradient-5"
            x1="116.05"
            y1="68.89"
            x2="193.26"
            y2="68.89"
            gradientTransform="translate(85.77 223.54) rotate(-90)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#666868" />
            <stop offset="1" stopColor="#363838" stopOpacity=".5" />
          </linearGradient>
          <linearGradient
            id="projects-linear-gradient-6"
            x1="46.89"
            y1="111.45"
            x2="73.46"
            y2="111.45"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ec5734" />
            <stop offset="1" stopColor="#ec5734" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="projects-linear-gradient-7"
            x1="50.08"
            y1="101.36"
            x2="70.27"
            y2="121.55"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ec5734" />
            <stop
              offset=".06"
              stopColor="rgba(236,87,52,.92)"
              stopOpacity=".92"
            />
            <stop
              offset=".25"
              stopColor="rgba(236,87,52,.64)"
              stopOpacity=".64"
            />
            <stop
              offset=".44"
              stopColor="rgba(236,87,52,.41)"
              stopOpacity=".41"
            />
            <stop
              offset=".62"
              stopColor="rgba(236,87,52,.24)"
              stopOpacity=".24"
            />
            <stop
              offset=".77"
              stopColor="rgba(236,87,52,.11)"
              stopOpacity=".11"
            />
            <stop
              offset=".91"
              stopColor="rgba(236,87,52,.03)"
              stopOpacity=".03"
            />
            <stop offset="1" stopColor="#ec5734" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="projects-linear-gradient-8"
            x1="93.35"
            y1="111.45"
            x2="213.33"
            y2="111.45"
            xlinkHref="#projects-linear-gradient-3"
          />
          <linearGradient
            id="projects-linear-gradient-9"
            y1="111.45"
            y2="111.45"
            xlinkHref="#projects-linear-gradient-4"
          />
          <linearGradient
            id="projects-linear-gradient-10"
            x1="116.05"
            y1="111.45"
            x2="193.26"
            y2="111.45"
            xlinkHref="#projects-linear-gradient-6"
          />
          <linearGradient
            id="projects-linear-gradient-11"
            y1="154.02"
            y2="154.02"
            xlinkHref="#projects-linear-gradient"
          />
          <linearGradient
            id="projects-linear-gradient-12"
            y1="154.02"
            y2="154.02"
            xlinkHref="#projects-linear-gradient-2"
          />
          <linearGradient
            id="projects-linear-gradient-13"
            x1="95.3"
            y1="154.02"
            x2="210.84"
            y2="154.02"
            xlinkHref="#projects-linear-gradient-3"
          />
          <linearGradient
            id="projects-linear-gradient-14"
            y1="154.02"
            y2="154.02"
            xlinkHref="#projects-linear-gradient-4"
          />
          <linearGradient
            id="projects-linear-gradient-15"
            y1="154.02"
            y2="154.02"
            gradientTransform="translate(.63 308.68) rotate(-90)"
            xlinkHref="#projects-linear-gradient-5"
          />
        </defs>
        <circle className="cls-2" cx="60.18" cy="68.89" r="13.28" />
        <path
          className="cls-9"
          d="M60.18,55.6c7.34,0,13.28,5.95,13.28,13.28s-5.95,13.28-13.28,13.28-13.28-5.95-13.28-13.28,5.95-13.28,13.28-13.28M60.18,54.6c-7.88,0-14.28,6.41-14.28,14.28s6.41,14.28,14.28,14.28,14.28-6.41,14.28-14.28-6.41-14.28-14.28-14.28h0Z"
        />
        <rect
          className="cls-12"
          x="87.8"
          y="56.9"
          width="133.7"
          height="23.97"
          rx="5"
          ry="5"
        />
        <path
          className="cls-16"
          d="M216.5,56.9c2.76,0,5,2.24,5,5v13.97c0,2.76-2.24,5-5,5h-123.7c-2.76,0-5-2.24-5-5v-13.97c0-2.76,2.24-5,5-5h123.7M216.5,55.9h-123.7c-3.31,0-6,2.69-6,6v13.97c0,3.31,2.69,6,6,6h123.7c3.31,0,6-2.69,6-6v-13.97c0-3.31-2.69-6-6-6h0Z"
        />
        <rect
          className="cls-6"
          x="152.67"
          y="30.28"
          width="3.97"
          height="77.21"
          rx="1.98"
          ry="1.98"
          transform="translate(223.54 -85.77) rotate(90)"
        />
        <line className="cls-17" x1="60.18" y1="62.19" x2="60.18" y2="71.08" />
        <line className="cls-17" x1="60.18" y1="75.58" x2="60.18" y2="75.58" />
        <rect className="cls-10" x="16.97" y="47.1" width="218.06" height="1" />
        <rect
          className="cls-10"
          x="16.97"
          y="89.67"
          width="218.06"
          height="1"
        />
        <rect
          className="cls-10"
          x="16.97"
          y="132.24"
          width="218.06"
          height="1"
        />
        <rect
          className="cls-10"
          x="16.97"
          y="174.81"
          width="218.06"
          height="1"
        />
        <circle className="cls-3" cx="60.18" cy="111.45" r="13.28" />
        <path
          className="cls-11"
          d="M60.18,98.17c7.34,0,13.28,5.95,13.28,13.28s-5.95,13.28-13.28,13.28-13.28-5.95-13.28-13.28,5.95-13.28,13.28-13.28M60.18,97.17c-7.88,0-14.28,6.41-14.28,14.28s6.41,14.28,14.28,14.28,14.28-6.41,14.28-14.28-6.41-14.28-14.28-14.28h0Z"
        />
        <rect
          className="cls-14"
          x="87.8"
          y="99.15"
          width="133.7"
          height="24.61"
          rx="5"
          ry="5"
        />
        <path
          className="cls-13"
          d="M216.5,99.15c2.76,0,5,2.24,5,5v14.61c0,2.76-2.24,5-5,5h-123.7c-2.76,0-5-2.24-5-5v-14.61c0-2.76,2.24-5,5-5h123.7M216.5,98.15h-123.7c-3.31,0-6,2.69-6,6v14.61c0,3.31,2.69,6,6,6h123.7c3.31,0,6-2.69,6-6v-14.61c0-3.31-2.69-6-6-6h0Z"
        />
        <polyline
          className="cls-17"
          points="66.66 107.57 58.89 115.34 54.5 110.95"
        />
        <rect
          className="cls-15"
          x="116.05"
          y="109.47"
          width="77.21"
          height="3.97"
          rx="1.98"
          ry="1.98"
        />
        <circle className="cls-5" cx="60.18" cy="154.02" r="13.28" />
        <path
          className="cls-1"
          d="M60.18,140.74c7.34,0,13.28,5.95,13.28,13.28s-5.95,13.28-13.28,13.28-13.28-5.95-13.28-13.28,5.95-13.28,13.28-13.28M60.18,139.74c-7.88,0-14.28,6.41-14.28,14.28s6.41,14.28,14.28,14.28,14.28-6.41,14.28-14.28-6.41-14.28-14.28-14.28h0Z"
        />
        <rect
          className="cls-7"
          x="87.8"
          y="141.74"
          width="133.7"
          height="24.56"
          rx="5"
          ry="5"
        />
        <path
          className="cls-8"
          d="M216.5,141.74c2.76,0,5,2.24,5,5v14.56c0,2.76-2.24,5-5,5h-123.7c-2.76,0-5-2.24-5-5v-14.56c0-2.76,2.24-5,5-5h123.7M216.5,140.74h-123.7c-3.31,0-6,2.69-6,6v14.56c0,3.31,2.69,6,6,6h123.7c3.31,0,6-2.69,6-6v-14.56c0-3.31-2.69-6-6-6h0Z"
        />
        <line className="cls-17" x1="55.5" y1="154.02" x2="64.85" y2="154.02" />
        <rect
          className="cls-4"
          x="152.67"
          y="115.42"
          width="3.97"
          height="77.21"
          rx="1.98"
          ry="1.98"
          transform="translate(308.68 -.63) rotate(90)"
        />
      </svg>
    </div>
  );
};
export default Projects;
