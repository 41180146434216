import { ReactElement } from "react";

const Expand = (): ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M19.6181 7.36541H18.2014C17.9905 7.36541 17.82 7.19431 17.82 6.98398V2.56103C17.82 2.35015 17.6494 2.1796 17.4385 2.1796H11.9312C11.7204 2.1796 11.5498 2.00904 11.5498 1.79817V0.38143C11.5498 0.170554 11.7204 0 11.9312 0H19.6181C19.829 0 19.9996 0.170554 19.9996 0.38143V6.98452C19.9996 7.1954 19.8285 7.36595 19.6181 7.36595V7.36541Z" />
      <path d="M8.06833 20H0.38143C0.170554 20 0 19.8294 0 19.6186V13.0155C0 12.8046 0.171098 12.634 0.38143 12.634H1.79817C2.00904 12.634 2.1796 12.8051 2.1796 13.0155V17.4384C2.1796 17.6493 2.35015 17.8198 2.56103 17.8198H8.06833C8.2792 17.8198 8.44976 17.9904 8.44976 18.2013V19.618C8.44976 19.8289 8.2792 19.9994 8.06833 19.9994V20Z" />
      <path d="M10.0003 14.9728C8.51924 14.9728 7.09379 14.5298 5.87812 13.6922C4.69187 12.8749 3.78243 11.7388 3.24789 10.406L3.08496 10L3.24789 9.59406C3.78243 8.26123 4.69187 7.12512 5.87812 6.30777C7.09379 5.47026 8.51924 5.02725 10.0003 5.02725C11.4813 5.02725 12.9068 5.47026 14.1224 6.30777C15.3087 7.12512 16.2181 8.26123 16.7527 9.59406L16.9156 10L16.7527 10.406C16.2181 11.7388 15.3087 12.8749 14.1224 13.6922C12.9068 14.5298 11.4813 14.9728 10.0003 14.9728ZM5.45364 10C6.31622 11.7104 8.05608 12.7932 10.0003 12.7932C11.9445 12.7932 13.6838 11.7104 14.5469 10C13.6843 8.28957 11.9445 7.20685 10.0003 7.20685C8.05608 7.20685 6.31676 8.28957 5.45364 10Z" />
      <path d="M9.99951 11.5132C10.8352 11.5132 11.5127 10.8357 11.5127 10C11.5127 9.16429 10.8352 8.48682 9.99951 8.48682C9.1638 8.48682 8.48633 9.16429 8.48633 10C8.48633 10.8357 9.1638 11.5132 9.99951 11.5132Z" />
    </svg>
  );
};
export default Expand;
