import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";

const actionContainer = css`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 1.5rem;
`;
export default {
  customHeaderContentContainer: css`
    display: none;

    @media ${queries.medium} {
      display: initial;
      position: absolute;
      right: 1.3rem;
      top: 4.5rem;
    }

    @media ${queries.large} {
      right: 3.1rem;
      top: 3rem;
    }
  `,
  actions: css`
    display: flex;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-wrap: wrap;
  `,
  viewProjectButton: css`
    background: ${theme.colors.white};
    width: 138px;
    height: 48px;
    border-color: unset;
    border-radius: 8px;
  `,
  customActionPills: css`
    margin: 0 !important;
    padding: 0 !important;
    flex-wrap: wrap;
    overflow-x: unset;
  `,
  projectListingWrapper: css`
    background-color: ${theme.colors.darkCharcoal};
  `,
  tableActionWrapper: css`
    display: flex;
    justify-content: space-between;
  `,
  heading: css`
    color: ${theme.colors.white};
    font-size: 14px;
  `,
  mobileHeading: css`
    margin-top: auto;
    padding-bottom: 0.3125rem;
    padding-top: 2rem;
    font-size: 2rem !important;

    @media ${queries.medium} {
      display: none;
    }
  `,
  listingsPage: css`
    height: 100%;
    background-color: ${theme.colors.white};
  `,
  badge: css`
    background-color: ${theme.colors.functional.brightOrangeHover};
    font-size: 0.6rem;
    font-weight: bold;
    padding: 0.1rem;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
    color: ${theme.colors.white};
    border-radius: 20px;
    margin-left: 0.3rem;
  `,
  textContainer: css`
    display: block;
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
  `,
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  termsContainer: css`
    display: none;
    @media ${queries.large} {
      background-color: ${theme.colors.white};
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      box-shadow: 0 0 1px 0 var(--colors-light-gray);
      color: ${theme.colors.secondary.navyBlue};
      display: flex;
      font-size: 14px;
      height: 32px;
      justify-content: space-around;
    }
  `,
  placeholderColumn: css`
    width: 40%;
  `,
  termsColumn: css`
    align-items: center;
    display: flex;
    justify-content: center;
    @media ${queries.large} {
      @media only screen and (max-width: 5000px) and (min-width: 1382px) {
        width: 68.7%;
      }
      @media only screen and (max-width: 1381px) and (min-width: 992px) {
        width: 70%;
      }
      border-left: solid 1px #e2e2e2;
    }
  `,
  termsColumnWithBrandName: css`
    @media ${queries.large} {
      @media only screen and (max-width: 5000px) and (min-width: 1382px) {
        width: 81.7%;
      }
      @media only screen and (max-width: 1381px) and (min-width: 992px) {
        width: 90%;
      }
    }
  `,
  rowValue: css`
    font-size: 14px;
    white-space: nowrap;
    color: ${theme.colors.white};
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  multipleRowValues: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
  `,
  statusIconContainer: css`
    width: 32px;
    height: 32px;
    margin-right: 2rem;
  `,
  contentContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  tableTitle: css`
    color: ${theme.colors.white};
    font-weight: 500;
    margin-bottom: 1rem;
    font-size: 1.25rem;
  `,
  tableContainer: css`
    .ant-table-tbody > tr > td {
      height: 60px;
      white-space: nowrap;
    }

    .tbody > tr:hover > td {
      background-color: ${theme.colors.black} !important;
      color: ${theme.colors.black} !important;
    }

    tbody tr:hover {
      cursor: pointer;
    }

    .ant-table-tbody {
      padding-right: 0 !important;
    }

    .ant-table-content {
      overflow: auto;
      scrollbar-width: thin;
      table {
        box-shadow: 0 2px 3px -1px var(--colors-light-gray) !important;
        padding-right: 0 !important;
      }
    }
    .ant-table-cell::before {
      content: none !important;
    }
    .ant-table-cell:first-of-type {
      box-shadow: 3px 0 5px -2px ${theme.colors.blackShadow};
    }

    .ant-table-cell:last-of-type {
      box-shadow: -3px 0 5px -2px ${theme.colors.blackShadow};
    }

    .ant-table-cell:nth-of-type(4) {
      @media ${queries.large} {
        padding-left: 16px;
      }
    }

    .ant-table-thead {
      @media ${queries.large} {
        .ant-table-cell:nth-of-type(5),
        .ant-table-cell:nth-of-type(6),
        .ant-table-cell:nth-of-type(7),
        .ant-table-cell:nth-of-type(8),
        .ant-table-cell:nth-of-type(9),
        .ant-table-cell:nth-of-type(10) {
          background-color: #f2f4fe;
        }
      }
    }

    @media ${queries.medium} {
      padding-top: 1.5rem;
    }

    .ant-pagination {
      margin-bottom: 1.375rem;

      .ant-pagination-item-link {
        border: none;
      }

      .ant-pagination-item-active {
        background-color: ${theme.colors.secondary.navyBlue};

        a {
          color: ${theme.colors.white} !important;
          padding-top: 0.0625rem;
        }
      }

      .ant-pagination-item {
        border: none;
        border-radius: 1rem !important;
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        color: ${theme.colors.secondary.purple} !important;
      }

      .ant-pagination-disabled {
        color: ${theme.colors.background.lightGray} !important;
      }
    }
  `,
  flexContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${queries.medium} {
      width: 410px;
      flex-direction: initial;
    }
  `,
  image: css`
    height: 180px;
    width: 180px;

    @media ${queries.medium} {
      width: 230px;
      height: 230px;
    }
  `,
  button: css`
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;

    :focus {
      border-radius: 0;
    }

    @media ${queries.medium} {
      position: initial;
      border-radius: 0.3125rem;

      :focus {
        border-radius: 0.3125rem;
      }
    }
  `,
  stickyFooterButton: css`
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;

    @media ${queries.medium} {
      display: none;
    }
  `,
  paginationCount: css`
    color: ${theme.colors.white};
    font-size: 0.875rem;
    text-align: center;
    margin-top: 0 !important;
    margin-bottom: 3.5rem;
  `,
  sortProjectsDrawer: css`
    ul {
      list-style-type: none;
      margin: 1rem;
      padding: 0;

      li {
        background-color: ${theme.colors.background.gray};
        padding: 0.6875rem 1.063rem 0.6875rem 1.3438rem;
        margin-top: 0.25rem;

        button {
          background: transparent;
          border: none;
          font-family: "proxima-nova", sans-serif;
          padding: 0;
          color: black;
          width: 100%;
          text-align: left;
          cursor: pointer;
        }
      }
    }
  `,
  checkIcon: css`
    float: right;
    padding-top: 0.3125rem;
    color: ${theme.colors.secondary.purple};
  `,
  projectInfoDrawer: css`
    padding-top: 0;

    > div {
      min-height: 2.8125rem;

      .label {
        display: inline-block;
      }
    }

    .secondary {
      font-size: 0.875rem;
      color: ${theme.colors.background.darkGray};
      display: block;
    }
  `,
  titleContainer: css`
    display: flex;
    align-items: center;
    font-weight: bold;
  `,
  sortContainer: css`
    display: flex;
    align-items: center;
    color: ${theme.colors.black};
    position: relative;
    left: 1rem;
  `,
  sortSpan: css`
    left: 0.4rem;
    position: relative;
    font-size: 1rem;
    font-weight: bold;
  `,
  sortButton: css`
    display: flex;
    margin-right: 0;
    &:hover {
      background-color: transparent;
    }
    &:focus-visible {
      outline: none;
    }
  `,
  drawerContentContainer: css`
    padding: 1rem 1rem 0 1rem;
    min-height: 220px;

    .ant-form-item-explain.ant-form-item-explain-error {
      padding-top: 0.4375rem;
      padding-left: 0.125rem;
    }
  `,
  drawerInfoContainer: css`
    margin-bottom: 1.5rem;
  `,
  drawerHeader: css`
    color: ${theme.colors.secondary.navyBlue} !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    margin-bottom: 0 !important;

    :first-of-type {
      margin-top: 1rem;
    }
  `,
  backButton: css`
    width: 250px;
    margin-top: 1rem;
    cursor: pointer;
  `,
  closeButton: css`
    background-color: white;
    border: none;
    cursor: pointer;
  `,
  input: css`
    max-width: 497px;
    background: ${theme.colors.background.white};
    border: 1px solid ${theme.colors.background.greyRoom};
    border-radius: 8px;
    margin-bottom: 24px;
    margin-right: 16px;
    .ant-input-group-addon {
      border: unset !important;
      right: 10px;
      background-color: transparent;
      background-repeat: no-repeat;
      cursor: pointer;
      overflow: hidden;
      outline: none;
      padding: 6px 0 0 10px;
    }

    .ant-input {
      border: unset !important;
    }

    .ant-input::placeholder {
      color: ${theme.colors.secondary.iconGray};
      opacity: 1; /* Firefox */
    }
  `,
  inputClear: css`
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
  `,
  actionsContainer: css`
    ${actionContainer}
    padding-bottom: 3rem;
  `,
  primaryButton: css`
    border: 1px solid ${theme.colors.background.greyRoom};
    border-radius: 8px !important;
    background-color: ${theme.colors.background.white};
    padding: 12px 24px;
    height: 48px !important;

    span {
      font-family: ${theme.fonts.sofiaPro};
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 500;
      color: ${theme.colors.secondary.black};
    }

    &:hover,
    &:focus {
      border: 1px solid ${theme.colors.background.greyRoom};
      background-color: ${theme.colors.background.fadeToGrey};
    }
  `,
  tabsContainer: css`
    position: relative;
  `,
};
