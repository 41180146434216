import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";

export default {
  heading: css`
    font-family: ${theme.fonts.sofiaPro};
    margin-bottom: 0 !important;
    color: ${theme.colors.secondary.blueOnBlack} !important;

    @media ${queries.medium} {
      font-size: 2rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem !important;
    }

    @media ${queries.large} {
      font-size: 2rem;
    }
  `,
  divider: css`
    background-color: ${theme.colors.darkCharcoal};
  `,
  tabHeaderAdditionalText: css`
    font-size: 24px;
    font-weight: 300;
  `,
  tabHeaderTitle: css`
    font-size: 56px;
    font-weight: 500;
    line-height: 64px;
  `,
  tabHeaderContainer: css`
    display: flex;
    padding-top: 48px;
    flex-direction: column;
    row-gap: 16px;
    color: ${theme.colors.white};
  `,
  headerContainer: css`
    display: none;
    width: 100%;
    margin-bottom: 3rem;

    @media ${queries.medium} {
      h1 {
        margin-top: 3rem;
        font-family: ${theme.fonts.sofiaPro};
        font-weight: 800;
        font-size: 2rem;
      }
      display: flex;
      font-size: 2.25rem !important;
      padding-right: 1.25rem;
      min-height: 80px;
    }

    @media ${queries.large} {
      margin-bottom: 1.5rem;
      padding-top: 48px;
    }
  `,
  container: css`
    margin: 0 auto;
    max-width: 1552px;
    padding: 0 24px;
  `,
  contentContainer: css`
    padding-top: 1.5rem;
    border-radius: 0;

    @media ${queries.medium} {
      border-radius: 0.313rem;
      padding: 1.5rem 3.125rem;
    }
  `,
  headerBackBtn: css`
    display: none;

    @media ${queries.medium} {
      display: flex;
      font-size: 1rem;
    }
  `,
  baseBackground: css`
    height: 100%;
    background: ${theme.colors.background.landingDarkGray};
    overflow: auto;
  `,
  darkBackground: css`
    background: ${theme.colors.black};
  `,
  adaptiveMusicContainer: css`
    min-height: calc(100vh - 326px);
    margin-top: 48px;
    margin-bottom: 48px;
    display: flex;
    flex-direction: column;

    iframe {
      min-height: 150vh;
      flex: 1;
      @media ${queries.large} {
        min-height: 100vh;
      }
    }
  `,
};
