import { FunctionComponent } from "react";
import BreadcrumbProvider from "./state/provider";

const withBreadcrumbs = (
  WrappedComponent: FunctionComponent
): FunctionComponent => (props: Record<string, unknown>) => (
  <BreadcrumbProvider>
    <WrappedComponent {...props} />
  </BreadcrumbProvider>
);

export default withBreadcrumbs;
