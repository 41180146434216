import { Col, Row } from "antd";
import React, { ReactElement, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";
import mainStyles from "../../styles";
import SectionTitleAndSubtitle from "../section-title-and-subtitle";

interface IProps {
  globalBrandName?: string;
  regionalBrandName?: string;
  productName?: string;
  productDivision?: string;
}

interface IProductDetailColumnProps {
  title: string;
  value?: string;
}

const ProductDetailColumn = ({ title, value }: IProductDetailColumnProps) => {
  const { t } = useTranslation();

  if (!value) return <></>;

  return (
    <Col xs={24} sm={24} md={6} lg={6}>
      <div css={mainStyles.tableTitle}>
        {t(`ClientProjectViewPage##${title}`)}
      </div>
      <div css={mainStyles.sectionMainText}>{value}</div>
    </Col>
  );
};

const ProductDetails = forwardRef(
  (
    {
      globalBrandName,
      regionalBrandName,
      productName,
      productDivision,
    }: IProps,
    ref: React.Ref<HTMLDivElement>
  ): ReactElement => {
    return (
      <div css={mainStyles.sectionContainer} id="product-details" ref={ref}>
        <div css={styles.productDetailsContainer}>
          <SectionTitleAndSubtitle
            title="Product details"
            subtitle="Key information about this project"
          />
          <Row gutter={[16, 16]}>
            {[
              { title: "Global brand name", value: globalBrandName },
              { title: "Regional brand name", value: regionalBrandName },
              { title: "Product name", value: productName },
              { title: "Product division", value: productDivision },
            ].map((data) => (
              <ProductDetailColumn
                key={data.title}
                title={data.title}
                value={data.value}
              />
            ))}
          </Row>
        </div>
      </div>
    );
  }
);

export default ProductDetails;
