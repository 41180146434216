import { AxiosError } from "axios";
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import useAuth from "src/auth/use-auth";
import getFeatureFlags from "src/api/organisation-features";
import { useLocation } from "react-router-dom";
import { errorToast } from "src/components/toast-notification";
import FeaturesContext from "./context";
import featureFlagsUtils from "./utils";

interface IFeatureFlagsProviderContext {
  children: ReactNode;
}
const FeatureFlagsProvider = ({
  children,
}: IFeatureFlagsProviderContext): ReactElement => {
  const { getAccessToken, isAuthenticated, organisationId } = useAuth();
  const isFetching = useRef(false);
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    void (async () => {
      const accessToken = getAccessToken();

      if (
        accessToken &&
        isAuthenticated &&
        organisationId &&
        !isFetching.current
      ) {
        try {
          isFetching.current = true;
          setIsLoading(true);
          const featureFlags = await getFeatureFlags(accessToken);
          if (featureFlags) {
            const urlSearchParams = new URLSearchParams(search);
            const urlFeatureFlagOverrides = featureFlagsUtils.getUrlFeatureFlagOverrides(
              urlSearchParams
            );
            // Store the feature flags in local storage from the response
            featureFlagsUtils.storeFeatureFlagsToLocalStorage(featureFlags);
            // Update the feature flags if set in the url
            featureFlagsUtils.updateFeatureFlagsInLocalStorage(
              urlFeatureFlagOverrides
            );
          }
        } catch (e) {
          const error = e as AxiosError;
          errorToast({ message: error.message });
        } finally {
          isFetching.current = false;
          setIsLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessToken, isAuthenticated, organisationId]);

  return (
    <FeaturesContext.Provider
      value={{
        isLoading,
      }}
    >
      {children}
    </FeaturesContext.Provider>
  );
};
export default FeatureFlagsProvider;
