import React, { useCallback, useMemo, useState } from "react";
import CheckMarkValid from "src/app/assets/icons/component-icons/buttons/check-mark-valid";
import Expired from "src/app/assets/icons/component-icons/buttons/expired";
import utils from "src/utils/projects/terms/term-utils";
import { dateToStringMMMDDYYYY } from "src/utils/time";
import DropDownArrowUp from "src/app/assets/icons/component-icons/dropdown-arrow-up";
import DropDownArrowDown from "src/app/assets/icons/component-icons/dropdown-arrow-down";
import { useOutsideClick } from "src/utils/hooks/use-outside-click";
import styles from "./styles";
import { IClientOrgProjectProps } from "../interfaces";

export interface ILicenseDropdownSelectorProps {
  projects: IClientOrgProjectProps[];
  selectedProjectId: string;
  disabled: boolean;
  onChange: (projectId: string) => void;
}

interface ILicenseDropdownSelectorMenuItemProps {
  projectData?: IClientOrgProjectProps;
  isSelected?: boolean;
}

const LicenseSelectorMenuItem = ({
  projectData,
  isSelected,
}: ILicenseDropdownSelectorMenuItemProps) => {
  const getDatesRange = useCallback((project: IClientOrgProjectProps) => {
    let datesRange = "";

    if (project.contractStartDate) {
      datesRange += dateToStringMMMDDYYYY(project.contractStartDate);
    }
    datesRange += " - ";
    if (project.contractEndDate) {
      datesRange += dateToStringMMMDDYYYY(project.contractEndDate);
    }

    return datesRange;
  }, []);

  const getStatusIcon = useCallback((project: IClientOrgProjectProps) => {
    const isExpired = utils.getDaysRemaining(project.contractEndDate) <= 0;

    if (isExpired) {
      return <Expired />;
    }

    return <CheckMarkValid />;
  }, []);

  if (!projectData) {
    return <div />;
  }

  return (
    <div css={[styles.menuItem, isSelected && styles.menuItemSelected]}>
      <div>{getDatesRange(projectData)}</div>
      {getStatusIcon(projectData)}
    </div>
  );
};

const LicenseDropdownSelector = ({
  projects,
  selectedProjectId,
  disabled,
  onChange,
}: ILicenseDropdownSelectorProps) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const selectedProjectData = useMemo(() => {
    return (projects ?? [])?.find(
      (project) => project.id === selectedProjectId
    );
  }, [projects, selectedProjectId]);

  const handleOnClick = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const hasNoProjects = useMemo(() => {
    return (projects ?? []).length === 0;
  }, [projects]);

  return (
    <div
      css={[styles.mainContainer, disabled && styles.disabledContainer]}
      ref={dropdownRef}
    >
      <div
        css={[styles.labelContainer, isOpen && styles.activeContainer]}
        onClick={handleOnClick}
        aria-hidden="true"
      >
        {selectedProjectId ? (
          <LicenseSelectorMenuItem projectData={selectedProjectData} />
        ) : (
          <div css={styles.noDataLabel}>Select</div>
        )}
        {isOpen ? <DropDownArrowUp /> : <DropDownArrowDown />}
      </div>
      {isOpen && (
        <div css={styles.dataContainer}>
          {(projects ?? []).map((project) => (
            <div
              key={project.id}
              onClick={() => {
                onChange?.(project.id);
                setIsOpen(false);
              }}
              aria-hidden="true"
            >
              <LicenseSelectorMenuItem
                projectData={project}
                isSelected={project.id === selectedProjectId}
              />
            </div>
          ))}
          {hasNoProjects && <div css={styles.noData}>No licenses found</div>}
        </div>
      )}
    </div>
  );
};
export default LicenseDropdownSelector;
