import { css, SerializedStyles } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";

const sharedDropdownStyle = `
  border-radius: 0.2rem;
  box-shadow: 0 0 0.313rem 0.125rem rgb(0 0 0 / 12%),
    0 0.375rem 1rem 0 rgb(0 0 0 / 8%), 0 0.563rem 1.75rem rgb(0 0 0 / 5%);

  .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title {
    padding: 0;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin-bottom: 0;
    padding-right: 1rem;
    padding-left: 0.5rem;
    font-family: ${theme.fonts.sofiaPro};
    background-color: ${theme.colors.background.gray};

    li {
      padding: 0.2rem;
      cursor: pointer;

      :hover {
        font-weight: bold;
      }
    }
  }

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: ${theme.colors.background.gray};
  }
`;

export default {
  customHeaderContentContainer: css`
    display: none;

    @media ${queries.medium} {
      display: flex;
      position: relative;
      bottom: 4rem;

      button span {
        font-weight: 700 !important;
        font-family: ${theme.fonts.sofiaPro};
      }
    }
  `,
  modalTitle: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-size: 2rem;
    font-weight: 250;
    line-height: 2.5rem;
    letter-spacing: -0.02em;
    margin-bottom: 1rem;
  `,
  modalSubTitle: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: -0.02em;
    margin-bottom: 1.5rem;
  `,
  modalList: css`
    overflow: auto !important;
    height: 400px;
    width: 520px;
    border: 1px solid ${theme.colors.background.veryDarkGray};
    border-radius: 4px;

    .ant-list-header {
      border-bottom: none;
      font-family: ${theme.fonts.sofiaPro};
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.125rem;
      color: #adadad;
      margin-left: 1.5rem;
    }

    .ant-list-split .ant-list-item {
      border-bottom: none;
      margin-bottom: 1rem;
      margin-top: 1rem;
      margin-right: 1.5rem;
    }

    .ant-list-split.ant-list-something-after-last-item
      .ant-spin-container
      > .ant-list-items
      > .ant-list-item:last-child {
      border-bottom: none;
    }

    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.secondary.mediumGrey} !important;
    }

    ::-webkit-scrollbar-track {
      background: ${theme.colors.background.basaltBlack} !important;
    }

    .ant-list-item-meta-title {
      font-family: ${theme.fonts.sofiaPro};
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      color: ${theme.colors.white} !important;
    }

    .ant-list-item-meta-description {
      color: #fafafa !important;
      font-size: 0.75rem;
      line-height: 1rem;
    }

    .ant-pagination {
      background: ${theme.colors.background.basaltBlack} !important;

      .ant-pagination-item {
        font-weight: 500 !important;
        background: ${theme.colors.white} !important;
        border: transparent !important;
        border-radius: 1rem !important;

        a {
          color: ${theme.colors.black} !important;
        }
      }

      .ant-pagination-item.ant-pagination-item-active {
        margin: 0 0.6rem !important;
      }

      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        span {
          color: ${theme.colors.white} !important;
        }
        border: none !important;
        background: none !important;
      }

      .ant-pagination-jump-prev
        .ant-pagination-item-container
        .ant-pagination-item-link-icon,
      .ant-pagination-jump-next
        .ant-pagination-item-container
        .ant-pagination-item-link-icon {
        color: ${theme.colors.white};
      }

      .ant-pagination-jump-prev
        .ant-pagination-item-container
        .ant-pagination-item-ellipsis,
      .ant-pagination-jump-next
        .ant-pagination-item-container
        .ant-pagination-item-ellipsis {
        color: ${theme.colors.white};
      }

      .ant-pagination-item:not(.ant-pagination-item-active) {
        font-weight: 500 !important;
        background: none !important;

        a {
          margin: 0 0.6rem !important;
          margin-right: 5px;
          min-width: 16px !important;
          height: 16px !important;
          color: ${theme.colors.white} !important;
          border: none !important;
          border-radius: 1rem !important;
        }
      }
    }
  `,
  tooltip: css`
    .ant-tooltip-inner {
      color: ${theme.colors.black} !important;
    }

    .ant-tooltip-placement-top {
      top: -20px !important;
    }
  `,
  projectTooltip: css`
    .ant-tooltip-inner {
      color: ${theme.colors.black} !important;
    }

    .ant-tooltip-placement-top {
      top: -20px !important;
    }
  `,
  backBtnContainer: css`
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    :hover {
      path {
        fill: ${theme.colors.functional.brightOrange};
      }
    }
  `,
  backBtn: css`
    font-family: ${theme.fonts.sofiaPro};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background: transparent;
    margin-right: 4px;
    border: none;
    cursor: pointer;
    border-bottom: none;

    :hover {
      border-bottom: none !important;
    }

    svg {
      margin-right: 3px;
      height: 24px;
    }
  `,
  backText: css`
    font-size: 0.875rem;
    font-weight: 400;
    font-family: ${theme.fonts.sofiaPro};
    background: none;
    color: white;
    border: none;
    cursor: pointer;

    :hover {
      border-bottom: none !important;
      color: white;
    }
  `,
  animationBg: css`
    position: absolute;
    right: 0;
  `,
  backBtnAndTitleCol: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  titleAndTabRow: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
    font-size: 24px;

    button {
      background: none;
      border-top: none;
      border-left: none;
      border-right: none;

      &:hover {
        cursor: pointer;
      }
    }
  `,
  subtitle: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    z-index: 2;
    font-size: 56px;
    margin-bottom: 48px;
  `,
  moreInfoButton: css`
    pointer-events: visible;

    :hover {
      background-color: rgba(128, 88, 255, 0.15);
      border-radius: 1.5625rem;
      box-sizing: border-box;
    }
  `,
  dropdownMenu: css`
    ${sharedDropdownStyle}
    min-width: 125px;
    max-width: 195px;
    background: ${theme.colors.black};
    li {
      color: ${theme.colors.white};
    }
    button {
      border: none;
      background: none;
      font-size: 0.875rem;
      font-weight: 600;
      font-family: ${theme.fonts.sofiaPro};
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 16px;
      &:hover {
        cursor: pointer;
        background-color: ${theme.colors.background.veryDarkGray};
        color: ${theme.colors.white};
      }
    }
    .ant-checkbox-wrapper {
      margin-right: 8px;
    }
    .clear-button {
      color: ${theme.colors.white};
    }
  `,
  iconButton: css`
    background: transparent !important;
    border: none;
    cursor: pointer;

    :hover {
      svg path {
        fill: ${theme.colors.functional.brightOrange} !important;
      }
    }
  `,
  heading: css`
    font-family: ${theme.fonts.calluna};
    margin-top: auto;
    padding-bottom: 0.3125rem;
    @media ${queries.medium} {
      font-size: 2.25rem !important;
      margin-bottom: 0.9375rem;
    }
    @media ${queries.large} {
      margin-bottom: 0 !important;
      font-size: 2.75rem !important;
    }
  `,
  userProfileTableBtn: css`
    text-align: left !important;
  `,
  notFound: css`
    text-align: center;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 800px;
    margin-top: 168px;
  `,
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 3.125rem;
  `,
  contentContainer: css`
    width: 410px;
  `,
  flexContainer: css`
    display: flex;
  `,
  image: css`
    height: 240px;
    width: 240px;
    @media ${queries.medium} {
      width: 230px;
      height: 230px;
    }
  `,
  button: css`
    width: 100%;
  `,
  stickyFooterButton: css`
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    border-radius: 0;
    z-index: 100;

    &:focus,
    &:hover {
      border-radius: 0 !important;
    }

    @media ${queries.medium} {
      display: none;
    }
  `,
  textContainer: css`
    display: block;
    text-align: left;
    padding-top: 0.625rem;
    padding-bottom: 1.25rem;
  `,
  teamName: css`
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: ${theme.colors.white} !important;
    margin-right: 0.5rem;
  `,
  nameAndPill: css`
    display: flex;
    flex-direction: row;

    button {
      background: none;
      cursor: pointer;
      border: none;
    }
  `,
  agencyPill: css`
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    font-size: 0.75rem;
    font-weight: 800;
    text-align: center;
    color: ${theme.colors.white};
    background-color: ${theme.colors.functional.brightOrange};
  `,
  teamEmail: css`
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${theme.colors.secondary.blueOnBlackShadow};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  teamAdminCol: css`
    text-align: left;
    color: ${theme.colors.white};
    cursor: pointer;

    button {
      cursor: pointer;
    }
  `,
  dropdown: css`
    .ant-select-dropdown .ant-select-dropdown-placement-bottomLeft {
      width: 100px !important;
      background-color: ${theme.colors.background.veryDarkGray};

      .ant-select-item .ant-select-item-option {
        background-color: ${theme.colors.background.veryDarkGray};
      }

      .ant-select-item-option-content {
        background-color: ${theme.colors.background.veryDarkGray};
        color: ${theme.colors.white};
      }
    }

    .ant-select {
      .ant-select-selector {
        color: #adadad !important;
        border: none !important;
        font-size: 0.875rem !important;
        line-height: 1.125rem !important;
      }
    }

    .ant-select-arrow {
      padding-left: 1rem;
      padding-top: 2px;

      span {
        color: #adadad !important;
      }
    }
  `,
  titleAdminCol: css`
    text-align: left;
    color: ${theme.colors.white};
    display: block;
  `,
  teamEnterpriseAdminCol: css`
    display: inline-block;
    cursor: pointer;

    button {
      cursor: pointer;
    }
  `,
  teamAdminPill: css`
    color: ${theme.colors.white};
    font-size: 0.5rem;
    font-weight: 700;
    padding: 2px 4px;
    margin-left: 0.75rem;
    letter-spacing: 2%;
    background-color: ${theme.colors.secondary.bigSky};
    border-radius: 0.25rem;
    vertical-align: top;
  `,
  teamInviteEmail: css`
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${theme.colors.white};
    margin-top: -0.3125rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  brandMembersCol: css`
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.125rem;
    text-align: left;
    color: #adadad;
  `,
  invitesColumnHeader: css`
    text-align: left;
    display: block;
  `,
  teamInviteStatus(expired: boolean): SerializedStyles {
    return css`
      font-size: 0.875rem;
      text-align: center;
      padding: 0.5rem 1rem;
      line-height: 1.25rem;
      color: ${expired
        ? `${theme.colors.functional.alertRed}`
        : `${theme.colors.functional.infoBlue}`};
      margin-top: -0.3125rem;
      background-color: ${expired
        ? `${theme.colors.functional.alertRedLight}`
        : `${theme.colors.functional.infoBlueLight}`};
      border-radius: 40px;
    `;
  },
  teamInviteMobile: css`
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
    max-height: 2rem;
  `,
  teamInviteStatusMobile: css`
    font-size: 0.625rem;
    text-align: center;
    color: ${theme.colors.background.black};
    background-color: rgba(0, 204, 204, 0.2);
    border-radius: 0.75rem;
    padding: 0.15rem 0.4rem;
    align-self: center;
  `,
  deleteButtonMobile: css`
    button {
      max-height: 1rem !important;
      margin-right: 0 !important;
      padding-right: 0 !important;

      & svg {
        margin-right: -1rem !important;
      }
    }
  `,
  titleContainer: css`
    display: flex;
    align-items: center;
    color: ${theme.colors.white};
    font-weight: 600;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 0.875rem;
  `,
  sentDateTitleContainer: css`
    display: flex;
    justify-content: flex-start;
    color: ${theme.colors.white};
    font-weight: 800;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 0.875rem;
  `,
  headerContainer: css`
    padding: 0.8rem 0;
  `,
  emailContainer: css`
    padding: 0.75rem 0;
    color: ${theme.colors.white};
  `,
  sortContainer: css`
    display: flex;
    align-items: center;
    color: ${theme.colors.white};
    position: relative;
    left: 1rem;
  `,
  sortSpan: css`
    left: 0.4rem;
    position: relative;
    font-size: 1rem;
    font-weight: bold;
  `,
  sortButton: css`
    padding: 0;
    min-width: auto;
    height: auto;
    margin-left: 0.3125rem;

    :hover {
      background: transparent !important;
    }
  `,
  compLibTable: css`
    background: ${theme.colors.background.basaltBlack} !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    .not-found {
      color: ${theme.colors.white};
      background: #333;
      padding: 48px 26px;
    }
    table {
      th,
      td {
        color: ${theme.colors.white} !important;
        padding: 20px 16px !important;
        background: ${theme.colors.background.basaltBlack};
        border-bottom: none !important;
        border-top: none !important;
      }
      thead {
        td {
          border-right: 1px solid var(--basalt-background, #141414);
          border-bottom: none !important;
          background: ${theme.colors.background.veryDarkGray} !important;
          font-size: 16px;
        }
      }
      tbody td {
        &:hover {
          background: ${theme.colors.background.veryDarkGray} !important;
        }
      }
      .ant-table-tbody > tr > td {
        max-width: none;
      }
      tr {
        border-bottom: none;
        &:hover {
          td {
            background: #000 !important;
          }
        }
      }
      border-radius: 0 !important;
      padding: 0;
      box-shadow: none !important;
      .more-info-button {
        svg {
          transform: rotate(90deg);
        }
      }
    }
    .copy-button {
      &:hover {
        background: ${theme.colors.functional.brightOrange} !important;
      }
    }
  `,
  table: css`
    .ant-table {
      background: ${theme.colors.background.basaltBlack} !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      table {
        th,
        td {
          color: #fff !important;
          padding: 18px 16px !important;
        }
        th {
          border-right: 1px solid var(--basalt-background, #141414);
          border-bottom: none !important;
          background: ${theme.colors.background.veryDarkGray} !important;
          font-size: 16px;
        }
        td {
          background: ${theme.colors.background.basaltBlack};
          border-bottom: none !important;
          border-top: none !important;
          &:hover {
            background: ${theme.colors.background.veryDarkGray} !important;
          }
        }
        thead tr th {
          white-space: nowrap;
          height: 64px;
          max-height: 64px !important;
        }
        thead tr th:first-of-type .ant-checkbox-inner::after {
          border: none;
          background: transparent;
        }
        .ant-checkbox-inner {
          border: 1.5px solid #a6a6a6;
        }
        .ant-checkbox-checked {
          .ant-checkbox-inner {
            background-color: #77acba !important;
            color: ${theme.colors.black} !important;
            ::after {
              border-color: ${theme.colors.black} !important;
            }
          }
        }

        .ant-table-tbody > tr > td {
          max-width: none;
        }
        tr {
          &:hover {
            td {
              background: #000 !important;
            }
          }
        }
        border-radius: 0 !important;
        padding: 0;
        box-shadow: none !important;
        .more-info-button {
          svg {
            transform: rotate(90deg);
          }
        }
      }
    }
    .ant-checkbox:hover .ant-checkbox-inner {
      border-color: ${theme.colors.white} !important;
    }
    .ant-pagination {
      background: ${theme.colors.background.basaltBlack} !important;

      .ant-pagination-item {
        font-weight: 500 !important;
        background: ${theme.colors.white} !important;
        border: transparent !important;
        border-radius: 1rem !important;

        a {
          color: ${theme.colors.black} !important;
        }
      }

      .ant-pagination-item.ant-pagination-item-active {
        margin: 0 0.6rem !important;
      }

      .ant-pagination-prev .ant-pagination-item-link,
      .ant-pagination-next .ant-pagination-item-link {
        span {
          color: ${theme.colors.white} !important;
        }
        border: none !important;
        background: none !important;
      }

      .ant-pagination-jump-prev
        .ant-pagination-item-container
        .ant-pagination-item-link-icon,
      .ant-pagination-jump-next
        .ant-pagination-item-container
        .ant-pagination-item-link-icon {
        color: ${theme.colors.white};
      }

      .ant-pagination-jump-prev
        .ant-pagination-item-container
        .ant-pagination-item-ellipsis,
      .ant-pagination-jump-next
        .ant-pagination-item-container
        .ant-pagination-item-ellipsis {
        color: ${theme.colors.white};
      }

      .ant-pagination-item:not(.ant-pagination-item-active) {
        font-weight: 500 !important;
        background: none !important;

        a {
          margin: 0 0.6rem !important;
          margin-right: 5px;
          min-width: 16px !important;
          height: 16px !important;
          color: ${theme.colors.white} !important;
          border: none !important;
          border-radius: 1rem !important;
        }
      }
    }
    .actions {
      .approve-icon {
        margin-right: 8px;
        rect {
          fill: ${theme.colors.white};
        }
        path {
          fill: ${theme.colors.functional.approveGreen};
        }
        &:hover {
          cursor: pointer;
          rect {
            fill: ${theme.colors.functional.approveGreen};
          }
          path {
            fill: ${theme.colors.white};
          }
        }
      }
      .deny-icon {
        cursor: pointer;
        rect {
          fill: ${theme.colors.white};
        }
        path {
          fill: ${theme.colors.functional.denyRed};
        }
        &:hover {
          cursor: pointer;
          rect {
            fill: ${theme.colors.functional.denyRed};
          }
          path {
            fill: ${theme.colors.white};
          }
        }
      }
      button {
        background: none;
        border: none;
      }
    }
  `,
  invitesTable: css`
    font-family: ${theme.fonts.sofiaPro};
    padding-bottom: 4rem;

    .ant-table-tbody > tr > td {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;

      :hover {
        background-color: ${theme.colors.background.veryDarkGray} !important;
      }
    }

    .ant-table-tbody > tr:hover > td {
      background-color: ${theme.colors.background.veryDarkGray} !important;
    }
  `,
  brandLoginModal: css`
    .ant-modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: ${theme.colors.background.basaltBlack} !important;
    }

    .ant-modal-content {
      width: 450px;
      height: 279px;
    }

    .ant-modal-close-x {
      svg {
        g {
          stroke: ${theme.colors.white} !important;
        }
      }
    }
  `,
  modalBtnRow: css`
    display: flex;
    justify-content: flex-end;

    .inviteMembersCancelButton {
      color: ${theme.colors.black};
    }

    .inviteMembersSendButton {
      margin-left: 1rem;
    }
  `,
  inviteMembersModal: css`
    .ant-modal-body {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background-color: ${theme.colors.background.basaltBlack} !important;
    }

    .ant-modal-content {
      width: 700px;
      height: 650px;
    }

    .ant-modal-close-x {
      svg {
        g {
          stroke: ${theme.colors.white} !important;
        }
      }
    }
  `,
  drawer: css`
    .ant-drawer-content {
      min-height: 18.75rem !important;
    }
  `,
  moreInfo: css`
    margin-right: -1rem;
  `,
  pageHeader: css`
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: -16px;
  `,
  sectionHeader: css`
    font-family: ${theme.fonts.sofiaPro};
    font-style: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 1px;
    color: ${theme.colors.secondary.enterpriseBruised};
  `,
  deleteButton: css`
    svg {
      width: 17.5px;
      height: 19.5px;
    }

    .ant-tooltip-placement-top {
      padding-bottom: 0.75rem;

      .ant-tooltip-arrow {
        left: 58%;
      }
    }

    .ant-tooltip-inner {
      background-color: ${theme.colors.primary.eiffelBlue} !important;
    }
  `,
  modalConfirmButtonStyleOverride: css`
    .ant-modal-content {
      h1 {
        padding-top: 22px;
      }

      .confirmationModalConfirmButton {
        border-radius: 5px !important;
        min-width: fit-content !important;
        border: none !important;

        span {
          color: ${theme.colors.white} !important;
        }
      }
    }
  `,
  header: css`
    display: flex;
    align-items: center;
    height: 170px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    justify-content: center;
  `,
  headerContent: css`
    width: 100%;
    align-items: center;
    max-width: 1552px;
    padding: 0 24px;
    height: 100%;
  `,
  pages: css`
    background-color: inherit;
    & > * {
      background-color: inherit;
      align-self: stretch;
      border-bottom: 3px solid transparent;
      padding: 0;
      padding-bottom: 10px;
      margin-right: 8px;
      line-height: 1.5rem;
      font-weight: 800;
      font-family: ${theme.fonts.sofiaPro};
      font-size: 1rem;
    }

    button {
      font-weight: 250;
      border-radius: 32px;
      border: 1px solid #333;
      padding: 12px;
    }
  `,
  mainHeader: css`
    display: flex;
    height: 100%;
    width: 100%;
    font-weight: 200;
    line-height: 3.5rem;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white} !important;
    font-size: 3rem;
    justify-content: space-between;
  `,
  selectedPage: css`
    border: 1px solid #f0f0f0 !important;
  `,
  search: css`
    display: flex;
    max-width: 656px;
    flex: 1 1 auto;
    justify-content: end;
    padding-top: 30px;
  `,
  searchInput: css`
    vertical-align: top;
    margin-right: 18px;
    flex: 1;
    max-width: 250px;
    background: transparent;
    border: none;

    &:hover {
      border: none;
    }

    .ant-input-affix-wrapper {
      background-color: ${theme.colors.background.basaltBlack};
      border: none;
    }

    .ant-input-prefix {
      padding-bottom: 8px;

      svg {
        stroke: #adadad;

        path {
          fill: #adadad;
        }
      }
    }

    input.ant-input {
      height: 56px;
      flex: 1;
      border-radius: 8px !important;
      min-width: 0 !important;
      padding-left: 40px;
      color: ${theme.colors.white} !important;
      box-shadow: none;
    }
  `,
  searchWrapper: css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg.magnify {
      position: absolute;
      margin: 16px 10px 5px 10px;
    }

    button.closeButton {
      display: none;
    }
    label {
      color: ${theme.colors.white};
    }
    .passcode-actions {
      display: inline-flex;
      margin-bottom: 24px;
      button {
        margin-left: 24px;
      }
    }
  `,
  titleBtnWrapper: css`
    width: fit-content;
    align-items: center;
    margin-bottom: 1.5rem;
    display: flex;

    button {
      cursor: pointer;
      border: none;
    }

    #tab-breadcrumb {
      background: none;
    }
  `,
  tableTitle: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-size: 2rem;
    font-weight: 250;
    line-height: 2.5rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin-right: 1rem;
  `,
  tableDescription: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    text-align: left;
    margin-top: 1rem;
  `,
  tableTitleWithChild(childTab: string): SerializedStyles {
    return css`
      color: ${childTab !== "brand-profile"
        ? `${theme.colors.white}`
        : `#adadad`};
      font-family: ${theme.fonts.sofiaPro};
      font-size: 2rem;
      font-weight: 250;
      line-height: 2.5rem;
      letter-spacing: -0.02em;
      text-align: left;
      margin-right: 0.5rem;
      background: none;
    `;
  },
  childBrandTitleContainer: css`
    display: flex;
    align-items: center;
    color: ${theme.colors.secondary.mediumGrey};
    font-size: 2rem;
    font-weight: 250;
    line-height: 2.5rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin-right: 1rem;
  `,
  childBrandTitleBtn: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-size: 2rem;
    font-weight: 250;
    line-height: 2.5rem;
    letter-spacing: -0.02em;
    text-align: left;
    margin-right: 1rem;
    margin-left: 0.5rem;
  `,
  hide: css`
    display: none;
  `,
  pageContainer: css`
    margin: 0 auto;
    padding: 32px 24px 0;
    max-width: 1552px;
    height: 100%;
    min-height: 100vh;
  `,
  teamPage: css`
    background-color: ${theme.colors.background.basaltBlack};
  `,
  deleteModal: css`
    color: ${theme.colors.white};
  `,
  permissionTooltipContainer: css`
    display: inline-block;
  `,
  antTooltip: css`
    .ant-tooltip-inner {
      background: ${theme.colors.white};
      color: ${theme.colors.black};
    }

    .ant-tooltip-arrow-content {
      --antd-arrow-background-color: ${theme.colors.white} !important;
    }
  `,
  disabledBtn: css`
    pointer-events: none;
  `,
  subHeader: css`
    display: flex;
    margin-top: 113px;
    margin-bottom: 32px;
    flex: 1;
    .row-container {
      display: flex;
      width: 100%;
      margin-top: 100px;
    }
    .subtabs {
      flex: 1;
      button {
        font-weight: 250;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 24px;
        color: #adadad;
        margin-right: 49px;
        &.selected {
          color: ${theme.colors.white};
        }
      }
    }
    .filter-icon {
      background: none;
      border: none;
      margin-right: 8px;
      cursor: pointer;
      &:hover {
        path {
          fill: ${theme.colors.functional.brightOrange};
        }
      }
      path {
        fill: ${theme.colors.white};
      }
    }
  `,
  statusButtons: (status: string) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      flex: 1;
      box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.5);
    }
    .disabled {
      border: ${status === "Inactive" ? "1px solid #f0f0f0;" : "none"};
    }
    .enabled {
      border: ${status === "Active" ? "1px solid #f0f0f0;" : "none"};
    }
  `,
  inviteButton: css`
    vertical-align: top;
  `,
  passcodesFilter: css`
    cursor: pointer;
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    margin-bottom: 40px;
    svg {
      margin-left: 6px;
    }
  `,
  date: css`
    white-space: nowrap;
  `,
  customActionPills: css`
    margin: 0 !important;
    padding: 0 !important;
    flex-wrap: wrap;
    overflow-x: unset;
  `,
};
