import React from "react";
import { SerializedStyles } from "@emotion/react";
import PillButton from "src/components/pill-button";
import appStyles from "src/app/styles";
import styles from "../../styles";

interface IPill {
  key: string;
  name: string;
  isSelected: boolean;
  variable?: string;
  isHidden?: boolean;
  onClick: () => void;
}

interface IPillsProps {
  pills: IPill[];
  customStyles?: SerializedStyles;
}

const Pills = ({ pills, customStyles }: IPillsProps) => {
  return (
    <div css={[appStyles.container, styles.tabs, customStyles]}>
      {pills
        .filter(({ isHidden }) => !isHidden)
        .map(({ key, name, isSelected, variable, onClick }) => {
          return (
            <PillButton
              key={key}
              name={name}
              variable={variable}
              isSelected={isSelected}
              onClick={onClick}
            />
          );
        })}
    </div>
  );
};

export default Pills;
