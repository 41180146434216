import React, { Fragment } from "react";
import { TFunction } from "react-i18next";
import DeleteButton from "src/components/control-buttons/delete-button";
import { IOrganisationMemberInviteDataSource } from "src/components/table/interfaces";
import dayjs from "dayjs";
import dayJSHelper from "src/sumo/src/utils/dayJSHelper";
import { Button } from "@songtradr/component-library";
import { Tooltip } from "antd";
import RubbishBinIcon from "src/app/assets/icons/component-icons/bin-icon";
import theme from "src/theme";
import ResendInviteIcon from "src/app/assets/icons/component-icons/resend-invite-icon";
import { IMemberInvite } from "../interfaces";
import style from "../styles";

export default (
  orgInvites: Array<IMemberInvite>,
  isTablet: boolean,
  isMobile: boolean,
  handleResendInviteClick: (inviteId: string) => void,
  handleDeleteInvite: (inviteId: string) => void,
  t: TFunction<string>
): Array<IOrganisationMemberInviteDataSource> => {
  if (isMobile) {
    return orgInvites?.map(({ email, id, expiryDate }: IMemberInvite) => {
      const inviteExpired = dayjs().isAfter(
        dayJSHelper.parseApiDate(expiryDate)
      );

      return {
        key: id,
        email: (
          <Fragment>
            <div css={style.teamInviteEmail}>{email}</div>
            <div css={style.teamInviteMobile}>
              <span css={style.teamInviteStatusMobile}>
                {inviteExpired
                  ? t("manageTeam##invites##INVITE EXPIRED")
                  : t("manageTeam##invites##INVITE PENDING")}
              </span>
              <Button
                theme="blue-link"
                data-testid="resend-invite-button"
                onClick={() => {
                  handleResendInviteClick(id);
                }}
              >
                {t("manageTeam##invites##Resend")}
              </Button>
              <span css={style.deleteButtonMobile}>
                <DeleteButton
                  showTooltip
                  onClick={() => handleDeleteInvite(id)}
                />
              </span>
            </div>
          </Fragment>
        ),
      };
    });
  }

  if (isTablet) {
    return orgInvites?.map(({ email, id, expiryDate }: IMemberInvite) => {
      const inviteExpired = dayjs().isAfter(
        dayJSHelper.parseApiDate(expiryDate)
      );

      return {
        key: id,
        email: (
          <Fragment>
            <div css={style.emailContainer}>{email}</div>
          </Fragment>
        ),
        status: (
          <span css={style.teamInviteStatus(inviteExpired)}>
            {inviteExpired
              ? t("manageTeam##invites##INVITE EXPIRED")
              : t("manageTeam##invites##INVITE PENDING")}
          </span>
        ),
        resend: (
          <Button
            theme="blue-link"
            data-testid="resend-invite-button"
            onClick={() => {
              handleResendInviteClick(id);
            }}
          >
            {t("manageTeam##invites##Resend")}
          </Button>
        ),
        delete: (
          <Tooltip
            getPopupContainer={(triggerNode) => triggerNode}
            title="Delete invite"
            color={theme.colors.white}
            placement="top"
            css={style.tooltip}
          >
            <button
              type="button"
              css={style.iconButton}
              onClick={() => handleDeleteInvite(id)}
            >
              <RubbishBinIcon />
            </button>
          </Tooltip>
        ),
      };
    });
  }

  return orgInvites?.map(
    ({ email, id, expiryDate, sentDate }: IMemberInvite) => {
      const inviteExpired = dayjs().isAfter(
        dayJSHelper.parseApiDate(expiryDate)
      );
      return {
        key: id,
        email: (
          <Fragment>
            <div css={style.teamInviteEmail}>{email}</div>
          </Fragment>
        ),
        sentDate: (
          <Fragment>
            <div css={style.teamInviteEmail}>
              {sentDate
                ? dayJSHelper.parseApiDateToDisplayDate(
                    sentDate,
                    "DD MMMM YYYY"
                  )
                : ""}
            </div>
          </Fragment>
        ),
        status: (
          <span css={style.teamInviteStatus(inviteExpired)}>
            {inviteExpired
              ? t("manageTeam##invites##INVITE EXPIRED")
              : t("manageTeam##invites##INVITE PENDING")}
          </span>
        ),
        resend: (
          <Tooltip
            getPopupContainer={(triggerNode) => triggerNode}
            title={t("manageTeam##invites##Resend")}
            color={theme.colors.white}
            placement="top"
            css={style.tooltip}
          >
            <button
              type="button"
              css={style.iconButton}
              onClick={() => handleResendInviteClick(id)}
              data-testid="resend-invite-button"
            >
              <ResendInviteIcon />
            </button>
          </Tooltip>
        ),
        delete: (
          <Tooltip
            getPopupContainer={(triggerNode) => triggerNode}
            title="Delete invite"
            color={theme.colors.white}
            placement="top"
            css={style.tooltip}
          >
            <button
              type="button"
              css={style.iconButton}
              onClick={() => handleDeleteInvite(id)}
              data-testid="delete-button"
            >
              <RubbishBinIcon />
            </button>
          </Tooltip>
        ),
      };
    }
  );
};
