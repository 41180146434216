import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  footer: css`
    width: 100%;
    color: ${theme.colors.white};
    padding: 1rem;
  `,
};
