import { CheckCircleFilled } from "@ant-design/icons";
import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, Button, ButtonLoadingIcon } from "@songtradr/component-library";
import DashIcon from "src/app/assets/icons/component-icons/dash-icon";
import TickIcon from "src/app/assets/icons/component-icons/tick-icon";
import EyeWithStrikethrough from "src/app/assets/icons/eye-with-strikethrough";
import EyeWithoutStrikethrough from "src/app/assets/icons/eye-without-strikethrough";
import appStyles from "src/app/styles";
import FloatingLabel from "src/components/floating-label";
import styles from "./styles";
import { IProps } from "./interfaces";
import {
  MINIMUM_PASSWORD_LENGTH,
  numberCheck,
  hasUppercase,
  hasLowercase,
  hasSpecial,
} from "../../../../utils/regex";

const PasswordInputV2 = ({
  password,
  setPassword,
  primaryBtnAction,
  primaryBtnText,
  secondaryBtnAction,
  secondaryBtnText,
  setIsValidPassword,
  isValidPassword,
  isError,
  name,
  isLoading,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [hasMinimumLength, setHasMinimumLength] = useState(false);
  const [hasUppercaseLetter, setHasUpperCaseLetter] = useState(false);
  const [hasLowercaseLetter, setHasLowerCaseLetter] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);

  useEffect(() => {
    const isNewPasswordValid =
      hasMinimumLength &&
      hasUppercaseLetter &&
      hasLowercaseLetter &&
      hasSpecialCharacter &&
      hasNumber;
    setIsValidPassword(isNewPasswordValid);
  }, [
    hasMinimumLength,
    hasUppercaseLetter,
    hasLowercaseLetter,
    hasSpecialCharacter,
    hasNumber,
    setIsValidPassword,
  ]);

  const toggleValidationMessage = (check: boolean, message: string) => (
    <>
      {check ? <TickIcon css={styles.check} /> : <DashIcon css={styles.info} />}
      {t(message)}
    </>
  );

  const handlePasswordValidation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setPassword(value);

    if (value) {
      setHasMinimumLength(value.length >= MINIMUM_PASSWORD_LENGTH);
      setHasUpperCaseLetter(hasUppercase(value));
      setHasLowerCaseLetter(hasLowercase(value));
      setHasSpecialCharacter(hasSpecial(value));
      setHasNumber(numberCheck(value));
    }
  };

  return (
    <>
      <FloatingLabel label="Password" value={password}>
        <Input
          required
          data-testid="password-input"
          name={name}
          maxLength={80}
          hasError={isError}
          onChange={(event) => handlePasswordValidation(event)}
          type={showPassword ? "text" : "password"}
          css={[
            appStyles.inputAutofillOverride,
            styles.inputField,
            styles.inputPassword,
          ]}
          icon={
            <button
              onClick={() => setShowPassword(!showPassword)}
              type="button"
              css={[appStyles.resetBtn, styles.showHidePasswordBtn]}
            >
              {showPassword ? (
                <EyeWithoutStrikethrough />
              ) : (
                <EyeWithStrikethrough />
              )}
            </button>
          }
        />
      </FloatingLabel>
      <div css={styles.btnContainer}>
        <div css={styles.secondaryBtn}>
          <Button variant="link" onClick={secondaryBtnAction} type="button">
            {secondaryBtnText}
          </Button>
        </div>
        <Button
          variant="primary"
          onClick={primaryBtnAction}
          disabled={!isValidPassword || isLoading}
        >
          {primaryBtnText} {isLoading && <ButtonLoadingIcon />}
        </Button>
      </div>
      {isValidPassword ? (
        <div className="valid-message" css={styles.validPassword}>
          <CheckCircleFilled css={styles.check} />{" "}
          {t("Your password is secure, you're good to go.")}
        </div>
      ) : (
        <div className="password-criteria" css={styles.passwordCriteria}>
          <div css={styles.passwordInstruction}>
            {t("Your password must contain")}
          </div>
          <ul>
            <li>
              {toggleValidationMessage(
                hasMinimumLength,
                "8 characters minimum"
              )}
            </li>
            <li>
              {toggleValidationMessage(
                hasUppercaseLetter,
                "1 upper case character"
              )}
            </li>
            <li>
              {toggleValidationMessage(
                hasLowercaseLetter,
                "1 lower case character"
              )}
            </li>
            <li>
              {toggleValidationMessage(
                hasSpecialCharacter,
                "1 special character"
              )}
            </li>
            <li>{toggleValidationMessage(hasNumber, "1 number")}</li>
          </ul>
        </div>
      )}
    </>
  );
};

export default PasswordInputV2;
