import { format } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { DateFormatMMMDDYYYY, ISODateParseFormat } from "src/constants";

export const DateStringYYYYMMDD = (date = ""): Dayjs => {
  return dayjs(date, ISODateParseFormat);
};

export const dateToStringMMMDDYYYY = (date = ""): string => {
  return format(new Date(date), DateFormatMMMDDYYYY);
};

export const getUnixTimestampInSeconds = () => Math.floor(Date.now() / 1000);

export default DateStringYYYYMMDD;
