import { css } from "@emotion/react";
import themeVariables from "src/theme";
import { IToastContainerProps } from "../ts";

const styles = ({ theme }: IToastContainerProps) => css`
  --toastify-toast-width: 350px;
  --toastify-font-family: "sofia-pro";
  --toastify-color-dark: ${themeVariables.colors.secondary.blueOnBlack};
  --toastify-text-color-light: ${themeVariables.colors.secondary.blueOnBlack};
  --toastify-icon-color-info: ${themeVariables.colors.functional.infoBlue};
  --toastify-icon-color-success: ${themeVariables.colors.functional
    .successGreenDark};
  --toastify-icon-color-warning: ${themeVariables.colors.functional
    .warningYellow};
  --toastify-icon-color-error: ${themeVariables.colors.functional.errorRed};
  .toast-notification {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    padding: 24px;
  }
  .toast-body {
    padding: 0;
  }
  .toast-body a {
    font-size: 14px;
    text-decoration: underline;
    font-weight: 400;
    color: ${theme === "light"
      ? themeVariables.colors.secondary.blueOnBlack
      : themeVariables.colors.white};
  }
`;

export default styles;
