import { getAuthHeader, getJsonContentHeader } from "src/api/headers";
import axios from "axios/index";
import config from "src/config";
import {
  IBrandAuditJob,
  INewBrandAuditJob,
  IYouTubeChannel,
  IYouTubeVideo,
} from "src/interfaces/brand-audit";

export const createNewAudit = async (
  accessToken: string,
  abortController: AbortController,
  auditData: INewBrandAuditJob
): Promise<IBrandAuditJob> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    signal: abortController.signal,
  };

  const { data } = await axios.post<IBrandAuditJob>(
    `${config.brandAudit.url}/audits`,
    auditData,
    options
  );

  return data;
};

export const updateExistingAudit = async (
  accessToken: string,
  abortController: AbortController,
  auditId: string,
  auditData: Omit<INewBrandAuditJob, "userName">
): Promise<IBrandAuditJob> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    signal: abortController.signal,
  };

  const { data } = await axios.put<IBrandAuditJob>(
    `${config.brandAudit.url}/audits/${auditId}`,
    auditData,
    options
  );

  return data;
};

export const getAllAudits = async (
  accessToken: string,
  abortController: AbortController
): Promise<IBrandAuditJob[] | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    signal: abortController.signal,
  };

  const { data } = await axios.get<IBrandAuditJob[]>(
    `${config.brandAudit.url}/audits`,
    options
  );

  return data;
};

export const getSingleAudit = async (
  accessToken: string,
  abortController: AbortController,
  auditId: string
): Promise<IBrandAuditJob | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    signal: abortController.signal,
  };

  const { data } = await axios.get<IBrandAuditJob>(
    `${config.brandAudit.url}/audits/${auditId}`,
    options
  );

  return data;
};

export const downloadAuditCsv = async (
  accessToken: string,
  abortController: AbortController,
  auditId: string,
  fileName: string
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken) },
    signal: abortController.signal,
    responseType: "blob",
  } as const;

  const { data } = await axios.get(
    `${config.brandAudit.url}/audits/${auditId}/csv`,
    options
  );

  const downloadElement = document.createElement("a");

  downloadElement.href = URL.createObjectURL(data);
  downloadElement.download = `${fileName}.csv`;

  document.body.append(downloadElement);
  downloadElement.click();
  downloadElement.remove();
  URL.revokeObjectURL(downloadElement.href);
};

export const restartFile = async (
  accessToken: string,
  abortController: AbortController,
  auditId: string,
  fileId: string
): Promise<IBrandAuditJob | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    signal: abortController.signal,
  };

  const { data } = await axios.post<IBrandAuditJob | null>(
    `${config.brandAudit.url}/audits/${auditId}/restart/${fileId}`,
    null,
    options
  );

  return data;
};

export const searchYouTubeChannel = async (
  accessToken: string,
  abortController: AbortController,
  searchValue: string
): Promise<IYouTubeChannel[] | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    signal: abortController.signal,
  };

  const { data } = await axios.get<IYouTubeChannel[] | null>(
    `${config.brandAudit.url}/audits/search/${searchValue}`,
    options
  );

  return data;
};

export const getYouTubeVideos = async (
  accessToken: string,
  abortController: AbortController,
  channelId: string
): Promise<IYouTubeVideo[] | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    signal: abortController.signal,
  };

  const { data } = await axios.get<IYouTubeVideo[] | null>(
    `${config.brandAudit.url}/audits/search/channel/${channelId}`,
    options
  );

  return data;
};

export const deleteAudit = async (
  accessToken: string,
  abortController: AbortController,
  auditId: string
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    signal: abortController.signal,
  };

  await axios.delete(`${config.brandAudit.url}/audits/${auditId}`, options);
};

export const restartAudit = async (
  accessToken: string,
  abortController: AbortController,
  auditId: string
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    signal: abortController.signal,
  };

  await axios.post(
    `${config.brandAudit.url}/audits/${auditId}/restart`,
    null,
    options
  );
};

export default getAllAudits;
