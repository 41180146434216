import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils/media-queries";
import theme from "src/theme";

const baseStyles = css`
  font-family: ${theme.fonts.sofiaPro};
  font-weight: 400;
  color: ${theme.colors.white};
  margin-bottom: 2rem;
`;

export default {
  h1: css`
    ${baseStyles};
    font-weight: 500;
    font-size: 2.5rem;
    line-height: 3rem;

    @media ${queries.medium} {
      font-size: 3.5rem;
      line-height: 4rem;
    }
  `,
  h2: css`
    ${baseStyles};
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2rem;
  `,
  h3: css`
    ${baseStyles};
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  `,
  p: css`
    ${baseStyles};
    margin-bottom: 2.75rem;
  `,
  divider: css`
    border: 0;
    padding: 0;
    margin: 3rem 0;
    height: 1px;
    background: ${theme.colors.darkCharcoal};
  `,
  copyrightFooter: css`
    width: 100%;
    padding: 0;

    @media ${queries.medium} {
      padding: 1rem 0;
    }
  `,
  innerContainer: css`
    display: flex;
    flex-direction: column;
    margin-top: 2rem;

    @media ${queries.medium} {
      margin-top: 5rem;
    }
  `,
  contentContainer: css`
    flex-grow: 1;
    margin-bottom: 1rem;

    @media ${queries.medium} {
      margin-bottom: 4rem;
    }
  `,
};
