export enum eAuthType {
  App = "auth0",
  Facebook = "facebook",
}

export interface ISimpleUser {
  id: string;
  name: string;
  email: string;
}

export interface IAuthorization {
  Authorization: string;
}

export enum eApplicationType {
  Portal = "Portal",
  ProjectsInternal = "ProjectsInternal",
  LibraryFull = "LibraryFull",
  ProjectsClient = "ProjectsClient",
  LibraryReadOnly = "LibraryReadOnly",
  Validate = "Validate",
  Resources = "Resources",
  Insights = "Insights",
  TeamManagement = "TeamManagement",
}

export enum eCognitoRoles {
  SumoAdmin = "app:role:vinyl-music-admin",
}

export default eAuthType;
