import { Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DownloadIcon } from "src/app/assets/icons/component-icons";
import WaveSurfer from "wavesurfer.js";
import { useTranslation } from "react-i18next";
import { ConditionallyVisible } from "src/sumo/src/components/visibility";
import CopyLink from "src/app/assets/icons/component-icons/copy";
import useProject from "src/providers/project/hooks";
import { attachmentDownloadUrl } from "src/api/project-url-helper";
import copy from "copy-to-clipboard";
import PlayButtonIcon from "src/app/assets/icons/component-icons/buttons/play-button";
import PauseButtonIcon from "src/app/assets/icons/component-icons/buttons/pause-button";
import styles from "./styles";
import { standardToast } from "../toast-notification";

interface IProps {
  url: string | HTMLMediaElement;
  fileName: string;
  artist?: string;
  handleDownloadClick?: () => void;
  setTrackPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  isTrackPlaying: boolean;
  attachmentId: string;
  textColor?: string;
  containerId: string;
}

// This is a wrapper around the Waveform.js library for easier usage within React.
export default function Waveform({
  url,
  fileName,
  artist,
  handleDownloadClick,
  setTrackPlaying,
  isTrackPlaying,
  attachmentId,
  textColor = "white",
  containerId,
}: IProps): JSX.Element {
  const wavesurfer = useRef<WaveSurfer>();
  const [playing, setPlay] = useState(false);
  const [isActiveTrack, setActiveTrack] = useState(false);
  const [isWaveformLoading, setIsWaveformLoading] = useState(false);
  const { t } = useTranslation();
  const { project } = useProject();

  // Create a new WaveSurfer instance on component mount and when url changes
  useEffect(() => {
    setPlay(false);
    setIsWaveformLoading(true);

    wavesurfer.current = WaveSurfer.create({
      container: `#${containerId}`,
      waveColor: "#555555",
      progressColor: "#ff4e00",
      cursorColor: "transparent",
      barWidth: 4,
      barRadius: 1,
      responsive: true,
      height: 45,
      normalize: true,
      partialRender: true,
    });

    /* TODO - API integration and generating peaks for waveforms
      - Because loading tracks takes a few seconds (or more for large tracks)
      - We want to generate the waveform and only download the track for streaming when the user clicks play
      - Reach out the relevant lambda function for the peaks JSON data
      - Pass into wavesurfer to generate the waveform
      - Then fetch track when user clicks play
      (For now, we will simply load the song in below)
    */
    wavesurfer.current.load(url);
    wavesurfer.current.on("ready", () => {
      setIsWaveformLoading(false);
    });
    // Removes events, elements and disconnects web audio nodes on unmount
    return () => wavesurfer.current?.destroy();
  }, [url, containerId]);

  const trackDuration = wavesurfer?.current?.getDuration() ?? 0;

  // Interval set to update the track progress button every 500ms
  useEffect(() => {
    let interval: ReturnType<typeof setTimeout>;

    if (!!wavesurfer.current && playing) {
      interval = setInterval(() => {
        const currentTime = wavesurfer?.current?.getCurrentTime() ?? 0;
        const progress = Math.min(currentTime / trackDuration, 1);

        // If the song has completed playing, reset the play button.
        if (progress === 1) {
          setTrackPlaying(false);
          setActiveTrack(false);
          setPlay(false);
          wavesurfer?.current?.setCurrentTime(0);
        }
      }, 500);
    }

    return () => clearInterval(interval);
  }, [trackDuration, playing, setTrackPlaying]);

  const handlePlayPause = async () => {
    if (isTrackPlaying && !isActiveTrack) {
      return;
    }

    if (playing && isTrackPlaying) {
      setTrackPlaying(false);
      setActiveTrack(false);
      setPlay(false);
    }

    if (!playing && !isTrackPlaying) {
      setActiveTrack(true);
      setTrackPlaying(true);
      setPlay(true);
    }

    await wavesurfer.current?.playPause();
  };

  const copyTrackLink = async () => {
    const copySuccess = copy(attachmentDownloadUrl(project.id, attachmentId));
    if (copySuccess) {
      standardToast({ message: "Track link copied" });
    }
  };

  return (
    <div css={styles.container}>
      <div css={styles.fileNameContainer}>
        <Tooltip title={fileName}>
          <div css={styles.fileName(textColor)}>{fileName}</div>
        </Tooltip>
        <ConditionallyVisible condition={!!artist}>
          <div css={styles.artist(textColor)}>{artist}</div>
        </ConditionallyVisible>
      </div>
      <div css={styles.playButtonContainer}>
        <button
          type="button"
          onClick={async () => {
            await handlePlayPause();
          }}
          data-testid={
            playing ? "music-player-pause-button" : "music-player-play-button"
          }
          disabled={isWaveformLoading}
          css={styles.control}
        >
          {playing ? <PauseButtonIcon /> : <PlayButtonIcon />}
        </button>
      </div>
      <button
        css={styles.buttonContainer}
        type="button"
        onClick={copyTrackLink}
      >
        <CopyLink fill="white" width={48} height={48} />
      </button>
      {handleDownloadClick && (
        <button
          css={styles.buttonContainer}
          type="button"
          aria-label={t("Download")}
          onClick={handleDownloadClick}
        >
          <DownloadIcon fill="white" height={48} width={48} />
        </button>
      )}
      <div css={styles.waveform} id={containerId} />
    </div>
  );
}
