import { Table } from "antd";
import React, { ReactElement, forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { INameLinkState } from "src/pages/projects/client-project-view/interfaces";
import { ConditionallyVisible } from "src/sumo/src/components/visibility";
import projectsMainStyles from "src/pages/projects/styles";
import SortDESC from "src/app/assets/icons/component-icons/buttons/sort-desc";
import SortASC from "src/app/assets/icons/component-icons/buttons/sort-asc";
import mainStyles from "../../styles";
import SectionTitleAndSubtitle from "../section-title-and-subtitle";

interface IProps {
  musicAssets: INameLinkState[];
  finalVideo: INameLinkState[];
}

const OtherAssets = forwardRef(
  (
    { musicAssets, finalVideo }: IProps,
    ref: React.Ref<HTMLDivElement>
  ): ReactElement => {
    const { t } = useTranslation();

    const populatedMusicAssets = musicAssets.filter(
      ({ name, link }) => name && link
    );
    const populatedVideoAssets = finalVideo.filter(
      ({ name, link }) => name && link
    );

    const columns = [
      {
        dataIndex: "name",
        key: "name",
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        title: (sortColumn: any) => {
          const sortedColumn = sortColumn.sortColumn?.key === "name";
          const sortedOrder = sortColumn.sortOrder;
          return (
            <div css={mainStyles.tableColumnHeader}>
              {t("ClientProjectViewPage##Name")}
              {sortedColumn && sortedOrder === "ascend" ? (
                <SortDESC />
              ) : (
                <SortASC />
              )}
            </div>
          );
        },
      },
      {
        title: t("ClientProjectViewPage##Link"),
        dataIndex: "link",
        key: "link",
      },
    ];

    const musicAssetstableDataSource = populatedMusicAssets?.map((asset) => {
      return {
        key: asset.key,
        name: asset.name || "-",
        link: asset.link ? (
          <a href={asset.link} target="_blank" rel="noreferrer">
            {asset.link}
          </a>
        ) : (
          "-"
        ),
      };
    });

    const videoAssetstableDataSource = populatedVideoAssets?.map((asset) => {
      return {
        key: asset.key,
        name: asset.name,
        link: (
          <a href={asset.link} target="_blank" rel="noreferrer">
            {asset.link}
          </a>
        ),
      };
    });

    return (
      <div css={mainStyles.sectionContainer} id="other-assets" ref={ref}>
        <SectionTitleAndSubtitle
          title="Assets"
          subtitle="All tracks, links or assets provided for your project"
        />
        <div css={mainStyles.splitTableView}>
          <ConditionallyVisible condition={populatedMusicAssets.length > 0}>
            <div>
              <div css={mainStyles.tableTitle}>
                {t("ClientProjectViewPage##Music assets")}
              </div>
              <Table
                css={projectsMainStyles.getTableStyles(false)}
                columns={columns}
                dataSource={musicAssetstableDataSource}
                pagination={false}
                scroll={{ x: true }}
              />
            </div>
          </ConditionallyVisible>
          <ConditionallyVisible condition={populatedVideoAssets.length > 0}>
            <div>
              <div css={mainStyles.tableTitle}>
                {t("ClientProjectViewPage##Final video assets")}
              </div>
              <Table
                css={projectsMainStyles.getTableStyles(false)}
                columns={columns}
                dataSource={videoAssetstableDataSource}
                pagination={false}
                scroll={{ x: true }}
              />
            </div>
          </ConditionallyVisible>
        </div>
      </div>
    );
  }
);

export default OtherAssets;
