import React, { useCallback } from "react";
import { IProjectsDataSource } from "src/components/table/interfaces";
import { format, parseISO } from "date-fns";
import { Button, Tooltip } from "antd";
import DaysRemainingPill from "src/components/days-remaining-pill";
import countries from "i18n-iso-countries";
import { getI18n, useTranslation } from "react-i18next";
import {
  DaysDuration,
  LicenseFixedDurations,
  MediaTypes,
  ProjectFilterIconStatus,
  ProjectFilterStatus,
  getEnumKeyByEnumValue,
} from "src/constants";
import termUtils from "src/utils/projects/terms";
import { useHistory } from "react-router-dom";
import { IFinalTrack } from "src/pages/projects/client-project-view/interfaces";
import StatusSoonToExpire from "src/app/assets/icons/component-icons/status/status-soon-to-expire";
import StatusExpired from "src/app/assets/icons/component-icons/status/status-expired";
import StatusActive from "src/app/assets/icons/component-icons/status/status-active";
import StatusUndefined from "src/app/assets/icons/component-icons/status/status-undefined";
import theme from "src/theme";
import { IOption } from "src/components/custom-select/interfaces";
import { IClientProjectRowsProps, IClientOrgProjectProps } from "../interfaces";
import registerTerritoryLocales from "../helpers/registerTerritoryLocales";
import styles from "../styles";

registerTerritoryLocales();

const formatTrackTitles = (tracks?: IFinalTrack[]) => {
  if (!tracks) {
    return "";
  }
  const trackTitles = tracks.map((track) => track.title);
  return trackTitles.filter((track) => track).join(", ");
};

export default (
  projects: Array<IClientOrgProjectProps>,
  showBrandNameColumn: boolean,
  selectedStatusOption: IOption
): IProjectsDataSource[] => {
  const { language } = getI18n();
  const { t } = useTranslation();
  const history = useHistory();

  const getStatusIcon = useCallback((status?: string) => {
    switch (status) {
      case ProjectFilterIconStatus.Active:
        return <StatusActive title={ProjectFilterStatus.Active} />;
      case ProjectFilterIconStatus.Expired:
        return <StatusExpired />;
      case ProjectFilterIconStatus.ExpireIn7Days:
        return (
          <StatusSoonToExpire
            fill={theme.colors.functional.scarletRed}
            title={ProjectFilterIconStatus.ExpireIn7Days}
          />
        );
      case ProjectFilterIconStatus.ExpireIn30Days:
        return (
          <StatusSoonToExpire
            fill={theme.colors.functional.goldenTangerine}
            title={ProjectFilterIconStatus.ExpireIn30Days}
          />
        );
      case ProjectFilterStatus.Undefined:
        return <StatusUndefined />;
      case ProjectFilterStatus.InPerpetuity:
        return (
          <StatusActive
            fill={theme.colors.functional.skyBlue}
            title={ProjectFilterStatus.InPerpetuity}
          />
        );
      default:
        return null;
    }
  }, []);

  const getPillStatusFromDuration = useCallback(
    (endDate?: string, fixedDuration?: string) => {
      if (fixedDuration === LicenseFixedDurations.InPerpetuity) {
        return ProjectFilterIconStatus.InPerpetuity;
      }
      if (!endDate) {
        return ProjectFilterIconStatus.Undefined;
      }
      const daysRemaining = termUtils.getDaysRemaining(endDate);
      if (daysRemaining <= DaysDuration.Expired) {
        return ProjectFilterIconStatus.Expired;
      }
      if (daysRemaining < DaysDuration.Seven) {
        return ProjectFilterIconStatus.ExpireIn7Days;
      }
      if (daysRemaining < DaysDuration.Thirty) {
        return ProjectFilterIconStatus.ExpireIn30Days;
      }

      return ProjectFilterIconStatus.Active;
    },
    []
  );

  const getGlobalStatusFromDuration = useCallback(
    (endDate?: string, fixedDuration?: string) => {
      if (fixedDuration === LicenseFixedDurations.InPerpetuity) {
        return getEnumKeyByEnumValue(
          ProjectFilterStatus,
          ProjectFilterStatus.InPerpetuity
        );
      }
      if (!endDate) {
        return getEnumKeyByEnumValue(
          ProjectFilterStatus,
          ProjectFilterStatus.Undefined
        );
      }
      const daysRemaining = termUtils.getDaysRemaining(endDate);
      if (daysRemaining <= DaysDuration.Expired) {
        return getEnumKeyByEnumValue(
          ProjectFilterStatus,
          ProjectFilterStatus.Expired
        );
      }
      if (daysRemaining < DaysDuration.Thirty) {
        return getEnumKeyByEnumValue(
          ProjectFilterStatus,
          ProjectFilterStatus.SoonToExpire
        );
      }

      return getEnumKeyByEnumValue(
        ProjectFilterStatus,
        ProjectFilterStatus.Active
      );
    },
    []
  );

  const handleViewProject = useCallback(
    (e: React.MouseEvent<HTMLElement, MouseEvent>, projectId) => {
      e.stopPropagation();
      history.push(`/commerciallicense/${projectId}`);
    },
    [history]
  );
  const newRows: Array<IClientProjectRowsProps> = [];

  projects.forEach((project) => {
    if (project.terms && project.terms.length >= 1) {
      project.terms?.forEach((term) => {
        const duration = termUtils.calculateDuration(term);
        let territoriesText = <></>;
        let mediaTypesText = <></>;

        if (term.territories) {
          if (term.territories.worldwide) {
            territoriesText = <span>Worldwide</span>;
          } else if (term.territories.includedCountries.length === 1) {
            territoriesText = (
              <span>
                {countries.getName(
                  term.territories.includedCountries[0],
                  language
                )}
              </span>
            );
          } else {
            const firstCountry = countries.getName(
              term.territories.includedCountries[0],
              language
            );

            territoriesText = (
              <div>
                {firstCountry}...
                <span css={styles.badge}>
                  +{term.territories.includedCountries.length - 1}{" "}
                </span>
              </div>
            );
          }
        }

        const mediaType = term.mediaType[0] as keyof typeof MediaTypes;
        if (term.mediaType) {
          if (term.mediaType.length === 1) {
            mediaTypesText = <span>{MediaTypes[mediaType]}</span>;
          } else if (term.mediaType.length > 1) {
            mediaTypesText = (
              <div>
                {MediaTypes[mediaType]}...
                <span css={styles.badge}>
                  +{term.mediaType.length + term.customMediaType.length - 1}{" "}
                </span>
              </div>
            );
          } else if (term.customMediaType.length === 1) {
            mediaTypesText = <span>{term.customMediaType[0]}</span>;
          } else if (term.customMediaType.length > 1) {
            mediaTypesText = (
              <div>
                {term.customMediaType[0]}...
                <span css={styles.badge}>
                  +{term.customMediaType.length - 1}{" "}
                </span>
              </div>
            );
          }
        }
        const endDate = termUtils.getEndDateFromTerm(term);
        const globalStatus = getGlobalStatusFromDuration(endDate, duration);
        if (
          selectedStatusOption?.value !== "All" &&
          selectedStatusOption?.value !== globalStatus
        ) {
          return;
        }
        newRows?.push({
          brandName: project.client?.name,
          id: `${project.id}-${JSON.stringify(term)}`,
          projectId: project.id,
          name: project.name,
          trackName: formatTrackTitles(project.tracks),
          clientRef: project.clientReferenceNo,
          startDate: term.startDate
            ? format(parseISO(term.startDate), "dd/MM/yyyy")
            : "",
          duration,
          endDate, // work out end date from custom or fixed - used for days remaining pill
          territories: territoriesText,
          media: mediaTypesText,
          campaignName: project.campaignName,
        });
      });
    } else {
      // If the project hasn't terms setup yet we can return this generic row
      newRows?.push({
        brandName: project.client?.name,
        id: project.id,
        projectId: project.id,
        name: project.name,
        campaignName: project.campaignName,
        trackName: formatTrackTitles(project.tracks),
        clientRef: project.clientReferenceNo,
        startDate: project.terms[0]?.startDate
          ? format(parseISO(project.terms[0]?.startDate), "dd/MM/yyyy")
          : "",
        duration: "",
        endDate: "",
        territories: 0,
        media: "",
        status: project.status,
      });
    }
  });

  return newRows.map(
    ({
      id,
      projectId,
      name,
      trackName,
      clientRef,
      startDate,
      duration,
      endDate,
      territories,
      media,
      campaignName,
      brandName,
    }: IClientProjectRowsProps) => {
      const status = getPillStatusFromDuration(endDate, duration);
      return {
        key: id,
        projectId,
        name: <div css={styles.rowValue}>{name}</div>,
        ...(!showBrandNameColumn
          ? {}
          : { brandName: <div css={styles.rowValue}>{brandName}</div> }),
        trackName: <div css={styles.rowValue}>{trackName}</div>,
        clientRefId: <div css={styles.rowValue}>{clientRef}</div>,
        startDate: <div css={styles.rowValue}>{startDate}</div>,
        duration: <div css={styles.rowValue}>{duration}</div>,
        remaining: (
          <div css={styles.rowValue}>
            <DaysRemainingPill
              startDate={startDate}
              endDate={endDate}
              duration={duration}
            />
          </div>
        ),
        territories: <div css={styles.rowValue}>{territories}</div>,
        media: <div css={styles.rowValue}>{media}</div>,
        campaignName: <div css={styles.rowValue}>{campaignName}</div>,
        status: (
          <div css={styles.rowValue}>
            <div css={styles.multipleRowValues}>
              <Tooltip title={status}>
                <div css={styles.statusIconContainer}>
                  {getStatusIcon(status)}
                </div>
              </Tooltip>
              <Button
                css={styles.primaryButton}
                onClick={(e) => handleViewProject(e, projectId)}
              >
                {t("ProjectsPage##View project")}
              </Button>
            </div>
          </div>
        ),
      };
    }
  );
};
