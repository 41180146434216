import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from "react";
import getOrganisation from "src/api/organisation/get-organisation";
import { errorToast } from "src/components/toast-notification";
import { IOrganisation } from "src/interfaces/organisation";
import OrganisationContext from "./context";

interface IOrganisationProvider {
  children: ReactNode;
}

const OrganisationProvider = ({
  children,
}: IOrganisationProvider): ReactElement => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [organisation, setOrganisation] = useState<IOrganisation>();
  const getDataAbortController = useRef<AbortController>(new AbortController());

  const getOrg = useCallback(
    async (organisationId: string, accessToken: string) => {
      if (!organisationId) {
        return;
      }

      try {
        setIsLoading(true);
        const response = await getOrganisation(
          organisationId,
          accessToken,
          getDataAbortController.current
        );

        if (response) {
          setOrganisation(response);
        }
      } catch (err) {
        errorToast({ message: "An error has occurred" });
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return (
    <OrganisationContext.Provider
      value={{
        isLoading,
        getOrg,
        organisation,
      }}
    >
      {children}
    </OrganisationContext.Provider>
  );
};

export default OrganisationProvider;
