import { Form } from "antd";
import { Checkbox } from "@songtradr/component-library";
import React from "react";
import { eApplicationType } from "src/interfaces/auth";
import styles from "./styles";

interface IPermissionCheckboxProps {
  permission: eApplicationType;
  checked: boolean;
  dataTestId: string;
  disabled: boolean;
  label: string;
  onClick: (permission: eApplicationType) => void;
}

const PermissionCheckbox = ({
  permission,
  checked,
  dataTestId,
  disabled,
  label,
  onClick,
}: IPermissionCheckboxProps) => {
  return (
    <Form.Item css={styles.checkboxWrapper}>
      <Checkbox
        data-testid={dataTestId}
        checked={checked}
        disabled={disabled}
        onClick={() => onClick(permission)}
      />
      <label htmlFor={dataTestId}>{label}</label>
    </Form.Item>
  );
};

export default PermissionCheckbox;
