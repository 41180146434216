import axios from "axios";
import { IOrganisationInvite } from "../../../interfaces/organisation";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";
import { getOrganisationInviteUrl } from "../../organisation-invites-url-helper";

const getOrganisationInvite = async (
  accessToken: string,
  organisationId: string,
  organisationInviteId: string,
  abortController?: AbortController
): Promise<IOrganisationInvite> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    ...{ signal: abortController?.signal },
  };
  const { data } = await axios.get<IOrganisationInvite>(
    getOrganisationInviteUrl(organisationId, organisationInviteId),
    options
  );
  return data;
};

export default getOrganisationInvite;
