import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { DeleteIcon } from "src/app/assets/icons/component-icons";
import theme from "src/theme";
import ControlButton from "../base-button";

interface IProps {
  showLabel?: boolean;
  showTooltip?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  label?: string;
  fillColor?: string;
}

const DeleteButton = ({
  showLabel = false,
  showTooltip = true,
  className = "",
  onClick,
  label = "Delete",
  fillColor = theme.colors.black,
}: IProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <ControlButton
      className={className}
      showLabel={showLabel}
      showTooltip={showTooltip}
      label={t(label)}
      data-testid="delete-button"
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
      }}
    >
      <DeleteIcon
        fill={fillColor}
        height={theme.svgSize.small}
        width={theme.svgSize.small}
      />
    </ControlButton>
  );
};

export default DeleteButton;
