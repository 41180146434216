import { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "./interfaces";

const AdaptiveMusicSmallIcon = ({
  fill = theme.colors.white,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1533_2412)">
        <path
          d="M23.571 8.28415H21.9777C21.7405 8.28415 21.5487 8.09172 21.5487 7.85517V2.88027C21.5487 2.64311 21.3569 2.45129 21.1197 2.45129H14.9259C14.6888 2.45129 14.4969 2.25948 14.4969 2.02232V0.428977C14.4969 0.191814 14.6888 0 14.9259 0H23.571C23.8082 0 24 0.191814 24 0.428977V7.85517C24 8.09233 23.8076 8.28415 23.571 8.28415Z"
          fill={fill}
        />
        <path
          d="M0 22.7076V15.2814C0 15.0442 0.192427 14.8524 0.428976 14.8524H2.02232C2.25948 14.8524 2.45129 15.0448 2.45129 15.2814V20.2557C2.45129 20.4928 2.64311 20.6847 2.88027 20.6847H9.07408C9.31124 20.6847 9.50305 20.8765 9.50305 21.1136V22.707C9.50305 22.9441 9.31124 23.136 9.07408 23.136H0.428976C0.191814 23.136 0 22.9441 0 22.707V22.7076Z"
          fill={fill}
        />
        <path
          d="M6.75578 9.86584H5.16244C4.92552 9.86584 4.73346 10.0579 4.73346 10.2948V17.9527C4.73346 18.1896 4.92552 18.3816 5.16244 18.3816H6.75578C6.99269 18.3816 7.18475 18.1896 7.18475 17.9527V10.2948C7.18475 10.0579 6.99269 9.86584 6.75578 9.86584Z"
          fill={fill}
        />
        <path
          d="M12.7969 4.75488H11.2036C10.9667 4.75488 10.7746 4.94694 10.7746 5.18386V8.76152C10.7746 8.99844 10.9667 9.1905 11.2036 9.1905H12.7969C13.0338 9.1905 13.2259 8.99844 13.2259 8.76152V5.18386C13.2259 4.94694 13.0338 4.75488 12.7969 4.75488Z"
          fill={fill}
        />
        <path
          d="M12.7969 13.3761H11.2036C10.9667 13.3761 10.7746 13.5682 10.7746 13.8051V17.9527C10.7746 18.1896 10.9667 18.3816 11.2036 18.3816H12.7969C13.0338 18.3816 13.2259 18.1896 13.2259 17.9527V13.8051C13.2259 13.5682 13.0338 13.3761 12.7969 13.3761Z"
          fill={fill}
        />
        <path
          d="M18.8382 4.75488H17.2448C17.0079 4.75488 16.8159 4.94694 16.8159 5.18386V12.9465C16.8159 13.1834 17.0079 13.3755 17.2448 13.3755H18.8382C19.0751 13.3755 19.2672 13.1834 19.2672 12.9465V5.18386C19.2672 4.94694 19.0751 4.75488 18.8382 4.75488Z"
          fill={fill}
        />
        <path
          d="M5.95906 11.0915C4.12917 11.0915 2.64124 9.60292 2.64124 7.77364C2.64124 5.94436 4.12978 4.4552 5.95906 4.4552C7.78834 4.4552 9.2775 5.94375 9.2775 7.77364C9.2775 9.60353 7.78895 11.0915 5.95906 11.0915V11.0915ZM5.95906 6.90649C5.48106 6.90649 5.09253 7.29564 5.09253 7.77364C5.09253 8.25164 5.48167 8.64017 5.95906 8.64017C6.43645 8.64017 6.82621 8.25164 6.82621 7.77364C6.82621 7.29564 6.43706 6.90649 5.95906 6.90649V6.90649Z"
          fill={fill}
        />
        <path
          d="M11.9923 14.9388C10.1624 14.9388 8.67389 13.4502 8.67389 11.6203C8.67389 9.79043 10.1624 8.30249 11.9923 8.30249C13.8222 8.30249 15.3102 9.79104 15.3102 11.6203C15.3102 13.4496 13.8216 14.9388 11.9923 14.9388V14.9388ZM11.9923 10.7538C11.5143 10.7538 11.1252 11.1429 11.1252 11.6203C11.1252 12.0977 11.5143 12.4875 11.9923 12.4875C12.4703 12.4875 12.8589 12.0983 12.8589 11.6203C12.8589 11.1423 12.4703 10.7538 11.9923 10.7538V10.7538Z"
          fill={fill}
        />
        <path
          d="M18.0415 18.7867C16.2116 18.7867 14.7231 17.2981 14.7231 15.4682C14.7231 13.6383 16.2116 12.1504 18.0415 12.1504C19.8714 12.1504 21.3593 13.6389 21.3593 15.4682C21.3593 17.2975 19.8708 18.7867 18.0415 18.7867ZM18.0415 14.6017C17.5635 14.6017 17.1743 14.9902 17.1743 15.4682C17.1743 15.9462 17.5635 16.3354 18.0415 16.3354C18.5195 16.3354 18.908 15.9462 18.908 15.4682C18.908 14.9902 18.5189 14.6017 18.0415 14.6017Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_1533_2412">
          <rect width="24" height="24" fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};
export default AdaptiveMusicSmallIcon;
