import React, { Fragment, ReactElement, useEffect, useState } from "react";
import orgDashboardStyles from "src/pages/org-dashboard/styles";
import { Button, Table } from "@songtradr/component-library";
import ModalPortal from "src/components/modal-portal";
import BaseModal from "src/components/modals/base-modal";
import { errorToast, successToast } from "src/components/toast-notification";
import {
  SUPERADMIN_PAGE_SIZE,
  deleteSuperadmin as deleteSuperadminAPI,
  getSuperadmins as getSuperadminsAPI,
} from "src/api/superadmins";
import useAuth from "src/auth/use-auth";
import ChevronRight from "src/app/assets/icons/component-icons/chevron-right";
import STLoadingLogo from "src/components/st-loading-logo";
import paginationStyles from "../org-table/styles";
import styles from "./styles";
import CreateSuperadmin from "../create-superadmin";

interface ISuperadmin {
  id: string;
  name: string;
  email: string;
}

const Superadmins = (): ReactElement => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedSuperadmin, setSelectedSuperadmin] = useState<ISuperadmin>();
  const [superadmins, setSuperadmins] = useState<ISuperadmin[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [possiblePages, setPossiblePages] = useState<number[]>([]);
  const { getAccessToken } = useAuth();

  const handleDeleteSuperadmin = (item: ISuperadmin) => {
    setSelectedSuperadmin(item);
    setIsDeleteModalOpen(true);
  };

  const getPossiblePages = (currentPage: number, maxPossiblePage: number) =>
    [
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1,
      currentPage + 2,
    ].filter((item) => item <= maxPossiblePage && item > -1);

  const getSuperadmins = async (newPage = 0) => {
    const accessToken = getAccessToken();
    setIsLoading(true);
    const response = await getSuperadminsAPI(accessToken, newPage);
    if (!response) {
      errorToast({ message: "Error getting superadmins" });
      setIsLoading(false);
      return;
    }
    const superadminList = response.items.map((superadmin: any) => {
      return {
        id: superadmin.auth0UserId,
        name: `${superadmin.firstName} ${superadmin.lastName}`,
        email: superadmin.email,
      };
    });
    setSuperadmins(superadminList);
    setTotal(response.total);
    setIsLoading(false);
    const maxNumberOfPages = Math.floor(response.total / SUPERADMIN_PAGE_SIZE);
    setPossiblePages(getPossiblePages(page, maxNumberOfPages));
  };

  const deleteSuperadmin = async () => {
    if (!selectedSuperadmin?.id) {
      return;
    }
    const accessToken = getAccessToken();

    const response = await deleteSuperadminAPI(
      accessToken,
      selectedSuperadmin.id
    );
    if (!response) {
      errorToast({ message: "Error deleting superadmin" });
      return;
    }
    successToast({ message: `${selectedSuperadmin?.name} deleted` });
    setIsDeleteModalOpen(false);
    getSuperadmins();
  };

  const DeleteModalContent = () => {
    return (
      <>
        <h2 css={styles.modalText}>Are you sure?</h2>
        <div css={styles.modalText}>
          <p>
            Are you sure you want to delete this superadmin? This action cannot
            be undone.
          </p>
          <div css={styles.deleteModalButtons}>
            <Button onClick={() => setIsDeleteModalOpen(false)}>Cancel</Button>
            <Button onClick={deleteSuperadmin} variant="primary">
              Delete
            </Button>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    getSuperadmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoToPage = (newPage: number) => {
    if (newPage === page) {
      return;
    }

    if (newPage < 0) {
      return;
    }

    if (total < newPage * 10) {
      return;
    }

    setPage(newPage);
    getSuperadmins(newPage);
  };

  return (
    <div css={orgDashboardStyles.container}>
      <div css={orgDashboardStyles.innerContainer}>
        <h1 className="section-header">Superadmins</h1>
        <Button
          onClick={() => setIsNew(true)}
          css={styles.createButton}
          variant="primary"
        >
          Create
        </Button>
        {isNew ? (
          <CreateSuperadmin goBack={() => setIsNew(false)} />
        ) : (
          <Fragment>
            <p>Click on user rows to open the delete modal.</p>
            {isLoading ? (
              <STLoadingLogo styles={styles.loadingLogo} />
            ) : (
              <Fragment>
                <Table
                  data={superadmins}
                  interactive="interactiveWithIcon"
                  onRowClick={handleDeleteSuperadmin}
                  columns={[
                    {
                      label: "Name",
                      dataKey: "name",
                    },
                    {
                      label: "Email",
                      dataKey: "email",
                    },
                  ]}
                />
                <div css={paginationStyles.paginationContainer}>
                  <button
                    type="button"
                    css={[paginationStyles.btnReset, paginationStyles.pageBtn]}
                    onClick={() => handleGoToPage(page - 1)}
                    data-testid="prev-page-btn"
                  >
                    <ChevronRight css={paginationStyles.horizontalFlip} />
                  </button>
                  <div>
                    {possiblePages.map((item) => (
                      <button
                        type="button"
                        css={[
                          paginationStyles.btnReset,
                          paginationStyles.pageBtn,
                          page === item
                            ? paginationStyles.currentPageBtn
                            : null,
                        ]}
                        key={`page-${item}`}
                        onClick={() => handleGoToPage(item)}
                        data-testid={
                          page === item
                            ? `current-page-btn`
                            : `go-to-page-${item}-btn`
                        }
                      >
                        {item + 1}
                      </button>
                    ))}
                  </div>
                  <button
                    type="button"
                    css={[paginationStyles.btnReset, paginationStyles.pageBtn]}
                    onClick={() => handleGoToPage(page + 1)}
                    data-testid="next-page-btn"
                  >
                    <ChevronRight />
                  </button>
                </div>
              </Fragment>
            )}
          </Fragment>
        )}

        <ModalPortal>
          <BaseModal
            visible={isDeleteModalOpen}
            content={<DeleteModalContent />}
            buttons={[]}
            onClose={() => setIsDeleteModalOpen(false)}
          />
        </ModalPortal>
      </div>
    </div>
  );
};

export default Superadmins;
