import produce from "immer";
import {
  // IFeeSplitDetailState,
  INameLinkState,
  IProjectAttachment,
  IProjectForm,
} from "src/pages/projects/client-project-view/interfaces";
import * as uuid from "uuid";
import { initialiseAttachments } from "./projectResponseMapper";
import { ProjectActions, ProjectAction } from "./types";

export const projectReducer = produce(
  (draft: IProjectForm, action: ProjectAction) => {
    switch (action.type) {
      case ProjectActions.STORE_PROJECT:
        // eslint-disable-next-line no-param-reassign
        draft = action.value.project;
        return draft;
      case ProjectActions.ADD_NAME_LINK_ROW:
        draft.music[action.value].push({
          name: "",
          link: "",
          isNew: true,
          key: uuid.v4(),
        } as INameLinkState);
        return draft;
      case ProjectActions.ADD_FINAL_TRACK_LINKS_ROW:
        draft.music.finalTrack.finalTrackLinks.push({
          name: "",
          link: "",
          isNew: true,
          key: uuid.v4(),
        } as INameLinkState);
        return draft;

      case ProjectActions.STORE_ATTACHMENTS:
        draft.briefAttachments = initialiseAttachments(draft, "Brief");
        draft.attachments = action.value.attachments as IProjectAttachment[];
        draft.version = action.value.projectVersion;

        return draft;

      default:
        throw new Error();
    }
  }
);

export const InitialProjectState = {
  version: 0,
  id: "",
  attachments: [],
  purchaseOrderAttachments: [],
  estimateAttachments: [],
  licenseAttachments: [],
  invoiceAttachments: [],
  miscAttachments: [],
  assetAttachments: [],
  sequenceNo: 0,
  clients: [],
  clientReferenceNo: "",
  clientsVersion: 0,
  music: {
    finalTrack: {
      masterFeeSplitsVersion: 0,
      masterFeeSplits: [],
      publisherFeeSplitsVersion: 0,
      publisherFeeSplits: [],
      finalTrackLinksVersion: 0,
      finalTrackLinks: [],
      finalTrackAttachments: [],
    },
    musicAssetsVersion: 0,
    musicAssets: [],
    finalVideoVersion: 0,
    finalVideo: [],
  },
  terms: [],
};
