import { css } from "@emotion/react";
import theme from "src/theme";
import "./index.css";
import { queries } from "@songtradr/spa-common/lib/utils";

export const navHeight = "96px";
export const mobileNavHeight = "70px";

export default {
  navBuffer: css`
    padding-bottom: ${mobileNavHeight};

    @media ${queries.large} {
      padding-bottom: ${navHeight};
    }
  `,
  navBarTransition: (hidden = false, atTop = true) => css`
    background-color: ${atTop
      ? theme.colors.background.basaltBlack
      : "rgba(0, 0, 0, 0.8)"};
    transition: transform 0.5s ease-in-out,
      background-color 0.5s ease-in-out 0.19s;
    transform: translateY(${hidden ? "-100%" : "0%"});
  `,
  navWrapper: css`
    position: fixed;
    z-index: 11;
    width: 100%;
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    height: ${mobileNavHeight};

    @media ${queries.large} {
      height: ${navHeight};
    }
  `,
  innerContainer: css`
    height: 100%;
    display: flex;

    .nav-menus {
      display: flex;
      align-items: center;
    }

    .middle-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }

    .logo-container {
      margin-right: 3rem;
    }
  `,
  mobileNav: css`
    display: flex;
    align-items: center;
  `,
  link: css`
    margin: 0 24px;
    display: inline-block;
    letter-spacing: 3.3px;
    font-weight: 400;
    line-height: 24px;
    font-size: 14px;
    color: ${theme.colors.secondary.waveformGray};
    text-transform: uppercase;
    border-bottom: 1px solid transparent;
    padding: 0.5rem 0;

    &:hover,
    &:focus {
      color: ${theme.colors.white};
      text-decoration: none;
    }
  `,
  activeLink: css`
    border-bottom-color: ${theme.colors.functional.brightOrange};
    color: ${theme.colors.white};
  `,
  orgLogo: css`
    height: 32px;
    margin: 15px auto;
    display: block;
  `,
};
