import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: css`
    font-family: ${theme.fonts.sofiaPro};
    display: block;
    background-color: ${theme.colors.background.landingDarkGray};
    color: ${theme.colors.white};
    width: 100%;
    h2,
    h1,
    h3 {
      text-align: center;
      color: ${theme.colors.white};
    }
    h1 {
      font-size: 64px;
      margin-bottom: 48px;
    }
    h3 {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 16px;
    }
    .content {
      margin-top: 192px;
    }
    .signup-complete {
      font-family: ${theme.fonts.sofiaPro};
      padding-top: 1px;
      margin: 0 auto;
      margin-top: 292px;
      width: 616px;
      height: 448px;
      background: #333;
      border-radius: 8px;
      text-align: center;
      h1 {
        font-size: 32px;
        margin-top: 116px;
      }
      p {
        margin-left: 48px;
        margin-right: 48px;
      }
    }
    form {
      width: 616px;
      margin: 0 auto;
      .floatingLabel {
        color: ${theme.colors.secondary.textSecondary};
      }
      input {
        width: 100%;
        color: ${theme.colors.white};
        display: flex;
        height: 64px;
        padding: 12px;
        align-items: center;
        flex-shrink: 0;
        border-radius: 8px;
        border: 1px solid ${theme.colors.secondary.mediumGrey};
        background: ${theme.colors.background.landingDarkGray};
        padding-bottom: 0;
        &:focus {
          outline: 1px solid ${theme.colors.white};
        }
      }
      .buttons {
        text-align: right;
        .cancel-button {
          color: ${theme.colors.white};
          &:hover {
            background: none;
          }
        }
      }
    }
  `,
};
