import axios from "axios";
import { IOrganisationInvite } from "src/interfaces/organisation";
import { getAuthHeader, getAxiosOptions } from "src/api/headers";
import getJsonContentHeader from "../../headers/get-json-content-header";
import { acceptOrganisationInviteUrl } from "../../organisation-invites-url-helper";

async function acceptOrganisationInvite(
  accessToken: string,
  organisationId: string,
  organisationInviteId: string,
  request: IOrganisationInvite,
  abortController?: AbortController
): Promise<void> {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    ...{ signal: abortController?.signal },
  };

  return axios.post(
    acceptOrganisationInviteUrl(organisationId, organisationInviteId),
    request,
    options
  );
}

export default acceptOrganisationInvite;
