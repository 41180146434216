import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  dateColumn: css`
    width: 200px;
  `,
  linksColumn: css`
    width: 200px;

    button,
    a {
      line-height: normal;
      padding: 0;
      margin: 0 0.75rem;
      background: none;
      cursor: pointer;
      border: none;

      &:hover svg {
        fill: ${theme.colors.functional.brightOrange};
      }
    }
  `,
  tableBase: css`
    position: relative;
    text-align: left;
    width: 100%;
    table-layout: fixed;

    td,
    th {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th {
      padding: 1.25rem;
    }

    td {
      padding: 1.65rem 1.25rem;
    }

    thead {
      th {
        color: ${theme.colors.white};
        font-size: 0.9rem;
        font-weight: 400;
        background: ${theme.colors.background.veryDarkGray};

        &:first-of-type {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:last-child {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
    }

    .finalRow {
      border-bottom: 1px solid ${theme.colors.background.veryDarkGray};
    }
  `,
  noResultsContainer: css`
    margin-top: 32px;
    border: 1px solid ${theme.colors.background.veryDarkGray};
    border-radius: 8px;
    padding: 48px;

    button {
      display: flex;
      margin: auto;
    }
  `,
  noResultsTitle: css`
    max-width: 650px;
    margin: auto;
    text-align: center;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 16px;
  `,
  noResultsSubtitle: css`
    max-width: 550px;
    margin: auto;
    text-align: center;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 32px;
  `,
  statusPill: css`
    border-radius: 40px;
    padding: 6px 16px;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 0.875rem;
    line-height: 1.25rem;
  `,
  statusPillPending: css`
    background: ${theme.colors.functional.infoBlueLight};
    color: ${theme.colors.functional.infoBlue};
  `,
  statusPillComplete: css`
    background: ${theme.colors.functional.successGreenLight};
    color: ${theme.colors.functional.successGreenDark};
  `,
};
