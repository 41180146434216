import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  menuContainer: css`
    position: relative;
  `,
  gridBtn: css`
    appearance: none;
    -webkit-appearance: none;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    margin-left: 1.5rem;
  `,
  dropdownContainerOpen: css`
    pointer-events: auto;
    opacity: 1;
  `,
  dropdownContainer: css`
    pointer-events: none;
    opacity: 0;
    transition: all 0.15s ease-in-out 0s;
    transform: translate(1.25rem, 2px);
    background: ${theme.colors.black};
    position: absolute;
    top: 2.95rem;
    right: 1rem;
    border-radius: 10px;
    filter: drop-shadow(rgba(0, 0, 0, 0.16) 0 4px 16px);
    z-index: 144;
    border: 1px solid ${theme.colors.background.darkGray100};
    min-width: 220px;
    width: 336px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  `,
  containerRow: css`
    display: flex;
    align-items: center;
  `,
  navItem: css`
    height: 96px;
    width: 96px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:hover,
    &:focus {
      text-decoration: none;

      svg,
      path {
        fill: ${theme.colors.functional.brightOrange};
      }

      p {
        color: ${theme.colors.white};
      }
    }
  `,
  navIcon: css`
    fill: ${theme.colors.white};
  `,
  navText: css`
    font-size: 14px;
    margin: 0;

    &,
    &:hover,
    &:focus {
      color: ${theme.colors.background.lightGray100};
      text-decoration: none;
    }
  `,
};
