import { ReactElement } from "react";
import { IComponentIconProps } from "./interfaces";

const ChevronRight = ({
  height = 16,
  width = 16,
  fill = "white",
  className,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 11 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.19834 15.8466L0.820883 14.4691C0.616288 14.2645 0.616288 13.9318 0.820883 13.7272L6.17707 8.37097C6.38167 8.16637 6.38167 7.83363 6.17707 7.62903L0.820133 2.27284C0.615538 2.06824 0.615538 1.7355 0.820133 1.5309L2.19759 0.153446C2.40218 -0.0511487 2.73493 -0.0511487 2.93953 0.153446L10.4151 7.62903C10.6197 7.83363 10.6197 8.16637 10.4151 8.37097L2.93953 15.8466C2.73493 16.0511 2.40218 16.0511 2.19759 15.8466H2.19834Z"
        fill={fill}
      />
    </svg>
  );
};
export default ChevronRight;
