import { css } from "@emotion/react";

export default {
  container: css`
    border-radius: 1.5rem;
    display: flex;
    font-size: 0.75rem;
    height: 1.5rem;
    justify-content: space-around;
    line-height: 1rem;
    padding: 0.25rem 0.625rem 0.25rem 0.625rem;
    width: 5rem;
  `,
  days: css`
    font-weight: 700;
  `,
  red: css`
    background-color: rgba(255, 236, 236, 1);
    p {
      color: rgba(173, 5, 5, 1);
    }
  `,
  amber: css`
    background-color: rgba(254, 238, 217, 1);
    p {
      color: rgba(190, 112, 10, 1);
    }
  `,
  green: css`
    background-color: rgba(227, 249, 236, 1);
    p {
      color: rgba(2, 116, 48, 1);
    }
  `,
};
