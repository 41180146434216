import { ReactElement } from "react";
import styles from "./styles";

const StudioSearch = (): ReactElement => {
  return (
    <div css={styles.search}>
      <svg
        id="Artwork"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 252 242"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="73.04"
            y1="68.44"
            x2="111.99"
            y2="68.44"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2e3030" />
            <stop offset="1" stopColor="#161818" stopOpacity=".8" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="72.04"
            y1="68.44"
            x2="112.99"
            y2="68.44"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#323434" />
            <stop offset="1" stopColor="#323434" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-3"
            x1="46.68"
            y1="144.59"
            x2="197.68"
            y2="97.81"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4a4c4c" />
            <stop offset="1" stopColor="#4a4c4c" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-4"
            x1="41.47"
            y1="120.59"
            x2="206.8"
            y2="120.59"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4f5050" />
            <stop offset="1" stopColor="#323434" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-5"
            x1="84.65"
            y1="75.82"
            x2="101.47"
            y2="60.05"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#666868" />
            <stop offset="1" stopColor="#363838" stopOpacity=".5" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-6"
            x1="84.39"
            y1="75.55"
            x2="101.21"
            y2="59.78"
            xlinkHref="#linear-gradient-5"
          />
          <linearGradient
            id="linear-gradient-7"
            x1="84.9"
            y1="76.09"
            x2="101.72"
            y2="60.32"
            xlinkHref="#linear-gradient-5"
          />
          <linearGradient
            id="linear-gradient-8"
            y1="174.06"
            y2="174.06"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-9"
            y1="174.06"
            y2="174.06"
            xlinkHref="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-10"
            x1="122.71"
            y1="199.69"
            x2="154.05"
            y2="199.69"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-11"
            x1="121.71"
            y1="199.69"
            x2="155.05"
            y2="199.69"
            xlinkHref="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-12"
            x1="84.98"
            y1="167.66"
            x2="97.09"
            y2="177.94"
            xlinkHref="#linear-gradient-5"
          />
          <linearGradient
            id="linear-gradient-13"
            x1="132.09"
            y1="199.33"
            x2="142.63"
            y2="202.12"
            xlinkHref="#linear-gradient-5"
          />
          <linearGradient
            id="linear-gradient-14"
            x1="131.71"
            y1="200.77"
            x2="142.25"
            y2="203.56"
            xlinkHref="#linear-gradient-5"
          />
          <linearGradient
            id="linear-gradient-15"
            x1="132.98"
            y1="195.96"
            x2="143.52"
            y2="198.75"
            xlinkHref="#linear-gradient-5"
          />
          <linearGradient
            id="linear-gradient-16"
            x1="133.28"
            y1="194.82"
            x2="143.83"
            y2="197.6"
            xlinkHref="#linear-gradient-5"
          />
          <linearGradient
            id="linear-gradient-17"
            x1="105.77"
            y1="120.59"
            x2="182.98"
            y2="120.59"
            gradientTransform="translate(23.78 264.97) rotate(-90)"
            xlinkHref="#linear-gradient-5"
          />
          <linearGradient
            id="linear-gradient-18"
            x1="45.9"
            y1="120.59"
            x2="97.07"
            y2="120.59"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ec5734" />
            <stop offset="1" stopColor="#ec5734" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-19"
            x1="44.9"
            y1="120.59"
            x2="98.07"
            y2="120.59"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ec5734" />
            <stop
              offset=".06"
              stopColor="rgba(236,87,52,.92)"
              stopOpacity=".92"
            />
            <stop
              offset=".25"
              stopColor="rgba(236,87,52,.64)"
              stopOpacity=".64"
            />
            <stop
              offset=".44"
              stopColor="rgba(236,87,52,.41)"
              stopOpacity=".41"
            />
            <stop
              offset=".62"
              stopColor="rgba(236,87,52,.24)"
              stopOpacity=".24"
            />
            <stop
              offset=".77"
              stopColor="rgba(236,87,52,.11)"
              stopOpacity=".11"
            />
            <stop
              offset=".91"
              stopColor="rgba(236,87,52,.03)"
              stopOpacity=".03"
            />
            <stop offset="1" stopColor="#ec5734" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-20"
            x1="122.12"
            y1="42.31"
            x2="153.46"
            y2="42.31"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-21"
            x1="121.12"
            y1="42.31"
            x2="154.46"
            y2="42.31"
            xlinkHref="#linear-gradient-2"
          />
          <linearGradient
            id="linear-gradient-22"
            x1="132.38"
            y1="49.3"
            x2="143.97"
            y2="37.1"
            xlinkHref="#linear-gradient-5"
          />
        </defs>
        <path
          className="cls-9"
          d="M207.08,181.33c-53.2,47.08-136.77,7.75-134.98-63.11,1-49.82,48.9-87.66,97.59-77.23,15.41,3.17,29.78,10.99,40.84,22.11-11.18-11-25.59-18.63-40.94-21.63-48.3-10.14-95.65,27.4-96.5,76.78-1.85,70.54,80.7,109.1,133.98,63.08h0Z"
        />
        <circle className="cls-4" cx="92.52" cy="68.44" r="19.47" />
        <path
          className="cls-15"
          d="M92.52,48.97c10.76,0,19.47,8.72,19.47,19.47s-8.72,19.47-19.47,19.47-19.47-8.72-19.47-19.47,8.72-19.47,19.47-19.47M92.52,47.97c-11.29,0-20.47,9.18-20.47,20.47s9.18,20.47,20.47,20.47,20.47-9.18,20.47-20.47-9.18-20.47-20.47-20.47h0Z"
        />
        <rect
          className="cls-17"
          x="42.47"
          y="101.55"
          width="163.33"
          height="38.09"
          rx="7"
          ry="7"
        />
        <path
          className="cls-23"
          d="M198.8,101.55c3.87,0,7,3.13,7,7v24.09c0,3.87-3.13,7-7,7H49.47c-3.87,0-7-3.13-7-7v-24.09c0-3.87,3.13-7,7-7h149.33M198.8,100.55H49.47c-4.41,0-8,3.59-8,8v24.09c0,4.41,3.59,8,8,8h149.33c4.41,0,8-3.59,8-8v-24.09c0-4.41-3.59-8-8-8h0Z"
        />
        <path
          className="cls-10"
          d="M92.37,59.32c-.76,4.59-4.39,8.22-8.98,8.98-.17.03-.17.27,0,.29,4.59.76,8.22,4.39,8.98,8.98.03.17.27.17.29,0,.76-4.59,4.39-8.22,8.98-8.98.17-.03.17-.27,0-.29-4.59-.76-8.22-4.39-8.98-8.98-.03-.17-.27-.17-.29,0Z"
        />
        <path
          className="cls-6"
          d="M100.71,57.03c-.26,1.6-1.52,2.86-3.12,3.12-.06,0-.06.09,0,.1,1.6.26,2.86,1.52,3.12,3.12,0,.06.09.06.1,0,.26-1.6,1.52-2.86,3.12-3.12.06,0,.06-.09,0-.1-1.6-.26-2.86-1.52-3.12-3.12,0-.06-.09-.06-.1,0Z"
        />
        <path
          className="cls-16"
          d="M84.22,73.51c-.26,1.6-1.52,2.86-3.12,3.12-.06,0-.06.09,0,.1,1.6.26,2.86,1.52,3.12,3.12,0,.06.09.06.1,0,.26-1.6,1.52-2.86,3.12-3.12.06,0,.06-.09,0-.1-1.6-.26-2.86-1.52-3.12-3.12,0-.06-.09-.06-.1,0Z"
        />
        <circle className="cls-19" cx="92.52" cy="174.06" r="19.47" />
        <path
          className="cls-18"
          d="M92.52,154.58c10.76,0,19.47,8.72,19.47,19.47s-8.72,19.47-19.47,19.47-19.47-8.72-19.47-19.47,8.72-19.47,19.47-19.47M92.52,153.58c-11.29,0-20.47,9.18-20.47,20.47s9.18,20.47,20.47,20.47,20.47-9.18,20.47-20.47-9.18-20.47-20.47-20.47h0Z"
        />
        <circle className="cls-22" cx="138.38" cy="199.69" r="15.67" />
        <path
          className="cls-8"
          d="M138.38,184.02c8.66,0,15.67,7.02,15.67,15.67s-7.02,15.67-15.67,15.67-15.67-7.02-15.67-15.67,7.02-15.67,15.67-15.67M138.38,183.02c-9.19,0-16.67,7.48-16.67,16.67s7.48,16.67,16.67,16.67,16.67-7.48,16.67-16.67-7.48-16.67-16.67-16.67h0Z"
        />
        <path
          className="cls-1"
          d="M95.51,178.31v-5.73c0-.18-.13-.32-.3-.32h-1.11c-.17,0-.3.14-.3.32v3.91c0,.18-.13.32-.3.32h-6.24c-.17,0-.3-.14-.3-.32v-7.56c0-.18.13-.32.3-.32h7.09c.17,0,.3-.14.3-.32v-1.18c0-.18-.13-.32-.3-.32h-8.8c-.17,0-.3.14-.3.32v11.2c0,.18.13.32.3.32h9.66c.17,0,.3-.14.3-.32ZM93.76,169.48h-4.09c-.17,0-.3.14-.3.32v5.73c0,.18.13.32.3.32h1.11c.17,0,.3-.14.3-.32v-3.91c0-.18.13-.32.3-.32h6.39c.17,0,.3.14.3.32v7.56c0,.18-.13.32-.3.32h-7.09c-.17,0-.3.14-.3.32v1.18c0,.18.13.32.3.32h8.8c.17,0,.3-.14.3-.32v-11.2c0-.18-.13-.32-.3-.32h-5.72Z"
        />
        <path
          className="cls-11"
          d="M142.91,205.16l-2.77-2.77c-.09-.09-.09-.23,0-.32l2.77-2.77c.09-.09.23-.09.32,0l.59.59c.09.09.09.23,0,.32l-1.85,1.85c-.09.09-.09.23,0,.32l1.85,1.85c.09.09.09.23,0,.32l-.59.59c-.09.09-.23.09-.32,0Z"
        />
        <path
          className="cls-14"
          d="M140.12,204.94h-5.68c-.12,0-.23-.1-.23-.23v-4.95c0-.12.1-.23.23-.23h5.68c.12,0,.23.1.23.23v.84c0,.12-.1.23-.23.23h-4.39c-.12,0-.23.1-.23.23v2.36c0,.12.1.23.23.23h4.39c.12,0,.23.1.23.23v.84c0,.12-.1.23-.23.23Z"
        />
        <path
          className="cls-7"
          d="M135.29,198.99c-1.33,0-2.42-1.08-2.42-2.42s1.08-2.42,2.42-2.42,2.42,1.08,2.42,2.42-1.08,2.42-2.42,2.42ZM135.29,195.45c-.62,0-1.13.5-1.13,1.13s.5,1.13,1.13,1.13,1.13-.5,1.13-1.13-.5-1.13-1.13-1.13Z"
        />
        <path
          className="cls-12"
          d="M139.93,198.99c-1.33,0-2.42-1.08-2.42-2.42s1.08-2.42,2.42-2.42,2.42,1.08,2.42,2.42-1.08,2.42-2.42,2.42ZM139.93,195.45c-.62,0-1.13.5-1.13,1.13s.5,1.13,1.13,1.13,1.13-.5,1.13-1.13-.5-1.13-1.13-1.13Z"
        />
        <rect
          className="cls-2"
          x="141.16"
          y="81.99"
          width="6.43"
          height="77.21"
          rx="3.22"
          ry="3.22"
          transform="translate(264.97 -23.78) rotate(90)"
        />
        <circle className="cls-20" cx="71.49" cy="120.59" r="25.58" />
        <path
          className="cls-3"
          d="M71.49,95.01c14.13,0,25.58,11.45,25.58,25.58s-11.45,25.58-25.58,25.58-25.58-11.45-25.58-25.58,11.45-25.58,25.58-25.58M71.49,94.01c-14.66,0-26.58,11.92-26.58,26.58s11.92,26.58,26.58,26.58,26.58-11.92,26.58-26.58-11.92-26.58-26.58-26.58h0Z"
        />
        <circle
          className="cls-24"
          cx="71.06"
          cy="120.16"
          r="7.54"
          transform="translate(-67.44 137.68) rotate(-66.61)"
        />
        <line
          className="cls-24"
          x1="76.39"
          y1="125.49"
          x2="79.45"
          y2="128.56"
        />
        <circle className="cls-13" cx="137.79" cy="42.31" r="15.67" />
        <path
          className="cls-5"
          d="M137.79,26.63c8.66,0,15.67,7.02,15.67,15.67s-7.02,15.67-15.67,15.67-15.67-7.02-15.67-15.67,7.02-15.67,15.67-15.67M137.79,25.63c-9.19,0-16.67,7.48-16.67,16.67s7.48,16.67,16.67,16.67,16.67-7.48,16.67-16.67-7.48-16.67-16.67-16.67h0Z"
        />
        <path
          className="cls-21"
          d="M136.11,38.8l.69-.69c.1-.1.1-.27,0-.37l-2.57-2.61c-.1-.1-.27-.1-.37,0l-2.57,2.61c-.1.1-.1.27,0,.37l.69.69c.1.1.27.1.37,0l.96-.97v3.34c0,.15.12.26.26.26h.97c.14,0,.26-.12.26-.26v-3.34l.96.97c.1.1.27.1.37,0ZM144.12,49.57h-2.19c-.14,0-.26-.11-.26-.25v-.94c0-.14.11-.25.26-.25h.73c.14,0,.26-.11.26-.25v-6.37c0-.07-.03-.13-.07-.18l-4-4.05s-.11-.08-.18-.08h-.81c-.1,0-.19-.04-.26-.11-.24-.27-.81-.9-1.07-1.19-.05-.06-.01-.15.07-.15h2.68c.07,0,.13.03.18.08l4.85,4.91s.07.11.07.18v8.41c0,.14-.11.25-.26.25ZM136.05,40.27h3.94c.1,0,.18.08.18.18v1.09c0,.1-.08.18-.18.18h-3.94c-.1,0-.18-.08-.18-.18v-1.09c0-.1.08-.18.18-.18ZM136.05,45.04h5.4c.1,0,.18.08.18.18v1.09c0,.1-.08.18-.18.18h-5.4c-.1,0-.18-.08-.18-.18v-1.09c0-.1.08-.18.18-.18ZM140.71,42.65h-4.67c-.1,0-.18.08-.18.18v1.09c0,.1.08.18.18.18h4.67c.1,0,.18-.08.18-.18v-1.09c0-.1-.08-.18-.18-.18ZM133.56,49.57h5.77c.14,0,.26-.11.26-.25v-.94c0-.14-.11-.25-.26-.25h-4.31c-.14,0-.26-.11-.26-.25v-3.47c0-.14-.11-.25-.26-.25h-.95c-.14,0-.26.11-.26.25v4.92c0,.14.11.25.26.25Z"
        />
      </svg>
    </div>
  );
};
export default StudioSearch;
