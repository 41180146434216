import { Modal } from "antd";
import { Fragment } from "react";
import styles from "./styles";

export const pleaseWait = (
  message?: string,
  subMessage?: string,
  width?: number
) => {
  // eslint-disable-next-line no-nested-ternary
  const setWidth = width
    ? `${width}px`
    : message === undefined
    ? "auto"
    : "215px";
  return Modal.info({
    className: `css-${styles.pleaseWaitDialogLogo.name}`,
    icon: (
      <Fragment>
        <span className="___text">{message || "Please wait..."}</span>
        {subMessage && <span className="___subtext">{subMessage}</span>}
        <div css={styles.pleaseWaitDialogLogo} />
      </Fragment>
    ),
    okButtonProps: { style: { display: "none" } },
    maskClosable: false,
    width: setWidth,
  });
};

export const IsNullOrEmptyString = (text?: string) =>
  text === undefined || text === null || text.trim().length === 0;

export const getCookie = (name: string) => {
  const match = document.cookie.match(RegExp(`(?:^|;\\s*)${name}=([^;]*)`));
  return match ? match[1] : null;
};
