import { css } from "@emotion/react";

export default {
  table: css`
    width: 100%;

    tr {
      td {
        padding: 5px 25px 5px 0;

        p {
          margin-bottom: 0;
        }
      }

      th {
        text-align: left;
      }
    }
  `,
};
