import axios from "axios";
import { buildOrganisationSubscriptionsBaseUrl } from "src/api/base-url-helper";
import { ICreateOrgSubscriptionParams } from "src/api/organisation-subscriptions";
import { IOrganisation } from "../../../interfaces/organisation";
import { getJsonContentHeader, getAuthHeader } from "../../headers";

interface ICreateOrgParams
  extends IOrganisation,
    ICreateOrgSubscriptionParams {}

export type CreateOrgParams = Omit<ICreateOrgParams, "subscriptions">;

const createOrganisation = async (
  accessToken: string,
  params: CreateOrgParams,
  abortController?: AbortController
): Promise<IOrganisation | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController && { signal: abortController.signal }),
  };

  const { data } = await axios.post(
    buildOrganisationSubscriptionsBaseUrl(),
    params,
    options
  );
  return data as IOrganisation;
};

export default createOrganisation;
