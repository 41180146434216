/* eslint-disable no-param-reassign */
import {
  IProjectAttachment,
  INameLinkState,
  IProjectForm,
} from "src/pages/projects/client-project-view/interfaces";
import * as uuid from "uuid";

function initialiseNameLinks(nameLinks: Array<INameLinkState>) {
  if (nameLinks.length === 0) {
    nameLinks.push({
      name: "",
      link: "",
      isNew: true,
      key: uuid.v4(),
    });
  } else {
    nameLinks.forEach((nameLink) => {
      nameLink.key = uuid.v4();
    });
  }
}

export const initialiseAttachments = (
  project: IProjectForm,
  attachmentType: string,
  isCategory = false
): IProjectAttachment[] => {
  if (!project.attachments || project.attachments.length === 0) {
    return [];
  }

  return project.attachments.filter((attachment: IProjectAttachment) => {
    // Map ANTD props
    attachment.status = "done";
    attachment.percent = 100;
    attachment.type = attachment.contentType;
    attachment.uid = attachment.id;
    attachment.size = 0;

    if (isCategory) {
      return attachment.documentCategory === attachmentType;
    }

    return attachment.attachmentType === attachmentType;
  });
};

const mapProjectResponse = (project: IProjectForm): IProjectForm => {
  if (!project.attachments) {
    project.attachments = [];
  }
  initialiseNameLinks(project.music.musicAssets);

  initialiseNameLinks(project.music.finalVideo);

  return project;
};
export default mapProjectResponse;
