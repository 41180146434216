declare global {
  interface IWindow {
    __adobe_cep__: any;
  }
}

// eslint-disable-next-line no-underscore-dangle
const isAdobePremiere = window.__adobe_cep__ !== undefined;

export default isAdobePremiere;
