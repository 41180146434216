import React, {
  ReactElement,
  useEffect,
  useState,
  Fragment,
  useCallback,
} from "react";
import {
  getValidationResults,
  IValidationAttachment,
  IValidationResult,
} from "src/api/validation-results";
import useAuth from "src/auth/use-auth";
import { useTranslation } from "react-i18next";
import {
  errorToast,
  infoToast,
  successToast,
} from "src/components/toast-notification";
import getPresignedDownloadUrl from "src/api/attachments/get-presigned-download-url";
import { Button } from "@songtradr/component-library";
import Link from "src/app/assets/icons/validate-icons/link";
import dayjs from "dayjs";
import Expand from "src/app/assets/icons/validate-icons/expand";
import Download from "src/app/assets/icons/validate-icons/download";
import dayJSHelper from "src/sumo/src/utils/dayJSHelper";
import styles from "./styles";

export enum TAB {
  OVERVIEW = "Overview",
  MYRESEARCH = "MyResearch",
}

interface ITableProps {
  className?: string;
  updateTab: (key: string) => void;
  renderBeforeTable?: JSX.Element;
}

const ResultsTable = ({
  className,
  updateTab,
  renderBeforeTable,
}: ITableProps): ReactElement | null => {
  const { t } = useTranslation();
  const { getAccessToken, organisationId } = useAuth();
  const [validationResults, setValidationResults] = useState<
    IValidationResult[]
  >();

  useEffect(() => {
    const accessToken = getAccessToken();
    const abortController = new AbortController();

    (async () => {
      const data = await getValidationResults(
        accessToken,
        organisationId,
        abortController
      );

      if (data !== null) {
        setValidationResults(data);
      }
    })();

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDownloadLinks = useCallback(async () => {
    const accessToken = getAccessToken();
    if (!validationResults) return;
    const updatedValidationResults = validationResults
      .filter((result) => {
        return result.attachments && result.attachments.length;
      })
      .map(async (result) => {
        const updatedAttachments = await Promise.all(
          result.attachments!.map(async (attachment) => {
            const downloadData = await getPresignedDownloadUrl({
              fileName: attachment.name,
              attachmentId: attachment.id,
              projectId: result.id,
              organisationId,
              accessToken,
            });
            return {
              ...attachment,
              file_url: downloadData.data.url,
            };
          })
        );
        return { ...result, attachments: updatedAttachments };
      });
    const updatedResults = await Promise.all(updatedValidationResults);
    const inProgressCampaigns = validationResults.filter(
      (result) => !result.attachments || !result.attachments.length
    );
    setValidationResults([...updatedResults, ...inProgressCampaigns]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationResults]);

  useEffect(() => {
    if (!validationResults || !validationResults.length) {
      return;
    }

    const hasAttachmentsWithoutFileUrl = validationResults.some((result) => {
      return (
        result.attachments &&
        result.attachments.length &&
        result.attachments.some((attachment) => !attachment.file_url)
      );
    });
    if (hasAttachmentsWithoutFileUrl) {
      getDownloadLinks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationResults]);

  if (!validationResults || !validationResults.length) {
    return (
      <>
        {renderBeforeTable}
        <div css={styles.noResultsContainer}>
          <div css={styles.noResultsTitle}>
            Looks like you havent created a test yet!
          </div>
          <div css={styles.noResultsSubtitle}>
            Create your first test by clicking the button above or find out more
            on our Research Overview page.
          </div>
          <Button variant="primary" onClick={() => updateTab(TAB.OVERVIEW)}>
            Learn more
          </Button>
        </div>
      </>
    );
  }

  const handleDownloadFile = async (result: IValidationAttachment) => {
    if (!result.file_url) {
      errorToast({
        message: t("Validate##There was an issue downloading the file"),
      });
      return;
    }
    // Can't use `download` attribute from <a> because it only allows
    // downloads from the same origin
    let response;

    try {
      response = await fetch(result.file_url);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      errorToast({
        message: t("Validate##There was an issue downloading the file"),
      });
      return;
    }
    const fileTypeWithQueryString = result.file_url.split(".").pop();
    const fileType =
      fileTypeWithQueryString && fileTypeWithQueryString.includes("?")
        ? fileTypeWithQueryString.split("?").shift()
        : fileTypeWithQueryString;

    const downloadElement = document.createElement("a");

    downloadElement.href = URL.createObjectURL(await response?.blob());
    downloadElement.download = `${result.name}.${fileType}`;

    document.body.append(downloadElement);
    downloadElement.click();
    downloadElement.remove();

    setTimeout(() => URL.revokeObjectURL(downloadElement.href), 60000);
  };

  const handleCopyFileLink = (result?: IValidationAttachment) => {
    if (!result || !result.file_url) {
      infoToast({ message: "Unable to copy file link at this time." });
      return;
    }

    void navigator.clipboard.writeText(result.file_url);
    successToast({ message: t("Validate##Document link copied") });
  };

  return (
    <>
      {renderBeforeTable}
      <table css={styles.tableBase} className={className}>
        <thead>
          <tr>
            <th>Test name</th>
            <th>Document(s)</th>
            <th css={styles.dateColumn}>Date</th>
            <th>Status</th>
            <th aria-label="icons" css={styles.linksColumn} />
          </tr>
        </thead>
        <tbody>
          {validationResults.map((validationResult) => {
            return (
              <Fragment key={`${validationResult.id}`}>
                <tr
                  key={`${validationResult.id}`}
                  className={
                    !validationResult.attachments ||
                    validationResult.attachments.length <= 1
                      ? "finalRow"
                      : ""
                  }
                >
                  <td>{validationResult.campaign_name}</td>
                  <td>
                    {validationResult.attachments &&
                    validationResult.attachments.length > 0 ? (
                      validationResult.attachments[0].name
                    ) : (
                      <i>-</i>
                    )}
                  </td>

                  <td css={styles.dateColumn}>
                    {validationResult.attachments &&
                    validationResult.attachments.length > 0
                      ? dayjs(
                          validationResult.attachments[0].uploaded.dateTime
                        ).format(dayJSHelper.format.FullMonthDate)
                      : null}
                  </td>
                  <td>
                    {validationResult.attachments &&
                    validationResult.attachments.length > 0 ? (
                      <span
                        css={[styles.statusPill, styles.statusPillComplete]}
                      >
                        Complete
                      </span>
                    ) : (
                      <span css={[styles.statusPill, styles.statusPillPending]}>
                        Pending
                      </span>
                    )}
                  </td>
                  <td css={styles.linksColumn}>
                    {validationResult.attachments &&
                      validationResult.attachments.length > 0 && (
                        <Fragment>
                          <button
                            type="button"
                            onClick={() =>
                              handleCopyFileLink(
                                validationResult.attachments![0]
                              )
                            }
                          >
                            <Link />
                          </button>
                          <a
                            href={validationResult.attachments[0].file_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Expand />
                          </a>
                          <button
                            type="button"
                            onClick={() =>
                              handleDownloadFile(
                                validationResult.attachments![0]
                              )
                            }
                          >
                            <Download />
                          </button>
                        </Fragment>
                      )}
                  </td>
                </tr>
                <Fragment>
                  {validationResult.attachments &&
                    validationResult.attachments
                      .slice(1)
                      .map((attachment, index, attachmentArray) => {
                        return (
                          <tr
                            className={
                              index + 1 === attachmentArray.length
                                ? "finalRow"
                                : ""
                            }
                            key={attachment.id}
                          >
                            <td />
                            <td css={styles.dateColumn}>{attachment.name}</td>
                            <td css={styles.dateColumn}>
                              {dayjs(attachment.uploaded.dateTime).format(
                                dayJSHelper.format.FullMonthDate
                              )}
                            </td>
                            <td />
                            <td css={styles.linksColumn}>
                              <button
                                type="button"
                                onClick={() => handleCopyFileLink(attachment)}
                              >
                                <Link />
                              </button>
                              <a
                                href={attachment.file_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Expand />
                              </a>
                              <button
                                type="button"
                                onClick={() => handleDownloadFile(attachment)}
                              >
                                <Download />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                </Fragment>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default ResultsTable;
