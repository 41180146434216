import { css, keyframes } from "@emotion/react";
import theme from "src/theme";

interface IProps {
  darkMode?: boolean;
}

export const rotate360 = keyframes`
  0% {
    -webkit-transform: scale(0.9, 0.9);
    opacity: 0.7;
  }
  50% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9, 0.9);
    opacity: 0.7;
  }
`;

export default {
  loadingContainer: css`
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
  `,
  loading: ({ darkMode }: IProps) => css`
    animation: ${rotate360} 1s linear infinite;
    transform-box: fill-box;
    transform-origin: 50% 50%;
    width: 32px;
    height: 32px;
    svg {
      path {
        fill: ${darkMode ? theme.colors.white : theme.colors.black};
      }
    }
  `,
};
