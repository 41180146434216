import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import isAdobePremiere from "src/utils/isAdobePremiere";
import useOrganisation from "src/providers/organisation/hooks";
import styles from "./styles";

interface IServiceTileProps {
  title: string;
  description: string;
  link: string;
  image: string;
}

const ServiceTile = ({
  title,
  description,
  link,
  image,
}: IServiceTileProps) => {
  const history = useHistory();
  const { organisation } = useOrganisation();

  const handleServiceTileClick = useCallback(() => {
    if (!link) return;
    history.push(link);
  }, [history, link]);

  return (
    <div
      className="service-tile"
      css={styles.serviceTileContainer}
      onClick={isAdobePremiere ? undefined : handleServiceTileClick}
      onKeyDown={isAdobePremiere ? undefined : handleServiceTileClick}
      role="button"
      tabIndex={0}
    >
      {isAdobePremiere ? (
        <div>
          <img css={styles.image} src={image} alt="service" />
          <span css={styles.title}>{title}</span>
          <span css={styles.description}>{description}</span>
        </div>
      ) : (
        <a href={`mailto:${organisation?.contact?.email}?subject=${title}`}>
          <img css={styles.image} src={image} alt="service" />
          <span css={styles.title}>{title}</span>
          <span css={styles.description}>{description}</span>
        </a>
      )}
    </div>
  );
};

export default ServiceTile;
