import { allowedApplications } from "src/constants";
import { eApplicationType } from "src/interfaces/auth";

const getApplicationsPermissions = (
  allApplications: eApplicationType[],
  selectedPermissions: eApplicationType[]
) => {
  const isApplicationAdmin = selectedPermissions.includes(
    eApplicationType.TeamManagement
  );
  const applicationPermissions = allApplications.includes(
    eApplicationType.LibraryFull
  )
    ? allApplications.filter(
        (permission) => permission !== eApplicationType.LibraryReadOnly
      )
    : allApplications;
  const adminAdditionalApps = applicationPermissions.filter((applicationType) =>
    allowedApplications.includes(applicationType)
  );
  const additionalApplications = isApplicationAdmin
    ? adminAdditionalApps
    : selectedPermissions;

  return {
    isApplicationAdmin,
    additionalApplications,
  };
};

export default getApplicationsPermissions;
