import axios from "axios";
import buildOrganisationsBaseUrl from "src/api/base-url-helper";
import { IOrganisation } from "../../../interfaces/organisation";
import { getJsonContentHeader, getAuthHeader } from "../../headers";

const updateOrganisation = (
  organisationId: string,
  accessToken: string,
  request: IOrganisation
): Promise<IOrganisation | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
  };

  return axios.patch(
    buildOrganisationsBaseUrl(organisationId),
    request,
    options
  );
};

export default updateOrganisation;
