import { createContext } from "react";
import { IAvailableReport, IReport } from "src/api/power-bi";

export interface IPowerBiProviderContext {
  isLoading: boolean;
  availableReports: IAvailableReport[] | null;
  selectedReport: IReport | null;
  getReportById: (reportId: string) => Promise<void>;
  clearTimer: () => void;
}

export default createContext<IPowerBiProviderContext>({
  isLoading: true,
  availableReports: null,
  selectedReport: null,
  getReportById: async () => {},
  clearTimer: () => {},
});
