import { css } from "@emotion/react";
import {
  minLarge,
  semanticGrayBg,
  semanticGrayBgWeakestHover,
} from "@songtradr/component-library";
import theme from "src/theme";
import { white } from "@songtradr/component-library/tokens";

export default {
  mainContentContainer: css`
    flex-grow: 1;

    @media ${minLarge} {
      display: flex;
      margin-top: 4rem;
    }
  `,
  leftColumn: css`
    @media ${minLarge} {
      width: 33%;
      border-right: 1px solid ${semanticGrayBgWeakestHover};
      margin-right: 2rem;
      padding-right: 2rem;
    }
    flex-shrink: 0;
  `,
  rightColumn: css`
    flex-grow: 1;
    min-width: 0;
  `,
  footerContainer: css`
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 0 0.25rem 0;
    margin: 6rem 0 0 0;
    border-top: 1px solid ${semanticGrayBgWeakestHover};
  `,
  subHeading: css`
    letter-spacing: -0.02em;
    font-weight: 250;
    color: ${white};
    font-family: ${theme.fonts.sofiaPro};
    margin: 0 0 1.5rem 0;
    font-size: 1.5rem;
    line-height: normal;
  `,
  flexTableRow: css`
    display: flex;
    border-bottom: 1px solid ${semanticGrayBgWeakestHover};
    padding: 0.5rem 0;
    align-items: center;
  `,
  flexTableCall: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 0;
  `,
  pageContainer: css`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  tableCellUrl: css`
    width: 80%;

    a {
      color: ${white};
    }
  `,
  tableCellCheckbox: css`
    width: 15%;
    margin-left: 2rem;
  `,
  tableCellHeading: css`
    color: ${semanticGrayBg};
  `,
  marginTop2: css`
    margin-top: 2rem;
  `,
  marginTop4: css`
    margin-top: 4rem;
  `,
  backBtn: css`
    margin-right: 3rem;
  `,
};
