import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  floatingLabelInput: css`
    input {
      height: 64px;
      padding: 1.7rem 0.735rem 1rem 0.735rem;
      border: 1px solid ${theme.colors.secondary.mediumGrey};
      background-color: ${theme.colors.background.landingDarkGray};

      :disabled {
        background-color: ${theme.colors.background.landingDarkGray};
      }
    }
  `,
  dropdownContainer: css`
    .dropdown {
      > div {
        background: ${theme.colors.background.landingDarkGray} !important;
        border: 1px solid ${theme.colors.secondary.mediumGrey} !important;
        color: ${theme.colors.white} !important;
        height: 64px;

        &:hover,
        :focus,
        :active {
          border-color: ${theme.colors.white} !important;
        }
      }

      .dropdown-options {
        &__menu-list {
          background: ${theme.colors.background.landingDarkGray} !important;
          border: 1px solid ${theme.colors.secondary.mediumGrey} !important;
          color: ${theme.colors.white} !important;
        }
        &__option {
          background: ${theme.colors.background.landingDarkGray} !important;
          &:hover {
            background: ${theme.colors.secondary.mediumGrey} !important;
          }
        }
        &__placeholder {
          margin-top: 12px;
          margin-left: 3px;
        }
        &__value-container {
          padding: 0 8px;
          height: 64px;
        }
        &__single-value {
          color: ${theme.colors.white} !important;
          font-size: 1rem;
          line-height: 58px;
          margin-top: 8px;
          margin-left: 4px;
        }
        &__input-container {
          padding-top: 8px;
        }
        &__input {
          color: ${theme.colors.white} !important;
        }
        &__control--is-focused {
          box-shadow: none !important;
        }
      }
    }
  `,
  selectionInput: css`
    border: none;

    input {
      padding: 8px 0 !important;
    }
  `,
};
