import {
  IFinalTrackOwnership,
  IFeeSplitDetailState,
  INameLinkState,
  IProjectAttachmentsUpdate,
  IProjectForm,
  IProjectMusicSection,
} from "src/pages/projects/client-project-view/interfaces";

import { PickKeys } from "ts-essentials";

export enum ProjectActions {
  STORE_PROJECT = "STORE_PROJECT",
  ADD_NAME_LINK_ROW = "ADD_NAME_LINK_ROW",
  ADD_FINAL_TRACK_LINKS_ROW = "ADD_FINAL_TRACK_LINKS_ROW",
  ADD_FEE_SPLIT_ROW = "ADD_FEE_SPLIT_ROW",
  STORE_ATTACHMENTS = "STORE_ATTACHMENTS",
}

interface IStoreProject {
  type: ProjectActions.STORE_PROJECT;
  value: {
    project: IProjectForm;
  };
}

interface IAddNameLinkRow {
  type: ProjectActions.ADD_NAME_LINK_ROW;
  value: PickKeys<IProjectMusicSection, Array<INameLinkState>>;
}

interface IAddFinalTrackLinksRow {
  type: ProjectActions.ADD_FINAL_TRACK_LINKS_ROW;
}

interface IAddFeeSplitRow {
  type: ProjectActions.ADD_FEE_SPLIT_ROW;
  value: PickKeys<IFinalTrackOwnership, Array<IFeeSplitDetailState>>;
}

interface IStoreAttachments {
  type: ProjectActions.STORE_ATTACHMENTS;
  value: IProjectAttachmentsUpdate;
}

export type ProjectAction =
  | IStoreProject
  | IAddNameLinkRow
  | IAddFinalTrackLinksRow
  | IAddFeeSplitRow
  | IStoreAttachments;
