import React, { ReactElement } from "react";

const SmallChevronIcon = (
  props: React.SVGProps<SVGSVGElement>
): ReactElement => {
  return (
    <svg
      width="8"
      height="5"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.115085 1.14874L1.14818 0.115646C1.30162 -0.0378 1.55118 -0.0378 1.70463 0.115646L5.72177 4.13279C5.87522 4.28624 6.12478 4.28624 6.27823 4.13279L10.2954 0.115085C10.4488 -0.0383616 10.6984 -0.0383616 10.8518 0.115085L11.8849 1.14818C12.0384 1.30162 12.0384 1.55118 11.8849 1.70463L6.27823 7.31132C6.12478 7.46476 5.87522 7.46476 5.72177 7.31132L0.115085 1.70463C-0.0383616 1.55118 -0.0383616 1.30162 0.115085 1.14818L0.115085 1.14874Z"
        fill="white"
      />
    </svg>
  );
};

export default SmallChevronIcon;
