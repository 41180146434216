import { Progress, Typography } from "antd";
import { format, getUnixTime, parseISO } from "date-fns";
import React, { ReactElement, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { LicenseFixedDurations, TermStatus } from "src/constants";
import { IProjectTerm } from "src/pages/projects/client-project-view/interfaces";
import theme from "src/theme";
import termUtils from "src/utils/projects/terms";
import styles from "../styles";

const strokeColor = (status: TermStatus) => {
  switch (status) {
    case TermStatus.ACTIVE:
      return theme.colors.functional.successGreen;
    case TermStatus.EXPIRED:
      return theme.colors.secondary.blueOnBlackLight;
    case TermStatus.RENEW:
      return theme.colors.functional.orange;
    case TermStatus.URGENT:
      return theme.colors.functional.red;
    default:
      return theme.colors.background.veryLightGray;
  }
};

const trailColor = (status: TermStatus) => {
  switch (status) {
    case TermStatus.ACTIVE:
      return theme.colors.functional.successGreenLight;
    case TermStatus.EXPIRED:
      return theme.colors.secondary.blueOnBlackLight;
    case TermStatus.RENEW:
      return "#FA961433";
    case TermStatus.URGENT:
      return theme.colors.functional.alertRedLight;
    default:
      return theme.colors.background.veryLightGray;
  }
};

interface IProps {
  term: IProjectTerm;
}

const TermProgress = ({ term }: IProps): ReactElement => {
  const { t } = useTranslation();
  const now = new Date();
  const unixStart =
    term.startDate && getUnixTime(parseISO(term.startDate)) * 1000;

  const fixedDurationEndDate =
    term.startDate &&
    term.fixedDuration &&
    termUtils.calculateEndDate(term.startDate, term.fixedDuration);

  const unixEnd = term.endDate
    ? getUnixTime(parseISO(term.endDate)) * 1000
    : fixedDurationEndDate &&
      getUnixTime(parseISO(new Date(fixedDurationEndDate).toISOString())) *
        1000;

  const total = unixEnd && unixStart && unixEnd - unixStart;
  const current = unixStart && now.getTime() - unixStart;
  const percentage = current && total && Math.round((current / total) * 100);

  const licenseEndDate = term.endDate
    ? new Date(
        new Date(term.endDate || "").setDate(
          new Date(term.endDate || "").getDate()
        )
      ).toISOString()
    : "";

  const status = termUtils.determineTermStatus(
    term.endDate
      ? licenseEndDate
      : fixedDurationEndDate && new Date(fixedDurationEndDate).toISOString()
  );

  if (
    term.fixedDuration === LicenseFixedDurations.InPerpetuity ||
    term.fixedDuration === LicenseFixedDurations.Archival
  ) {
    return (
      <Fragment>
        <Progress
          strokeColor={theme.colors.functional.infoBlue}
          trailColor={theme.colors.functional.infoBlue}
          percent={percentage || 0}
          showInfo={false}
        />
        <Typography css={styles.progressDates}>
          {t("ClientProjectViewPage##Starts")}{" "}
          {term.startDate && format(parseISO(term.startDate), "MMM dd, yyyy")}
        </Typography>
      </Fragment>
    );
  }

  const calculateEndDate = () => {
    if (!term.fixedDuration && term.endDate) {
      return format(parseISO(term.endDate ? term.endDate : ""), "MMM dd, yyyy");
    }
    if (licenseEndDate) {
      return format(parseISO(licenseEndDate), "MMM dd, yyyy");
    }
    return "";
  };

  return (
    <Fragment>
      <Progress
        strokeColor={status && strokeColor(status)}
        trailColor={status && trailColor(status)}
        percent={percentage || 0}
        showInfo={false}
      />
      <div css={styles.progressDates}>
        <Typography>
          {t("ClientProjectViewPage##Starts")}{" "}
          {term.startDate && format(parseISO(term.startDate), "MMM dd, yyyy")}
        </Typography>
        <Typography>
          {t("ClientProjectViewPage##Ends")} {calculateEndDate()}
        </Typography>
      </div>
    </Fragment>
  );
};

export default TermProgress;
