import { ReactElement } from "react";

interface IProps {
  fill?: string;
}

const ArrowUpIcon = ({ fill = "white" }: IProps): ReactElement => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.0242 5.15975L6.25861 1.92535C6.5515 1.63246 6.5515 1.15758 6.25861 0.864689C5.96571 0.571796 5.49084 0.571796 5.19795 0.864689L0.60175 5.46088C0.453074 5.60956 0.379868 5.80513 0.382131 5.99998C0.379868 6.19483 0.453074 6.3904 0.60175 6.53907L5.19795 11.1353C5.49084 11.4282 5.96571 11.4282 6.25861 11.1353C6.5515 10.8424 6.5515 10.3675 6.25861 10.0746L2.84375 6.65975H10.868C11.2823 6.65975 11.618 6.32397 11.618 5.90975C11.618 5.49554 11.2823 5.15975 10.868 5.15975H3.0242Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowUpIcon;
