import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "src/auth/use-auth";
import {
  formFields,
  TGenericAuditFormShape,
  TVideosToProcess,
} from "src/pages/brand-audit/components/brand-audit-form/interfaces";
import { getSingleAudit, updateExistingAudit } from "src/api/brand-audit";
import axios, { AxiosError } from "axios";
import { errorToast, successToast } from "src/components/toast-notification";
import BrandAuditForm from "src/pages/brand-audit/components/brand-audit-form";
import { pick } from "lodash";
import STLoadingLogo from "src/components/st-loading-logo";
import styles from "./styles";
import { IProps } from "./interfaces";

const BrandAuditEdit = ({ auditId }: IProps): ReactElement | null => {
  const history = useHistory();
  const abortController = useRef<AbortController>();
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessToken } = useAuth();
  const [
    defaultFormData,
    setDefaultFormData,
  ] = useState<TGenericAuditFormShape>({});
  const [
    defaultVideosToProcess,
    setDefaultVideosToProcess,
  ] = useState<TVideosToProcess>({});

  const handleSetDefaultData = async (controller: AbortController) => {
    let auditData = null;

    try {
      auditData = await getSingleAudit(getAccessToken(), controller, auditId);
    } catch (error) {
      if (axios.isAxiosError(error) && error.code === AxiosError.ERR_CANCELED) {
        return;
      }

      if (axios.isAxiosError(error) && error?.response?.status === 404) {
        errorToast({ message: "Audit does not exist" });
        history.push("/admin/brand-audit");
        return;
      }

      // eslint-disable-next-line no-console
      console.error(error);

      errorToast({ message: "There was an issue fetching the audit data" });
      history.push(`/admin/brand-audit/${auditId}`);
      return;
    }

    setDefaultFormData(pick(auditData, formFields));

    if (!auditData?.files) {
      return;
    }

    const videos = auditData.files.reduce((acc: TVideosToProcess, file) => {
      // eslint-disable-next-line no-param-reassign
      acc[file.url] = {
        url: file.url,
        skipLalal: file.skipLalal,
      };

      return acc;
    }, {});

    setDefaultVideosToProcess(videos);
  };

  useEffect(() => {
    const controller = new AbortController();
    abortController.current = controller;

    (async () => {
      setIsLoading(true);
      await handleSetDefaultData(controller);
      setIsLoading(false);
    })();

    return () => controller.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = async (
    formData: TGenericAuditFormShape,
    videosToProcess: TVideosToProcess
  ) => {
    if (!abortController.current) {
      return;
    }

    const payload = {
      ...formData,
      files: Object.values(videosToProcess),
    };

    try {
      await updateExistingAudit(
        getAccessToken(),
        abortController.current,
        auditId,
        payload
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.code === AxiosError.ERR_CANCELED) {
        return;
      }

      errorToast({ message: "There was an updating the audit" });

      // eslint-disable-next-line no-console
      console.error(error);

      // Re-throw so we can reset the loading state of the form
      throw error;
    }

    successToast({ message: "Brand audit was successfully updated" });
    history.push(`/admin/brand-audit/${auditId}`);
  };

  if (isLoading) {
    return (
      <div css={styles.loadingSpinnerContainer}>
        <STLoadingLogo />
      </div>
    );
  }

  return (
    <BrandAuditForm
      isEditing
      defaultVideosToProcess={defaultVideosToProcess}
      defaultFormData={defaultFormData}
      handleFormSubmit={handleFormSubmit}
      backBtnLink={`/admin/brand-audit/${auditId}`}
    />
  );
};

export default BrandAuditEdit;
