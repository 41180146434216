import { Button, Label } from "@songtradr/component-library";
import { Input } from "antd";
import React, { Fragment } from "react";
import PermissionSelector from "../permission-selector";
import GeneratePasscode from "../generate-passcode";
import styles from "../../../../styles";
import { IPassCodeData } from "../../interfaces";

const passcodeSteps = (
  newPasscodeData: IPassCodeData,
  setNewPasscodeData: React.Dispatch<React.SetStateAction<any>>,
  selectedPermissions: any,
  handlePermissionSelect: (value: boolean, key: string) => void,
  handleGeneratedPasscode: (value: string) => void
) => {
  return [
    {
      content: (
        <Fragment>
          <h2>Step 1</h2>
          <h1>Passcode name</h1>
          <p>
            The passcode name serves exclusively as an internal reference to
            denote the user type of permissions
            <br />
          </p>
          <Label htmlFor="input-id">Passcode name*</Label>
          <Input
            id="input-id"
            onChange={(event) =>
              setNewPasscodeData({
                ...newPasscodeData,
                name: event.target.value,
              })
            }
            value={newPasscodeData.name}
            data-testid="pass-code-input"
          />
        </Fragment>
      ),
      validationCondition: newPasscodeData.name.length > 0,
    },
    {
      content: (
        <Fragment>
          <h2>Step 2</h2>
          <h1>Set permissions</h1>
          <p>
            Please choose the module(s) you wish to share with this group of
            users.
          </p>
          <PermissionSelector
            isSelected={selectedPermissions.Search}
            handleSelect={handlePermissionSelect}
            title="Search"
            description="Search for music in your pre-cleared catalog"
            data-testid="search-full-permission"
          />
          <PermissionSelector
            isSelected={selectedPermissions.SearchReadOnly}
            handleSelect={handlePermissionSelect}
            title="SearchReadOnly"
            description="Search for music in your pre-cleared catalog"
            data-testid="search-read-only-permission"
          />
          <PermissionSelector
            isSelected={selectedPermissions.Insights}
            handleSelect={handlePermissionSelect}
            title="Insights"
            description="Check artist and song performance for fit"
            data-testid="insights-permission"
          />
          <PermissionSelector
            isSelected={selectedPermissions.Projects}
            handleSelect={handlePermissionSelect}
            title="Projects"
            description="Track the status of your Music Licenses and requests"
            data-testid="projects-permission"
          />
          <PermissionSelector
            isSelected={selectedPermissions.Resources}
            handleSelect={handlePermissionSelect}
            title="Resources"
            description="Retrieve your Creative Service assets"
            data-testid="resources-permission"
          />
          <PermissionSelector
            isSelected={selectedPermissions.Measurement}
            handleSelect={handlePermissionSelect}
            title="Measurement"
            description="Measure your music choices with real audiences"
            data-testid="measurement-permission"
          />
        </Fragment>
      ),
    },
    {
      content: (
        <Fragment>
          <h2>Step 3</h2>
          <h1>Passcode Generator</h1>
          <p>
            Enable and share this code to grant users access to your account
          </p>
          <GeneratePasscode
            updatePasscodeData={handleGeneratedPasscode}
            passcode={newPasscodeData.code}
          />
          <p>Would you like the passcode to be active?</p>
          <div css={styles.statusButtons(newPasscodeData.status)}>
            <Button
              data-testid="disable-pass-code-option"
              className="disabled"
              onClick={() => {
                setNewPasscodeData({
                  ...newPasscodeData,
                  status: "Inactive",
                });
              }}
              variant="subtle"
            >
              Disabled
            </Button>
            <Button
              data-testid="enable-pass-code-option"
              className="enabled"
              onClick={() => {
                setNewPasscodeData({
                  ...newPasscodeData,
                  status: "Active",
                });
              }}
              variant="subtle"
            >
              Enabled
            </Button>
          </div>
        </Fragment>
      ),
    },
  ];
};

export default passcodeSteps;
