import React, { ReactElement } from "react";
import appStyles from "src/app/styles";
import CopyrightFooter from "src/components/copyright-footer";
import styles from "./styles";

const LinkError = (): ReactElement => {
  return (
    <div css={[appStyles.container, styles.innerContainer]}>
      <div css={[appStyles.guestPageInnerContainer, styles.contentContainer]}>
        <h1 css={styles.h1}>Oops! Something’s not quite right.</h1>
        <h2 css={styles.h2}>
          It looks like you&apos;re using an outdated link. Please check your
          inbox for the most recent email to complete your signup.
        </h2>
        <hr css={styles.divider} />
        <h3 css={styles.h3}>Why this happens?</h3>
        <p css={styles.p}>
          To ensure the security of your account, each invitation email we send
          contains a unique link. If you&apos;ve received multiple invitations,
          only the link from the most recent email will work.
        </p>
        <h3 css={styles.h3}>Need assistance?</h3>
        <p css={styles.p}>
          Our team is here to help. If you&apos;re having trouble finding the
          latest email or have any other questions, please reach out to your
          account manager for further assistance.
        </p>
      </div>
      <div css={[appStyles.container, styles.copyrightFooter]}>
        <CopyrightFooter />
      </div>
    </div>
  );
};

export default LinkError;
