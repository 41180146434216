import React, { ReactElement } from "react";

const InsightsIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_475_2686)">
        <path d="M9.17032 23.3953H0.433526C0.193848 23.3953 0 23.2015 0 22.9618V15.4568C0 15.2172 0.194468 15.0233 0.433526 15.0233H2.04377C2.28345 15.0233 2.47729 15.2178 2.47729 15.4568V20.4839C2.47729 20.7236 2.67114 20.9174 2.91082 20.9174H9.17032C9.41 20.9174 9.60385 21.1113 9.60385 21.351V22.9612C9.60385 23.2009 9.41 23.3947 9.17032 23.3947V23.3953Z" />
        <path d="M6.08604 17.8443L4.75573 16.937C4.55817 16.802 4.50676 16.5326 4.64178 16.3344L9.88868 8.63996C10.0187 8.44921 10.2758 8.39409 10.4727 8.51424L14.2165 10.7983C14.4073 10.9147 14.655 10.867 14.7888 10.6887L21.932 1.1734C22.0757 0.982025 22.3476 0.943007 22.539 1.08669L23.8266 2.05345C24.0179 2.19714 24.0569 2.46902 23.9133 2.66039L15.4192 13.9748C15.2855 14.1532 15.0371 14.2009 14.847 14.0844L11.193 11.8555C10.996 11.7353 10.739 11.7905 10.609 11.9812L6.68864 17.7304C6.55363 17.9279 6.28422 17.9794 6.08604 17.8443Z" />
        <path d="M20.5209 20.918H13.704C13.4646 20.918 13.2705 21.1121 13.2705 21.3516V22.9618C13.2705 23.2012 13.4646 23.3953 13.704 23.3953H20.5209C20.7604 23.3953 20.9545 23.2012 20.9545 22.9618V21.3516C20.9545 21.1121 20.7604 20.918 20.5209 20.918Z" />
        <path d="M2.08185 1.56976H0.471612C0.232182 1.56976 0.0380859 1.76386 0.0380859 2.00329V9.20354C0.0380859 9.44297 0.232182 9.63707 0.471612 9.63707H2.08185C2.32128 9.63707 2.51538 9.44297 2.51538 9.20354V2.00329C2.51538 1.76386 2.32128 1.56976 2.08185 1.56976Z" />
      </g>
      <defs>
        <clipPath id="clip0_475_2686">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default InsightsIcon;
