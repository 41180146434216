import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";

const sharedHeaderStyling = css`
  width: 100%;
  font-family: ${theme.fonts.proximaNova};

  .ant-tabs-tab {
    margin: 0 3rem 0 0;
  }
`;

export default {
  profilePage: css`
    background-color: ${theme.colors.background.landingDarkGray};
    color: ${theme.colors.white} !important;
    height: inherit;
    font-family: ${theme.fonts.sofiaPro};
    min-height: calc(100vh - 64px - 80px);
  `,
  header: css`
    padding-top: 24px;
    margin-bottom: 24px;

    @media ${queries.large} {
      padding-top: 0;
      margin-bottom: 48px;
    }
  `,
  backBtn: css`
    margin-bottom: 24px;
  `,
  yourProfile: css`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.white};
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;

    @media ${queries.large} {
      flex-direction: row;
      align-items: center;
      font-size: 2rem;
      font-weight: 700;
      line-height: 2.25rem;
      letter-spacing: -0.64px;
    }
  `,
  instruction: css`
    color: ${theme.colors.white};
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
  `,
  profileContent: css`
    display: grid;
    grid-template-columns: 1fr;

    @media ${queries.large} {
      display: grid;
      grid-template-columns: 1.5fr 2.5fr;
    }
  `,
  detailsContainer: css`
    width: 100%;

    @media ${queries.large} {
      width: 100%;
      border-left: 1px solid ${theme.colors.background.darkGray100};
      padding-left: 48px;
    }
  `,
  userDetails: css`
    color: ${theme.colors.secondary.blueOnBlackLight};
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
    display: flex;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    flex-direction: column !important;
    margin-bottom: 36px;

    @media ${queries.large} {
      flex-direction: row !important;
      align-items: center !important;
    }
  `,
  userEmail: css`
    display: flex;
    align-items: flex-start;

    p {
      font-family: ${theme.fonts.sofiaPro} !important;
      margin-bottom: 0.5rem !important;

      @media ${queries.large} {
        margin-bottom: 0 !important;
      }
    }
  `,
  dividerLine: css`
    display: none;

    @media ${queries.large} {
      display: block;
      margin-right: 1rem;
      height: 1.2rem !important;
      width: 1px;
      border: 1px solid ${theme.colors.background.darkGray100};
      margin-left: 1rem;
    }
  `,
  sectionHeader: css`
    color: #ccc;
    margin-bottom: 1.5rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 3.3px;
    text-transform: uppercase;
  `,
  headerContainer: css`
    display: none;
    width: 100%;
    background-color: ${theme.colors.background.basaltBlack};
    box-shadow: 0 0 0.313rem -0.15rem var(--colors-dark-gray);

    @media ${queries.large} {
      display: flex;
      font-size: 2.25rem !important;
      margin-bottom: 0 !important;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
    }

    @media ${queries.large} {
      padding: 2.325rem 3rem 1rem 3rem;
    }
  `,
  stickyHeaderContainer: css`
    ${sharedHeaderStyling}
    position: fixed;
    z-index: 1051;
    @media ${queries.large} {
      margin-top: 0;
    }
  `,
  container: css`
    ${sharedHeaderStyling}
  `,
  contentContainer: css`
    padding-top: 1.5rem;
    border-radius: 0;

    :empty {
      padding: 0.125rem 0 0 0 !important;
    }

    @media ${queries.large} {
      border-radius: 5px;
      padding: 1.5rem 3.125rem;
    }
  `,
  tabs: css`
    .ant-tabs-nav-wrap {
      padding-left: 1.25rem;
    }

    @media ${queries.large} {
      .ant-tabs-tabpane {
        padding-left: 1.25rem !important;
        padding-right: 1.25rem !important;
      }

      .ant-tabs-tab {
        padding-top: 0.3125rem !important;
      }
    }

    @media ${queries.large} {
      .ant-tabs-nav-wrap {
        padding-left: 3rem !important;
      }

      .ant-tabs-tabpane {
        padding-left: 3.125rem !important;
        padding-right: 3.125rem !important;
      }

      .ant-tabs-top {
        padding-top: 0.625rem !important;
      }
    }
  `,
  tabLink: css`
    color: ${theme.colors.black};

    :hover {
      color: ${theme.colors.black};
      text-decoration: none;
    }

    :visited {
      color: ${theme.colors.black};
    }
  `,
  deleteImageBtn: css`
    display: none;

    @media ${queries.large} {
      display: flex;
      margin-left: 24px;

      button {
        color: ${theme.colors.white} !important;

        :hover {
          color: ${theme.colors.functional.brightOrangeHover} !important;
        }
      }
    }
  `,
  profileCircle: css`
    display: flex;
    justify-content: center;
    height: 160px;
    width: 160px;
    border-radius: 50%;
    text-align: center;
    font-weight: 800;
    font-size: 2rem;
    align-items: center;
    line-height: 1.25rem;
    background: ${theme.colors.secondary.userIconBackground};
    margin-right: 28px;
  `,
  mobileDeleteBtn: css`
    position: relative;
    width: 48px;

    @media ${queries.large} {
      display: block;
    }
  `,
  buttonContainer: css`
    width: 48px;
    height: 48px;
    position: relative;
    bottom: 50px;
    left: 120px;
    display: flex;
    border: 1px solid ${theme.colors.background.darkGray100};
    background: #141414;
    justify-content: center;
    align-items: center;
    border-radius: 48px;

    @media ${queries.large} {
      display: none;
    }
  `,
  drawerButtonContainer: css`
    width: 48px;
    height: 48px;
    position: relative;
    bottom: 50px;
    left: 120px;
    display: flex;
    border: 1px solid ${theme.colors.background.darkGray100};
    background: ${theme.colors.background.basaltBlack};
    justify-content: center;
    align-items: center;
    border-radius: 48px;

    :hover {
      border: 1px solid ${theme.colors.white};
    }

    @media ${queries.large} {
      bottom: 10px;
      left: 200px;
    }
  `,
  guidelineText: css`
    color: ${theme.colors.white};
    margin-top: 1.5rem;
    margin-right: 0 !important;
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;
    text-transform: none;

    button {
      text-decoration: underline;
      background: none;
      border: none;
      font-size: 1rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5rem;
    }
  `,
  linkText: css`
    :hover {
      color: ${theme.colors.functional.brightOrangeHover};
    }
  `,
  modalContainer: css`
    .ant-modal-body {
      background: #141414;
      border: 1px solid #474747;
    }
    .ant-modal-close-icon {
      color: ${theme.colors.white};

      :hover {
        stroke: ${theme.colors.functional.brightOrange};
      }
    }
    .ant-modal-close g {
      stroke: ${theme.colors.white};

      :hover {
        stroke: ${theme.colors.functional.brightOrange};
      }
    }
  `,
  modalTitle: css`
    color: ${theme.colors.white} !important;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
  `,
  modalContent: css`
    color: ${theme.colors.white} !important;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 300;
    ul {
      li {
        margin: 1rem 0 1rem 0;
        list-style-type: none;
      }
    }
  `,
  drawerImageUploadContainer: css`
    width: 402px;
    height: 226px;
    flex-shrink: 0;
    border-radius: 8px;
    margin-top: 1rem;

    .ant-upload.ant-upload-drag {
      position: relative;
      background: none;
      border: 2px dashed #555;
      border-radius: 1rem;
      cursor: pointer;
      transition: border-color 0.3s;
      padding: 1rem;
    }

    .ant-upload.ant-upload-drag .ant-upload-drag-container {
      color: ${theme.colors.white};
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
    }

    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
      border-color: ${theme.colors.white};
    }

    svg {
      margin-bottom: 0.5rem;
    }

    button {
      margin-top: 1rem;
    }
  `,
  uploadImageViewer: css`
    width: 402px;
    height: 226px;
    flex-shrink: 0;
    border-radius: 8px;
    display: flex;
    margin-top: 24px;
    justify-content: center;

    img {
      height: 160px;
      width: 160px;
      border-radius: 50%;
      margin-right: 28px;
    }
  `,
  uploadContainer: css`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  `,
  uploadImageContainer: css`
    position: relative;
  `,
  uploadingImageLogo: css`
    padding-right: 28px;
  `,
  imageUpload: css`
    .org-logo {
      display: flex;

      img {
        height: 160px;
        width: 160px;
        border-radius: 50%;
        margin-right: 28px;
      }
    }
  `,
  uploadActions: css`
    display: flex;
    flex-direction: column;
  `,
  uploadButtons: css`
    display: flex;
    flex-direction: column;

    @media ${queries.large} {
      flex-direction: row;
    }
  `,
  inputs: css`
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;

    @media ${queries.large} {
      row-gap: 1.5rem;
      grid-template-columns: 1fr 1fr;
      column-gap: 1.5rem;
      margin-bottom: 0.5rem;
    }
  `,
  formContainer: css`
    flex: 1;
    border-radius: 0.25rem;
    margin-top: 1rem;
    margin-bottom: 2.5rem !important;

    .ant-form-item {
      margin-bottom: 0;
      width: 100%;
    }

    .ant-divider {
      max-width: 25rem !important;
      min-width: unset;
    }

    .ant-divider-horizontal {
      margin-bottom: 2rem;
    }
    .ant-form-item-control-input-content {
      > div {
        width: 100%;
      }
    }
    .columns {
      display: flex;
      flex-direction: row;
      align-items: left;
      div:nth-of-type(1) {
        margin-right: 50px;
      }
      .delete-button {
        display: block;
        margin-top: 15px;
      }

      .product-info {
        width: 100%;
      }
    }
    font-family: ${theme.fonts.sofiaPro};
    h2 {
      color: ${theme.colors.white};
    }
    .product-info-heading {
      margin-top: 35px;
    }
  `,
  formItemAndLabel: css`
    width: 100%;
    font-family: ${theme.fonts.sofiaPro} !important;

    .floatingLabel {
      margin-bottom: 0;
    }
  `,
  deleteImgContainer: css`
    margin-top: 1rem;
    display: none;
  `,
  resetInstructions: css`
    margin-left: 0.5rem;
  `,
  submit: css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 1.5rem;
    width: 100%;

    @media ${queries.large} {
      flex-direction: row;
      margin-top: 0;
      align-items: center;
      justify-content: space-between;
    }
  `,
  horizontalDivider: css`
    display: block;
    width: 100%;
    border: 1px solid ${theme.colors.background.darkGray100};
    margin-top: 1rem;
    margin-bottom: 0.5rem;

    @media ${queries.large} {
      display: none;
    }
  `,
  saveChangesBtn: css`
    width: unset !important;
    margin-top: 0.5rem;

    button {
      margin-right: 0.5rem;
    }

    @media ${queries.large} {
      margin-top: 0;

      .ant-form-item-control-input-content {
        width: fit-content !important;
        flex: none;
      }

      .ant-form-item-control-input {
        justify-content: flex-end;
      }
    }
  `,
  signOut: css`
    margin-bottom: 2.5rem;
  `,
  selectionInput: css`
    border-color: ${theme.colors.secondary.blueOnBlack};
    border-radius: 4px;

    input {
      padding: 8px 0 !important;
    }
  `,
  dropdownContainer: css`
    .dropdown {
      > div {
        background: ${theme.colors.background.landingDarkGray} !important;
        border: 1px solid ${theme.colors.secondary.mediumGrey} !important;
        color: ${theme.colors.white} !important;
        height: 64px;
      }

      .dropdown-options {
        &__menu-list {
          background: ${theme.colors.background.landingDarkGray} !important;
          border: 1px solid ${theme.colors.secondary.mediumGrey} !important;
          color: ${theme.colors.white} !important;
        }
        &__option {
          background: ${theme.colors.background.landingDarkGray} !important;
          &:hover {
            background: ${theme.colors.secondary.mediumGrey} !important;
          }
        }
        &__placeholder {
          margin-top: 12px;
          margin-left: 3px;
        }
        &__value-container {
          padding: 0 8px;
          height: 64px;
        }
        &__single-value {
          color: ${theme.colors.white} !important;
          font-size: 1rem;
          line-height: 58px;
          margin-top: 8px;
          margin-left: 4px;
        }
        &__input-container {
          padding-top: 8px;
        }
        &__input {
          color: ${theme.colors.white} !important;
        }
        &__control--is-focused {
          box-shadow: 0 0 0 1px ${theme.colors.secondary.mediumGrey} !important;
        }
      }
    }
  `,
  link: css`
    color: ${theme.colors.white} !important;
    font-family: ${theme.fonts.sofiaPro};
    margin-left: 1rem;
    text-decoration: none !important;
    background-color: unset;
    font-weight: 800;
    padding: 0 !important;

    :hover {
      color: ${theme.colors.functional.brightOrangeHover};
      background-color: unset;
      padding: 0 !important;
      text-decoration: underline;
    }
    &[disabled] {
      color: #d5d5d5 !important;
    }
  `,
  resetPasswordBtn: css`
    button {
      color: ${theme.colors.white} !important;

      :hover {
        color: ${theme.colors.functional.brightOrangeHover} !important;
      }
    }
  `,
  orgSelect: css`
    margin-bottom: 32px;
    width: 100%;

    @media ${queries.large} {
      margin-bottom: 48px;
    }
  `,
  idLabel: css`
    color: ${theme.colors.background.lightGray};
    display: flex;
    margin-top: 6px;

    .orgName {
      margin-right: 1rem;
    }

    .orgId {
      margin-left: 1rem;
    }
  `,
};
