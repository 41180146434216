import { ReactElement } from "react";

const RubbishBinIcon = (): ReactElement => {
  return (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5628 16.0974H0.437227C0.195503 16.0974 0 15.9019 0 15.6602V1.68645C0 1.44472 0.195503 1.24922 0.437227 1.24922H2.06121C2.30294 1.24922 2.49844 1.44472 2.49844 1.68645V13.1618C2.49844 13.4035 2.69394 13.599 2.93567 13.599H17.0643C17.3061 13.599 17.5016 13.4035 17.5016 13.1618V2.93567C17.5016 2.69394 17.3061 2.49844 17.0643 2.49844H7.11305C6.87133 2.49844 6.67583 2.30294 6.67583 2.06121V0.437227C6.67583 0.195503 6.87133 0 7.11305 0H19.5628C19.8045 0 20 0.195503 20 0.437227V15.6602C20 15.9019 19.8045 16.0974 19.5628 16.0974Z"
        fill="white"
      />
      <path
        d="M9.69027 11.5703L3.74898 5.62898C3.57846 5.45846 3.57846 5.18114 3.74898 5.01062L4.89764 3.86196C5.06816 3.69144 5.34549 3.69144 5.516 3.86196L9.69089 8.03685C9.86141 8.20737 10.1387 8.20737 10.3093 8.03685L17.6147 0.730794C17.7852 0.560276 18.0625 0.560276 18.2331 0.730794L19.3817 1.87945C19.5522 2.04997 19.5522 2.3273 19.3817 2.49781L10.3093 11.5703C10.1387 11.7408 9.86141 11.7408 9.69089 11.5703H9.69027Z"
        fill="white"
      />
    </svg>
  );
};
export default RubbishBinIcon;
