import React, { FormEvent, ReactElement, useState } from "react";
import {
  Button,
  ScopedTheme,
  InputFeedback,
  ValidationMessage,
  ErrorOvalIcon,
} from "@songtradr/component-library";
import useAuth from "src/auth/use-auth";
import appStyles from "src/app/styles";
import CopyrightFooter from "src/components/copyright-footer";
import FloatingLabelInput from "src/components/floating-label-input";
import BackBtn from "src/components/back-button";
import { useHistory } from "react-router-dom";
import styles from "./styles";

const ForgotPassword = (): ReactElement => {
  const history = useHistory();
  const { resetPassword } = useAuth();
  const [formStatus, setFormStatus] = useState({
    email: "",
    error: "",
    showPassword: false,
  });

  const hasError = formStatus.error !== "";

  const updateFormField = (name: string) => {
    return ({ target: { value } }) => {
      setFormStatus((oldValues) => ({ ...oldValues, [name]: value }));
    };
  };

  const handleResetPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormStatus({
      ...formStatus,
      error: "",
    });

    if (!formStatus.email) {
      setFormStatus({
        ...formStatus,
        error: "Please fill the email field",
      });

      return;
    }

    // No need to handle a loading state as the function isn't async
    resetPassword(formStatus.email);
    history.push("reset-password", {
      email: formStatus.email,
      useDarkBg: false,
    });
  };

  return (
    <ScopedTheme mode="dark" css={styles.background}>
      <div css={[appStyles.container, appStyles.guestPageContainer]}>
        <div css={styles.backBtn}>
          <BackBtn />
        </div>
        <form
          css={appStyles.guestPageInnerContainer}
          onSubmit={handleResetPassword}
        >
          <h1 css={styles.mainHeading}>Forgot password?</h1>
          <p css={styles.text}>
            Provide the email linked to your account, and we&apos;ll send you a
            code for resetting your Songtradr Studio password.
          </p>
          <h4 css={styles.sectionHeader}>Reset Password</h4>

          <FloatingLabelInput
            required
            name="email"
            value={formStatus.email}
            onChange={updateFormField("email")}
            hasError={hasError}
            type="email"
            label="Email"
          />

          {hasError && (
            <InputFeedback css={styles.validationMessage}>
              <ValidationMessage type="error">
                <ErrorOvalIcon />
                {formStatus.error}
              </ValidationMessage>
            </InputFeedback>
          )}

          <div css={styles.submitContainer}>
            <Button variant="primary" type="submit" css={styles.submitBtn}>
              Send reset link
            </Button>
          </div>
        </form>
      </div>
      <div css={[appStyles.container, styles.copyrightFooter]}>
        <CopyrightFooter />
      </div>
    </ScopedTheme>
  );
};

export default ForgotPassword;
