import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils";
import theme from "src/theme";

const styles = {
  column: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 11rem;
    margin-bottom: 11rem;
    @media ${queries.large} {
      justify-content: left;
      align-items: start;
    }
  `,

  header: css`
    @media ${queries.large} {
      margin-top: 1.5rem;
    }
  `,

  formWrapper: css`
    width: 100%;
    height: 100%;
  `,

  text: css`
    color: ${theme.colors.background.white} !important;
    text-align: center;

    @media ${queries.large} {
      color: rgba(0, 0, 0, 0.85) !important;
    }
  `,
};

export default styles;
