import React, { useState, ReactNode } from "react";
import { Button, CloseIcon } from "@songtradr/component-library";
import { LeftOutlined } from "@ant-design/icons";
import styles from "./styles";

interface IStep {
  content: ReactNode;
  validationCondition?: boolean;
}

interface IProps {
  isOpen: boolean;
  toggleMenu: () => void;
  steps: IStep[];
  onComplete: () => void;
  showButtonContainer?: boolean;
}

const SlideDrawer = ({
  isOpen,
  toggleMenu,
  steps,
  onComplete,
  showButtonContainer = true,
}: IProps) => {
  const [step, setStep] = useState(0);

  const handleBack = () => {
    if (step === 0) {
      toggleMenu();
    } else {
      setStep((prevValue) => prevValue - 1);
    }
  };

  const handleClose = () => {
    toggleMenu();
    setStep(0);
  };

  const saveChanges = () => {
    toggleMenu();
    onComplete();
    setStep(0);
  };

  const disabled =
    steps[step].validationCondition !== undefined
      ? !steps[step].validationCondition
      : false;

  return (
    <div css={styles.container(showButtonContainer)}>
      <div className={`slider-menu ${isOpen ? "open" : ""}`}>
        <div className="menu-content">
          {steps[step]?.content && steps[step].content}
        </div>
        <LeftOutlined className="back-icon" onClick={handleBack} />
        <CloseIcon className="close-icon" onClick={handleClose} />
        {showButtonContainer && (
          <div className="button-container">
            <Button onClick={handleClose} variant="subtle">
              Cancel
            </Button>
            <Button
              data-testid="continue-btn"
              disabled={disabled}
              className="primary"
              variant="primary"
              onClick={() =>
                step === steps.length - 1
                  ? saveChanges()
                  : setStep((prevValue) => prevValue + 1)
              }
            >
              {step === steps.length - 1 ? "Save changes" : "Continue"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SlideDrawer;
