import { Table } from "antd";
import React, { ReactElement, forwardRef } from "react";
import { startCase } from "lodash";
import projectsMainStyles from "src/pages/projects/styles";
import mainStyles from "../../styles";
import { IService } from "../../interfaces";
import { formatPriceWithCurrency } from "./utils";
import SectionTitleAndSubtitle from "../section-title-and-subtitle";

interface IProps {
  services?: IService[];
}

const Services = forwardRef(
  ({ services }: IProps, ref: React.Ref<HTMLDivElement>): ReactElement => {
    const columns = [
      {
        title: "Service type",
        dataIndex: "serviceType",
        key: "serviceType",
      },
      {
        title: "Music type",
        dataIndex: "musicType",
        key: "musicType",
      },
      {
        title: "Total",
        dataIndex: "totalFee",
        key: "totalFee",
      },
      {
        title: "URL",
        dataIndex: "link",
        key: "link",
      },
    ];

    const tableDataSource = services?.map((service) => {
      const formattedPriceAndCurrency =
        service.totalFee &&
        `${formatPriceWithCurrency(
          service.totalFee.value,
          service.totalFee.currency
        )}`;

      return {
        key: service.id,
        serviceType: startCase(service.serviceType) || "-",
        musicType: startCase(service.musicType) || "-",
        totalFee: formattedPriceAndCurrency || "-",
        link: service.asset?.link ? (
          <a href={service.asset?.link} target="_blank" rel="noreferrer">
            {service.asset?.link}
          </a>
        ) : (
          "-"
        ),
      };
    });

    return (
      <div css={mainStyles.sectionContainer} id="services" ref={ref}>
        <SectionTitleAndSubtitle
          title="Services"
          subtitle="An overview of any additional services provided"
        />
        <Table
          css={projectsMainStyles.getTableStyles(false)}
          columns={columns}
          dataSource={tableDataSource}
          pagination={false}
          scroll={{ x: true }}
        />
      </div>
    );
  }
);

export default Services;
