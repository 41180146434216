import { css } from "@emotion/react";

export default {
  search: css`
    .cls-1 {
      fill: url(#linear-gradient-12);
    }
    .cls-1,
    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9,
    .cls-10,
    .cls-11,
    .cls-12,
    .cls-13,
    .cls-14,
    .cls-15,
    .cls-16,
    .cls-17,
    .cls-18,
    .cls-19,
    .cls-20,
    .cls-21,
    .cls-22,
    .cls-23 {
      stroke-width: 0;
    }
    .cls-2 {
      fill: url(#linear-gradient-17);
    }
    .cls-3 {
      fill: url(#linear-gradient-19);
    }
    .cls-4 {
      fill: url(#linear-gradient);
    }
    .cls-5 {
      fill: url(#linear-gradient-21);
    }
    .cls-6 {
      fill: url(#linear-gradient-6);
    }
    .cls-7 {
      fill: url(#linear-gradient-15);
    }
    .cls-8 {
      fill: url(#linear-gradient-11);
    }
    .cls-9 {
      fill: #252727;
    }
    .cls-10 {
      fill: url(#linear-gradient-5);
    }
    .cls-11 {
      fill: url(#linear-gradient-13);
    }
    .cls-12 {
      fill: url(#linear-gradient-16);
    }
    .cls-13 {
      fill: url(#linear-gradient-20);
    }
    .cls-14 {
      fill: url(#linear-gradient-14);
    }
    .cls-15 {
      fill: url(#linear-gradient-2);
    }
    .cls-16 {
      fill: url(#linear-gradient-7);
    }
    .cls-17 {
      fill: url(#linear-gradient-3);
    }
    .cls-18 {
      fill: url(#linear-gradient-9);
    }
    .cls-24 {
      fill: none;
      stroke: #141414;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-width: 3px;
    }
    .cls-19 {
      fill: url(#linear-gradient-8);
    }
    .cls-20 {
      fill: url(#linear-gradient-18);
    }
    .cls-21 {
      fill: url(#linear-gradient-22);
      fill-rule: evenodd;
    }
    .cls-22 {
      fill: url(#linear-gradient-10);
    }
    .cls-23 {
      fill: url(#linear-gradient-4);
    }
  `,
  playlists: css`
    .cls-1 {
      fill: url(#playlists-linear-gradient);
    }
    .cls-1,
    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9 {
      stroke-width: 0;
    }
    .cls-2 {
      fill: url(#playlists-linear-gradient-6);
    }
    .cls-10 {
      stroke-width: 0.25px;
    }
    .cls-10,
    .cls-11 {
      fill: none;
      stroke: #1e2020;
      stroke-miterlimit: 10;
    }
    .cls-3 {
      fill: url(#playlists-linear-gradient-5);
    }
    .cls-4 {
      fill: url(#playlists-linear-gradient-2);
    }
    .cls-5 {
      fill: #1e2020;
    }
    .cls-6 {
      fill: url(#playlists-linear-gradient-7);
    }
    .cls-7 {
      fill: url(#playlists-linear-gradient-3);
    }
    .cls-8 {
      fill: url(#playlists-linear-gradient-8);
    }
    .cls-11 {
      stroke-width: 0.5px;
    }
    .cls-9 {
      fill: url(#playlists-linear-gradient-4);
    }
  `,
  projects: css`
    .cls-1 {
      fill: url(#projects-linear-gradient-12);
    }
    .cls-1,
    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9,
    .cls-10,
    .cls-11,
    .cls-12,
    .cls-13,
    .cls-14,
    .cls-15,
    .cls-16 {
      stroke-width: 0;
    }
    .cls-2 {
      fill: url(#projects-linear-gradient);
    }
    .cls-17 {
      fill: none;
      stroke: #141414;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-width: 2px;
    }
    .cls-3 {
      fill: url(#projects-linear-gradient-6);
    }
    .cls-4 {
      fill: url(#projects-linear-gradient-15);
    }
    .cls-5 {
      fill: url(#projects-linear-gradient-11);
    }
    .cls-6 {
      fill: url(#projects-linear-gradient-5);
    }
    .cls-7 {
      fill: url(#projects-linear-gradient-13);
    }
    .cls-8 {
      fill: url(#projects-linear-gradient-14);
    }
    .cls-9 {
      fill: url(#projects-linear-gradient-2);
    }
    .cls-10 {
      fill: #1e2020;
    }
    .cls-11 {
      fill: url(#projects-linear-gradient-7);
    }
    .cls-12 {
      fill: url(#projects-linear-gradient-3);
    }
    .cls-13 {
      fill: url(#projects-linear-gradient-9);
    }
    .cls-14 {
      fill: url(#projects-linear-gradient-8);
    }
    .cls-15 {
      fill: url(#projects-linear-gradient-10);
    }
    .cls-16 {
      fill: url(#projects-linear-gradient-4);
    }
  `,
  measurements: css`
    .cls-1 {
      fill: url(#measurements-linear-gradient);
    }
    .cls-1,
    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5 {
      stroke-width: 0;
    }
    .cls-2 {
      fill: url(#measurements-linear-gradient-2);
    }
    .cls-3 {
      fill: #1e2020;
    }
    .cls-4 {
      fill: url(#measurements-linear-gradient-3);
    }
    .cls-5 {
      fill: #ec5734;
    }
    .cls-6 {
      fill: none;
      stroke: #ec5734;
      stroke-linecap: round;
      stroke-miterlimit: 10;
      stroke-width: 2px;
    }
  `,
  insights: css`
    .cls-1 {
      fill: url(#insights-linear-gradient);
    }
    .cls-1,
    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9 {
      stroke-width: 0;
    }
    .cls-2 {
      fill: url(#insights-linear-gradient-6);
    }
    .cls-3 {
      fill: url(#insights-linear-gradient-5);
    }
    .cls-4 {
      fill: url(#insights-linear-gradient-2);
    }
    .cls-5 {
      fill: #1e2020;
    }
    .cls-6 {
      fill: url(#insights-linear-gradient-7);
    }
    .cls-7 {
      fill: url(#insights-linear-gradient-3);
    }
    .cls-8 {
      fill: url(#insights-linear-gradient-8);
    }
    .cls-9 {
      fill: url(#insights-linear-gradient-4);
    }
  `,
  adaptiveMusic: css`
    .cls-1 {
      fill: url(#adaptive-linear-gradient);
    }
    .cls-1,
    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9,
    .cls-10,
    .cls-11,
    .cls-12 {
      stroke-width: 0;
    }
    .cls-2 {
      fill: url(#adaptive-linear-gradient-6);
    }
    .cls-3 {
      fill: url(#adaptive-linear-gradient-5);
    }
    .cls-4 {
      fill: url(#adaptive-linear-gradient-2);
    }
    .cls-5 {
      fill: #1e2020;
    }
    .cls-6 {
      fill: url(#adaptive-linear-gradient-7);
    }
    .cls-7 {
      fill: url(#adaptive-linear-gradient-3);
    }
    .cls-8 {
      fill: #ec5734;
    }
    .cls-9 {
      fill: url(#adaptive-linear-gradient-9);
    }
    .cls-10 {
      fill: url(#adaptive-linear-gradient-8);
    }
    .cls-11 {
      fill: url(#adaptive-linear-gradient-10);
    }
    .cls-12 {
      fill: url(#adaptive-linear-gradient-4);
    }
  `,
};
