import { ReactElement } from "react";
import styles from "./styles";

const AdaptiveMusic = (): ReactElement => {
  return (
    <div css={styles.adaptiveMusic}>
      <svg
        id="Artwork"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 252 242"
      >
        <defs>
          <linearGradient
            id="adaptive-linear-gradient"
            x1="47.82"
            y1="68.89"
            x2="181.52"
            y2="68.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4a4c4c" />
            <stop offset="1" stopColor="#4a4c4c" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="adaptive-linear-gradient-2"
            x1="46.82"
            y1="68.89"
            x2="182.52"
            y2="68.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4f5050" />
            <stop offset="1" stopColor="#323434" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="adaptive-linear-gradient-3"
            x1="212.72"
            y1="111.45"
            x2="92.74"
            y2="111.45"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ec5734" />
            <stop offset="1" stopColor="#ec5734" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="adaptive-linear-gradient-4"
            x1="219.26"
            y1="111.45"
            x2="83.56"
            y2="111.45"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ec5734" />
            <stop
              offset=".06"
              stopColor="rgba(236,87,52,.92)"
              stopOpacity=".92"
            />
            <stop
              offset=".25"
              stopColor="rgba(236,87,52,.64)"
              stopOpacity=".64"
            />
            <stop
              offset=".44"
              stopColor="rgba(236,87,52,.41)"
              stopOpacity=".41"
            />
            <stop
              offset=".62"
              stopColor="rgba(236,87,52,.24)"
              stopOpacity=".24"
            />
            <stop
              offset=".77"
              stopColor="rgba(236,87,52,.11)"
              stopOpacity=".11"
            />
            <stop
              offset=".91"
              stopColor="rgba(236,87,52,.03)"
              stopOpacity=".03"
            />
            <stop offset="1" stopColor="#ec5734" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="adaptive-linear-gradient-5"
            x1="38.87"
            y1="154.02"
            x2="154.4"
            y2="154.02"
            xlinkHref="#adaptive-linear-gradient"
          />
          <linearGradient
            id="adaptive-linear-gradient-6"
            x1="30.36"
            y1="154.02"
            x2="166.06"
            y2="154.02"
            xlinkHref="#adaptive-linear-gradient-2"
          />
          <linearGradient
            id="adaptive-linear-gradient-7"
            x1="70.94"
            y1="68.89"
            x2="139.49"
            y2="68.89"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#666868" />
            <stop offset="1" stopColor="#363838" stopOpacity=".5" />
          </linearGradient>
          <linearGradient
            id="adaptive-linear-gradient-8"
            x1="56.06"
            y1="154.02"
            x2="124.61"
            y2="154.02"
            xlinkHref="#adaptive-linear-gradient-7"
          />
          <linearGradient
            id="adaptive-linear-gradient-9"
            x1="1612.63"
            y1="-415.94"
            x2="1612.63"
            y2="-580.6"
            gradientTransform="translate(1761.07 -380.67) rotate(-180)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#666868" />
            <stop offset="1" stopColor="#363838" stopOpacity=".1" />
          </linearGradient>
          <linearGradient
            id="adaptive-linear-gradient-10"
            x1="148.44"
            y1="35.93"
            x2="148.44"
            y2="26.26"
            xlinkHref="#adaptive-linear-gradient-7"
          />
        </defs>
        <rect
          className="cls-1"
          x="47.82"
          y="56.9"
          width="133.7"
          height="23.97"
          rx="5"
          ry="5"
        />
        <path
          className="cls-4"
          d="M176.52,56.9c2.76,0,5,2.24,5,5v13.97c0,2.76-2.24,5-5,5H52.82c-2.76,0-5-2.24-5-5v-13.97c0-2.76,2.24-5,5-5h123.7M176.52,55.9H52.82c-3.31,0-6,2.69-6,6v13.97c0,3.31,2.69,6,6,6h123.7c3.31,0,6-2.69,6-6v-13.97c0-3.31-2.69-6-6-6h0Z"
        />
        <rect className="cls-5" x="16.97" y="47.1" width="218.06" height="1" />
        <rect className="cls-5" x="16.97" y="89.67" width="218.06" height="1" />
        <rect
          className="cls-5"
          x="16.97"
          y="132.24"
          width="218.06"
          height="1"
        />
        <rect
          className="cls-5"
          x="16.97"
          y="174.81"
          width="218.06"
          height="1"
        />
        <rect
          className="cls-7"
          x="84.56"
          y="99.15"
          width="133.7"
          height="24.61"
          rx="5"
          ry="5"
        />
        <path
          className="cls-12"
          d="M213.26,99.15c2.76,0,5,2.24,5,5v14.61c0,2.76-2.24,5-5,5h-123.7c-2.76,0-5-2.24-5-5v-14.61c0-2.76,2.24-5,5-5h123.7M213.26,98.15h-123.7c-3.31,0-6,2.69-6,6v14.61c0,3.31,2.69,6,6,6h123.7c3.31,0,6-2.69,6-6v-14.61c0-3.31-2.69-6-6-6h0Z"
        />
        <rect
          className="cls-3"
          x="31.36"
          y="141.74"
          width="133.7"
          height="24.56"
          rx="5"
          ry="5"
        />
        <path
          className="cls-2"
          d="M160.06,141.74c2.76,0,5,2.24,5,5v14.56c0,2.76-2.24,5-5,5H36.36c-2.76,0-5-2.24-5-5v-14.56c0-2.76,2.24-5,5-5h123.7M160.06,140.74H36.36c-3.31,0-6,2.69-6,6v14.56c0,3.31,2.69,6,6,6h123.7c3.31,0,6-2.69,6-6v-14.56c0-3.31-2.69-6-6-6h0Z"
        />
        <path
          className="cls-6"
          d="M125.77,75.57c-3.72,0-5.54-3.28-7.29-6.45-1.69-3.05-3.29-5.93-6.42-5.93s-4.73,2.88-6.42,5.93c-1.75,3.17-3.57,6.45-7.29,6.45s-5.54-3.28-7.29-6.45c-1.69-3.05-3.29-5.93-6.42-5.93s-4.73,2.88-6.42,5.93c-1.75,3.17-3.57,6.45-7.29,6.45v-1c3.13,0,4.73-2.88,6.42-5.93,1.75-3.17,3.57-6.45,7.29-6.45s5.54,3.28,7.29,6.45c1.69,3.05,3.28,5.93,6.42,5.93s4.73-2.88,6.42-5.93c1.75-3.17,3.57-6.45,7.29-6.45s5.54,3.28,7.29,6.45c1.69,3.05,3.29,5.93,6.42,5.93s4.73-2.88,6.42-5.93c1.76-3.17,3.57-6.45,7.29-6.45v1c-3.13,0-4.73,2.88-6.42,5.93-1.76,3.17-3.57,6.45-7.29,6.45Z"
        />
        <path
          className="cls-8"
          d="M103.43,105.77c3.13,0,4.73,2.88,6.42,5.93,1.75,3.17,3.57,6.45,7.29,6.45s5.54-3.28,7.29-6.45c1.69-3.05,3.29-5.93,6.42-5.93s4.73,2.88,6.42,5.93c1.75,3.17,3.57,6.45,7.29,6.45h0s0,0,.01,0c3.72,0,5.54-3.28,7.29-6.45,1.69-3.05,3.29-5.93,6.42-5.93s4.73,2.88,6.42,5.93c1.75,3.17,3.57,6.45,7.29,6.45s5.54-3.28,7.29-6.45c1.69-3.05,3.29-5.93,6.42-5.93s4.73,2.88,6.42,5.93c1.75,3.17,3.57,6.45,7.29,6.45v-1c-3.13,0-4.73-2.88-6.42-5.93-1.75-3.17-3.57-6.45-7.29-6.45s-5.54,3.28-7.29,6.45c-1.69,3.05-3.28,5.93-6.42,5.93s-4.73-2.88-6.42-5.93c-1.75-3.17-3.57-6.45-7.29-6.45s-5.54,3.28-7.29,6.45c-1.69,3.05-3.29,5.93-6.42,5.93s-4.73-2.88-6.42-5.93c-1.76-3.17-3.57-6.45-7.29-6.45s-5.54,3.28-7.29,6.45c-1.69,3.05-3.28,5.93-6.42,5.93s-4.73-2.88-6.42-5.93c-1.75-3.17-3.57-6.45-7.29-6.45v1Z"
        />
        <path
          className="cls-10"
          d="M110.9,160.71c-3.72,0-5.54-3.28-7.29-6.45-1.69-3.05-3.29-5.93-6.42-5.93s-4.73,2.88-6.42,5.93c-1.75,3.17-3.57,6.45-7.29,6.45s-5.54-3.28-7.29-6.45c-1.69-3.05-3.28-5.93-6.42-5.93s-4.73,2.88-6.42,5.93c-1.75,3.17-3.57,6.45-7.29,6.45v-1c3.13,0,4.73-2.88,6.42-5.93,1.75-3.17,3.57-6.45,7.29-6.45s5.54,3.28,7.29,6.45c1.69,3.05,3.28,5.93,6.42,5.93s4.73-2.88,6.42-5.93c1.75-3.17,3.57-6.45,7.29-6.45s5.54,3.28,7.29,6.45c1.69,3.05,3.29,5.93,6.42,5.93s4.73-2.88,6.42-5.93c1.76-3.17,3.57-6.45,7.29-6.45v1c-3.13,0-4.73,2.88-6.42,5.93-1.76,3.17-3.57,6.45-7.29,6.45Z"
        />
        <rect
          className="cls-9"
          x="147.94"
          y="35.27"
          width="1"
          height="164.67"
        />
        <path
          className="cls-11"
          d="M148.44,35.93l-3.47-2.99v-6.67h6.94v6.67l-3.47,2.99ZM145.97,32.48l2.47,2.13,2.47-2.13v-5.21h-4.94v5.21Z"
        />
      </svg>
    </div>
  );
};
export default AdaptiveMusic;
