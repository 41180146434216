import { css } from "@emotion/react/dist/emotion-react.cjs";
import theme from "src/theme";
import { rotate360 } from "src/components/loading-spinner/styles";

export default {
  loadingSpinnerContainer: css`
    padding: 4rem;
    text-align: center;
  `,
  loadingSpinner: css`
    stroke: ${theme.colors.white};
    animation: ${rotate360} 1s linear infinite;
    transform-box: fill-box;
    transform-origin: 50% 50%;
  `,
};
