import { Fragment, ReactElement, useState } from "react";
import { IOrganisationMemberProps } from "src/pages/team/interfaces";
import { Table } from "antd";
import { eApplicationType } from "src/interfaces/auth";
import getColumns from "../../table-data/whitelisting-columns";
import {
  IOption,
  WhitelistingData,
} from "../../table-data/org-member-whitelist-data";
import styles from "../../styles";

interface IProps {
  orgMembers: Array<IOrganisationMemberProps>;
  projects: IOption[];
  allApplications: eApplicationType[];
  handleAddApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>;
  handleRemoveApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>;
  handleToggleUseProjectsWhitelist: (
    selectedOrgMember: IOrganisationMemberProps,
    useProjectsWhitelist: boolean
  ) => Promise<void>;
  handleUpdateProjectsWhitelist: (
    selectedOrgMember: IOrganisationMemberProps,
    projects: string[]
  ) => Promise<void>;
}

const WhitelistingTab = ({
  orgMembers,
  projects,
  allApplications,
  handleAddApplication,
  handleRemoveApplication,
  handleToggleUseProjectsWhitelist,
  handleUpdateProjectsWhitelist,
}: IProps): ReactElement => {
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);

  const hasProjectsClientApplication = allApplications.includes(
    eApplicationType.ProjectsClient
  );

  const usersToWhitelist =
    orgMembers.filter(
      (user) => !user.applications.includes(eApplicationType.TeamManagement)
    ) || orgMembers;

  const dataSource = WhitelistingData(
    usersToWhitelist,
    projects,
    handleAddApplication,
    handleRemoveApplication,
    hasProjectsClientApplication,
    handleToggleUseProjectsWhitelist,
    handleUpdateProjectsWhitelist,
    isProcessingRequest,
    setIsProcessingRequest
  );

  const columns = getColumns;

  return (
    <Fragment>
      <Table
        dataSource={dataSource}
        columns={columns()}
        css={styles.table}
        data-testid="project-permissions-table"
      />
    </Fragment>
  );
};
export default WhitelistingTab;
