const colors = {
  primary: {
    blue: "var(--colors-primary-blue)",
    royalBlue: "var(--colors-primary-royal-blue)",
    eiffelBlue: "var(--colors-eiffel-blue)",
  },
  variants: {
    eiffelBlueDark: "var(--colors-eiffel-blue-variant-dark)",
    eiffelBlueLight: "var(--colors-eiffel-blue-variant-light)",
  },
  secondary: {
    darkBlue: "var(--colors-secondary-dark-blue)",
    lightBlue: "var(--colors-secondary-light-blue)",
    navyBlue: "var(--colors-secondary-navy-blue)",
    purple: "var(--colors-secondary-purple)",
    lightPurple: "var(--colors-secondary-light-purple)",
    darkPurple: "var(--colors-secondary-dark-purple)",
    black: "var(--colors-secondary-black)",
    cyan: "var(--colors-secondary-cyan)",
    darkGray: "var(--colors-dark-gray)",
    lightGray: "var(--colors-light-gray)",
    navyPurple: "var(--colors-navy-purple)",
    iconGray: "var(--colors-secondary-icon-gray)",
    waveformGray: "var(--colors-secondary-waveform-gray)",
    sumoSidebarGray: "var(--colors-sumo-sidebar-gray)",
    userIconBackground: "var(--colors-secondary-user-icon-background)",
    greyRoom: "var(--colors-background-grey-room)",
    mediumGrey: "var(--colors-secondary-medium-grey)",
    bigSky: "var(--colors-background-big-sky)",
    blueOnBlack: "var(--colors-secondary-blue-on-black)",
    blueOnBlackLight: "var(--colors-secondary-blue-on-black-light)",
    blueOnBlackShadow: "var(--colors-secondary-blue-on-black-shadow)",
    enterpriseBruised: "var(--colors-enterprise-bruised)",
    textSecondary: "var(--colors-text-secondary)",
  },
  background: {
    white: "var(--colors-white)",
    veryLightGray: "var(--colors-very-light-gray)",
    lightGray: "var(--colors-light-gray)",
    darkGray: "var(--colors-dark-gray)",
    veryDarkGray: "var(--colors-very-dark-gray)",
    extraDarkGray: "var(--colors-extra-dark-gray)",
    landingDarkGray: "var(--colors-landing-dark-gray)",
    landingGradientGray: "var(--colors-landing-gradient-gray)",
    basaltBlack: "var(--colors-basalt-black)",
    black: "var(--colors-true-black)",
    gray: "var(--colors-background-gray)",
    greyRoom: "var(--colors-background-grey-room)",
    fadeToGrey: "var(--colors-background-fade-to-grey)",
    aliceBlue: "var(--colors-background-alice-blue)",
    darkGray100: "var(--colors-dark-gray-100)",
    lightGray100: "var(--colors-light-gray-100)",
    darkGray700: "var(--colors-dark-gray-700)",
    darkGray900: "var(--colors-dark-gray-900)",
  },
  functional: {
    red: "var(--colors-functional-red)",
    alertRed: "var(--colors-alert-red)",
    alertRedLight: "var(--colors-alert-red-light)",
    blue: "var(--colors-functional-blue)",
    infoBlue: "var(--colors-info-blue)",
    infoBlueLight: "var(--colors-info-blue-light)",
    orange: "var(--colors-functional-orange)",
    green: "var(--colors-functional-green)",
    brightGreen: "var(--colors-bright-green)",
    brightOrange: "var(--colors-bright-orange)",
    brightOrangeHover: "var(--colors-bright-orange-hover)",
    warningYellow: "var(--colors-warning-yellow)",
    errorRed: "var(--colors-error-red)",
    successGreen: "var(--colors-success-green)",
    successGreenDark: "var(--colors-success-green-dark)",
    successGreenLight: "var(--colors-success-green-light)",
    approveGreen: "var(--colors-approve-green)",
    approveGreenDark: "var(--colors-approve-green-dark)",
    denyRed: "var(--colors-deny-red)",
    denyRedDark: "var(--colors-deny-red-dark)",
    zambezi: "var(--colors-zambezi)",
    gray65: "var(--colors-gray-65)",
    scarletRed: "var(--colors-scarlet-red)",
    goldenTangerine: "var(--colors-golden-tangerine)",
    skyBlue: "var(--colors-sky-blue)",
    peachCream: "var(--colors-peach-cream)",
    rusticBrown: "var(--colors-rustic-brown)",
  },
  white: "var(--colors-white)",
  black: "var(--colors-black)",
  paleRed: "var(--colors-pale-red)",
  lightGrey: "var(--colors-light-grey)",
  darkCharcoal: "var(--colors-dark-charcoal)",
  blackShadow: "var(--colors-black-shadow)",
};

const svgSize = {
  small: 16,
  medium: 24,
  large: 38,
  xlarge: 48,
  nav: 56,
  songtradrLogo: 130,
  songtradrLogoLarge: 222,
};

const fonts = {
  proximaNova: "proxima-nova, sans-serif",
  calluna: "calluna, serif",
  sofiaPro: "sofia-pro, sans-serif",
};

const zIndex = {
  header: "100",
  headerButtons: "1051",
};

const typography = {
  proximaNova: {
    body: {
      fontWeight: "regular",
      fontSize: "1rem",
      lineHeight: "1.375rem",
      color: "var(--colors-black)",
    },
    small: {
      fontWeight: "regular",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
      color: "var(--colors-black)",
    },
    tableHeader: {
      fontWeight: "semibold",
      fontSize: "1rem",
      lineHeight: "1.25rem",
      color: "var(--colors-black)",
    },
    input: {
      fontWeight: "regular",
      fontSize: "1rem",
      lineHeight: "1.375rem",
      color: "var(--colors-black)",
    },
    label: {
      fontWeight: "regular",
      fontSize: "0.75rem",
      color: "var(--colors-dark-gray)",
    },
    link: {
      fontWeight: "bold",
      color: "#1F48E7",
    },
    header1: {
      desktop: {
        fontWeight: "bold",
        fontSize: "2rem",
        lineHeight: "2.375rem",
        color: "var(--colors-black)",
      },
      mobile: {
        fontWeight: "bold",
        fontSize: "1.75rem",
        lineHeight: "2.125rem",
        color: "var(--colors-black)",
      },
    },
    header2: {
      desktop: {
        fontWeight: "semibold",
        fontSize: "1.5rem",
        lineHeight: "1.875rem",
        color: "var(--colors-secondary-navy-blue)",
      },
      mobile: {
        fontWeight: "semibold",
        fontSize: "1.25rem",
        lineHeight: "1.625rem",
        color: "var(--colors-secondary-navy-blue)",
      },
    },
    header3: {
      desktop: {
        fontWeight: "regular",
        fontSize: "1.25rem",
        lineHeight: "1.625rem",
        color: "var(--colors-black)",
      },
      mobile: {
        fontWeight: "regular",
        fontSize: "1.125rem",
        lineHeight: "1.5rem",
        color: "var(--colors-black)",
      },
    },
  },
  calluna: {
    header1: {
      desktop: {
        fontWeight: "bold",
        fontSize: "3rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
      tablet: {
        fontWeight: "bold",
        fontSize: "2.5rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
      mobile: {
        fontWeight: "bold",
        fontSize: "2.25rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
    },
    header2: {
      desktop: {
        fontWeight: "bold",
        fontSize: "2.75rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
      tablet: {
        fontWeight: "bold",
        fontSize: "2.75rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
      mobile: {
        fontWeight: "semibold",
        fontSize: "1.75rem",
        lineHeight: "1.625rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
    },
    header3: {
      desktop: {
        fontWeight: "bold",
        fontSize: "2.25rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
      tablet: {
        fontWeight: "bold",
        fontSize: "1.75rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
      mobile: {
        fontWeight: "semibold",
        fontSize: "1.375rem",
        lineHeight: "1.625rem",
        color: {
          dark: "var(--colors-black)",
          light: "var(--colors-background-white)",
        },
      },
    },
  },
};

export default {
  colors,
  svgSize,
  fonts,
  typography,
  zIndex,
};
