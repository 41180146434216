import { ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles";

interface IPillButtonProps {
  name: string;
  isSelected?: boolean;
  variable?: string;
  onClick: () => void;
}

const PillButton = ({
  name,
  variable,
  isSelected = false,
  onClick,
}: IPillButtonProps): ReactElement => {
  const { t } = useTranslation();
  const label = useMemo(
    () => `${t(name, { value: variable }).toLowerCase()}-tab`,
    [name, t, variable]
  );

  return (
    <button
      key={name}
      type="button"
      css={[styles.button, isSelected && styles.selectedButton]}
      onClick={onClick}
      id={`${label}-${isSelected ? "selected" : ""}`}
      aria-label={label}
    >
      {t(name, { value: variable })}
    </button>
  );
};

export default PillButton;
