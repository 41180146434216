import React, { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "./interfaces";

const PreviousIcon = ({
  height = theme.svgSize.medium,
  width = theme.svgSize.medium,
  fill = theme.colors.white,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4532 4.15345L16.8443 5.5309C17.0509 5.7355 17.0509 6.06825 16.8443 6.27284L11.435 11.629C11.2284 11.8336 11.2284 12.1664 11.435 12.371L16.845 17.7272C17.0517 17.9318 17.0517 18.2645 16.845 18.4691L15.4539 19.8466C15.2473 20.0511 14.9113 20.0511 14.7046 19.8466L7.15497 12.371C6.94834 12.1664 6.94834 11.8336 7.15497 11.629L14.7046 4.15345C14.9113 3.94885 15.2473 3.94885 15.4539 4.15345L15.4532 4.15345Z"
        fill={fill}
      />
    </svg>
  );
};

export default PreviousIcon;
