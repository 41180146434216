import config from "../config/config";

const buildOrganisationsBaseUrl = (organisationId: string): string => {
  return `${config.organisationSubscriptionsService.url}/organisations/${organisationId}`;
};

export const buildRelatedOrganisationsBaseUrl = (
  organisationId: string
): string => {
  return `${config.organisationSubscriptionsService.url}/organisations/${organisationId}/related-orgs`;
};

export const buildOrganisationLinksBaseUrl = (
  organisationId: string
): string => {
  return `${config.organisationSubscriptionsService.url}/organisations/${organisationId}/org-links`;
};

export const buildOrganisationSubscriptionsBaseUrl = (): string => {
  return `${config.organisationSubscriptionsService.url}/organisations`;
};

export const buildVinylProjectManagementBaseUrl = (): string => {
  return `${config.projectManagementService.url}/projects`;
};

export const buildVinylPowerBiBaseUrl = (): string => {
  return `${config.projectManagementService.url}/power-bi`;
};

export default buildOrganisationsBaseUrl;
