import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common";

export default {
  verticalTitleContainer: css`
    background-color: ${theme.colors.background.gray};
    border-bottom: 0.063rem solid ${theme.colors.background.veryLightGray};
    border-top: 0.063rem solid ${theme.colors.background.veryLightGray};

    @media ${queries.medium} {
      background-color: ${theme.colors.background.gray};
      border-bottom: 0;
      border-top: 0;
    }

    @media ${queries.large} {
      width: 140px;
      text-align: right;
      margin-bottom: 7.5rem;
    }
  `,
  verticalTitle: css`
    font-family: ${theme.fonts.proximaNova};
    font-size: 0.8rem !important;
    letter-spacing: 0.1rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    height: 50px;
    padding-top: 1.5rem;
    padding-left: 1rem;
    background-color: ${theme.colors.background.gray};

    @media ${queries.medium} {
      padding-top: 0;
      padding-left: 0;
      margin-bottom: 2rem;
    }

    @media ${queries.large} {
      color: ${theme.colors.black};
      letter-spacing: 0.07rem;
      transform: rotate(270deg);
      font-size: 0.8rem !important;
      font-weight: 500 !important;
    }
  `,
  documentsContainer: css`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.background.gray};

    @media ${queries.medium} {
      padding-left: 2rem;
      padding-right: 2rem;
      padding-top: 3.75rem;
      padding-bottom: 5rem;
    }

    @media ${queries.large} {
      padding-left: 0;
      padding-right: 0;
      align-items: center;
      flex-direction: row;
    }
  `,
  documentsContentContainer: css`
    width: 100%;
    padding: 1rem;
    background-color: ${theme.colors.background.white};

    @media ${queries.medium} {
      padding: 0;
      background-color: ${theme.colors.background.gray};
    }

    @media ${queries.large} {
      width: 100%;
    }
  `,
  documentsTitle: css`
    font-family: ${theme.fonts.proximaNova};
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.125rem;
    color: ${theme.colors.background.darkGray};
    display: block;
  `,
  columnWrapper: css`
    margin-top: 1rem;
  `,
  horizontalRule: css`
    display: block;
    border: 0.063rem solid ${theme.colors.background.veryLightGray};
    width: 100%;

    @media ${queries.medium} {
      display: none;
    }
  `,
  tabletHorizontalRule: css`
    display: none;

    @media ${queries.medium} {
      display: block;
      border: 0.063rem solid ${theme.colors.background.veryLightGray};
      width: 100%;
    }

    @media ${queries.large} {
      display: none;
    }
  `,
  assetLink: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    color: ${theme.colors.white};
    font-weight: 300;

    :hover {
      color: ${theme.colors.functional.brightOrangeHover};
    }

    svg {
      position: relative;
      top: 0.7rem;
    }
  `,
  linkWrapper: css`
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;

    @media ${queries.medium} {
      white-space: nowrap;
    }

    @media ${queries.large} {
      white-space: normal;
    }
  `,
  openNewIcon: css`
    padding-left: 0.5rem;
  `,
  buttonContainer: css`
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    line-height: normal;
  `,
};
