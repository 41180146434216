import { Collapse } from "antd";
import React, { ReactElement, useState } from "react";
import { AddIcon, RemoveIcon } from "src/app/assets/icons/component-icons";
import styles from "./styles";
import mainStyles from "../../styles";
import SectionTitleAndSubtitle from "../section-title-and-subtitle";

export interface ICollapsiblePanel {
  header: JSX.Element;
  expandedContent: JSX.Element;
  id: string;
  panelTitle?: string;
}

interface IProps {
  mainTitle?: string;
  subtitle?: string;
  panels: ICollapsiblePanel[];
}

const CollapsiblePanels = ({
  mainTitle,
  subtitle,
  panels,
}: IProps): ReactElement => {
  const { Panel } = Collapse;
  const [activeKeys, setActiveKeys] = useState<string | string[]>("");

  const onChange = (key: string | string[]) => {
    setActiveKeys(key);
  };

  return (
    <div css={styles.panelsContainer}>
      <SectionTitleAndSubtitle title={mainTitle} subtitle={subtitle} />
      <Collapse
        activeKey={activeKeys}
        onChange={onChange}
        bordered={false}
        ghost
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <div>{isActive ? <RemoveIcon /> : <AddIcon />}</div>
        )}
      >
        {panels.map(({ id, header, expandedContent, panelTitle }) => {
          return (
            <React.Fragment key={id}>
              {panelTitle && (
                <div css={mainStyles.tableTitle}>{panelTitle}</div>
              )}
              <Panel header={header} key={id}>
                {expandedContent}
              </Panel>
            </React.Fragment>
          );
        })}
      </Collapse>
    </div>
  );
};

export default CollapsiblePanels;
