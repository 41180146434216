import { css, SerializedStyles } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import { play, pause } from "src/app/assets/icons/svg-icons";
import theme from "src/theme";

export default {
  container: css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
  `,
  playButtonContainer: css`
    width: 64px;
    height: 48px;

    @media ${queries.medium} {
      width: 76px;
    }
  `,
  playButton: css`
    border-radius: 50%;
    background-color: ${theme.colors.white};
    height: 64px;
    width: 64px;
  `,
  fileNameContainer: css`
    align-items: center;
    width: 60%;

    @media ${queries.medium} {
      width: 20%;
      min-width: 20%;
      margin-right: 1.5rem;
    }

    @media ${queries.large} {
      margin-right: 2.5rem;
    }
  `,
  fileName(textColour: string): SerializedStyles {
    return css`
      color: ${textColour};
      font-size: 1rem;
      line-height: 1.5rem;
      font-family: ${theme.fonts.sofiaPro};
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
    `;
  },
  artist(textColour: string): SerializedStyles {
    return css`
      color: ${textColour};
      font-size: 0.875rem;
      line-height: 1.125rem;
      font-family: ${theme.fonts.sofiaPro};
      opacity: 0.8;
      font-weight: 300;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 90%;
    `;
  },
  waveform: css`
    display: none;

    @media ${queries.medium} {
      display: block;
      width: 50%;
      margin-right: 0.625rem;
    }

    @media ${queries.large} {
      width: 65%;
    }
  `,
  tabletDownloadButton: css`
    background-color: transparent;
    border: none;
  `,
  playing: css`
    background-image: url(${pause});
  `,
  paused: css`
    background-image: url(${play});
  `,
  control: css`
    padding: 0;
    height: 64px;
    background: transparent;
    border: none;

    @media ${queries.medium} {
      height: 48px;
    }

    &:hover:not(:disabled) {
      circle {
        fill: ${theme.colors.functional.brightOrangeHover};
      }
      path {
        fill: ${theme.colors.white};
        stroke: ${theme.colors.white};
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  `,
  buttonContainer: css`
    align-items: center;
    width: 5%;
    margin-right: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    line-height: normal;
  `,
};
