import { Button } from "@songtradr/component-library";
import { Checkbox, Form, Input } from "antd";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { errorToast, successToast } from "src/components/toast-notification";
import updateOrganisation from "src/api/organisation/update-organisation";
import { ValidateErrorEntity } from "rc-field-form/lib/interface";
import useAuth from "src/auth/use-auth";
import checkFormAndShowNotification, {
  FormNotificationErrors,
  requiredField,
  typeUrl,
} from "src/utils/organisation-dashboard-validation";
import Select from "react-select";
import useServicingOrgDropdown from "src/pages/org-dashboard/components/organisation-dropdown/useServicingOrgDropdown";
import STLoadingLogo from "src/components/st-loading-logo";
import { CurrencyCode, IProps } from "./interfaces";
import styles from "../../styles";
import dropdownStyles from "../organisation-dropdown/styles";
import OrganisationDropdown, { IOption } from "../organisation-dropdown";

const FIELDS = {
  companyName: "companyName",
  organisationId: "organisationId",
  servicingOrganisationId: "servicingOrganisationId",
  parentOrganisationId: "parentOrganisationId",
  holdingOrganisationId: "holdingOrganisationId",
  servicingOrganisationEmail: "servicingOrganisationEmail",
  isPublicCompany: "isPublicCompany",
  marketSymbol: "marketSymbol",
  marketCap: "marketCap",
  numberOfEmployees: "numberOfEmployees",
  instagramFollowerCount: "instagramFollowerCount",
  tikTokFollowerCount: "tikTokFollowerCount",
  currency: "currency",
  enableCommercialMusicSearch: "enableCommercialMusicSearch",
  enableLicensingReports: "enableLicensingReports",
  naturalLanguageSearchVersion: "naturalLanguageSearchVersion",
  licensingOverviewDocumentUrl: "licensingOverviewDocumentUrl",
  enableBriefSearch: "enableBriefSearch",
  enablePasscodes: "enablePasscodes",
  enableAdaptiveMusic: "enableAdaptiveMusic",
  adaptiveMusicUrl: "adaptiveMusicUrl",
};

const colSpan = { span: 24 };

const DetailsSection = ({
  org,
  orgDropdownData = [],
  refreshData,
}: IProps): ReactElement | null => {
  const [form] = Form.useForm();
  const { excludedServicingOrgs, onlyServicingOrgs } = useServicingOrgDropdown(
    orgDropdownData
  );
  const handleFailedSubmit = useCallback((error: ValidateErrorEntity) => {
    checkFormAndShowNotification(error);
  }, []);

  const [selectedServiceOrg, setSelectedServiceOrg] = useState<IOption>({
    value: org?.servicingOrganisation?.id,
    label: orgDropdownData.find(
      (organisation) => organisation.id === org?.servicingOrganisation?.id
    )?.name,
  });

  const parentOrgLabel = orgDropdownData.find(
    (organisation) => organisation.id === org?.parent?.id
  )?.name;

  const [selectedParentOrg, setSelectedParentOrg] = useState<IOption>({
    value: parentOrgLabel ? org?.parent?.id : undefined,
    label: parentOrgLabel,
  });

  const [selectedHoldingOrg, setSelectedHoldingOrg] = useState<IOption>({
    value: org?.holdingCompany?.id,
    label: orgDropdownData.find(
      (organisation) => organisation.id === org?.holdingCompany?.id
    )?.name,
  });

  const [selectedCurrency, setSelectedCurrency] = useState<IOption>({
    value: org?.currency,
    label: org?.currency,
  });

  const [
    naturalLanguageSearchVersion,
    setNaturalLanguageSearchVersion,
  ] = useState<IOption>({
    value: org?.naturalLanguageSearchVersion,
    label: org?.naturalLanguageSearchVersion,
  });

  useEffect(() => {
    if (selectedServiceOrg.value) {
      form.setFieldsValue({
        [FIELDS.servicingOrganisationId]: selectedServiceOrg,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedServiceOrg]);

  const { getAccessToken, isSuperAdmin } = useAuth();
  if (!org) {
    return null;
  }
  const handleFormSubmit = async (values: any) => {
    const accessToken = getAccessToken();
    const request = {
      ...values,
      ...(values.servicingOrganisationId && {
        servicingOrganisation: {
          id: values.servicingOrganisationId.value,
          email: values.servicingOrganisationEmail,
        },
      }),
      ...(selectedParentOrg.value && {
        parent: { id: selectedParentOrg.value },
      }),
      ...(selectedHoldingOrg.value && {
        holdingCompany: { id: selectedHoldingOrg.value },
      }),
      ...(selectedCurrency.value && {
        currency: selectedCurrency.value,
      }),
      ...(naturalLanguageSearchVersion.value && {
        naturalLanguageSearchVersion: naturalLanguageSearchVersion.value,
      }),
    };

    const orgId = org.id;

    if (!orgId) {
      errorToast({ message: "Can't update org without a valid org ID" });
      return;
    }

    try {
      const response = await updateOrganisation(orgId, accessToken, request);

      if (response) {
        successToast({ message: "Organization successfully saved." });
        refreshData();
      }
    } catch (error) {
      errorToast({
        message:
          error.response?.data?.Message ??
          error.message ??
          "Failed to update organization",
      });
    }
  };
  const currencyCodes = Object.keys(CurrencyCode)
    .map((currency) => ({
      value: currency,
      label: currency,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  if (!orgDropdownData) {
    return <STLoadingLogo pageCentered />;
  }

  return (
    <>
      <Form
        form={form}
        onFinish={handleFormSubmit}
        onFinishFailed={handleFailedSubmit}
        key={org.id}
        data-testid="org-details-form"
      >
        <div>
          <h2 css={styles.subHeading}>Organization Details</h2>
          <div css={styles.detailsContainer} data-testid="details-section">
            <Form.Item
              name={FIELDS.companyName}
              label="Company Name"
              labelCol={colSpan}
              wrapperCol={colSpan}
              rules={[requiredField]}
              validateTrigger="onSubmit"
              help={FormNotificationErrors.Required}
              initialValue={org.name}
              preserve={false}
            >
              <Input aria-label="Company Name" data-testid="Company Name" />
            </Form.Item>
            <Form.Item
              name={FIELDS.organisationId}
              label="ID"
              labelCol={colSpan}
              wrapperCol={colSpan}
              initialValue={org.id}
            >
              <Input
                aria-label="Organization ID"
                readOnly
                data-testid="Organization ID"
              />
            </Form.Item>
          </div>

          <h2 css={styles.subHeading}>Servicing Organization</h2>
          <div css={styles.detailsContainer}>
            <Form.Item
              key={FIELDS.servicingOrganisationId}
              name={FIELDS.servicingOrganisationId}
              label="Organization ID"
              labelCol={colSpan}
              wrapperCol={colSpan}
              rules={[requiredField]}
              validateTrigger="onSubmit"
              help={FormNotificationErrors.Required}
            >
              <OrganisationDropdown
                id="servicingOrganisation"
                setValue={setSelectedServiceOrg}
                orgDropdownData={onlyServicingOrgs}
                selectedOrg={selectedServiceOrg}
              />
            </Form.Item>

            <Form.Item
              className="servicing-organisation-email"
              name={FIELDS.servicingOrganisationEmail}
              label="Email"
              labelCol={colSpan}
              wrapperCol={colSpan}
              rules={[requiredField]}
              validateTrigger="onSubmit"
              help={FormNotificationErrors.Required}
              initialValue={org.servicingOrganisation?.email}
            >
              <Input aria-label="Email" />
            </Form.Item>
          </div>

          <h2 css={styles.subHeading}>Parent Organization</h2>
          <div css={styles.detailsContainer}>
            <Form.Item
              name={FIELDS.parentOrganisationId}
              key={FIELDS.parentOrganisationId}
              label="Organization ID"
              labelCol={colSpan}
              wrapperCol={colSpan}
            >
              <OrganisationDropdown
                id="parentOrganisation"
                setValue={setSelectedParentOrg}
                orgDropdownData={excludedServicingOrgs}
                selectedOrg={selectedParentOrg}
              />
            </Form.Item>
          </div>
          {isSuperAdmin && (
            <>
              <h2 css={styles.subHeading}>Passcodes</h2>
              <div css={styles.detailsContainer}>
                <Form.Item
                  name={FIELDS.enablePasscodes}
                  labelCol={colSpan}
                  wrapperCol={colSpan}
                  valuePropName="checked"
                  initialValue={org.enablePasscodes ?? true}
                >
                  <Checkbox aria-label="Enable Passcodes">
                    Enable Passcodes
                  </Checkbox>
                </Form.Item>
              </div>
            </>
          )}

          <h2 css={styles.subHeading}>Holding Organization</h2>
          <div css={styles.detailsContainer}>
            <Form.Item
              name={FIELDS.holdingOrganisationId}
              label="Organization ID"
              labelCol={colSpan}
              wrapperCol={colSpan}
            >
              <OrganisationDropdown
                id="holdingOrganisation"
                setValue={setSelectedHoldingOrg}
                orgDropdownData={excludedServicingOrgs}
                selectedOrg={selectedHoldingOrg}
              />
            </Form.Item>
          </div>

          <h2 css={styles.subHeading}>Music Search</h2>
          <div css={styles.detailsContainer}>
            <Form.Item
              name={FIELDS.enableCommercialMusicSearch}
              labelCol={colSpan}
              wrapperCol={colSpan}
              valuePropName="checked"
              initialValue={org.enableCommercialMusicSearch || false}
            >
              <Checkbox
                id="enableCommercialMusicSearch"
                aria-label="Enable commercial music search"
              >
                Enable commercial music search
              </Checkbox>
            </Form.Item>
          </div>
          <div css={styles.detailsContainer}>
            <Form.Item
              name={FIELDS.enableBriefSearch}
              labelCol={colSpan}
              wrapperCol={colSpan}
              valuePropName="checked"
              initialValue={org.enableBriefSearch ?? true}
            >
              <Checkbox id="enableBriefSearch" aria-label="Enable brief search">
                Enable brief search
              </Checkbox>
            </Form.Item>
          </div>

          <div css={styles.detailsContainer}>
            <Form.Item
              label="Natural Language Search Version"
              name={FIELDS.naturalLanguageSearchVersion}
              labelCol={colSpan}
              wrapperCol={colSpan}
            >
              <div css={dropdownStyles.dropdownContainer}>
                <Select
                  required
                  options={[
                    {
                      label: "Internal",
                      value: "Internal",
                    },
                    {
                      label: "ChatGPT",
                      value: "ChatGPT",
                    },
                  ]}
                  onChange={(value) => {
                    if (value) {
                      setNaturalLanguageSearchVersion(value);
                    }
                  }}
                  className="org-dropdown"
                  classNamePrefix="org-options"
                  defaultValue={naturalLanguageSearchVersion}
                />
              </div>
            </Form.Item>
          </div>

          <h2 css={styles.subHeading}>Adaptive Music</h2>
          <div css={styles.detailsContainer}>
            <Form.Item
              name={FIELDS.enableAdaptiveMusic}
              labelCol={colSpan}
              wrapperCol={colSpan}
              valuePropName="checked"
              initialValue={org.enableAdaptiveMusic || false}
            >
              <Checkbox
                id="enableAdaptiveMusic"
                aria-label="Enable Adaptive Music"
              >
                Enable Adaptive Music
              </Checkbox>
            </Form.Item>
          </div>
          <div css={styles.detailsContainer}>
            <Form.Item
              name={FIELDS.adaptiveMusicUrl}
              label="Adaptive Music URL"
              labelCol={colSpan}
              wrapperCol={colSpan}
              rules={[typeUrl]}
              help={FormNotificationErrors.Url}
              initialValue={org.adaptiveMusicUrl}
            >
              <Input aria-label="Adaptive Music URL" />
            </Form.Item>
          </div>
          <h2 css={styles.subHeading}>Licensing</h2>
          <div css={styles.detailsContainer}>
            <Form.Item
              name={FIELDS.enableLicensingReports}
              labelCol={colSpan}
              wrapperCol={colSpan}
              valuePropName="checked"
              initialValue={org.enableLicensingReports ?? false}
            >
              <Checkbox
                id="enableLicensingReports"
                aria-label="Enable Licensing Reports"
              >
                Enable Licensing Reports
              </Checkbox>
            </Form.Item>
          </div>
          <div css={styles.detailsContainer}>
            <Form.Item
              name={FIELDS.licensingOverviewDocumentUrl}
              label="Licensing Overview Document Location"
              labelCol={colSpan}
              wrapperCol={colSpan}
              initialValue={org.licensingOverviewDocumentUrl}
              rules={[typeUrl]}
              help={FormNotificationErrors.Url}
            >
              <Input aria-label="Licensing Overview Document Location" />
            </Form.Item>
          </div>

          <h2 css={styles.subHeading}>Price Estimation</h2>
          <div css={styles.detailsContainer}>
            <Form.Item
              name={FIELDS.isPublicCompany}
              labelCol={colSpan}
              wrapperCol={colSpan}
              valuePropName="checked"
              initialValue={org.isPublicCompany}
            >
              <Checkbox aria-label="Public Company">Is public company</Checkbox>
            </Form.Item>
          </div>

          <div css={styles.detailsContainer}>
            <Form.Item
              name={FIELDS.marketSymbol}
              label="Market Symbol"
              labelCol={colSpan}
              wrapperCol={colSpan}
              initialValue={org.marketSymbol}
            >
              <Input aria-label="Market Symbol" />
            </Form.Item>

            <Form.Item
              name={FIELDS.marketCap}
              label="Market cap"
              labelCol={colSpan}
              wrapperCol={colSpan}
              initialValue={org.marketCap}
            >
              <Input type="number" aria-label="Market cap" />
            </Form.Item>

            <Form.Item
              name={FIELDS.numberOfEmployees}
              label="Number of employees"
              labelCol={colSpan}
              wrapperCol={colSpan}
              initialValue={org.numberOfEmployees}
            >
              <Input type="number" aria-label="Number of employees" />
            </Form.Item>

            <Form.Item
              name={FIELDS.currency}
              label="Currency"
              labelCol={colSpan}
              wrapperCol={colSpan}
              initialValue={org.currency}
            >
              <div css={dropdownStyles.dropdownContainer}>
                <Select
                  options={currencyCodes}
                  onChange={(value) => {
                    if (value) {
                      setSelectedCurrency(value);
                    }
                  }}
                  className="org-dropdown"
                  classNamePrefix="org-options"
                  defaultValue={selectedCurrency}
                />
              </div>
            </Form.Item>

            <Form.Item
              name={FIELDS.instagramFollowerCount}
              label="Instagram follower count"
              labelCol={colSpan}
              wrapperCol={colSpan}
              initialValue={org.instagramFollowerCount}
            >
              <Input type="number" aria-label="Instagram followers" />
            </Form.Item>

            <Form.Item
              name={FIELDS.tikTokFollowerCount}
              label="TikTok Followers"
              labelCol={colSpan}
              wrapperCol={colSpan}
              initialValue={org.tikTokFollowerCount}
            >
              <Input type="number" aria-label="TikTok followers" />
            </Form.Item>
          </div>

          <div css={styles.detailsContainer}>
            <Form.Item>
              <Button
                css={styles.submitBtn}
                variant="primary"
                type="submit"
                data-testid="submit-btn"
              >
                Save changes
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </>
  );
};

export default DetailsSection;
