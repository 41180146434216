import { ReactElement } from "react";
import ChildOrChildren from "../utils/child-or-children";

interface IProps {
  condition: boolean;
  children: ReactElement | ReactElement[];
}

const ConditionallyVisible = ({
  condition,
  children,
}: IProps): ReactElement | null => {
  if (!condition) return null;

  return <ChildOrChildren>{children}</ChildOrChildren>;
};

export default ConditionallyVisible;
