import { useEffect, useRef, useState } from "react";
import axios, { AxiosError } from "axios";

interface IProps<T> {
  fetchData: (abortController: AbortController) => Promise<T | null>;
  shouldRePoll: (arg: T | null) => boolean;
  pollingInterval: number;
}

/**
 * Allows polling an endpoint based on the outcome of the shouldRePoll prop.
 * Initial polling is started lazily by calling fetchData
 * @param fetchData - Calls the API
 * @param shouldRePoll - Determines if we should stop polling
 * @param pollingInterval
 */
const useApiPoll = <T>({
  fetchData,
  shouldRePoll,
  pollingInterval,
}: IProps<T>) => {
  const [data, setData] = useState<T | null>(null);
  const abortController = useRef<AbortController>();
  const pollSetTimeout = useRef<NodeJS.Timeout>();

  const clearCurrentPoll = () => {
    clearTimeout(pollSetTimeout.current);
  };

  const triggerFetchData = async () => {
    if (!abortController.current) {
      // eslint-disable-next-line no-console
      console.error("Tried to start polling without an abort controller");
      return;
    }

    const registerPoll = () => {
      clearCurrentPoll();
      pollSetTimeout.current = setTimeout(triggerFetchData, pollingInterval);
    };

    let newData = null;

    try {
      newData = await fetchData(abortController.current);
    } catch (e: unknown) {
      if (axios.isAxiosError(e) && e.code === AxiosError.ERR_CANCELED) {
        throw e;
      }

      // eslint-disable-next-line no-console
      console.error(e);

      throw e;
    }

    setData(newData);

    if (!shouldRePoll(newData)) {
      return;
    }

    registerPoll();
  };

  useEffect(() => {
    abortController.current = new AbortController();

    return () => {
      clearCurrentPoll();
      abortController.current?.abort();
    };
  }, []);

  return { data, triggerFetchData, abortController: abortController?.current };
};

export default useApiPoll;
