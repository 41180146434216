import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  control: css`
    align-items: center;
    background-color: transparent;
    background-image: none;
    background-origin: content-box;
    background-repeat: no-repeat;
    background-size: cover;
    border: 0;
    box-shadow: none;
    color: ${theme.colors.white};
    cursor: pointer;
    display: inline-flex;
    font-size: 1.5rem;
    font-weight: 300;
    justify-content: center;
    line-height: 1.5715;
    text-align: center;
    touch-action: manipulation;
    padding-right: 0 !important;
    user-select: none;
    white-space: nowrap;
    padding-left: 0 !important;
    height: 38px;
    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  `,
  controlWithTooltip: css`
    border-radius: 30px;
    padding: 0;
    margin: 0;
    height: auto;
    .ant-tooltip-arrow-content {
      height: 1rem;
      width: 1rem;
      position: unset !important;
      right: unset !important;
      left: unset !important;
      margin-top: -0.125rem;
      margin-left: -0.156rem;
    }

    .ant-tooltip-arrow {
      overflow: visible;
      z-index: -1;
    }

    :hover {
      background-color: ${theme.colors.background.veryLightGray};
    }
  `,
  label: css`
    color: ${theme.colors.black};
    font-weight: 600;
    font-size: 0.85rem;
    margin-left: 1rem;
  `,
  tooltip: css`
    display: none;
    @media ${queries.medium} {
      display: block;
    }
  `,
};
