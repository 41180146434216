import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  servicesSectionContainer: css`
    position: relative;
    margin-top: 96px;
    margin-bottom: 96px;
    @media (max-width: 768px) {
      margin-top: 32px;
    }
  `,
  servicesHeaderContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  carouselContainer: css`
    .rec-slider-container {
      margin-left: 0;
      margin-right: 0;

      .rec-item-wrapper {
        padding-top: 24px !important;
        padding-bottom: 24px !important;
      }

      img {
        transition: all 0.3s ease 0s;
      }

      &:hover:has(.service-tile img:hover) {
        .service-tile img:hover {
          opacity: 0.5;
          box-shadow: 0 8px 32px 0 ${theme.colors.blackShadow};
        }
      }
    }

    .swiper-pagination {
      bottom: 0;

      @media ${queries.medium} {
        display: none;
      }
    }

    .swiper-container {
      padding-bottom: 3rem;
      padding-top: 1rem;

      @media ${queries.medium} {
        padding-bottom: 1rem;
      }
    }

    .swiper-pagination-bullet {
      background: ${theme.colors.secondary.mediumGrey};
    }

    .swiper-pagination-bullet-active {
      background: ${theme.colors.secondary.waveformGray};
    }

    .swiper-slide {
      transition: all 0.15s ease-in-out;
      width: 95%;
      max-width: 350px;

      @media ${queries.small} {
        width: 50%;
      }

      @media ${queries.medium} {
        width: 35%;
      }

      @media ${queries.extraLarge} {
        width: 25%;
      }

      :hover {
        transform: scale(1.035);
      }
    }
  `,
};
