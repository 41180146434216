import React, { ReactElement, useMemo } from "react";
import useAuth from "src/auth/use-auth";
import { Redirect } from "react-router-dom";
import ProjectListings from "./components/project-listings/container";

export enum ProjectListingType {
  CommercialLicenses = "Commercial Licenses",
  LibraryLicenses = "Library Licenses",
}
interface IProps {
  projectType: ProjectListingType;
}

const Projects = ({ projectType }: IProps): ReactElement | null => {
  const { userPermissions, isLoading, user, organisationId } = useAuth();
  const isPageSetUp = useMemo(() => {
    return !isLoading && !!user && !!organisationId && userPermissions;
  }, [isLoading, user, organisationId, userPermissions]);

  if (isLoading) {
    return null;
  }

  if (!userPermissions?.canAccessProjects && isPageSetUp) {
    return <Redirect to="/home" />;
  }

  return <ProjectListings projectType={projectType} />;
};

export default Projects;
