export interface ILibraryTrackDetailsChannel {
  type: string;
  usageLinks: string[];
  id: string;
  name: string;
  url: string;
}

interface ILibraryTrackUserData {
  id: string;
  email: string;
  name: string;
}

export interface IProjectLibraryTrackDetails {
  id: string;
  projectId: string;
  trackId: string;
  trackTitle: string;
  trackArtist: string;
  downloadedByUser: ILibraryTrackUserData;
  declaredByUser: ILibraryTrackUserData;
  goLiveDate: string;
  projectName: string;
  projectDescription: string;
  businessBrand: string;
  regions: string[];
  channels: ILibraryTrackDetailsChannel[];
  createdDate: string;
}

export enum LibraryTrackSortType {
  trackId = "trackId",
  trackTitle = "trackTitle",
  trackArtist = "trackArtist",
  downloadedBy = "downloadedBy",
  declaredBy = "declaredBy",
  createdDate = "createdDate",
  projectName = "projectName",
  projectDescription = "projectDescription",
  businessBrand = "businessBrand",
  goLiveDate = "goLiveDate",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export interface ILibraryTrackTableSort {
  sortBy: LibraryTrackSortType;
  order: SortOrder;
}

export interface ILibraryTrackDataSource {
  key: string;
  trackName?: JSX.Element;
  projectName?: JSX.Element;
  businessBrand?: JSX.Element;
  platform?: JSX.Element | null;
  channels?: JSX.Element;
  declaredUsage?: JSX.Element;
  goLiveDate?: JSX.Element;
  createdDate?: JSX.Element;
}
