import { Button } from "@songtradr/component-library";
import { Dropdown, Menu } from "antd";
import { format } from "date-fns";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { standardToast } from "src/components/toast-notification";
import copy from "copy-to-clipboard";
import { IPassCode } from "../interfaces";
import styles from "../../../styles";
import MoreInfoButton from "../../more-info-button";

export const copyCode = (code: string) => {
  const copySuccess = copy(code);
  if (copySuccess) {
    standardToast({ message: "Passcode copied" });
  }
};

export const formatTableData = (
  data: IPassCode[],
  handleDelete: (id: string) => void,
  togglePasscodeStatus: (id: string, status: string) => void,
  editPasscode: (id: string) => void
) => {
  const formattedData = data.map((item) => {
    return {
      id: item.id,
      name: item.name,
      code: item.code,
      permissions: item.applications.join(", "),
      lastUpdated: format(new Date(item.lastUpdated), "MMM dd, yyy"),
      createdBy: item.createdByName,
      status:
        item.status === "Active" ? (
          <CheckCircleOutlined style={{ fontSize: "32px", color: "#20C885" }} />
        ) : (
          <MinusCircleOutlined style={{ fontSize: "32px" }} />
        ),
      copy: (
        <Button
          disabled={item.status !== "Active"}
          variant="important"
          onClick={() => copyCode(item.code)}
          className="copy-button"
          data-testid="copy-code-button"
        >
          Copy code
        </Button>
      ),
      options: (
        <Dropdown
          aria-label="action-items-dropdown"
          overlay={
            <Menu css={styles.dropdownMenu} data-testid="action-items-dropdown">
              <Menu.Item key="1">
                <button
                  type="button"
                  aria-label="Edit"
                  onClick={() => editPasscode(item.id)}
                  data-testid="edit-pass-code-btn"
                >
                  Edit passcode
                </button>
              </Menu.Item>
              <Menu.Item key="2">
                <button
                  onClick={() =>
                    togglePasscodeStatus(
                      item.id,
                      item.status === "Active" ? "Inactive" : "Active"
                    )
                  }
                  type="button"
                  aria-label={item.status === "Active" ? "Disable" : "Enable"}
                  data-testid={`${
                    item.status === "Active" ? "disable" : "enable"
                  }-pass-code-btn`}
                >
                  {item.status === "Active"
                    ? "Disable passcode"
                    : "Enable passcode"}
                </button>
              </Menu.Item>
              <Menu.Item key="3">
                <button
                  type="button"
                  aria-label="Delete"
                  data-testid="delete-pass-code-btn"
                  onClick={() => handleDelete(item.id)}
                >
                  Delete passcode
                </button>
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
          getPopupContainer={(triggerNode) => triggerNode}
          placement="bottomRight"
        >
          <MoreInfoButton
            className="more-info-button"
            showTooltip
            customStyles={styles.moreInfoButton}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation();
            }}
          />
        </Dropdown>
      ),
    };
  });
  return formattedData;
};
