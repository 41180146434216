import { AxiosError } from "axios";
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  useReducer,
} from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import getProject from "src/api/projects/get-project";
import useAuth from "src/auth/use-auth";
import {
  IFinalTrackOwnership,
  IFeeSplitDetailState,
  INameLinkState,
  IProjectAttachmentsUpdate,
  IProjectForm,
  IProjectMusicSection,
} from "src/pages/projects/client-project-view/interfaces";

import { PickKeys } from "ts-essentials";
import ProjectsContext from "./context";
import mapProjectResponse, {
  initialiseAttachments,
} from "./projectResponseMapper";
import { InitialProjectState, projectReducer } from "./store";
import { ProjectActions } from "./types";

interface IAccountProvider {
  children: ReactNode;
}
const ProjectsProvider = ({ children }: IAccountProvider): ReactElement => {
  const { getAccessToken, isAuthenticated, organisationId } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [projectFound, setProjectFound] = useState<boolean>(true);
  const [serverErrorOccurred, setServerErrorOccurred] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [state, dispatch] = useReducer(projectReducer, InitialProjectState);

  const storeProject = (projectToStore: IProjectForm) => {
    // Map attachments
    // eslint-disable-next-line no-param-reassign
    projectToStore.purchaseOrderAttachments = initialiseAttachments(
      projectToStore,
      "PurchaseOrder",
      true
    );

    // eslint-disable-next-line no-param-reassign
    projectToStore.licenseAttachments = initialiseAttachments(
      projectToStore,
      "License",
      true
    );

    // eslint-disable-next-line no-param-reassign
    projectToStore.invoiceAttachments = initialiseAttachments(
      projectToStore,
      "Invoice",
      true
    );

    // eslint-disable-next-line no-param-reassign
    projectToStore.estimateAttachments = initialiseAttachments(
      projectToStore,
      "Estimate",
      true
    );

    // eslint-disable-next-line no-param-reassign
    projectToStore.miscAttachments = initialiseAttachments(
      projectToStore,
      "Misc",
      true
    );

    // eslint-disable-next-line no-param-reassign
    projectToStore.assetAttachments = initialiseAttachments(
      projectToStore,
      "Asset",
      true
    );

    // eslint-disable-next-line no-param-reassign
    projectToStore.briefAttachments = initialiseAttachments(
      projectToStore,
      "Brief"
    );

    dispatch({
      type: ProjectActions.STORE_PROJECT,
      value: { project: projectToStore },
    });
  };

  const storeAttachments = (attachmentUpdate: IProjectAttachmentsUpdate) => {
    dispatch({
      type: ProjectActions.STORE_ATTACHMENTS,
      value: attachmentUpdate,
    });
  };

  const addNameLinkRowForArray = (
    arrayName: PickKeys<IProjectMusicSection, Array<INameLinkState>>
  ) => {
    dispatch({
      type: ProjectActions.ADD_NAME_LINK_ROW,
      value: arrayName,
    });
  };

  const addFinalTrackLinksRow = () => {
    dispatch({
      type: ProjectActions.ADD_FINAL_TRACK_LINKS_ROW,
    });
  };

  const addFeeRowForArray = (
    arrayName: PickKeys<IFinalTrackOwnership, Array<IFeeSplitDetailState>>
  ) => {
    dispatch({
      type: ProjectActions.ADD_FEE_SPLIT_ROW,
      value: arrayName,
    });
  };

  useEffect(() => {
    void (async () => {
      if (isAuthenticated && organisationId) {
        try {
          setIsLoading(true);
          const accessToken: string = await getAccessToken();
          const response = await getProject(accessToken, organisationId, id);

          if (response) {
            const updatedResponse = mapProjectResponse(response);
            storeProject(updatedResponse);
          }
          setIsLoading(false);
        } catch (reason) {
          const error = reason as AxiosError;
          setIsLoading(false);
          if (error.response?.status === 500) {
            setServerErrorOccurred(true);
          }
          if (error.response?.status === 403) {
            history.push("/commerciallicenses");
          }
          if (error.response?.status === 404) {
            setProjectFound(false);
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessToken, isAuthenticated, t, id, organisationId]);

  return (
    <ProjectsContext.Provider
      value={{
        project: state,
        isLoading,
        projectFound,
        serverErrorOccurred,
        storeProject,
        storeAttachments,
        addNameLinkRowForArray,
        addFinalTrackLinksRow,
        addFeeRowForArray,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
export default ProjectsProvider;
