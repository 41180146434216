import { css } from "@emotion/react";
import { StylesConfig } from "react-select";
import theme from "src/theme";
import { CSSObject } from "styled-components";

type PositionType = "absolute" | "relative" | "fixed" | "sticky";

const scrollbarStyles: CSSObject = {
  "::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none",
  scrollbarWidth: "none",
};
const darkGray = "linear-gradient(0deg, #141414, #141414)";
const multiValuesStyles = (
  isMenuOpened: boolean,
  isSelectFocused: boolean
): CSSObject => {
  return {
    width: "106px",
    height: "24px",
    borderRadius: "20px",
    backgroundColor: theme.colors.darkCharcoal,
    color: theme.colors.white,
    margin: 0,
    marginRight: "4px",
    marginBottom: "4px",
    display: isMenuOpened || isSelectFocused ? "none" : "flex",
  };
};

const componentHeight = 48;

export const customStyles = (
  isMenuOpened: boolean,
  isSelectFocused: boolean
): StylesConfig => {
  return {
    menu: (provided) => ({
      ...provided,
      zIndex: 5000,
      marginTop: 0,
      "::before": {
        content: "''",
        height: 1,
        display: "flex",
        background: theme.colors.darkCharcoal,
        width: "90%",
        marginRight: "auto",
        marginLeft: "auto",
        marginTop: "-1px",
      },
      cursor: "pointer",
      border: `1px solid ${theme.colors.darkCharcoal}`,
      borderTopLeftRadius: 0,
      borderTop: "none",
      borderTopRightRadius: 0,
      background: darkGray,
      color: theme.colors.white,
    }),
    menuList: (provided) => ({
      ...provided,
      ...scrollbarStyles,
    }),
    option: (provided) => ({
      ...provided,
      cursor: "pointer",
      background: darkGray,
      "&:hover": {
        background: "rgba(51, 51, 51, 0.5) !important",
      },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: "none",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: componentHeight,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      color: theme.colors.white,
      "&:hover": {
        color: theme.colors.white,
      },
      transform: isMenuOpened ? "rotate(180deg)" : "",
      transition: "all 300ms",
      height: componentHeight,
    }),
    control: (provided) => ({
      ...provided,
      width: 300,
      cursor: "pointer",
      height: componentHeight,
      borderRadius: 8,
      borderBottomLeftRadius: isMenuOpened
        ? 0
        : provided.borderBottomLeftRadius,
      borderBottomRightRadius: isMenuOpened
        ? 0
        : provided.borderBottomRightRadius,
      zIndex: 9,
      border: `1px solid ${theme.colors.darkCharcoal}`,
      borderBottom: isMenuOpened ? "none" : provided.borderBottom,
      borderColor: theme.colors.darkCharcoal,
      background: darkGray,
      boxShadow: `0 8px 32px 0 ${theme.colors.blackShadow}`,
      "&:hover": {
        borderColor: isMenuOpened ? "none" : theme.colors.white,
      },
    }),
    input: (provided) => ({
      ...provided,
      paddingBottom: 0,
      margin: 0,
      color: theme.colors.white,
      display: "block",
      height: isSelectFocused ? componentHeight : "0",
      paddingLeft: "16px",
      paddingTop: isSelectFocused ? "16px" : "0",
    }),
    valueContainer: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
      height: "28px",
      paddingLeft: isSelectFocused ? "0" : "16px",
      overflow: "unset",
      ...scrollbarStyles,
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: 0,
      padding: 0,
      color: theme.colors.white,
      visibility: isSelectFocused ? "hidden" : "initial",
      width: "fit-content",
      maxWidth: "100%",
      paddingRight: "6px",
    }),
    multiValue: (provided) => ({
      ...provided,
      ...multiValuesStyles(isMenuOpened, isSelectFocused),
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      ...multiValuesStyles(isMenuOpened, isSelectFocused),
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      backgroundColor: "inherit !important",
      borderRadius: "20px",
    }),
  };
};

export default {
  customSelectWrapper: css`
    position: relative;
  `,

  floatingLabel: (isInputFocused: boolean) => ({
    position: "absolute" as PositionType,
    transition: "all 300ms",
    fontSize: isInputFocused ? 12 : 16,
    fontWeight: 300,
    zIndex: 5001,
    top: isInputFocused ? 8 : 12,
    left: 16,
    color: theme.colors.white,
  }),

  openedMenu: css`
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `,
};
