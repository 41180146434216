import React from "react";
import styles from "./styles";
import IProps from "./interfaces";

function ProductTable({ products }: IProps) {
  return (
    <table css={styles.table}>
      <thead>
        <tr>
          <th>Brand</th>
          <th>Regional Brands</th>
        </tr>
      </thead>
      <tbody>
        {products.globalBrands.map((brand) => (
          <tr key={brand.id}>
            <td>{brand.brandName}</td>
            <td>
              {brand.regionalBrands.map((regionalBrand) => (
                <p key={regionalBrand.id}>{regionalBrand.brandName}</p>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ProductTable;
