import { css, SerializedStyles } from "@emotion/react";
import theme from "src/theme";

export default {
  container(showBorder: boolean): SerializedStyles {
    return css`
      font-family: ${theme.fonts.sofiaPro};
      .slider-menu {
        box-shadow: -16px 0 32px 0 rgba(0, 0, 0, 0.5);
        position: fixed;
        z-index: 9999;
        top: 0;
        right: -450px;
        height: 100%;
        width: 450px;
        background-color: ${theme.colors.background.basaltBlack};
        transition: right 0.3s ease-in-out;
        overflow: scroll;
        input {
          color: ${theme.colors.white};
          background: ${theme.colors.black};
          &:focus {
            border-color: ${theme.colors.white};
          }
        }
      }

      .slider-menu.open {
        right: 0;
      }

      .toggle-button {
        background: #333;
        color: ${theme.colors.white};
        border: none;
        padding: 10px;
        cursor: pointer;
      }

      .menu-content {
        margin: 88px 16px 0 24px;
        padding-bottom: 20px;
        border-bottom: ${showBorder ? "1px solid #333" : "none"};
        color: ${theme.colors.white} !important;
        h1 {
          font-size: 24px;
          line-height: 32px;
          color: ${theme.colors.white};
        }
        h2 {
          font-size: 14px;
          line-height: 18px;
          color: ${theme.colors.white};
        }
        p {
          margin-bottom: 24px;
        }
        label {
          color: ${theme.colors.secondary.textSecondary};
          font-size: 12px;
        }
      }
      .close-icon {
        position: absolute;
        top: 10px;
        right: 10px;
        color: ${theme.colors.white};
        cursor: pointer;
        width: 18px;
        height: 18px;
        margin: 14px;
      }
      .back-icon {
        position: absolute;
        top: 10px;
        left: 10px;
        color: ${theme.colors.secondary.mediumGrey};
        margin: 14px;
      }
      .button-container {
        position: relative;
        float: right;
        bottom: 0;
        right: 0;
        margin-top: 16px;
        margin-right: 24px;
        margin-bottom: 16px;
        padding-top: 16px;
        .primary {
          margin-left: 8px;
        }
      }
    `;
  },
};
