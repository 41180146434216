import React, { ReactElement, useEffect, useState } from "react";
import getOrganisation from "src/api/organisation/get-organisation";
import getProductInformation from "src/api/product-information/get-product-information";
import updateTheme, { IImgFormData } from "src/api/update-theme";
import useAuth from "src/auth/use-auth";
import { Button } from "@songtradr/component-library";
import { errorToast, successToast } from "src/components/toast-notification";
import { Upload } from "antd";
import isFulfilled from "src/utils/type-guards";
import deleteTheme from "src/api/delete-theme";
import saveProductInformation, {
  IProductInformationFormData,
} from "src/api/product-information/save-product-information";
import ModalPortal from "src/components/modal-portal";
import BaseModal from "src/components/modals/base-modal";
import STLoadingLogo from "src/components/st-loading-logo";
import styles from "../../styles";
import logoStyles from "./styles";
import ProductTable from "../product-table";
import { FileUpload, IImgPreviews, IProps } from "./interfaces";
import IProductTableProps from "../product-table/interfaces";

const validImgMimeTypes = ["image/png", "image/jpeg"];
const mbToByteConversions = {
  "5": 5242880,
  "10": 10485760,
};

const LogoProductInfoSection = ({ org }: IProps): ReactElement | null => {
  const [productInfo, setProductInfo] = useState<IProductTableProps>();
  const [imagePreview, setImagePreview] = useState<IImgPreviews>();
  const [isLoading, setIsLoading] = useState(false);
  const [logoGuidelinesOpen, setLogoGuidelinesOpen] = useState<boolean>(false);
  const [
    backgroundImgGuidelinesOpen,
    setBackgroundImgGuidelinesOpen,
  ] = useState<boolean>(false);
  const { getAccessToken } = useAuth();
  const accessToken = getAccessToken();

  const LogoGuidelinesModalContent = () => {
    return (
      <>
        <h2 css={styles.modalTitle}>Logo Image Guidelines</h2>
        <div css={styles.modalContent}>
          <ul>
            <li>
              PNG format is preferred for its ability to maintain transparency.
              Avoid JPEG to ensure clarity and quality.
            </li>
            <li>
              Keep your logo file size under 5MB to ensure smooth uploading and
              processing.
            </li>
            <li>
              Your logo should be at least 256 pixels in height to guarantee
              visibility and clarity.
            </li>
            <li>
              Opt for a landscape-oriented logo to maximize visibility and
              adaptability across different platforms and backgrounds.
            </li>
            <li>
              Utilize transparency in your PNG file to seamlessly integrate your
              logo with various backgrounds without any unwanted white space.
            </li>
          </ul>
        </div>
      </>
    );
  };

  const BackgroundImageModalContent = () => {
    return (
      <>
        <h2 css={styles.modalTitle}>Background Image Guidelines</h2>
        <div css={styles.modalContent}>
          <ul>
            <li>
              Ensure your background image is at least 2000 pixels wide to cover
              various screen sizes adequately.
            </li>
            <li>
              Keep your image file under 10MB for optimal website performance.
            </li>
            <li>
              Opt for JPEG for a good balance between image quality and file
              size, though PNG is also accepted.
            </li>
            <li>
              Landscape orientation is recommended for fullscreen display.
            </li>
            <li>
              Darker images are preferable as they enhance readability and allow
              content to stand out effectively against the background.
            </li>
          </ul>
        </div>
      </>
    );
  };

  const handleImgDeletion = async (filename: keyof IImgPreviews) => {
    if (!org?.id) {
      return Promise.reject();
    }

    try {
      await deleteTheme(org.id, accessToken, {
        [filename]: true,
      });
    } catch (error) {
      errorToast({ message: error.response.data.Message });
      return Promise.reject();
    }

    setImagePreview({
      ...imagePreview,
      [filename]: null,
    });

    successToast({ message: "Image was successfully deleted." });
    return Promise.resolve();
  };

  const handleImgUpload = async (
    { file, filename }: FileUpload,
    maxFileSizeInMb: keyof typeof mbToByteConversions
  ) => {
    const formData = new FormData();

    if (!org?.id) {
      return Promise.reject();
    }

    if (file.size > mbToByteConversions[maxFileSizeInMb]) {
      errorToast({
        message: `File is too large, maximum size is ${maxFileSizeInMb}mb`,
      });
      return Promise.reject();
    }

    if (!validImgMimeTypes.includes(file.type)) {
      errorToast({
        message: "Invalid file type, only JPEG and PNG files are allowed",
      });
      return Promise.reject();
    }

    formData.append(filename, file);

    try {
      await updateTheme(
        org.id,
        accessToken,
        (formData as unknown) as IImgFormData
      );
    } catch (error) {
      errorToast({ message: error.response.data.Message });
      return Promise.reject();
    }

    const objectUrl = URL.createObjectURL(file);

    setImagePreview({
      ...imagePreview,
      [filename]: objectUrl,
    });

    successToast({ message: "Image upload successful." });
    return Promise.resolve();
  };

  const renderImg = (filename: keyof IImgPreviews) => {
    if (imagePreview && imagePreview[filename]) {
      return <img src={imagePreview[filename]} alt="" />;
    }

    return (
      <p>
        No image uploaded, any changes to the logo can take some time to appear
        because it&apos;s cached in the CDN.
      </p>
    );
  };

  const onProductInfoUpload = async ({ file }: FileUpload) => {
    const formData = new FormData();

    if (!org?.id) {
      return Promise.reject();
    }

    formData.append("ProductInformation", file);

    try {
      const response = await saveProductInformation(
        org.id,
        accessToken,
        (formData as unknown) as IProductInformationFormData
      );

      if (response) {
        successToast({ message: "Product info upload successful." });
      }

      return response;
    } catch (error) {
      errorToast({ message: error.response.data.Message });
      return Promise.reject();
    }
  };

  useEffect(() => {
    if (!org?.id) {
      return;
    }
    (async () => {
      const logoPromise = getOrganisation(org.id, accessToken);
      const productPromise = getProductInformation(org.id, accessToken);

      setIsLoading(true);

      try {
        const values = await Promise.allSettled([logoPromise, productPromise]);
        setIsLoading(false);

        const logoPromiseResolved = values[0];
        const productPromiseResolved = values[1];

        if (isFulfilled(logoPromiseResolved)) {
          setImagePreview({
            PrimaryLogo: logoPromiseResolved.value?.primaryLogo,
            BackgroundImage: logoPromiseResolved.value?.backgroundImage,
          });
        }

        if (isFulfilled(productPromiseResolved)) {
          setProductInfo(productPromiseResolved.value);
        }
      } catch (e) {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org?.id]);

  if (!org) {
    return null;
  }

  return (
    <div css={styles.logoContainer}>
      {logoGuidelinesOpen && (
        <ModalPortal>
          <BaseModal
            visible={logoGuidelinesOpen}
            content={<LogoGuidelinesModalContent />}
            buttons={[]}
            onClose={() => setLogoGuidelinesOpen(false)}
          />
        </ModalPortal>
      )}
      {backgroundImgGuidelinesOpen && (
        <ModalPortal>
          <BaseModal
            visible={backgroundImgGuidelinesOpen}
            content={<BackgroundImageModalContent />}
            buttons={[]}
            onClose={() => setBackgroundImgGuidelinesOpen(false)}
          />
        </ModalPortal>
      )}
      <h2 css={styles.subHeading}>Logo</h2>
      <div className="columns">
        <div>
          <Upload
            className="parent"
            customRequest={(file) => handleImgUpload(file, "5")}
            showUploadList={false}
            name="PrimaryLogo"
          >
            <Button variant="primary" data-testid="upload-logo-btn">
              Upload
            </Button>
          </Upload>
          <div css={styles.guidelineText}>
            For image guidelines click
            <button
              type="button"
              onClick={() => setLogoGuidelinesOpen(true)}
              css={styles.linkText}
            >
              here
            </button>
          </div>
          <div css={logoStyles.deleteImgContainer}>
            <Button onClick={() => handleImgDeletion("PrimaryLogo")}>
              Delete image
            </Button>
          </div>
        </div>
        <div className="org-logo">
          {isLoading ? <STLoadingLogo /> : renderImg("PrimaryLogo")}
        </div>
      </div>
      <h2 css={styles.subHeading}>Background image</h2>
      <div className="columns">
        <div>
          <Upload
            className="parent"
            customRequest={(file) => handleImgUpload(file, "10")}
            showUploadList={false}
            name="BackgroundImage"
          >
            <Button variant="primary" data-testid="upload-logo-btn">
              Upload
            </Button>
          </Upload>
          <div css={styles.guidelineText}>
            For image guidelines click
            <button
              type="button"
              onClick={() => setBackgroundImgGuidelinesOpen(true)}
              css={styles.linkText}
            >
              here
            </button>
          </div>
          <div css={logoStyles.deleteImgContainer}>
            <Button onClick={() => handleImgDeletion("BackgroundImage")}>
              Delete image
            </Button>
          </div>
        </div>
        <div className="org-logo">
          {isLoading ? <STLoadingLogo /> : renderImg("BackgroundImage")}
        </div>
      </div>
      <h2 className="product-info-heading">Product info</h2>
      <div className="columns">
        <div>
          <Upload
            className="parent"
            customRequest={onProductInfoUpload}
            showUploadList={false}
          >
            <Button variant="primary" data-testid="upload-product-info-btn">
              Upload
            </Button>
          </Upload>
        </div>
        <div className="product-info" data-testid="product-info">
          {productInfo && productInfo.products ? (
            <ProductTable products={productInfo.products} />
          ) : (
            <p>No product info uploaded.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default LogoProductInfoSection;
