import React, { HTMLAttributes, ReactElement } from "react";
import styles from "./styles";

const CopyrightFooter = (
  props: HTMLAttributes<HTMLDivElement>
): ReactElement | null => {
  return (
    <div css={styles.footer} {...props}>
      Copyright &copy; {new Date().getFullYear()} Songtradr. All rights
      reserved.
    </div>
  );
};

export default CopyrightFooter;
