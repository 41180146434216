import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  passwordCriteria: css`
    margin-top: 2.5rem;
    height: fit-content;
    font-size: 0.75rem;
    border-radius: 8px;
    background-color: none;
    line-height: 0.9375rem;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};

    ul {
      list-style: none;
      padding-left: 0;
      padding-top: 1rem;
      li {
        display: flex;
        font-size: 1rem;
        font-weight: 300;
        line-height: 24px;
        align-items: center;
        padding-bottom: 1rem;

        svg {
          margin-right: 1rem;
          width: 24px;
          height: 24px;
        }
      }
    }
  `,
  showHidePasswordBtn: css`
    display: flex;
    align-items: center;
  `,
  check: css`
    color: ${theme.colors.functional.successGreen};
  `,
  info: css`
    color: ${theme.colors.white};
  `,
  inputField: css`
    height: 64px;
  `,
  inputPassword: css`
    background-color: ${theme.colors.background.landingDarkGray} !important;
    span {
      width: auto;
      pointer-events: auto;
    }
  `,
  btnContainer: css`
    display: flex;
    margin-top: 1.5rem;
    flex-direction: column;
    align-items: flex-end;

    @media ${queries.large} {
      justify-content: space-between;
      flex-direction: row;
      align-items: unset;
    }

    svg {
      margin: 0 0 0 1rem;
    }
  `,
  secondaryBtn: css`
    display: flex;
    margin-bottom: 1.5rem;

    button {
      color: ${theme.colors.white};
      :hover {
        color: ${theme.colors.functional.brightOrange};
      }
    }
    @media ${queries.large} {
      margin-bottom: 0;
    }
  `,
  passwordInstruction: css`
    color: ${theme.colors.secondary.waveformGray};
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5rem;
    letter-spacing: 3.3px;
    text-transform: uppercase;
  `,
  validPassword: css`
    border-radius: 8px;
    padding: 1.5rem;
    margin-top: 1.5rem;
    background-color: ${theme.colors.functional.successGreenLight};
    font-size: 0.875rem;
    font-family: ${theme.fonts.sofiaPro};
  `,
};
