import React, { ReactElement, ReactNode, forwardRef } from "react";
import { Tooltip } from "antd";
import theme from "src/theme";
import styles from "./styles";

interface IProps {
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  showTooltip?: boolean;
  showLabel?: boolean;
  tooltipColor?: string;
  "data-testid"?: string;
}

const ControlButton = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      label,
      onClick,
      disabled,
      className = "",
      children,
      showTooltip = false,
      showLabel = false,
      tooltipColor = theme.colors.black,
      "data-testid": dataTestId,
    }: IProps,
    ref
  ): ReactElement => (
    <Tooltip
      css={styles.tooltip}
      placement="topRight"
      title={label}
      color={tooltipColor}
      getPopupContainer={(triggerNode) => triggerNode}
      {...(showTooltip && { visible: false })}
    >
      <button
        ref={ref}
        aria-label={label}
        css={[styles.control, showTooltip && styles.controlWithTooltip]}
        className={className}
        onClick={(e) => onClick(e)}
        disabled={disabled}
        type="button"
        data-testid={dataTestId}
      >
        {children}
        {showLabel && <span css={styles.label}>{label}</span>}
      </button>
    </Tooltip>
  )
);

export default ControlButton;
