import axios from "axios";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";
import { unassignMemberUrl } from "../../organisation-members-url-helper";

const unassignMember = (
  accessToken: string,
  organisationId: string,
  destinationOrganisationId: string,
  userId: string
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.post(
    unassignMemberUrl(organisationId),
    {
      userId,
      destinationOrganisationId,
    },
    options
  );
};

export default unassignMember;
