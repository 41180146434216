import axios from "axios";
import config from "src/config/config";
import { getAuthHeader, getJsonContentHeader } from "../headers";

export interface ISuperadmin {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
}

export const SUPERADMIN_PAGE_SIZE = 10;

export const createSuperadmin = async (
  accessToken: string,
  data: ISuperadmin,
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const response = await axios.post(
    `${config.organisationSubscriptionsService.url}/superAdmins`,
    data,
    options
  );
  return response.data;
};

export const deleteSuperadmin = async (
  accessToken: string,
  id: string,
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };

  const { data } = await axios.delete(
    `${config.organisationSubscriptionsService.url}/superAdmins/${id}`,
    options
  );
  return data;
};

export const getSuperadmins = async (
  accessToken: string,
  page: number,
  abortController?: AbortController
): Promise<any | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : {}),
  };
  const start = page * SUPERADMIN_PAGE_SIZE;

  const { data } = await axios.post(
    `${config.organisationSubscriptionsService.url}/superAdmins/_search`,
    {
      start,
      perPage: SUPERADMIN_PAGE_SIZE,
      orderBy: "Asc",
    },
    options
  );
  return data;
};
