import axios, { AxiosResponse } from "axios";
import { buildOrganisationSubscriptionsBaseUrl } from "src/api/base-url-helper";
import { getAuthHeader, getJsonContentHeader } from "../../headers";

const deleteOrganisation = async (
  accessToken: string,
  orgId: string,
  abortController?: AbortController
): Promise<AxiosResponse | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController && { signal: abortController.signal }),
  };

  try {
    return await axios.delete(
      `${buildOrganisationSubscriptionsBaseUrl()}/${orgId}`,
      options
    );
  } catch (error) {
    return error as Error;
  }
};

export default deleteOrganisation;
