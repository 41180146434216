import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import useAuth from "src/auth/use-auth";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Toggle } from "@songtradr/component-library";
import MagnifyingGlass from "src/app/assets/icons/component-icons/magifying_glass";
import { CloseIcon } from "src/app/assets/icons/component-icons";
import backgroundLinesAnimation from "src/app/assets/animations/bars_bg.json";
import theme from "src/theme";
import { ConditionallyVisible } from "src/sumo/src/components/visibility";
import { getIsMobile } from "@songtradr/spa-common";
import BaseModal, { ModalTypes } from "src/components/modals/base-modal";
import { IConfirmContentProps } from "src/components/interfaces";
import deleteOrganisationMember from "src/api/organisation-members/delete-organisation-member";
import getOrganisation from "src/api/organisation/get-organisation";
import getOrganisationMembers from "src/api/organisation-members/get-org-members";
import { errorToast, successToast } from "src/components/toast-notification";
import { eApplicationType } from "src/interfaces/auth";
import getRelatedOrganisations from "src/api/organisation/get-related-organisations";
import { Checkbox, Dropdown, Input, List, Menu, Tooltip } from "antd";
import {
  ProjectSortType,
  SortOrder as SortOrderProjects,
} from "src/pages/projects/components/project-listings/interfaces";
import {
  IRelatedOrganisation,
  IRelatedOrganisations,
} from "src/interfaces/related-organisations";
import {
  ChildOrgMembersTable,
  OrgMembersByOrg,
} from "src/pages/team/components/child-org-members";
import assignMember from "src/api/organisation-members/assign-member";
import unassignMember from "src/api/organisation-members/unassign-member";
import searchProjects from "src/api/projects/search-projects";
import { IOption } from "src/pages/team/table-data/org-member-data";
import updateProjectsWhitelist from "src/api/organisation-members/update-projects-whitelist";
import addApplications from "src/api/organisation-members/add-applications";
import removeApplications from "src/api/organisation-members/remove-applications";
import PlusIcon from "src/app/assets/icons/component-icons/plus-icon";
import { LeftOutlined, RightOutlined, UpOutlined } from "@ant-design/icons";
import Lottie from "react-lottie-player";
import axios from "axios";
import { TablePaginationConfig } from "antd/es/table";
import useOrg from "src/providers/organisation/hooks";
import { createPasscode, getPasscodes, patchPasscode } from "src/api/passcodes";
import { getPendingInvites, getRejectedInvites } from "src/api/app-invites";
import _, { debounce } from "lodash";
import defaultUserPermissions from "src/auth/utils/user-access-permission";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import WhitelistingTab from "./components/whitelisting-tab";
import styles from "./styles";
import {
  IMemberInvite,
  IOrganisationMemberProps,
  OrgMembersSortType,
  SortOrder,
} from "./interfaces";
import OrganisationMemberContainer from "./components/org-members/containers/org-members";
import AgencyOrganisationMemberContainer from "./components/org-agencies/containers/agency-org-members";
import OrganisationInvitesContainer from "./components/org-invites/containers/org-invites";
import DeleteTeamMemberModalMainContent from "./components/org-members/components/delete-team-member";
import InviteMembers from "./components/org-invites/components/invite-members";
import RolesAndPermissionsProfile from "../roles-permissions-profile";
import PasscodesTable from "./components/passcodes";
import FilterIcon from "./components/passcodes/icons/filterIcon";
import { IAppInvite, IPassCode } from "./components/passcodes/interfaces";
import SlideDrawer from "./components/slide-drawer";
import passcodeSteps from "./components/passcodes/components/passcode-steps";
import PendingInvitesTable from "./components/pending-invites";
import RejectedInvitesTable from "./components/rejected-invites";
import Pills from "../projects/client-project-view/components/pills";
import { IPillData } from "../projects/components/project-listings/commercial-table-actions";
import getApplicationsPermissions from "./utils";

export interface IChildOrgMemberList {
  org: IRelatedOrganisation;
  members: IOrganisationMemberProps[];
}

enum PageName {
  Agencies = "Agencies",
  Brands = "Brands",
  Members = "Members",
  Passcodes = "Passcodes",
  ProjectPermissions = "Project Permissions",
  Profile = "Profile",
  UserProfile = "user-profile",
}

enum PageNameTranslation {
  Agencies = "manageTeam##members##Agencies",
  Brands = "manageTeam##members##Brands",
  Members = "manageTeam##members##Members",
  Passcodes = "manageTeam##passcodes##Passcodes",
  ProjectPermissions = "manageTeam##members##ProjectPermissions",
}

type PAGE_NAME_TRANSLATION_KEYS = keyof typeof PageNameTranslation;

enum childPageName {
  Profile = "Profile",
  BrandsOverview = "brands-overview",
  BrandView = "brand-profile",
  AgencyProfile = "agency-profile",
  AgenciesOverview = "agencies-overview",
}

enum SubTabs {
  Members = "Members",
  Pending = "Pending",
  Rejected = "Rejected",
  Invites = "Invites",
}

const PAGE_SIZE = 10;

const Team = (): ReactElement | null => {
  const { t } = useTranslation();
  const {
    userPermissions,
    getAccessToken,
    organisationId,
    user,
    switchOrg,
    isSuperAdmin,
    isLoading: authIsLoading,
  } = useAuth();
  const { organisation: currentOrg } = useOrg();
  const isMobile = getIsMobile();

  const permissions = useMemo(() => {
    return userPermissions ?? defaultUserPermissions;
  }, [userPermissions]);

  const isPageSetUp = useMemo(() => {
    return !authIsLoading && !!user && !!organisationId && userPermissions;
  }, [authIsLoading, user, organisationId, userPermissions]);

  const [invitesLastUpdatedAt, setInvitesLastUpdatedAt] = useState<Date>(
    new Date()
  );
  const [orgInvitesData, setOrgInvitesData] = useState<IMemberInvite[] | []>(
    []
  );
  const [currentTab, setCurrentTab] = useState<string>(PageName.Members);
  const [searchText, setSearchText] = useState<string>("");
  const [
    searchTextOutsideDebounce,
    setSearchTextOutsideDebounce,
  ] = useState<string>("");

  const [inviteMembersModalVisible, toggleInviteModal] = useState<boolean>(
    false
  );
  const [assignMembersModalVisible, toggleAssignModal] = useState<boolean>(
    false
  );
  const [confirmBrandLoginModal, toggleBrandLoginModal] = useState<boolean>(
    false
  );
  const [deleteMemberModalVisible, toggleDeleteModal] = useState<boolean>(
    false
  );
  const [assignMemberToOrg, setAssignMemberToOrg] = useState<
    IRelatedOrganisation | undefined
  >(undefined);

  const [mobileDrawerVisible, toggleMobileDrawer] = useState<boolean>(false);
  const [selectedOrgMember, setSelectedOrgMember] = useState<
    IOrganisationMemberProps | undefined
  >();
  const [isDeleteMemberModalOpen, setIsDeleteMemberModalOpen] = useState(false);
  const [allApplications, setAllApplications] = useState<
    eApplicationType[] | []
  >([]);
  const [orgMembersData, setOrgMembersData] = useState<
    IOrganisationMemberProps[] | []
  >([]);
  const [
    childOrgMembersData,
    setChildOrgMembersData,
  ] = useState<OrgMembersByOrg>(
    new Map<IRelatedOrganisation, IOrganisationMemberProps[]>()
  );

  const [
    relatedOrgs,
    setRelatedOrgs,
  ] = useState<IRelatedOrganisations | null>();
  const [isLoadingRequest, setIsLoadingRequest] = useState<boolean>(false);
  const [tabTitle, setTabTitle] = useState<String>(PageName.Members);
  const [currentSortType, setCurrentSort] = useState<OrgMembersSortType>(
    OrgMembersSortType.CreatedDate
  );
  const [nameSortAscending, setNameSort] = useState<boolean>(true);
  const [dateSort, setDateSort] = useState<boolean>(false);
  const [
    filteredAndSortedOrgMembersData,
    setFilteredAndSortedOrgMembersData,
  ] = useState<IOrganisationMemberProps[] | []>([]);
  const [
    filteredAndSortedAgencyOrgMembersData,
    setFilteredAndSortedAgencyOrgMembersData,
  ] = useState<IOrganisationMemberProps[] | []>([]);
  const [openModalType] = useState<ModalTypes>(
    ModalTypes.deleteOrganisationMember
  );
  const [projects, setProjects] = useState<IOption[]>([]);

  const [assignableMembers, setAssignableMembers] = useState<
    IOrganisationMemberProps[]
  >();
  const [brandTitle, setBrandTitle] = useState<string>();
  const [selectedBrand, setSelectedBrand] = useState<IRelatedOrganisation>();
  const [selectedSubTab, setSelectedSubTab] = useState<SubTabs>(
    SubTabs.Members
  );
  const [currentChildTab, setChildTab] = useState<string>(
    childPageName.BrandsOverview
  );
  const [paginationData, setPaginationData] = useState<TablePaginationConfig>({
    current: 1,
    total: 0,
  });
  const [showActivePassCodes, setShowActivePassCodes] = useState<boolean>(
    false
  );
  const [passcodes, setPasscodes] = useState<IPassCode[]>([]);
  const [isPasscodeMenuOpen, setIsPasscodeMenuOpen] = useState<boolean>(false);
  const [newPasscodeData, setNewPasscodeData] = useState({
    applications: [],
    status: "Inactive",
    name: "",
    code: "",
  });
  const [isEditingPasscode, setIsEditingPasscode] = useState(false);
  const [pendingInvitesData, setPendingInvitesData] = useState();
  const [rejectedInvitesData, setRejectedInvitesData] = useState();
  const [passcodeNameOptions, setPasscodeNameOptions] = useState<string[]>([]);
  const [passcodesFilter, setPasscodesFilter] = useState<string[]>([]);
  const [filteredInvitesData, setFilteredInvitesData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);

  const getDataAbortController = useRef<AbortController>(new AbortController());

  useEffect(() => () => getDataAbortController.current?.abort(), []);

  const debouncedSearchTextChange = useMemo(
    () =>
      debounce((event: any) => {
        setSearchText(event.target.value);
      }, 500),
    []
  );

  const handleSearchTextChange = useCallback(
    (event: any) => {
      setSearchTextOutsideDebounce(event.target.value);
      debouncedSearchTextChange(event);
    },
    [debouncedSearchTextChange]
  );

  const toggleMenu = () => {
    setIsPasscodeMenuOpen(!isPasscodeMenuOpen);
    setNewPasscodeData({
      applications: [],
      status: "Inactive",
      name: "",
      code: "",
    });
  };

  const filterWithSearchText = useCallback(
    (orgMembers: IOrganisationMemberProps[]): IOrganisationMemberProps[] => {
      const searchTextTokens = searchText
        .split(" ")
        .filter((token) => token.trim().length > 0)
        .map((token) => token.toLowerCase());

      return orgMembers.filter((orgMember) =>
        searchTextTokens.every(
          (token) =>
            (orgMember.email?.toLowerCase().includes(token) ?? false) ||
            (orgMember.firstName?.toLowerCase().includes(token) ?? false) ||
            (orgMember.lastName?.toLowerCase().includes(token) ?? false)
        )
      );
    },
    [searchText]
  );

  const fetchPasscodes = useCallback(async () => {
    try {
      const accessToken = getAccessToken();

      const results = await getPasscodes(accessToken, organisationId);
      setPasscodes(results.items);
    } catch {
      errorToast({
        message: "Failed to fetch passcodes.",
      });
    }
  }, [getAccessToken, organisationId]);

  const applySortAndSearchFilter = useCallback(
    (members?: IOrganisationMemberProps[]) => {
      const orgMembers = members || orgMembersData;
      switch (currentSortType) {
        case OrgMembersSortType.Name: {
          const filteredData = searchText
            ? filterWithSearchText(orgMembers)
            : orgMembers;

          const sortedData = filteredData.sort((a, b) => {
            const fullNameA = `${a.firstName} ${a.lastName}`;
            const fullNameB = `${b.firstName} ${b.lastName}`;

            if (nameSortAscending) {
              return fullNameA.localeCompare(fullNameB);
            }
            return fullNameB.localeCompare(fullNameA);
          });

          setFilteredAndSortedOrgMembersData(sortedData);
          break;
        }
        default: {
          const filteredData = searchText
            ? filterWithSearchText(orgMembers)
            : orgMembers;
          setFilteredAndSortedOrgMembersData(filteredData);
          break;
        }
      }
    },
    [
      currentSortType,
      filterWithSearchText,
      nameSortAscending,
      orgMembersData,
      searchText,
    ]
  );

  const applySortAndSearchAgencyFilter = useCallback(
    (members?: IOrganisationMemberProps[]) => {
      const orgMembers = members || [];
      switch (currentSortType) {
        case OrgMembersSortType.Name: {
          const filteredData = searchText
            ? filterWithSearchText(orgMembers)
            : orgMembers;

          const sortedData = filteredData.sort((a, b) => {
            const fullNameA = `${a.firstName} ${a.lastName}`;
            const fullNameB = `${b.firstName} ${b.lastName}`;

            if (nameSortAscending) {
              return fullNameA.localeCompare(fullNameB);
            }
            return fullNameB.localeCompare(fullNameA);
          });

          setFilteredAndSortedAgencyOrgMembersData(sortedData);
          break;
        }
        default: {
          const filteredData = searchText
            ? filterWithSearchText(orgMembers)
            : orgMembers;
          setFilteredAndSortedAgencyOrgMembersData(filteredData);
          break;
        }
      }
    },
    [currentSortType, filterWithSearchText, nameSortAscending, searchText]
  );

  const handleGetData = useCallback(
    async (page?: string): Promise<void> => {
      const accessToken = getAccessToken();
      const currentPage = page ? parseInt(page, 10) : 1;

      if (!organisationId) {
        return;
      }

      // Abort any in-flight requests for getData
      getDataAbortController.current?.abort();
      getDataAbortController.current = new AbortController();

      try {
        setIsLoadingRequest(true);

        const doesOrgHaveAccessToProjects = [
          eApplicationType.ProjectsInternal,
          eApplicationType.ProjectsClient,
        ].some((permission) => currentOrg?.applications?.includes(permission));

        const isSortAscending =
          (currentSortType === OrgMembersSortType.Name && nameSortAscending) ||
          (currentSortType === OrgMembersSortType.CreatedDate && dateSort);

        const organisationPromise = getOrganisation(
          organisationId,
          accessToken,
          getDataAbortController.current
        );

        const responsePromise = getOrganisationMembers(
          accessToken,
          organisationId,
          false,
          user?.id,
          getDataAbortController.current,
          currentPage ? PAGE_SIZE * (currentPage - 1) : undefined,
          undefined,
          searchText,
          isSortAscending ? SortOrderProjects.ASC : SortOrderProjects.DESC,
          currentSortType
        );

        const childMembersPromise = getOrganisationMembers(
          accessToken,
          organisationId,
          true,
          user?.id,
          getDataAbortController.current,
          currentPage ? PAGE_SIZE * (currentPage - 1) : undefined,
          undefined,
          searchText,
          nameSortAscending ? SortOrderProjects.ASC : SortOrderProjects.DESC
        );

        const relatedOrgsPromise = getRelatedOrganisations(
          organisationId,
          accessToken,
          getDataAbortController.current
        );

        const projectsPromise =
          doesOrgHaveAccessToProjects && permissions.canAccessProjects
            ? searchProjects(
                accessToken,
                organisationId,
                { by: ProjectSortType.name, order: SortOrderProjects.ASC },
                0,
                { signal: getDataAbortController.current?.signal }
              )
            : null;

        const pendingInvitePromise = getPendingInvites(
          accessToken,
          organisationId,
          searchText,
          getDataAbortController.current
        );

        const rejectedInvitePromise = getRejectedInvites(
          accessToken,
          organisationId,
          searchText,
          getDataAbortController.current
        );
        const passcodesPromise = getPasscodes(accessToken, organisationId);
        const [
          organisation,
          response,
          childMembers,
          relatedOrgsResponse,
          projectsResponse,
          pendingInviteResponse,
          rejectedInviteResponse,
          passcodesResponse,
        ] = await Promise.all([
          organisationPromise,
          responsePromise,
          childMembersPromise,
          relatedOrgsPromise,
          projectsPromise,
          pendingInvitePromise,
          rejectedInvitePromise,
          passcodesPromise,
        ]);

        const childOrgsWithMembers = new Map<
          IRelatedOrganisation,
          IOrganisationMemberProps[]
        >();
        relatedOrgsResponse?.childOrganisations
          .concat(relatedOrgsResponse.hostLinkedOrganisations)
          .concat(relatedOrgsResponse.servicedOrganisations)
          .forEach((org) => {
            childOrgsWithMembers.set(
              org,
              childMembers.orgMembers.filter(
                (member) => member.organisationId === org.orgId
              )
            );
          });

        const agencyOrgMembers = response.orgMembers?.filter(
          (member) =>
            relatedOrgs?.guestLinkedOrganisations?.findIndex(
              (agency) => agency.orgId === member.controllingOrgId
            ) !== -1
        );

        const projectsOptions: IOption[] =
          doesOrgHaveAccessToProjects && permissions.canAccessProjects
            ? projectsResponse?.results?.map((project) => {
                return {
                  value: project.id,
                  label: project.name,
                };
              }) ?? []
            : [];
        setProjects(projectsOptions);
        setAllApplications(organisation?.applications ?? []);
        setOrgMembersData(response.orgMembers);
        setChildOrgMembersData(childOrgsWithMembers);
        setPasscodes(passcodesResponse.items);
        setRelatedOrgs(relatedOrgsResponse);
        applySortAndSearchFilter(response.orgMembers);
        applySortAndSearchAgencyFilter(agencyOrgMembers);
        setPendingInvitesData(pendingInviteResponse.items);
        setPasscodeNameOptions(
          _.uniq(
            pendingInviteResponse.items.map(
              (invite: IAppInvite) => invite.passcode.name
            )
          )
        );
        setRejectedInvitesData(rejectedInviteResponse.items);
        setPaginationData((previous) => {
          return { current: previous.current, total: response.total };
        });

        if (
          response.total <
          (paginationData?.current ? paginationData?.current - 1 : 0) *
            PAGE_SIZE
        ) {
          setPaginationData((previous) => {
            return { current: 1, total: previous.total };
          });
        }
      } catch (e) {
        if (!axios.isCancel(e)) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      } finally {
        setIsLoadingRequest(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentOrg?.applications,
      currentSortType,
      dateSort,
      getAccessToken,
      nameSortAscending,
      organisationId,
      permissions.canAccessProjects,
      searchText,
      user?.id,
    ]
  );

  const handleConfirmDeleteMemberClick = async () => {
    const accessToken: string = getAccessToken();
    if (selectedOrgMember) {
      const response = await deleteOrganisationMember(
        accessToken,
        selectedOrgMember?.organisationId,
        selectedOrgMember.auth0UserId
      );
      if (response.status === 200) {
        setIsDeleteMemberModalOpen(false);
        successToast({
          message: t("manageTeam##members##Team member deleted"),
        });
        await handleGetData();
      } else {
        errorToast({
          message: t(
            "manageTeam##members##An error occurred deleting this team member"
          ),
        });
      }
    }
  };

  const confirmDeleteContentProps: IConfirmContentProps = {
    confirmAction: handleConfirmDeleteMemberClick,
    onClose: () => setIsDeleteMemberModalOpen(false),
    primaryButtonLabel: "manageTeam##members##Delete member",
    secondaryButtonLabel: "manageTeam##Cancel",
    mainContent: <DeleteTeamMemberModalMainContent />,
  };

  const confirmMobileContentProps = { ...confirmDeleteContentProps };
  confirmMobileContentProps.onClose = () =>
    toggleMobileDrawer(!mobileDrawerVisible);
  confirmMobileContentProps.primaryButtonLabel = "Delete";

  const handleUnassignMember = async (
    orgMember: IOrganisationMemberProps,
    destinationOrg: IRelatedOrganisation
  ) => {
    const accessToken: string = await getAccessToken();
    if (!organisationId || isLoadingRequest) return;
    setIsLoadingRequest(true);
    try {
      await unassignMember(
        accessToken,
        organisationId,
        destinationOrg.orgId,
        orgMember.auth0UserId
      );
      await handleGetData();
      successToast({ message: "Removed member from brand." });
    } catch (e) {
      errorToast({
        message: "Failed to remove member from brand.",
      });
    }
    setIsLoadingRequest(false);
  };

  const [membersList, setMembersList] = useState<
    IOrganisationMemberProps[] | undefined
  >(undefined);

  const getAssignableMembers = (
    org: IRelatedOrganisation
  ): IOrganisationMemberProps[] | undefined => {
    if (org && membersList !== undefined) {
      const unassignMembers = orgMembersData.filter(
        (member) =>
          membersList.findIndex((a) => a.auth0UserId === member.auth0UserId) ===
          -1
      );

      if (relatedOrgs?.guestLinkedOrganisations) {
        const nonAgencyList = unassignMembers.filter(
          (member) =>
            relatedOrgs?.guestLinkedOrganisations.findIndex(
              (a) => a.orgId === member.controllingOrgId
            ) === -1
        );
        return nonAgencyList;
      }
    }
    return undefined;
  };

  const assignableMembersForOrganisation = (
    org: IRelatedOrganisation
  ): IOrganisationMemberProps[] => {
    const membersInChildOrg = childOrgMembersData.get(org);
    if (membersInChildOrg) {
      const userIdsAlreadyAssigned = membersInChildOrg.map(
        (member: IOrganisationMemberProps) => member.auth0UserId
      );
      const listOfAssignableMembers = orgMembersData.filter(
        (member) =>
          userIdsAlreadyAssigned.findIndex(
            (id: string) => id === member.auth0UserId
          ) === -1
      );
      setMembersList(listOfAssignableMembers);
      return listOfAssignableMembers;
    }

    return [];
  };

  const history = useHistory();
  const onGoBack = () => {
    if (currentTab === PageName.Members) {
      history.push("/home");
    }
    if (
      currentTab === PageName.Brands &&
      currentChildTab === childPageName.BrandView
    ) {
      setCurrentTab(PageName.Brands);
      setChildTab(childPageName.BrandsOverview);
    }
    if (
      currentTab === PageName.Agencies &&
      currentChildTab === childPageName.AgencyProfile
    ) {
      setCurrentTab(PageName.Agencies);
      setChildTab(childPageName.AgenciesOverview);
    } else {
      setCurrentTab(PageName.Members);
      setTabTitle(PageName.Members);
    }
  };

  const handleAssignMember = (
    org: IRelatedOrganisation,
    currentMembers: IOrganisationMemberProps[]
  ) => {
    setMembersList(currentMembers);
    toggleAssignModal(true);
    setAssignableMembers(assignableMembersForOrganisation(org));
    setAssignMemberToOrg(org);
  };

  const assignMemberToChildOrganisation = useCallback(
    async (
      orgMember: IOrganisationMemberProps,
      destinationOrg: IRelatedOrganisation
    ) => {
      toggleAssignModal(false);
      const accessToken: string = await getAccessToken();
      if (!organisationId || isLoadingRequest) return;
      try {
        setIsLoadingRequest(true);

        await assignMember(
          accessToken,
          organisationId,
          destinationOrg.orgId,
          orgMember.auth0UserId
        );
        await handleGetData();
        successToast({ message: "Assigned member to brand." });
      } catch (e) {
        errorToast({
          message: "Failed to assign member.",
        });
      } finally {
        setIsLoadingRequest(false);
      }
    },
    [organisationId, handleGetData, getAccessToken, isLoadingRequest]
  );

  const handleToggleUseProjectsWhitelist = useCallback(
    async (member: IOrganisationMemberProps, useProjectsWhitelist: boolean) => {
      const accessToken: string = getAccessToken();
      if (!organisationId || isLoadingRequest) return;
      try {
        setIsLoadingRequest(true);

        await updateProjectsWhitelist(
          accessToken,
          organisationId,
          member.auth0UserId,
          useProjectsWhitelist,
          []
        );
        await handleGetData();
        successToast({ message: "User whitelist status updated." });
      } catch (e) {
        errorToast({
          message: "Failed to enable project whitelist.",
        });
      } finally {
        setIsLoadingRequest(false);
      }
    },
    [organisationId, getAccessToken, isLoadingRequest, handleGetData]
  );
  const handleUpdateProjectsWhitelist = useCallback(
    async (member: IOrganisationMemberProps, whitelistedProjects: string[]) => {
      const accessToken: string = getAccessToken();
      if (!organisationId || isLoadingRequest) return;
      try {
        setIsLoadingRequest(true);
        await updateProjectsWhitelist(
          accessToken,
          organisationId,
          member.auth0UserId,
          member?.useProjectsWhitelist ?? false,
          whitelistedProjects
        );
        await handleGetData();
        successToast({ message: "User whitelist updated." });
      } catch (e) {
        errorToast({
          message: "Failed to enable project whitelist.",
        });
      } finally {
        setIsLoadingRequest(false);
      }
    },
    [getAccessToken, handleGetData, isLoadingRequest, organisationId]
  );

  const handleAddApplication = async (
    member: IOrganisationMemberProps,
    application: eApplicationType
  ) => {
    const accessToken: string = await getAccessToken();
    const {
      additionalApplications,
    } = getApplicationsPermissions(currentOrg?.applications || [], [
      application,
    ]);

    if (!organisationId || isLoadingRequest) return;
    try {
      setIsLoadingRequest(true);
      await addApplications(
        accessToken,
        organisationId,
        member.auth0UserId,
        additionalApplications
      );
      const params = new URLSearchParams(history.location.search);
      const page = params.get("page") || undefined;
      await handleGetData(page);
      successToast({ message: "Added role to user." });
    } catch (e) {
      errorToast({
        message: "Failed to add role to user.",
      });
    } finally {
      setIsLoadingRequest(false);
    }
  };

  const handleRemoveApplication = async (
    member: IOrganisationMemberProps,
    application: eApplicationType
  ) => {
    const accessToken: string = await getAccessToken();
    if (!organisationId || isLoadingRequest) return;
    try {
      setIsLoadingRequest(true);
      await removeApplications(
        accessToken,
        organisationId,
        member.auth0UserId,
        [application]
      );
      const params = new URLSearchParams(history.location.search);
      const page = params.get("page") || undefined;
      await handleGetData(page);
      successToast({ message: "Role removed from user." });
    } catch (e) {
      errorToast({
        message: "Failed to remove role from user.",
      });
    } finally {
      setIsLoadingRequest(false);
    }
  };

  const handleAddAndRemoveApplication = async (
    member: IOrganisationMemberProps,
    addApplication: eApplicationType,
    removeApplication: eApplicationType
  ) => {
    const accessToken: string = await getAccessToken();
    if (!organisationId || isLoadingRequest) return;

    try {
      setIsLoadingRequest(true);
      await addApplications(accessToken, organisationId, member.auth0UserId, [
        addApplication,
      ]);
      await removeApplications(
        accessToken,
        organisationId,
        member.auth0UserId,
        [removeApplication]
      );
      const params = new URLSearchParams(history.location.search);
      const page = params.get("page") || undefined;
      await handleGetData(page);
      successToast({ message: "Added role to user." });
    } catch (e) {
      errorToast({
        message: "Failed to add role to user.",
      });
    } finally {
      setIsLoadingRequest(false);
    }
  };
  const handleApplicationDebounce = debounce(
    async (
      shouldAdd: boolean,
      ...args: [member: IOrganisationMemberProps, application: eApplicationType]
    ) => {
      if (shouldAdd) {
        await handleAddApplication(...args);
        return;
      }

      await handleRemoveApplication(...args);
    },
    500
  );

  const setSortTypeAndOrder = (
    sortType: OrgMembersSortType,
    sortOrder: SortOrder
  ) => {
    setCurrentSort(sortType);

    const sortIsAscending = sortOrder === SortOrder.Ascending;

    switch (sortType) {
      case OrgMembersSortType.Name: {
        setNameSort(sortIsAscending);
        break;
      }
      case OrgMembersSortType.CreatedDate: {
        setDateSort(sortIsAscending);
        break;
      }
      default: {
        break;
      }
    }
  };

  useEffect(() => {
    handleGetData();
  }, [handleGetData]);

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const page = params.get("page");

    if (page) {
      handleGetData(page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.search]);

  const handleBrandLogin = (brandOrgId: string) => {
    switchOrg(brandOrgId);
  };

  const handleTabClick = useCallback(
    (tab: string) => {
      setCurrentTab(tab);
      setTabTitle(tab);
      setSelectedBrand(undefined);

      if (currentChildTab === childPageName.BrandView) {
        setChildTab("");
      }
    },
    [currentChildTab]
  );

  const showAgenciesTab =
    relatedOrgs?.guestLinkedOrganisations !== undefined &&
    relatedOrgs?.guestLinkedOrganisations.length > 0;

  const showBrandsTab = childOrgMembersData.size > 0;

  const showLoginToBrandBtn =
    selectedBrand?.orgId !== undefined &&
    currentChildTab === childPageName.BrandView &&
    isSuperAdmin;

  const handleChangeChildTab = () => {
    if (currentChildTab === childPageName.BrandView)
      setChildTab(childPageName.BrandsOverview);
  };

  const handleToggleBrandLogin = () => {
    toggleBrandLoginModal((prev) => !prev);
  };

  const handleToggleInviteModal = () => {
    toggleInviteModal((prev) => !prev);
  };

  const [selectedPermissions, setSelectedPermissions] = useState({
    Search: false,
    SearchReadOnly: false,
    Playlists: false,
    Insights: false,
    Projects: false,
    Resources: false,
    Measurement: false,
  });

  const labelMap = {
    LibraryFull: "Search",
    LibraryReadOnly: "SearchReadOnly",
    ProjectsClient: "Projects",
    Validate: "Measurement",
    Insights: "Insights",
    Resources: "Resources",
  };

  const handleEditPasscode = (passcodeId: string) => {
    setIsEditingPasscode(true);
    const selectedPasscodeData = passcodes.find(
      (passcode) => passcode.id === passcodeId
    );
    setNewPasscodeData(selectedPasscodeData);
    selectedPasscodeData?.applications.forEach((application) => {
      if (application === "Portal") return;
      const frontEndLabel = labelMap[application];
      setSelectedPermissions((prev) => ({ ...prev, [frontEndLabel]: true }));
    });

    // setSelectedPermissions
    setIsPasscodeMenuOpen(true);
  };

  const handleCreatePasscode = () => {
    setIsEditingPasscode(false);
    setIsPasscodeMenuOpen(true);
  };

  const handlePermissionSelect = (value: boolean, key: string) => {
    setSelectedPermissions((prev) => ({ ...prev, [key]: value }));
  };

  const updatePasscode = async () => {
    const applicationArray = Object.keys(selectedPermissions)
      .filter((permission) => {
        return selectedPermissions[permission] === true;
      })
      .map((permission) => {
        switch (permission) {
          case "Search":
            return eApplicationType.LibraryFull;
          case "SearchReadOnly":
            return eApplicationType.LibraryReadOnly;
          case "Playlists":
            return eApplicationType.LibraryFull;
          case "Projects":
            return eApplicationType.ProjectsClient;
          case "Measurement":
            return eApplicationType.Validate;
          default:
            return permission;
        }
      });
    const accessToken = await getAccessToken();
    const patchBody = {
      ...newPasscodeData,
      applications: [...applicationArray, "Portal"],
    } as IPassCode;

    try {
      await patchPasscode(accessToken, organisationId, patchBody.id, patchBody);
      successToast({
        message: "Passcode successfully updated",
      });
      fetchPasscodes();
    } catch (e) {
      errorToast({
        message: "Failed to update passcode",
      });
    }
  };

  const createNewPasscode = async () => {
    const applicationArray = Object.keys(selectedPermissions)
      .filter((permission) => {
        return selectedPermissions[permission] === true;
      })
      .map((permission) => {
        switch (permission) {
          case "Search":
            return eApplicationType.LibraryFull;
          case "SearchReadOnly":
            return eApplicationType.LibraryReadOnly;
          case "Playlists":
            return eApplicationType.LibraryFull;
          case "Projects":
            return eApplicationType.ProjectsClient;
          case "Measurement":
            return eApplicationType.Validate;
          default:
            return permission;
        }
      });

    const postBody = {
      ...newPasscodeData,
      applications: [...applicationArray, "Portal"],
    };
    const accessToken = getAccessToken();

    try {
      const response = await createPasscode(
        accessToken,
        organisationId,
        postBody
      );

      if (response) {
        successToast({
          message: "Passcode created",
        });
        fetchPasscodes();
      }
    } catch (e) {
      errorToast({
        message: "Failed to create passcode",
      });
    }
  };

  const handleGeneratedPasscode = (code: string) => {
    setNewPasscodeData({
      ...newPasscodeData,
      code,
    });
  };

  const renderSubtitle = (tab: string) => {
    switch (tab) {
      case PageName.Members:
        return t("manageTeam##MembersSubtitle");
      case PageName.Brands:
        return t("manageTeam##BrandsSubtitle");
      case PageName.Agencies:
        return t("manageTeam##members##AgenciesDescription");
      case PageName.ProjectPermissions:
        return t("manageTeam##members##ProjectPermissionsDescription");
      case PageName.Passcodes:
        return t("manageTeam##PassCodesSubtitle");
      default:
        return "";
    }
  };

  const handlePasscodeFilter = (
    passcodeName: string,
    event: CheckboxChangeEvent
  ) => {
    if (event.target.checked) {
      setPasscodesFilter((previous) => [...previous, passcodeName]);
    } else {
      setPasscodesFilter((previous) =>
        previous.filter((name) => name !== passcodeName)
      );
    }
  };

  useEffect(() => {
    if (passcodesFilter.length > 0) {
      const filteredInvites = pendingInvitesData?.filter(
        (invite: IAppInvite) => {
          return passcodesFilter.includes(invite.passcode.name);
        }
      );
      setFilteredInvitesData(filteredInvites);
    } else {
      setFilteredInvitesData(undefined);
    }
  }, [passcodesFilter, pendingInvitesData]);

  const getPillsData = useCallback(() => {
    const pillsData: IPillData[] = [
      {
        label: PageName.Members,
      },
      { label: PageName.Brands, isHidden: !showBrandsTab },
      { label: PageName.Agencies, isHidden: !showAgenciesTab },
      { label: PageName.ProjectPermissions },
      {
        label: PageName.Passcodes,
        isHidden: !(currentOrg?.enablePasscodes ?? true),
      },
    ];

    return pillsData.map((pill) => {
      return {
        key: pill.label,
        name:
          PageNameTranslation[
            pill.label.replace(/ /g, "") as PAGE_NAME_TRANSLATION_KEYS
          ],
        variable: pill?.variable,
        isSelected: pill.label === currentTab,
        isHidden: pill.isHidden,
        onClick: () => {
          if (pill.label === PageName.Brands) {
            setChildTab(childPageName.BrandsOverview);
          }
          if (pill.label === PageName.Agencies) {
            setChildTab(childPageName.AgenciesOverview);
          }
          handleTabClick(pill.label);
        },
      };
    });
  }, [
    currentOrg?.enablePasscodes,
    currentTab,
    handleTabClick,
    showAgenciesTab,
    showBrandsTab,
  ]);

  if (!userPermissions?.canAccessTeams && isPageSetUp) {
    return <Redirect to="/home" />;
  }

  return (
    <div data-testid="manage-team" css={styles.teamPage}>
      <div css={styles.header}>
        <div css={styles.headerContent}>
          <div css={styles.mainHeader}>
            <div css={styles.backBtnAndTitleCol}>
              <div css={styles.backBtnContainer}>
                <button
                  css={styles.backBtn}
                  type="button"
                  aria-label="Back"
                  name="Back"
                  onClick={() => onGoBack()}
                >
                  <LeftOutlined />
                </button>
                <button
                  type="button"
                  onClick={() => onGoBack()}
                  css={styles.backText}
                >
                  BACK
                </button>
              </div>
              <div css={styles.titleAndTabRow}>
                {t("manageTeam##RolesAndPermissions")}
              </div>
              <div css={styles.subtitle}>{renderSubtitle(currentTab)}</div>
              <div css={styles.titleAndTabRow}>
                <div css={styles.pages}>
                  <Pills
                    pills={getPillsData()}
                    customStyles={styles.customActionPills}
                  />
                </div>
              </div>
            </div>
            <div css={styles.animationBg}>
              <Lottie
                animationData={backgroundLinesAnimation}
                style={{ width: 1000, height: 175 }}
                loop
                play
              />
            </div>
          </div>
        </div>
      </div>
      <div css={styles.pageContainer}>
        <div css={styles.subHeader}>
          <ConditionallyVisible condition={currentTab === PageName.Members}>
            <div className="row-container">
              <div className="subtabs">
                <button
                  onClick={() => setSelectedSubTab(SubTabs.Members)}
                  type="button"
                  className={
                    selectedSubTab === SubTabs.Members ? "selected" : ""
                  }
                >
                  Members
                </button>
                <button
                  data-testid="pending-invites-tab"
                  onClick={() => setSelectedSubTab(SubTabs.Pending)}
                  type="button"
                  className={
                    selectedSubTab === SubTabs.Pending ? "selected" : ""
                  }
                >
                  Pending{" "}
                  {pendingInvitesData &&
                    pendingInvitesData.length > 0 &&
                    `(${pendingInvitesData.length})`}
                </button>
                <button
                  data-testid="rejected-invites-tab"
                  onClick={() => setSelectedSubTab(SubTabs.Rejected)}
                  type="button"
                  className={
                    selectedSubTab === SubTabs.Rejected ? "selected" : ""
                  }
                >
                  Rejected{" "}
                  {rejectedInvitesData &&
                    rejectedInvitesData.length > 0 &&
                    `(${rejectedInvitesData.length})`}
                </button>
                <button
                  data-testid="invites-tab"
                  onClick={() => setSelectedSubTab(SubTabs.Invites)}
                  type="button"
                  className={
                    selectedSubTab === SubTabs.Invites ? "selected" : ""
                  }
                >
                  Invites
                </button>
              </div>
              <div>
                <ConditionallyVisible
                  condition={currentTab === PageName.Members}
                >
                  <Input
                    css={styles.searchInput}
                    prefix={<MagnifyingGlass />}
                    placeholder="Search"
                    onChange={handleSearchTextChange}
                    value={searchTextOutsideDebounce}
                  />
                </ConditionallyVisible>
                <ConditionallyVisible
                  condition={
                    currentTab === PageName.Members &&
                    selectedSubTab === SubTabs.Pending
                  }
                >
                  <button
                    type="button"
                    onClick={() => setShowFilter((previous) => !previous)}
                    className="filter-icon"
                    data-testid="filter-btn"
                  >
                    <FilterIcon />
                  </button>
                </ConditionallyVisible>
                <Button
                  css={styles.inviteButton}
                  variant="primary"
                  data-testid="invite-members"
                  onClick={handleToggleInviteModal}
                >
                  {t("manageTeam##members##Invite members")}
                </Button>
              </div>
            </div>
          </ConditionallyVisible>
        </div>
        <ConditionallyVisible
          condition={
            currentTab === PageName.Members &&
            selectedSubTab === SubTabs.Pending &&
            showFilter
          }
        >
          <div>
            <Dropdown
              overlay={
                <Menu
                  css={styles.dropdownMenu}
                  data-testid="action-items-dropdown"
                >
                  {passcodeNameOptions.map((passcodeName) => (
                    <Menu.Item key={passcodeName}>
                      <button
                        type="button"
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <Checkbox
                          data-testid={passcodeName}
                          checked={passcodesFilter.includes(passcodeName)}
                          onChange={(event: CheckboxChangeEvent) =>
                            handlePasscodeFilter(passcodeName, event)
                          }
                        />{" "}
                        {passcodeName}
                      </button>
                    </Menu.Item>
                  ))}
                  <div className="clear-button">
                    <button
                      type="button"
                      onClick={() => setPasscodesFilter([])}
                    >
                      Clear all
                    </button>
                  </div>
                </Menu>
              }
              trigger={["click"]}
              getPopupContainer={(triggerNode) => triggerNode}
              placement="bottomRight"
            >
              <p css={styles.passcodesFilter} data-testid="toggle-dropdown">
                Passcode name:{" "}
                {passcodesFilter.length > 0
                  ? passcodesFilter.join(", ")
                  : "All"}
                <UpOutlined />
              </p>
            </Dropdown>
          </div>
        </ConditionallyVisible>
        <ConditionallyVisible
          condition={
            currentTab === PageName.Brands || currentTab === PageName.Passcodes
          }
        >
          <div css={styles.searchWrapper}>
            <div css={styles.titleBtnWrapper}>
              <ConditionallyVisible condition={!isMobile}>
                <button
                  onClick={handleChangeChildTab}
                  type="button"
                  id="tab-breadcrumb"
                >
                  <div css={styles.tableTitleWithChild(currentChildTab)}>
                    {tabTitle}
                  </div>
                </button>

                <ConditionallyVisible
                  condition={currentChildTab === childPageName.BrandView}
                >
                  <div css={styles.childBrandTitleContainer}>
                    <RightOutlined />
                    <button
                      css={styles.childBrandTitleBtn}
                      onClick={handleChangeChildTab}
                      type="button"
                      id="tab-breadcrumb"
                    >
                      {brandTitle}
                    </button>
                    <ConditionallyVisible condition={showLoginToBrandBtn}>
                      <Button
                        variant="primary"
                        data-testid="invite-members"
                        onClick={handleToggleBrandLogin}
                      >
                        {t("manageTeam##members##Log in to brand")}
                      </Button>
                    </ConditionallyVisible>
                  </div>
                </ConditionallyVisible>
              </ConditionallyVisible>
            </div>
            <button
              css={!searchText && styles.hide}
              type="button"
              className="closeButton"
              onClick={() => {
                setSearchText("");
              }}
            >
              <CloseIcon
                fill={theme.colors.black}
                height={15}
                width={15}
                title="Close"
                role="button"
              />
            </button>
            <ConditionallyVisible condition={currentTab === PageName.Passcodes}>
              <div className="passcode-actions">
                <Toggle
                  data-testid="show-active-toggle"
                  onCheckedChange={(value) => setShowActivePassCodes(value)}
                >
                  Show active
                </Toggle>
                <Button
                  variant="primary"
                  onClick={handleCreatePasscode}
                  data-testid="create-pass-code"
                >
                  Create passcode
                </Button>
              </div>
            </ConditionallyVisible>
          </div>
        </ConditionallyVisible>
        {currentTab === PageName.Members &&
          selectedSubTab === SubTabs.Members && (
            <OrganisationMemberContainer
              setCurrentTab={setCurrentTab}
              searchText={searchText}
              confirmDeleteContentProps={confirmDeleteContentProps}
              setSelectedOrgMember={setSelectedOrgMember}
              isDeleteMemberModalOpen={isDeleteMemberModalOpen}
              setIsDeleteMemberModalOpen={setIsDeleteMemberModalOpen}
              isLoadingRequest={isLoadingRequest}
              allApplications={allApplications}
              currentSortType={currentSortType}
              setCurrentSort={setCurrentSort}
              nameSortAscending={nameSortAscending}
              dateSort={dateSort}
              setNameSort={setNameSort}
              setDateSort={setDateSort}
              filteredAndSortedOrgMembersData={filteredAndSortedOrgMembersData}
              openModalType={openModalType}
              applySortAndSearchFilter={applySortAndSearchFilter}
              projects={projects}
              handleToggleUseProjectsWhitelist={
                handleToggleUseProjectsWhitelist
              }
              handleUpdateProjectsWhitelist={handleUpdateProjectsWhitelist}
              handleAddApplication={async (...args) =>
                void handleApplicationDebounce(true, ...args)
              }
              handleRemoveApplication={async (...args) =>
                void handleApplicationDebounce(false, ...args)
              }
              handleAddAndRemoveApplication={handleAddAndRemoveApplication}
              paginationData={paginationData}
              setPaginationData={setPaginationData}
            />
          )}
        {currentTab === PageName.Members &&
          selectedSubTab === SubTabs.Pending && (
            <PendingInvitesTable
              tableData={filteredInvitesData || pendingInvitesData}
              onGetData={handleGetData}
            />
          )}
        {currentTab === PageName.Members &&
          selectedSubTab === SubTabs.Rejected && (
            <RejectedInvitesTable
              tableData={rejectedInvitesData}
              onGetData={handleGetData}
            />
          )}
        {currentTab === PageName.Brands && (
          <ChildOrgMembersTable
            orgMembersByOrg={childOrgMembersData}
            setChildOrgMembersData={setChildOrgMembersData}
            handleAssignMember={handleAssignMember}
            handleUnassignMember={handleUnassignMember}
            currentChildTab={currentChildTab}
            setChildTab={setChildTab}
            setBrandTitle={setBrandTitle}
            setSelectedBrand={setSelectedBrand}
          />
        )}
        {currentTab === PageName.Passcodes && (
          <PasscodesTable
            showActive={showActivePassCodes}
            allPasscodes={passcodes}
            handleEditPasscode={handleEditPasscode}
          />
        )}
        {currentTab === PageName.Agencies && (
          <AgencyOrganisationMemberContainer
            currentChildTab={currentChildTab}
            setChildTab={setChildTab}
            searchText={searchText}
            confirmDeleteContentProps={confirmDeleteContentProps}
            setSelectedOrgMember={setSelectedOrgMember}
            isDeleteMemberModalOpen={isDeleteMemberModalOpen}
            setIsDeleteMemberModalOpen={setIsDeleteMemberModalOpen}
            setCurrentTab={setCurrentTab}
            isLoadingRequest={isLoadingRequest}
            allApplications={allApplications}
            currentSortType={currentSortType}
            setCurrentSort={setCurrentSort}
            nameSortAscending={nameSortAscending}
            setNameSort={setNameSort}
            filteredAndSortedOrgMembersData={
              filteredAndSortedAgencyOrgMembersData
            }
            openModalType={openModalType}
            applySortAndSearchFilter={applySortAndSearchFilter}
            setSortTypeAndOrder={setSortTypeAndOrder}
            agencyOrganisations={relatedOrgs?.guestLinkedOrganisations ?? []}
            handleAddApplication={async (...args) =>
              void handleApplicationDebounce(true, ...args)
            }
            handleRemoveApplication={async (...args) =>
              void handleApplicationDebounce(false, ...args)
            }
            handleAddAndRemoveApplication={handleAddAndRemoveApplication}
          />
        )}
        <ConditionallyVisible
          condition={currentTab === PageName.ProjectPermissions}
        >
          <WhitelistingTab
            orgMembers={filteredAndSortedOrgMembersData}
            projects={projects}
            allApplications={allApplications}
            handleToggleUseProjectsWhitelist={handleToggleUseProjectsWhitelist}
            handleUpdateProjectsWhitelist={handleUpdateProjectsWhitelist}
            handleAddApplication={async (...args) =>
              void handleApplicationDebounce(true, ...args)
            }
            handleRemoveApplication={async (...args) =>
              void handleApplicationDebounce(false, ...args)
            }
          />
        </ConditionallyVisible>
        {currentTab === PageName.Members && selectedSubTab === SubTabs.Invites && (
          <div css={styles.invitesTable}>
            <OrganisationInvitesContainer
              invitesLastUpdatedAt={invitesLastUpdatedAt}
              orgInvitesData={orgInvitesData}
              setOrgInvitesData={setOrgInvitesData}
              searchText={searchText}
            />
          </div>
        )}
        {currentTab === PageName.UserProfile && (
          <div css={styles.invitesTable}>
            <RolesAndPermissionsProfile userProfile={selectedOrgMember} />
          </div>
        )}
      </div>
      <BaseModal
        buttons={[]}
        visible={inviteMembersModalVisible}
        onClose={() => toggleInviteModal(!inviteMembersModalVisible)}
        dataTestId="invite-members-modal"
        css={styles.inviteMembersModal}
        content={
          <InviteMembers
            handleToggle={() => toggleInviteModal(!inviteMembersModalVisible)}
            setInvitesLastUpdatedAt={setInvitesLastUpdatedAt}
            allApplications={allApplications}
            orgMembers={orgMembersData}
            pendingInvites={orgInvitesData}
          />
        }
      />
      <BaseModal
        buttons={[]}
        visible={deleteMemberModalVisible}
        onClose={() => toggleDeleteModal(!deleteMemberModalVisible)}
        dataTestId="delete-members-modal"
        css={styles.inviteMembersModal}
        content={
          <>
            <div css={styles.modalTitle}>Are you sure?</div>
          </>
        }
      />
      <BaseModal
        buttons={[]}
        visible={confirmBrandLoginModal}
        onClose={() => toggleBrandLoginModal(!confirmBrandLoginModal)}
        dataTestId="invite-members-modal"
        css={styles.brandLoginModal}
        content={
          selectedBrand && (
            <>
              <div css={styles.modalTitle}>Are you sure?</div>
              <div css={styles.modalSubTitle}>
                Please confirm you want to log in to {selectedBrand.name}
              </div>
              <div css={styles.modalBtnRow}>
                <Button
                  aria-label={t("Cancel")}
                  onClick={() => toggleBrandLoginModal(false)}
                  className="inviteMembersCancelButton"
                  theme="black-secondary"
                >
                  {t("Cancel")}
                </Button>
                <Button
                  theme="orange-to-white"
                  aria-label="confirm-brand-login"
                  type="button"
                  onClick={() => handleBrandLogin(selectedBrand.orgId)}
                  data-testid="confirm-brand-login"
                  className="inviteMembersSendButton"
                >
                  Login to brand
                </Button>
              </div>
            </>
          )
        }
      />
      <SlideDrawer
        isOpen={isPasscodeMenuOpen}
        toggleMenu={toggleMenu}
        steps={passcodeSteps(
          newPasscodeData,
          setNewPasscodeData,
          selectedPermissions,
          handlePermissionSelect,
          handleGeneratedPasscode
        )}
        onComplete={isEditingPasscode ? updatePasscode : createNewPasscode}
      />
      <BaseModal
        buttons={[]}
        visible={assignMembersModalVisible}
        onClose={() => toggleAssignModal(!assignMembersModalVisible)}
        dataTestId="assign-members-modal"
        css={styles.inviteMembersModal}
        content={
          <div>
            {assignMemberToOrg && (
              <>
                <div css={styles.modalTitle}>
                  Add user to {assignMemberToOrg.name}
                </div>
                <div css={styles.modalSubTitle}>
                  Users will have access to this brand based on their
                  permissions
                </div>
                {assignableMembers !== undefined ? (
                  <div css={styles.modalList}>
                    <List
                      dataSource={getAssignableMembers(assignMemberToOrg)}
                      header={<div>Available members</div>}
                      renderItem={(item: IOrganisationMemberProps) => (
                        <List.Item key={item.auth0UserId}>
                          <List.Item.Meta
                            title={
                              <div>
                                {item.firstName} {item.lastName}
                              </div>
                            }
                            description={item.email}
                          />
                          <Tooltip
                            getPopupContainer={(triggerNode) => triggerNode}
                            title="Add"
                            color={theme.colors.white}
                            placement="top"
                            css={styles.tooltip}
                          >
                            <button
                              type="button"
                              css={styles.iconButton}
                              onClick={() =>
                                assignMemberToChildOrganisation(
                                  item,
                                  assignMemberToOrg
                                )
                              }
                            >
                              <PlusIcon />
                            </button>
                          </Tooltip>
                        </List.Item>
                      )}
                    />
                  </div>
                ) : (
                  <div>No Members</div>
                )}
              </>
            )}
          </div>
        }
      />
    </div>
  );
};

export default Team;
