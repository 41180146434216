import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: css`
    background: ${theme.colors.background.landingDarkGray};
    padding-bottom: 141px;
    .content {
      position: relative;
      z-index: 999;
      width: 958px;
      margin: auto;
    }
    p {
      font-family: ${theme.fonts.sofiaPro};
      color: ${theme.colors.white};
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      white-space: pre-line;
    }
    p:nth-of-type(1) {
      font-size: 24px;
      line-height: 32px;
    }

    h1 {
      letter-spacing: -0.02em;
      font-weight: 250;
      line-height: 72px;
      color: ${theme.colors.white};
      font-family: ${theme.fonts.sofiaPro};
      font-size: 64px;
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      text-align: left;
      strong {
        color: ${theme.colors.functional.brightOrange};
        font-weight: 250;
      }
      z-index: 9999;
    }
    h2 {
      color: ${theme.colors.white};
      font-family: ${theme.fonts.sofiaPro};
      font-style: normal;
      font-weight: 250;
      font-size: 32px;
      line-height: 40px;
      margin-top: 64px;
    }
  `,
  videoContainer: css`
    width: 953px;
    height: 538px;
    background: url("../images/zolando-placeholder.png") no-repeat;
    margin-top: 64px;
  `,
};
