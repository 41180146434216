import { ReactElement } from "react";

const FilterIcon = (): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M16.5147 22.8264H17.8127C18.0507 22.8264 18.2454 22.6317 18.2454 22.3937V14.4327C18.2454 14.1947 18.0507 14 17.8127 14H16.5147C16.2767 14 16.082 14.1947 16.082 14.4327V22.3937C16.082 22.6317 16.2767 22.8264 16.5147 22.8264ZM19.684 24.8707H14.6326C14.3947 24.8707 14.2 25.0654 14.2 25.3034V26.6014C14.2 26.8394 14.3947 27.0341 14.6326 27.0341H16.0712V33.5673C16.0712 33.8053 16.2659 34 16.5039 34H17.8019C18.0399 34 18.2346 33.8053 18.2346 33.5673V27.0341H19.6732C19.9111 27.0341 20.1058 26.8394 20.1058 26.6014V25.3034C20.1058 25.0654 19.9111 24.8707 19.6732 24.8707H19.684ZM23.3617 17.4181H24.6597C24.8976 17.4181 25.0923 17.2234 25.0923 16.9854V14.4327C25.0923 14.1947 24.8976 14 24.6597 14H23.3617C23.1237 14 22.929 14.1947 22.929 14.4327V16.9854C22.929 17.2234 23.1237 17.4181 23.3617 17.4181ZM26.5309 19.4624H21.4796C21.2416 19.4624 21.0469 19.6571 21.0469 19.8951V21.1931C21.0469 21.431 21.2416 21.6257 21.4796 21.6257H22.9182V33.5673C22.9182 33.8053 23.1129 34 23.3508 34H24.6488C24.8868 34 25.0815 33.8053 25.0815 33.5673V21.6257H26.5201C26.7581 21.6257 26.9528 21.431 26.9528 21.1931V19.8951C26.9528 19.6571 26.7581 19.4624 26.5201 19.4624H26.5309ZM30.2086 26.0498H31.5066C31.7446 26.0498 31.9393 25.8551 31.9393 25.6171V14.4327C31.9393 14.1947 31.7446 14 31.5066 14H30.2086C29.9706 14 29.7759 14.1947 29.7759 14.4327V25.6171C29.7759 25.8551 29.9706 26.0498 30.2086 26.0498ZM33.3779 28.0941H28.3265C28.0885 28.0941 27.8938 28.2888 27.8938 28.5268V29.8248C27.8938 30.0627 28.0885 30.2574 28.3265 30.2574H29.7651V33.5673C29.7651 33.8053 29.9598 34 30.1978 34H31.4958C31.7338 34 31.9285 33.8053 31.9285 33.5673V30.2574H33.3671C33.605 30.2574 33.7997 30.0627 33.7997 29.8248V28.5268C33.7997 28.2888 33.605 28.0941 33.3671 28.0941H33.3779Z"
        fill="#FF4E00"
      />
    </svg>
  );
};
export default FilterIcon;
