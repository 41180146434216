import {
  getIsDesktop,
  getIsMobile,
  getIsTablet,
  useWindowSize,
} from "@songtradr/spa-common/lib/utils";
import React, {
  Fragment,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { IConfirmContentProps } from "src/components/interfaces";
import MobileDrawer from "src/components/mobile-drawer";
import ConfirmationDrawer from "src/components/mobile-drawer/confirmation-drawer";
import BaseModal, { ModalTypes } from "src/components/modals/base-modal";
import Content from "src/components/modals/confirmation-modal/content";
import { Table } from "antd";
import { TableRowSelection } from "antd/lib/table/interface";
import { Button, CloseIcon } from "@songtradr/component-library";
import useAuth from "src/auth/use-auth";
import { errorToast, successToast } from "src/components/toast-notification";
import { resendInvites } from "src/api/app-invites";
import axios from "axios";
import STLoadingLogo from "src/components/st-loading-logo";
import { IMemberInvite } from "../../interfaces";
import style from "../../styles";
import pendingInviteStyles from "../pending-invites/styles";
import orgInvitesColumns from "../../table-data/org-invites-columns";
import orgInvitesData from "../../table-data/org-invites-data";
import ApproveIcon from "../passcodes/icons/approveIcon";

interface IProps {
  getData: () => void;
  orgInvites: Array<IMemberInvite>;
  handleResendInvite: (inviteId: string) => void;
  handleDeleteInvite: (inviteId: string) => void;
  handleConfirmDeleteInviteClick: () => void;
  handleOnCloseModal: () => void;
  openModalType?: ModalTypes;
  invitesLastUpdatedAt: Date;
  isModalOpen: boolean;
  confirmDeleteContentProps: IConfirmContentProps;
  isLoading: boolean;
  searchText: string;
}

const OrganisationInvitesTable = ({
  getData,
  orgInvites,
  searchText,
  isModalOpen,
  openModalType,
  confirmDeleteContentProps,
  handleResendInvite,
  handleDeleteInvite,
  handleOnCloseModal,
  invitesLastUpdatedAt,
  isLoading,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [isMultiSelect, setIsMultiSelect] = useState<boolean>(false);
  const { organisationId, getAccessToken } = useAuth();

  const clearMultiSelect = () => {
    setIsMultiSelect(false);
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    setIsMultiSelect(selectedRowKeys.length > 1);
  }, [selectedRowKeys]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitesLastUpdatedAt, searchText]);

  useWindowSize();
  const isTablet = getIsTablet();
  const isMobile = getIsMobile();
  const isDesktop = getIsDesktop();
  const columns = orgInvitesColumns(isTablet, isMobile, t);

  const dataSource = orgInvitesData(
    orgInvites,
    isTablet,
    isMobile,
    handleResendInvite,
    handleDeleteInvite,
    t
  );

  const resendMultipleInvites = async () => {
    const accessToken = getAccessToken();
    const inviteIds = selectedRowKeys;

    if (!organisationId) {
      return;
    }

    try {
      const response = await resendInvites(
        accessToken,
        organisationId,
        inviteIds
      );
      if (response.unsuccessfulApplicationIds.length > 0) {
        errorToast({
          message: `${
            response.unsuccessfulApplicationIds.length
          } invites could not be resent (${response.unsuccessfulApplicationIds.join(
            ","
          )})`,
        });
        return;
      }
      successToast({ message: "Invites have been resent" });
      getData();
      if (isMultiSelect) {
        clearMultiSelect();
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        errorToast({ message: "Error resending invites." });
      }
    }
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<DataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showSpinner = useMemo(() => isLoading && dataSource.length === 0, [
    dataSource.length,
    isLoading,
  ]);

  const emptyText = useMemo(
    () =>
      showSpinner ? <Fragment /> : t("manageTeam##invites##No invites found"),
    [showSpinner, t]
  );

  return (
    <div css={pendingInviteStyles.container}>
      <Table
        rowSelection={rowSelection}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        css={style.table}
        className="team-invites-table"
        data-testid="team-invites-table"
        loading={{
          spinning: showSpinner,
          indicator: <STLoadingLogo />,
        }}
        locale={{ emptyText }}
      />

      {isDesktop &&
        openModalType === ModalTypes.deleteOrganisationInvite &&
        confirmDeleteContentProps && (
          <BaseModal
            visible={isModalOpen}
            content={<Content {...confirmDeleteContentProps} />}
            buttons={[]}
            onClose={handleOnCloseModal}
            data-testid="about-to-delete-invite-modal"
          />
        )}

      {(isMobile || isTablet) &&
        openModalType === ModalTypes.deleteOrganisationInvite &&
        confirmDeleteContentProps && (
          <MobileDrawer
            data-testid="mobile-drawer"
            placement="bottom"
            onClose={handleOnCloseModal}
            visible={isModalOpen}
            key="bottom"
            closable={false}
            destroyOnClose
            css={style.drawer}
          >
            <ConfirmationDrawer {...confirmDeleteContentProps} />
          </MobileDrawer>
        )}
      {isMultiSelect && (
        <div css={pendingInviteStyles.multiSelect}>
          <span>{selectedRowKeys.length} invites selected.</span>
          <div css={pendingInviteStyles.resendContainer}>
            <Button
              type="button"
              className="approve-button"
              onClick={resendMultipleInvites}
            >
              <ApproveIcon /> Resend all
            </Button>
            <CloseIcon className="close-icon" onClick={clearMultiSelect} />
          </div>
        </div>
      )}
    </div>
  );
};

export default OrganisationInvitesTable;
