import React, { Fragment, ReactElement, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Table from "src/components/table";
import {
  getIsMobile,
  getIsTablet,
  useWindowSize,
} from "@songtradr/spa-common/lib/utils";
import getColumns from "src/pages/team/table-data/org-members-columns";
import BaseModal, { ModalTypes } from "src/components/modals/base-modal";
import { IConfirmContentProps } from "src/components/interfaces";
import Content from "src/components/modals/confirmation-modal/content";
import { eApplicationType } from "src/interfaces/auth";
import { TablePaginationConfig } from "antd";
import { useHistory } from "react-router-dom";
import STLoadingLogo from "src/components/st-loading-logo";
import style from "../../styles";
import { IOrganisationMemberProps, OrgMembersSortType } from "../../interfaces";
import { teamMemberData, IOption } from "../../table-data/org-member-data";

interface IProps {
  orgMembers: Array<IOrganisationMemberProps>;
  nameSortAscending: boolean;
  dateSort: boolean;
  confirmDeleteContentProps: IConfirmContentProps;
  currentSortType: OrgMembersSortType;
  openModalType?: ModalTypes;
  isDeleteMemberModalOpen: boolean;
  isLoading: boolean;
  nameTitleReplacement?: string;
  projects: IOption[];
  allApplications: eApplicationType[];
  setIsDeleteMemberModalOpen: (value: boolean) => void;
  handleNameSort: () => void;
  handleDateSort: () => void;
  setCurrentTab: (value: string) => void;
  setSelectedOrgMember: React.Dispatch<
    React.SetStateAction<IOrganisationMemberProps | undefined>
  >;
  handleToggleUseProjectsWhitelist: (
    selectedOrgMember: IOrganisationMemberProps,
    useProjectsWhitelist: boolean
  ) => void;
  handleUpdateProjectsWhitelist: (
    selectedOrgMember: IOrganisationMemberProps,
    projects: string[]
  ) => void;
  handleAddApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>;
  handleRemoveApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>;
  handleAddAndRemoveApplication: (
    member: IOrganisationMemberProps,
    addApplication: eApplicationType,
    removeApplication: eApplicationType
  ) => Promise<void>;
  paginationData: TablePaginationConfig;
  setPaginationData: (values: TablePaginationConfig) => void;
}

const OrgMembersTable = ({
  orgMembers,
  nameSortAscending,
  dateSort,
  allApplications,
  currentSortType,
  openModalType,
  isDeleteMemberModalOpen,
  confirmDeleteContentProps,
  isLoading,
  nameTitleReplacement,
  handleAddApplication,
  handleRemoveApplication,
  handleAddAndRemoveApplication,
  paginationData,
  setIsDeleteMemberModalOpen,
  setCurrentTab,
  handleNameSort,
  handleDateSort,
  setSelectedOrgMember,
  setPaginationData,
}: IProps): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleRowClick = (id: string) => {
    const portalUser = orgMembers.find((a) => a.id === id);
    setSelectedOrgMember(portalUser);
    setCurrentTab("user-profile");
  };

  const handleDeleteButtonClick = (orgMember: IOrganisationMemberProps) => {
    setIsDeleteMemberModalOpen(true);
    setSelectedOrgMember(orgMember);
  };

  useWindowSize();
  const isTablet = getIsTablet();
  const isMobile = getIsMobile();

  const dataSource = teamMemberData(
    orgMembers,
    isMobile,
    handleRowClick,
    handleDeleteButtonClick,
    handleAddApplication,
    handleRemoveApplication,
    handleAddAndRemoveApplication,
    allApplications
  );

  const columns = getColumns(
    isTablet,
    isMobile,
    nameSortAscending,
    dateSort,
    handleNameSort,
    handleDateSort,
    currentSortType,
    t,
    nameTitleReplacement
  );

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const { current } = pagination;
    setPaginationData({ current, total: paginationData.total });
    history.push(`?page=${current}`);
  };

  const showSpinner = useMemo(() => isLoading && dataSource.length === 0, [
    dataSource.length,
    isLoading,
  ]);

  const emptyText = useMemo(
    () =>
      showSpinner ? <Fragment /> : t("manageTeam##members##No members found"),
    [showSpinner, t]
  );

  return (
    <Fragment>
      <Table
        dataSource={dataSource}
        columns={columns}
        css={style.table}
        data-testid="team-members-table"
        loading={{
          spinning: showSpinner,
          indicator: <STLoadingLogo />,
        }}
        locale={{ emptyText }}
        pagination={paginationData}
        onChange={handleTableChange}
      />

      {openModalType === ModalTypes.deleteOrganisationMember &&
        confirmDeleteContentProps && (
          <BaseModal
            visible={isDeleteMemberModalOpen}
            content={<Content {...confirmDeleteContentProps} />}
            buttons={[]}
            onClose={() => setIsDeleteMemberModalOpen(false)}
          />
        )}
    </Fragment>
  );
};

export default OrgMembersTable;
