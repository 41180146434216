import React, { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "./component-icons/interfaces";

const InfoIconWhite = ({
  height = theme.svgSize.medium,
  width = theme.svgSize.medium,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="12"
        transform="rotate(-180 12 12)"
        fill="white"
      />
      <path
        d="M12 17.7275V10.9775"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle
        cx="12"
        cy="7.25635"
        r="1.125"
        transform="rotate(-180 12 7.25635)"
        fill="black"
      />
    </svg>
  );
};

export default InfoIconWhite;
