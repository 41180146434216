import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import DoughnutChart from "src/app/assets/icons/validate-icons/doughnut-image";
import PieChart from "src/app/assets/icons/validate-icons/pie-image";
import LineChart from "src/app/assets/icons/validate-icons/line-chart-image";
import ShadedLineChart from "src/app/assets/icons/validate-icons/shaded-line-chart-image";
import { Button } from "@songtradr/component-library";
import { useHistory } from "react-router-dom";
import styles from "./styles";

interface ICardProps {
  children: ReactElement;
}

const ImageCard = ({ children }: ICardProps): ReactElement => {
  return <div css={styles.card}>{children}</div>;
};

const ExplainerAndIntro = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div css={styles.explainerAndIntroContainer}>
      <div css={styles.textContainer}>
        <div>
          <div css={styles.explainerAndIntroTitle}>
            The RIGHT music will ensure that your ad <span>breaks through</span>{" "}
            clutter
          </div>
          <div css={styles.explainerAndIntroDescription}>
            <p>
              Advertising that evokes an emotional response tends to be more
              engaging, more memorable and more persuasive. Since music has such
              a profound effect on our emotions, it plays a pivotal role in your
              ad’s ability to create a connection with your audience, making it
              more likely that they will act.
            </p>
            <p>
              Songtradr has designed a research solution that focuses
              specifically on quantifying the emotional link between your music
              choices and measurable consumer actions such as sales, repeat
              purchases, and brand evangelism.
            </p>
            <p>
              We use advanced neuro-psychology research tools to measure both
              conscious and sub-conscious response to your creative, exposing it
              to real audiences in specific contexts – including TV, radio,
              YouTube, Instagram, and TikTok.
            </p>
            <p>
              You get accurate and reliable predictions of the behavioral impact
              of the music you’ve chosen for your creative, along with insights
              for improvement and optimization.
            </p>
          </div>
          <Button
            variant="primary"
            onClick={() => history.push("/measurement/new-test")}
          >
            {t("Validate##Create a new test")}
          </Button>
        </div>
      </div>
      <div css={styles.imageContainer}>
        <div>
          <ImageCard>
            <DoughnutChart />
          </ImageCard>
          <ImageCard>
            <PieChart />
          </ImageCard>
        </div>
        <div>
          <ImageCard>
            <LineChart />
          </ImageCard>
          <ImageCard>
            <ShadedLineChart />
          </ImageCard>
        </div>
      </div>
    </div>
  );
};

export default ExplainerAndIntro;
