import axios from "axios";
import getNotifyAccountManagerUrl from "src/api/organisation-notifications-url-helper";
import { INotifyAccountManagerRequest } from "src/interfaces/organisation";
import {
  getJsonContentHeader,
  getAuthHeader,
  getAxiosOptions,
} from "../../headers";

const notifyAccountManager = (
  accessToken: string,
  organisationId: string,
  request: INotifyAccountManagerRequest
): Promise<void> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  return axios.post(
    getNotifyAccountManagerUrl(organisationId),
    request,
    options
  );
};

export default notifyAccountManager;
