import React, { ReactElement } from "react";

const GridMenuIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="13.5 13.5 21 21"
      height="22"
      width="22"
      {...props}
    >
      <path
        d="M17.6188 13.5H14.3812C13.8945 13.5 13.5 13.8918 13.5 14.375V17.625C13.5 18.1082 13.8945 18.5 14.3812 18.5H17.6188C18.1055 18.5 18.5 18.1082 18.5 17.625V14.375C18.5 13.8918 18.1055 13.5 17.6188 13.5Z"
        fill="white"
      />
      <path
        d="M17.6188 21.5H14.3812C13.8945 21.5 13.5 21.8918 13.5 22.375V25.625C13.5 26.1082 13.8945 26.5 14.3812 26.5H17.6188C18.1055 26.5 18.5 26.1082 18.5 25.625V22.375C18.5 21.8918 18.1055 21.5 17.6188 21.5Z"
        fill="white"
      />
      <path
        d="M17.6188 29.5H14.3812C13.8945 29.5 13.5 29.8918 13.5 30.375V33.625C13.5 34.1082 13.8945 34.5 14.3812 34.5H17.6188C18.1055 34.5 18.5 34.1082 18.5 33.625V30.375C18.5 29.8918 18.1055 29.5 17.6188 29.5Z"
        fill="white"
      />
      <path
        d="M25.6188 13.5H22.3812C21.8945 13.5 21.5 13.8918 21.5 14.375V17.625C21.5 18.1082 21.8945 18.5 22.3812 18.5H25.6188C26.1055 18.5 26.5 18.1082 26.5 17.625V14.375C26.5 13.8918 26.1055 13.5 25.6188 13.5Z"
        fill="white"
      />
      <path
        d="M25.6188 21.5H22.3812C21.8945 21.5 21.5 21.8918 21.5 22.375V25.625C21.5 26.1082 21.8945 26.5 22.3812 26.5H25.6188C26.1055 26.5 26.5 26.1082 26.5 25.625V22.375C26.5 21.8918 26.1055 21.5 25.6188 21.5Z"
        fill="white"
      />
      <path
        d="M25.6188 29.5H22.3812C21.8945 29.5 21.5 29.8918 21.5 30.375V33.625C21.5 34.1082 21.8945 34.5 22.3812 34.5H25.6188C26.1055 34.5 26.5 34.1082 26.5 33.625V30.375C26.5 29.8918 26.1055 29.5 25.6188 29.5Z"
        fill="white"
      />
      <path
        d="M33.6188 13.5H30.3812C29.8945 13.5 29.5 13.8918 29.5 14.375V17.625C29.5 18.1082 29.8945 18.5 30.3812 18.5H33.6188C34.1055 18.5 34.5 18.1082 34.5 17.625V14.375C34.5 13.8918 34.1055 13.5 33.6188 13.5Z"
        fill="white"
      />
      <path
        d="M33.6188 21.5H30.3812C29.8945 21.5 29.5 21.8918 29.5 22.375V25.625C29.5 26.1082 29.8945 26.5 30.3812 26.5H33.6188C34.1055 26.5 34.5 26.1082 34.5 25.625V22.375C34.5 21.8918 34.1055 21.5 33.6188 21.5Z"
        fill="white"
      />
      <path
        d="M33.6188 29.5H30.3812C29.8945 29.5 29.5 29.8918 29.5 30.375V33.625C29.5 34.1082 29.8945 34.5 30.3812 34.5H33.6188C34.1055 34.5 34.5 34.1082 34.5 33.625V30.375C34.5 29.8918 34.1055 29.5 33.6188 29.5Z"
        fill="white"
      />
    </svg>
  );
};

export default GridMenuIcon;
