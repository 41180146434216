import React, { ReactElement, useMemo } from "react";
import TabPageContainer from "src/components/tab-page-container";
import styles from "src/app/styles";
import { ProjectListingType } from "src/pages/projects";
import NotFound from "src/pages/not-found";
import CommercialProjectListings from "./commercial-project-listings";
import EnterpriseLibraryProjectListings from "./enterprise-library-project-listings";

export interface IProps {
  projectType: ProjectListingType;
}

const ProjectsContainer = ({ projectType }: IProps): ReactElement => {
  const content = useMemo(() => {
    switch (projectType) {
      case ProjectListingType.LibraryLicenses:
        return <EnterpriseLibraryProjectListings />;
      case ProjectListingType.CommercialLicenses:
        return <CommercialProjectListings />;
      default:
        return <NotFound />;
    }
  }, [projectType]);

  return (
    <div css={styles.projectContainer}>
      <TabPageContainer content={content} projectType={projectType} />;
    </div>
  );
};

export default ProjectsContainer;
