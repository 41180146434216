import { AxiosError } from "axios";
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
  useReducer,
  useCallback,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import useAuth from "src/auth/use-auth";
import {
  IReport,
  getAvailableReports,
  getAvailableReportById,
  IAvailableReport,
} from "src/api/power-bi";
import PowerBiContext from "./context";
import { InitialPowerBiReportsState, powerBiReducer } from "./store";
import { PowerBiActions } from "./types";

interface IProvider {
  children: ReactNode;
}

// 10 minutes in milliseconds is by documentation the required time to refresh the token before it expires
const TEN_MINUTES_IN_MS = 10 * 60 * 1000;

const getTimeoutInMs = (expiration: string): number => {
  return (
    new Date(expiration).getTime() - new Date().getTime() - TEN_MINUTES_IN_MS
  );
};

const PowerBiProvider = ({ children }: IProvider): ReactElement => {
  const { getAccessToken, isAuthenticated, organisationId } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const history = useHistory();
  const [state, dispatch] = useReducer(
    powerBiReducer,
    InitialPowerBiReportsState
  );
  const timer = useRef<NodeJS.Timeout | null>(null);

  const storeAvailableReports = (availableReports: IAvailableReport[]) => {
    dispatch({
      type: PowerBiActions.AVAILABLE_REPORTS,
      value: availableReports,
    });
  };

  const storeSelectedReport = (selectedReport: IReport | null) => {
    dispatch({
      type: PowerBiActions.SELECTED_REPORT,
      value: selectedReport,
    });
  };

  const getReportById = useCallback(
    async (reportId: string) => {
      try {
        setIsLoading(true);
        const accessToken: string = await getAccessToken();
        const response = await getAvailableReportById(
          accessToken,
          reportId,
          organisationId
        );

        if (response) {
          storeSelectedReport(response);
          timer.current = setTimeout(async () => {
            await getReportById(reportId);
          }, getTimeoutInMs(response.expiration));
        }
        setIsLoading(false);
      } catch (reason) {
        const error = reason as AxiosError;
        setIsLoading(false);
        if (error.response?.status === 403) {
          history.push("/commerciallicenses");
        }
      }
    },
    [getAccessToken, history, organisationId]
  );

  useEffect(() => {
    void (async () => {
      if (isAuthenticated && organisationId) {
        try {
          setIsLoading(true);
          const accessToken: string = getAccessToken();
          const response = await getAvailableReports(
            accessToken,
            organisationId
          );

          if (response) {
            storeAvailableReports(response.reports);
          }
          setIsLoading(false);
        } catch (reason) {
          const error = reason as AxiosError;
          setIsLoading(false);
          if (error.response?.status === 403) {
            history.push("/commerciallicenses");
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessToken, isAuthenticated, organisationId]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const clearTimer = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
      storeSelectedReport(null);
    }
  }, []);

  return (
    <PowerBiContext.Provider
      value={{
        isLoading,
        availableReports: state.availableReports,
        selectedReport: state.selectedReport,
        getReportById,
        clearTimer,
      }}
    >
      {children}
    </PowerBiContext.Provider>
  );
};
export default PowerBiProvider;
