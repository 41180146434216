import React, { useCallback, useMemo, useRef, useState } from "react";
import SearchIcon from "src/app/assets/icons/component-icons/icon-search";
import CloseIcon from "src/sumo/src/app/assets/icons/component/close_icon";
import styles from "./styles";

export interface ICustomSearchInput {
  searchText?: string;
  onClearSearchText?: () => void;
  onUpdateSearchText: (newValue: string) => void;
}

const CustomSearchInput = ({
  searchText,
  onClearSearchText,
  onUpdateSearchText,
}: ICustomSearchInput) => {
  const searchRef = useRef<HTMLDivElement>(null);
  const [isSearchFocused, setSearchFocused] = useState(false);

  const handleChangeFocus = useCallback(
    () => setSearchFocused(!isSearchFocused),
    [isSearchFocused]
  );

  const isCloseButtonVisible = useMemo(() => !isSearchFocused && !!searchText, [
    isSearchFocused,
    searchText,
  ]);

  const isSearchIconOnRightSide = useMemo(
    () => isSearchFocused || !!searchText,
    [isSearchFocused, searchText]
  );

  return (
    <div ref={searchRef} css={styles.searchWrapper}>
      <input
        css={styles.searchInput}
        value={searchText}
        onFocus={handleChangeFocus}
        onBlur={handleChangeFocus}
        onChange={(e) => {
          onUpdateSearchText?.(e.target.value);
        }}
      />
      <button
        type="button"
        onClick={() => onClearSearchText?.()}
        css={styles.closeButton(isCloseButtonVisible)}
      >
        <CloseIcon
          height={20}
          width={20}
          title="Close"
          role="button"
          fill="white"
        />
      </button>
      <span
        css={styles.searchIcon(
          isSearchIconOnRightSide,
          searchRef.current?.clientWidth ?? 0
        )}
      >
        <SearchIcon />
      </span>
    </div>
  );
};

export default CustomSearchInput;
