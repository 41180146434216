import React, { ReactElement } from "react";

const PlaylistsIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M22.5888 12.8625V11.1721C22.5888 10.9205 22.3853 10.717 22.1336 10.717H17.8517V5.90711C17.8517 5.6555 17.6482 5.452 17.3966 5.452H12.6147V0.670078C12.6147 0.418466 12.4112 0.214966 12.1595 0.214966H0.405307C0.153695 0.214966 -0.0498047 0.418466 -0.0498047 0.670078V12.4243C-0.0498047 12.6759 0.153695 12.8794 0.405307 12.8794H5.18723V17.6614C5.18723 17.913 5.39073 18.1165 5.64234 18.1165H10.3911V22.4602C10.3911 22.7118 10.5946 22.9153 10.8462 22.9153H12.5366C12.7882 22.9153 12.9918 22.7118 12.9918 22.4602V13.3183H22.1336C22.3853 13.3183 22.5888 13.1148 22.5888 12.8632V12.8625ZM5.18723 5.90711V9.82368C5.18723 10.0753 4.98373 10.2788 4.73212 10.2788H3.00595C2.75433 10.2788 2.55083 10.0753 2.55083 9.82368V3.27072C2.55083 3.0191 2.75433 2.8156 3.00595 2.8156H9.55826C9.80987 2.8156 10.0134 3.0191 10.0134 3.27072V4.99689C10.0134 5.2485 9.80987 5.452 9.55826 5.452H5.64169C5.39008 5.452 5.18658 5.6555 5.18658 5.90711H5.18723ZM15.2504 10.2619C15.2504 10.5135 15.0469 10.717 14.7953 10.717H10.8456C10.594 10.717 10.3905 10.9205 10.3905 11.1721V15.0601C10.3905 15.3117 10.187 15.5152 9.93535 15.5152H8.24233C7.99072 15.5152 7.78722 15.3117 7.78722 15.0601V8.50775C7.78722 8.25614 7.99072 8.05264 8.24233 8.05264H14.7946C15.0463 8.05264 15.2498 8.25614 15.2498 8.50775V10.2619H15.2504ZM23.4333 17.004H21.7429C21.4913 17.004 21.2878 17.2075 21.2878 17.4592V21.1592C21.2878 21.4108 21.0843 21.6143 20.8327 21.6143H16.1119C15.8603 21.6143 15.6568 21.8178 15.6568 22.0694V23.7599C15.6568 24.0115 15.8603 24.215 16.1119 24.215H23.4333C23.6849 24.215 23.8884 24.0115 23.8884 23.7599V17.4592C23.8884 17.2075 23.6849 17.004 23.4333 17.004Z" />
    </svg>
  );
};

export default PlaylistsIcon;
