import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  panelsContainer: css`
    div.ant-collapse-item {
      border: 1px solid ${theme.colors.darkCharcoal} !important;
      margin-bottom: 2rem;
      border-radius: 0.5rem !important;

      .ant-collapse-header {
        padding: 1rem 2.5rem 1rem 1rem !important;
        height: 96px;
        align-items: center;
      }

      .ant-collapse-arrow {
        height: 20px;
        top: 50% !important;
      }

      &:last-child {
        margin-bottom: 0 !important;
      }
    }
    div.ant-collapse-item-active {
      border-color: ${theme.colors.secondary.waveformGray} !important;
    }
  `,
};
