export const stripCurrencyFormatting = (value: string | number): string => {
  return value.toString().replace(/[,]/g, "");
};

export const formatPriceWithCurrency = (
  number: string | number,
  currency: string
): string => {
  const numberToFormat = stripCurrencyFormatting(number.toString());
  const formattedNum = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format((numberToFormat as unknown) as number);
  return formattedNum !== "NaN" ? formattedNum : number.toString();
};
