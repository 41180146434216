import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common";

export default {
  panelHeader: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-right: 24px;
  `,
  headerTitle: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: ${theme.colors.white};
  `,
  headerSubtitle: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${theme.colors.white};
  `,
  panelBody: css`
    padding-top: 1rem;

    div.ant-row:not(:last-of-type) {
      margin-bottom: 40px;
    }
  `,
  active: css`
    background-color: ${theme.colors.functional.successGreenLight};
    color: ${theme.colors.functional.successGreenDark};
  `,
  lessThan7Days: css`
    background-color: ${theme.colors.functional.alertRedLight};
    color: ${theme.colors.functional.alertRed};
  `,
  expired: css`
    background: ${theme.colors.background.veryLightGray};
    color: ${theme.colors.background.darkGray};
  `,
  perpetuity: css`
    background: ${theme.colors.functional.infoBlueLight};
    color: ${theme.colors.functional.infoBlue};
  `,
  lessThan30Days: css`
    background-color: ${theme.colors.functional.peachCream};
    color: ${theme.colors.functional.rusticBrown};
  `,
  statusChip: css`
    height: 40px;
    border-radius: 40px;
    padding: 8px 16px;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;

    div {
      text-align: center;
    }
    @media ${queries.medium} {
      display: inline-block;
    }
  `,
  timeRemaining: css`
    display: flex;
    justify-content: center;
    align-items: center;

    b {
      padding-left: 4px;
    }
  `,
  progressDates: css`
    display: flex;
    justify-content: space-between;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 1.125rem;
    margin-bottom: 40px;
    .ant-typography {
      color: ${theme.colors.white};
    }
  `,
  duration: css`
    text-align: center;
    color: ${theme.colors.background.darkGray};
    margin: 0 0.3125rem 1.125rem;
    @media ${queries.medium} {
      display: inline-block;
      float: right;
      margin-top: 1rem;
    }
  `,
  territoryList: css`
    display: flex;
    flex-direction: row;
    list-style-type: none;
    padding-left: 0;
    flex-wrap: wrap;
  `,
  showMoreText: css`
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${theme.colors.functional.brightOrangeHover};
    text-align: center;
    cursor: pointer;
  `,
  territory: css`
    padding-right: 4px;
  `,
  getInTouch: css`
    background-color: ${theme.colors.background.aliceBlue};
    border-radius: 8px;
    margin-top: 24px;
    padding: 24px;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${theme.colors.background.landingDarkGray};

    a {
      color: ${theme.colors.background.landingDarkGray};
      font-weight: 700;
      padding-left: 4px;
      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  `,
  noBottomMargin: css`
    margin-bottom: 0 !important;
  `,
  noteContainer: css`
    border: 1px solid ${theme.colors.darkCharcoal};
    padding: 0.5rem;
    border-radius: 8px;
    font-family: ${theme.fonts.sofiaPro};
    min-height: 128px;
    &:hover {
      border: 1px solid ${theme.colors.functional.zambezi};
    }
    div:first-of-type {
      font-weight: 300;
      font-size: 0.75rem;
      line-height: 1rem;
      color: ${theme.colors.functional.gray65};
    }
    div:last-of-type {
      color: ${theme.colors.white};
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  `,
  minHeight80px: css`
    min-height: 80px;
  `,
};
