import React, { ReactElement, useCallback } from "react";
import termUtils from "src/utils/projects/terms";
import { ConditionallyVisible } from "src/sumo/src/components/visibility";
import {
  DaysDuration,
  LicenseFixedDurations,
  ProjectFilterIconStatus,
} from "src/constants";
import styles from "./styles";
import pillStyles from "../../pages/projects/client-project-view/components/licensing/styles";

interface IProps {
  startDate?: string;
  endDate?: string;
  duration?: string;
}

const DaysRemainingPill = ({
  startDate = "",
  endDate = "",
  duration = "",
}: IProps): ReactElement => {
  const getPillStyles = useCallback(() => {
    const daysRemaining = termUtils.getDaysRemaining(endDate);

    if (duration === LicenseFixedDurations.InPerpetuity) {
      return pillStyles.perpetuity;
    }
    if (daysRemaining <= DaysDuration.Expired) {
      return pillStyles.expired;
    }
    if (daysRemaining < DaysDuration.Seven) {
      return pillStyles.lessThan7Days;
    }
    if (daysRemaining < DaysDuration.Thirty) {
      return pillStyles.lessThan30Days;
    }
    return pillStyles.active;
  }, [duration, endDate]);

  const getTimeRemaining = useCallback(() => {
    let timeRemaining = "";

    if (duration === LicenseFixedDurations.InPerpetuity) {
      timeRemaining = ProjectFilterIconStatus.InPerpetuity;
    } else if (endDate) {
      // if there are no terms / no end date calculated don't display anything
      timeRemaining = termUtils.calculateTimeRemaining(endDate);
    }
    return timeRemaining;
  }, [duration, endDate]);

  if (!endDate) {
    return <></>;
  }

  return (
    <ConditionallyVisible
      condition={termUtils.isTermStartDateInThePast(startDate, false)}
    >
      <div css={[styles.container, getPillStyles()]}>
        <p css={styles.days}>{getTimeRemaining()}</p>
      </div>
    </ConditionallyVisible>
  );
};
export default DaysRemainingPill;
