import { errorToast } from "src/components/toast-notification";
import { useEffect, useRef, useState } from "react";
import axios, { AxiosError } from "axios";
import { AsyncAdditionalProps } from "react-select/dist/declarations/src/useAsync";
import { GroupBase } from "react-select";
import { getYouTubeVideos, searchYouTubeChannel } from "src/api/brand-audit";
import { IYouTubeVideo } from "src/interfaces/brand-audit";
import useAuth from "src/auth/use-auth";
import { IYtChannelSelectOption } from "../../brand-audit-form/interfaces";

const useYouTubeAudit = () => {
  const [youTubeVideos, setYouTubeVideos] = useState<IYouTubeVideo[]>();
  const [isYtQuotaMaxed, setIsYtQuotaMaxed] = useState(false);
  const { getAccessToken } = useAuth();
  const abortController = useRef<AbortController>();

  useEffect(() => {
    abortController.current = new AbortController();
    return () => abortController.current?.abort();
  }, []);

  const handleFetchYouTubeVideos = async (channelId: string) => {
    if (!abortController.current || !channelId) {
      return;
    }

    let data;

    try {
      data = await getYouTubeVideos(
        getAccessToken(),
        abortController.current,
        channelId
      );
    } catch (error) {
      if (axios.isAxiosError(error) && error.code === AxiosError.ERR_CANCELED) {
        return;
      }

      if (axios.isAxiosError(error) && error.response?.status === 429) {
        setIsYtQuotaMaxed(true);
        return;
      }

      errorToast({
        message:
          "There was an issue getting the videos for this YouTube channel",
      });

      // eslint-disable-next-line no-console
      console.error(error);
    }

    if (!data) {
      return;
    }

    setYouTubeVideos(data);
  };

  const handleYouTubeChannelSearch: AsyncAdditionalProps<
    IYtChannelSelectOption,
    GroupBase<IYtChannelSelectOption>
  >["loadOptions"] = (searchValue, callback) => {
    // This anonymous async function is needed as react-select
    // doesn't work correctly with a debounce that returns a promise
    // the promise must be resolved, then call the `callback` arg
    (async () => {
      if (!abortController.current || !searchValue) {
        return;
      }

      let data: Awaited<ReturnType<typeof searchYouTubeChannel>> = [];

      try {
        data = await searchYouTubeChannel(
          getAccessToken(),
          abortController.current,
          searchValue
        );
      } catch (error) {
        if (
          axios.isAxiosError(error) &&
          error.code === AxiosError.ERR_CANCELED
        ) {
          return;
        }

        if (axios.isAxiosError(error) && error.response?.status === 429) {
          setIsYtQuotaMaxed(true);
          callback([]);
          return;
        }

        errorToast({
          message: "There was an issue searching for your YouTube channel",
        });

        // eslint-disable-next-line no-console
        console.error(error);
      }

      if (!data) {
        return;
      }

      const transformedData = data.reduce(
        (acc: IYtChannelSelectOption[], channel) => {
          if (!channel.snippet.channelId || !channel.snippet.title) {
            return acc;
          }

          acc.push({
            value: channel.snippet.channelId,
            label: channel.snippet.title,
            thumbnail: channel.snippet.thumbnails.default.url,
          });

          return acc;
        },
        []
      );

      callback(transformedData);
    })();
  };

  return {
    youTubeVideos,
    handleYouTubeChannelSearch,
    handleFetchYouTubeVideos,
    isYtQuotaMaxed,
  };
};

export default useYouTubeAudit;
