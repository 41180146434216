import { ReactElement } from "react";
import styles from "./styles";

const Measurements = (): ReactElement => {
  return (
    <div css={styles.measurements}>
      <svg
        id="Artwork"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 252 242"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="33.11"
            y1="117.34"
            x2="191.08"
            y2="117.34"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#565656" stopOpacity=".2" />
            <stop offset="1" stopColor="#565656" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="222.65"
            y1="133.01"
            x2="194.52"
            y2="133.01"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-3"
            x1="198.41"
            y1="71.86"
            x2="198.41"
            y2="196.93"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#2e3030" />
            <stop
              offset=".29"
              stopColor="rgba(43,45,45,.91)"
              stopOpacity=".91"
            />
            <stop
              offset=".58"
              stopColor="rgba(37,39,39,.65)"
              stopOpacity=".65"
            />
            <stop
              offset=".88"
              stopColor="rgba(27,29,29,.22)"
              stopOpacity=".22"
            />
            <stop offset="1" stopColor="#161818" stopOpacity="0" />
          </linearGradient>
        </defs>
        <rect
          className="cls-3"
          x="26.51"
          y="174.81"
          width="198.99"
          height="1"
        />
        <rect className="cls-3" x="26.51" y="138.6" width="198.99" height="1" />
        <rect className="cls-3" x="26.51" y="102.4" width="198.99" height="1" />
        <rect className="cls-3" x="26.51" y="66.19" width="198.99" height="1" />
        <rect className="cls-3" x="53.09" y="44.82" width="1" height="152.36" />
        <rect className="cls-3" x="89.3" y="44.82" width="1" height="152.36" />
        <rect className="cls-3" x="125.5" y="44.82" width="1" height="152.36" />
        <rect className="cls-3" x="161.7" y="44.82" width="1" height="152.36" />
        <rect
          className="cls-3"
          x="197.91"
          y="44.82"
          width="1"
          height="152.36"
        />
        <path
          className="cls-5"
          d="M198.41,62.88c2.11,0,3.82,1.71,3.82,3.82s-1.71,3.82-3.82,3.82-3.82-1.71-3.82-3.82,1.71-3.82,3.82-3.82M198.41,58.88c-4.32,0-7.82,3.5-7.82,7.82s3.5,7.82,7.82,7.82,7.82-3.5,7.82-7.82-3.5-7.82-7.82-7.82h0Z"
        />
        <path
          className="cls-1"
          d="M109.07,159.81c-12.18,0-17.48-17.66-22.61-34.73-4.47-14.88-8.69-28.93-16.89-28.93s-12.87,15.71-17.02,30.91c-4.41,16.1-8.96,32.76-18.94,32.76-.28,0-.5-.22-.5-.5s.22-.5.5-.5c9.21,0,13.67-16.28,17.97-32.02,4.45-16.27,8.66-31.65,17.99-31.65s13.27,14.4,17.84,29.65c5.02,16.73,10.21,34.02,21.65,34.02s18.68-22.86,25.67-44.97c6.34-20.03,12.33-38.96,21.27-38.96,10.33,0,16.25,17.8,21.48,33.51,4.13,12.4,8.03,24.12,13.09,24.12.28,0,.5.22.5.5s-.22.5-.5.5c-5.79,0-9.61-11.5-14.04-24.81-5.12-15.39-10.92-32.83-20.53-32.83-8.21,0-14.09,18.58-20.31,38.26-7.1,22.45-14.45,45.67-26.63,45.67Z"
        />
        <path
          className="cls-6"
          d="M192.29,66.7c-14.66,0-17.25,80.31-34.93,80.31s-18.54-46.3-39.24-46.3-16.39,43.41-39.24,43.41c-17.25,0-15.09-21.7-25.01-21.7s-16.82,29.66-16.82,29.66"
        />
        <path
          className="cls-2"
          d="M198.41,129.19c2.11,0,3.82,1.71,3.82,3.82s-1.71,3.82-3.82,3.82-3.82-1.71-3.82-3.82,1.71-3.82,3.82-3.82M198.41,125.19c-4.32,0-7.82,3.5-7.82,7.82s3.5,7.82,7.82,7.82,7.82-3.5,7.82-7.82-3.5-7.82-7.82-7.82h0Z"
        />
        <path
          className="cls-4"
          d="M202.23,133.01c0-2.11-1.71-3.82-3.82-3.82s-3.82,1.71-3.82,3.82,1.71,3.82,3.82,3.82,3.82-1.71,3.82-3.82ZM198.41,74.52c-2.34,0-4.43-1.03-5.87-2.66v55.99c1.43-1.63,3.53-2.66,5.87-2.66s4.43,1.03,5.87,2.66v-55.99c-1.43,1.63-3.53,2.66-5.87,2.66ZM198.41,140.84c-2.34,0-4.43-1.03-5.87-2.66v58.75h11.73v-58.75c-1.43,1.63-3.53,2.66-5.87,2.66Z"
        />
      </svg>
    </div>
  );
};
export default Measurements;
