import dayjs, { Dayjs } from "dayjs";

const format = {
  DateDisplay: "MMM D, YYYY",
  CardDate: "MM/YY",
  ApiDate: "YYYY-MM-DD HH:mm:ss.000Z",
  FullMonthDate: "D MMMM YYYY",
  FullMonthDateTime: "do MMMM yyyy @ HH:mma",
};

const parseApiDate = (originalDate?: string): Dayjs => {
  return dayjs(originalDate, format.ApiDate);
};

const parseApiDateToDisplayDate = (
  originalDate?: string,
  dateFormat = format.DateDisplay
): string => {
  return parseApiDate(originalDate).format(dateFormat);
};

export default {
  format,
  parseApiDate,
  parseApiDateToDisplayDate,
};
