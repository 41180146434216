import React, { ReactElement } from "react";
import { useParams } from "react-router-dom";
import BrandAuditList from "src/pages/brand-audit/components/brand-audit-list";
import BrandAuditDetails from "src/pages/brand-audit/components/brand-audit-details";
import Lottie from "react-lottie-player";
import backgroundLinesAnimation from "src/app/assets/animations/bars_bg.json";
import BrandAuditEdit from "src/pages/brand-audit/components/brand-audit-edit";
import BrandAuditNew from "src/pages/brand-audit/components/brand-audit-new";
import styles from "./styles";

const BrandAudit = (): ReactElement => {
  const { subRoute, tertiaryRoute } = useParams<{
    subRoute: string;
    tertiaryRoute: string;
  }>();

  const getChildRouteComponent = () => {
    if (!subRoute) {
      return <BrandAuditList />;
    }

    if (subRoute === "new") {
      return <BrandAuditNew />;
    }

    if (subRoute !== "" && tertiaryRoute === "edit") {
      return <BrandAuditEdit auditId={subRoute} />;
    }

    return <BrandAuditDetails auditId={subRoute} />;
  };

  return (
    <div css={styles.container}>
      <div css={styles.animationContainer}>
        <Lottie
          animationData={backgroundLinesAnimation}
          style={{ width: 1000, height: 175 }}
          loop
          play
        />
      </div>
      <div css={styles.innerContainer}>{getChildRouteComponent()}</div>
    </div>
  );
};

export default BrandAudit;
