import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  controls: css`
    display: flex;
    align-items: center;
    gap: 24px;
  `,
  button: css`
    &:disabled svg path {
      fill: ${theme.colors.secondary.mediumGrey};
    }
  `,
};
