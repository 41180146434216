import React from "react";
import { eApplicationType } from "src/interfaces/auth";
import { Checkbox } from "@songtradr/component-library";
import { Tooltip } from "antd";
import { SerializedStyles } from "@emotion/react";
import style from "../styles";

interface IPermissionCheckboxProps {
  permission: eApplicationType;
  tooltipText: string;
  cssStyle: SerializedStyles | null;
  disabled: boolean;
  checked: boolean;
  dataTestId: string;
  onClick: (permission: eApplicationType) => void;
}

const PermissionCheckboxWithTooltip = ({
  permission,
  tooltipText,
  cssStyle,
  disabled,
  checked,
  dataTestId,
  onClick,
}: IPermissionCheckboxProps) => {
  return (
    <div css={style.teamEnterpriseAdminCol}>
      <Tooltip
        getPopupContainer={(triggerNode) => triggerNode}
        title={tooltipText}
        css={style.antTooltip}
        placement="top"
      >
        <div css={style.permissionTooltipContainer}>
          <Checkbox
            css={cssStyle}
            disabled={disabled}
            checked={checked}
            onClick={async (e: any) => {
              e.stopPropagation();
              onClick(permission);
            }}
            data-testid={dataTestId}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default PermissionCheckboxWithTooltip;
