import config from "src/config";
import { Subscriptions } from "src/constants";
import getDefaultPlaylistsUrl from "src/utils/subscriptionPlaylistsDefaultUrl";
import { eApplicationType } from "src/interfaces/auth";
import { IOrganisation } from "src/interfaces/organisation";

export const generateSearchLink = (
  organisationId: string,
  isCSCMode?: boolean
) => {
  let url = "";

  // If org is not listed then use the new player: https://player.enterprise.songtradr.com
  if (!config.oldPlayerOrgIds.includes(organisationId)) {
    url = !isCSCMode
      ? `${config.player.url}/search`
      : `${config.player.url}/search?cscMode=on`;
  } else {
    url += `${Subscriptions.Library.path}/search`;
  }

  return url;
};

export const generateLibraryLink = (
  organisationId: string,
  isCSCMode?: boolean
) => {
  let url = "";
  const digitalSubscriptionUrl = getDefaultPlaylistsUrl(organisationId);
  // If org is not listed then use the new player: https://player.enterprise.songtradr.com
  if (!config.oldPlayerOrgIds.includes(organisationId)) {
    url = !isCSCMode
      ? `${config.player.url}`
      : `${config.player.url}/my-music/playlists`;
  } else {
    url += digitalSubscriptionUrl;
  }

  return url;
};

export const generateSonicBrandingLink = (organisationId: string) => {
  const url = `${config.assetsSpa.url}/list/${organisationId}`;
  return url;
};

export const isModuleVisible = (
  organisation: IOrganisation | undefined,
  permission: boolean,
  module: eApplicationType
) => {
  if (module === eApplicationType.Insights) {
    return organisation?.enableCommercialMusicSearch;
  }

  if (module === eApplicationType.LibraryFull) {
    return organisation?.applications?.some((application) =>
      [eApplicationType.LibraryFull, eApplicationType.LibraryReadOnly].includes(
        application
      )
    );
  }

  if (!organisation?.applications?.includes(module)) {
    return false;
  }

  return permission;
};

export const hasAdaptiveMusicAccess = (
  organisation: IOrganisation | undefined
): boolean => {
  return (
    organisation?.adaptiveMusicUrl !== "" && !!organisation?.enableAdaptiveMusic
  );
};
