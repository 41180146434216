import { ReactElement, Fragment } from "react";

interface IProps {
  children: ReactElement | ReactElement[];
}

const ChildOrChildren = ({ children }: IProps): ReactElement | null => {
  const isGroupOfChildren = Array.isArray(children);
  let childrenToRender: ReactElement[] | null = null;

  if (isGroupOfChildren) {
    childrenToRender = children as ReactElement[];
  }

  return (
    <Fragment>
      {childrenToRender ? childrenToRender.map((child) => child) : children}
    </Fragment>
  );
};

export default ChildOrChildren;
