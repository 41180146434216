import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";

export default {
  background: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    background: ${theme.colors.background.landingDarkGray};
  `,
  container: css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  backgroundHeading: css`
    position: absolute;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 15rem !important;
    line-height: 15rem !important;
    font-weight: 800 !important;
    letter-spacing: -0.02em !important;
    top: 4.5rem;
    left: 50%;
    transform: translate(-50%, 0);
    color: ${theme.colors.background.veryDarkGray} !important;
    @media ${queries.medium} {
      font-size: 25rem !important;
      line-height: 25rem !important;
    }
  `,
  headingAndTextContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    text-align: center;
    padding-top: 14rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    @media ${queries.medium} {
      padding-left: 0;
      padding-right: 0;
      padding-top: 20.25rem;
    }
  `,
  heading: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 3rem !important;
    line-height: 3.5rem !important;
    font-weight: 500 !important;
    color: ${theme.colors.white} !important;
    margin-bottom: 1.5rem;
    @media ${queries.medium} {
      font-size: 5rem !important;
      line-height: 5.5rem !important;
    }
  `,
  text: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    font-weight: 400 !important;
    color: ${theme.colors.white} !important;
  `,
  goHomeBtn: css`
    margin-top: 3rem;
    margin-bottom: 9rem;
    transition: all 2s ease-in-out;

    svg {
      margin: 0 0 0 1rem;
    }
  `,
  copyrightFooter: css`
    width: 100%;
    position: absolute;
    bottom: 0;
  `,
};
