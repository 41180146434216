import { useMountedRef } from "@songtradr/spa-common";
import axios from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import PageContainer from "src/components/page-container";
import { useImmer } from "use-immer";
import { successToast } from "src/components/toast-notification";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import patchOrgMember from "src/api/organisation-members/patch-org-member";
import getOrgMember from "src/api/organisation-members/get-org-member";
import { IOrganisationMember } from "src/pages/team/interfaces";
import UserProfile from "..";
import useAuth from "../../../auth/use-auth";
import { IProfileUpdateForm } from "../interfaces";

function ProfileContainer(): ReactElement | null {
  const mountedRef = useMountedRef();
  const { t } = useTranslation();
  const { organisationId, user, getAccessToken, resetPassword } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const [orgMember, setOrgMember] = useImmer<IOrganisationMember>(
    {} as IOrganisationMember
  );

  const handleResetPassword = () => {
    if (!user?.email) {
      return;
    }

    resetPassword(user.email);
    history.push("reset-password", { email: user.email, useDarkBg: false });
  };

  const submitForm = async (formData: IProfileUpdateForm) => {
    try {
      const accessToken = getAccessToken();

      if (organisationId && user?.id) {
        await patchOrgMember(organisationId, user?.id, accessToken, {
          firstName: formData.firstName,
          lastName: formData.lastName,
          jobTitle: formData?.jobTitle?.value,
          phoneNumber: formData.phoneNumber,
          country: formData?.country?.value,
        });
      }

      setOrgMember((draft) => {
        draft.firstName = formData.firstName;
        draft.lastName = formData.lastName;
        draft.jobTitle = { value: formData.jobTitle, label: formData.jobTitle };
        draft.country = { value: formData.country, label: formData.country };
        draft.phoneNumber = formData.phoneNumber;
      });

      successToast({
        message: t("UserProfilePage##Details updated."),
        toastId: "success-toast",
      });
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!organisationId || !user?.id) {
      return;
    }

    const source = axios.CancelToken.source();

    (async () => {
      setIsLoading(true);

      const orgMemberResponse = await getOrgMember(
        getAccessToken(),
        organisationId,
        user.id
      );

      if (mountedRef.current) {
        setOrgMember(orgMemberResponse);
        setIsLoading(false);
      }
    })();

    // eslint-disable-next-line consistent-return
    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId, user]);

  return (
    <PageContainer
      showBackBtn
      Content={
        <UserProfile
          profile={orgMember}
          resetPassword={handleResetPassword}
          submitForm={submitForm}
          isLoading={isLoading}
        />
      }
    />
  );
}

export default ProfileContainer;
