import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  floatLabel: css`
    position: relative;
    color: ${theme.colors.background.darkGray};
    input,
    textarea {
      padding-top: 2rem;
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  `,
  label: css`
    color: ${theme.colors.secondary.waveformGray} !important;
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 0.75rem;
    top: 1.3rem;
    transition: 0.2s ease all;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  labelFloat: css`
    top: 8px;
    font-size: 0.75rem !important;
  `,
};
