import React, { ReactElement } from "react";
import ReactSelectAsync, { AsyncProps } from "react-select/async";
import { GroupBase } from "react-select";
import styles from "./styles";

const AsyncSelect = <
  TOption,
  Group extends GroupBase<TOption>,
  IsMulti extends boolean = false
>(
  props: AsyncProps<TOption, IsMulti, Group>
): ReactElement => {
  return (
    <ReactSelectAsync
      css={styles.select}
      classNames={{
        control: () => "control-el",
        input: () => "input-el",
        placeholder: () => "placeholder-el",
        loadingIndicator: () => "loading-el",
        singleValue: () => "single-value-el",
        option: () => "option-el",
        menu: () => "menu-el",
        menuList: () => "menu-list-el",
      }}
      {...props}
    />
  );
};

export default AsyncSelect;
