import React from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import useOrgSubscription from "src/providers/organisation/hooks";
import { eApplicationType } from "src/interfaces/auth";
import styles from "./styles";

interface IProps {
  isSelected?: boolean;
  handleSelect: (value: boolean, key: string) => void;
  title: string;
  description: string;
}

const mapTitleToModule = (title: string) => {
  switch (title) {
    case "Search":
      return eApplicationType.LibraryFull;
    case "SearchReadOnly":
      return eApplicationType.LibraryReadOnly;
    case "Playlists":
      return eApplicationType.LibraryFull;
    case "Projects":
      return eApplicationType.ProjectsClient;
    case "Measurement":
      return eApplicationType.Validate;
    default:
      return title;
  }
};

const PermissionSelector = ({
  isSelected,
  handleSelect,
  title,
  description,
  ...rest
}: IProps) => {
  const { organisation } = useOrgSubscription();
  if (!organisation?.applications.includes(mapTitleToModule(title))) {
    return <div />;
  }

  return (
    <div
      role="button"
      css={styles.container(isSelected || false)}
      onClick={() => handleSelect(!isSelected, title)}
      onKeyDown={() => handleSelect(!isSelected, title)}
      tabIndex={0}
      {...rest}
    >
      <div className="col">
        <h1>{title}</h1>
        <p>{description}</p>
      </div>
      <div className="col icon-container">
        {isSelected && (
          <CheckCircleOutlined
            className="icon"
            data-testid="item-is-selected"
          />
        )}
      </div>
    </div>
  );
};

export default PermissionSelector;
