import { ReactElement, useCallback, useState } from "react";
import { Form } from "antd";
import FloatingLabel from "src/components/floating-label";
import { Button } from "@songtradr/component-library";
import { registerInvite } from "src/api/app-invites";
import { errorToast } from "src/components/toast-notification";
import styles from "./styles";

interface IProps {
  code: string;
}

const SignUp = ({ code }: IProps): ReactElement => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const [isComplete, setIsComplete] = useState(false);

  const refreshPage = () => {
    window.location.reload();
  };

  const checkisValid =
    !formData.firstName ||
    !formData.lastName ||
    !formData.email ||
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email);

  const submitForm = useCallback(async () => {
    const { firstName, lastName, email } = formData;

    try {
      await registerInvite(code, firstName, lastName, email);
      setIsComplete(true);
    } catch (e) {
      errorToast({ message: "Something went wrong, please try again later." });
    }
  }, [formData, code]);

  if (isComplete) {
    return (
      <div css={styles.container}>
        <div className="signup-complete">
          <h1>Thank you for your registration</h1>
          <p>
            A team member will promptly confirm your registration and
            invitation, followed by a welcome email. We look forward to meeting
            you!
          </p>
        </div>
      </div>
    );
  }

  return (
    <div css={styles.container}>
      <div className="content">
        <h1>Create your account</h1>
        <h3>Please complete the form below to complete the sign up process</h3>
        <Form layout="vertical">
          <FloatingLabel label="First Name" value={formData.firstName}>
            <Form.Item>
              <input
                data-testid="first-name-input"
                type="text"
                onChange={(event) =>
                  setFormData({ ...formData, firstName: event.target.value })
                }
              />
            </Form.Item>
          </FloatingLabel>
          <FloatingLabel label="Last Name" value={formData.lastName}>
            <Form.Item>
              <input
                data-testid="last-name-input"
                type="text"
                onChange={(event) =>
                  setFormData({ ...formData, lastName: event.target.value })
                }
              />
            </Form.Item>
          </FloatingLabel>
          <FloatingLabel label="Email" value={formData.email}>
            <Form.Item>
              <input
                data-testid="email-input"
                type="email"
                onChange={(event) =>
                  setFormData({ ...formData, email: event.target.value })
                }
              />
            </Form.Item>
          </FloatingLabel>
          <Form.Item className="buttons">
            <Button
              onClick={refreshPage}
              className="cancel-button"
              variant="subtle"
              data-testid="cancel-btn"
            >
              Cancel
            </Button>
            <Button
              onClick={submitForm}
              disabled={checkisValid}
              variant="primary"
              data-testid="submit-btn"
            >
              Confirm
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SignUp;
