import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  container: css`
    padding: 0 1rem;

    .title-container {
      display: flex;
      h2 {
        color: ${theme.colors.white};
        font-family: ${theme.fonts.sofiaPro};
        font-weight: 400;
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 16px;
        line-height: 28px;
        flex: 1;

        @media ${queries.medium} {
          margin-top: 48px;
          margin-bottom: 24px;
        }
      }
      svg {
        fill: white;
        margin-top: 32px;
        cursor: pointer;

        @media ${queries.medium} {
          margin-top: 54px;
        }
      }
    }
    p {
      color: ${theme.colors.white};
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      white-space: pre-line;
    }

    hr {
      border: 1px solid ${theme.colors.background.veryDarkGray};
    }
    .animation {
      opacity: 0;
      height: 0;
      transition: ease 0.2s;
      &.visible {
        opacity: 1;
        height: auto;
      }
    }
  `,
};
