const getEmailValidationError = (email: string) => {
  const emailPattern = /^[a-zA-Z0-9.!#$%&’'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!emailPattern.test(email)) {
    switch (true) {
      case !/@/.test(email):
        return "Invalid email address (no '@' symbol)";
      case /^[^@]+@\./.test(email):
        return "Invalid email address (missing domain name after the '@' symbol)";
      default:
        return "Invalid email";
    }
  } else {
    return "";
  }
};

export default getEmailValidationError;
