import React, { ReactElement, useCallback } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import NewTest from "./components/new-test";
import styles from "./styles";
import ValidateHeader from "./components/validate-header";
import Measurement from "./components/measurement";

const Validate = (): ReactElement => {
  const history = useHistory();
  const { path } = useRouteMatch();

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <div css={styles.validateContainer}>
      <ValidateHeader onGoBack={handleGoBack} />
      <Switch>
        <Route exact path={path}>
          <Measurement />
        </Route>
        <Route path={`${path}/new-test`}>
          <NewTest />
        </Route>
      </Switch>
    </div>
  );
};

export default Validate;
