import { css } from "@emotion/react";
import theme from "src/theme";
import { queries } from "@songtradr/spa-common/lib/utils";

export default {
  modal: css`
    width: calc(100vw - 32px) !important;

    .ant-modal-content {
      border-radius: 4px;
      min-height: 210px;
      padding-right: 16px;
      padding-left: 16px;
      padding-bottom: 46px;

      @media ${queries.medium} {
        padding-right: 16px;
        padding-left: 16px;
        padding-bottom: 64px;
      }
    }
  `,
  sectionHeader: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 800;
    color: ${theme.colors.secondary.navyBlue};
    line-height: 28px;
  `,
  text: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400;
    line-height: 19px;
    margin-bottom: 20px;
  `,
  okButton: css`
    background-color: ${theme.colors.primary.blue};
    color: ${theme.colors.white};
    width: 70px;
    height: 40px;
    border-radius: 8px;
    float: right;
    margin-bottom: 20px;
    border: none !important;

    @media ${queries.medium} {
      height: 60px;
    }
  `,
};
