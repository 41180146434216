import React from "react";
import { Form, Typography } from "antd";
import styles from "src/components/modals/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@songtradr/component-library";
import { IConfirmContentProps } from "../../../interfaces";

const Content = ({
  modalTitle = "confirmation##defaultTitle",
  primaryButtonLabel = "defaultPrimaryButton",
  secondaryButtonLabel = "defaultSecondaryButton",
  mainContent,
  confirmAction,
  onClose,
}: IConfirmContentProps): JSX.Element => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  return (
    <div>
      <Typography.Title data-testid="modal-Title" css={styles.modalTitle}>
        {t(modalTitle)}
      </Typography.Title>
      <Form form={form} layout="vertical" onFinish={() => confirmAction()}>
        {mainContent}
        <div css={styles.footer}>
          <Button
            type="button"
            onClick={onClose}
            aria-label={secondaryButtonLabel}
            className="confirmationModalCancelButton"
          >
            {t(secondaryButtonLabel)}
          </Button>
          <Button
            variant="primary"
            aria-label={t(primaryButtonLabel)}
            className="confirmationModalConfirmButton"
          >
            {t(primaryButtonLabel)}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Content;
