import React, { ReactElement, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useOutsideClick } from "src/utils/hooks/use-outside-click";
import GridMenuIcon from "src/app/assets/icons/component-icons/grid-menu-icon";
import SearchIcon from "src/app/assets/icons/component-icons/navigation/search-icon";
import InsightIcon from "src/app/assets/icons/component-icons/navigation/insights-icon";
import ProjectsIcon from "src/app/assets/icons/component-icons/navigation/projects-icon";
import PlaylistsIcon from "src/app/assets/icons/component-icons/navigation/playlists-icon";
import ResourcesIcon from "src/app/assets/icons/component-icons/navigation/resources-icon";
import MeasurementIcon from "src/app/assets/icons/component-icons/navigation/measurement-icon";
import useNavItems from "src/utils/hooks/use-nav-items";
import AdaptiveMusicSmallIcon from "src/app/assets/icons/component-icons/adaptive-music-small";
import styles from "./styles";

const GridMenu = (
  props: React.HTMLAttributes<HTMLDivElement>
): ReactElement | null => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const navData = useNavItems();
  useOutsideClick(menuRef, () => setIsOpen(false));

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div css={styles.menuContainer} ref={menuRef} {...props}>
      <button
        type="button"
        css={styles.gridBtn}
        data-heapid="header-nav-grid-icon"
        data-testid="header-nav-grid-icon"
        onClick={toggleOpen}
      >
        <GridMenuIcon />
      </button>
      <div
        css={[styles.dropdownContainer, isOpen && styles.dropdownContainerOpen]}
      >
        <div css={styles.containerRow}>
          {navData.Search.hasPermission && (
            <a css={styles.navItem} {...navData.Search}>
              <SearchIcon css={styles.navIcon} />
              <p css={styles.navText}>Search</p>
            </a>
          )}

          {navData.Library.hasPermission && (
            <a css={styles.navItem} {...navData.Library}>
              <PlaylistsIcon css={styles.navIcon} />
              <p css={styles.navText}>Playlists</p>
            </a>
          )}

          {navData.Insights.hasPermission && (
            <a css={styles.navItem} {...navData.Insights}>
              <InsightIcon css={styles.navIcon} />
              <p css={styles.navText}>Insight</p>
            </a>
          )}
        </div>

        <div css={styles.containerRow}>
          {navData.Projects.hasPermission && (
            <Link
              css={styles.navItem}
              onClick={toggleOpen}
              {...navData.Projects}
            >
              <ProjectsIcon css={styles.navIcon} />
              <p css={styles.navText}>Projects</p>
            </Link>
          )}

          {navData.Resources.hasPermission && (
            <a css={styles.navItem} role="none" {...navData.Resources}>
              <ResourcesIcon css={styles.navIcon} />
              <p css={styles.navText}>Resources</p>
            </a>
          )}

          {navData.Measurement.hasPermission && (
            <Link
              css={styles.navItem}
              onClick={toggleOpen}
              {...navData.Measurement}
            >
              <MeasurementIcon css={styles.navIcon} />
              <p css={styles.navText}>Measurement</p>
            </Link>
          )}
        </div>

        <div css={styles.containerRow}>
          {navData.AdaptiveMusic.hasPermission && (
            <Link
              css={styles.navItem}
              onClick={toggleOpen}
              {...navData.AdaptiveMusic}
            >
              <AdaptiveMusicSmallIcon css={styles.navIcon} />
              <p css={styles.navText}>Adaptive Music</p>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default GridMenu;
