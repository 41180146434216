import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  header: css`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    justify-content: center;
  `,
  headerContent: css`
    width: 100%;
    align-items: center;
    height: 100%;
  `,
  mainHeader: css`
    display: flex;
    height: 100%;
    width: 100%;
    font-weight: 200;
    line-height: 3.5rem;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white} !important;
    font-size: 3rem;
    justify-content: space-between;
  `,
  backBtn: css`
    margin: 2rem 0;
  `,
  backBtnAndTitleCol: css`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  title: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 5.5rem;
    line-height: 6rem;
    font-weight: 700;
    letter-spacing: -2%;
    color: ${theme.colors.white};
  `,
  subtitles: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 1rem;
    align-items: center;
    color: ${theme.colors.white};

    div {
      display: flex;
      flex-direction: row;
      font-family: ${theme.fonts.sofiaPro};
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 300;
      span:first-of-type {
        font-weight: 700;
        margin-right: 0.5rem;
      }
    }
  `,
  tabs: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    text-wrap: nowrap;
    padding-top: 1rem;
    padding-bottom: 1rem;
    button {
      margin-right: 0.5rem;
    }
    color: ${theme.colors.white};
    position: sticky;
    top: 64px;
    background-color: ${theme.colors.background.landingDarkGray};
    z-index: 3;
  `,
  projectBackground: css`
    background-color: ${theme.colors.background.landingDarkGray};
    height: 100%;
  `,
  clientProjectContainer: css`
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    padding-bottom: 48px;
  `,
  projectTitle: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 800;
    letter-spacing: -2%;
    color: ${theme.colors.secondary.blueOnBlack};
    margin-bottom: 16px;
  `,
  projectClient: css`
    font-family: ${theme.fonts.sofiaPro};
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 800;
    letter-spacing: -2%;
    color: ${theme.colors.secondary.blueOnBlack};
    margin: 0;
  `,
  projectId: css`
    white-space: nowrap;
    font-weight: 400;
  `,
  sectionContainer: css`
    padding-bottom: 96px;
  `,
  sectionTitle: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.25rem;
    letter-spacing: -2%;
    color: ${theme.colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
  `,
  sectionSubtitle: css`
    @media ${queries.medium} {
      padding-left: 1rem;
      border-left: 1px solid ${theme.colors.darkCharcoal};
    }
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${theme.colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  secondaryTitle: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: ${theme.colors.white};
    margin-bottom: 8px;
    display: block;
  `,
  sectionMainText: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${theme.colors.white};
  `,
  segmentHeader: css`
    font-family: ${theme.fonts.proximaNova};
    color: ${theme.colors.background.darkGray};
    margin-bottom: 0.5rem;
    margin-left: 0.1rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 0.8rem;
  `,
  emptyProjectText: css`
    font-family: ${theme.fonts.proximaNova};
    font-weight: 500;
    margin-top: 1rem;
  `,
  noDataContainer: css`
    display: flex;
    flex-direction: column;
    padding: 3rem;
    max-width: 1800px;
    background-color: ${theme.colors.white};
  `,
  tableTitle: css`
    text-transform: uppercase;
    font-family: ${theme.fonts.sofiaPro};
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
    color: ${theme.colors.secondary.waveformGray};
    margin-bottom: 24px;
    letter-spacing: 3.3px;
  `,
  tableColumnHeader: css`
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
    }
  `,
  splitTableView: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
    > div {
      display: flex;
      flex-direction: column;
      flex: 1;
      max-width: 100%;
      min-width: 40%;
    }

    @media ${queries.medium} {
      flex-direction: row;
    }
  `,
  titleAndSubtitle: css`
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 32px;
  `,
};
