import React, { Fragment } from "react";
import { format, parseISO } from "date-fns";
import { Dropdown, Menu, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { FilePdfFilled } from "@ant-design/icons";
import { errorToast, successToast } from "src/components/toast-notification";
import useAuth from "src/auth/use-auth";
import getMusicUsageDeclarationReport from "src/api/music-usage-declaration-report";
import TableMenu from "src/app/assets/icons/component-icons/buttons/table-menu";
import ControlButton from "src/components/control-buttons/base-button";
import style from "../styles";
import {
  ILibraryTrackDataSource,
  IProjectLibraryTrackDetails,
} from "../interfaces";

export enum ChannelType {
  Youtube = "Youtube",
  Facebook = "Facebook",
  Instagram = "Instagram",
  Twitter = "Twitter",
  TikTok = "TikTok",
  CompanyWebsite = "CompanyWebsite",
  Other = "Other",
}

export default (
  libraryTracks: IProjectLibraryTrackDetails[]
): ILibraryTrackDataSource[] => {
  return libraryTracks.map(
    ({
      id,
      trackId,
      trackTitle,
      trackArtist,
      downloadedByUser,
      declaredByUser,
      createdDate,
      projectName,
      projectDescription,
      businessBrand,
      regions,
      channels,
      goLiveDate,
      projectId,
    }) => {
      const { getAccessToken, organisationId } = useAuth();
      const channelsByType: {
        [key: string]: string[];
      } = {
        youtube: [],
        facebook: [],
        instagram: [],
        twitter: [],
        tikTok: [],
        companyWebsite: [],
        other: [],
        videoLinks: (channels ?? [])?.[0]?.usageLinks ?? [],
      };

      channels.forEach((channel) => {
        switch (channel.type) {
          case ChannelType.Youtube:
            channelsByType.youtube.push(channel.name);
            break;
          case ChannelType.Facebook:
            channelsByType.facebook.push(channel.name);
            break;
          case ChannelType.Instagram:
            channelsByType.instagram.push(channel.name);
            break;
          case ChannelType.Twitter:
            channelsByType.twitter.push(channel.name);
            break;
          case ChannelType.TikTok:
            channelsByType.tikTok.push(channel.name);
            break;
          case ChannelType.CompanyWebsite:
            channelsByType.companyWebsite.push(channel.id);
            break;
          case ChannelType.Other:
            channelsByType.other.push(channel.id);
            break;
          default:
            break;
        }
      });
      const territories = (regions ?? []).join(", ");
      const youtubeChannels = channelsByType.youtube.join(", ");
      const facebookPages = channelsByType.facebook.join(", ");
      const instagramAccounts = channelsByType.instagram.join(", ");
      const twitterAccounts = channelsByType.twitter.join(", ");
      const tikTokAccounts = channelsByType.tikTok.join(", ");
      const companyWebsite = channelsByType.companyWebsite.join(", ");
      const otherChannels = channelsByType.other.join(", ");
      const videoLinks = channelsByType.videoLinks.join(", ");

      const DropdownMenu = () => {
        const { t } = useTranslation();
        const menuItem = (
          <Menu css={style.dropdownMenu}>
            <Menu.Item key="0">
              <button
                type="button"
                css={style.actionButton}
                aria-label={t("Download license PDF")}
                onClick={async (
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.stopPropagation();
                  try {
                    successToast({
                      message: t("File downloading to your file storage"),
                      autoClose: 3500,
                      closeButton: true,
                    });
                    const musicUsageDeclarationResponse = await getMusicUsageDeclarationReport(
                      projectId,
                      id,
                      getAccessToken(),
                      organisationId
                    );
                    saveAs(
                      musicUsageDeclarationResponse,
                      `Music Usage Declaration - ${id}.pdf`
                    );
                  } catch {
                    errorToast({
                      message: t("There was a problem downloading the file"),
                      autoClose: 3500,
                      closeButton: true,
                    });
                  }
                }}
              >
                <FilePdfFilled css={style.mr5} />
                {t("Download license PDF")}
              </button>
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown
            overlay={menuItem}
            trigger={["click"]}
            placement="bottomRight"
          >
            <ControlButton
              className="table-menu-button"
              css={style.moreButton}
              showTooltip={false}
              label=""
              data-testid="table-more-button"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <TableMenu />
            </ControlButton>
          </Dropdown>
        );
      };

      return {
        key: id,
        trackId: (
          <Tooltip placement="topLeft" title={trackId ?? ""}>
            <div css={style.tableDataText}>{trackId ?? ""}</div>
          </Tooltip>
        ),
        trackTitle: (
          <Tooltip placement="topLeft" title={trackTitle ?? ""}>
            <div css={style.tableDataText}>{trackTitle ?? ""}</div>
          </Tooltip>
        ),
        trackArtist: (
          <Tooltip placement="topLeft" title={trackArtist ?? ""}>
            <div css={style.tableDataText}>{trackArtist ?? ""}</div>
          </Tooltip>
        ),
        downloadedBy: (
          <Fragment>
            <Tooltip placement="topLeft" title={downloadedByUser.name ?? ""}>
              <div css={style.tableDataText}>{downloadedByUser.name ?? ""}</div>
            </Tooltip>
            <Tooltip placement="topLeft" title={downloadedByUser.email ?? ""}>
              <div css={style.tableDataSubText}>
                {downloadedByUser.email ?? ""}
              </div>
            </Tooltip>
          </Fragment>
        ),
        declaredBy: (
          <Fragment>
            <Tooltip placement="topLeft" title={declaredByUser.email ?? ""}>
              <div css={style.tableDataText}>{declaredByUser.name ?? ""}</div>
            </Tooltip>
            <Tooltip placement="topLeft" title={declaredByUser.email ?? ""}>
              <div css={style.tableDataSubText}>
                {declaredByUser.email ?? ""}
              </div>
            </Tooltip>
          </Fragment>
        ),
        createdDate: (
          <div css={style.tableDataText}>
            {createdDate && format(parseISO(createdDate), "MMM do, yyyy")}
          </div>
        ),
        projectName: (
          <Tooltip placement="topLeft" title={projectName ?? ""}>
            <div css={style.tableDataText}>{projectName ?? ""}</div>
          </Tooltip>
        ),
        projectDescription: (
          <Tooltip placement="topLeft" title={projectDescription ?? ""}>
            <div css={style.tableDataText}>{projectDescription ?? ""}</div>
          </Tooltip>
        ),
        businessBrand: (
          <Tooltip placement="topLeft" title={businessBrand ?? ""}>
            <div css={style.tableDataText}>{businessBrand ?? ""}</div>
          </Tooltip>
        ),
        territories: (
          <Tooltip placement="topLeft" title={territories}>
            <div css={style.tableDataSubText}>{territories}</div>
          </Tooltip>
        ),
        goLiveDate: (
          <div css={style.tableDataText}>
            {goLiveDate && format(parseISO(goLiveDate), "MMM do, yyyy")}
          </div>
        ),
        youtubeChannels: (
          <Tooltip placement="topLeft" title={youtubeChannels}>
            <div css={style.tableDataSubText}>{youtubeChannels}</div>
          </Tooltip>
        ),
        facebookPages: (
          <Tooltip placement="topLeft" title={facebookPages}>
            <div css={style.tableDataSubText}>{facebookPages}</div>
          </Tooltip>
        ),
        instagramPages: (
          <Tooltip placement="topLeft" title={instagramAccounts}>
            <div css={style.tableDataSubText}>{instagramAccounts}</div>
          </Tooltip>
        ),
        tikTokAccounts: (
          <Tooltip placement="topLeft" title={tikTokAccounts}>
            <div css={style.tableDataSubText}>{tikTokAccounts}</div>
          </Tooltip>
        ),
        twitterAccounts: (
          <Tooltip placement="topLeft" title={twitterAccounts}>
            <div css={style.tableDataSubText}>{twitterAccounts}</div>
          </Tooltip>
        ),
        companyWebsite: (
          <Tooltip placement="topLeft" title={companyWebsite}>
            <div css={style.tableDataSubText}>{companyWebsite}</div>
          </Tooltip>
        ),
        other: (
          <Tooltip placement="topLeft" title={otherChannels}>
            <div css={style.tableDataSubText}>{otherChannels}</div>
          </Tooltip>
        ),
        videoLinks: (
          <Tooltip placement="topLeft" title={videoLinks}>
            <div css={style.tableDataSubText}>{videoLinks}</div>
          </Tooltip>
        ),
        menuDropDown: <DropdownMenu />,
      };
    }
  );
};
