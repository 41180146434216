import React, { ReactElement, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import useAuth from "src/auth/use-auth";
import {
  TGenericAuditFormShape,
  TVideosToProcess,
} from "src/pages/brand-audit/components/brand-audit-form/interfaces";
import { createNewAudit } from "src/api/brand-audit";
import axios, { AxiosError } from "axios";
import { errorToast, successToast } from "src/components/toast-notification";
import BrandAuditForm from "src/pages/brand-audit/components/brand-audit-form";

const BrandAuditNew = (): ReactElement | null => {
  const history = useHistory();
  const abortController = useRef<AbortController>();
  const { getAccessToken, user } = useAuth();

  useEffect(() => {
    abortController.current = new AbortController();
    return () => abortController.current?.abort();
  }, []);

  const handleFormSubmit = async (
    formData: TGenericAuditFormShape,
    videosToProcess: TVideosToProcess
  ) => {
    if (!abortController.current) {
      return;
    }

    const payload = {
      ...formData,
      userName: user?.name,
      files: Object.values(videosToProcess),
    };

    try {
      await createNewAudit(getAccessToken(), abortController.current, payload);
    } catch (error) {
      if (axios.isAxiosError(error) && error.code === AxiosError.ERR_CANCELED) {
        return;
      }

      errorToast({ message: "There was an issue creating the brand audit" });

      // eslint-disable-next-line no-console
      console.error(error);

      // Re-throw so we can reset the loading state of the form
      throw error;
    }

    successToast({ message: "Brand audit was successfully created" });
    history.push("/admin/brand-audit");
  };

  return <BrandAuditForm handleFormSubmit={handleFormSubmit} />;
};

export default BrandAuditNew;
