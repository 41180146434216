import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  validateContainer: css`
    background: ${theme.colors.background.landingDarkGray};
  `,
  validateContent: css`
    margin: 0 auto;
    width: 100%;

    @media ${queries.large} {
      max-width: 1552px;
      margin: 32px auto;
    }
  `,
  backBtnContainer: css`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    padding-top: 32px;

    :hover {
      path {
        fill: ${theme.colors.functional.brightOrange};
      }
    }
  `,
  backBtn: css`
    font-family: ${theme.fonts.sofiaPro};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background: transparent;
    margin-right: 4px;
    border: none;
    cursor: pointer;
    color: ${theme.colors.white};
    padding: 0;

    svg {
      margin-right: 3px;
    }
  `,
  backText: css`
    font-size: 0.875rem;
    font-weight: 400;
    font-family: ${theme.fonts.sofiaPro};
    background: none;
    color: white;
    border: none;
    cursor: pointer;

    :hover {
      color: white;
    }
  `,
  animationBg: css`
    position: absolute;
    right: 0;
  `,
  tabs: css`
    .ant-tabs-nav::before {
      display: none;
    }
    .ant-tabs-nav-wrap {
      background: none;
      box-shadow: none;
      padding-left: 0 !important;
      justify-content: center;
      margin-bottom: 48px;

      @media ${queries.medium} {
        padding-left: 3rem !important;
        margin-bottom: 64px;
      }
    }
    .ant-tabs-tab-active .ant-tabs-tab-btn {
      color: ${theme.colors.white} !important;
      font-weight: 250 !important;
    }
    .ant-tabs-tab {
      color: ${theme.colors.secondary.textSecondary};
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: -2%;
      font-family: ${theme.fonts.sofiaPro};
      font-weight: 250;

      @media ${queries.medium} {
        font-size: 1.5rem;
        line-height: 2rem;
      }

      &:hover,
      .ant-tabs-tab-btn:hover {
        color: ${theme.colors.white} !important;
      }
    }
    .ant-tabs-ink-bar {
      background: ${theme.colors.functional.brightOrange} !important;
      height: 1px !important;
    }
  `,
  testResultsTable: css`
    margin: 1.5rem 0 5rem 0;
  `,
  tableHeaderContainer: css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  secondaryHeader: css`
    letter-spacing: -0.02em;
    font-weight: 250;
    color: ${theme.colors.white};
    font-family: ${theme.fonts.sofiaPro};
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 0;
    font-size: 1.5rem;
    line-height: 2rem;

    @media ${queries.medium} {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  `,
};
