import React, { Fragment } from "react";
import { IOrganisationMemberDataSource } from "src/components/table/interfaces";
import { eApplicationType } from "src/interfaces/auth";
import { Checkbox } from "@songtradr/component-library";
import { ConditionallyVisible } from "src/sumo/src/components/visibility";
import { Dropdown, Menu } from "antd";
import { format } from "date-fns";
import style from "../styles";
import { IOrganisationMemberProps } from "../interfaces";
import MoreInfoButton from "../components/more-info-button";
import PermissionCheckboxWithTooltip from "./permission-checkbox-with-tooltip";

export interface IOption {
  value: string;
  label: string;
  disabled?: boolean;
}

const permissionToModuleMap = {
  [eApplicationType.LibraryReadOnly]: "Library (read only)",
  [eApplicationType.LibraryFull]: "Library (full)",
  [eApplicationType.ProjectsClient]: "Projects (client)",
  [eApplicationType.ProjectsInternal]: "Projects (internal)",
  [eApplicationType.Insights]: "Insights",
  [eApplicationType.Resources]: "Resources",
  [eApplicationType.Validate]: "Validate",
  [eApplicationType.Portal]: "Portal",
  [eApplicationType.TeamManagement]: "Team Management",
};

export const teamMemberData = (
  teamMembers: Array<IOrganisationMemberProps>,
  isMobile: boolean,
  handleRowClick: (key: string) => void,
  handleDeleteOrgMemberClick: (orgMember: IOrganisationMemberProps) => void,
  handleAddApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>,
  handleRemoveApplication: (
    selectedOrgMember: IOrganisationMemberProps,
    application: eApplicationType
  ) => Promise<void>,
  handleAddAndRemoveApplication: (
    member: IOrganisationMemberProps,
    addApplication: eApplicationType,
    removeApplication: eApplicationType
  ) => Promise<void>,
  allApplications: eApplicationType[]
): Array<IOrganisationMemberDataSource> => {
  const getTooltipTextForPermission = (permission: eApplicationType) => {
    // If the org has access to the permission, don't show the tooltip
    if (allApplications.includes(permission)) {
      return "";
    }

    return `This org does not have the '${permissionToModuleMap[permission]}' module enabled`;
  };

  return teamMembers?.map((orgMember: IOrganisationMemberProps) => {
    const {
      key,
      id,
      firstName,
      lastName,
      email,
      isCurrentUser,
      applications,
      controllingOrgId,
      audit,
    } = orgMember;

    const isPermissionCheckboxDisabled = (permission: eApplicationType) => {
      return (
        !allApplications.includes(permission) ||
        applications.includes(eApplicationType.TeamManagement)
      );
    };

    const dateAdded = format(new Date(audit[0].dateTime), "do MMM y");

    const handleCheckboxClick = async (permission: eApplicationType) => {
      if (applications.includes(permission)) {
        await handleRemoveApplication(orgMember, permission);
        return;
      }
      if (
        permission === eApplicationType.LibraryFull &&
        applications.includes(eApplicationType.LibraryReadOnly)
      ) {
        await handleAddAndRemoveApplication(
          orgMember,
          permission,
          eApplicationType.LibraryReadOnly
        );
        return;
      }
      if (
        permission === eApplicationType.LibraryReadOnly &&
        applications.includes(eApplicationType.LibraryFull)
      ) {
        await handleAddAndRemoveApplication(
          orgMember,
          permission,
          eApplicationType.LibraryFull
        );
        return;
      }

      await handleAddApplication(orgMember, permission);
    };

    const isPermissionChecked = (permission: eApplicationType) => {
      return (
        applications.includes(permission) ||
        applications.includes(eApplicationType.TeamManagement)
      );
    };

    const getDisabledStylesForPermissionCheckbox = (
      permission: eApplicationType
    ) => {
      return isPermissionCheckboxDisabled(permission)
        ? style.disabledBtn
        : null;
    };

    return {
      key,
      id,
      name: (
        <Fragment>
          <div css={style.nameAndPill}>
            <div css={style.teamName}>
              {firstName} {lastName}
            </div>
            <ConditionallyVisible condition={controllingOrgId !== undefined}>
              <div css={style.agencyPill}>Agency</div>
            </ConditionallyVisible>
          </div>
          <div css={style.teamEmail}>{email}</div>
        </Fragment>
      ),
      dateAdded: <div css={style.teamEnterpriseAdminCol}>{dateAdded}</div>,
      isAdmin: (
        <div css={style.teamEnterpriseAdminCol}>
          <Checkbox
            disabled={isCurrentUser}
            checked={applications.includes(eApplicationType.TeamManagement)}
            onClick={async (e: any) => {
              e.stopPropagation();
              await handleCheckboxClick(eApplicationType.TeamManagement);
            }}
            data-testid="admin-checkbox"
          />
        </div>
      ),
      ...[
        {
          columnKey: "hasMusicSubscription",
          permission: eApplicationType.LibraryFull,
          dataTestId: "music-admin-checkbox",
        },
        {
          columnKey: "hasReadOnlyLibraryPermissions",
          permission: eApplicationType.LibraryReadOnly,
          dataTestId: "music-read-only-admin-checkbox",
        },
        {
          columnKey: "hasProjectsSubscription",
          permission: eApplicationType.ProjectsClient,
          dataTestId: "projects-admin-checkbox",
        },
        {
          columnKey: "hasInsightsPermissions",
          permission: eApplicationType.Insights,
          dataTestId: "insights-admin-checkbox",
        },
        {
          columnKey: "hasMyContentPermissions",
          permission: eApplicationType.Resources,
          dataTestId: "assets-admin-checkbox",
        },
        {
          columnKey: "hasValidatePermissions",
          permission: eApplicationType.Validate,
          dataTestId: "validate-admin-checkbox",
        },
      ]
        .map(({ permission, dataTestId, columnKey }) => {
          return {
            columnKey,
            columnValue: (
              <PermissionCheckboxWithTooltip
                permission={permission}
                tooltipText={getTooltipTextForPermission(permission)}
                cssStyle={getDisabledStylesForPermissionCheckbox(permission)}
                disabled={isPermissionCheckboxDisabled(permission)}
                checked={isPermissionChecked(permission)}
                dataTestId={dataTestId}
                onClick={handleCheckboxClick}
              />
            ),
          };
        })
        .reduce(
          (prevData, currData) => ({
            ...prevData,
            [currData.columnKey]: currData.columnValue,
          }),
          {}
        ),
      ...(!isCurrentUser && {
        delete: (
          <Dropdown
            aria-label="action-items-dropdown"
            overlay={
              <Menu
                css={style.dropdownMenu}
                data-testid="action-items-dropdown"
              >
                <Menu.Item key="0">
                  <button onClick={() => handleRowClick(id)} type="button">
                    View
                  </button>
                </Menu.Item>
                <Menu.Item key="1">
                  <button
                    onClick={() => handleDeleteOrgMemberClick(orgMember)}
                    type="button"
                    aria-label="Delete"
                  >
                    Delete
                  </button>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            getPopupContainer={(triggerNode) => triggerNode}
            placement="bottomRight"
          >
            <MoreInfoButton
              className="more-info-button"
              showTooltip
              customStyles={style.moreInfoButton}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
              }}
            />
          </Dropdown>
        ),
      }),
    };
  });
};
