import React, { ReactElement, useEffect, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
  useLocation,
} from "react-router-dom";
import { Layout } from "antd";
import Team from "src/pages/team";
import Profile from "src/pages/profile/container";
import ResetPassword from "src/pages/reset-password";
import Projects, { ProjectListingType } from "src/pages/projects";
import Login from "src/pages/login";
import ProjectsProvider from "src/providers/project/state/provider";
import ClientProjectView from "src/pages/projects/client-project-view";
import NavigationBar from "src/components/navigation-bar";
import RestrictedRoute, { RestrictionType } from "src/auth/restricted-route";
import { ToastContainer } from "src/components/toast-notification";
import SelectOrg from "src/pages/select-org";
import Validate from "src/pages/validate";
import UserProfileContainer from "src/pages/user-profile/container";
import CaseStudy from "src/pages/validate/components/case-study";
import Heap from "src/components/heap";
import useOrganisation from "src/providers/organisation/hooks";
import "@songtradr/component-library/theme-light.css";
import { Theme, ScopedTheme, GlobalTheme } from "@songtradr/component-library";
import { StorageKeys } from "src/providers/feature-flags/state/utils";
import Stonly from "src/utils/stonly";
import OrgDashboard from "src/pages/org-dashboard";
import CreateNewOrg from "src/pages/create-new-org";
import BrandAudit from "src/pages/brand-audit";
import FeatureFlagsProvider from "src/providers/feature-flags/state/provider";
import CodePage from "src/pages/code";
import PowerBiProvider from "src/providers/power-bi/state/provider";
import ForgotPassword from "src/pages/forgot-password";
import AdaptiveMusic from "src/pages/adaptive-music";
import STLoadingLogo from "src/components/st-loading-logo";
import Landing from "src/pages/landing";
import config from "src/config";
import { buildEnvUrl } from "src/config/config";
import MobileWarnModal from "./mobile-warning-modal";
import useAuth from "../auth/use-auth";
import NotFound from "../pages/not-found";
import styles from "./styles";

const customTheme: Theme = {};

interface IMatchParams {
  organisationId: string;
  organisationInviteId: string;
}

interface IRedirectParam {
  redirectUrl?: string;
}

function App(): ReactElement {
  // const isMobile = !window.matchMedia(queries.medium).matches;
  const { logout, user } = useAuth();
  const { organisation } = useOrganisation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Setup Runtime Config Values
    config.player.url = buildEnvUrl(
      config.player.subdomainPrefix,
      config.player.baseUrl
    );
    config.assetsSpa.url = buildEnvUrl(
      config.assetsSpa.subdomainPrefix,
      config.assetsSpa.baseUrl
    );
  }, []);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const isCSCMode = urlSearchParams.get(StorageKeys.CSCMODE);

    if (isCSCMode && ["on", "off"].includes(isCSCMode)) {
      localStorage.setItem(StorageKeys.CSCMODE, isCSCMode);
    }
  }, []);

  if (location.pathname === "/sign-out") {
    logout();
    return <STLoadingLogo />;
  }

  const RoutesWithNavigationBar = () => {
    return (
      <>
        <PowerBiProvider>
          <ScopedTheme
            mode="dark"
            css={styles.navContainer}
            data-testid="nav-container"
          >
            <div css={styles.navPage} data-testid="nav-page">
              <Switch>
                <RestrictedRoute
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                  exact
                  path="/"
                >
                  <Redirect to="/home" />
                </RestrictedRoute>
                <RestrictedRoute
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                  exact
                  path="/home"
                >
                  <Landing />
                </RestrictedRoute>
                <RestrictedRoute
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                  exact
                  path="/team"
                >
                  <MobileWarnModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                  />
                  <Team />
                </RestrictedRoute>
                <Route exact path="/reset-password">
                  <ResetPassword />
                </Route>
                <RestrictedRoute
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                  exact
                  path="/profile"
                >
                  <MobileWarnModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                  />
                  <Profile />
                </RestrictedRoute>
                <RestrictedRoute
                  path="/measurement"
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                >
                  <Validate />
                </RestrictedRoute>
                <RestrictedRoute
                  path="/adaptivemusic"
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                >
                  <MobileWarnModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                  />
                  <AdaptiveMusic />
                </RestrictedRoute>
                <RestrictedRoute
                  path="/study/:caseStudy"
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                >
                  <CaseStudy />
                </RestrictedRoute>
                <RestrictedRoute
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                  path="/commerciallicenses"
                >
                  <MobileWarnModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                  />
                  <Projects
                    projectType={ProjectListingType.CommercialLicenses}
                  />
                </RestrictedRoute>
                <RestrictedRoute
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                  exact
                  path="/commerciallicense/:id"
                >
                  <ProjectsProvider>
                    <ClientProjectView />
                  </ProjectsProvider>
                </RestrictedRoute>
                <RestrictedRoute
                  restriction={RestrictionType.AUTHENTICATED_ONLY}
                  path="/librarylicenses"
                >
                  <MobileWarnModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                  />
                  <Projects projectType={ProjectListingType.LibraryLicenses} />
                </RestrictedRoute>
                <RestrictedRoute
                  restriction={RestrictionType.SUPER_ADMIN_ONLY}
                  exact
                  path="/admin/brand-audit/:subRoute?/:tertiaryRoute?"
                >
                  <MobileWarnModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                  />
                  <BrandAudit />
                </RestrictedRoute>
                <RestrictedRoute
                  restriction={RestrictionType.SUPER_ADMIN_ONLY}
                  exact
                  path="/admin/organization-dashboard"
                >
                  <MobileWarnModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                  />
                  <OrgDashboard />
                </RestrictedRoute>
                <RestrictedRoute
                  restriction={RestrictionType.SUPER_ADMIN_ONLY}
                  exact
                  path="/admin/create-new-organization"
                >
                  <MobileWarnModal
                    isOpen={isModalOpen}
                    setIsOpen={setIsModalOpen}
                  />
                  <CreateNewOrg />
                </RestrictedRoute>
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </div>
          </ScopedTheme>
        </PowerBiProvider>
      </>
    );
  };

  return (
    <GlobalTheme {...customTheme} mode="light">
      <div className="app" css={styles.app}>
        <Heap
          userId={user?.id}
          email={user?.email}
          orgName={organisation?.name}
          orgId={organisation?.id}
        />
        <Stonly
          userDetails={user}
          orgName={organisation?.name}
          orgId={organisation?.id}
        />
        <Layout>
          <FeatureFlagsProvider>
            <ToastContainer theme="light" />
            <NavigationBar />
            <Layout.Content css={styles.pageContainer}>
              <Switch>
                <Route exact path="/index.html">
                  <Redirect to="/" />
                </Route>
                <Route path="/login/:redirectUrl?">
                  {({ match }: RouteComponentProps<IRedirectParam>) => (
                    <Login
                      redirectUrl={match.params.redirectUrl || undefined}
                    />
                  )}
                </Route>
                <Route exact path="/code">
                  <CodePage />
                </Route>
                <Route exact path="/forgot-password">
                  <ForgotPassword />
                </Route>
                <RestrictedRoute
                  restriction={
                    RestrictionType.AUTHENTICATED_WITHOUT_ORGANISATION
                  }
                  exact
                  path="/select-org"
                >
                  <SelectOrg />
                </RestrictedRoute>
                <Route
                  exact
                  path="/organisations/:organisationId/invites/:organisationInviteId"
                >
                  {({ match }: RouteComponentProps<IMatchParams>) => (
                    <UserProfileContainer
                      organisationId={match.params.organisationId}
                      organisationInviteId={match.params.organisationInviteId}
                    />
                  )}
                </Route>
                <Route render={RoutesWithNavigationBar} />
              </Switch>
            </Layout.Content>
          </FeatureFlagsProvider>
        </Layout>
      </div>
    </GlobalTheme>
  );
}

export default App;
