import { IOrganisation } from "src/interfaces/organisation";

const setOrgDefaults = (data: IOrganisation) => {
  return {
    ...data,
    naturalLanguageSearchVersion:
      data.naturalLanguageSearchVersion ?? "Internal",
  };
};

export default setOrgDefaults;
