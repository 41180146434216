import { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

export const modalCssId = "modal-portal";

interface IModalPortalProps {
  children?: ReactNode;
}

const ModalPortal = ({ children }: IModalPortalProps) => {
  const portalElementRef = useRef<any>(null);
  const appCssId = "root"; // TODO: Maybe export to an env var - will need to be set/managed at a product level

  useEffect(() => {
    const appElement = document.querySelector(`#${appCssId}`);
    appElement?.appendChild(portalElementRef.current);

    return () => portalElementRef.current.remove();
  }, []);

  const getPortalElement = () => {
    if (portalElementRef.current) {
      return portalElementRef.current;
    }

    const modalElement = document.getElementById(modalCssId);

    if (modalElement) {
      portalElementRef.current = modalElement;
      return portalElementRef.current;
    }

    portalElementRef.current = document.createElement("div");
    portalElementRef.current.setAttribute("id", modalCssId);

    return portalElementRef.current;
  };

  return createPortal(children, getPortalElement());
};

export default ModalPortal;
