import React from "react";
import { ScopedTheme } from "@songtradr/component-library";
import appStyles from "src/app/styles";
import useOrganisation from "src/providers/organisation/hooks";
import useAuth from "src/auth/use-auth";
import ProfileAndContactSection from "./components/profile-and-contact-section";
import styles from "./styles";
import LandingBackground from "./components/landing-background";
import LandingHeading from "./components/landing-heading";
import ModulesSection from "./components/modules-section";
import ServicesSection from "./components/services-section";

const Landing = () => {
  const { organisation } = useOrganisation();
  const { user } = useAuth();
  let userFirstName;
  if (user?.firstName) {
    userFirstName = user.firstName;
  } else {
    userFirstName = user?.name?.split(" ")[0];
  }

  return (
    <ScopedTheme mode="dark" css={styles.background}>
      <LandingBackground url={organisation?.backgroundImage} />
      <div css={appStyles.container}>
        <LandingHeading
          logoUrl={organisation?.primaryLogo}
          userName={userFirstName || ""}
        />
        <ProfileAndContactSection />
        <ModulesSection />
        <ServicesSection />
      </div>
    </ScopedTheme>
  );
};

export default Landing;
