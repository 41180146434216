import React, { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "../interfaces";

const PauseButtonIcon = ({
  height = theme.svgSize.xlarge,
  width = theme.svgSize.xlarge,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="white" />
      <path
        d="M19.5 17V31"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M28.5 17V31"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default PauseButtonIcon;
