import React from "react";
import { IUserPermissions } from "src/auth/access-token-utils";
import { ISimpleUser } from "src/interfaces/auth";
import { IAuthenticatedUser } from "src/auth/interfaces";

export interface ICognitoContext {
  handleLogin: (
    username: string,
    password: string,
    redirectUrl?: string,
    afterLoginFailure?: (error: string) => void
  ) => void;
  login: (target?: string) => void;
  logout: () => void;
  isSessionValid: () => Promise<boolean>;
  isAuthenticated: boolean;
  getAccessToken: () => string;
  isLoading: boolean;
  user?: IAuthenticatedUser;
  users?: ISimpleUser[];
  isOrgAdmin: boolean;
  organisationId: string;
  userPermissions?: IUserPermissions;
  resetPassword: (email: string) => void;
  isPasswordReset: boolean;
  confirmResetVerificationCode: (
    verificationCode: string,
    newPassword: string,
    email: string
  ) => void;
  changePassword: (
    verificationCode: string,
    newPassword: string,
    email: string,
    successCallback?: () => void
  ) => void;
  error?: {
    error: string;
    error_description: string;
  } | null;
  setOrganisationId: (orgId: string) => void;
  isSuperAdmin: boolean;
  isBlessedSuperAdmin: boolean;
  switchOrg: (orgId: string) => Promise<void>;
  hasMultipleOrgs: boolean;
  profileImage?: string;
  dismissError?: () => void;
}

export default React.createContext<ICognitoContext>({
  login: () => {},
  logout: () => {},
  handleLogin: () => {},
  isSessionValid: () => Promise.resolve(false),
  users: [],
  isAuthenticated: false,
  userPermissions: {
    canAccessPortal: false,
    canAccessInsights: false,
    canAccessMyContent: false,
    canAccessValidate: false,
    canAccessTeams: false,
    canAccessProjects: false,
    canAccessMusicSubscription: false,
  },
  isLoading: true,
  isOrgAdmin: false,
  resetPassword: () => {},
  changePassword: () => {},
  isPasswordReset: false,
  confirmResetVerificationCode: () => {},
  error: null,
  organisationId: "",
  getAccessToken: () => "",
  setOrganisationId: () => {},
  isSuperAdmin: false,
  isBlessedSuperAdmin: false,
  switchOrg: async () => {},
  hasMultipleOrgs: false,
});
