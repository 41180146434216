import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  serviceTileContainer: css`
    background-color: transparent;
    display: flex;
    flex-direction: column;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
    &:hover {
      cursor: pointer;
    }
    a {
      text-decoration: none;
      color: ${theme.colors.white};
    }
  `,
  image: css`
    width: 100%;
    border-radius: 8px;
    margin-bottom: 16px;
  `,
  title: css`
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -2%;
    font-weight: 700;
    margin-bottom: 8px;
  `,
  description: css`
    font-size: 16px;
    line-height: 24px;
    font-weight: 300;
    display: -webkit-box;
  `,
};
