import { useHistory } from "react-router-dom";
import ArrowLeft from "src/sumo/src/app/assets/icons/component/arrow-left";
import theme from "src/theme";
import styles from "./styles";
import { IProps } from "./interfaces";

export const BackBtn = ({ onClick, className }: IProps) => {
  const history = useHistory();

  const buttonOnClick = () => {
    if (onClick) {
      onClick();
      return;
    }

    if (history.length) {
      history.goBack();
      return;
    }

    history.replace("/home");
  };

  return (
    <button
      css={styles.buttonContainer}
      onClick={buttonOnClick}
      data-testid="back-button"
      data-heapid="Back"
      type="button"
      className={className}
    >
      <div css={styles.span}>
        <ArrowLeft fill={theme.colors.white} />
      </div>
      <div className="buttonText">Back</div>
    </button>
  );
};

export default BackBtn;
