export interface ICaseStudyContent {
  title: string;
  description: string;
  link: string;
  linkText: string;
  imageSrc: string;
}

const caseStudyContent = [
  {
    title: "Nutella",
    description:
      "With sonic branding, we help brands and agencies cut through the noise – from concept to implementation, Abba to Zappa, zero to hero.",
    link: "",
    linkText: "Coming soon",
    imageSrc: "images/nutella-card.jpg",
  },
  {
    title: "Asahi",
    description:
      "With sonic branding, we help brands and agencies cut through the noise – from concept to implementation, Abba to Zappa, zero to hero.",
    link: "",
    linkText: "Coming soon",
    imageSrc: "images/asahi-card.jpg",
  },
  {
    title: "Lady Speed Stick",
    description:
      "With sonic branding, we help brands and agencies cut through the noise – from concept to implementation, Abba to Zappa, zero to hero.",
    link: "",
    linkText: "Coming soon",
    imageSrc: "images/lady-speed-stick-card.jpg",
  },
  {
    title: "Zalando",
    description:
      "With sonic branding, we help brands and agencies cut through the noise – from concept to implementation, Abba to Zappa, zero to hero.",
    link: "/study/zalando",
    linkText: "Read case study",
    imageSrc: "images/zalando-card.jpeg",
  },
];

export default caseStudyContent;
