import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common/lib/utils";
import "../../theme/index.css";
import theme from "src/theme";

export default {
  app: css`
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    @media ${queries.large} {
      background: transparent;
      padding-bottom: 0;
    }
  `,
  projectContainer: css`
    width: 100%;
    background-color: ${theme.colors.background.landingDarkGray};
  `,
  navContainer: css`
    width: 100%;

    @media ${queries.large} {
      margin-top: 0;
    }
  `,
  navPage: css`
    position: relative;
    min-height: 100%;
    background: ${theme.colors.background.landingDarkGray};
    width: 100%;
    height: 100%;
  `,
  pageContainer: css`
    background: var(--colors-landing-gradient-gray);
    @media ${queries.large} {
      margin-top: 0;
      flex-direction: row;
    }
  `,
  myResearchContainer: css`
    max-width: 1530px;
    margin: auto;
    padding: 0 1rem;
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
  `,
  secondaryBtn: css`
    border: 1px solid ${theme.colors.black};
    padding: 0.5rem 1rem;
    height: auto;
    border-radius: 5px;

    &:hover {
      background: ${theme.colors.background.veryLightGray};
      border-color: ${theme.colors.black};
      color: ${theme.colors.black};
    }
  `,
  mobileOnly: css`
    display: block;

    @media ${queries.large} {
      display: none;
    }
  `,
  desktopOnly: css`
    display: none;

    @media ${queries.large} {
      display: block;
    }
  `,
  container: css`
    position: relative;
    max-width: 100%;
    width: calc(100vw - 40px);
    @media ${queries.extraLarge} {
      max-width: 1600px;
    }
    margin: 0 auto;
  `,
  resetBtn: css`
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    outline: none;
    background: transparent;
  `,
  inputAutofillOverride: css`
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-internal-autofill-selected {
      -webkit-box-shadow: 0 0 0 64px ${theme.colors.background.landingDarkGray}
        inset;
    }
  `,
  guestPageContainer: css`
    height: 100%;
    padding: 50px 0;
    margin: 50px auto;
  `,
  guestPageInnerContainer: css`
    max-width: 630px;
    margin: auto;
  `,
};
