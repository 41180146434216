import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import appStyles from "src/app/styles";
import styles from "./styles";
import StudioSearch from "../modules-section/images/StudioSearchSVG";
import StudioPlaylists from "../modules-section/images/StudioPlaylistsSVG";
import Projects from "../modules-section/images/ProjectsSVG";
import Measurements from "../modules-section/images/MeasurementsSVG";
import Insights from "../modules-section/images/InsightsSVG";
import { EModules } from "../modules-section/utils";
import AdaptiveMusic from "../modules-section/images/AdaptiveMusicSVG";

interface IModuleTileProps {
  title: string;
  description: string;
  link: string;
  externalLink: boolean;
  openInSameWindow?: boolean;
}

const ModuleTile = ({
  title,
  description,
  link,
  externalLink,
  openInSameWindow = false,
}: IModuleTileProps) => {
  const history = useHistory();

  const handleModuleTileClick = useCallback(() => {
    if (openInSameWindow) {
      window.open(link, "_self");
    } else if (externalLink) {
      window.open(link, "_blank");
    } else {
      history.push(link);
    }
  }, [externalLink, openInSameWindow, history, link]);

  const renderModuleImage = () => {
    switch (title) {
      case EModules.SEARCH:
        return <StudioSearch />;
      case EModules.LIBRARY:
        return <StudioPlaylists />;
      case EModules.PROJECTS:
        return <Projects />;
      case EModules.MEASUREMENTS:
        return <Measurements />;
      case EModules.INSIGHTS:
        return <Insights />;
      case EModules.ADAPTIVE_MUSIC:
        return <AdaptiveMusic />;
      case EModules.RESOURCES:
        return <AdaptiveMusic />;
      default:
        return null;
    }
  };

  return (
    <button
      type="button"
      className="module-tile"
      onClick={handleModuleTileClick}
      onKeyDown={handleModuleTileClick}
      css={appStyles.resetBtn}
    >
      <div css={styles.moduleTileContainer}>
        <span css={styles.title}>{title}</span>
        <span css={styles.description}>{description}</span>
        <div css={styles.cardImg}>{renderModuleImage()}</div>
      </div>
    </button>
  );
};

export default ModuleTile;
