import React, { ReactElement } from "react";

const Silhouette = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00418 10.8248C5.02037 10.8248 2.5918 8.39715 2.5918 5.41238C2.5918 2.42762 5.01942 0 8.00418 0C10.9889 0 13.4166 2.42762 13.4166 5.41238C13.4166 8.39715 10.9889 10.8248 8.00418 10.8248ZM8.00418 3.81048C7.12037 3.81048 6.40132 4.52952 6.40132 5.41333C6.40132 6.29714 7.12037 7.01619 8.00418 7.01619C8.88799 7.01619 9.60704 6.29714 9.60704 5.41333C9.60704 4.52952 8.88799 3.81048 8.00418 3.81048Z"
        fill="white"
      />
      <path
        d="M14.9412 15.9995H12.4469C12.1155 15.9995 11.8374 15.7547 11.7879 15.4271C11.5107 13.588 9.9193 12.1728 8.00406 12.1728C6.08882 12.1728 4.49739 13.5871 4.22025 15.4271C4.17072 15.7547 3.89263 15.9995 3.5612 15.9995H1.06691C0.671676 15.9995 0.365009 15.6585 0.403104 15.2652C0.77358 11.3976 4.0412 8.36328 8.00406 8.36328C11.9669 8.36328 15.2345 11.3976 15.605 15.2652C15.6431 15.6585 15.3364 15.9995 14.9412 15.9995Z"
        fill="white"
      />
    </svg>
  );
};

export default Silhouette;
