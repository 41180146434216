import {
  getAuthHeader,
  getJsonContentHeader,
  getAxiosOptions,
} from "src/api/headers";
import axios from "axios/index";
import {
  getAvailableReportByIdUrl,
  getAvailableReportsUrl,
} from "../power-bi-url-helper";

export interface IAvailableReport {
  name: string;
  description: string;
  reportId: string;
}

export interface IAvailableReports {
  reports: IAvailableReport[];
}

export interface IReport {
  report: IAvailableReport;
  embedUrl: string;
  token: string;
  expiration: string;
}

const getAvailableReportById = async (
  accessToken: string,
  reportId: string,
  organisationId: string
): Promise<IReport> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  const response = await axios.get(
    getAvailableReportByIdUrl(organisationId, reportId),
    options
  );
  return response.data as IReport;
};

const getAvailableReports = async (
  accessToken: string,
  organisationId: string
): Promise<IAvailableReports> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
  };

  const response = await axios.get(
    getAvailableReportsUrl(organisationId),
    options
  );
  return response.data as IAvailableReports;
};

export { getAvailableReports, getAvailableReportById };
