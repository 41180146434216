import { ReactElement, ReactNode, useState } from "react";
import BreadcrumbContext from "./context";

interface IHubProvider {
  children: ReactNode;
}

const BreadcrumbProvider = ({ children }: IHubProvider): ReactElement => {
  const [playlistName, setPlaylistName] = useState<string>("");
  const [trackName, setTrackName] = useState<string>("");
  const [folderName, setFolderName] = useState<string>("");
  const [projectName, setProjectName] = useState<string>("");

  const updateBreadcrumbPlaylistName = (name: string) => {
    setPlaylistName(name);
  };

  const updateBreadcrumbTrackName = (name: string) => {
    setTrackName(name);
  };

  const updateBreadcrumbFolderName = (name: string) => {
    setFolderName(name);
  };

  const updateBreadcrumbProjectName = (name: string) => {
    setProjectName(name);
  };

  return (
    <BreadcrumbContext.Provider
      value={{
        playlistName,
        updateBreadcrumbPlaylistName,
        trackName,
        updateBreadcrumbTrackName,
        folderName,
        updateBreadcrumbFolderName,
        projectName,
        updateBreadcrumbProjectName,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

export default BreadcrumbProvider;
