import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  submit: css`
    margin-top: 1rem;
    margin-bottom: 0;
    button {
      font-family: ${theme.fonts.sofiaPro};
      font-weight: 700;
    }
  `,
  input: css`
    .input,
    input {
      background: ${theme.colors.background.veryDarkGray} !important;
      color: #adadad !important;
    }

    .error input {
      border-color: ${theme.colors.functional.red} !important;
    }

    .ant-form-item-explain-error span {
      padding: 0 !important;
      margin-bottom: 16px;
    }
    .input-label,
    div {
      color: ${theme.colors.white} !important;
      font-family: ${theme.fonts.sofiaPro};
    }
  `,
  profileFormContainer: css`
    background-color: ${theme.colors.background.basaltBlack};
    width: 100% !important;
    height: 650px;
  `,
  description: css`
    margin-bottom: 32px !important;
    font-family: ${theme.fonts.sofiaPro};
  `,
  title: css`
    font-size: 3rem !important;
    line-height: 3rem !important;
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 800 !important;
    letter-spacing: -0.02em;
    margin-bottom: 16px !important;
  `,
  brandArrow: css`
    position: absolute;
    right: 0;
    top: calc(-11rem + 32px);
  `,
};
