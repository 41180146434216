import { css } from "@emotion/react";
import { breakpoints, queries } from "@songtradr/spa-common";
import theme from "src/theme";

const headingBase = css`
  letter-spacing: -0.02em;
  font-weight: 250;
  font-size: 3rem;
  line-height: 3.5rem;
  color: ${theme.colors.white};
  font-family: ${theme.fonts.sofiaPro};
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 0;
  text-align: left;

  @media ${queries.medium} {
    font-size: 4rem;
    line-height: 4.5rem;
    text-align: center;
  }
`;

export default {
  validateHeaderContainer: css`
    font-family: ${theme.fonts.sofiaPro};
    color: ${theme.colors.white};
    margin: auto;
    padding: 0 1rem 2rem;
    max-width: 100%;

    @media ${queries.medium} {
      padding-bottom: 32px;
      max-width: ${breakpoints.xxLarge}px;
    }
  `,
  headerContainer: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-family: ${theme.fonts.sofiaPro};
    font-style: normal;
    margin-bottom: 48px;
  `,
  textContainer: css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @media ${queries.medium} {
      width: 900px;
    }
    margin: auto;
    font-family: ${theme.fonts.sofiaPro};

    p {
      color: ${theme.colors.white};
      font-weight: 300;
      font-size: 1rem;
      margin-top: 2rem;
      margin-bottom: 3rem;
      text-align: left;

      @media ${queries.medium} {
        text-align: center;
        font-size: 1.5rem;
      }
    }

    .createButton {
      margin: auto;
    }
  `,
  headerText: css`
    ${headingBase};

    span {
      color: ${theme.colors.functional.brightOrange};
      font-weight: 250;
    }
  `,
  videoContainer: css`
    width: 75%;
    height: 626px;
    background: rgb(182, 213, 248);
    margin: auto;
    border-radius: 15px;
  `,
  backBtnContainer: css`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    padding-top: 32px;

    :hover {
      path {
        fill: ${theme.colors.functional.brightOrange};
      }
    }
  `,
  backBtn: css`
    font-family: ${theme.fonts.sofiaPro};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    background: transparent;
    margin-right: 4px;
    border: none;
    cursor: pointer;

    svg {
      margin-right: 3px;
      fill: ${theme.colors.white};
    }
  `,
  backText: css`
    font-size: 0.875rem;
    font-weight: 400;
    font-family: ${theme.fonts.sofiaPro};
    background: none;
    color: white;
    border: none;
    cursor: pointer;

    :hover {
      color: white;
    }
  `,
  tableHeaderContainer: css`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  secondaryHeader: css`
    ${headingBase};
    font-size: 2rem;
    line-height: normal;
  `,
  testResultsTable: css`
    margin: 1.5rem 0 5rem 0;
  `,
  marginAuto: css`
    margin: auto;
  `,
};
