import React, { ReactElement } from "react";
import { Widget } from "@typeform/embed-react";
import useAuth from "src/auth/use-auth";
import styles from "./styles";

const NewTest = (): ReactElement => {
  const { organisationId } = useAuth();
  const TypeformHiddenFields = {
    org_id: organisationId,
  };

  return (
    <Widget css={styles.widget} id="M1qH7Smj" hidden={TypeformHiddenFields} />
  );
};

export default NewTest;
