import { IconButton } from "@songtradr/component-library";
import React from "react";
import NextIcon from "src/app/assets/icons/component-icons/next";
import PreviousIcon from "src/app/assets/icons/component-icons/previous";
import { getIsMobile, useWindowSize } from "@songtradr/spa-common";
import styles from "./styles";

export enum Direction {
  PREV = "prev",
  NEXT = "next",
}

const CarouselControllers = () => {
  useWindowSize();
  const isMobile = getIsMobile();
  if (isMobile) {
    return null;
  }

  return (
    <div css={styles.controls}>
      <IconButton
        className="previous-button"
        variant="primary"
        css={styles.button}
      >
        <PreviousIcon />
      </IconButton>
      <IconButton className="next-button" variant="primary" css={styles.button}>
        <NextIcon />
      </IconButton>
    </div>
  );
};

export default CarouselControllers;
