import React, { ReactElement } from "react";

const ProjectsIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M16.7497 16.1383H7.29556C7.11992 16.1383 6.97754 16.2793 6.97754 16.4532V18.3424C6.97754 18.5163 7.11992 18.6573 7.29556 18.6573H16.7497C16.9253 18.6573 17.0677 18.5163 17.0677 18.3424V16.4532C17.0677 16.2793 16.9253 16.1383 16.7497 16.1383Z" />
      <path d="M14.2055 7.86166H7.29556C7.11992 7.86166 6.97754 8.00264 6.97754 8.17654V10.0658C6.97754 10.2397 7.11992 10.3806 7.29556 10.3806H14.2055C14.3812 10.3806 14.5236 10.2397 14.5236 10.0658V8.17654C14.5236 8.00264 14.3812 7.86166 14.2055 7.86166Z" />
      <path d="M15.4776 12H7.29556C7.11992 12 6.97754 12.141 6.97754 12.3149V14.2041C6.97754 14.378 7.11992 14.519 7.29556 14.519H15.4776C15.6533 14.519 15.7956 14.378 15.7956 14.2041V12.3149C15.7956 12.141 15.6533 12 15.4776 12Z" />
      <path d="M12.9839 24H2.94523C2.69908 24 2.5 23.8029 2.5 23.5592V15.028C2.5 14.7843 2.69972 14.5872 2.94523 14.5872H4.59894C4.84509 14.5872 5.04417 14.7849 5.04417 15.028V21.0396C5.04417 21.2833 5.24325 21.4804 5.4894 21.4804H12.9839C13.23 21.4804 13.4291 21.6775 13.4291 21.9212V23.5586C13.4291 23.8023 13.23 23.9994 12.9839 23.9994V24Z" />
      <path d="M21.3005 23.9996H17.4881C17.2419 23.9996 17.0428 23.8025 17.0428 23.5588V21.9214C17.0428 21.6777 17.2419 21.4806 17.4881 21.4806H18.7563C19.0025 21.4806 19.2016 21.2835 19.2016 21.0398V9.99028C19.2016 9.87504 19.1558 9.7642 19.0743 9.6817L12.1103 2.65186C12.0264 2.56747 11.9119 2.51961 11.7923 2.51961H5.49038C5.24423 2.51961 5.04515 2.71672 5.04515 2.96043V9.26418C5.04515 9.50789 4.84607 9.705 4.59992 9.705H2.94621C2.70006 9.705 2.50098 9.50789 2.50098 9.26418V0.440821C2.50098 0.19711 2.70006 0 2.94621 0H12.8608C12.9804 0 13.0949 0.0478608 13.1789 0.132247L21.6185 8.65207C21.6999 8.73457 21.7457 8.8454 21.7457 8.96065V23.5581C21.7457 23.8019 21.5466 23.999 21.3005 23.999V23.9996Z" />
    </svg>
  );
};

export default ProjectsIcon;
