import React, { ReactElement } from "react";

const CheckMarkValid = (): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#20C885" />
      <path
        d="M10.2449 15.9447L7.39884 13.0987C7.28372 12.9835 7.28372 12.7964 7.39884 12.6813L8.65096 11.4292C8.76608 11.314 8.95322 11.314 9.06834 11.4292L10.2449 12.6057C10.36 12.7208 10.5472 12.7208 10.6623 12.6057L14.9317 8.33634C15.0468 8.22122 15.2339 8.22122 15.349 8.33634L16.6012 9.58846C16.7163 9.70358 16.7163 9.89072 16.6012 10.0058L10.6623 15.9447C10.5472 16.0598 10.36 16.0598 10.2449 15.9447Z"
        fill="white"
      />
    </svg>
  );
};

export default CheckMarkValid;
