import React, { ReactElement } from "react";

const EyeWithoutStrikethrough = (
  props: React.SVGProps<SVGSVGElement>
): ReactElement => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.35203 8.00405C8.63545 7.07833 10.2354 6.30005 12 6.30005C13.7646 6.30005 15.3646 7.07948 16.648 8.00405C17.9371 8.93204 18.9566 10.0429 19.5737 10.7892L19.5794 10.7949C19.8549 11.1378 20 11.5732 20 12.0143C20 12.4555 19.8549 12.8898 19.5794 13.2337L19.5737 13.2395C18.9566 13.9857 17.9371 15.0966 16.648 16.0246C15.3646 16.9515 13.7646 17.7286 12 17.7286C10.2354 17.7286 8.63545 16.9492 7.35203 16.0257C6.06288 15.0955 5.04346 13.9846 4.42632 13.2383L4.4206 13.2326C4.14588 12.8862 3.99752 12.4564 4.00003 12.0143C4.00003 11.5732 4.14517 11.1389 4.4206 10.7949L4.42632 10.7892C5.04346 10.0429 6.06288 8.93204 7.35203 8.00405ZM12 14.5857C12.3377 14.5857 12.6721 14.5192 12.9841 14.39C13.296 14.2608 13.5795 14.0714 13.8183 13.8326C14.0571 13.5938 14.2465 13.3103 14.3757 12.9984C14.5049 12.6864 14.5714 12.352 14.5714 12.0143C14.5714 11.6766 14.5049 11.3423 14.3757 11.0303C14.2465 10.7183 14.0571 10.4348 13.8183 10.1961C13.5795 9.95727 13.296 9.76786 12.9841 9.63864C12.6721 9.50941 12.3377 9.4429 12 9.4429C11.318 9.4429 10.664 9.71382 10.1817 10.1961C9.69951 10.6783 9.42859 11.3323 9.42859 12.0143C9.42859 12.6963 9.69951 13.3504 10.1817 13.8326C10.664 14.3148 11.318 14.5857 12 14.5857Z"
        fill="white"
      />
    </svg>
  );
};

export default EyeWithoutStrikethrough;
