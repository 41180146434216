import { ReactElement } from "react";

const SearchIcon = (): ReactElement => {
  return (
    <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.0886 31.6771C18.2147 31.6771 14.25 27.7124 14.25 22.8386C14.25 17.9647 18.2147 14 23.0886 14C27.9624 14 31.9271 17.9647 31.9271 22.8386C31.9271 27.7124 27.9624 31.6771 23.0886 31.6771ZM23.0886 16.5808C19.638 16.5808 16.8308 19.388 16.8308 22.8386C16.8308 26.2891 19.638 29.0963 23.0886 29.0963C26.5391 29.0963 29.3463 26.2891 29.3463 22.8386C29.3463 19.388 26.5391 16.5808 23.0886 16.5808Z"
        fill="white"
      />
      <path
        d="M28.8284 27.2447L33.4394 31.8558C33.616 32.0323 33.616 32.3179 33.4394 32.4945L32.2533 33.6806C32.0767 33.8572 31.7911 33.8572 31.6145 33.6806L27.0035 29.0696C26.4364 28.5025 27.0791 28.2527 27.4935 27.7648C27.9171 27.266 28.3297 26.7461 28.8284 27.2447Z"
        fill="white"
      />
    </svg>
  );
};
export default SearchIcon;
