import { Divider, Table } from "antd";
import { DebouncedFunc, startCase } from "lodash";
import React, { useState, ReactElement, forwardRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Waveform from "src/components/music-player";
import {
  IProjectTrack,
  IProjectUploadFile,
} from "src/pages/projects/client-project-view/interfaces";
import theme from "src/theme";
import projectsMainStyles from "src/pages/projects/styles";
import SortDESC from "src/app/assets/icons/component-icons/buttons/sort-desc";
import SortASC from "src/app/assets/icons/component-icons/buttons/sort-asc";
import mainStyles from "../../styles";
import CollapsiblePanels, { ICollapsiblePanel } from "../collapsible-panels";
import styles from "./styles";

interface IProps {
  tracks: IProjectTrack[];
  downloadFile: DebouncedFunc<(file: IProjectUploadFile) => Promise<void>>;
}

interface ITrackHeaderProps {
  track: IProjectTrack;
  artist: string;
}

const TrackHeader = ({ track, artist }: ITrackHeaderProps) => {
  return (
    <div
      key={track.id}
      css={styles.waveformContainer}
      role="rowheader"
      tabIndex={0}
    >
      <div css={styles.trackTitle}>
        <div>{track.title}</div>
        <div>{artist}</div>
      </div>
    </div>
  );
};

interface ITrackAttachmentsProps {
  track: IProjectTrack;
  artist: string;
  isTrackPlaying: boolean;
  setTrackPlaying: React.Dispatch<React.SetStateAction<boolean>>;
  downloadFile: DebouncedFunc<(file: IProjectUploadFile) => Promise<void>>;
}

const TrackAttachments = ({
  track,
  artist,
  isTrackPlaying,
  setTrackPlaying,
  downloadFile,
}: ITrackAttachmentsProps) => {
  const hasAttachments = track.attachments?.length > 0;
  const { t } = useTranslation();

  return (
    <div css={styles.trackDetails}>
      <div css={mainStyles.tableTitle}>
        {t("ClientProjectViewPage##Attachments")}
      </div>
      {hasAttachments ? (
        <div>
          {track.attachments.map((attachment) => {
            if (attachment.url) {
              return (
                <div key={attachment.id}>
                  <Waveform
                    containerId={`track${attachment.id}`}
                    url={attachment.url}
                    fileName={attachment.name}
                    artist={artist}
                    setTrackPlaying={setTrackPlaying}
                    isTrackPlaying={isTrackPlaying}
                    textColor={theme.colors.white}
                    attachmentId={attachment.id}
                    handleDownloadClick={() => downloadFile(attachment)}
                  />
                </div>
              );
            }
            return null;
          })}
        </div>
      ) : (
        <div css={styles.noDataAvailable}>
          {t("ClientProjectViewPage##No data available")}
        </div>
      )}
    </div>
  );
};

const FinalTrackDetails = forwardRef(
  (
    { tracks, downloadFile }: IProps,
    ref: React.Ref<HTMLDivElement>
  ): ReactElement => {
    const { t } = useTranslation();
    const [isTrackPlaying, setTrackPlaying] = useState<boolean>(false);
    const hasMoreProjectTracks = useMemo(() => tracks.length > 1, [
      tracks.length,
    ]);

    const columns = [
      {
        dataIndex: "name",
        key: "name",
        sorter: (a: any, b: any) => a.name.localeCompare(b.name),
        title: (sortColumn: any) => {
          const sortedColumn = sortColumn.sortColumn?.key === "name";
          const sortedOrder = sortColumn.sortOrder;
          return (
            <div css={mainStyles.tableColumnHeader}>
              {t("ClientProjectViewPage##Name")}
              {sortedColumn && sortedOrder === "ascend" ? (
                <SortDESC />
              ) : (
                <SortASC />
              )}
            </div>
          );
        },
      },
      {
        title: t("ClientProjectViewPage##Link"),
        dataIndex: "link",
        key: "link",
      },
      {
        title: t("ClientProjectViewPage##Music type"),
        dataIndex: "musicType",
        key: "musicType",
      },
    ];

    const tableDataSource = (track: IProjectTrack) => {
      const { musicType } = track;
      return track.assets?.map((asset, index) => {
        return {
          key: `${track.id}-${index}`,
          name: asset.name || "-",
          link: asset.link ? (
            <a href={asset.link} target="_blank" rel="noreferrer">
              {asset.link}
            </a>
          ) : (
            "-"
          ),
          musicType: startCase(musicType) || "-",
        };
      });
    };

    const panels: ICollapsiblePanel[] = tracks.map((track, index) => {
      const artist = (track.artistContacts ?? [])
        .map((contact) => contact.name)
        .join(", ");
      return {
        id: track.id,
        header: (
          <TrackHeader
            key={`header${track.id}`}
            track={track}
            artist={artist}
          />
        ),
        panelTitle: hasMoreProjectTracks
          ? `${t("ClientProjectViewPage##Track")} ${index + 1}`
          : "",
        expandedContent: (
          <div key={`content${track.id}`} css={styles.contentContainer}>
            <Divider />
            <TrackAttachments
              track={track}
              artist={artist}
              isTrackPlaying={isTrackPlaying}
              setTrackPlaying={setTrackPlaying}
              downloadFile={downloadFile}
            />
            <div css={mainStyles.tableTitle}>
              {t("ClientProjectViewPage##Final track urls")}
            </div>
            <Table
              tableLayout="fixed"
              css={projectsMainStyles.getTableStyles(false)}
              columns={columns}
              dataSource={tableDataSource(track)}
              pagination={false}
              locale={{
                emptyText: "No data available",
              }}
              scroll={{ x: true }}
            />
          </div>
        ),
      };
    });

    return (
      <div css={mainStyles.sectionContainer} id="final-track" ref={ref}>
        <CollapsiblePanels
          mainTitle="Final track(s)"
          subtitle="All tracks, links or assets provided for your project"
          panels={panels}
        />
      </div>
    );
  }
);

export default FinalTrackDetails;
