import { Fragment, ReactElement, useEffect, useState } from "react";
import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import "powerbi-report-authoring";
import usePowerBi from "src/providers/power-bi/hooks/index";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import style from "./styles";
import STLoadingLogo from "../st-loading-logo";

const NoReport = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div css={style.container}>
      <div css={style.contentContainer}>
        <Typography.Text css={style.textContainer}>
          {t(
            "ProjectsPage##Nothing to see here as a report has yet to be created for your organisation"
          )}
        </Typography.Text>
      </div>
    </div>
  );
};
interface IPowerBiReport {
  visible: boolean;
}
const PowerBiReport = ({ visible }: IPowerBiReport): JSX.Element => {
  const {
    isLoading,
    availableReports,
    selectedReport,
    getReportById,
    clearTimer,
  } = usePowerBi();
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(true);
  const [
    reportConfig,
    setReportConfig,
  ] = useState<models.IReportEmbedConfiguration | null>(null);

  useEffect(() => {
    const getReport = async () => {
      const firstAvailableReport = (availableReports ?? [])[0];
      if (firstAvailableReport) {
        await getReportById(firstAvailableReport.reportId);
      }
      setIsLoadingReport(false);
    };

    if (!selectedReport) {
      getReport();
    }
  }, [availableReports, getReportById, selectedReport]);

  useEffect(() => {
    if (selectedReport) {
      setReportConfig({
        type: "report",
        tokenType: models.TokenType.Embed,
        embedUrl: selectedReport.embedUrl,
        accessToken: selectedReport.token,
      });
      setIsLoadingReport(false);
    }
  }, [selectedReport]);

  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, [clearTimer]);

  if (!visible) {
    return <Fragment />;
  }

  if (isLoadingReport || isLoading) {
    return <STLoadingLogo />;
  }

  if (!isLoadingReport && !reportConfig) {
    return <NoReport />;
  }

  return (
    <div
      css={style.reportContainer}
      style={{
        visibility: visible ? "visible" : "hidden",
        height: visible ? "100%" : "0",
      }}
    >
      <PowerBIEmbed
        embedConfig={reportConfig as models.IReportEmbedConfiguration}
      />
    </div>
  );
};

export default PowerBiReport;
