import { css } from "@emotion/react";
import theme from "src/theme";
import { gray800, white } from "@songtradr/component-library/tokens";

const truncatedText = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default {
  channelOption: css`
    display: flex;
    align-items: center;
    width: 100%;
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0.5rem;

    &:hover {
      background: ${gray800};
    }
  `,
  searchContainer: css`
    display: flex;
    width: 100%;
  `,
  selectContainer: css`
    flex-grow: 1;
    width: 100%;
    min-width: 0;
  `,
  addUrlBtn: css`
    margin-left: 1rem;
    white-space: nowrap;
  `,
  videoTitle: css`
    text-align: left;
    font-size: 0.9rem;
    color: ${white};
    margin: 0;
    ${truncatedText}
  `,
  videoDescription: css`
    font-size: 0.7rem;
    color: ${theme.colors.secondary.iconGray};
    margin: 0;
    text-align: left;
    ${truncatedText}
  `,
  channelThumbnail: css`
    height: 50px;
    width: 50px;
    border-radius: 4px;
  `,
  ytError: css`
    margin-bottom: 1rem;
  `,
  titleContainer: css`
    min-width: 0;
    width: 55%;
    flex-grow: 1;
  `,
  leftSpacing: css`
    margin-left: 1rem;
  `,
  videoMeta: css`
    font-size: 0.75rem;
    text-align: right;
    white-space: nowrap;
  `,
  videoCheckbox: css`
    min-width: 0;
    pointer-events: none;
  `,
  backBtn: css`
    margin: 0.5rem 0 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    font-size: 0.9rem;
    color: ${theme.colors.secondary.iconGray};

    > span {
      display: inline-block;
      margin-right: 0.5rem;
    }
  `,
  label: css`
    display: flex !important;
    align-items: center;

    svg {
      margin-left: 0.5rem;
      height: 15px;
      width: 15px;
    }
  `,
};
