import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  passcodeContainer: css`
    height: 96px;
    line-height: 96px;
    text-align: center;
    background: #333;
    border-radius: 8px;
    margin-bottom: 24px;
    .passcode-character {
      background: ${theme.colors.black};
      font-size: 48px;
      border-radius: 8px;
      margin: 20px 16px 20px 0;
      padding: 4px 9px;
      &:nth-of-type(1) {
        margin-left: 16px;
      }
    }
  `,
  icon: css`
    margin-right: 8px;
  `,
  copyButton: css`
    margin-bottom: 48px;
  `,
};
