import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: css`
    font-family: ${theme.fonts.sofiaPro};
    display: block;
    text-align: center;
    background-color: ${theme.colors.background.landingDarkGray};
    color: ${theme.colors.white};
    width: 100%;
    h2,
    h1,
    h3 {
      color: ${theme.colors.white};
    }
    h1 {
      font-size: 64px;
      margin-bottom: 96px;
    }
    h2 {
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      font-size: 24px;
      font-weight: 250;
      margin-bottom: 48px;
    }
    h3 {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 16px;
    }
    .content {
      margin-top: 192px;
    }
    .passcode-character {
      display: inline-block;
      width: 96px;
      height: 96px;
      font-size: 64px;
      background: ${theme.colors.black};
      border-radius: 8px;
      border: 1px solid #333;
      line-height: 96px;
      text-align: center;
      margin-right: 8px;
      outline-color: ${theme.colors.white};
    }
    .confirmation {
      font-family: ${theme.fonts.sofiaPro};
      font-size: 24px;
      font-weight: 250;
      width: 616px;
      height: 96px;
      background: ${theme.colors.black};
      text-align: center;
      margin: 0 auto;
      border-radius: 8px;
      line-height: 96px;
    }
  `,
};
