import React, { ReactElement } from "react";

const CrossIcon = (props: React.SVGProps<SVGSVGElement>): ReactElement => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 10.7017V13.2983C18 13.5381 17.8061 13.7311 17.5672 13.7311H13.7311V17.5672C13.7311 17.807 13.5372 18 13.2983 18H10.7017C10.4628 18 10.2689 17.807 10.2689 17.5672V13.7311H6.43278C6.19388 13.7311 6 13.5381 6 13.2983V10.7017C6 10.4628 6.19388 10.2689 6.43278 10.2689H10.2689V6.43278C10.2689 6.19388 10.4628 6 10.7017 6H13.2983C13.5372 6 13.7311 6.19388 13.7311 6.43278V10.2689H17.5672C17.8061 10.2689 18 10.4628 18 10.7017Z"
        fill="white"
      />
    </svg>
  );
};

export default CrossIcon;
