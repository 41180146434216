import { ReactElement } from "react";
import styles from "./styles";

const Insights = (): ReactElement => {
  return (
    <div css={styles.insights}>
      <svg
        id="Artwork"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 252 242"
      >
        <defs>
          <linearGradient
            id="insights-linear-gradient"
            x1="190.48"
            y1="107.93"
            x2="190.48"
            y2="174.79"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4a4c4c" />
            <stop offset="1" stopColor="#4a4c4c" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="insights-linear-gradient-2"
            x1="173.39"
            y1="111.95"
            x2="207.58"
            y2="171.18"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#4f5050" />
            <stop offset="1" stopColor="#323434" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="insights-linear-gradient-3"
            x1="828.36"
            y1="53.77"
            x2="828.36"
            y2="165.47"
            gradientTransform="translate(975.85) rotate(-180) scale(1 -1)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ec5734" />
            <stop offset="1" stopColor="#ec5734" stopOpacity=".2" />
          </linearGradient>
          <linearGradient
            id="insights-linear-gradient-4"
            x1="117.64"
            y1="60.41"
            x2="177.35"
            y2="163.82"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ec5734" />
            <stop
              offset=".06"
              stopColor="rgba(236,87,52,.92)"
              stopOpacity=".92"
            />
            <stop
              offset=".25"
              stopColor="rgba(236,87,52,.64)"
              stopOpacity=".64"
            />
            <stop
              offset=".44"
              stopColor="rgba(236,87,52,.41)"
              stopOpacity=".41"
            />
            <stop
              offset=".62"
              stopColor="rgba(236,87,52,.24)"
              stopOpacity=".24"
            />
            <stop
              offset=".77"
              stopColor="rgba(236,87,52,.11)"
              stopOpacity=".11"
            />
            <stop
              offset=".91"
              stopColor="rgba(236,87,52,.03)"
              stopOpacity=".03"
            />
            <stop offset="1" stopColor="#ec5734" stopOpacity="0" />
          </linearGradient>
          <linearGradient
            id="insights-linear-gradient-5"
            x1="104.51"
            y1="133.47"
            x2="104.51"
            y2="175.16"
            xlinkHref="#insights-linear-gradient"
          />
          <linearGradient
            id="insights-linear-gradient-6"
            x1="92.81"
            y1="133.79"
            x2="116.2"
            y2="174.3"
            xlinkHref="#insights-linear-gradient-2"
          />
          <linearGradient
            id="insights-linear-gradient-7"
            x1="61.52"
            y1="92.77"
            x2="61.52"
            y2="174.25"
            xlinkHref="#insights-linear-gradient"
          />
          <linearGradient
            id="insights-linear-gradient-8"
            x1="40.98"
            y1="98.07"
            x2="82.05"
            y2="169.2"
            xlinkHref="#insights-linear-gradient-2"
          />
        </defs>
        <rect
          className="cls-5"
          x="26.51"
          y="174.81"
          width="198.99"
          height="1"
        />
        <rect className="cls-5" x="26.51" y="138.6" width="198.99" height="1" />
        <rect className="cls-5" x="26.51" y="102.4" width="198.99" height="1" />
        <rect className="cls-5" x="26.51" y="66.19" width="198.99" height="1" />
        <rect
          className="cls-1"
          x="180.33"
          y="107.83"
          width="20.3"
          height="67.48"
          rx="3"
          ry="3"
        />
        <path
          className="cls-4"
          d="M197.63,107.83c1.66,0,3,1.34,3,3v61.48c0,1.66-1.34,3-3,3h-14.3c-1.66,0-3-1.34-3-3v-61.48c0-1.66,1.34-3,3-3h14.3M197.63,106.83h-14.3c-2.21,0-4,1.79-4,4v61.48c0,2.21,1.79,4,4,4h14.3c2.21,0,4-1.79,4-4v-61.48c0-2.21-1.79-4-4-4h0Z"
        />
        <rect
          className="cls-7"
          x="137.34"
          y="48.92"
          width="20.3"
          height="126.39"
          rx="3"
          ry="3"
        />
        <path
          className="cls-9"
          d="M154.64,48.92c1.66,0,3,1.34,3,3v120.39c0,1.66-1.34,3-3,3h-14.3c-1.66,0-3-1.34-3-3V51.92c0-1.66,1.34-3,3-3h14.3M154.64,47.92h-14.3c-2.21,0-4,1.79-4,4v120.39c0,2.21,1.79,4,4,4h14.3c2.21,0,4-1.79,4-4V51.92c0-2.21-1.79-4-4-4h0Z"
        />
        <rect
          className="cls-3"
          x="94.36"
          y="132.78"
          width="20.3"
          height="42.52"
          rx="3"
          ry="3"
        />
        <path
          className="cls-2"
          d="M111.66,132.78c1.66,0,3,1.34,3,3v36.52c0,1.66-1.34,3-3,3h-14.3c-1.66,0-3-1.34-3-3v-36.52c0-1.66,1.34-3,3-3h14.3M111.66,131.78h-14.3c-2.21,0-4,1.79-4,4v36.52c0,2.21,1.79,4,4,4h14.3c2.21,0,4-1.79,4-4v-36.52c0-2.21-1.79-4-4-4h0Z"
        />
        <rect
          className="cls-6"
          x="51.37"
          y="91.96"
          width="20.3"
          height="83.35"
          rx="3"
          ry="3"
        />
        <path
          className="cls-8"
          d="M68.67,91.96c1.66,0,3,1.34,3,3v77.35c0,1.66-1.34,3-3,3h-14.3c-1.66,0-3-1.34-3-3v-77.35c0-1.66,1.34-3,3-3h14.3M68.67,90.96h-14.3c-2.21,0-4,1.79-4,4v77.35c0,2.21,1.79,4,4,4h14.3c2.21,0,4-1.79,4-4v-77.35c0-2.21-1.79-4-4-4h0Z"
        />
      </svg>
    </div>
  );
};
export default Insights;
