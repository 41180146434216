import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  container: (selected: boolean) => css`
    margin-bottom: 16px;
    font-family: ${theme.fonts.sofiaPro};
    display: flex;
    flex-direction: row;
    border-radius: 8px;
    border: 1px solid ${selected ? "#F0F0F0" : "#333"} !important;
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.5);
    background: ${theme.colors.black};
    padding: 16px;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
    h1 {
      font-size: 16px !important;
      font-weight: 300;
      line-height: 24px;
    }
    p {
      font-size: 14px !important;
      margin-bottom: 0 !important;
      font-weight: 300;
      line-height: 18px;
      color: #adadad;
    }
    .row {
      display: flex;
      .icon {
        flex: 1;
        font-size: 24px;
      }
    }
    .col {
      flex-direction: column;
      flex: 1;
      &:first-of-type {
        flex: 1 1 80%;
      }
      .icon {
        font-size: 24px;
        vertical-align: middle;
      }
    }
    .icon-container {
      align-self: center;
    }
  `,
};
