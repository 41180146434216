import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  password: css`
    .password-criteria {
      background-color: transparent !important;
    }
    .valid-message {
      color: ${theme.colors.black};
    }
  `,
  form: css`
    input {
      margin-bottom: 16px;
    }
    .valid-message,
    .password-criteria {
      margin-top: 16px;
    }
    button {
      float: right;
      margin-top: 16px;
    }
  `,
  backButton: css`
    position: absolute;
    right: 0;
    top: -40px;
  `,
  servicingOrgs: css`
    margin-bottom: 16px;
    button {
      margin-top: 0 !important;
    }
    div {
      margin-bottom: 8px;
    }
  `,
};
