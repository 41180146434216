import React, { ReactElement, ReactNode, forwardRef } from "react";
import { getIsDesktop } from "@songtradr/spa-common/lib/utils";
import ReactTooltip from "react-tooltip";
import theme from "src/theme";
import styles from "./styles";

interface IProps {
  label: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
  showTooltip?: boolean;
  showLabel?: boolean;
  "data-testid"?: string;
}

const isDesktop = getIsDesktop();

const ControlButton = forwardRef<HTMLButtonElement, IProps>(
  (
    {
      label,
      onClick,
      disabled,
      className = "",
      children,
      showTooltip = false,
      showLabel = false,
      "data-testid": dataTestId,
    }: IProps,
    ref
  ): ReactElement => (
    <>
      <button
        data-tip
        data-for={dataTestId}
        ref={ref}
        aria-label={label}
        css={[
          styles.control,
          showTooltip && isDesktop ? styles.controlWithTooltip : null,
        ]}
        className={className}
        onClick={(e) => onClick(e)}
        disabled={disabled}
        type="button"
        data-testid={dataTestId}
      >
        {children}
        {showLabel && <span css={styles.label}>{label}</span>}
      </button>

      {showTooltip && (
        <ReactTooltip
          css={showTooltip && isDesktop ? styles.tooltip : null}
          arrowColor={theme.colors.secondary.blueOnBlack}
          id={dataTestId}
          place="top"
          effect="solid"
        >
          <span>{label}</span>
        </ReactTooltip>
      )}
    </>
  )
);

export default ControlButton;
