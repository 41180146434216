import React, { ReactElement } from "react";
import theme from "src/theme";
import { IComponentIconProps } from "../interfaces";

const PlayButtonIcon = ({
  height = theme.svgSize.xlarge,
  width = theme.svgSize.xlarge,
}: IComponentIconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.5889 24.7077L20.3122 31.3772C19.9067 31.6171 19.3761 31.4947 19.1272 31.1038C19.044 30.9731 19 30.8228 19 30.6694V17.3304C19 16.8718 19.3858 16.5 19.8616 16.5C20.0207 16.5 20.1767 16.5424 20.3122 16.6226L31.5889 23.2921C31.9945 23.532 32.1215 24.0434 31.8726 24.4343C31.8017 24.5457 31.7045 24.6394 31.5889 24.7077Z"
        fill="black"
      />
    </svg>
  );
};

export default PlayButtonIcon;
