import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  icon: css`
    display: flex;
    path {
      fill: ${theme.colors.secondary.blueOnBlackShadow} !important;
    }
    :hover {
      path {
        fill: ${theme.colors.secondary.blueOnBlack} !important;
      }
    }
  `,
  flip180: css`
    color: green;
    svg {
      transform: rotate(180deg);
    }
  `,
};
