import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { components, MenuProps, OptionProps } from "react-select";
import {
  Alert,
  Button,
  Checkbox,
  ErrorOvalIcon,
  Label,
} from "@songtradr/component-library";
import AsyncSelect from "src/components/async-select";
import { debounce } from "lodash";
import { LeftOutlined } from "@ant-design/icons";
import ReactTooltip from "react-tooltip";
import InfoIconWhite from "src/app/assets/icons/info-icon-white";
import { IYtChannelSelectOption } from "../brand-audit-form/interfaces";
import styles from "./styles";
import useYouTubeAudit from "./hooks/use-youtube-audit";
import { IProps } from "./interfaces";
import { isValidUrl, parseDuration } from "./utils";

const searchDebounceDelay = 500;

// If this component needs to be reused we should update this component to not
// use react-select. react-select re-renders `<Option>` when a YT video is
// selected/unselected which causes the YT video thumbnails to be re-fetched.
// The issue only happens if the browser has its cache explicitly disabled in dev tools
const VideoSelect = ({ handleSubmitVideoUrls }: IProps): ReactElement => {
  const [selectedChannel, setSelectedChannel] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [urlValue, setUrlValue] = useState("");
  const [urlsToAdd, setUrlsToAdd] = useState<Record<string, true>>({});
  const [failedImgs, setFailedImgs] = useState<Record<string, boolean>>({});
  const scrollAnchorRef = useRef<HTMLDivElement>(null);
  const scrollPositionRef = useRef<number>(0);
  const {
    youTubeVideos,
    handleYouTubeChannelSearch,
    handleFetchYouTubeVideos,
    isYtQuotaMaxed,
  } = useYouTubeAudit();

  const getMenuListElement = () =>
    scrollAnchorRef.current?.getElementsByClassName("menu-list-el")[0];

  // When a dropdown menu item is selected the scroll position is lost when it's
  // re-rendered. After the re-render lets restore it
  useEffect(() => {
    const element = getMenuListElement();

    if (element) {
      element.scrollTop = scrollPositionRef.current;
    }
  }, [urlsToAdd]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((searchValue, callback) => {
      if (isValidUrl(searchValue)) {
        callback([]);
        return;
      }

      handleYouTubeChannelSearch(searchValue, callback);
    }, searchDebounceDelay),
    []
  );

  const handleGetYouTubeVideos = async (youTubeChannel: string) => {
    setIsLoading(true);
    await handleFetchYouTubeVideos(youTubeChannel);
    setIsLoading(false);
  };

  const handleToggleUrl = (urlToAdd: string) => {
    const newUrlsToAdd = { ...urlsToAdd };

    if (newUrlsToAdd[urlToAdd]) {
      delete newUrlsToAdd[urlToAdd];
      setUrlsToAdd(newUrlsToAdd);
      return;
    }

    newUrlsToAdd[urlToAdd] = true;
    setUrlsToAdd(newUrlsToAdd);
  };

  const Menu = (props: MenuProps<IYtChannelSelectOption, false>) => {
    if (urlValue !== "" && !isValidUrl(urlValue)) {
      return <components.Menu {...props}>{props.children}</components.Menu>;
    }

    return null;
  };

  const Option = (props: OptionProps<IYtChannelSelectOption, false>) => {
    const { data } = props;

    if (isLoading) {
      return (
        <components.LoadingMessage {...props}>
          Loading...
        </components.LoadingMessage>
      );
    }

    if (selectedChannel === data.value && youTubeVideos) {
      return (
        <div>
          <button
            css={styles.backBtn}
            type="button"
            onClick={() => setSelectedChannel(undefined)}
          >
            <LeftOutlined />
            Back
          </button>

          {youTubeVideos.map((video, index) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              data-testid={`video-option-${index}`}
              tabIndex={0}
              role="button"
              css={styles.channelOption}
              key={video.Id.VideoId}
              onClick={() => {
                const element = getMenuListElement();

                // Save current scroll position, so we can restore it after re-render
                if (scrollAnchorRef.current && element) {
                  scrollPositionRef.current = element.scrollTop;
                }

                handleToggleUrl(
                  `https://www.youtube.com/watch?v=${video.Id.VideoId}`
                );
              }}
            >
              <Checkbox
                variant="primary"
                css={styles.videoCheckbox}
                checked={
                  urlsToAdd[
                    `https://www.youtube.com/watch?v=${video.Id.VideoId}`
                  ]
                }
              />

              {!failedImgs[`video-${video.Id.VideoId}`] && (
                <img
                  key={video.Id.VideoId}
                  css={[styles.channelThumbnail, styles.leftSpacing]}
                  src={video.Snippet.thumbnails.default.url}
                  alt={video.Snippet.Title ?? ""}
                  referrerPolicy="no-referrer"
                  onError={() =>
                    setFailedImgs({
                      ...failedImgs,
                      [`video-${video.Id.VideoId}`]: true,
                    })
                  }
                />
              )}

              <div css={[styles.titleContainer, styles.leftSpacing]}>
                <h2 css={styles.videoTitle}>{video.Snippet.Title}</h2>
                <p css={styles.videoDescription}>{video.Snippet.Description}</p>
              </div>

              <div css={[styles.leftSpacing, styles.videoMeta]}>
                {Number(video.Snippet.ViewCount ?? "").toLocaleString()} views
              </div>

              <div css={[styles.leftSpacing, styles.videoMeta]}>
                {parseDuration(video.Snippet.Duration ?? "")}
              </div>
            </div>
          ))}
        </div>
      );
    }

    if (!selectedChannel) {
      return (
        <button
          data-testid={`channel-option-${data.value}`}
          css={styles.channelOption}
          type="button"
          onClick={() => {
            setSelectedChannel(data.value);
            void handleGetYouTubeVideos(data.value);
          }}
        >
          {!failedImgs[`channel-${data.value}`] && (
            <img
              css={styles.channelThumbnail}
              src={data.thumbnail}
              alt={data.label}
              referrerPolicy="no-referrer"
              onError={() =>
                setFailedImgs({
                  ...failedImgs,
                  [`channel-${data.value}`]: true,
                })
              }
            />
          )}
          <div css={styles.leftSpacing}>{data.label}</div>
        </button>
      );
    }

    return null;
  };

  const handleSetUrlValue = (value: string) => {
    if (value === "") {
      return;
    }

    setUrlValue(value);
  };

  return (
    <>
      {isYtQuotaMaxed && (
        <Alert
          icon={<ErrorOvalIcon aria-hidden="true" />}
          title="YouTube Quota"
          variant="error"
          css={styles.ytError}
        >
          Please try again later, or alternatively you can paste in the YouTube
          video URLs manually.
        </Alert>
      )}
      <Label css={styles.label}>
        Video URL
        <>
          <ReactTooltip place="top" effect="solid">
            Dynamically search for YouTube videos or paste in a URL from any
            platform.
          </ReactTooltip>
          <span data-tip="tooltip">
            <InfoIconWhite />
          </span>
        </>
      </Label>
      <div css={styles.searchContainer}>
        <div css={styles.selectContainer} ref={scrollAnchorRef}>
          <AsyncSelect
            onInputChange={handleSetUrlValue}
            components={{
              Option,
              Menu,
              DropdownIndicator: null,
              IndicatorSeparator: null,
            }}
            placeholder="Begin typing a YouTube channel or paste in a URL"
            loadOptions={debouncedSearch}
            isLoading={isLoading}
            onMenuClose={() => {
              setSelectedChannel(undefined);
            }}
          />
        </div>

        <Button
          css={styles.addUrlBtn}
          variant="primary"
          type="button"
          data-testid="add-url-btn"
          disabled={
            urlValue === "" ||
            (!isValidUrl(urlValue) && !Object.keys(urlsToAdd).length)
          }
          onClick={() => {
            if (isValidUrl(urlValue)) {
              handleSubmitVideoUrls([urlValue]);
              setUrlValue("");
              return;
            }

            handleSubmitVideoUrls(Object.keys(urlsToAdd));
            setUrlValue("");
            setUrlsToAdd({});
          }}
        >
          Add URL
        </Button>
      </div>
    </>
  );
};

export default VideoSelect;
