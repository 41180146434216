import { IProjectTerm } from "src/pages/projects/client-project-view/interfaces";
import {
  add,
  differenceInCalendarMonths,
  differenceInDays,
  differenceInCalendarYears,
  format,
  intervalToDuration,
  parseISO,
  isPast,
  parse,
} from "date-fns";
import { LicenseFixedDurations, TermStatus } from "src/constants";
import { ITerm } from "src/pages/projects/components/project-listings/interfaces";
import dayJSHelper from "src/sumo/src/utils/dayJSHelper";

const determineTermStatus = (endDate?: string) => {
  const now = new Date();
  if (!endDate) {
    return TermStatus.EXPIRED;
  }
  if (parseISO(endDate) < now) {
    return TermStatus.EXPIRED;
  }
  const durationRemaining = intervalToDuration({
    start: now,
    end: parseISO(endDate),
  });
  if (durationRemaining.years || durationRemaining.months) {
    return TermStatus.ACTIVE;
  }
  if (durationRemaining.days && durationRemaining.days > 7) {
    return TermStatus.RENEW;
  }
  return TermStatus.URGENT;
};

const generateDurationOrTimeRemainingText = (
  durationOrTimeRemaining: number,
  word: "day" | "month" | "year"
) => {
  if (durationOrTimeRemaining > 0) {
    if (durationOrTimeRemaining > 1) {
      return `${durationOrTimeRemaining} ${word}s`;
    }

    return `${durationOrTimeRemaining} ${word}`;
  }

  return "Expired";
};

const calculateDuration = (term: IProjectTerm | ITerm): string => {
  let duration = "0";
  // Setting the duration text if it isn't already provided from server
  if (term.fixedDuration) {
    duration = term.fixedDuration.valueOf();
  } else if (term.startDate && term.endDate) {
    const years = differenceInCalendarYears(
      parseISO(term.endDate),
      parseISO(term.startDate)
    );

    const months = differenceInCalendarMonths(
      parseISO(term.endDate),
      parseISO(term.startDate)
    );

    const days = differenceInDays(
      parseISO(term.endDate),
      parseISO(term.startDate)
    );

    if (months >= 24) {
      duration = generateDurationOrTimeRemainingText(years, "year");
    } else if (months > 1) {
      duration = generateDurationOrTimeRemainingText(months, "month");
    } else if (days > 1) {
      // Custom term date is less than 1 month so we must provide the date in days.
      duration = generateDurationOrTimeRemainingText(days, "day");
    }
  }
  return duration;
};

const calculateEndDate = (
  startDate: string,
  duration: LicenseFixedDurations
) => {
  switch (duration) {
    case LicenseFixedDurations.ThreeMonths:
      return format(add(parseISO(startDate), { months: 3 }), "MMM dd, yyyy");
    case LicenseFixedDurations.SixMonths:
      return format(add(parseISO(startDate), { months: 6 }), "MMM dd, yyyy");
    case LicenseFixedDurations.OneYear:
      return format(add(parseISO(startDate), { years: 1 }), "MMM dd, yyyy");
    case LicenseFixedDurations.EighteenMonths:
      return format(add(parseISO(startDate), { months: 18 }), "MMM dd, yyyy");
    case LicenseFixedDurations.TwoYears:
      return format(add(parseISO(startDate), { years: 2 }), "MMM dd, yyyy");
    case LicenseFixedDurations.ThreeYears:
      return format(add(parseISO(startDate), { years: 3 }), "MMM dd, yyyy");
    case LicenseFixedDurations.FourYears:
      return format(add(parseISO(startDate), { years: 4 }), "MMM dd, yyyy");
    case LicenseFixedDurations.FiveYears:
      return format(add(parseISO(startDate), { years: 5 }), "MMM dd, yyyy");
    default:
      return "";
  }
};

// allows for formats 01/06/2022 or 2022-06-01 00:00:00.000Z
// set parseAsISODate to true for a date in format 2022-06-01 00:00:00.000Z
const isTermStartDateInThePast = (
  startDate?: string,
  parseAsISODate: boolean = false
): boolean => {
  if (startDate) {
    const parsedStartDate = parseAsISODate
      ? parseISO(startDate)
      : parse(startDate, "dd/MM/yyyy", new Date());
    return isPast(parsedStartDate);
  }
  return true;
};

const getDaysRemaining = (endDate?: string): number => {
  if (endDate) {
    if (isPast(parseISO(endDate))) return 0;
    return differenceInDays(parseISO(endDate), new Date());
  }
  return 0;
};

const getMonthsRemaining = (endDate?: string): number => {
  if (endDate) {
    return differenceInCalendarMonths(parseISO(endDate), new Date());
  }
  return 0;
};

const getYearsRemaining = (endDate?: string): number => {
  if (endDate) {
    return differenceInCalendarYears(
      new Date(dayJSHelper.parseApiDate(endDate).toISOString()),
      new Date()
    );
  }
  return 0;
};

const calculateTimeRemaining = (endDate?: string): string => {
  let timeRemaining = "";
  const yearsRemaining = getYearsRemaining(endDate);
  const monthsRemaining = getMonthsRemaining(endDate);
  const daysRemaining = getDaysRemaining(endDate);
  if (monthsRemaining >= 24) {
    timeRemaining = generateDurationOrTimeRemainingText(yearsRemaining, "year");
  } else if (monthsRemaining > 1) {
    timeRemaining = generateDurationOrTimeRemainingText(
      monthsRemaining,
      "month"
    );
  } else {
    // term end date is less than 1 month so we must provide the date in days.
    timeRemaining = generateDurationOrTimeRemainingText(daysRemaining, "day");
  }
  return timeRemaining;
};

const getEndDateFromTerm = (term?: IProjectTerm | ITerm): string => {
  let endDate = "";
  if (term) {
    if (term.endDate) endDate = term.endDate;
    else if (term.startDate && term.fixedDuration)
      endDate = calculateEndDate(term.startDate, term.fixedDuration);
  }
  return endDate;
};

const calculateTimeRemainingFromTerm = (
  term?: IProjectTerm | ITerm
): string => {
  const endDate = getEndDateFromTerm(term);
  const status = determineTermStatus(endDate);

  if (status === TermStatus.EXPIRED) {
    return "0 days";
  }
  return calculateTimeRemaining(endDate);
};

export default {
  generateDurationOrTimeRemainingText,
  determineTermStatus,
  calculateDuration,
  calculateEndDate,
  isTermStartDateInThePast,
  getDaysRemaining,
  getMonthsRemaining,
  getYearsRemaining,
  calculateTimeRemaining,
  getEndDateFromTerm,
  calculateTimeRemainingFromTerm,
};
