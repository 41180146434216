import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

export default {
  sectionTitle: css`
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 700;
    font-size: 2rem;
    line-height: 2.25rem;
    letter-spacing: -2%;
    color: ${theme.colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 1rem;
  `,
  sectionSubtitle: css`
    @media ${queries.medium} {
      padding-left: 1rem;
      border-left: 1px solid ${theme.colors.darkCharcoal};
    }
    font-family: ${theme.fonts.sofiaPro};
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${theme.colors.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  titleAndSubtitle: css`
    align-items: baseline;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
};
