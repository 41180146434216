import React, { ReactElement } from "react";
import { Form } from "antd";
import { ValidationMessage } from "@songtradr/component-library";
import Select, { SingleValue } from "react-select";
import FloatingLabel from "../floating-label";
import { IFloatingLabelSelectProps } from "./interfaces";
import styles from "./styles";
import { IOption } from "../custom-select/interfaces";

const FloatingLabelSelect = (
  props: IFloatingLabelSelectProps
): ReactElement => {
  const {
    value,
    label,
    helpMessage,
    required,
    name,
    id,
    options,
    onChange,
  } = props;
  return (
    <div css={styles.dropdownContainer}>
      <FloatingLabel value={value} label={label}>
        <Form.Item
          name={name}
          validateTrigger="onChange"
          rules={[
            {
              required,
              whitespace: true,
              message: (
                <ValidationMessage type="error">
                  {helpMessage}
                </ValidationMessage>
              ),
            },
          ]}
        >
          <Select
            id={id}
            className="dropdown"
            classNamePrefix="dropdown-options"
            aria-label={label}
            data-testid={id}
            placeholder=""
            css={styles.selectionInput}
            components={{
              IndicatorSeparator: null,
            }}
            onChange={(option: SingleValue<IOption>) =>
              onChange(name, option?.value)
            }
            options={options}
            value={{
              label: value,
              value,
            }}
          />
        </Form.Item>
      </FloatingLabel>
    </div>
  );
};

export default FloatingLabelSelect;
