import { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { cloneDeep } from "lodash";
import { LibraryTrackPageSize } from "src/constants";
import csvDownload from "json-to-csv-export";
import { IClientOrgProjectProps } from "src/pages/projects/components/project-listings/interfaces";
import LibraryTableActions from "src/pages/projects/components/project-listings/library-table-actions";
import useDebounce from "src/utils/hooks/use-debounce";
import LibraryTrackTable from "./library-track-table";
import {
  ILibraryTrackDetailsChannel,
  IProjectLibraryTrackDetails,
  ILibraryTrackTableSort,
  LibraryTrackSortType,
  SortOrder,
} from "./interfaces";
import getExportDataSource from "./table-data/get-export-data-source";

interface IProps {
  isLoading: boolean;
  libraryTracks: IProjectLibraryTrackDetails[];
  projects: IClientOrgProjectProps[];
  selectedProjectId: string;
  isLoadingProjects: boolean;
  onProjectChange: (projectId: string) => void;
}

const LibraryListings = ({
  isLoading,
  libraryTracks,
  projects,
  selectedProjectId,
  isLoadingProjects,
  onProjectChange,
}: IProps): ReactElement => {
  const [libraryTracksCurrentPage, setLibraryTrackCurrentPage] = useState(1);
  const [libraryTrackSearchText, setLibraryTrackSearchText] = useState("");
  const debouncedSearchTerm = useDebounce(libraryTrackSearchText, 250);
  const [libraryTracksSortOption, setLibraryTracksSortOption] = useState({
    sortBy: LibraryTrackSortType.createdDate,
    order: SortOrder.DESC,
  });

  const handleLibraryTrackPageChange = useCallback((page: number) => {
    setLibraryTrackCurrentPage(page);
  }, []);

  const handleLibraryTrackUpdateSearchText = useCallback((text: string) => {
    setLibraryTrackSearchText(text);
  }, []);

  const handleLibraryTrackSortChange = useCallback(
    (sort: ILibraryTrackTableSort) => {
      setLibraryTracksSortOption(sort);
    },
    []
  );

  const projectLibraryTracks = useMemo(() => {
    let searchedTracks = cloneDeep(libraryTracks);

    let searchText =
      libraryTrackSearchText === debouncedSearchTerm
        ? libraryTrackSearchText
        : debouncedSearchTerm;
    searchText = debouncedSearchTerm.toLocaleLowerCase();
    const isStringIncludedInText = (text: string) => {
      return (text ?? "").toLocaleLowerCase().includes(searchText);
    };
    const isStringIncludedInChannelsArray = (
      channels: ILibraryTrackDetailsChannel[],
      prop: "type" | "name" | "id"
    ) => {
      return (channels ?? [])
        .map((channel) => channel[prop])
        .filter((channelType) => channelType)
        .join(", ")
        .toLocaleLowerCase()
        .includes(searchText);
    };

    if (searchText !== "") {
      searchedTracks = libraryTracks.filter((track) => {
        return (
          isStringIncludedInText(track.trackId) ||
          isStringIncludedInText(track.trackTitle) ||
          isStringIncludedInText(track.trackArtist) ||
          isStringIncludedInText(track.downloadedByUser.email) ||
          isStringIncludedInText(track.downloadedByUser.name) ||
          isStringIncludedInText(track.declaredByUser.email) ||
          isStringIncludedInText(track.declaredByUser.name) ||
          isStringIncludedInText(track.projectName) ||
          isStringIncludedInText(track.projectDescription) ||
          isStringIncludedInText(track.businessBrand) ||
          isStringIncludedInText(track.regions.join(", ")) ||
          isStringIncludedInChannelsArray(track.channels, "type") ||
          isStringIncludedInChannelsArray(track.channels, "name") ||
          isStringIncludedInChannelsArray(track.channels, "id") ||
          isStringIncludedInText(
            ((track.channels ?? [])?.[0]?.usageLinks ?? "").join(", ")
          )
        );
      });
    }
    const { sortBy, order } = libraryTracksSortOption;

    if (sortBy === LibraryTrackSortType.declaredBy) {
      return searchedTracks.sort((a, b) => {
        return order === SortOrder.ASC
          ? (a.declaredByUser.name ?? "").localeCompare(
              b.declaredByUser.name ?? ""
            )
          : (b.declaredByUser.name ?? "").localeCompare(
              a.declaredByUser.name ?? ""
            );
      });
    }
    if (sortBy === LibraryTrackSortType.downloadedBy) {
      return searchedTracks.sort((a, b) => {
        return order === SortOrder.ASC
          ? (a.declaredByUser.name ?? "").localeCompare(
              b.declaredByUser.name ?? ""
            )
          : (b.declaredByUser.name ?? "").localeCompare(
              a.declaredByUser.name ?? ""
            );
      });
    }

    const sortProp:
      | "trackId"
      | "trackTitle"
      | "trackArtist"
      | "createdDate"
      | "projectName"
      | "projectDescription"
      | "businessBrand"
      | "goLiveDate" = sortBy || "createdDate";

    return searchedTracks.sort((a, b) => {
      return order === SortOrder.ASC
        ? (a[sortProp] ?? "").localeCompare(b[sortProp] ?? "")
        : (b[sortProp] ?? "").localeCompare(a[sortProp] ?? "");
    });
  }, [
    debouncedSearchTerm,
    libraryTrackSearchText,
    libraryTracks,
    libraryTracksSortOption,
  ]);

  useEffect(() => {
    if (
      Math.ceil(projectLibraryTracks.length / LibraryTrackPageSize) <
      libraryTracksCurrentPage
    ) {
      handleLibraryTrackPageChange(1);
    }
  }, [
    handleLibraryTrackPageChange,
    libraryTracksCurrentPage,
    projectLibraryTracks.length,
  ]);

  const dataHeaders = useMemo(() => {
    return [
      "Track ID",
      "Track Title",
      "Track Artist",
      "Downloaded by",
      "Declared by",
      "Declared Date",
      "Project Name",
      "Project Description",
      "Business Unit/Brand",
      "Territories",
      "Go live date",
      "Youtube Channel/s",
      "Facebook Page/s",
      "Instagram Page/s",
      "TikTok account/s",
      "X account/s",
      "Company website",
      "Other",
      "Video links",
    ];
  }, []);

  const handleExportAsCSV = useCallback(() => {
    const dataToConvert = {
      data: getExportDataSource(projectLibraryTracks),
      filename: `music_usage_declaration_${Date.now()}`,
      delimiter: ",",
      headers: dataHeaders,
    };

    return csvDownload(dataToConvert);
  }, [dataHeaders, projectLibraryTracks]);

  return (
    <div>
      <LibraryTableActions
        dropdownSelect={{
          projects,
          selectedProjectId,
          disabled: isLoadingProjects,
          onChange: onProjectChange,
        }}
        search={{
          searchText: libraryTrackSearchText,
          onUpdateSearchText: handleLibraryTrackUpdateSearchText,
          onClearSearchText: () => handleLibraryTrackUpdateSearchText(""),
        }}
        button={{
          label: "ClientProjectViewPage##Export as CSV",
          onClick: handleExportAsCSV,
        }}
      />
      <LibraryTrackTable
        isLoading={isLoading || libraryTrackSearchText !== debouncedSearchTerm}
        libraryTracks={projectLibraryTracks}
        paginationOptions={{
          total: projectLibraryTracks.length,
          currentPage: libraryTracksCurrentPage,
        }}
        handlePageChange={handleLibraryTrackPageChange}
        debouncedSearchTerm={debouncedSearchTerm}
        sortOptions={libraryTracksSortOption}
        handleSort={handleLibraryTrackSortChange}
      />
    </div>
  );
};

export default LibraryListings;
