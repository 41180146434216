import axios from "axios";
import { eApplicationType } from "src/interfaces/auth";
import {
  IOrganisationItem,
  IOrganisationMemberProps,
  IOrganisationMembersResponse,
  OrgMembersSortType,
} from "src/pages/team/interfaces";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";
import { getOrganisationMembersUrl } from "../../organisation-members-url-helper";

interface IGetOrganisationMembersResponse {
  orgMembers: Array<IOrganisationMemberProps>;
  total: number;
  start?: number;
}

const getOrganisationMembers = async (
  accessToken: string,
  orgID: string,
  childMembers: boolean,
  currentUserID?: string,
  abortController?: AbortController,
  start = 0,
  emailFilter?: string,
  searchText?: string,
  orderBy = "DESC",
  sort = OrgMembersSortType.CreatedDate,
  perPage = 10
): Promise<IGetOrganisationMembersResponse> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    ...{ signal: abortController?.signal },
  };
  const { data } = await axios.post<IOrganisationMembersResponse>(
    getOrganisationMembersUrl(orgID),
    {
      start,
      perPage,
      orderBy,
      sort,
      includeChildAndLinkedOrgMembers: childMembers,
      emailFilter,
      searchText,
    },
    options
  );

  const isCurrentUser = (userIdToCheck: string): boolean => {
    return userIdToCheck === currentUserID;
  };

  const orgMembers: Array<IOrganisationMemberProps> = data.items?.map(
    (orgMember: IOrganisationItem) => {
      const { organisationId } = orgMember;
      const hasProjectsSubscription =
        orgMember.applications.includes(eApplicationType.ProjectsInternal) ||
        orgMember.applications.includes(eApplicationType.ProjectsClient);

      const hasMusicSubscription =
        orgMember.applications.includes(eApplicationType.LibraryFull) ||
        orgMember.applications.includes(eApplicationType.LibraryReadOnly);

      const {
        firstName,
        lastName,
        id,
        auth0UserId,
        email,
        phoneNumber,
        jobTitle,
        applications,
        controllingOrgId,
        projectsWhitelist,
        useProjectsWhitelist,
        profileImage,
        audit,
      } = orgMember;

      const newOrgMember: IOrganisationMemberProps = {
        hasMusicSubscription,
        hasProjectsSubscription,
        organisationId,
        isCurrentUser: isCurrentUser(auth0UserId),
        key: id,
        firstName,
        lastName,
        jobTitle,
        phoneNumber,
        id,
        auth0UserId,
        email,
        applications,
        controllingOrgId,
        projectsWhitelist,
        useProjectsWhitelist,
        profileImage,
        audit,
      };

      return newOrgMember;
    }
  );

  return {
    orgMembers,
    total: data.total,
  };
};

export default getOrganisationMembers;
