import { Typography } from "antd";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import STLoadingLogo from "src/components/st-loading-logo";
import style from "../../styles";
import { IClientOrgProjectProps } from "../../interfaces";

interface IProps {
  activeProject?: IClientOrgProjectProps;
}

const ProjectInfoDrawer = ({ activeProject }: IProps): ReactElement => {
  const { t } = useTranslation();

  if (activeProject) {
    return (
      <div className="projects-info-drawer" data-testid="projects-info-drawer">
        <div css={[style.drawerContentContainer, style.projectInfoDrawer]}>
          <div css={style.drawerInfoContainer}>
            <Typography.Title css={style.drawerHeader} level={5}>
              {t("ProjectsPage##Project name")}
            </Typography.Title>
            <Typography.Text>{activeProject?.name}</Typography.Text>
            <Typography.Text className="secondary">
              {activeProject.client?.name}
            </Typography.Text>
          </div>
          <div css={style.drawerInfoContainer}>
            <Typography.Title css={style.drawerHeader} level={5}>
              {t("ProjectsPage##Track name")}
            </Typography.Title>
            <Typography.Text>{activeProject?.name}</Typography.Text>
          </div>
          <div css={style.drawerInfoContainer}>
            <Typography.Title css={style.drawerHeader} level={5}>
              {t("ProjectsPage##Client ref ID")}
            </Typography.Title>
            <Typography.Text>
              {activeProject?.clientReferenceNo}
            </Typography.Text>
          </div>
          <div> test</div>
          <div css={style.drawerInfoContainer}>
            <Typography.Title css={style.drawerHeader} level={5}>
              {t("ProjectsPage##Start date")}
            </Typography.Title>
            <Typography.Text>
              {/* TODO - Add active project term mapping */}
              Start Date
            </Typography.Text>
          </div>
          <div css={style.drawerInfoContainer}>
            <Typography.Title css={style.drawerHeader} level={5}>
              {t("ProjectsPage##Duration")}
              <Typography.Text>
                {/* TODO - Add active project term duration mapping */}
                Terms
              </Typography.Text>
            </Typography.Title>
          </div>
          <div css={style.drawerInfoContainer}>
            <Typography.Title css={style.drawerHeader} level={5}>
              {t("ProjectsPage##Remaining")}
              <Typography.Text>
                0 Days Remaining
                {/* TODO - Calculate remaining */}
              </Typography.Text>
            </Typography.Title>
          </div>
          <div css={style.drawerInfoContainer}>
            <Typography.Title css={style.drawerHeader} level={5}>
              {t("ProjectsPage##Territories")}
              <Typography.Text>
                {/* TODO - Map over territories */}
                Territories
              </Typography.Text>
            </Typography.Title>
          </div>
          <div css={style.drawerInfoContainer}>
            <Typography.Title css={style.drawerHeader} level={5}>
              {t("ProjectsPage##Media")}
              <Typography.Text>
                {/* TODO - Map over media types */}
                Media Type
              </Typography.Text>
            </Typography.Title>
          </div>
        </div>
      </div>
    );
  }

  return <STLoadingLogo />;
};

export default ProjectInfoDrawer;
