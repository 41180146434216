import { eApplicationType } from "src/interfaces/auth";
import { IPaginationProps } from "src/interfaces/pagination";
import { IRelatedOrganisation } from "src/interfaces/related-organisations";

export interface IOrganisationMember {
  id: string;
  key: string;
  firstName: string;
  lastName: string;
  email: string;
  organisationId: string;
  controllingOrgId: string;
  hasMusicSubscription?: boolean;
  hasProjectsSubscription?: boolean;
  isCurrentUser?: boolean;
  auth0UserId: string;
  jobTitle?: string;
  phoneNumber?: string;
  country?: string;
  audit: [{ dateTime: string; description: string }];
  companyName?: string;
  companyUrl?: string;
  useProjectsWhitelist: boolean;
  projectsWhitelist: string[];
  applications: eApplicationType[];
  profileImage: string;
  isBlessedSuperAdmin?: boolean;
}

export interface IOrganisationMemberProps extends IOrganisationMember {
  key: string;
  isCurrentUser: boolean;
  isAdmin?: boolean;
}

export interface IMembersInviteResponse extends IPaginationProps {
  items: [IMemberInvite];
}

export interface IMemberInvite {
  application: string;
  email: string;
  expiryDate: string;
  firstName: string;
  id: string;
  lastName: string;
  role: string;
  sentDate: string;
}

export interface IOrganisationItem {
  applications: eApplicationType[];
  audit: [
    {
      dateTime: string;
      description: string;
    }
  ];
  auth0UserId: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  organisationId: string;
  controllingOrgId: string;
  phoneNumber?: string;
  jobTitle?: string;
  useProjectsWhitelist: boolean;
  projectsWhitelist: string[];
  profileImage: string;
}
export interface IOrganisationMembersResponse extends IPaginationProps {
  items: IOrganisationItem[];
}

export interface ITableSort {
  sortType: OrgMembersSortType;
  sortOrder: SortOrder;
}

export enum OrgMembersSortType {
  Name = "Name",
  LastLogin = "Last Login",
  CreatedDate = "CreatedDate",
}

export enum SortOrder {
  Ascending = "Ascending",
  Descending = "Descending",
}
