import { css } from "@emotion/react";
import { queries } from "@songtradr/spa-common";
import theme from "src/theme";

const moduleTilePadding = "10px";

export default {
  modulesSectionContainer: css`
    position: relative;
    margin-top: 48px;
    @media ${queries.extraLarge} {
      margin-top: 96px;
    }
  `,
  modulesHeaderContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  carouselContainer: css`
    margin-left: -${moduleTilePadding};
    margin-top: 30px;
    height: 440px;

    .swiper-pagination {
      bottom: 0;

      @media ${queries.medium} {
        display: none;
      }
    }

    .swiper-container {
      padding-bottom: 3rem;
      padding-top: 1rem;

      @media ${queries.medium} {
        padding-bottom: 1rem;
      }
    }

    .swiper-pagination-bullet {
      background: ${theme.colors.secondary.mediumGrey};
    }

    .swiper-pagination-bullet-active {
      background: ${theme.colors.secondary.waveformGray};
    }

    .swiper-slide {
      transition: all 0.15s ease-in-out;
      text-align: left;
      width: 80%;
      max-width: 300px;

      &:hover {
        cursor: pointer;
        transform: scale(1.035);
      }

      @media ${queries.small} {
        width: 30%;
      }

      @media ${queries.extraLarge} {
        width: 25%;
      }
    }
  `,
};
