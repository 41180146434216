import React, { ReactElement } from "react";
import { Modal, Button } from "antd";
import style from "./style";

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

const MobileWarnModal = ({ isOpen, setIsOpen }: IProps): ReactElement => {
  const handleOk = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      visible={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
      css={style.modal}
    >
      <h2 css={style.sectionHeader}>
        This page is not optimised for mobile devices.{" "}
      </h2>
      <p css={style.text}>
        For a better experience, with access to all features, consider switching
        to a desktop device.{" "}
      </p>
      <Button onClick={() => handleOk()} css={style.okButton}>
        Ok
      </Button>
    </Modal>
  );
};

export default MobileWarnModal;
