import { css } from "@emotion/react";
import theme from "src/theme";

export default {
  btn: css`
    appearance: none;
    background: transparent;
    cursor: pointer;
    padding-bottom: 0.5rem;
    border: 0;
    border-bottom: 2px solid transparent;
  `,
  hasUnderline: css`
    border-bottom: 2px solid ${theme.colors.functional.brightOrange};
  `,
};
