import { useMountedRef } from "@songtradr/spa-common/lib/utils";
import { AxiosError, isAxiosError } from "axios";
import React, { ReactElement, useCallback, useRef, useState } from "react";
import getOrganisationInvite from "src/api/organisation-invites/get-org-invite";
import { IOrganisationInvite, IProfileForm } from "src/interfaces/organisation";
import { IOrganisationUrlParams } from "src/interfaces/organisation-url-params";
import UserProfile from "..";
import acceptOrganisationInvite from "../../../api/organisation-invites/accept-org-invite";
import useAuth from "../../../auth/use-auth";

function UserProfileContainer(
  orgParams: IOrganisationUrlParams
): ReactElement | null {
  const isMountedRef = useMountedRef();
  const { getAccessToken, handleLogin } = useAuth();
  const [isSavePending, setIsSavePending] = useState(false);
  const [organisationInvite, setOrganisationInvite] = useState<
    IOrganisationInvite | undefined | AxiosError
  >(undefined);
  const abortController = useRef<AbortController>(new AbortController());

  const submitForm = async (formData: IProfileForm) => {
    if (isAxiosError(organisationInvite)) {
      return;
    }

    const accessToken = getAccessToken();
    setIsSavePending(true);
    try {
      if (organisationInvite !== undefined) {
        const requestBody = {
          ...organisationInvite,
          ...orgParams,
          ...formData,
          email: organisationInvite.email,
        };

        await acceptOrganisationInvite(
          accessToken,
          orgParams.organisationId,
          orgParams.organisationInviteId,
          requestBody,
          abortController.current
        );
      }

      if (organisationInvite?.email)
        handleLogin(organisationInvite.email, formData.password);
    } finally {
      if (isMountedRef.current) setIsSavePending(false);
    }
  };

  const getData = useCallback(async () => {
    const accessToken = getAccessToken();
    try {
      const organisationInviteResponse = await getOrganisationInvite(
        accessToken,
        orgParams.organisationId,
        orgParams.organisationInviteId,
        abortController.current
      );
      if (isMountedRef.current)
        setOrganisationInvite(organisationInviteResponse);
    } catch (e: any) {
      if (isMountedRef.current) setOrganisationInvite(e);
    }
  }, [
    getAccessToken,
    isMountedRef,
    orgParams.organisationId,
    orgParams.organisationInviteId,
  ]);

  function callGetData(): IOrganisationInvite | undefined | AxiosError {
    void getData();
    return organisationInvite;
  }

  return (
    <UserProfile
      getData={callGetData}
      organisationInvite={organisationInvite}
      onSubmit={submitForm}
      isSavePending={isSavePending}
    />
  );
}

export default UserProfileContainer;
