import axios from "axios";
import config from "src/config/config";
import {
  getAuthHeader,
  getAxiosOptions,
  getJsonContentHeader,
} from "../../headers";

const getChildOrgsCount = async (
  accessToken: string,
  orgId: string,
  abortController: AbortController
): Promise<{ count: number }> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...getAxiosOptions(),
    signal: abortController.signal,
  };

  const { data } = await axios.get(
    `${config.projectManagementService.url}/projects/count-child-orgs?orgId=${orgId}`,
    options
  );

  return data;
};

export default getChildOrgsCount;
