import {
  Fragment,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getIsMobile, getIsTablet, useWindowSize } from "@songtradr/spa-common";
import Table from "src/components/table";
import { LibraryTrackPageSize } from "src/constants";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import STLoadingLogo from "src/components/st-loading-logo";
import {
  IProjectLibraryTrackDetails,
  ILibraryTrackTableSort,
} from "../interfaces";
import style from "../styles";
import getColumns from "../table-data/get-columns";
import getDataSource from "../table-data/get-data-source";
import projectsMainStyles from "../../../../styles";

interface IProps {
  isLoading: boolean;
  libraryTracks: IProjectLibraryTrackDetails[];
  handlePageChange: (page: number) => void;
  paginationOptions: { total: number; currentPage: number };
  debouncedSearchTerm: string;
  sortOptions: ILibraryTrackTableSort;
  handleSort: (params: ILibraryTrackTableSort) => void;
}

const LibraryTrackTable = ({
  isLoading,
  libraryTracks,
  handlePageChange,
  paginationOptions,
  debouncedSearchTerm,
  handleSort,
  sortOptions,
}: IProps): ReactElement => {
  const [paginationRange, setPaginationRange] = useState([
    1,
    paginationOptions.total > 0 &&
    paginationOptions.total < LibraryTrackPageSize
      ? paginationOptions.total
      : LibraryTrackPageSize,
  ]);

  useWindowSize();
  const isTablet = getIsTablet();
  const isMobile = getIsMobile();
  const columns = getColumns(isMobile, isTablet, handleSort, sortOptions);
  const dataSource = getDataSource(libraryTracks);

  useEffect(() => {
    if (paginationOptions.total > 0) {
      const min =
        (paginationOptions.currentPage - 1) * LibraryTrackPageSize + 1;
      const max =
        paginationOptions.total <
        LibraryTrackPageSize * paginationOptions.currentPage
          ? paginationOptions.total
          : LibraryTrackPageSize * paginationOptions.currentPage;
      setPaginationRange([min, max]);
    }
  }, [paginationOptions.total, paginationOptions.currentPage]);

  const onPageChange = (page: number) => {
    handlePageChange(page);
    if (page === 1) {
      setPaginationRange([
        1,
        paginationOptions.total < LibraryTrackPageSize
          ? paginationOptions.total
          : LibraryTrackPageSize,
      ]);
      return;
    }
    const min = (page - 1) * LibraryTrackPageSize + 1;
    const max =
      min + LibraryTrackPageSize < paginationOptions.total
        ? min + LibraryTrackPageSize - 1
        : paginationOptions.total;
    setPaginationRange([min, max]);
  };

  const itemRender = useCallback(
    (
      page: number,
      type: "prev" | "page" | "next" | "jump-prev" | "jump-next",
      originalElement: ReactNode
    ) => {
      if (type === "prev") {
        return <ArrowLeftOutlined />;
      }
      if (type === "next") {
        return <ArrowRightOutlined />;
      }
      return originalElement;
    },
    []
  );

  const emptyText = useMemo(
    () => (isLoading ? <Fragment /> : "No declared tracks found"),
    [isLoading]
  );

  return (
    <div css={projectsMainStyles.getTableStyles(false)}>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={{
          spinning: isLoading,
          indicator: <STLoadingLogo relativePosition />,
          style: { height: "300px" },
        }}
        pagination={{
          pageSize: LibraryTrackPageSize,
          total: paginationOptions.total,
          current: paginationOptions.currentPage,
          position: ["bottomCenter"],
          onChange: (page) => onPageChange(page),
          showSizeChanger: false,
          showLessItems: true,
          itemRender,
        }}
        locale={{ emptyText }}
        scroll={{ x: true }}
      />
      {libraryTracks.length === 0 && debouncedSearchTerm === "" ? null : (
        <div
          css={style.paginationCount}
        >{`Showing ${paginationRange[0]}-${paginationRange[1]} of ${paginationOptions.total} results`}</div>
      )}
    </div>
  );
};

export default LibraryTrackTable;
