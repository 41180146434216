import { ReactElement } from "react";

const Link = (): ReactElement => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.68193 17.1584L3.06786 10.5443C2.89274 10.3692 2.89274 10.0844 3.06786 9.90925L4.2475 8.72961C4.42262 8.55449 4.70743 8.55449 4.88254 8.72961L9.68257 13.5296C9.85769 13.7047 10.1425 13.7047 10.3176 13.5296L15.1176 8.72961C15.2928 8.55449 15.5776 8.55449 15.7527 8.72961L16.9323 9.9086C17.1074 10.0837 17.1074 10.3685 16.9323 10.5436L10.3183 17.1577C10.1431 17.3328 9.85833 17.3328 9.68321 17.1577L9.68193 17.1584Z" />
      <path d="M10.8336 0H9.16582C8.91783 0 8.7168 0.201033 8.7168 0.44902V15.2128C8.7168 15.4608 8.91783 15.6618 9.16582 15.6618H10.8336C11.0816 15.6618 11.2826 15.4608 11.2826 15.2128V0.44902C11.2826 0.201033 11.0816 0 10.8336 0Z" />
      <path d="M19.551 17.4246H0.44902C0.201033 17.4246 0 17.6256 0 17.8736V19.5414C0 19.7894 0.201033 19.9904 0.44902 19.9904H19.551C19.799 19.9904 20 19.7894 20 19.5414V17.8736C20 17.6256 19.799 17.4246 19.551 17.4246Z" />
    </svg>
  );
};
export default Link;
