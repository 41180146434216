import { UploadFile } from "antd/lib/upload/interface";
import { Exclusivity, LicenseFixedDurations } from "src/constants";

export enum ProjectTypes {
  ProductionMusic = "ProductionMusic",
  License = "License",
  Other = "Other",
  EnterpriseLibrary = "EnterpriseLibrary",
}

export enum ProjectStatus {
  NewOpen = "NewOpen",
  InProgress = "InProgress",
  InContractInvoiced = "InContractInvoiced",
  Complete = "Complete",
  OnHold = "OnHold",
  Cancelled = "Cancelled",
  Deleted = "Deleted",
}

export interface IProjectClient {
  id?: string;
  name?: string;
  holdingCompany?: {
    name?: string;
    id?: string;
  };
}

export interface IProjectTerm {
  id: string;
  name: string;
  type: string;
  mediaType: string[];
  customMediaType: string[];
  territories: ITerritory;
  startDate?: string;
  endDate?: string;
  fixedDuration?: LicenseFixedDurations;
  noOfFilms: string;
  durationOfFilms: string;
  exclusivity?: Exclusivity;
  notes: string;
  lastUpdated: string;
  lastUpdatedBy: string;
  version: number;
}

export default interface ITermsSection {
  terms: IProjectTerm[];
}

export interface IProjectAttachment extends UploadFile {
  id: string;
  name: string;
  contentType: string;
  attachmentType: string;
  documentCategory?: string;
  uploaded: {
    userId: string;
    userFullname: string;
    dateTime: string;
  };
  percent: number;
  type: string;
  uid: string;
  size: number;
}

export interface ITerritory {
  worldwide: boolean;
  excludedCountries: string[];
  includedCountries: string[];
}
