import axios from "axios";
import buildOrganisationsBaseUrl from "src/api/base-url-helper";
import setOrgDefaults from "./transformer";
import { IOrganisation } from "../../../interfaces/organisation";
import { getJsonContentHeader, getAuthHeader } from "../../headers";

const getOrganisation = async (
  organisationId: string,
  accessToken: string,
  abortController?: AbortController
): Promise<IOrganisation | null> => {
  const options = {
    headers: { ...getAuthHeader(accessToken), ...getJsonContentHeader() },
    ...(abortController ? { signal: abortController.signal } : null),
  };

  const { data } = await axios.get<IOrganisation>(
    buildOrganisationsBaseUrl(organisationId),
    options
  );

  return setOrgDefaults(data);
};

export default getOrganisation;
