import React, { useMemo } from "react";
import { getIsDesktop, useWindowSize } from "@songtradr/spa-common";
import appStyles from "src/app/styles";
import getGreeting from "./utils";
import styles from "./styles";

interface ILandingHeadingProps {
  logoUrl?: string;
  userName: string;
}

const LandingHeading = ({ logoUrl = "", userName }: ILandingHeadingProps) => {
  useWindowSize();
  const isDesktop = getIsDesktop();
  const greeting = useMemo(() => {
    return `${getGreeting()} ${userName},`;
  }, [userName]);

  return (
    <div css={styles.headingContainer}>
      <div css={styles.greetingContainer}>
        <span css={styles.greeting(isDesktop)}>{greeting}</span>
        <span css={styles.welcomeMessage(isDesktop)}>
          Welcome to your music portal
        </span>
      </div>
      {!!logoUrl && (
        <img
          css={[appStyles.desktopOnly, styles.logo]}
          src={logoUrl}
          alt="logo"
        />
      )}
    </div>
  );
};

export default LandingHeading;
