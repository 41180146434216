import { css, keyframes } from "@emotion/react";
import theme from "src/theme";

const greenPulse = keyframes`
  0% {
    color: ${theme.colors.functional.successGreen};
  }

  100% {
    color: ${theme.colors.secondary.iconGray};
  }
`;

export default {
  buttonRowContainer: css`
    display: flex;
    justify-content: space-between;
  `,
  secondaryBtn: css`
    margin-left: 1.5rem;

    &[type="button"] {
      color: ${theme.colors.white};
    }
  `,
  backBtn: css`
    border: 0;
    background: transparent;
    cursor: pointer;

    &:hover {
      color: ${theme.colors.functional.brightOrange};
    }
  `,
  backBtnTxt: css`
    text-transform: uppercase;
    padding-left: 1.5rem;
    font-size: 0.875rem;
  `,
  progressIndicator: css`
    div {
      color: ${theme.colors.white};
    }
  `,
  progressIndicatorLabel: css`
    padding-bottom: 0.5rem;
  `,
  link: css`
    color: ${theme.colors.white};
    text-decoration: underline;

    &:hover {
      color: ${theme.colors.functional.brightOrange};
    }
  `,
  metaData: css`
    margin-bottom: 2rem;

    p {
      color: ${theme.colors.secondary.iconGray};
      font-size: 0.9rem;
      padding: 0.25rem 0;
      margin: 0;
    }
  `,
  metaDataHeading: css`
    color: ${theme.colors.white};
    font-weight: bold;
  `,
  tableHeaderContainerOverrides: css`
    margin-bottom: 0;
  `,
  pulseAnimation: css`
    animation: ${greenPulse} 2s;
  `,
  retryFileButton: css`
    padding-left: 1rem;
  `,
};
